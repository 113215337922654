import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CrossIcon from '../../../asserts/images/Close.svg'
import NoImageFound from '../../../asserts/images/noImageFound.jpg'
import Moment from 'moment'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Box, Card, CardContent, Grid, Button, Avatar, Typography, Divider, CardMedia } from '@mui/material'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import DialogActions from '@mui/material/DialogActions'
import CreateNewEvent from '../AddSevaEvent/CreateNewEvent'
import PreviewImagesDialog from 'Components/AllInitiativeReportRefactored/PreviewImagesDialog/PreviewImagesDialog'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { getIds } from '../../ReusableComponents.js/getIds'
import PdfCard from '../../ReusableComponents.js/PdfCard'
import { handleFileName } from '../../ReusableComponents.js/reuseMethods'
import './EventDetailsDialog.css'

const EventDetailsDialog = ({
  handleCloseEventDetailsDialog,
  openEventDetailsDialog,
  details,
  mpProfileData,
  initiativeId,
  user,
  setEventDeleted,
  limit = 10,
  tenure,
}) => {
  const [isSevaEventEdit, setIsSevaEventEdit] = useState(false)
  const [files, setFiles] = useState([])
  const [openCreateEventDialog, setOpenCreateEventDialog] = useState(false)
  const [openPreviewImages, setOpenPreviewImages] = useState(false)
  const [previewImageIndex, setPreviewImageIndex] = useState()
  const postAuditLogs = async (sqId, id) => {
    await makeLogRequest('seva_event_popup', sqId, {
      useridtype: getIds(),
      e_id: id && id,
    })
  }

  useEffect(() => {
    postAuditLogs('pageload', details?.id)
  }, [])

  useEffect(() => {
    if (details) {
      setFiles(details?.media && JSON.parse(details?.media))
    }
  }, [details])

  const handlePreview = (file) => {
    const images = ['jpg', 'jpeg', 'gif', 'png', 'svg', 'jfif']
    const videos = ['mp4', '3gp', 'ogg']
    const url = new URL(file)
    const extension = url?.search.split('.')[1]
    const pathExtension = url?.pathname?.slice(url.pathname.lastIndexOf('.') + 1, url.pathname.length) || ''
    if (details && (images.includes(extension) || images.includes(pathExtension))) {
      return (
        <Avatar
          variant="square"
          src={file}
          alt="Preview"
          onError={(e) => (e.target.src = NoImageFound)}
          className="form-img__img-preview-invide-ini event-details-asset-preview"
        />
      )
    } else if (details && (videos.includes(extension) || videos.includes(pathExtension))) {
      return (
        <video controls className="form-img__img-preview-invide-ini event-details-asset-preview">
          <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }
  }

  const handleOpenCreateEvent = () => {
    setOpenCreateEventDialog(true)
  }
  const handleCloseCreateEvent = () => {
    // setEventByMpId(false);
    setOpenCreateEventDialog(false)
  }

  const onEditClick = () => {
    setIsSevaEventEdit(true)
    handleOpenCreateEvent()
    // if (checkMySeva) {
    //     setEventByMpId(true);
    // }
  }

  const handleOpenPreviewImages = (index) => {
    setPreviewImageIndex(index)
    setOpenPreviewImages(true)
  }
  const handleClosePreviewImages = () => {
    setOpenPreviewImages(false)
  }

  const descWithLineBreaks = details?.desc && details.desc
  const descArray = descWithLineBreaks && descWithLineBreaks.split(/\r?\n/)

  return (
    <>
      <Dialog
        open={openEventDetailsDialog}
        onClose={() => (handleCloseEventDetailsDialog(), postAuditLogs('close'))}
        sx={{ height: '96vh', marginTop: '15px' }}
      >
        <Avatar
          onClick={() => (handleCloseEventDetailsDialog(), postAuditLogs('close'))}
          src={CrossIcon}
          className="closeIcon"
        />
        <DialogTitle
          id="customized-dialog-title"
          onClose={() => (handleCloseEventDetailsDialog(), postAuditLogs('close'))}
        >
          <Box className="event-conducted-by-container">
            <Typography component="h1" className="event-conducted-by-text">
              Event Conducted by {details?.user?.user_name}
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
        </DialogTitle>
        <DialogContent sx={{ mt: -1.5 }}>
          <Box className="text-center pt-1 d-flex justify-content-center">
            {details?.user?.user_avatar ? (
              <CardMedia
                component="img"
                src={details?.user?.user_avatar}
                className="img-circle leader-circle-img mr-1"
              />
            ) : (
              <AccountCircleIcon
                sx={{
                  fontSize: 'xx-large',
                  width: '80px',
                  height: '80px',
                  color: '#EEEEEE',
                }}
              />
            )}
          </Box>
          <Box className="card-content">
            <Typography component="h2" className="event-details-user-name">
              {details?.user?.user_name ?? ''}
            </Typography>
            <Typography component="span" className="initial event-details-user-designation">
              {details?.user?.designation ?? '' + ', ' + details?.user?.state_name ?? ''}
            </Typography>
            <Box />
            <Typography component="span" className="constituency event-details-user-constituency">
              {'Constituency' + ' : ' + details?.user?.constituency_name ?? ''}
            </Typography>
            <Box />
            <Typography component="span" className="event-details-event-title">
              {details?.eventTitle ?? ''}
            </Typography>
          </Box>
          <Box className="event-details-event-description-container">
            <Typography component="span" className="event-details-event-label">
              Event Description
              <Box />
            </Typography>
            <Box className="event-details-event-description">
              <Typography
                component="span"
                className="event-details-event-description-text"
                title={descArray?.map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <Box />
                  </React.Fragment>
                ))}
              >
                {descArray?.map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <Box />
                  </React.Fragment>
                ))}
              </Typography>
            </Box>
          </Box>
          <Box mt={3} />
          <Box>
            <Typography component="span" className="event-details-event-label">
              Event Images
            </Typography>
            <Box>
              {files?.length > 1 ? (
                <Tabs
                  variant="scrollable"
                  scrollButtons
                  aria-label="visible arrows tabs example"
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                    },
                    '& .MuiTabs-flexContainer': { marginLeft: '-5px' },
                  }}
                >
                  {files?.map((file, index) => (
                    <Card
                      sx={{ minWidth: 200, mr: 2, borderRadius: '20px' }}
                      className=" bshadow"
                      onClick={() => {
                        handleOpenPreviewImages(index)
                      }}
                    >
                      <CardContent>
                        <Box key={index}>{handlePreview(file)}</Box>
                      </CardContent>
                    </Card>
                  ))}
                </Tabs>
              ) : (
                files?.map((file, index) => (
                  <Card
                    sx={{ minWidth: 200, mr: 2, borderRadius: '20px' }}
                    className="bshadow"
                    onClick={() => {
                      handleOpenPreviewImages(index)
                    }}
                  >
                    <CardContent>
                      <Box key={index}>{handlePreview(file)}</Box>
                    </CardContent>
                  </Card>
                ))
              )}
            </Box>
            <Grid container>
              <Grid item xs={6}>
                <Typography component="span" className="event-details-event-label">
                  Start Date :
                </Typography>{' '}
                {Moment(details?.statTime).utc().format('DD/MM/YYYY')}
              </Grid>
              <Grid item xs={6}>
                <Typography component="span" className="event-details-event-label">
                  End Date :
                </Typography>{' '}
                {details?.endTime !== null ? Moment(details?.endTime).utc().format('DD/MM/YYYY') : '-'}
              </Grid>
            </Grid>
            <Typography component="span" className="event-details-event-label event-details-event-label-location">
              {' '}
              Location
            </Typography>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{
                  fontFamily: 'HK Grotesk',
                  color: '#505050',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  marginLeft: '55px',
                }}
              >
                {details?.location ?? ''}
              </Grid>
            </Grid>
            <Grid>
              {((typeof details?.detailsPdf == 'string' && details?.detailsPdf !== '[]') ||
                (typeof details?.detailsPdf == 'object' && details?.detailsPdf.length > 0)) && (
                <>
                  <Typography component="span" className="event-details-event-label event-details-event-label-pdf">
                    Event Pdf :
                  </Typography>
                  <Box sx={{ marginLeft: '40px' }}>
                    <PdfCard url={details.detailsPdf} name={handleFileName(details.detailsPdf)} />
                  </Box>
                </>
              )}
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'Center' }}>
          {details?.userId === mpProfileData?.id && (
            <>
              {user !== 'Admin' && user !== 'Leader' && (
                <Button
                  variant="contained"
                  className="button-tr-2"
                  disabled={tenure === 'Modi 2.0'}
                  autoFocus
                  sx={
                    tenure === 'Modi 2.0'
                      ? {
                          color: 'rgba(0, 0, 0, 0.26) !important',
                          boxShadow: 'none !important',
                          backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                          border: 'none !important',
                        }
                      : { backgroundColor: '#ef7335' }
                  }
                  onClick={onEditClick}
                >
                  Edit
                </Button>
              )}
              <Button
                variant="outlined"
                className="button-tr-citizen-cancel"
                autoFocus
                onClick={handleCloseEventDetailsDialog}
              >
                Cancel
              </Button>
            </>
          )}
        </DialogActions>
        {openCreateEventDialog && (
          <CreateNewEvent
            openCreateEventDialog={openCreateEventDialog}
            isSevaEventEdit={isSevaEventEdit}
            handleCloseCreateEvent={handleCloseCreateEvent}
            details={details}
            editEventDetails={true}
            handleCloseEventDetailsDialog={handleCloseEventDetailsDialog}
            initiativeId={initiativeId}
            setEventDeleted={setEventDeleted}
            limit={limit}
          />
        )}
        {openPreviewImages && (
          <PreviewImagesDialog
            openPreviewImages={openPreviewImages}
            handleClosePreviewImages={handleClosePreviewImages}
            files={files}
            previewImageIndex={previewImageIndex}
          />
        )}
      </Dialog>
    </>
  )
}

export default EventDetailsDialog
