import React from 'react'
import { useState, useEffect } from 'react'
import './sharingTextBox.css'

import ShareIcon from '@mui/icons-material/Share'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Avatar, Dialog, DialogTitle, Typography } from '@mui/material'
import Share from '../../ReusableComponents.js/Share'
import CrossIcon from '../../../asserts/images/Close.svg'
import QuoteIcon from '../../../asserts/images/Quote.svg'
import { getIds } from '../../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { pushTag } from '../../../utils/gtmservice'
import { Box } from '@mui/system'

function SharingTextBox({ language, postAuditLogs }) {
  const [textData, setTextData] = useState([])
  const texttemplates = useSelector(
    (state) => state?.socialMediaKit?.data !== [] && state?.socialMediaKit?.data?.texttemplates
  )
  const [filterData, setFilterData] = useState([])
  const [openShare, setOpenShare] = useState(false)
  const [content, setContent] = useState('')
  const dispatch = useDispatch()
  const tkn = localStorage.getItem('tokenDetails')
  const handleClickingOpen = () => {
    pushTag({
      event: 'pop_up',
      page_title: 'sharing_text_medium_pop_up',
    })
    setOpenShare(true)
  }

  const handleShareModalClose = (value) => {
    setOpenShare(false)
  }
  // if languages have been added we can add the functionality here //

  useEffect(() => {
    setTextData(texttemplates && texttemplates)
  }, [])

  useEffect(() => {
    if (openShare) {
      sendSharePopUpLog('pageload')
    }
  }, [openShare])

  const sendSharePopUpLog = async (type) => {
    const parameters = {
      useridtype: getIds(),
    }
    makeLogRequest('share_on_socialmedia_popup', type, parameters)
  }

  const copyShareContent = async (item, type) => {
    const content = `${item.text} ${
      item.hashtag ? (item.hashtag?.startsWith('#') ? item.hashtag : `#${item.hashtag}`) : ''
    }`
    if (type === 'copy') {
      try {
        await window.navigator.clipboard.writeText(content)
        toast.success('Copied to clipboard.')
      } catch (error) {
        console.log(error, 'error while copying content')
      }
      return
    }
    setContent(content)
    handleClickingOpen()
  }

  return (
    <>
      <Box className="itemfixed5">
        {textData.length === 0 ? (
          <Typography component="span" className="no-text-message">
            No text added by Admin
          </Typography>
        ) : (
          textData
            ?.filter((x) => x.language == language || language == 'All')
            .map((item, i) => (
              <Box className="sharing-text-box-container custom-sharing-text-box sharing-box-padding" key={i}>
                <Avatar
                  variant="square"
                  sx={{ height: '29px', width: '13px' }}
                  src={QuoteIcon}
                  alt="Quote icon"
                  height={31}
                  width={13}
                />
                <Typography
                  variant="body1"
                  sx={{ fontFamily: 'HK Grotesk' }}
                  className="linebreakut ml-4 social-media-kit-paragraph"
                >
                  {item.text}
                </Typography>
                <Box className="position-relative mt-auto">
                  <Box className="sharing-text-box-bottom linebreakut ml-4">
                    <Typography variant="body1" className="mb-0">
                      {item.hashtag}
                    </Typography>
                    <Box className="sharing-text-box-bottom-right">
                      <Box>
                        <ShareIcon
                          className="copy-share-container mr-2"
                          onClick={async () => {
                            pushTag({
                              event: 'share',
                              page_title: 'sharing_text_pop_up',
                            })
                            copyShareContent(item, 'share')
                            await postAuditLogs('share', { text_id: item.id })
                          }}
                        />
                      </Box>
                      <Box>
                        <ContentCopyIcon
                          onClick={async () => {
                            pushTag({
                              event: 'copy',
                              page_title: 'sharing_text_pop_up',
                            })
                            copyShareContent(item, 'copy')
                            await postAuditLogs('copy_text', {
                              text_id: item.id,
                            })
                          }}
                          className="copy-share-container"
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {/* <ShareModal open={openShare} handleClose={handleShareModalClose} /> */}
              </Box>
            ))
        )}
        {openShare && (
          <Dialog open={openShare} handleClose={handleShareModalClose}>
            <DialogTitle>
              <Avatar
                sx={{
                  position: 'absolute',
                  right: '15px',
                  top: '15px',
                  height: '30px',
                  width: '30px',
                }}
                onClick={() => {
                  sendSharePopUpLog('close')
                  handleShareModalClose(false)
                }}
                src={CrossIcon}
                className="closeIcon"
              />
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: '#357092',
                  fontFamily: 'HK Grotesk',
                  fontSize: '26px',
                  fontWeight: 'bold',
                }}
              >
                Share On Social Media
              </Typography>

              <Box className="share-container">
                <Share content={content} sharingTextMediumPopUp={true} />
              </Box>
              {/* <CloseIcon onClick={() => setAddMembers(false)} /> */}
            </DialogTitle>
          </Dialog>
        )}
      </Box>
    </>
  )
}

export default SharingTextBox
