import { Grid, Box, Typography, Avatar, CardMedia } from '@mui/material'
import CardScoreComponent from '../CardScoreComponent/CardScoreComponent'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { convertNumberIntoLacsCr } from '../../ReusableComponents.js/reuseMethods'
import { useNavigate } from 'react-router-dom'
import { getIds } from '../../ReusableComponents.js/getIds'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import third from '../../../asserts/images/3rd.png'
import first from '../../../asserts/images/1st.png'
import second from '../../../asserts/images/2nd.png'
import './CardComponent.css'

const CardComponent = ({ mp, index, selectedCard }) => {
  const navigate = useNavigate()
  const loggedInId = getIds()
  const ranking = {
    1: first,
    2: second,
    3: third,
  }

  const handleCardlogs = (mp_id, index) => {
    const parameters = {
      rank: index + 1,
      mp_id: mp_id,
      section: selectedCard,
      useridtype: getIds(),
    }
    makeLogRequest('mp_leaderboard', 'mp_card', parameters)
  }

  const handleCardClick = () => {
    if (mp?.hasOwnProperty('isMP') && mp?.isMP == 0) {
      return
    }
    if (!window.getSelection().toString()) {
      if (mp.id === loggedInId) {
        navigate('/MySevaUpdates', {
          state: {
            MpName: 'My Seva Rank',
            mpId: loggedInId,
          },
        })
      } else {
        navigate('/MpSevaUpdates', {
          state: {
            MpName: mp.user_name,
            mpId: mp.id,
            mpClick: true,
            mpData: mp && mp,
          },
        })
      }
    }
    handleCardlogs(mp.id, index)
  }

  return (
    <Grid item xs={12} md={12} lg={4} xl={4}>
      <Box className="cursor-pointer" onClick={handleCardClick}>
        <Box>
          <Box className="Cards position-relative card-shadow">
            {Object.keys(ranking).includes(index.toString()) ? (
              <Box sx={{ maxWidth: '35px' }}>
                <CardMedia component="img" alt="rank" src={ranking[index]} className="topicon"></CardMedia>
              </Box>
            ) : (
              <Box
                sx={{ height: '36px', width: '36px', background: '#356F92', borderRadius: '50%' }}
                className="d-flex, justify-content-center text-center"
              >
                <Typography
                  sx={{
                    color: '#ffffff',
                    fontFamily: 'HK Grotesk bold',
                    fontSize: '20px',
                  }}
                  variant="span"
                >
                  {index}
                </Typography>
              </Box>
            )}
            {mp.user_avatar ? (
              <Box className="text-center bottom-16 position-relative d-flex justify-content-center">
                <Avatar
                  alt="cross"
                  src={mp.user_avatar}
                  className="img-circle leader-circle-img mr-1 profile-img-box-shadow mpcard-avatar"
                  draggable="false"
                />
              </Box>
            ) : (
              <Box className="text-center position-relative bottom-16 ">
                <AccountCircleIcon
                  className="profile-icon-box-shadow"
                  sx={{
                    fontSize: 'xx-large',
                    width: '70px',
                    height: 'auto',
                    color: '#EEEEEE',
                  }}
                />
              </Box>
            )}
            <Box className="leader-content ellipsewe-name">
              <Typography
                className="ellipsewe1"
                sx={{
                  color: '#356f92',
                  fontFamily: 'HK Grotesk bold',
                  fontSize: '20px',
                }}
              >
                {mp?.user_name}
              </Typography>
              {mp?.house === 'Rajya Sabha' ? (
                <Typography
                  className="ellipsewe1"
                  sx={{ color: '#707a89', fontSize: '12px', fontFamily: 'HK Grotesk medium' }}
                >
                  State: {mp?.state_name}
                </Typography>
              ) : (
                <Typography
                  className="ellipsewe1"
                  sx={{ color: '#707a89', fontSize: '12px', fontFamily: 'HK Grotesk medium' }}
                >
                  Constituency: {mp?.constituency_name}
                </Typography>
              )}
            </Box>
            {[
              {
                key: 'sevaScore',
                text: 'MP Seva Score',
                value: mp?.sevaScore,
              },
              {
                key: 'memberAdded',
                text: 'Members Added',
                value: mp?.memberAdded,
              },
              {
                key: 'followers',
                text: 'Followers',
                value: mp?.followers,
              },
              {
                key: 'donation',
                text: 'Donations Raised',
                value: mp?.donation,
              },
              {
                key: 'survey',
                text: 'Surveys completed in constituency',
                value: mp?.campaignScore,
              },
            ].map((card) =>
              card.value !== undefined
                ? selectedCard === card.key && (
                    <CardScoreComponent
                      key={card.key}
                      selectedCard={selectedCard}
                      CardScoreComponentTitle={card.text}
                      CardScoreComponentValue={convertNumberIntoLacsCr(parseInt(card.value, 10))}
                    />
                  )
                : null
            )}
            {selectedCard === 'ecards' ? (
              <>
                <Box className="score-container border-bottom">
                  <Typography variant="h3" className="margin-b-1 card-content">
                    E-Cards Campaign Score
                  </Typography>
                  <Typography variant="span">
                    {convertNumberIntoLacsCr(parseInt(mp?.campaignScore < 0 ? 0 : mp?.campaignScore, 10))}
                  </Typography>
                </Box>
                <Box className="score-container font-12 mt-1">
                  <Grid container rowSpacing={0.6} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                    <Grid item xs={9} className="mt-1">
                      E-Cards created by MP
                    </Grid>
                    <Grid item xs={3} className="mt-1">
                      {convertNumberIntoLacsCr(mp?.cardsCreated)}
                    </Grid>
                    <Grid item xs={9}>
                      Likes on E-Cards created by MP
                    </Grid>
                    <Grid item xs={3}>
                      {convertNumberIntoLacsCr(mp?.likes)}
                    </Grid>
                    <Grid item xs={9} md={10}>
                      Wishes on E-Cards created by MP
                    </Grid>
                    <Grid item xs={3} md={2}>
                      {convertNumberIntoLacsCr(mp?.wishes)}
                    </Grid>
                  </Grid>
                </Box>
              </>
            ) : null}
            {selectedCard === 'twitterPerformance' ? (
              <Box className="score-container">
                <Box className="Twitter-performance__score-heading">Twitter Performance</Box>
                <Typography variant="span">{convertNumberIntoLacsCr(parseInt(mp?.twitterPerformance, 10))}</Typography>
              </Box>
            ) : null}
            {selectedCard === 'initiatives' ? (
              <Box className="score-container font-14">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={9} className="mt-1">
                    Citizens Engagement with Events
                  </Grid>
                  <Grid item xs={3} className="mt-1">
                    {convertNumberIntoLacsCr(mp?.citizen)}
                  </Grid>
                  <Grid item xs={8}>
                    Initiatives
                  </Grid>
                  <Grid item xs={4}>
                    {convertNumberIntoLacsCr(mp?.initiatives)}
                  </Grid>
                  <Grid item xs={8}>
                    Events Organized
                  </Grid>
                  <Grid item xs={4}>
                    {convertNumberIntoLacsCr(mp?.eventOrganized)}
                  </Grid>
                </Grid>
              </Box>
            ) : null}
            {selectedCard === 'mediaCoverage' ? (
              <Box className="score-container">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} paddingTop={2.5}>
                  <Grid item xs={6}>
                    Op-Eds
                  </Grid>
                  <Grid item xs={6}>
                    {convertNumberIntoLacsCr(parseInt(mp?.opEds, 10))}
                  </Grid>
                  <Grid item xs={8}>
                    Media Coverage
                  </Grid>
                  <Grid item xs={4}>
                    {convertNumberIntoLacsCr(parseInt(mp?.mediaCoverage, 10))}
                  </Grid>
                </Grid>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Grid>
  )
}
export default CardComponent
