import { Grid, Box, IconButton, FormHelperText } from '@mui/material'
import { useMemo } from 'react'
import { BootstrapTooltipInfo } from '../../ReusableComponents.js/ToolTip/ToolTipComponent'
import infoic from '../../../asserts/images/Info.svg'
import downloadIcon from '../../../asserts/images/downloadIcon.svg'
import './UploadFileComponent.css'

const UploadFileComponent = ({
  ComponentTitle,
  input,
  errors,
  i,
  fileFormats,
  assetRef,
  registerFunction,
  onClick,
  onChange,
}) => {
  const gridStyles = useMemo(
    () => ({
      pr: 1,
      pl: 1,
      margin: '0 auto',
      border: 'dotted 3px #1976d2',
      bgcolor: '#E0F6FF',
      padding: '50px',
      maxWidth: '275px !important',
      borderRadius: '14px',
      aspectRatio: '7 / 4',
    }),
    []
  )

  return (
    <Grid item xs={6} md={6} lg={6} xl={6} sx={{ marginTop: '20px' }}>
      <Box className="preview-img">{ComponentTitle}</Box>
      {input.upload && (
        <Grid item xs={6} sx={gridStyles}>
          <Box className="title-n-tooltip d-flex justify-content-center">
            <Box className="upload-text upload-file-text ">UPLOAD A FILE</Box>
            <BootstrapTooltipInfo
              title={
                ComponentTitle === 'Video Preview'
                  ? 'Accepted file formats: .mp4, .mov, .wmv, .webm, .avi, .flv, .mkv, .mts'
                  : 'Accepted file formats: .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .tiff, .psd, .eps, .ai, .indd, .raw, .jfif, .webp, .svg, .svgz, .heif'
              }
              placement="left"
              componentsProps={{
                tooltip: {
                  sx: {
                    fontSize: '18px',
                    fontFamily: 'HK Grotesk',
                    padding: '15px',
                  },
                },
              }}
            >
              <img src={infoic} className="infoic-tooltip" />
            </BootstrapTooltipInfo>
          </Box>
          <Box
            sx={{
              display: 'flex',
              '& > :not(style)': {
                width: 30,
                height: 30,
              },
            }}
          >
            <Box>
              <IconButton color="primary" aria-label="Upload" className="upload-icon" onClick={onClick}>
                <img src={downloadIcon} width={40} />
              </IconButton>
              <br />
              <input
                type="file"
                {...registerFunction()}
                ref={assetRef}
                multiple
                onChange={onChange}
                accept={fileFormats.join(', ')}
                className="d-none"
              />

              <Box className="upload-support">
                {`Supported File: ${ComponentTitle === 'Video Preview' ? 'Videos' : 'Images'}, Open File, Zip File.`}
              </Box>
              <Box className="upload-browse cursorshow" onClick={onClick}>
                Browse
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
      {errors[i] && (
        <FormHelperText error class="p-error center" classname="ml-0">
          {ComponentTitle === 'Video Preview'
            ? errors && errors[i] && errors[i].videos?.message
            : errors && errors[i] && errors[i].images?.message}
        </FormHelperText>
      )}
    </Grid>
  )
}

export default UploadFileComponent
