import React from 'react'
import dropDown from '../../asserts/images/Dropdown.svg'
import arrowDown from '../../asserts/images/arrowDown.svg'
import zipIcon from '../../asserts/images/ZipIcon.svg'
import fileIcon from '../../asserts/images/FileIcon.svg'

const zipFormat = ['application/x-zip-compressed', 'application/x-zip', 'zip']
const aiFormat = ['application/postscript', 'application/illustrator', 'ai']
export const validateNotEmpty = (value, fieldName) => {
  if (value.trim() === '') {
    return `Please enter ${fieldName}`
  }
  return true
}

export const iconComponent = () => {
  return <img alt="select" src={dropDown} className="icon-component" />
}
export const iconComponentDown = () => {
  return <img alt="select" src={dropDown} className="icon-component transform180" />
}
export const ArrowComponent = () => {
  return <img alt="select" src={arrowDown} className="arrow-down" />
}
export const ArrowComponentDown = () => {
  return <img alt="select" src={arrowDown} className="arrow-down transform180" />
}
export const handleSrc = (img) => {
  let imageSrc = img.url ? img.url : img
  if (img?.type) {
    imageSrc = ' .' + img?.type
  }
  if (imageSrc.split('.').pop() === 'zip') {
    return 'zip'
  } else if (['ai', 'postscript'].includes(imageSrc?.split('.').pop())) {
    return 'ai'
  } else if (img?.type === 'image') {
    return img?.url
  }
  return imageSrc
}

export const handleVideoSrc = (video) => {
  video = video.file ? video.file : video
  let videoSrc = video.url ? video.url : video
  let isSupported = false
  if (video?.type === 'video') {
    videoSrc = ' .' + video?.type
    const arr = video?.url?.split(',')
    const base64String = arr[arr?.length - 1]
    const binaryString = atob(base64String)
    const bytes = new Uint8Array(binaryString.length)
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i)
    }

    const header = Array.from(bytes.slice(0, 4))
      .map((byte) => byte.toString(16))
      .join('')
      .toUpperCase()

    isSupported = header.includes('66747970') || header.includes('1A45DFA3') // '66747970' '1A45DFA3' corresponds to WebM, MP4 file signature
  }
  if (
    (typeof videoSrc == 'string' && videoSrc?.split('.').pop() === 'zip') ||
    videoSrc?.type === 'application/x-zip-compressed'
  ) {
    return 'zip'
  } else if (typeof videoSrc == 'string' && ['ai', 'postscript'].includes(videoSrc?.split('.').pop())) {
    return 'ai'
  } else if (video?.type === 'mts' || (typeof videoSrc == 'string' && videoSrc.split('.').pop() === 'mts')) {
    return 'mts'
  } else if (
    ['video/mp4', 'mp4', 'video/webm'].includes(video?.type) ||
    (isSupported && video?.url?.split(';')[0] != 'data:video/x-matroska') ||
    (typeof videoSrc == 'string' && ['video/mp4', 'mp4', 'webm'].includes(videoSrc.split('.').pop()))
  ) {
    return 'video'
  }
  return 'unknown'
}

export const handleFileName = (file) => {
  let url = file?.url ? file?.url : file
  try {
    if (file?.url) {
      return file?.file?.name
    } else if (file) {
      const sections = url?.split('/')
      const parts = sections[sections?.length - 1].split('.')
      const desiredString = parts[0]?.split('_-_')[0] + '.' + parts[parts?.length - 1]
      return desiredString
    }
  } catch (error) {
    console.log('reuseMethods- handleFileName - error:', error)
  }
  return
}
export const handlePdfClickOpen = (file) => {
  window.open(typeof file == 'string' ? file : URL.createObjectURL(file), '_blank')
}

export const onPdfFileUpload = (event, setDetailPdf, showNotification) => {
  const type = event.target.files[0].type
  const isRightFormat = ['application/pdf'].includes(type)
  if (!isRightFormat) {
    showNotification('Error', 'Please upload PDF format only', 'error')
    return
  }
  if (event.target.files[0].size > 52428800) {
    showNotification('Error', 'Please upload file smaller than 50 MB', 'error')
    return
  }
  setDetailPdf(event.target.files[0])
  return true
}

export const convertNumberIntoLacsCr = (number) => {
  if (typeof number !== 'number') return number
  if (number <= 100000) {
    return number.toLocaleString('en-IN')
  } else if (number <= 9999999) {
    const lacs = (number / 100000).toFixed(2)
    if (lacs !== 'NaN') {
      return `${lacs} Lacs`
    }
  } else {
    const crores = (number / 10000000).toFixed(2)
    if (crores !== 'NaN') {
      return `${crores} Cr`
    }
  }
}

export const convertToHHMMSS = (val) => {
  let roundOffValue = Math.floor(val)
  const secNum = parseInt(roundOffValue, 10)
  let hours = Math.floor(secNum / 3600)
  let minutes = Math.floor((secNum - hours * 3600) / 60)
  let seconds = secNum - hours * 3600 - minutes * 60

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }
  if (seconds < 10) {
    seconds = '0' + seconds
  }
  let time
  // only mm:ss
  if (hours === '00') {
    time = minutes + ':' + seconds
  } else {
    time = hours + ':' + minutes + ':' + seconds
  }
  return time
}

export const getVideoFileDuration = (file, callback) => {
  const video = document.createElement('video')
  video.onloadedmetadata = () => {
    callback(video.duration)
  }
  video.src = URL.createObjectURL(file)
}

export const clearInputValue = (event) => {
  event.target.value = null
}
