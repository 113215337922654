import React, { useEffect, useMemo, useState } from 'react'
import { VideoLoader } from '../../ReusableComponents.js/VideoLoader'

import zipIcon from '../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../asserts/images/FileIcon.svg'
import unknownImg from '../../../asserts/images/folders.png'
import { ImageListItem, Typography, Box, Avatar } from '@mui/material'
import { handleFileName } from '../../ReusableComponents.js/reuseMethods'
import './VideosFolder.css'

const VideosFolder = ({
  videoResponse,
  languageData,
  setViewVideo,
  folderName,
  setAllVideos,
}) => {
  const [videoData, setVideoData] = useState([])

  const zipFormat = ['application/x-zip-compressed', 'application/x-zip', 'zip']
  const aiFormat = [
    'application/postscript',
    'application/illustrator',
    'ai',
    'postscript',
  ]
  useEffect(() => {
    handleVideos()
  }, [])

  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )

  const handleVideos = () => {
    const videos =
      videoResponse &&
      videoResponse.filter((data) => {
        return data.language === languageData
      })
    setVideoData(videos)
    // setAllVideos(videos);
  }

  let videosArray = []
  videoData &&
    videoData?.forEach((image) => {
      const video = JSON.parse(image.video)
      // image && image?.forEach((data) =)
      video && video?.forEach((data) => videosArray.push(data))
    })

  useEffect(() => {
    setAllVideos(videosArray)
  }, [videoData])

  return (
    <>
      <Box className="itemgrid2 cursorshow">
        {videosArray &&
          videosArray?.map((item, i) => (
            <Box
              className="position-relative"
              onClick={() => {
                setViewVideo(item)
              }}
            >
              {/* {item.split('.').pop()} */}
              {!['webm', 'mp4', 'webM'].includes(item.split('.').pop()) ? (
                <ImageListItem
                  key={i}
                  className="bgfolder cursorshow"
                  sx={{
                    margin: '0px',
                    width: '200px !important',
                    padding: '10px',
                    aspectRatio: '1 / 0.9 !important',
                  }}
                >
                  <Avatar
                    variant="square"
                    className="zip-ai-postscript-video"
                    key={i}
                    src={
                      item.split('.').pop() === 'zip'
                        ? zipIcon
                        : ['ai', 'postscript'].includes(item.split('.').pop())
                        ? fileIcon
                        : unknownImg
                    } //?w=164&h=164&fit=crop&auto=format
                    srcSet={
                      item.split('.').pop() === 'zip'
                        ? zipIcon
                        : ['ai', 'postscript'].includes(item.split('.').pop())
                        ? fileIcon
                        : unknownImg
                    } //?w=164&h=164&fit=crop&auto=format
                    // alt={item.title}
                  />
                  <Typography variant="subtitle1" sx={subtitleStyles}>
                    {handleFileName(item)}
                  </Typography>
                </ImageListItem>
              ) : (
                <VideoLoader
                  // iframe={true}
                  left="80"
                  width="100%"
                  height="120"
                  className=" im100 cursorshow"
                  src={`${item}`} //?w=164&h=164&fit=crop&auto=format
                  loading="lazy"
                  videosFolderPath={true}
                  onClick={() => setViewVideo(item)}
                  isSmallSection={true}
                />
              )}
              {/* <video
                className=" im100 cursorshow"
                src={`${item}?w=164&h=164&fit=crop&auto=format`}
                // srcSet={`${item.video}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                // alt={item.subTitle}
                loading="lazy"
                onClick={() => setViewVideo(item)}
              /> */}
              {/* <Typography variant="subtitle1">{item.title}</Typography> */}
            </Box>
          ))}
      </Box>
    </>
  )
}

export default VideosFolder
