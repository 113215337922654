import {
  SEARCH_SEVA_INITIATIVES_REQUEST,
  SEARCH_SEVA_INITIATIVES_SUCCESS,
  SEARCH_SEVA_INITIATIVES_FAILURE,
} from './types'
import axiosInstance from '../../utils/api'

export const searchSevaInitiativesRequest = () => {
  return {
    type: SEARCH_SEVA_INITIATIVES_REQUEST,
    payload: [],
  }
}
export const searchSevaInitiativesSuccess = (value) => {
  return {
    type: SEARCH_SEVA_INITIATIVES_SUCCESS,
    payload: value,
  }
}
export const searchSevaInitiativesFailure = (error) => {
  return {
    type: SEARCH_SEVA_INITIATIVES_FAILURE,
    payload: [],
  }
}

export const getSearchSevaInitiativesList = (queryParams) => async (dispatch) => {
  dispatch(searchSevaInitiativesRequest)
  const { searchText, offset, limit, mpId } = queryParams
  let urlEndPoint = `/api/search/initiatives?${
    mpId ? `id=${mpId}&` : ''
  }searchText=${searchText}&offset=${offset}&limit=${limit}`

  await axiosInstance
    .get(urlEndPoint)
    .then((response) => {
      const result = response.data
      dispatch(searchSevaInitiativesSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(searchSevaInitiativesFailure(errorMsg))
    })
}
