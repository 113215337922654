import { useState, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import './CardHeader.css'
import { Box, TextField, InputAdornment } from '@mui/material'
import searchIcon from '../../../asserts/images/Search.svg'
import closeIcon from '../../../asserts/images/close.png'
import { getRoles, getIds } from '../../ReusableComponents.js/getIds'
import { searchInitiative } from '../../../store/action/searchInitiative'
import { getAllInitiative } from '../../../store/action/allInitiatives'
import { makeLogRequest } from '../../../utils/AuditLogs'

const SearchIcon = ({
  setSearchStatus,
  setHideSearch,
  hideSearch,
  handleAllInitiatives,
  setSelectedFilter,
  itemData,
}) => {
  const [searchInput, setSearchInput] = useState('')
  const dispatch = useDispatch()
  const loggedInId = getIds()
  const mpUserId = getRoles()

  const postAuditLogs = async (sqId, data = {}, flag = 'your_initiatives_page') => {
    data.user_role = getRoles() == 2 ? 'admin' : getRoles() == 3 ? 'leadership' : 'mp'
    data.useridtype = mpUserId
    await makeLogRequest(flag, sqId, data)
  }

  const handleSearch = (event) => {
    const searchText = event.target.value
    setSearchInput(searchText)
    debouncedSendRequest(searchText)
  }

  const sendRequest = useCallback((value) => {
    value ? setSearchStatus(true) : setSearchStatus(false)
    value ? dispatch(searchInitiative(loggedInId, value)) : dispatch(getAllInitiative(loggedInId))
    setSelectedFilter(itemData[0]?.value ?? '')
  }, [])

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendRequest, 1000)
  }, [sendRequest])

  const blockInvalidChar = (e) => {
    if (e.keyCode === 32 && e.target.selectionStart === 0) {
      e.preventDefault()
    }
  }

  const handleCloseClick = () => {
    setSearchInput('')
    setSearchStatus(false)
    dispatch(getAllInitiative(loggedInId))
  }

  return (
    <Box>
      {!hideSearch ? (
        <Box className="sort-search-icon">
          <img
            className="searchIcon cursorshow"
            width={18}
            height={18}
            src={searchIcon}
            onClick={async () => {
              await postAuditLogs('search_initiative')
              setHideSearch(!hideSearch)
            }}
          />
        </Box>
      ) : (
        <Box>
          <TextField
            sx={{
              '& fieldset': { border: 'none' },
              padding: '0px',
              '& .MuiInputBase-input': {
                padding: '5px 10px 5px 10px !important',
                fontFamily: 'HK Grotesk',
              },
              marginRight: '-12px',
            }}
            className="search-sort-ini"
            onChange={handleSearch}
            onKeyDown={blockInvalidChar}
            placeholder="Search Seva Initiatives"
            value={searchInput}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {/* search icon  */}
                  {searchInput?.length > 0 && (
                    <img
                      src={closeIcon}
                      width={16}
                      height={16}
                      className="m-3 search-close-icon"
                      onClick={handleCloseClick}
                      alt="close"
                    />
                  )}
                  <img
                    className="searchIcon cursorshow"
                    width={18}
                    height={18}
                    src={searchIcon}
                    alt="Hide Search"
                    onClick={() => {
                      setHideSearch(!hideSearch)
                      handleAllInitiatives()
                    }}
                  />
                </InputAdornment>
              ),
            }}
          ></TextField>
        </Box>
      )}
    </Box>
  )
}
export default SearchIcon
