import {
  CircularProgress,
  FormHelperText,
  Grid,
  ImageListItem,
  InputAdornment,
  Paper,
  TableContainer,
  TableHead,
  Tooltip,
} from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useRef, useEffect, useMemo } from 'react'
import SideMenu from '../../SideMenu/SideMenu'
import { alpha, styled } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import InputLabel from '@mui/material/InputLabel'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import AddIcon from '@mui/icons-material/Add'
import Table from '@mui/material/Table'
import { TableBody, TableRow, Button } from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
// import AddTextTemplates from "./AddTextTemplateNew.js/AddTextTemplate";
import AddTextTemplates from './AddTextTemplates'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { tooltipClasses } from '@mui/material/Tooltip'
import AssignToMP from './NewAssingMP'
import AddImageTemplates from './AddImageTemplate/AddImageTemplates'
import UploadCoverImage from './UploadCoverImage'
import AddVideoTemplate from './AddVideoTemplate/AddVideoTemplate'
import { yupResolver } from '@hookform/resolvers/yup'
import { createInitiativeSchema } from '../../../utils/Validations'
import { useForm } from 'react-hook-form'
import { Card } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { postIntiative } from '../../../store/action/createInitiative'
import getInitiativeList from '../../../store/action/initiativeList'
import { useLocation } from 'react-router-dom'
import {
  getSocialMediaIntiativesListById,
  getOngoingSevaIntiativesListWithInitiativeId,
} from '../../../store/action/ongoingSevaInitiativesList'
import { getLanguageList } from '../../../store/action/languageList'
import RemoveIcon from '@mui/icons-material/Remove'
import { useParams } from 'react-router-dom'
import DeleteInitiativeDialog from './DeleteInitiativeDialog'
import imagedelete from '../../../asserts/images/bin.png'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { SvgIcon } from '@mui/material'
import pdf from '../../../asserts/images/pdf.svg'
import infoic from '../../../asserts/images/Info.svg'
import zipIcon from '../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../asserts/images/FileIcon.svg'
import CrossIcon from '../../../asserts/images/Close.svg'
import unknownImg from '../../../asserts/images/folders.png'
import WarningMessageDialog from '../WarningMessageDialog'
import {
  handleFileName,
  handleSrc,
  handleVideoSrc,
  onPdfFileUpload,
  validateNotEmpty,
  handlePdfClickOpen,
} from '../../ReusableComponents.js/reuseMethods'
import VideoPlayer from '../../ReusableComponents.js/VideoPlayer'
import { useNotificationContext } from '../../../utils/NotificationContext'
import getUserProfile from '../../../store/action/getUserProfile'
import { uploadMedia, uploadFile } from '../../../utils/mediaUpload'
import { useLoading } from '../../../utils/LoadingContext'
import { makeLogRequest } from '../../../utils/AuditLogs'
import {
  allowedImageFormat,
  acceptedImageFormat,
  acceptedImageFormatsForInitiativeCover,
} from '../../../utils/constant'
import { pushTag } from '../../../utils/gtmservice'
import { ImageLoader } from '../../ReusableComponents.js/ImageLoader'

const enumForWarningDialog = {
  text: 'textTemplate',
  image: 'imageTemplate',
  video: 'videoTemplate',
}

const defaultDeleteMessage = 'Are you sure you want to delete this initiative?'

// https://github.com/styled-components/styled-components/issues/540#issuecomment-283664947
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 400,
    padding: '8px 10px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const BootstrapInput1 = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 8,
    position: 'relative',
    backgroundColor: theme.palette.mode === 'light' ? '#fcfcfb' : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px 10px',
    minWidth: '190px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))

const CreateInitiatives = () => {
  // const { id } = useParams();
  const [addTextExpand, setAddTextExpand] = useState(false)
  const [addImageExpand, setAddImageExpand] = useState(false)
  const [addVideoExpand, setAddVideoExpand] = useState(false)
  const [openTextTemplateDialog, setOpenTextTemplateDialog] = useState(false)
  const [openUploadImageDialog, setOpenUploadImageDialog] = useState(false)
  const [openVideoTemplateDialog, setOpenVideoTemplateDialog] = useState(false)
  const [openImageTemplateDialog, setOpenImageTemplateDialog] = useState(false)
  const [openAssingToMPDialog, setOpenAssingToMPDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [intiative, setIntiative] = useState()
  const [files, setImageFileData] = useState()
  const [openWarningDialog, setOpenWarningDialog] = useState(false)
  const [dialogIsFor, setDialogIsFor] = useState('')
  const [checkWarningClick, setCheckWarningClick] = useState(false)
  const { assignMpList } = useSelector((state) => state?.assignMpList)
  const { initiative, eventList, MPList } = useSelector((state) => state?.ongoingIntiativeListWithID?.data)
  const editInitiativeDetails = useSelector((state) => state?.socialMediaInitiativeById?.data[0])
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const [editDetails, setEditDetails] = useState(editInitiativeDetails)
  const name = editDetails?.initiativeName
  const [editDetailsName, setEditDetailsName] = useState({
    prefix: editDetails?.initiativeName,
    text: name,
  })

  const [image, setImage] = useState(null)
  const [editTextDetails, setEditTextDetails] = useState(editInitiativeDetails?.texttemplates)
  const [checkTextDetails, setCheckDetails] = useState(false)
  const [preview, setPreview] = useState(editDetails?.coverimage)
  const img = editDetails?.coverimage?.substring(editDetails?.coverimage?.lastIndexOf('/') + 1)
  const [upload, setUpload] = useState(false)
  const coverImage = img?.substring(0, img.indexOf('.', 3))
  const [imagetemplates, setImagetemplates] = useState(editDetails?.imagetemplates)
  const [videoTemplates, setVideoTemplates] = useState(editDetails?.videotemplates)
  // const coverImage = editDetails?.coverimage?.match(/[^\/]+$/)[0].split('-')
  const [imageName, setImageName] = useState(coverImage)
  const { loading } = useSelector((state) => state?.ongoingIntiativeListWithID)
  const [targetedDate, setTargetDate] = useState(editDetails?.tagetDate)
  const apiLanguageList = useSelector((state) => state.languageList?.data?.languagedata)
  const [textData, setTextData] = useState(editDetails?.texttemplates || [])
  const [imageData, setImageData] = useState([])
  const [videoData, setVideoData] = useState([])
  const [onEditBrowse, setOnEditBrowse] = useState(false)
  const [updateValue, setUpdateValue] = useState(false)
  const [updateVideoValue, setUpdateVideoValue] = useState(false)
  const [checkCoverImage, setCheckCoverImage] = useState(false)
  const [isTextTemplateErrorVisible, setIsTextTemplateErrorVisible] = useState(false)
  const [isImageTemplateErrorVisible, setIsImageTemplateErrorVisible] = useState(false)
  const [isVideoTemplateErrorVisible, setIsVideoTemplateErrorVisible] = useState(false)
  const [isStepVisible, setIsStepVisible] = useState(false)
  const [stepValue, setStepValue] = useState(editDetails?.steps ? editDetails?.steps : '')
  const [checkDescError, setCheckDescError] = useState(false)
  const [checkDescValue, setCheckDescValue] = useState(false)
  const [checkDescChanges, setCheckDescChanges] = useState(false)
  const [stepPdf, setStepPdf] = useState(null)
  const [stepPdfName, setStepPdfName] = useState()
  const { showNotification } = useNotificationContext()
  const fileFormats = ['image/png', 'image/jpg']
  const { data: initiativeEventDetails } = useSelector((state) => state?.getAllEvents)
  const [deleteMessage, setDeleteMessage] = React.useState(defaultDeleteMessage)
  const [textFileName, setTextFileName] = useState('')
  const [textFileError, setTextFileError] = useState(null)
  const [isTextFileLoading, setTextFileIsLoading] = useState(false)
  const [csvToJsonData, setCsvToJsonData] = useState([]) //use to delete file data
  const [manualInsetCount, setManualInsetCount] = useState(1) //use to delete file data from that location
  //to keep track of present, so event if we click cancel, we will be able to handle file upload state
  const [isTextFileUpload, setIsTextFileUpload] = useState(false)
  const [languageList, setLanguageList] = useState(apiLanguageList)
  const { setLoading } = useLoading()

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('create_seva_initiatives_form', sqId, data)
  }

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'seva_initiative_page',
      message: message,
    })
  }

  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )

  useEffect(() => {
    if (!editDetails?.stepsPdf?.[0]) {
      setStepPdf(null)
    }
    PdfFileInput.current.value = null
  }, [])
  useEffect(() => {
    if (apiLanguageList) {
      setLanguageList(apiLanguageList)
    }
  }, [apiLanguageList])

  useEffect(() => {
    setDeleteMessage(defaultDeleteMessage)
    if (Array.isArray(initiativeEventDetails) && initiativeEventDetails?.length) {
      setDeleteMessage(
        'Multiple MPs have created events for this initiative. It will be permanently deleted from MP Corner. Are you sure you want to delete this initiative?'
      )
    }
  }, [initiativeEventDetails])

  useEffect(() => {
    if (textData?.length > 0) {
      setEditTextDetails(textData || [])
      setIsTextTemplateErrorVisible(false)
    }
  }, [textData])
  useEffect(() => {
    if (updateValue && imageData.length > 0) {
      const output = imageData[0].map((item) => ({
        typeof_image: item.imageType,
        imageLanguage: item.language,
        url: item.images,
        banner_size: item.bannerSize || '',
      }))
      setImagetemplates(output)
      setUpdateValue(false)
      setIsImageTemplateErrorVisible(false)
    }
  }, [imageData])

  useEffect(() => {
    if (updateVideoValue && videoData.length > 0) {
      const output = videoData[0].map((item) => ({
        language: item.language,
        video: item.videos,
      }))
      setVideoTemplates(output)
      setUpdateVideoValue(false)
      setIsVideoTemplateErrorVisible(false)
    }
  }, [videoData])

  useEffect(() => {
    setIsStepVisible(false)
  }, [stepValue])

  const handleImageDownload = (url, callback) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const fileName = url?.split('/').pop()
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          const file = new File([blob], fileName, { type: blob.type })
          const formattedObject = {
            url: base64data,
            file: file,
          }
          callback(formattedObject)
        }
      })
      .catch((error) => console.error(error))
  }

  const handleVideoDownload = (url, callback) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const fileName = url.split('/').pop()
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
          const base64data = reader.result
          const file = new File([blob], fileName, { type: blob.type })
          const formattedObject = {
            url: base64data,
            file: file,
          }
          callback(formattedObject)
        }
      })
      .catch((error) => console.error(error))
  }

  useEffect(() => {
    if (!createNewClick) {
      setUpload(false)
      let temp = [[]]
      if (imagetemplates?.length > 0 && imageData?.length == 0) {
        imagetemplates?.map((value, i) => {
          let val = {}
          val.imageType = value.typeof_image
          val.language = value.imageLanguage
          let urlTemp = JSON.parse(value.url)
          let urlData = [...urlTemp]
          val.images = urlData
          value.banner_size && (val.bannerSize = value.banner_size)
          temp[0].push(val)
        })
        setUpdateValue(true)
        setImageData(temp)
      }
    }
  }, [editDetails])

  useEffect(() => {
    if (!createNewClick) {
      let temp = [[]]
      if (videoTemplates?.length > 0 && videoData?.length == 0) {
        videoTemplates?.map((value, i) => {
          let val = {}
          val.language = value.language
          let urlTemp = JSON.parse(value.video)
          let urlData = [...urlTemp]
          val.videos = urlData
          temp[0].push(val)
        })
        setUpdateVideoValue(true)
        setVideoData(temp)
      }
    }
  }, [editDetails])
  useEffect(() => {
    dispatch(getUserProfile())
    dispatch(getLanguageList())
    postAuditLogs('pageload')

    if (!id) {
      setPreview('')
      setImageName('')
      setImageData([])
      setTargetDate(null)
      setVideoData([])
      setValue('initiativeName', '')
      setValue('initiativeDetails', '')
      setValue('steps', '')
      setValue('hashTags', '')
      // setOpenUploadImageDialog(true)
    } else {
      dispatch(getSocialMediaIntiativesListById(id))
      dispatch(getOngoingSevaIntiativesListWithInitiativeId(id))
    }
  }, [])

  useEffect(() => {
    if (id && editInitiativeDetails) {
      setEditDetails(editInitiativeDetails)
      const name = editInitiativeDetails?.initiativeName.split('-')
      name?.shift()
      setValue('initiativeName', editInitiativeDetails?.initiativeName)
      setValue('initiativeDetails', editInitiativeDetails?.initiativeDetails)
      setValue('steps', editInitiativeDetails?.steps)
      setEditDetailsName({
        prefix: editInitiativeDetails?.initiativeName,
        text: name,
      })
      setEditTextDetails(editInitiativeDetails?.texttemplates)
      setTextData(editInitiativeDetails?.texttemplates || [])
      let coverImg = editInitiativeDetails?.coverimage?.substring(
        editInitiativeDetails?.coverimage?.lastIndexOf('/') + 1
      )
      let coverImageName = coverImg?.substring(0, coverImg.indexOf('.', 3))
      setImageName(coverImageName)
      setUpload(true)
      setPreview(editInitiativeDetails?.coverimage)
      setTargetDate(editInitiativeDetails?.tagetDate)
      setValue('hashTags', editInitiativeDetails?.hashTags)
      setImagetemplates(editInitiativeDetails?.imagetemplates)
      setVideoTemplates(editInitiativeDetails?.videotemplates)
      if (editInitiativeDetails?.stepsPdf != null && editInitiativeDetails?.stepsPdf.length > 0) {
        setStepPdf(editInitiativeDetails?.stepsPdf[0])
        setStepPdfName(handleFileName(editInitiativeDetails?.stepsPdf[0]))
      }
    }
  }, [editInitiativeDetails])

  const dispatch = useDispatch()
  const { id } = useParams()

  const hiddenFileInput = useRef(null)
  const PdfFileInput = useRef(null)
  const location = useLocation()
  // let id = location ?.state?.id
  let createNewClick = location?.state?.create
  const handleClick = async (event, postLog) => {
    hiddenFileInput.current?.click()
    // setOnEditBrowse(createNewClick ? false : true);
    event?.stopPropagation()
    if (postLog) await postAuditLogs('browse')
  }
  const handlePdfClick = async (event) => {
    if ((typeof stepPdf == 'string' && stepPdf?.length > 0) || (typeof stepPdf != 'string' && stepPdf != null)) {
      handlePdfClickOpen(stepPdf)
    } else {
      PdfFileInput.current.value = ''
      PdfFileInput.current?.click()
      event?.stopPropagation()
    }
  }
  useEffect(() => {
    if (createNewClick) {
      setEditDetails({})
      setEditTextDetails([])
      setCheckDetails(true)
      setVideoData([])
      setImageData([])
      setTextData([])
      setStepPdf(null)
    }
  }, [createNewClick])

  const onFileUpload = (event) => {
    const fileName = event.target.files[0].name
    const fileExtension = fileName.split('.').pop().toLowerCase()
    // Check if the file extension is one of the accepted formats
    const allowedExtensions = [
      'png',
      'jpg',
      'jpeg',
      'jpe',
      'jif',
      'jfif',
      'jfi',
      'tiff',
      'psd',
      'eps',
      'ai',
      'indd',
      'raw',
      'webp',
      'svg',
      'svgz',
      'heif',
    ]
    if (!allowedExtensions.includes(fileExtension)) {
      showNotification(
        'Error',
        'Please upload image in .jpg, .jpeg, .jpe, .jif, .jfi, .png, .tiff, .psd, .eps, .ai, .indd, .raw, .jfif, .webp, .svg, .svgz, .heif format only',
        'error'
      )
      return
    }
    let fsize = Math.round(event.target.files[0].size / 1024)
    if (fsize > 20 * 1024) {
      showNotification('Error', 'Please upload image with size less than 20MB', 'error')
      return
    }
    setImage(event.target.files[0])
    setUpload(true)
    setPreview(URL.createObjectURL(event.target.files[0]))
    setImageName(event.target.files[0].name)
    // handleCloseUploadImageDialog();
    setOnEditBrowse(createNewClick ? false : true)
  }
  // const formOptions = { resolver: yupResolver(createInitiativeSchema) };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    resetField,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (checkWarningClick) {
      handleCloseWarningDialog(true)
      setCheckWarningClick(false)
      switch (dialogIsFor) {
        case enumForWarningDialog.text:
          setAddTextExpand(false)
          setOpenTextTemplateDialog(false)
          if (isTextFileUpload) {
            setTextFileName('')
            setCsvToJsonData([])
          }
          break
        case enumForWarningDialog.image:
          setAddImageExpand(false)
          setOpenImageTemplateDialog(false)
          break
        case enumForWarningDialog.video:
          setAddVideoExpand(false)
          setOpenVideoTemplateDialog(false)
          break
        default:
          break
      }
    }
  }, [checkWarningClick])
  // const [rows,setRows]=useState()
  // const updateMp = (idList) => {
  //     let output= assignMpList?.mpAssignData.filter(val=>{idList.includes(val.id)})
  //     // setPersonalDetail({...personalDetail,[key]:value})
  //   }
  const handleOpenTextTemplateDialog = () => {
    setOpenTextTemplateDialog(true)
  }

  // const handleClickOpenWarningDialog = () => {
  //   setOpenWarningDialog(true);
  // }

  const handleCloseWarningDialog = () => setOpenWarningDialog(false)

  const handleCloseTextTemplateDialog = (warningDialog, isCrossClicked) => {
    if (warningDialog) {
      setOpenWarningDialog(true)
      setDialogIsFor(enumForWarningDialog.text)
    } else {
      if (isCrossClicked) {
        setAddTextExpand(false)
      }
      setOpenTextTemplateDialog(false)
    }
  }

  const handleOpenImageTemplateDialog = () => {
    setOpenImageTemplateDialog(true)
  }

  const handleCloseImageTemplateDialog = (warningDialog, isCrossClicked) => {
    if (warningDialog) {
      setOpenWarningDialog(true)
      setDialogIsFor(enumForWarningDialog.image)
    } else {
      if (isCrossClicked) {
        setAddImageExpand(false)
      }
      setOpenImageTemplateDialog(false)
    }
  }

  const handleOpenVideosTemplateDialog = () => {
    setOpenVideoTemplateDialog(true)
  }

  const handleCloseVideosTemplateDialog = (warningDialog, isCrossClicked) => {
    if (warningDialog) {
      setOpenWarningDialog(true)
      setDialogIsFor(enumForWarningDialog.video)
    } else {
      if (isCrossClicked) {
        setAddVideoExpand(false)
      }
      setOpenVideoTemplateDialog(false)
    }
  }

  const handleCloseUploadImageDialog = () => {
    setOpenUploadImageDialog(false)
  }

  const handleOpenUploadImageDialog = () => {
    setOpenUploadImageDialog(true)
  }

  const handleClickOpenDeleteDialog = async () => {
    await postAuditLogs('delete', { i_id: id })
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const addCoverImageToFormData = async (formData, coverImage) => {
    const response = await fetch(coverImage)
    const blob = await response.blob()
    const file = new File([blob], `image.jpg`, { type: blob.type })
    formData.append(`coverimage1`, file, file.name)
  }

  const handleFormErrors = (errors) => {
    // console.log(stepValue,"eewwwwwwww",stepPdf?.name)
    // Check Social Media Kit errors
    if (stepValue?.length < 1 && stepPdfName == null) {
      setIsStepVisible(true)
    }
    if (textData?.length < 1) {
      setIsTextTemplateErrorVisible(true)
    }
    if (imageData?.length < 1) {
      setIsImageTemplateErrorVisible(true)
    }
    if (videoData?.length < 1) {
      setIsVideoTemplateErrorVisible(true)
    }
    if (stepValue?.length > 0 || !(stepPdfName == null)) {
      setIsStepVisible(false)
    }
    return (
      textData?.length < 1 ||
      imageData?.length < 1 ||
      videoData?.length < 1 ||
      (stepValue?.length < 1 && stepPdfName == null)
    )
  }

  const handleOpenAssingToMPDialog = async (data) => {
    console.log('data', data)
    await postAuditLogs('assign', { i_id: id })
    if (handleFormErrors(null)) return
    if (!imageName) return
    setLoading(true)
    const formData = new FormData()
    const textTemplates = csvToJsonData?.length ? [...textData] : textData

    let uploadImageTemplates = imageData[0]?.map(async (value, i) => {
      const existingUrls = []
      const filesObj = {
        imageFiles: value?.images?.map((val) => {
          if (val.toString().startsWith('http')) {
            existingUrls.push(val)
            return
          }
          return val.file
        }),
      }
      // todo need to remove undefined body api calls
      const urls = filesObj?.imageFiles?.length > 0 ? await uploadMedia(dispatch, filesObj) : { imageUrls: [] }
      const obj = {
        url: [...urls?.imageUrls, ...existingUrls],
        language: value.language,
        type: value.imageType,
      }
      if (value?.bannerSize) {
        obj.size = value.bannerSize
      }
      return obj
    })
    const imageTemplates = uploadImageTemplates ? await Promise.all(uploadImageTemplates || new Promise()) : []

    const uploadVideoTemplates = videoData[0]?.map(async (value, i) => {
      const existingUrls = []
      const filesObj = {
        videoFiles: value?.videos?.map((val) => {
          if (val.toString().startsWith('http')) {
            existingUrls.push(val)
            return
          }
          return val.file
        }),
      }
      // todo need to remove undefined body api calls
      const urls = filesObj?.videoFiles?.length > 0 ? await uploadFile(filesObj) : { videoUrls: [] }
      return {
        url: [...urls?.videoUrls, ...existingUrls],
        name: 'media',
        language: value.language,
      }
    })
    let videoTemplates = uploadVideoTemplates ? await Promise.all(uploadVideoTemplates || new Promise()) : []
    if (videoTemplates.length > 0) {
      videoTemplates = videoTemplates.filter((item) => {
        return item.url.length > 0
      })
    }

    let urls = image
    if (image) {
      urls = await uploadMedia(dispatch, {
        coverImage: image?.file ? image.file : image,
      })
    } else {
      urls = {
        coverImageUrl: JSON.parse(editInitiativeDetails?.coverimage)[0] || '',
      }
    }

    let stepurl = stepPdf ? stepPdf : null
    if (stepPdf && stepPdf?.size) {
      let { stepPdfUrl } = await uploadMedia(dispatch, { stepPdf: stepPdf })
      stepurl = stepPdfUrl
    } else if (stepPdf && id) {
      stepurl = editDetails?.stepsPdf[0]
    } else stepurl = null
    const payload = {
      initiativeName: data?.initiativeName,
      initiativeDetails: data?.initiativeDetails,
      hashTags: data?.hashTags,
      targetDate: targetedDate,
      steps: data?.steps?.trim()?.length > 0 ? data?.steps?.trim() : '',
      stepsPdf: stepurl != null ? [stepurl] : [],
      coverimage: [urls?.coverImageUrl] || [''],
      textTemplates: textTemplates || [],
      imageTemplates: imageTemplates,
      videoTemplates: videoTemplates,
    }

    setLoading(false)
    setIntiative(payload)
    setOpenAssingToMPDialog(true)
  }

  const handleCloseAssingToMPDialog = () => {
    setOpenAssingToMPDialog(false)
  }

  // const onCreateInitiativeSubmit = (data) => {

  // }

  // function createData(id, language, text, hashtags) {
  //     return { id, language, text, hashtags };
  // }

  // const rows = [
  //     createData(1, 'English', "I am organizing a blood donation camp", "#DonateBlood"),
  //     createData(2, 'Hindi', "This is the new text", "#DonateBlood"),
  // ];

  const dateConvert = (dateStr) => {
    if (dateStr === null) return ''
    const date = new Date(dateStr)
    let month = date.getMonth() + 1 // getMonth() returns 0-indexed month, so add 1
    let day = date.getDate()
    let year = date.getFullYear()
    // month= && "0"+month;
    // day=day.length==1 && "0"+day;
    const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
    return formattedDate
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F5F6FA',
      color: '#2e739c',
      fontWeight: 'bold',
      fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      fontWeight: 'bold',
      backgroundColor: '#fff',
      color: '#505050',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))
  // const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  //     <Tooltip {...props} arrow classes={{ popper: className }} />
  //   ))(({ theme }) => ({
  //     [`& .${tooltipClasses.arrow}`]: {
  //       color: theme.palette.common.black,
  //     },
  //     [`& .${tooltipClasses.tooltip}`]: {
  //       backgroundColor: theme.palette.common.black,
  //     },
  //   }));
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #356F92',
      },
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: '1px solid #356F92',
      boxShadow: theme.shadows[1],
      width: 370,
      maxWidth: '370px',
    },
  }))

  const formatDate = (value) => {
    let splittedDate = value.split('/')
    return `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`
  }

  const validateDate = (value, fieldName, useValue) => {
    const parts = value.split('/')
    if (parts.length === 3) {
      const year = parts[2]
      if (year > 2099) {
        return 'Please enter a valid date'
      }
    }
    const regex = new RegExp('^[a-zA-Z][a-zA-Z]*')
    const inputDate = new Date(useValue ? formatDate(value) : targetedDate)
    const currentDate = new Date()
    if (inputDate <= currentDate || regex.test(targetedDate)) {
      return 'Please enter a valid date'
    }
    return null
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  const handleInitiativeNameChange = (event) => {
    const inputValue = event.target.value?.trimStart()
    setValue('initiativeName', inputValue)
    if (inputValue.length < 10 && inputValue.length > 0) {
      setError('initiativeName', {
        type: 'minLength',
        message: 'Minimum character length is 10',
      })
    } else if (inputValue.length === 0) {
      setError('initiativeName', {
        type: 'value',
        message: 'Please enter Initiative Name',
      })
    } else if (inputValue.length > 299) {
      setError('initiativeName', {
        type: 'maxLength',
        message: 'Maximum character length is 300',
      })
    } else {
      clearErrors('initiativeName')
    }
  }

  return (
    <>
      <Box className="page-wrapper1 d-flex">
        <SideMenu active="SevaInitiative" user="Admin" createInitiative={true} />
        {/* <SideMenu active="SevaInitiative" user="Admin" /> */}

        {/* <Grid item xs={4} md={2} lg={2} xl={2}  >



            </Grid> */}

        <Grid item xs={8} md={10} lg={10} xl={10} className={'main-wrapper customscroll gridwid'}>
          <Box className="d-flex justify-content-between align-items-center seva-initiative-st">
            <Typography component="h1" className="seva-initiative-h1">
              {' '}
              Seva Initiatives
            </Typography>
            {/* <div className="search-filter-icon-admin d-flex-admin">
                        <div style={{ position: "relative", left: "90%" }}>

                            <img className="searchIcon" width={20} height={21} src={searchIcon} />

                        </div>
                    </div> */}
          </Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <form>
              <Card
                sx={{
                  fontFamily: 'HK Grotesk',
                  width: '90%',
                  margin: '0 auto',
                  padding: '45px',
                  borderRadius: '20px',
                }}
              >
                <Grid item xs={10} md={10} lg={10} xl={10} sx={{ fontFamily: 'HK Grotesk' }}>
                  <Typography variant="h1" className="initiative-st">
                    {' '}
                    {id ? 'Edit Initiative' : 'Create Initiative'}
                  </Typography>
                  <FormControl variant="standard" sx={{ marginTop: '25px' }}>
                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#2e739c' }}>
                      <Typography className="headincreateini initiative-titles-st">Initiative Name</Typography>
                    </InputLabel>
                    <BootstrapInput
                      id="bootstrap-input"
                      inputProps={{
                        maxLength: 300,
                      }}
                      defaultValue={id ? editDetailsName?.text : ''}
                      {...register('initiativeName', {
                        required: 'Please enter Initiative Name',
                        maxLength: {
                          value: 300,
                          message: 'Maximum character length is 300',
                        },
                        minLength: {
                          value: 10,
                          message: 'Minimum character length is 10',
                        },
                        // validate: (value) => validateNotEmpty(value, 'Initiative Name'),
                      })}
                      onChange={handleInitiativeNameChange}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: '#d32f2f', fontWeight: '700' }}>
                    {errors && errors?.initiativeName?.message}
                  </FormHelperText>
                </Grid>

                <Grid item xs={10} md={10} lg={10} xl={10}>
                  <FormControl variant="standard" sx={{ marginTop: '25px' }}>
                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#2e739c' }}>
                      <Typography className="headincreateini initiative-titles-st">Initiative Details</Typography>
                    </InputLabel>
                    <BootstrapInput
                      id="bootstrap-input"
                      placeholder="Initiative Details"
                      className="details-inp-st"
                      defaultValue={id ? editDetails?.initiativeDetails : ''}
                      inputProps={{
                        minLength: 10,
                      }}
                      multiline
                      rows={2}
                      {...register('initiativeDetails', {
                        required: 'Please enter Initiative Details',
                        maxLength: {
                          value: 5000,
                          message: 'Maximum 5000 characters allowed',
                        },
                        minLength: {
                          value: 10,
                          message: 'Minimum character length is 10',
                        },
                        validate: (value) => validateNotEmpty(value, 'Initiative Details'),
                      })}
                      onChange={(e) => {
                        if (e.target.value !== '') {
                          setCheckDescValue(true)
                        } else {
                          setCheckDescValue(false)
                        }
                        if (e.target.value.length > 5000) {
                          setCheckDescValue(false)
                          setCheckDescError(true)
                          setCheckDescChanges(true)
                        } else {
                          setCheckDescError(false)
                          setCheckDescChanges(false)
                        }
                      }}
                      onPaste={(e) => {
                        const pastedText = e.clipboardData.getData('text')
                        if (pastedText.length + e.target.value.length > 5000) {
                          e.preventDefault()
                          const truncatedText = pastedText.substring(0, 5000 - e.target.value.length)
                          document.execCommand('insertText', false, truncatedText)
                          // setCheckDescError(true);
                        }
                      }}
                    />
                  </FormControl>
                  <FormHelperText
                    sx={{
                      color: checkDescValue ? 'blue !important' : '#d32f2f !important',
                      fontWeight: '700 !important',
                    }}
                  >
                    {checkDescError || checkDescValue
                      ? 'Maximum 5000 characters allowed'
                      : errors?.initiativeDetails?.message}
                  </FormHelperText>
                </Grid>

                <Grid item xs={10} md={10} lg={10} xl={10} sx={{ marginTop: '25px' }}>
                  <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#2e739c' }}>
                    <Typography className="headincreateini initiative-titles-st">
                      Upload Initiative Cover Image
                    </Typography>
                  </InputLabel>
                  <BootstrapTooltip
                    title="Accepted file formats for open & zip uploads : .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .tiff, .psd, .eps, .ai, .indd, .raw, .webp, .svg, .svgz, .heif"
                    placement="right-start"
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: '18px',
                          fontFamily: 'HK Grotesk',
                          padding: '15px',
                        },
                      },
                    }}
                    className="mg-t-1"
                  >
                    <Box className="cls-input-sty texcretei" sx={{ width: '421px' }}>
                      <input
                        type="file"
                        accept={acceptedImageFormatsForInitiativeCover}
                        ref={hiddenFileInput}
                        className="in-st-div"
                        onChange={onFileUpload}
                        required
                      />
                      <Box className="div-pre">
                        <Button
                          onClick={(e) => {
                            if (!preview) {
                              sendGALog('browse')
                              handleClick(e, true)
                            } else {
                              handleOpenUploadImageDialog()
                            }
                          }}
                          className="div-br-pr"
                        >
                          {preview ? 'Preview' : 'Browse'}
                        </Button>
                        {imageName ? (
                          <Typography
                            sx={{ display: 'block', fontSize: '14px' }}
                            className="ellipsewehe"
                            onClick={handleOpenUploadImageDialog}
                          >
                            {imageName}
                          </Typography>
                        ) : null}
                      </Box>
                      <FormHelperText sx={{ color: '#d32f2f', marginTop: '6px', fontWeight: '700' }}>
                        {checkCoverImage && !imageName && 'Please upload a cover image'}
                      </FormHelperText>
                    </Box>
                  </BootstrapTooltip>
                </Grid>

                <Box className="hash-date-div">
                  <Grid item xs={10} md={10} lg={10} xl={10} sx={{ marginTop: '30px' }}>
                    <FormControl variant="standard" sx={{ width: '180px' }}>
                      <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#2e739c' }}>
                        <Typography className="headincreateini initiative-titles-st">Event Hashtag</Typography>
                      </InputLabel>
                      <BootstrapInput1
                        id="bootstrap-input"
                        defaultValue={id ? editDetails?.hashTags : ''}
                        inputProps={{
                          maxLength: 280,
                          minLength: 5,
                        }}
                        onKeyDown={handleKeyDown}
                        {...register('hashTags', {
                          required: 'Please enter a hashtag',
                          maxLength: {
                            value: 280,
                            message: 'Max character length is 280',
                          },
                          minLength: {
                            value: 5,
                            message: 'Min character length is 5',
                          },
                          validate: (value) => validateNotEmpty(value, 'hashtag'),
                        })}
                      />
                      <FormHelperText sx={{ color: '#d32f2f', fontWeight: '700' }}>
                        {errors && errors?.hashTags?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={10} md={10} lg={10} xl={10} sx={{ marginTop: '30px', marginLeft: '35px' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#2e739c', marginBottom: '0px' }}>
                        <Typography className="headincreateini initiative-titles-st" sx={{ lineHeight: '1.2' }}>
                          Target Date
                        </Typography>
                      </InputLabel>
                      <DatePicker
                        components={{
                          OpenPickerIcon: CalendarMonthIcon,
                        }}
                        InputProps={{
                          disableUnderline: true,
                          sx: {
                            '& .MuiButtonBase-root': {
                              left: '33px',
                            },
                            '& .MuiSvgIcon-root': {
                              color: '#fff',
                              background: '#356f92',
                              width: '30px',
                              height: '30px',
                              padding: '5px',
                              borderRadius: '5px',
                              mt: '2px',
                            },
                          },
                        }}
                        {...register('targetDate', {
                          required: !targetedDate ? 'Please choose a target date' : false,
                        })}
                        defaultValue={id ? editDetails?.tagetDate : ''}
                        inputFormat="DD/MM/YYYY"
                        value={targetedDate}
                        minDate={new Date(Date.now() + 86400000)}
                        className="cls-input-sty-2"
                        sx={{
                          fontFamily: 'HK Grotesk',
                          backgroundColor: 'red',
                        }}
                        onChange={(newValue) => {
                          setTargetDate(dateConvert(newValue))
                          resetField('targetDate')
                        }}
                        renderInput={(params) => (
                          <FormControl
                            sx={{
                              fontFamily: 'HK Grotesk',
                              backgroundColor: '#fcfcfb',
                              width: '182px',
                            }}
                          >
                            {/* <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: "#2e739c" }}>
                                                            <b>Event</b>
                                                        </InputLabel> */}
                            <TextField
                              variant="standard"
                              value={watch('targetDate')}
                              sx={{
                                '& .MuiInputBase-input': {
                                  width: '100px', // Set your height here.
                                  marginTop: '5px',
                                  marginLeft: '10px',
                                },
                              }}
                              {...register('targetDate', {
                                required: !targetedDate ? 'Please choose a target date' : false,
                                validate: (value) => validateDate(value, 'targetDate'),
                              })}
                              tooltip={false}
                              {...params}
                              onKeyUp={(e) => {
                                if (e.target.value?.length === 10) {
                                  let validationResult = validateDate(e.target.value, 'targetDate', true)
                                  let error =
                                    validationResult === null
                                      ? null
                                      : {
                                          type: 'validate',
                                          message: validationResult,
                                        }
                                  setError('targetDate', error)
                                }
                              }}
                              error={
                                // !targetedDate &&
                                Boolean(errors?.targetDate?.message)
                              }
                              // {...register("targetDate", { required: true })}
                            />
                          </FormControl>
                        )}
                      />
                      <FormHelperText sx={{ color: '#d32f2f', fontWeight: '700' }}>
                        {errors && errors?.targetDate?.message}
                      </FormHelperText>
                    </LocalizationProvider>
                  </Grid>
                </Box>
                <Grid item xs={10} md={10} lg={10} xl={10} sx={{ marginTop: '25px', display: 'flex' }}>
                  <FormControl sx={{ flexDirection: 'column' }} variant="standard">
                    <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#2e739c' }}>
                      <Typography className="headincreateini initiative-titles-st">Steps</Typography>
                    </InputLabel>
                    <BootstrapInput
                      id="bootstrap-input"
                      defaultValue={id ? editDetails?.steps : ''}
                      className="step-pad"
                      multiline
                      rows={3}
                      inputProps={{
                        maxLength: 1000,
                      }}
                      {...register('steps', {
                        maxLength: {
                          value: 1000,
                          message: 'Maximum character length is 1000',
                        },
                        minLength: {
                          value: 10,
                          message: 'Minimum character length is 10',
                        },
                        // validate: (value) => validateNotEmpty(value, "Steps"),
                      })}
                      onChange={(e) => {
                        setStepValue(e.target.value?.trim())
                      }}
                    />
                    <Grid container>
                      <Grid item xs={8}>
                        <FormHelperText sx={{ color: '#d32f2f', fontWeight: '1000' }}>
                          {isStepVisible && 'Please Enter Steps or PDF'}
                          {errors && !isStepVisible && errors?.steps?.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography component="span" className="string-st">
                          (Max length 1000)
                        </Typography>
                      </Grid>
                    </Grid>
                  </FormControl>
                  <Typography component="span" className="orClass">
                    {' '}
                    Or{' '}
                  </Typography>
                  <FormControl
                    sx={{
                      maxWidth: '240px',
                      justifyContent: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <input
                      ref={PdfFileInput}
                      className="in-st-div"
                      type="file"
                      accept=".pdf"
                      onChange={(e) => {
                        if (onPdfFileUpload(e, setStepPdf, showNotification)) {
                          setStepPdfName(e.target.files[0].name)
                        }
                        setUpload(true)
                      }}
                    />
                    <Button
                      onClick={(e) => {
                        handlePdfClick(e)
                      }}
                      className="pdfButton"
                    >
                      <Typography className="uploadPdfClass">
                        <img src={pdf} alt="pdf" width="20px" />
                      </Typography>
                      {stepPdfName ? (
                        <>
                          <Typography className="pdfEllipse" onClick={(e) => handlePdfClick(e)}>
                            {stepPdfName}
                          </Typography>
                          <img
                            onClick={(e) => {
                              e?.stopPropagation()
                              PdfFileInput.current.value = ''
                              setStepPdf(null)
                              setStepPdfName(null)
                            }}
                            src={CrossIcon}
                            className="pdfCloseIcon"
                          />
                        </>
                      ) : (
                        <Typography component="span" className="pdfEllipse" sx={{ fontFamily: 'HK Grotesk' }}>
                          Upload a PDF File
                        </Typography>
                      )}
                    </Button>
                    <Grid item xs={4} className="mt-3">
                      <Typography component="span" className="opt-span-st">
                        Optimal Upload size: 50MB
                      </Typography>
                    </Grid>
                  </FormControl>
                </Grid>

                <Grid item xs={10} md={10} lg={10} xl={10} sx={{ mt: 2 }}>
                  <Typography variant="h1" className="createKitText" sx={{ lineHeight: '1.2', marginBottom: '0.5rem' }}>
                    {' '}
                    Create Social Media Kit
                  </Typography>
                  <Accordion
                    className="bradiusaccord"
                    sx={{
                      backgroundColor: '#E3F5FF',
                      mb: 2,
                      fontFamily: 'HK Grotesk',
                      mt: 2,
                      border: '0',
                      color: '#505050',
                      '&:before': {
                        display: 'none',
                      },
                    }}
                    onClick={
                      checkTextDetails && textData[0] === undefined
                        ? handleOpenTextTemplateDialog
                        : editDetails && editDetails?.texttemplates?.length === 0
                        ? handleOpenTextTemplateDialog
                        : undefined
                    }
                    expanded={addTextExpand}
                  >
                    <AccordionSummary
                      expandIcon={addTextExpand ? <RemoveIcon color="primary" /> : <AddIcon color="primary" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      onClick={async () => {
                        await postAuditLogs('add_text_template')
                        sendGALog('add_template')
                        setAddTextExpand(!addTextExpand)
                      }}
                    >
                      <Typography sx={{ fontFamily: 'HK Grotesk', fontWeight: 'bold' }}>
                        Add Text Templates
                        <BootstrapTooltip
                          title="Create share text or upload CSV files of share texts"
                          placement="right"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontSize: '18px',
                                fontFamily: 'HK Grotesk',
                                padding: '15px',
                              },
                            },
                          }}
                        >
                          <img src={infoic} className="tooltip-div-st" />
                          {/* <ErrorOutlineIcon sx={{ ml: 2, color: "#387194" }} /> */}
                        </BootstrapTooltip>
                        {isTextTemplateErrorVisible && (
                          <Typography component="span" className="error-msg1">
                            Please add text templates
                          </Typography>
                        )}
                      </Typography>
                    </AccordionSummary>
                    {textData?.length > 0 || (editDetails && editDetails?.texttemplates?.length > 0) ? (
                      <AccordionDetails className="acc-st">
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 810, tableLayout: 'fixed' }} aria-label="customized table">
                            <TableHead>
                              <StyledTableCell sx={{ fontFamily: 'HK Grotesk' }}>Language</StyledTableCell>
                              <StyledTableCell sx={{ fontFamily: 'HK Grotesk' }}>Text</StyledTableCell>
                              <StyledTableCell sx={{ fontFamily: 'HK Grotesk' }}>Hashtags</StyledTableCell>
                            </TableHead>
                            <TableBody>
                              {textData?.map((row, index) => (
                                <StyledTableRow key={`${row?.id}_${index}`}>
                                  <StyledTableCell component="th" scope="row">
                                    {row?.language ? row?.language : row?.language}
                                  </StyledTableCell>
                                  <StyledTableCell title={row?.text ? row.text : ''}>
                                    {typeof row?.text === 'string' ? row?.text?.slice(0, 25) || '-' : row?.text}
                                    {typeof row?.text === 'string'
                                      ? row?.text && row?.text?.length > 25
                                        ? '...'
                                        : ''
                                      : row?.text}
                                  </StyledTableCell>
                                  <StyledTableCell className="hashtagEllipse" title={row?.hashtag ? row.hashtag : ''}>
                                    {row?.hashtag}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                              <Button
                                variant="outlined"
                                className="button-tr-citizen-admin"
                                startIcon={<AddIcon sx={{ mt: '5px' }} />}
                                sx={{
                                  mt: 1,
                                  ml: 1,
                                  mb: 1,
                                  fontFamily: 'HK Grotesk',
                                }}
                                onClick={async () => {
                                  await postAuditLogs('edit_text_template')
                                  handleOpenTextTemplateDialog()
                                }}
                              >
                                Edit
                              </Button>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    ) : null}
                  </Accordion>
                  <Accordion
                    className="bradiusaccord"
                    sx={{
                      backgroundColor: '#E3F5FF',
                      mb: 2,
                      fontFamily: 'HK Grotesk',
                      fontSize: '18px',
                      mt: 2,
                      border: '0',
                      '&:before': {
                        display: 'none',
                      },
                      color: '#505050',
                    }}
                    onClick={imageData.length === 0 ? handleOpenImageTemplateDialog : undefined}
                    expanded={addImageExpand}
                  >
                    <AccordionSummary
                      expandIcon={addImageExpand ? <RemoveIcon color="primary" /> : <AddIcon color="primary" />}
                      onClick={async () => {
                        await postAuditLogs('add_images')
                        sendGALog('add_image')
                        setAddImageExpand(!addImageExpand)
                      }}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontFamily: 'HK Grotesk', fontWeight: 'bold' }}>
                        Add Images
                        <BootstrapTooltip
                          title="Accepted file formats for open & zip uploads : .jpg, .jpeg, .jpe, .jif, .jfi, .png, .tiff, .psd, .eps, .ai, .indd, .raw, .jfif, .webp, .svg, .svgz, .heif"
                          placement="right"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontSize: '18px',
                                fontFamily: 'HK Grotesk',
                                padding: '15px',
                              },
                            },
                          }}
                        >
                          <img src={infoic} className="tooltip-div-st" />
                          {/* <ErrorOutlineIcon sx={{ ml: 2, color: "#387194" }} /> */}
                        </BootstrapTooltip>
                        {isImageTemplateErrorVisible && (
                          <Typography component="span" className="error-msg2 ml-5rem">
                            Please add image templates
                          </Typography>
                        )}
                      </Typography>
                    </AccordionSummary>
                    {imageData[0]?.length > 0 && (
                      <AccordionDetails>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{
                              display: 'grid',
                              maxHeight: '450px',
                              overflowX: 'hidden',
                              overflowY: 'auto',
                            }}
                            aria-label="customized table"
                          >
                            {/* <TableHead>
                                                        <StyledTableCell>Language</StyledTableCell>
                                                    </TableHead>
                                                    <TableBody> */}
                            {imageData[0]?.map((item, i) => (
                              <Box className="div-st-im">
                                <Typography component="h6" className="h6-st-1" sx={{ lineHeight: '1.2' }}>
                                  {item.imageType} - {item.language}
                                </Typography>
                                <Box className="create-initiative__image-grid">
                                  {/* {
                                                            item?.videos?.map(video => (
                                                                <video width="80" height="80" controls>
                                                                    <source src={video.url} type="video/mp4" />

                                                                </video>
                                                            ))
                                                        } */}
                                  {item?.images?.map((img, i) =>
                                    //class used for fetching image
                                    ['zip', 'ai'].includes(handleSrc(img)) ? (
                                      <ImageListItem
                                        key={i}
                                        className="bgfolder cursorshow zip-placeholder unsupported-files"
                                        sx={{
                                          margin: '0px',
                                          width: '170px !important',
                                          padding: '0px 33px ',
                                          aspectRatio: '1 / 0.9 !important',
                                        }}
                                      >
                                        <img
                                          className="im-mg unsupported-image"
                                          key={i}
                                          src={handleSrc(img) === 'zip' ? zipIcon : handleSrc(img) == 'ai' && fileIcon} //?w=164&h=164&fit=crop&auto=format
                                          srcSet={
                                            handleSrc(img) === 'zip'
                                              ? zipIcon
                                              : handleSrc(img) == 'ai' && fileIcon && fileIcon
                                          } //?w=164&h=164&fit=crop&auto=format
                                          // alt={item.title}
                                        />
                                        <Typography variant="subtitle1" sx={subtitleStyles}>
                                          {handleFileName(img)}
                                        </Typography>
                                      </ImageListItem>
                                    ) : (
                                      <ImageLoader
                                        key={i}
                                        className={`fetchImage${i}`}
                                        src={handleSrc(img)}
                                        alt=""
                                        width={!item.bannerSize || item.bannerSize == '1:1' ? '160' : '150'}
                                        height={(!item.bannerSize || item.bannerSize == '1:1') && '160'}
                                        defaultImg={unknownImg}
                                        subtitleStyles={subtitleStyles}
                                        item={handleFileName(img)}
                                      />
                                    )
                                  )}
                                </Box>
                                {/* <img src={item['images'][0].url} alt="" width="50" height="50" /> */}
                              </Box>
                            ))}
                            <Button
                              variant="outlined"
                              className="button-tr-citizen-admin"
                              startIcon={<AddIcon sx={{ mt: '5px' }} />}
                              sx={{
                                mt: 1,
                                ml: 1,
                                mb: 1,
                                marginRight: '15px',
                                fontFamily: 'HK Grotesk',
                              }}
                              onClick={async () => {
                                await postAuditLogs('edit_image')
                                handleOpenImageTemplateDialog()
                              }}
                            >
                              Edit
                            </Button>
                            {/* </TableBody> */}
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    )}
                  </Accordion>
                  <Accordion
                    className="bradiusaccord"
                    sx={{
                      backgroundColor: '#E3F5FF',
                      fontFamily: 'HK Grotesk',
                      mt: 2,
                      border: '0',
                      '&:before': {
                        display: 'none',
                      },
                      color: '#505050',
                    }}
                    onClick={videoData.length === 0 ? handleOpenVideosTemplateDialog : undefined}
                    expanded={addVideoExpand}
                  >
                    <AccordionSummary
                      expandIcon={addVideoExpand ? <RemoveIcon color="primary" /> : <AddIcon color="primary" />}
                      onClick={async () => {
                        await postAuditLogs('add_videos')
                        sendGALog('add_videos')
                        setAddVideoExpand(!addVideoExpand)
                      }}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography sx={{ fontFamily: 'HK Grotesk', fontWeight: 'bold' }}>
                        Add Videos
                        <BootstrapTooltip
                          title="Accepted file formats for open & zip uploads: .mp4, .mov, .wmv, .webm, .avi, .flv, .mkv, .mts"
                          placement="right"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                fontSize: '18px',
                                fontFamily: 'HK Grotesk',
                                padding: '15px',
                              },
                            },
                          }}
                        >
                          <img src={infoic} className="tooltip-div-st" />
                        </BootstrapTooltip>
                        {isVideoTemplateErrorVisible && (
                          <Typography component="span" className="error-msg ml-5rem">
                            Please add video templates
                          </Typography>
                        )}
                      </Typography>
                    </AccordionSummary>
                    {videoData[0]?.length > 0 && (
                      <AccordionDetails>
                        <TableContainer className="video-main-Container" component={Paper}>
                          <Table
                            sx={{
                              display: 'grid',
                              maxHeight: '450px',
                              overflowX: 'auto',
                              overflowY: 'auto',
                              minWidth: 810,
                              fontFamily: 'HK Grotesk',
                            }}
                            aria-label="customized table"
                          >
                            {/* <TableHead>
                                                        <StyledTableCell>Language</StyledTableCell>
                                                    </TableHead>
                                                    <TableBody> */}
                            {videoData[0]?.map((item, i) => (
                              // <StyledTableRow key={row.id}>
                              //     <StyledTableCell component="th" scope="row">
                              //         {row.language}
                              //     </StyledTableCell>
                              //     <StyledTableCell title={row.text ? row.text : ""} >{(row.text &&
                              //         row.text.slice(0, 25)) ||
                              //         "-"}
                              //         {row.text &&
                              //             row.text.length > 25
                              //             ? "..."
                              //             : ""}{" "}</StyledTableCell>
                              //     <StyledTableCell >{row.hashtags}</StyledTableCell>
                              // </StyledTableRow>
                              <Box className="div-st-im">
                                <Typography component="h6" className="h6-st-1" sx={{ lineHeight: '1.2' }}>
                                  {item.language}
                                </Typography>
                                <Box className="itemfixed4 ">
                                  {item?.videos?.map((video) => (
                                    <Box>
                                      {handleVideoSrc(video) === 'video' && (
                                        <VideoPlayer
                                          width="300"
                                          height="200"
                                          top="37%"
                                          className={`fetchVideo${i}`}
                                          src={
                                            video?.url || video?.url === '' ? URL.createObjectURL(video.file) : video
                                          }
                                          controls="play"
                                          addVideoExpandState={addVideoExpand}
                                        />
                                      )}
                                      {['ai', 'mts', 'zip', 'unknown'].includes(handleVideoSrc(video)) && (
                                        <ImageListItem
                                          key={i}
                                          className="bgfolder1 cursorshow "
                                          sx={{
                                            marginLeft: '45px !important',
                                            width: '200px !important',
                                            padding: '0px 25px ',

                                            aspectRatio: '1 / 0.9 !important',
                                            textAlign: '-webkit-center',
                                          }}
                                        >
                                          <img
                                            width="120"
                                            height="120"
                                            className={`fetchVideo${i} video-hn`}
                                            src={
                                              handleVideoSrc(video) === 'ai'
                                                ? fileIcon
                                                : handleVideoSrc(video) === 'zip'
                                                ? zipIcon
                                                : unknownImg
                                            }
                                          />
                                          <Typography variant="subtitle1" sx={subtitleStyles}>
                                            {handleFileName(video)}
                                          </Typography>
                                        </ImageListItem>
                                      )}
                                    </Box>
                                    // <video
                                    //
                                    //   controls
                                    //   className={`fetchVideo${i}`}
                                    // >
                                    //   <source
                                    //     src={video.url ? video.url : video}
                                    //     type="video/mp4"
                                    //   />
                                    // </video>
                                  ))}
                                </Box>
                                {/* <img src={item['videos'][0].url} alt="" width="50" height="50" /> */}
                              </Box>
                            ))}
                            <Button
                              variant="outlined"
                              className="button-tr-citizen-admin"
                              startIcon={<AddIcon sx={{ mt: '5px' }} />}
                              sx={{
                                mt: 1,
                                ml: 1,
                                mb: 1,
                                marginRight: '15px',
                                fontFamily: 'HK Grotesk',
                              }}
                              onClick={async () => {
                                await postAuditLogs('edit_video')
                                handleOpenVideosTemplateDialog()
                              }}
                            >
                              Edit
                            </Button>
                            {/* </TableBody> */}
                          </Table>
                        </TableContainer>
                      </AccordionDetails>
                    )}
                  </Accordion>
                </Grid>
                <Grid item xs={10} md={10} lg={10} xl={10}>
                  <Button
                    variant="contained"
                    onClick={handleSubmit(handleOpenAssingToMPDialog, handleFormErrors)}
                    onMouseUp={() => (setCheckCoverImage(true), setCheckDescValue(false))}
                    className={checkDescError || checkDescChanges ? 'button-tr-3' : 'button-tr-2'}
                    sx={{
                      fontFamily: 'HK Grotesk',
                      mt: '24px!important',
                      fontSize: '18px!important',
                      height: '35px',
                    }}
                    disabled={(checkDescChanges || checkDescError) && true}
                  >
                    Assign
                  </Button>
                  {id && (
                    <img
                      src={imagedelete}
                      onClick={handleClickOpenDeleteDialog}
                      className="deleteimgcss1 cursorshow"
                      alt="delete"
                    />
                  )}
                  {openTextTemplateDialog && (
                    <AddTextTemplates
                      openTextTemplateDialog={openTextTemplateDialog}
                      handleCloseTextTemplateDialog={handleCloseTextTemplateDialog}
                      setText={setTextData}
                      languages={languageList}
                      // editTextTemplateDetails={editDetails}
                      createNewClick={createNewClick}
                      editTextTemplateDetails={editTextDetails}
                      setTextFileName={setTextFileName}
                      textFileName={textFileName}
                      setTextFileError={setTextFileError}
                      textFileError={textFileError}
                      setTextFileIsLoading={setTextFileIsLoading}
                      isTextFileLoading={isTextFileLoading}
                      setCsvToJsonData={setCsvToJsonData}
                      csvToJsonData={csvToJsonData}
                      setManualInsetCount={setManualInsetCount}
                      manualInsetCount={manualInsetCount}
                      setIsTextFileUpload={setIsTextFileUpload}
                      isFileUploaded={isTextFileUpload}
                    />
                  )}
                  {openAssingToMPDialog && (
                    <AssignToMP
                      handleCloseAssingToMPDialog={handleCloseAssingToMPDialog}
                      openAssingToMPDialog={openAssingToMPDialog}
                      intiativeData={intiative}
                      image={image}
                      id={id}
                      selectedMP={MPList}
                      createNewClick={createNewClick}
                      eventList={eventList}
                    />
                  )}
                  {openImageTemplateDialog && (
                    <AddImageTemplates
                      handleCloseImageTemplateDialog={handleCloseImageTemplateDialog}
                      openImageTemplateDialog={openImageTemplateDialog}
                      setImageData={setImageData}
                      languages={languageList}
                      imageData={imageData}
                      editDetails={imageData.length > 0 && imagetemplates}
                      createNewClick={createNewClick}
                      setUpdateValue={setUpdateValue}
                    />
                  )}
                  {openVideoTemplateDialog && (
                    <AddVideoTemplate
                      handleCloseVideosTemplateDialog={handleCloseVideosTemplateDialog}
                      openVideoTemplateDialog={openVideoTemplateDialog}
                      languages={languageList}
                      editDetails={videoData.length > 0 && videoTemplates}
                      setVideoData={setVideoData}
                      setUpdateVideoValue={setUpdateVideoValue}
                    />
                  )}
                  {openDeleteDialog && (
                    <DeleteInitiativeDialog
                      handleCloseDeleteDialog={handleCloseDeleteDialog}
                      openDeleteDialog={openDeleteDialog}
                      initiativeId={id && id}
                      message={deleteMessage}
                    />
                  )}
                  {openUploadImageDialog && (
                    <UploadCoverImage
                      handleCloseUploadImageDialog={handleCloseUploadImageDialog}
                      openUploadImageDialog={openUploadImageDialog}
                      image={image}
                      setImage={setImage}
                      setImageFileData={setImageFileData}
                      preview={preview}
                      setPreview={setPreview}
                      setImageName={setImageName}
                      handleClick={handleClick}
                      createNewClick={createNewClick}
                      onEditBrowse={onEditBrowse}
                    />
                  )}
                  {openWarningDialog && (
                    <WarningMessageDialog
                      openWarningDialog={openWarningDialog}
                      handleCloseWarningDialog={handleCloseWarningDialog}
                      setCheckWarningClick={setCheckWarningClick}
                    />
                  )}
                </Grid>
              </Card>
            </form>
          )}
          {/* <Grid item xs={10} md={10} lg={10} xl={10}>
                    <React.Fragment>
                        <Box sx={{ display: "flex", flexDirection: "row", pl: 28, mb: 2, mt: 2 }}>
                            <Button
                                variant="contained"
                                sx={{ p: 1, mr: 1, backgroundColor: "#ef7335", borderRadius: 4, fontFamily: 'HK Grotesk' }}
                                className="button-tr-2"
                                onClick={handleSubmit(onCreateInitiativeSubmit)}
                            >
                                Submit
                            </Button>
                            <Box sx={{ flex: "1 1 auto" }} />
                        </Box>
                    </React.Fragment>
                </Grid> */}
        </Grid>
      </Box>
    </>
  )
}

export default CreateInitiatives
