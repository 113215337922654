import {
  FETCH_MASTERLIST_FAILURE,
  FETCH_MASTERLIST_REQUEST,
  FETCH_MASTERLIST_SUCCESS,
  UPDATE_MASTERLIST_SUCCESS,
} from '../action/types'

const initialState = {
  data: [],
  error: '',
}

export const MasterListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MASTERLIST_REQUEST:
      return {
        ...state,
      }
    case FETCH_MASTERLIST_SUCCESS:
      return {
        data: action.payload,
      }
    case UPDATE_MASTERLIST_SUCCESS:
      return {
        data: state.data.concat(action.payload),
      }
    case FETCH_MASTERLIST_FAILURE:
      return {
        data: [],
        error: action.payload,
      }
    default:
      return state
  }
}
