import {
  FETCH_TWITTER_MOST_NEW_FOLLOWERS_REQUEST,
  FETCH_TWITTER_MOST_NEW_FOLLOWERS_SUCCESS,
  FETCH_TWITTER_MOST_NEW_FOLLOWERS_FAILURE,
} from './types';
import axiosInstance from '../../utils/api';

export const fetchTopMostFollowersRequest = () => {
  return {
    type: FETCH_TWITTER_MOST_NEW_FOLLOWERS_REQUEST,
  };
};
export const fetchTopMostFollowersSuccess = (value) => {
  return {
    type: FETCH_TWITTER_MOST_NEW_FOLLOWERS_SUCCESS,
    payload: value,
  };
};
export const fetchTopMostFollowersFailure = (error) => {
  return {
    type: FETCH_TWITTER_MOST_NEW_FOLLOWERS_FAILURE,
    payload: error,
  };
};

export const getTopMostFollowers = () => async (dispatch) => {
  dispatch(fetchTopMostFollowersRequest);
  await axiosInstance
    .get('/api/mp/TopMostfollowers')
    .then((response) => {
      const result = response.data;
      dispatch(fetchTopMostFollowersSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchTopMostFollowersFailure(errorMsg));
    });
};
