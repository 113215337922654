import { Button, Box } from '@mui/material'

const MasterListButtonSection = ({ updateList, clickCancel, showUpdateButton }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2, mt: 4, ml: 2 }}>
      <Button
        variant="contained"
        className="button-tr-2-13 "
        autoFocus="true"
        onClick={() => updateList()}
        sx={{ backgroundColor: '#ef7335', fontSize: '16px', fontWeight: 'bold' }}
        disabled={!showUpdateButton}
      >
        UPDATE
      </Button>
      <Button
        variant="contained"
        className="button-tr-citizen-cancel ml-3"
        onClick={() => clickCancel()}
        sx={{
          backgroundColor: '#ef7335',
          fontWeight: 'bold',
        }}
      >
        CANCEL
      </Button>
    </Box>
  )
}

export default MasterListButtonSection
