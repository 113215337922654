import { Box, Grid, Paper, Typography } from '@mui/material'
import Notify from '../../asserts/images/SendNotification.svg'
import Console from '../../asserts/images/AdminConsole.svg'
import Pin from '../../asserts/images/Pin-icon.svg'
import { useNavigate } from 'react-router-dom'

let settingsOptions = [
  {
    title: 'Send Notifications',
    icon: Notify,
    target: '/Settings/SendNotification',
  },
  {
    title: 'Admin Console',
    icon: Console,
    target: '/Settings/MasterList',
  },
  {
    title: 'Pin Activity',
    icon: Pin,
    target: '/Settings/PinActivity',
  },
]

const SettingsContent = () => {
  let navigate = useNavigate()

  return (
    <Grid md={12} lg={12} xl={12}>
      <Paper
        className="d-flex"
        sx={{
          mt: 4,
          ml: 1,
          width: '100%',
          height: '50vh',
          borderRadius: 6,
          overflow: 'auto',
          paddingLeft: '8px',
        }}
      >
        {settingsOptions.map((item, index) => (
          <Box
            className="d-flex flex-column justify-content-center setting-content-button cursorshow"
            key={index}
            onClick={() => navigate(item.target)}
          >
            <img src={item.icon} alt="" className="h-45" />
            <Typography variant="body1" className="text-center font-size-1 box-title">
              {item.title}
            </Typography>
          </Box>
        ))}
      </Paper>
    </Grid>
  )
}

export default SettingsContent
