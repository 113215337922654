/* eslint-disable react/jsx-no-duplicate-props */
import { Grid, Paper, Typography, Button, InputAdornment, TextField, Tooltip, Avatar, CardMedia } from '@mui/material'
import { Box, display } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import SideMenu from '../SideMenu/SideMenu'
// import BorderColorIcon from "@mui/icons-material/BorderColor";
import { Link } from 'react-router-dom'
import searchIcon from '../../asserts/images/Search.svg'
import { useLoading } from '../../utils/LoadingContext'
import { useNotificationContext } from '../../utils/NotificationContext'
import editIcon from '../../asserts/images/Edit.svg'
import illimageIcon from '../../asserts/images/Illustration.svg'
import { useDispatch, useSelector } from 'react-redux'
import getInitiativeList from '../../store/action/initiativeList'
import {
  getOngoingSevaIntiativesListWithInitiativeId,
  getSocialMediaIntiativesListById,
} from '../../store/action/ongoingSevaInitiativesList'
import BorderColorIcon from '../../asserts/images/Create.svg'
import ClearIcon from '@mui/icons-material/Clear'
import { getEvents } from '../../store/action/getEvents'
import getUserProfile from '../../store/action/getUserProfile'
import axiosInstance from '../../utils/api'
import { makeLogRequest } from '../../utils/AuditLogs'
import { pushTag } from '../../utils/gtmservice'
import './SevaInitiatives.css'
import { useRef } from 'react'

const SevaInitiatives = () => {
  const navigate = useNavigate()
  const initiativeLists = useSelector((state) => state?.initiativeList?.data)
  const initiativeList = initiativeLists?.initiative
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const [clearIcon, setClearIcon] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useDispatch()
  const inputRef = useRef(null)

  const handleCreateNew = async () => {
    pushTag({
      userid: userProfile?.uuid,
      event: 'select_option',
      message: 'create_now',
      page_title: 'seva_initiative_page',
    })
    await postAuditLogs('create_now')
    navigate('/SevaInitiatives/createInitiative', {
      state: {
        create: true,
      },
    })
  }
  const handleSearch = async (e) => {
    await postAuditLogs('search')
    dispatch(getInitiativeList(e.target.value))
  }

  const onTwitterRefreshClick = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.get(`/api/mp/updatetweets`).then((response) => {
        return response
      })
      showNotification('Success', response?.data && response?.data?.message, 'success')
    } catch (error) {
      showNotification('Error', 'Access Denied', 'error')
    } finally {
      setLoading(false)
    }
  }

  const handleEdit = async (id) => {
    pushTag({
      userid: userProfile?.uuid,
      event: 'select_option',
      message: 'edit',
      page_title: 'seva_initiative_page',
    })
    await postAuditLogs('edit_initiative', { i_id: id })
    await dispatch(getSocialMediaIntiativesListById(id))
    await dispatch(getOngoingSevaIntiativesListWithInitiativeId(id))
    dispatch(getEvents(id))

    navigate(`/SevaInitiatives/editInitiative/${id}`, {
      state: {
        id: id,
        create: false,
      },
    })
  }

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('seva_initiatives', sqId, data)
  }
  const handleSearchClick = () => {
    if (inputRef.current !== null) {
      inputRef.current.focus()
    }
  }
  const fetchData = async () => {
    try {
      setLoading(true)
      await dispatch(getUserProfile())
      await dispatch(getInitiativeList())
      await postAuditLogs('pageload')
    } catch (error) {
      showNotification('Error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    pushTag({
      userid: userProfile?.uuid,
      event: 'pageload',
      page_title: 'seva_initiative_page',
    })
    fetchData()
  }, [])

  const blockInvalidChar = (e) =>
    ['#', '&', '+', '_', '!', '@', '%', '[', ']', '=', '*', '^', '-', '?', '/', '$', ')', '('].includes(e.key) &&
    e.preventDefault()

  return (
    <>
      <Box className="page-wrapper d-flex seva-initiatives-outer-container">
        <SideMenu active="SevaInitiative" user="Admin" />
        <Box className="main-wrapper customscroll w-100 seva-initiatives-inner-container">
          <Grid container>
            <Grid md={12} lg={12} xl={12}>
              <Box className="d-flex justify-content-between align-items-center">
                <Typography component="h1" className="seva-initiatives-title-text">
                  {' '}
                  Seva Initiatives
                </Typography>
                <TextField
                  className="search-filter-icon"
                  sx={{
                    '& fieldset': { border: 'none' },
                  }}
                  onChange={(e) => {
                    setSearchValue(e.target.value)
                    handleSearch(e)
                    if (e.target.value !== '') {
                      setClearIcon(true)
                    } else {
                      setClearIcon(false)
                    }
                  }}
                  inputRef={inputRef}
                  value={searchValue}
                  // onKeyDown={blockInvalidChar}
                  placeholder="Search Initiative"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {/* search icon  */}
                        {clearIcon ? (
                          <ClearIcon
                            position="start"
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              dispatch(getInitiativeList())
                              setClearIcon(false)
                              setSearchValue('')
                            }}
                          />
                        ) : (
                          <Avatar
                            variant="square"
                            className="search-icon-sevaInitiative searchIcon cursorshow"
                            src={searchIcon}
                            onClick={handleSearchClick}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
                {/* <div className="search-filter-icon-admin d-flex-admin">
                            <div style={{position: "relative",left: "90%"}}>
                            {/* <a className="d-block mr-2" href=""> */}
                {/* <svg
                                    className="searchIcon position-absolute"
                                    width="20"
                                    height="21"
                                    viewBox="0 0 20 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.58317 18.6253C4.87484 18.6253 1.0415 14.792 1.0415 10.0837C1.0415 5.37533 4.87484 1.54199 9.58317 1.54199C14.2915 1.54199 18.1248 5.37533 18.1248 10.0837C18.1248 14.792 14.2915 18.6253 9.58317 18.6253ZM9.58317 2.79199C5.55817 2.79199 2.2915 6.06699 2.2915 10.0837C2.2915 14.1003 5.55817 17.3753 9.58317 17.3753C13.6082 17.3753 16.8748 14.1003 16.8748 10.0837C16.8748 6.06699 13.6082 2.79199 9.58317 2.79199Z"
                                        fill="#5c819e"
                                    />
                                    <path
                                        d="M18.3335 19.4585C18.1752 19.4585 18.0169 19.4002 17.8919 19.2752L16.2252 17.6085C15.9835 17.3669 15.9835 16.9669 16.2252 16.7252C16.4669 16.4835 16.8669 16.4835 17.1085 16.7252L18.7752 18.3919C19.0169 18.6335 19.0169 19.0335 18.7752 19.2752C18.6502 19.4002 18.4919 19.4585 18.3335 19.4585Z"
                                        fill="#5c819e"
                                    />
                                </svg> 
                                <img className="searchIcon" width={20} height={21} src={searchIcon}/>
                             </a> 
                            </div>
                        </div> */}
              </Box>
            </Grid>

            <Box className="create-dash-new card create-initiatives-title-container">
              <Grid container sx={{ ml: 2 }}>
                <Typography
                  sx={{
                    fontFamily: 'HK Grotesk ExtraBold',
                    color: '#505050',
                    fontSize: '24px',
                    fontWeight: 'bolder !important',
                  }}
                >
                  Create Initiatives
                </Typography>
              </Grid>
              <Grid container sx={{ ml: 2 }}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontFamily: 'HK Grotesk',
                      color: '#505050',
                      fontSize: '20px',
                      fontWeight: 'normal',
                    }}
                  >
                    Would you like to create a Seva Initiative? Add the details here
                  </Typography>
                </Grid>
              </Grid>
              <Grid container sx={{ ml: 2 }}>
                <Button
                  className="button-tr1-admin"
                  sx={{
                    fontFamily: 'HK Grotesk ExtraBold !important',
                    textTransform: 'none !important',
                    fontWeight: 'bolder !important',
                    fontSize: '20px !important',
                    padding: '8px 20px',
                    width: '242px',
                  }}
                  // endIcon={<BorderColorIcon />}
                  onClick={handleCreateNew}
                >
                  {}
                  <Box className="create-container">
                    Create Now
                    <Box className="create-button">
                      <CardMedia component="img" image={BorderColorIcon} className="create-now-button-icon" />
                    </Box>
                  </Box>
                </Button>
              </Grid>
            </Box>
            <Grid md={12} lg={12} xl={12}>
              <Paper
                sx={{
                  mt: 4,
                  ml: 1,
                  width: '100%',
                  height: ' 56vh',
                  borderRadius: 6,
                  overflow: 'auto',
                  // position:"relative",
                  // left:"50%"
                }}
              >
                {initiativeList?.length > 0 ? (
                  <>
                    {initiativeList?.map((initiativeItem) => {
                      return (
                        <Box className="create-dash-new card created-initiative-item-element">
                          <Grid
                            container
                            sx={{
                              ml: 2,
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginTop: '-10px',
                            }}
                          >
                            {/* <Typography
                              className="ellipsewehe12"
                              sx={{
                                ,
                              }}
                            >
                              {initiativeItem.initiativeName}
                            </Typography> */}
                            <Tooltip title={initiativeItem.initiativeName} arrow>
                              <Typography variant="span" className="d-flex create-initiative-title">
                                {' '}
                                Seva {initiativeItem.id} -{' '}
                                <Typography variant="span" className="ellipsewehe12 ml-1">
                                  {' '}
                                  {initiativeItem.initiativeName}{' '}
                                </Typography>{' '}
                              </Typography>
                            </Tooltip>
                            <Box className="edit-parent-box">
                              <CardMedia
                                className="edit-initiative-button"
                                onClick={() => {
                                  handleEdit(initiativeItem?.id)
                                }}
                                component="img"
                                image={editIcon}
                              />
                            </Box>
                          </Grid>
                        </Box>
                      )
                    })}
                  </>
                ) : (
                  <>
                    <CardMedia component="img" className="no-data-media" src={illimageIcon} alt="" />
                    <Box className="no-data-found-text-container d-flex justify-content-center flex-column align-items-center">
                      <Typography variant="span" className="no-data-found-text">
                        No data found!
                      </Typography>
                      <Typography variant="span" className="create-initiative-link-text">
                        Click <Link to="/SevaInitiatives/createInitiative">here</Link> to create an initiative
                      </Typography>
                    </Box>
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

export default SevaInitiatives
