import { ASSIGN_MP_REQUEST, ASSIGN_MP_SUCCESS, ASSIGN_MP_FAILURE } from './types';
import axiosInstance from '../../utils/api';

export const fetchMpListRequest = () => {
  return {
    type: ASSIGN_MP_REQUEST,
  };
};
export const fetchMpListSuccess = (carMakes) => {
  return {
    type: ASSIGN_MP_SUCCESS,
    payload: carMakes,
  };
};
export const fetchMpListFailure = (error) => {
  return {
    type: ASSIGN_MP_FAILURE,
    payload: error,
  };
};

export const getAssignMpList = (state) => async (dispatch) => {
  dispatch(fetchMpListRequest);
  await axiosInstance
    .get(`/api/initiative/mpAssign?state_name=${state ? state : ''}`)
    .then((response) => {
      const result = response.data;
      // const result = MpList
      dispatch(fetchMpListSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchMpListFailure(errorMsg));
    });
};
