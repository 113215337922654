import React, { useEffect, useState } from 'react'
import { Box, Grid, Divider } from '@mui/material'
import { getIds } from 'Components/ReusableComponents.js/getIds'
import { makeLogRequest } from 'utils/AuditLogs'
import { pushTag } from 'utils/gtmservice'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import LeaderBoardTabs from 'Components/MpHomeRefactor/LeaderBoardTabs/LeaderBoardTabs'
import MpCard from 'Components/MpHomeRefactor/MpCard/MpCard'
import StickyHeadTable from 'Components/MpHomeRefactor/MpTable/MpTable'
import './MpHome.css'
import HighlightsSection from 'Components/MpHomeRefactor/HighlightsSection/HighlightsSection'
import LeaderBoardHeader from 'Components/MpHomeRefactor/LeaderBoardHeader/LeaderBoardHeader'
import NotificationsList from 'Components/ReusableComponents.js/NotificationList/NotificationsList'
import { useLoading } from '../../utils/LoadingContext'
const MpHome = () => {
  const [dataFilter, setDataFilter] = useState('15days')
  const [defaultVal, setDefaultVal] = useState('15days')
  const [tenure, setCurrentTenure] = useState('Modi3.0')
  const [defaultTenure, setTenure] = useState('Modi3.0')
  const isBdayCampaignEnabled = JSON.parse(localStorage.getItem('isBdayCampaignEnabled') || false)
  //const isSurveyCampaignEnabled = JSON.parse(localStorage.getItem('isSurveyCampaignEnabled') || false)
  const lastData = JSON.parse(localStorage.getItem('leaderBoardSelectedItem') || '{}')
  const { tabId, card, flag, scrollDirection } = lastData
  const [selectedCard, setSelectedCard] = useState(card ? card : 'sevaScore')
  const [Flag, setFlag] = useState(flag ? flag : '101')
  const [isMarqueeVisible, setIsMarqueeVisible] = useState(false)
  const [cardLoading, setCardLoading] = useState(false)
  const [highlightsLoading, setHighlightsLoading] = useState(false)
  const [onTableLoad, setTableLoad] = useState(false)
  const { setLoading } = useLoading()
  const scrollRef = React.useRef()
  const handleScroll = () => {
    scrollRef.current.scrollTop = 0
  }
  useEffect(() => {
    const pageLoad = async () => {
      const parameters = {
        useridtype: getIds(),
        section: selectedCard,
      }
      await makeLogRequest('mp_leaderboard', 'pageload', parameters)
      pushTag({
        event: 'pageload',
        page_title: 'leaderboard_page',
      })
    }
    pageLoad()
  }, [])
  useEffect(() => {
    if (cardLoading || highlightsLoading || onTableLoad) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [cardLoading, highlightsLoading, onTableLoad])
  return (
    <>
      <Box className="page-wrapper d-flex">
        <SideMenu activeTab={'Leader'} />
        <Box className="main-wrapper center-width customscroll mphome-container" ref={scrollRef}>
          <NotificationsList setIsMarqueeVisible={setIsMarqueeVisible} isMarqueeVisible={isMarqueeVisible} />
          <Box>
            <Grid container spacing={2} className="mpHomeContainer">
              <Grid md={8} lg={8} xl={8} xs={10}>
                <LeaderBoardHeader
                  title={'MP Leaderboard'}
                  selectedCard={selectedCard}
                  setDataFilter={setDataFilter}
                  setCurrentTenure={setCurrentTenure}
                  dataFilter={dataFilter}
                  isMarqueeVisible={isMarqueeVisible}
                  defaultVal={defaultVal}
                  setDefaultVal={setDefaultVal}
                  setTenure={setTenure}
                  defaultTenure={defaultTenure}
                />
                <LeaderBoardTabs
                  dataFilter={dataFilter}
                  setSelectedCard={setSelectedCard}
                  flag={Flag}
                  setFlag={setFlag}
                  setCardLoading={setCardLoading}
                  tenure={tenure}
                />
                <Divider className="mt-2 mb-4" />
                <MpCard selectedCard={selectedCard} />
                <Box className="mt-5 learders-list">
                  <StickyHeadTable
                    setTableLoad={setTableLoad}
                    selectedCard={selectedCard}
                    flag={Flag}
                    dataFilter={dataFilter}
                    tenure={tenure}
                  />
                </Box>
              </Grid>
              <Grid className="pt-0" item xs={6} md={4} lg={4} xl={4} sx={{ backgroundColor: '#F5F6FA' }}>
                <HighlightsSection
                  handleScroll={handleScroll}
                  dataFilter={dataFilter}
                  isMarqueeVisible={isMarqueeVisible}
                  setHighlightsLoading={setHighlightsLoading}
                  tenure={tenure}
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default MpHome
