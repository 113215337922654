import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  Box,
  Avatar,
} from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import rightArrow from '../../asserts/images/arrowRight.png'
import MemberIcon from '../../asserts/images/MemberIcon.png'
import DonationIcon from '../../asserts/images/InviteDonation.png'
import CircleBg from '../../asserts/images/CircleBg.png'
import IconEdit from '../../asserts/images/IconEdit.svg'
import CrossIcon from '../../asserts/images/Close.svg'
import ProfileEdit from './ProfileEdit'
import Copyo from '../../asserts/images/copy1.svg'
import facebook from '../../asserts/images/Facebook.svg'
import twitter1 from '../../asserts/images/Twitter1.svg'
import whatsapp from '../../asserts/images/WhatsApp.svg'
import email from '../../asserts/images/Email.svg'
import Share from './Share'
import { toast } from 'react-hot-toast'
import CopyAndShareClick from './CopyAndShareAPI'
import DonationClick from './DonationClick'
import { makeLogRequest } from '../../utils/AuditLogs'
import { getIds } from '../ReusableComponents.js/getIds'
import { pushTag } from '../../utils/gtmservice'
import './ProfileCard.css'

const ProfileCard = ({ profile, mySeva, page_title }) => {
  // const navigate= useNavigate();

  const [open, setOpen] = React.useState(false)
  const [addMembers, setAddMembers] = React.useState(false)
  const [inviteDonations, setInviteDonations] = React.useState(false)
  // let code = ["LBLA67-F", "M4TSUX-F"]
  let code = localStorage.getItem('Referal')
  let link = `https://www.narendramodi.in/downloadapp?referralCode=${code}`
  let link2 = `https://www.narendramodi.in/donation/${code}`
  const content = `Join me on NM App in the journey to transform India. Click ${link} & Enter code ${code}`

  const postAuditLogs = async (sqId) => {
    await makeLogRequest(mySeva ? 'my_seva_updates' : 'nationwide_seva_updates', sqId, { useridtype: getIds() })
  }

  const postAuditLogsforPopups = async (sqId) => {
    await makeLogRequest(addMembers ? 'add_members_popup' : inviteDonations && 'invite_donations_popup', sqId, {
      useridtype: getIds(),
    })
  }

  useEffect(() => {
    if (addMembers || inviteDonations) {
      postAuditLogsforPopups('pageload')
    }
    if (addMembers) {
      pushTag({
        event: 'pop_up',
        page_title: 'expand_movement_popup',
      })
    }
    if (inviteDonations) {
      pushTag({
        event: 'pop_up',
        page_title: 'invite_donations_popup',
      })
    }
  }, [addMembers, inviteDonations])

  const handleEdit = () => {
    setOpen(true)
  }
  const handleOpen = (val) => {
    setOpen(val)
  }

  const handleProfileCardLog = (type) => {
    const parameters = {
      useridtype: profile?.id,
    }
    makeLogRequest('mp_leaderboard', type, parameters)
    pushTag({
      event: 'select_option',
      page_title: page_title,
      message: type,
    })
  }

  return (
    <>
      <Card className="right-card-3 card">
        {/* <div> */}
        {/* {open && <ProfileEdit mpDetail={profile} setOpen={handleOpen} open={open} />} */}
        {/* <Card m={2} sx={{ border: "#357092 2px solid", borderRadius: "12px", height: "170px" }} 
          // onClick={handleEdit}
          >
            <CardActionArea>
              <img
                src={IconEdit}
                alt="edit"
                className="card-icon-edit"
              />
              <img src={CircleBg} alt="bg" className="card-bg" />
              <img
                src={profile.user_avatar}
                alt={profile.user_name}
                className="card-icon-image"
              />
              <CardContent>
                <Box width="50%">
                  <Typography
                    variant="h5"
                    color="#357092"
                    fontWeight="Bold"
                    padding="0"
                    fontFamily="HK Grotesk"
                    fontSize="18px"
                    className="cardellipright"
                  >
                    {profile?.user_name}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    fontFamily="HK Grotesk"
                    fontSize="14px"
                    sx={{ pt: 2 }}
                  >
                    {profile?.designation}-{profile?.party},<br />
                    <br />
                    {profile?.state_name}
                  </Typography>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card> */}

        {/* </div> */}

        <Box className="right-card-1">
          <Box className="d-flex-gap-1 right-card-bottom-elements-wrapper">
            {/* <Button sx={{ textTransform: "unset", textAlign:"-webkit-left",   color: "white",    font: "inherit"}}> */}
            <Card
              className="small-cards small-card1 clickable cursorshow"
              sx={{ borderRadius: '10px' }}
              onClick={() => {
                setAddMembers(true)
                handleProfileCardLog('add_members_to_namo_app')
              }}
            >
              <Box mb={0} sx={{ height: '19px', width: '19px' }}>
                <CardMedia component="img" className="mr-3" src={MemberIcon} alt="" />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={2}>
                <Typography className="right-card-bottom-elements-text mb-0">Add members to NaMo App</Typography>
                <Avatar
                  variant="square"
                  className="align-bottom right-card-bottom-elements-right-arrow-icon"
                  src={rightArrow}
                />
              </Box>
            </Card>

            {/* <div className="small-cards small-card1">
                <p className="mb-0">
                  <img className="mr-3" src={MemberIcon} width="15" alt="" />
                </p>
                <div className="d-flex justify-content-between mt-3">
                  <p className="mb-0">Add members to NaMo App </p>
                  <img
                    width="24"
                    height="21.44"
                    className="align-bottom"
                    src={rightArrow}
                  />
                </div>
              </div> */}
            {/* </Button> */}

            {/* <Button sx={{ textTransform: "unset", textAlign:"-webkit-left",   color: "white",    font: "inherit"}}> */}
            <Card
              className="small-cards small-card2 clickable cursorshow"
              sx={{ borderRadius: '10px' }}
              onClick={() => {
                setInviteDonations(true)
                handleProfileCardLog('invite_donations')
              }}
            >
              <Box mb={0} sx={{ width: '19px', height: '19px' }}>
                <CardMedia component="img" className="mr-3" src={DonationIcon} alt="" />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={2}>
                <Typography className="right-card-bottom-elements-text mb-0">
                  Invite <br></br> Donations
                </Typography>
                <Avatar
                  variant="square"
                  className="align-bottom right-card-bottom-elements-right-arrow-icon"
                  src={rightArrow}
                />
              </Box>
            </Card>

            {/* <div className="small-cards small-card2">
                <p className="mb-0">
                  <img className="mr-3" src={DonationIcon} width="15" alt="" />
                </p>
                <div className="d-flex justify-content-between mt-3">
                  <p className="mb-0">Invite Donations</p>
                  <img
                    width="24"
                    height="21.44"
                    className="align-text-bottom"
                    src={rightArrow}
                  />
                </div>
              </div> */}
            {/* </Button> */}
          </Box>
        </Box>
        {/* </div> */}
      </Card>
      <Dialog
        open={addMembers}
        onClose={() => (
          setAddMembers(false),
          postAuditLogsforPopups('close'),
          pushTag({
            event: 'back',
            page_title: 'expand_movement_popup',
          })
        )}
        PaperProps={{
          style: {
            width: '600px',
          },
        }}
      >
        <DialogTitle sx={{ mt: 2 }}>
          <Avatar
            onClick={() => (
              setAddMembers(false),
              postAuditLogsforPopups('close'),
              pushTag({
                event: 'back',
                page_title: 'expand_movement_popup',
              })
            )}
            src={CrossIcon}
            className="closeIcon"
          />
          <Typography
            sx={{
              color: '#357092',
              fontFamily: 'HK Grotesk ExtraBold !important',
              fontSize: '30px',
              fontWeight: 'bold',
            }}
            className="d-flex justify-content-center"
          >
            Expand the Movement
          </Typography>
          <Typography
            sx={{
              color: '#505050',
              fontFamily: 'HK Grotesk Bold !important',
              fontSize: '22px',
              fontWeight: 'bold',
              mt: 1,
            }}
            className="d-flex justify-content-center"
          >
            Invite more members to be New India Volunteer
          </Typography>
          <Box className="your-unique-code-wrapper">
            <Box className="sharepopupbg">
              <Typography component="span" className="your-unique-code-text">
                YOUR UNIQUE CODE <Typography className="your-unique-code">{code}</Typography>
              </Typography>
              {/* <span style={{
                color: "#505050",
                fontFamily: "HK Grotesk",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center", display: "block", marginLeft: "-63%"
              }}>M4TSUX-F </span> */}
            </Box>
            <Avatar
              variant="square"
              src={Copyo}
              className="copysv cursorshow"
              onClick={() => {
                window.navigator.clipboard
                  .writeText(code)
                  .then((res) => {
                    toast.dismiss()
                    toast.success('Copied to clipboard.')
                  })
                  .catch((err) => console.log(err))
                postAuditLogsforPopups('copy_code')
                pushTag({
                  event: 'copy',
                  page_title: 'expand_movement_popup',
                })
                // CopyAndShareClick()
              }}
            />
          </Box>
          <Box className="share-options-container">
            <Share
              CopyAndShareClick={CopyAndShareClick}
              content={`Join me on NM App in the journey to transform India. Click ${link} & Enter code ${code}`}
              linkurl={link}
              addMembers={true}
            />
          </Box>
          {/* <CloseIcon onClick={() => setAddMembers(false)} /> */}
        </DialogTitle>
      </Dialog>
      <Dialog
        open={inviteDonations}
        onClose={() => (
          setInviteDonations(false),
          postAuditLogsforPopups('close'),
          pushTag({
            event: 'back',
            page_title: 'invite_donations_popup',
          })
        )}
        PaperProps={{
          style: {
            width: '600px',
            height: '480px',
            overflowY: 'hidden',
          },
        }}
      >
        <DialogTitle sx={{ minHeight: '550px', mt: 2 }}>
          <Avatar
            onClick={() => (
              setInviteDonations(false),
              postAuditLogsforPopups('close'),
              pushTag({
                event: 'back',
                page_title: 'invite_donations_popup',
              })
            )}
            src={CrossIcon}
            className="closeIcon"
          />
          <Typography
            sx={{
              color: '#357092',
              fontFamily: 'HK Grotesk ExtraBold !important',
              fontSize: '30px',
              fontWeight: 'bold',
            }}
            className="d-flex justify-content-center"
          >
            Invite Donations
          </Typography>
          <Typography
            sx={{
              color: '#505050',
              fontFamily: 'HK Grotesk Bold !important',
              fontSize: '22px',
              fontWeight: 'bold',
              mt: 1,
            }}
            className="d-flex justify-content-center text-center"
          >
            Encourage contributions towards strengthening <br></br>a party of New India Builders.
          </Typography>
          <Typography className="refer-now-to-your-friends-text">Refer now to your friends</Typography>
          <Box className="your-unique-code-wrapper">
            <Box className="sharepopupbg">
              <Typography variant="span" className="your-unique-code-text">
                YOUR UNIQUE CODE <Typography className="your-unique-code">{code}</Typography>
              </Typography>
              {/* <span style={{
                color: "#505050",
                fontFamily: "HK Grotesk",
                fontSize: "20px",
                fontWeight: "bold",
                textAlign: "center", display: "block", marginLeft: "-63%"
              }}>LBLA67-F </span> */}
            </Box>
            <Avatar
              variant="square"
              src={Copyo}
              className="copysv cursorshow"
              onClick={() => {
                window.navigator.clipboard
                  .writeText(code)
                  .then((res) => {
                    toast.dismiss()
                    toast.success('Copied to clipboard.')
                  })
                  .catch((err) => console.log(err))
                // DonationClick()
                postAuditLogsforPopups('copy_code')
                pushTag({
                  event: 'copy',
                  page_title: 'invite_donations_popup',
                })
              }}
            />
          </Box>
          <Box className="share-options-container">
            <Share
              donation={true}
              content={`I donated to the party of aspirations of all Indians. I contributed towards strengthening a party of New India builders under PM Modi. You can do it too! Use my Code ${code}  
${link2} via Namo App`}
              linkurl={link2}
            />
          </Box>
        </DialogTitle>
      </Dialog>
    </>
  )
}

export default ProfileCard
