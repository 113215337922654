import React, { useState, useEffect, useMemo, useRef } from 'react'
import {
  Grid,
  Breadcrumbs,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
  Paper,
  Box,
  Avatar,
} from '@mui/material'
import SideMenu from '../SideMenu/SideMenu'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import SevaUpdates from './SevaUpdates'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useDispatch, useSelector } from 'react-redux'
import { getEventsList, getEventsListByMpId } from '../../store/action/eventsList'
import NoImageFound from '../../asserts/images/noImageFound.jpg'
import illimageIcon from '../../asserts/images/Illustration.svg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import Moment from 'moment'
import InitiativeDetails from './AllInitiativeReports/initiativeDetails'
import ShareIcon from '@mui/icons-material/Share'
import CrossIcon from '../../asserts/images/Close.svg'
import Share from '../ReusableComponents.js/Share'
import { getMpProfile } from '../../store/action/individualMP'
import { getIds } from '../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../utils/AuditLogs'
import searchIcon from '../../asserts/images/Search.svg'
import {
  getSearchEventsList,
  getSearchMediaCoveragesList,
  getSearchDevProjectsList,
  getSearchMiscellaneousList,
} from '../../store/action/searchSevaUpdates'
import {
  getOnlyMediaCoverageList,
  getOnlyMediaCoverageListByMpID,
  getOpedsAndBooksList,
  getOpedsAndBooksListByMpID,
} from '../../store/action/mediaCoverageList'
import { getDevelopmentProjectsList, getDevelopmentProjectsListByMpId } from '../../store/action/developmentProjectList'
import './viewAllScreen.css'
import { useLoading } from '../../utils/LoadingContext'
import AddMediaCoverage from './AddMediaCoverage/AddMediaCoverage'
import AddDevelopmentProjects from './AddDevelopmentProjects/AddDevelopmentProjects'
import CreateNewEvent from './AddSevaEvent/CreateNewEvent'
import AddSevaEvent from './AddSevaEvent/AddSevaEvent'
import NotFoundImg from '../../asserts/images/searchNotFound.png'
import ProfilePictureComponent from '../ReusableComponents.js/ProfilePictureComponent'
import { AllScreenCardProfile } from '../ReusableComponents.js/ProfilePictureStyles'

const defaultLimit = 10

const ViewAllScreen = ({ user, newUser }) => {
  const { setLoading } = useLoading()
  const location = useLocation()
  const navigate = useNavigate()
  const containerRef = useRef(null)
  const viewAllValue = location?.state?.viewAllValue
  // const cardsData = location?.state?.data;
  const isEdit = location?.state?.isEdit
  const MpClick = location?.state?.MpClick
  const title = location?.state?.title
  const mpName = location?.state?.mpName
  const checkMpIdOrMySeva = location?.state?.checkMpIdOrMySeva
  const mpId = location?.state?.mpId
  const mySeva = location?.state?.mySeva
  const isFromNationwide = location?.state?.isFromNationwide
  const eventsForInitiative = location?.state?.eventsForInitiative
  const initiativeId = location?.state?.initiativeId
  const mpData = location?.state?.mpData
  const dispatch = useDispatch()
  const [openInitiativeDetailsDialog, setOpenInitiativeDetailsDialog] = useState(false)
  const [personDetails, setPersonDetails] = useState()
  const [cardsData, setCardsData] = useState([])
  const [noRecordFound, setNoRecordFound] = useState(true)
  const [totalCardLength, setTotalCardLength] = useState(0)
  const [onViewClick, setOnViewClick] = useState()
  const [mediaShare, setMediaShare] = useState(false)
  const [shareData, setShareData] = useState({})
  const [checkIsUpdate, setCheckIsUpdate] = useState(false)
  const [searchOffset, setSearchOffset] = useState(0)
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])

  const [eventByMpId, setEventByMpId] = useState(false)
  const [opedsByMpId, setOpedsByMpId] = useState(false)
  const [developmentProjectsByMpId, setDevelopmentProjectsByMpId] = useState(false)
  const eventsLists = useSelector((state) => state?.eventsList?.data)
  const eventsList = eventsLists?.data
  const eventsListLength = eventsLists?.totalCount
  const eventListsByMpId = useSelector((state) => state.eventListByMpId?.data)
  const eventListByMpId = eventListsByMpId?.data
  const eventListLengthByMpId = eventListsByMpId?.totalCount
  const mediaCoverageLists = useSelector((state) => state?.onlyMediaCoverageList?.data)
  const mediaCoverageList = mediaCoverageLists?.data
  const mediaCoverageListLength = mediaCoverageLists?.totalCount
  const mediaCoverageListsByMpId = useSelector((state) => state?.onlyMediaCoverageListByMpId?.data)
  const mediaCoverageListByMpId = mediaCoverageListsByMpId?.data
  const mediaCoverageListLengthByMpId = mediaCoverageListsByMpId?.totalCount
  const opedAndBooksLists = useSelector((state) => state?.opedAndBooksList?.data)
  const opedAndBooksList = opedAndBooksLists?.data
  const opedAndBooksListLength = opedAndBooksLists?.totalCount
  const opedAndBooksListsByMpId = useSelector((state) => state?.opedAndBooksListByMpId?.data)
  const opedAndBooksListByMpId = opedAndBooksListsByMpId?.data
  const opedAndBooksListLengthByMpId = opedAndBooksListsByMpId?.totalCount
  const developmentProjectsLists = useSelector((state) => state?.developmentProjectsList?.data)
  const developmentProjectsList = developmentProjectsLists?.data
  const developmentProjectsListLength = developmentProjectsLists?.totalCount
  const developmentProjectListsByMpId = useSelector((state) => state.developmentProjectListByMpId?.data)
  const developmentProjectListByMpId = developmentProjectListsByMpId?.data
  const developmentProjectListLengthByMpId = developmentProjectListsByMpId?.totalCount
  const searchResults = useSelector((state) => state.searchSevaUpdates?.data)
  const [searchText, setSearchText] = useState('')
  const [lastPaginatedResult, setLastPaginatedResult] = useState([])
  const [isItemDeleted, setIsItemDeleted] = useState(false)
  const [isItemUpdated, setIsItemUpdated] = useState(false)
  const [openMediaCoverage, setOpenMediaCoverage] = useState(false)
  const [openDevelopmentProjects, setOpenDevelopmentProjects] = useState(false)
  const [openNewEvent, setOpenNewEvent] = useState(false)
  const [addMediaTitle, setAddMediaTitle] = useState('Media Coverage')
  const [openSevaEvent, setOpenSevaEvent] = useState(false)
  const ongoingSevaInitiativesListByMpId = useSelector(
    (state) => state?.ongoingSevaInitiativesListByMpId?.data?.initiative
  )

  const viewAllLengthForMp = {
    'Seva Events': eventListLengthByMpId,
    'Media Coverage': mediaCoverageListLengthByMpId,
    Miscellaneous: opedAndBooksListLengthByMpId,
    'Development Projects': developmentProjectListLengthByMpId,
  }

  const viewAllLengthForAll = {
    'Seva Events': eventsListLength,
    'Media Coverage': mediaCoverageListLength,
    Miscellaneous: opedAndBooksListLength,
    'Development Projects': developmentProjectsListLength,
  }

  useEffect(() => {
    if (searchText?.length) {
      return
    }
    if (checkMpIdOrMySeva) {
      setTotalCardLength(viewAllLengthForMp[viewAllValue])
    } else if (!eventsForInitiative) {
      setTotalCardLength(viewAllLengthForAll[viewAllValue])
    }
  }, [viewAllLengthForMp, viewAllLengthForAll, searchText])

  useEffect(() => {
    if (searchText !== '') {
      containerRef.current.scrollTop = 0
      setSearchOffset(0)
      setSearchText('')
    }
  }, [isItemUpdated])

  useEffect(() => {
    if (searchText?.length) {
      if (searchOffset === 0) {
        setCardsData(searchResults?.data)
      } else {
        pushingAPIResultsToCardsData(searchResults?.data)
      }
      setTotalCardLength(searchResults?.totalCount)
      if (searchResults?.data?.length > 0) {
        setNoRecordFound(true)
      } else if (searchResults?.data?.length === 0) {
        setTotalCardLength(0)
        setNoRecordFound(false)
      }
    }
  }, [searchResults])

  // const dispatch = useDispatch();
  // const eventsList = useSelector((state) => state?.eventsList?.data);
  const onFolderClick = () => {
    if (!isFromNationwide && !eventsForInitiative) {
      navigate('/MySevaUpdates', {
        state: {
          MpName: 'My Seva Rank',
          user: user,
          mpId: getIds(),
        },
      })
    } else if (eventsForInitiative) {
      handleCardClick()
    } else {
      navigate('/SevaUpdates', {
        state: {
          user: newUser,
        },
      })
    }
  }

  const handleCardClick = (data) => {
    navigate('/SevaUpdates/allInitiativeReports', {
      state: {
        user: user,
        // initiativeDetails: initiativeDetails,
        initiativeId: initiativeId,
        initiativeName: title,
        mpName: mpName,
        mpId: getIds(),
      },
    })
  }
  const handleMp = (e) => {
    navigate('/MpSevaUpdates', {
      state: {
        MpName: mpName,
        user: user,
        mpId: mpId,
        mpClick: true,
        mpData: mpData,
      },
    })
  }

  const viewAllValueForAll = {
    'Seva Events': eventsList,
    'Media Coverage': mediaCoverageList,
    Miscellaneous: opedAndBooksList,
    'Development Projects': developmentProjectsList,
  }

  const viewAllValueForMp = {
    'Seva Events': eventListByMpId,
    'Media Coverage': mediaCoverageListByMpId,
    Miscellaneous: opedAndBooksListByMpId,
    'Development Projects': developmentProjectListByMpId,
  }

  const pushingAPIResultsToCardsData = (latestApiResponse) => {
    const newLastId = latestApiResponse[latestApiResponse?.length - 1]['id'] || 0
    const lastId = cardsData?.length ? cardsData[cardsData.length - 1]['id'] || 0 : Number.MAX_SAFE_INTEGER
    // TODO: need refactoring of component- getting re-rendered multiple times
    if (lastId !== newLastId) {
      if (cardsData?.length === 10) {
        setCardsData(latestApiResponse)
      } else {
        const currentData = [...cardsData, ...latestApiResponse]
        setCardsData(currentData)
      }
    }
  }

  const setDefaultCardData = () => {
    const payload = {
      id: 0,
      limit: 60,
    }
    setIsItemUpdated(true)
    getSevaUpdateResults(payload)
  }

  const handlePaginatedResults = () => {
    let latestApiResponse = []
    if (checkMpIdOrMySeva) {
      latestApiResponse = viewAllValueForMp[viewAllValue]
    } else {
      latestApiResponse = viewAllValueForAll[viewAllValue]
    }
    setLastPaginatedResult(latestApiResponse)
    if (isItemDeleted || isItemUpdated) {
      setCardsData(latestApiResponse)
      setIsItemDeleted(false)
      setIsItemUpdated(false)
      return
    }
    // Todo handle - if results are less than equal to 10 - need to reset redux state
    // && JSON.stringify(latestApiResponse) !== JSON.stringify(location?.state?.data)
    if (latestApiResponse?.length && JSON.stringify(latestApiResponse) !== JSON.stringify(location?.state?.data)) {
      pushingAPIResultsToCardsData(latestApiResponse)
    } else if (JSON.stringify(latestApiResponse) === JSON.stringify(location?.state?.data)) {
      setCardsData(latestApiResponse)
    }
  }

  useEffect(() => {
    if (viewAllValue in viewAllValueForAll) {
      handlePaginatedResults()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checkMpIdOrMySeva,
    eventListByMpId,
    eventsList,
    mediaCoverageListByMpId,
    mediaCoverageList,
    opedAndBooksListByMpId,
    opedAndBooksList,
    developmentProjectListByMpId,
    developmentProjectsList,
  ])

  const mapPaginationApiToViewAll = {
    'Seva Events': getEventsList,
    'Media Coverage': getOnlyMediaCoverageList,
    'Development Projects': getDevelopmentProjectsList,
    Miscellaneous: getOpedsAndBooksList,
  }

  const mapPaginationApiToViewAllForMp = {
    'Seva Events': getEventsListByMpId,
    'Media Coverage': getOnlyMediaCoverageListByMpID,
    'Development Projects': getDevelopmentProjectsListByMpId,
    Miscellaneous: getOpedsAndBooksListByMpID,
  }

  const getSevaUpdateResults = (payload) => {
    const fetchData = async (callBackFun) => {
      try {
        setLoading(true)
        await dispatch(callBackFun)
      } catch (error) {
        console.log('Error', error)
      } finally {
        setLoading(false)
      }
    }
    if (viewAllValue in mapPaginationApiToViewAll) {
      if (checkMpIdOrMySeva) {
        payload.id = mySeva ? getIds() : mpId
        fetchData(mapPaginationApiToViewAllForMp[viewAllValue](payload))
      } else {
        fetchData(mapPaginationApiToViewAll[viewAllValue](payload))
      }
    } else {
      const data = location?.state?.data
      setTotalCardLength(data?.length)
      return setCardsData(data)
    }
  }

  useEffect(() => {
    const payload = {
      id: 0,
      limit: 60,
    }
    getSevaUpdateResults(payload)
  }, [checkIsUpdate, checkMpIdOrMySeva, mpId, mySeva, viewAllValue])

  useEffect(() => {
    dispatch(getMpProfile(getIds()))
    const parameters = {
      useridtype: getIds(),
    }
    sendWallLogs('pageload', parameters)
  }, [])

  // const sendCloseDialogLog = () => {
  //     const parameters = {
  //         useridtype: getIds()
  //     }
  //     sendWallLogs('close', parameters);
  // }

  const sendWallLogs = async (type, parameters) => {
    await makeLogRequest(walltype[viewAllValue], type, parameters)
  }

  const walltype = {
    'Seva Events': 'seva_events_wall',
    'Media Coverage': 'books_and_media_coverage_wall',
    'Development Projects': 'development_project_wall',
    Miscellaneous: 'Miscellaneous_wall',
  }

  const handleCloseInitiativeDetails = (operationDetails) => {
    setIsItemDeleted(operationDetails?.deleted || false)
    setIsItemUpdated(operationDetails?.updated || false)
    setOpenInitiativeDetailsDialog(false)
  }

  const handleOpenInitiativeDetails = (item, title) => {
    const parameters = {
      useridtype: getIds(),
    }
    switch (viewAllValue) {
      case 'Seva Events':
        parameters.e_id = item.id
        sendWallLogs('event_card', parameters)
        break
      case 'Media Coverage':
        parameters.bm_id = item.id
        sendWallLogs('bm_card', parameters)
        break
      case 'Development Projects':
        parameters.dp_id = item.id
        sendWallLogs('dp_card', parameters)
        break
      case 'Miscellaneous':
        parameters.m_id = item.id
        sendWallLogs('m_card', parameters)
        break
      default:
        break
    }
    setPersonDetails(item)
    setOnViewClick(title)
    setOpenInitiativeDetailsDialog(true)
  }

  const handleShare = (e, data) => {
    const parameters = {
      useridtype: getIds(),
    }
    switch (viewAllValue) {
      case 'Seva Events':
        parameters.e_id = data.id
        break
      case 'Media Coverage':
        parameters.bm_id = data.id
        break
      case 'Development Projects':
        parameters.dp_id = data.id
        break
      case 'Miscellaneous':
        parameters.m_id = data.id
        break
      default:
        break
    }
    sendWallLogs('share', parameters)
    e.stopPropagation()
    e.preventDefault()
    setShareData(data)
    setMediaShare(true)
  }
  const truncateText = (text, characterLimit) => {
    if (text.length > characterLimit) {
      const words = text.split(' ')
      let truncatedText = ''

      for (const word of words) {
        if (truncatedText.length + word.length + 1 <= characterLimit) {
          truncatedText += `${word} `
        } else {
          if (truncatedText.length === 0) {
            // First word itself exceeds character limit, truncate it to 19 characters
            return `${word.slice(0, 19)}...`
          }
          break
        }
      }

      return `${truncatedText.trim()}...`
    }

    return text
  }

  const mapApiToViewAll = {
    'Seva Events': getSearchEventsList,
    'Media Coverage': getSearchMediaCoveragesList,
    'Development Projects': getSearchDevProjectsList,
    Miscellaneous: getSearchMiscellaneousList,
  }

  const handleSearch = (e) => {
    const searchText = e.target.value
    const regex = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/
    if (regex.test(searchText)) {
      return
    }
    setCardsData([])
    setTotalCardLength(0)
    containerRef.current.scrollTop = 0
    setSearchOffset(0)
    setSearchText(searchText)
    if (searchText?.length === 0) {
      return setDefaultCardData()
    }
    callSearchAPI(searchText, 0)
  }

  const callSearchAPI = (searchText, offset) => {
    const params = {
      searchText,
      offset,
      limit: 60,
      mpId: isFromNationwide || eventsForInitiative ? mpId : getIds(),
    }
    if (eventsForInitiative) {
      params.initiativeId = location?.state?.data[0]?.initiativeId || 0
      return dispatch(getSearchEventsList(params))
    }
    return dispatch(mapApiToViewAll[viewAllValue](params))
  }

  const handleSearchCrossClick = () => {
    if (searchText?.length === 0) {
      return
    }
    containerRef.current.scrollTop = 0
    setSearchOffset(0)
    setSearchText('')
    setDefaultCardData()
  }

  const loadMoreSearchResults = () => {
    setSearchOffset(cardsData?.length)
    callSearchAPI(searchText, cardsData?.length)
  }

  const getMoreResults = () => {
    if (searchText?.length > 0) {
      loadMoreSearchResults()
      return
    }
    const currentData = cardsData
    if (lastPaginatedResult?.length || !cardsData?.length) {
      const lastObj = currentData[currentData?.length - 1]
      const payload = {
        id: 0,
        prevId: lastObj.id,
        limit: 60,
      }
      getSevaUpdateResults(payload)
    }
  }
  const handleNoRecord = (e) => {
    switch (viewAllValue) {
      case 'Seva Events':
        if (!ongoingSevaInitiativesListByMpId?.length) {
          setOpenNewEvent(true)
        } else {
          setOpenSevaEvent(true)
        }
        break
      case 'Media Coverage':
        setAddMediaTitle('Media Coverage')
        setOpenMediaCoverage(true)
        break
      case 'Miscellaneous':
        setAddMediaTitle('Miscellaneous of')
        setOpenMediaCoverage(true)
        break
      case 'Development Projects':
        setOpenDevelopmentProjects(true)
        break
      default:
        break
    }
  }
  const noRecordStyles = useMemo(
    () => ({
      mt: 4,
      ml: 1,
      width: '75%',
      height: '60vh',
      borderRadius: 6,

      minWidth: '405%',
    }),
    []
  )

  const handleCloseMediaCoverage = () => {
    setOpenMediaCoverage(false)
  }

  const handleCloseNewEvent = () => {
    setOpenNewEvent(false)
  }

  const handleCloseDevelopmentProjects = () => {
    setOpenDevelopmentProjects(false)
  }

  const handleCloseSevaEvent = () => {
    setOpenSevaEvent(false)
  }

  return (
    <Box className="page-wrapper d-flex">
      {/* component 1 */}

      {newUser ? (
        <SideMenu active="Seva" user={newUser} profileData={mpProfileData} />
      ) : (
        <SideMenu active="Seva" user={user} profileData={mpProfileData} />
      )}

      <Box className="main-wrapper customscroll mainDiv">
        <Grid container className="cardGrid">
          <Box className="row viewAllHeader">
            <Typography component="h1" className="page-title mb-0 font-style-property">
              {viewAllValue} {totalCardLength ? '(' + totalCardLength?.toLocaleString('en-IN') + ')' : '(0)'}
            </Typography>
            <Box>
              <TextField
                className="search-filter-icon cursorshow"
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                value={searchText}
                inputProps={{ maxLength: 200 }}
                onChange={handleSearch}
                placeholder={`Search ${eventsForInitiative ? `Seva Events in ${title}` : viewAllValue}`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <img
                        className="searchIcon cursorshow"
                        alt="Search"
                        width={20}
                        height={21}
                        src={searchText?.length ? CrossIcon : searchIcon}
                        onClick={handleSearchCrossClick}
                      />
                    </InputAdornment>
                  ),
                }}
              >
                {' '}
              </TextField>
            </Box>
          </Box>
          <Grid container sx={{ mb: 2, mt: 2 }}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" sx={{ marginLeft: '-15px' }} />}
            >
              <Button underline="hover" className="button-underline-hover1" onClick={onFolderClick}>
                <Typography className="font-family-weight">
                  {isFromNationwide ? 'Nationwide Seva Updates' : eventsForInitiative ? title : 'My Seva Updates'}
                </Typography>
              </Button>
              {mpName && mpName !== 'My Seva Rank' && (
                <Button underline="hover" className="Breadcrumb-btn" onClick={handleMp}>
                  {mpName}
                </Button>
              )}
              <Button underline="hover" className="button-underline-hover2">
                <Typography className="font-family-weight">
                  {eventsForInitiative ? 'All Events' : viewAllValue}
                </Typography>
              </Button>
            </Breadcrumbs>
          </Grid>
          <Box ref={containerRef} className="customscroll scrollDiv " sx={{ minWidth: 'calc(100vw - 275px)' }}>
            <Box className="itemfixed4 mb-4">
              {/* <Grid item xs={8}> */}
              {cardsData?.length > 0
                ? cardsData?.map((item, index) => (
                    <Card
                      key={item?.id}
                      className="cursorshow cardStyle"
                      onClick={() => handleOpenInitiativeDetails(item, title)}
                    >
                      <CardMedia
                        className="image2acrd cardmedia-dimension"
                        component="img"
                        onError={(e) => (e.target.src = NoImageFound)}
                        src={
                          title === 'Seva aur Samarpan Campaign' || eventsForInitiative
                            ? item?.coverimage && JSON.parse(item?.coverimage)[0]
                            : item?.media && JSON.parse(item?.media)[0]
                        }
                        id={item?.id}
                        loading="lazy"
                        alt="new Image"
                      />
                      <ShareIcon
                        className="shareicone2 customshare"
                        key={index}
                        onClick={(e) => {
                          handleShare(e, item)
                        }}
                      />
                      <CardContent sx={{ minHeight: '150px', maxHeight: '150px' }}>
                        <Box className="mt-n2-1">
                          <Typography
                            sx={{
                              fontSize: '16px',
                              fontWeight: 'bold',
                              fontFamily: 'HK Grotesk',
                              color: '#505050',
                            }}
                            className="ellipsewehe12-qw "
                          >
                            {viewAllValue === 'Seva Events' || eventsForInitiative
                              ? truncateText(item.eventTitle, 25)
                              : viewAllValue === 'Miscellaneous' || viewAllValue === 'Media Coverage'
                              ? item?.title
                              : item?.projecttitle}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '14px',
                              fontWeight: '500',
                              fontFamily: 'HK Grotesk',
                              color: '#505050',
                            }}
                          >
                            {viewAllValue === 'Miscellaneous' || viewAllValue === 'Media Coverage'
                              ? item?.type
                              : viewAllValue === 'Seva Events' || eventsForInitiative
                              ? truncateText(item?.location, 50)
                              : item?.status}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: '12px',
                              fontWeight: '500',
                              fontFamily: 'HK Grotesk',
                              color: '#505050',
                            }}
                          >
                            Date-{' '}
                            {item.endDate
                              ? Moment(item.endDate).format('DD/MM/YYYY')
                              : Moment(item?.createdAt).format('DD/MM/YYYY')}
                          </Typography>
                          <Divider sx={{ padding: '5px' }} />
                          <Box className="display-margin">
                            <Box>
                              <ProfilePictureComponent
                                ProfilPic={item?.user?.user_avatar}
                                sxProfile={AllScreenCardProfile}
                              />
                              {/* {item?.user?.user_avatar ? (
                              <CardMedia
                                component="img"
                                alt="DP"
                                key={index}
                                src={item?.user?.user_avatar}
                                className="img-circle leader-circle-img mr-1 circularimage2 width-margin"

                              // style={{ position: 'absol}}
                              />
                            ) : (
                              <Box className="text-center">
                                <AccountCircleIcon
                                  sx={{
                                    fontSize: 'xx-large',
                                    width: '50px',
                                    height: 'auto',
                                    border: 0,
                                    color: '#EEEEEE',
                                  }}
                                />
                              </Box>
                            )} */}
                            </Box>
                            <Box className="card-details-container">
                              <Typography
                                sx={{
                                  fontSize: '14px',
                                  fontWeight: 'bold',
                                  fontFamily: 'HK Grotesk',
                                  color: '#356F92',
                                }}
                              >
                                {item.user?.user_name}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  fontFamily: 'HK Grotesk',
                                  color: '#797979',
                                }}
                              >
                                {item.user?.designation}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: '12px',
                                  fontWeight: '500',
                                  fontFamily: 'HK Grotesk',
                                  color: '#797979',
                                }}
                              >
                                {item.user?.constituency_name} - {item.user?.state_name}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  ))
                : !noRecordFound && (
                    <Box sx={{ minWidth: '20vw' }}>
                      <Paper className="d-flex flex-column" sx={noRecordStyles}>
                        <img className="imagecenter image-center-width-height" src={NotFoundImg} alt="" />
                        <Box className="viewAll-NoRecord">
                          <Typography variant="span" className="viewAll-span">
                            No results found :(
                          </Typography>
                        </Box>
                        {user !== 'Admin' && user !== 'Leader' ? (
                          <Box className="viewAll-NoRecord-add cursorshow">
                            <Typography
                              variant="span"
                              className="viewAll-NoRecord-add-span font-family-weight"
                              onClick={handleNoRecord}
                            >
                              Click here to create a{' '}
                              {['Seva Events', 'Development Projects'].includes(viewAllValue)
                                ? viewAllValue.slice(0, -1)
                                : ['Miscellaneous', 'Media Coverage'].includes(viewAllValue)
                                ? viewAllValue
                                : 'Seva Event'}
                            </Typography>
                          </Box>
                        ) : (
                          <Box />
                        )}
                      </Paper>
                    </Box>
                  )}
            </Box>
            {(searchText?.length > 0 && totalCardLength > cardsData?.length) ||
            (!searchText?.length && lastPaginatedResult?.length && !(lastPaginatedResult?.length < 60)) ? (
              <Box className="centerDiv">
                <Button variant="outlined" className="loadMoreButton" onClick={getMoreResults}>
                  Load More
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box className="d-none">
            <InitiativeDetails
              handleCloseInitiativeDetails={handleCloseInitiativeDetails}
              openInitiativeDetailsDialog={openInitiativeDetailsDialog}
              details={personDetails}
              onViewClickTitle={eventsForInitiative ? 'Seva aur Samarpan Campaign' : onViewClick}
              isEdit={isEdit}
              MpClick={MpClick}
              mpName={mpName}
              mpProfileData={mpProfileData}
              viewAllCheck={true}
              user={user}
              setCheckIsUpdate={setCheckIsUpdate}
              setOpedsByMpId={setOpedsByMpId}
              setEventByMpId={setEventByMpId}
              setDevelopmentProjectsByMpId={setDevelopmentProjectsByMpId}
              eventByMpId={eventByMpId}
              opedsByMpId={opedsByMpId}
              developmentProjectsByMpId={developmentProjectsByMpId}
              checkMySeva={checkMpIdOrMySeva}
              mpId={mpId}
              limit={60}
            />
          </Box>
          <Dialog open={mediaShare} onClose={() => setMediaShare(false)}>
            <DialogTitle>
              <Avatar
                alt="close"
                onClick={() => setMediaShare(false)}
                src={CrossIcon}
                className="closeIcon"
                sx={{
                  height: '30px',
                  width: '30px',
                  position: 'absolute',
                }}
              />
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: '#357092',
                  fontFamily: 'HK Grotesk',
                  fontSize: '26px',
                  fontWeight: 'bold',
                }}
              >
                Share to Social Media
              </Typography>

              <Box className="div-style-property">
                <Share data={shareData} title={title} />
              </Box>
              {/* <CloseIcon onClick={() => setAddMembers(false)} /> */}
            </DialogTitle>
          </Dialog>
          <AddSevaEvent
            openSevaEvent={openSevaEvent}
            handleCloseSevaEvent={handleCloseSevaEvent}
            eventByMpId={eventByMpId}
            mpId={mySeva ? getIds() : mpId}
            limit={defaultLimit}
          />
          {openNewEvent && (
            <CreateNewEvent
              openCreateEventDialog={openNewEvent}
              handleCloseCreateEvent={handleCloseNewEvent}
              eventByMpId={eventByMpId}
              mpId={mySeva ? getIds() : mpId}
              limit={defaultLimit}
            />
          )}
          {openMediaCoverage && (
            <AddMediaCoverage
              openMediaCoverage={openMediaCoverage}
              handleCloseMediaCoverage={handleCloseMediaCoverage}
              onViewClickTitle={addMediaTitle}
              opedsByMpId={opedsByMpId}
              mpId={mySeva ? getIds() : mpId}
              limit={defaultLimit}
            />
          )}
          {openDevelopmentProjects && (
            <AddDevelopmentProjects
              openDevelopmentProjects={openDevelopmentProjects}
              handleCloseDevelopmentProjects={handleCloseDevelopmentProjects}
              developmentProjectsByMpId={developmentProjectsByMpId}
              mpId={mySeva ? getIds() : mpId}
              limit={defaultLimit}
            />
          )}
        </Grid>
      </Box>
    </Box>
  )
}

export default ViewAllScreen
