import React from 'react';
import Constant from '../../utils/constant';
import { env } from '../../env';

export const getIds = () => {
  const CryptoJS = require('crypto-js');
  let v =
    localStorage.getItem('mpId') &&
    CryptoJS.AES.decrypt(localStorage.getItem('mpId'), env.REACT_APP_ID_KEY).toString(CryptoJS.enc.Utf8);

  return parseInt(v);
};

export const getRoles = () => {
  const CryptoJS = require('crypto-js');

  return (
    localStorage.getItem('userId') &&
    CryptoJS.AES.decrypt(localStorage.getItem('userId'), env.REACT_APP_ROLE_KEY).toString(CryptoJS.enc.Utf8)
  );
};
