import { CREATE_ALERT_REQUEST, CREATE_ALERT_SUCCESS, CREATE_ALERT_FAILURE } from '../types'
import axiosInstance from '../../../utils/api'

export const createAlertRequest = () => {
  return {
    type: CREATE_ALERT_REQUEST,
  }
}

export const createAlertSuccess = (value) => {
  return {
    type: CREATE_ALERT_SUCCESS,
    payload: value,
  }
}

export const createAlertError = (error) => {
  return {
    type: CREATE_ALERT_FAILURE,
    payload: error,
  }
}

export const createAlertAction =
  (id = null, payload, config) =>
  async (dispatch) => {
    dispatch(createAlertRequest)
    return axiosInstance
      .post(id ? `/api/admin/notification/create?id=${id}` : '/api/admin/notification/create', payload, config)
      .then((response) => {
        const result = response.data
        dispatch(createAlertSuccess(result))
        return response
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(createAlertError(errorMsg))
        throw new Error(error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message)
      })
  }
