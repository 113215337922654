import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useNavigate } from 'react-router-dom'
import { useLoading } from '../../../utils/LoadingContext'
import { useNotificationContext } from '../../../utils/NotificationContext'
import axiosInstance from '../../../utils/api'
import { useSelector } from 'react-redux'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { useEffect } from 'react'
import { pushTag } from '../../../utils/gtmservice'
import { defaultErrorMessage } from '../../../utils/constant'
import { Box } from '@mui/system'
import TemplateDialogCloseButton from '../TemplateDialogCloseButton/TemplateDialogCloseButton'
import TemplateDialogSubmitButton from '../TemplateDialogSubmitButton/TemplateDialogSubmitButton'

export default function DeleteInitiativeDialog(props) {
  const { handleCloseDeleteDialog, openDeleteDialog, initiativeId, message } = props
  const navigate = useNavigate()
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const userProfile = useSelector((state) => state?.userProfile?.data)

  useEffect(() => {
    pushTag({
      event: 'pageload',
      page_title: 'delete_initiative_pop_up',
    })
  }, [])

  const onDeleteClick = async () => {
    try {
      await postAuditLogs('delete', { i_id: initiativeId })
      pushTag({
        event: 'select_option',
        page_title: 'delete_initiative_pop_up',
        message: 'delete',
      })
      setLoading(true)
      const response = await axiosInstance.delete(`/api/delete/initiative/${initiativeId}`).then((response) => {
        return response
      })
      if (response.status === 200 || response.status === 201) {
        showNotification('Success', response.data.message, 'success')
        handleCloseDeleteDialog()
        navigate('/SevaInitiatives')
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    } finally {
      setLoading(false)
    }
  }

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('delete_initiative_popup', sqId, data)
  }

  React.useEffect(() => {
    postAuditLogs('pageload')
  }, [])

  return (
    <Box>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ maxWidth: '550px', margin: '0 auto' }}
      >
        <TemplateDialogCloseButton
          onClick={async () => {
            await postAuditLogs('close')
            handleCloseDeleteDialog()
          }}
        />
        <DialogContent>
          <DialogContentText
            sx={{
              minHeight: '100px',
              display: 'flex',
              alignItems: 'center',
              color: '#6C6C6C',
              fontFamily: 'HK Grotesk',
              fontSize: '22px',
              textAlign: 'center',
              mt: 2,
            }}
          >
            {message}
          </DialogContentText>
        </DialogContent>
        <TemplateDialogSubmitButton
          title={'Delete'}
          dialogActionSx={{ justifyContent: 'center' }}
          buttonSx={{
            fontFamily: 'HK Grotesk',
            ml: 2,
            width: '250px',
            mb: 2,
            height: '44px',
          }}
          onClick={onDeleteClick}
        />
      </Dialog>
    </Box>
  )
}
