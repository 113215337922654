import axiosInstance from '../../utils/api';
import { UPLOAD_MEDIA_REQUEST, UPLOAD_MEDIA_SUCCESS, UPLOAD_MEDIA_FAILURE } from './types';

const uploadMediaRequest = () => {
  return {
    type: UPLOAD_MEDIA_REQUEST,
  };
};
const uploadMediaSuccess = (value) => {
  return {
    type: UPLOAD_MEDIA_SUCCESS,
    payload: value,
  };
};
const uploadMediaFailure = (error) => {
  return {
    type: UPLOAD_MEDIA_FAILURE,
    payload: error,
  };
};

export const uploadMediaFiles = (payload, config) => async (dispatch) => {
  dispatch(uploadMediaRequest);
  return axiosInstance
    .post(`/api/url`, payload, config)
    .then((response) => {
      const result = response.data;
      dispatch(uploadMediaSuccess(result));
      return response;
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(uploadMediaFailure(errorMsg));
    });
};
