import { Pin } from '@mui/icons-material'
import './PinActivity.css'
import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import SettingsBreadCrumb from 'Components/Settings/SettingsBreadCrumb'
import PinnedActivityContainer from 'Components/PinnedActivityContainer/PinnedActivityContainer'
const PinActivity = () => {
  return (
    <Box className="page-wrapper d-flex seva-initiatives-outer-container">
      <SideMenu activeTab={'Settings'} />
      <Box className="main-wrapper customscroll w-100 p-5">
        <Typography component="h2" className="PinActivity-title-text  settings-header">
          Pin Activity
        </Typography>
        <SettingsBreadCrumb selectedOption="Pin Activity" />
        <PinnedActivityContainer />
      </Box>
    </Box>
  )
}

export default PinActivity
