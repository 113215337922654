import { Box, Typography, Button, Dialog, DialogTitle, Avatar } from '@mui/material'
import InitiativeDetails from '../../AllInitiativeReportRefactored/InitiativeDetails/initiativeDetails'
import CrossIcon from '../../../asserts/images/Close.svg'
import Share from '../../ReusableComponents.js/Share'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getIds } from '../../ReusableComponents.js/getIds'
import { useSelector } from 'react-redux'
import AddMediaCoverage from '../../SevaUpdateRefactored/AddMediaCoverage/AddMediaCoverage'
import AddDevelopmentProjects from '../../SevaUpdateRefactored/AddDevelopmentProjects/AddDevelopmentProjects'
import CreateNewEvent from '../../SevaUpdateRefactored/AddSevaEvent/CreateNewEvent'
import AddSevaEvent from '../../SevaUpdateRefactored/AddSevaEvent/AddSevaEvent'
import UpdateInitiativeReportDetails from '../../AllInitiativeReportRefactored/UpdateInitiativeReportDetails/UpdateInitiativeReportDialog'
import './ViewAllScreenContent.css'
import ViewAllScreenOngoingSevaInitiativesCard from '../ViewAllScreenOngoingSevaInitiativesCard/ViewAllScreenOngoingSevaInitiativesCard'
import ViewAllScreenCard from '../ViewAllScreenCard/ViewAllScreenCard'
import NoRecordFoundComponent from '../NoRecordFoundComponent/NoRecordFoundComponent'
import ViewAllReports from '../ViewAllReports/ViewAllReports'
import CreateInitiativeReportDialog from '../../AllInitiativeReportRefactored/CreateInitiativeReportDialog/createInitiativeReportDialog'
const defaultLimit = 10

const ViewAllScreenContent = ({
  containerRef,
  cardsData,
  viewAllTitle,
  searchText,
  totalCardLength,
  sendWallLogs,
  getSevaUpdateResults,
  setSearchOffset,
  callSearchAPI,
  noRecordFound,
  checkIsUpdate,
  setCheckIsUpdate,
  lastPaginatedResult,
  tenure,
}) => {
  const location = useLocation()
  const isEdit = location?.state?.isEdit
  const MpClick = location?.state?.MpClick
  const title = location?.state?.title
  const mpName = location?.state?.mpName
  const mpId = location?.state?.mpId
  const mySeva = location?.state?.mySeva
  const eventsForInitiative = location?.state?.eventsForInitiative
  const InitiativeReports = location?.state?.InitiativeReports
  const initiativeId = location?.state?.initiativeId
  const ongoingSevaInitiatives = location?.state?.ongoingSevaInitiatives

  const [isItemDeleted, setIsItemDeleted] = useState(false)
  const [isItemUpdated, setIsItemUpdated] = useState(false)
  const [openMediaCoverage, setOpenMediaCoverage] = useState(false)
  const [openDevelopmentProjects, setOpenDevelopmentProjects] = useState(false)
  const [openNewEvent, setOpenNewEvent] = useState(false)
  const [addMediaTitle, setAddMediaTitle] = useState('Media Coverage')
  const [openSevaEvent, setOpenSevaEvent] = useState(false)
  const [shareData, setShareData] = useState({})
  const [openInitiativeDetailsDialog, setOpenInitiativeDetailsDialog] = useState(false)
  const [personDetails, setPersonDetails] = useState()
  const [mediaShare, setMediaShare] = useState(false)
  const [onViewClick, setOnViewClick] = useState()
  const [eventByMpId, setEventByMpId] = useState(false)
  const [opedsByMpId, setOpedsByMpId] = useState(false)
  const [developmentProjectsByMpId, setDevelopmentProjectsByMpId] = useState(false)
  const [initiativeName, setInitiativeName] = useState()
  const [openUpdateIntiativeDialog, setOpenUpdateInitiativeDialog] = useState(false)
  const [ongoingDetails, setOngoingDetails] = useState()
  const [onViewClickValue, setOnViewClickValue] = useState()
  const [openInitiativeReportDialog, setOpenInitiativeReportDialog] = useState(false)
  const initiativeReportDetailsByMPIdAndIntiativeId = useSelector(
    (state) => state?.initiativeReportDetailsByMPIdAndIntiativeId?.data
  )
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])

  const viewAllTitleMap = {
    'Ongoing Seva Initiatives': 1,
    'Seva Events': 2,
    'Media Coverage': 3,
    'Development Projects': 4,
    Miscellaneous: 5,
    'Initiative Reports': 6,
    'Initiative Events': 7,
  }

  const onViewClickValueMap = {
    'Ongoing Seva Initiatives': 1,
    'Seva aur Samarpan Campaign': 2,
    'Media Coverage of': 3,
    'Development Projects': 4,
    'Miscellaneous of': 5,
  }

  const handleOpenInitiativeDetails = (item, title) => {
    const parameters = {
      useridtype: getIds(),
    }
    switch (viewAllTitleMap[viewAllTitle]) {
      case 2:
        parameters.e_id = item.id
        sendWallLogs('event_card', parameters)
        break
      case 3:
        parameters.bm_id = item.id
        sendWallLogs('bm_card', parameters)
        break
      case 4:
        parameters.dp_id = item.id
        sendWallLogs('dp_card', parameters)
        break
      case 5:
        parameters.m_id = item.id
        sendWallLogs('m_card', parameters)
        break
      default:
        break
    }
    setPersonDetails(item)
    setOnViewClick(title)
    setOnViewClickValue(
      eventsForInitiative ? onViewClickValueMap['Seva aur Samarpan Campaign'] : onViewClickValueMap[title]
    )
    setOpenInitiativeDetailsDialog(true)
  }

  const getMoreResults = () => {
    if (searchText?.length > 0) {
      loadMoreSearchResults()
      return
    }
    const currentData = cardsData
    if (lastPaginatedResult?.length || !cardsData?.length) {
      const lastObj =
        InitiativeReports || eventsForInitiative || ongoingSevaInitiatives
          ? currentData?.length
          : currentData[currentData?.length - 1]
      const payload = {
        id: 0,
        prevId: InitiativeReports || eventsForInitiative || ongoingSevaInitiatives ? lastObj : lastObj.id,
        limit: 60,
        isPrevTenure: tenure === 'Modi 2.0' ? true : false,
      }
      if (InitiativeReports) {
        payload.id = initiativeId
      }
      getSevaUpdateResults(payload)
    }
  }

  const loadMoreSearchResults = () => {
    setSearchOffset(cardsData?.length)
    callSearchAPI(searchText, cardsData?.length)
  }

  const handleCloseMediaCoverage = () => {
    setOpenMediaCoverage(false)
  }

  const handleCloseNewEvent = () => {
    setOpenNewEvent(false)
  }

  const handleCloseDevelopmentProjects = () => {
    setOpenDevelopmentProjects(false)
  }

  const handleCloseSevaEvent = () => {
    setOpenSevaEvent(false)
  }

  const handleCloseUpdateInitiativeDetails = (reset, reportFiles, setUploadFiles) => {
    reset()
    reportFiles([])
    setUploadFiles([])
    setOpenUpdateInitiativeDialog(false)
  }

  const handleCloseInitiativeDetails = (operationDetails) => {
    setIsItemDeleted(operationDetails?.deleted || false)
    setIsItemUpdated(operationDetails?.updated || false)
    setOpenInitiativeDetailsDialog(false)
  }

  const handleCloseInitiativeReportDialog = () => {
    setOpenInitiativeReportDialog(!openInitiativeReportDialog)
  }

  return (
    <Box className="ml-1">
      <Box
        ref={containerRef}
        className="customscroll scrollDiv "
        sx={{
          minWidth: 'calc(100vw - 275px)',
          height: InitiativeReports ? 'calc(100vh - 235px) !important' : null,
        }}
      >
        <Box className="itemfixed4 mb-4">
          {cardsData?.length > 0
            ? cardsData?.map((item, index) => (
                <>
                  {InitiativeReports ? (
                    <ViewAllReports item={item} />
                  ) : viewAllTitleMap[viewAllTitle] === 1 ? (
                    <ViewAllScreenOngoingSevaInitiativesCard
                      item={item}
                      setInitiativeName={setInitiativeName}
                      setOpenUpdateInitiativeDialog={setOpenUpdateInitiativeDialog}
                      setOngoingDetails={setOngoingDetails}
                      tenure={tenure}
                    />
                  ) : (
                    <ViewAllScreenCard
                      item={item}
                      index={index}
                      handleOpenInitiativeDetails={handleOpenInitiativeDetails}
                      viewAllTitle={viewAllTitle}
                      viewAllTitleMap={viewAllTitleMap}
                      sendWallLogs={sendWallLogs}
                      setMediaShare={setMediaShare}
                      setShareData={setShareData}
                    />
                  )}
                </>
              ))
            : !noRecordFound && (
                <NoRecordFoundComponent
                  viewAllTitle={viewAllTitle}
                  viewAllTitleMap={viewAllTitleMap}
                  setOpenNewEvent={setOpenNewEvent}
                  setOpenSevaEvent={setOpenSevaEvent}
                  setAddMediaTitle={setAddMediaTitle}
                  setOpenMediaCoverage={setOpenMediaCoverage}
                  setOpenDevelopmentProjects={setOpenDevelopmentProjects}
                  setOpenInitiativeReportDialog={setOpenInitiativeReportDialog}
                  setOpenUpdateInitiativeDialog={setOpenUpdateInitiativeDialog}
                  setOngoingDetails={setOngoingDetails}
                  setInitiativeName={setInitiativeName}
                />
              )}
        </Box>
        {(searchText?.length > 0 && totalCardLength > cardsData?.length) ||
        (!searchText?.length && lastPaginatedResult?.length && !(lastPaginatedResult?.length < 60)) ? (
          <Box className="centerDiv">
            <Button variant="outlined" className="loadMoreButton" onClick={getMoreResults}>
              Load More
            </Button>
          </Box>
        ) : null}
      </Box>
      <Dialog open={mediaShare} onClose={() => setMediaShare(false)}>
        <DialogTitle>
          <Avatar
            alt="close"
            onClick={() => setMediaShare(false)}
            src={CrossIcon}
            className="closeIcon"
            sx={{
              height: '30px',
              width: '30px',
              position: 'absolute',
            }}
          />
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              color: '#357092',
              fontFamily: 'HK Grotesk',
              fontSize: '26px',
              fontWeight: 'bold',
            }}
          >
            Share to Social Media
          </Typography>

          <Box className="div-style-property">
            <Share data={shareData} title={title} />
          </Box>
        </DialogTitle>
      </Dialog>
      <Box className="d-none">
        <InitiativeDetails
          handleCloseInitiativeDetails={handleCloseInitiativeDetails}
          openInitiativeDetailsDialog={openInitiativeDetailsDialog}
          details={personDetails}
          onViewClickTitle={eventsForInitiative ? 'Seva aur Samarpan Campaign' : onViewClick}
          onViewClickTitleValue={onViewClickValue}
          isEdit={isEdit}
          MpClick={MpClick}
          mpName={mpName}
          mpProfileData={mpProfileData}
          viewAllCheck={true}
          setCheckIsUpdate={setCheckIsUpdate}
          setOpedsByMpId={setOpedsByMpId}
          setEventByMpId={setEventByMpId}
          setDevelopmentProjectsByMpId={setDevelopmentProjectsByMpId}
          eventByMpId={eventByMpId}
          opedsByMpId={opedsByMpId}
          developmentProjectsByMpId={developmentProjectsByMpId}
          checkMySeva={mpId || mySeva ? true : false}
          mpId={mpId}
          limit={60}
          tenure={tenure}
        />
      </Box>

      <AddSevaEvent
        openSevaEvent={openSevaEvent}
        handleCloseSevaEvent={handleCloseSevaEvent}
        eventByMpId={eventByMpId}
        mpId={mySeva ? getIds() : mpId}
        limit={defaultLimit}
      />
      {openNewEvent && (
        <CreateNewEvent
          openCreateEventDialog={openNewEvent}
          handleCloseCreateEvent={handleCloseNewEvent}
          eventByMpId={eventByMpId}
          mpId={mySeva ? getIds() : mpId}
          limit={defaultLimit}
          initiativeId={initiativeId}
          initiativeName={title}
        />
      )}
      {openMediaCoverage && (
        <AddMediaCoverage
          openMediaCoverage={openMediaCoverage}
          handleCloseMediaCoverage={handleCloseMediaCoverage}
          onViewClickTitle={addMediaTitle}
          onViewClickTitleValue={viewAllTitleMap[addMediaTitle]}
          opedsByMpId={opedsByMpId}
          mpId={mySeva ? getIds() : mpId}
          limit={defaultLimit}
        />
      )}
      {openDevelopmentProjects && (
        <AddDevelopmentProjects
          openDevelopmentProjects={openDevelopmentProjects}
          handleCloseDevelopmentProjects={handleCloseDevelopmentProjects}
          developmentProjectsByMpId={developmentProjectsByMpId}
          mpId={mySeva ? getIds() : mpId}
          limit={defaultLimit}
        />
      )}
      {openUpdateIntiativeDialog && (
        <UpdateInitiativeReportDetails
          openUpdateIntiativeDialog={openUpdateIntiativeDialog}
          details={ongoingDetails}
          initiativeName={initiativeName}
          handleCloseUpdateInitiativeDetails={handleCloseUpdateInitiativeDetails}
          initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
          tenure={tenure}
        />
      )}
      {openInitiativeReportDialog && (
        <CreateInitiativeReportDialog
          open={openInitiativeReportDialog}
          initiativeId={initiativeId}
          initiativeName={title}
          handleClose={handleCloseInitiativeReportDialog}
        />
      )}
    </Box>
  )
}

export default ViewAllScreenContent
