export const threeCardsProfile = {
  width: '70px',
  height: '70px',
  marginBottom: '16px',
}
export const MPTableprofile = {
  width: '40px',
  height: '40px',
}

export const MPsevaProfile = {
  height: '80px',
  width: '80px',
}

export const MostFollowersProfile = {
  height: '70px',
  width: '70px',
}

export const searchMPProfile = {
  height: '70px',
  width: '70px',
  border: '2px',
  borderStyle: 'solid',
  borderRadius: '60%',
  position: 'absolute',
  top: '35px',
  left: '5px',
  borderColor: 'white',
}

export const searchmpNoProfile = {
  backgroundColor: 'white',
}

export const InitiativeProfile = {
  height: '80px',
  width: '80px',
}

export const AllInitiativeProfile = {
  width: '40px',
  height: '40px',
  marginLeft: '15px',
  marginTop: '15px',
  marginBottom: '5px',
}

export const AllScreenCardProfile = {
  height: '50px',
  width: '50px',
}

export const AssignMPProfile = {
  height: '27px',
  width: '27px',
}

export const SideMenuProfile = {
  height: '48px',
  width: '48px',
  borderRadius: '50%',
  border: 'solid 0.13rem #eee',
  boxShadow: '0px 14px 64px white',
}
