import { Box } from '@mui/material'
import HighlightsText from '../HighlightsText/HighlightsText'
import TableHighlights from '../../Highlights/TableHighlightsRefactor/TableHighlights'
import ProfileCard from '../../ReusableComponents.js/ProfileCard/ProfileCard'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getAllHighlights } from '../../../store/action/globalHighlights.js'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNotificationContext } from '../../../utils/NotificationContext'
import './HighlightsSection.css'
import { getRoles } from '../../ReusableComponents.js/getIds'
import SearchBar from '../SearchBar/SearchBar'
const HighlightsSection = ({ dataFilter, isMarqueeVisible, handleScroll, setHighlightsLoading, tenure }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data, loading } = useSelector((state) => state?.highlights)
  const [dataLoading, setDataLoading] = useState(loading)
  const { showNotification } = useNotificationContext()
  const [rows, setRows] = useState([])
  const userId = parseInt(getRoles())

  function createData(heading, value) {
    return { heading, value }
  }

  useEffect(() => {
    fetchData()
  }, [dataFilter, tenure])

  useEffect(() => {
    const rowsData = Object.keys(data ? data : [])?.map((val) => {
      return createData(val, data[val])
    })
    rowsData.splice(10, 1)
    setRows(rowsData)
  }, [data])

  const fetchData = async () => {
    try {
      setHighlightsLoading(true)
      const response = await dispatch(getAllHighlights(0, dataFilter, tenure))
      if (response.status !== 200) {
        showNotification('Error', response?.data?.message, 'error')
      }
    } catch (error) {
      showNotification('Error', error, 'error')
    } finally {
      setDataLoading(false)
      setHighlightsLoading(false)
    }
  }

  return (
    <Box className="d-flex flex-column" sx={{ maxWidth: '695px' }} id="custom-dialog-container">
      <Box>
        <SearchBar isMarqueeVisible={isMarqueeVisible} handleScroll={handleScroll} />
        <Box className="rtcar">
          <Box className="right-card-2" onClick={() => navigate('/SevaUpdates')}>
            <HighlightsText />
            <TableHighlights data={rows} dataLoading={dataLoading} />
          </Box>
          <Box className="mg-15">{userId === 1 && <ProfileCard page_title="leaderboard_page" />}</Box>
        </Box>
      </Box>
    </Box>
  )
}

export default HighlightsSection
