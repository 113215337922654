import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  InputAdornment,
  List,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Avatar,
  Box,
} from '@mui/material'
import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react'
import { debounce } from 'lodash'
import { useNavigate } from 'react-router-dom'
import searchIcon from '../../../asserts/images/Search.svg'
import closeIcon from '../../../asserts/images/close.png'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { searchMpList } from '../../../store/action/mpSearch'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import NoImageFound from '../../../asserts/images/noImageFound1.jpg'
import { getIds } from '../../ReusableComponents.js/getIds'
import './Search.css'

const Search = ({ user, marginRightValue, marginTopValue, inputValue, clearParentTextField }) => {
  const searchRef = useRef(null)
  const inputRef = useRef(null)
  const [searchInput, setSearchInput] = useState('')
  const [anchorEl, setAnchorEl] = React.useState(false)
  const [searchInputWidth, setSearchInputWidth] = useState(
    document.getElementById('custom-dialog-container').offsetWidth - 35
  )
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mpList = useSelector((state) => state?.mpSearchList?.data)
  const isSearching = useSelector((state) => state?.mpSearchList?.loading, shallowEqual)
  const loggedInId = getIds()

  const handleClose = () => {
    setSearchInput('')
    setAnchorEl(false)
    clearParentTextField(false)
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      // handleClose()
    }
  }

  const handleSearch = (e) => {
    const searchText = e.target.value
    const trimmedValue = searchText.trimStart()
    setSearchInput(trimmedValue)
  }

  const sendRequest = useCallback((value) => {
    value?.length ? setAnchorEl(true) : setAnchorEl(false)
    dispatch(searchMpList(value))
  }, [])

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendRequest, 1000)
  }, [sendRequest])

  const blockInvalidChar = (e) => {
    const invalidChars = ['#', '&', '+', '_', '!', '@', '%', '[', ']', '=', '*', '^', '-', '?', '/', '$', ')', '(']
    if ((e.keyCode === 32 && e.target.selectionStart === 0) || invalidChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    const trimmedValue = searchInput.trimStart()
    if (searchInput?.length > 0) debouncedSendRequest(searchInput)
  }, [searchInput])

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current !== null) {
        inputRef.current.focus()
      }
    }, 0)
  }, [inputRef])

  useEffect(() => {
    function handleResize() {
      setSearchInputWidth(document.getElementById('custom-dialog-container').offsetWidth - 35)
    }
    window.addEventListener('resize', handleResize)
    return (_) => {
      window.removeEventListener('resize', handleResize)
    }
  })

  const handleCardClick = (e, i) => {
    if (i?.hasOwnProperty('isMP') && i?.isMP == 0) {
      return
    }
    e.preventDefault()
    if (i.id === loggedInId) {
      navigate('/MySevaUpdates', {
        state: {
          MpName: 'My Seva Rank',
          user: user,
          mpId: loggedInId,
        },
      })
    } else {
      navigate('/MpSevaUpdates', {
        state: {
          MpName: i.user_name,
          user: user,
          mpId: i.id,
          mpClick: true,
          mpData: i && i,
        },
      })
    }
  }

  return (
    <Dialog
      open={inputValue}
      onClose={() => clearParentTextField(false)}
      // container={() => document.getElementById('custom-dialog-container')}
      sx={{
        '& .MuiDialog-container': {
          justifyContent: 'end',
          alignItems: 'start',
          marginTop: `${marginTopValue}px`,
          marginRight: `${marginRightValue}px`,
          '& .MuiDialog-paperScrollPaper': {
            backgroundColor: 'transparent !important',
            boxShadow: 'none !important',
            margin: '0px !important',
            overflow: 'hidden',
            alignItems: 'end',
          },
        },
      }}
    >
      <Box id="custom-dialog-container-1">
        <DialogTitle sx={{ padding: '15px 16px 0 16px' }}>
          <TextField
            className="search-filter-icon cursorshow"
            sx={{
              '& fieldset': { border: 'none' },
              width: `${searchInputWidth}px !important`,
            }}
            autoFocus
            onChange={handleSearch}
            onKeyDown={blockInvalidChar}
            inputRef={inputRef}
            value={searchInput}
            placeholder="Search MP / Constituency"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {searchInput ? (
                    <Avatar
                      variant="square"
                      className="cursorshow"
                      src={closeIcon}
                      sx={{ width: '21px', height: '21px' }}
                      onClick={handleClose}
                      alt="close"
                    />
                  ) : (
                    <Avatar
                      variant="square"
                      className="cursorshow"
                      alt="Search"
                      sx={{ width: '21px', height: '21px' }}
                      src={searchIcon}
                      onClick={() => {
                        inputRef.current.focus()
                      }}
                    />
                  )}
                </InputAdornment>
              ),
            }}
          />
        </DialogTitle>
        <DialogContent className="search-result-container">
          {mpList && searchInput !== '' && mpList.length !== 0 && anchorEl ? (
            <>
              <Box
                ref={searchRef}
                className="w-100 searchfixed-2 search-result-border-radius"
                sx={{ width: `${searchInputWidth}px !important` }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography
                    sx={{
                      margin: '2% 8% 2% 8%',
                      wordBreak: 'break-word',
                      display: 'inline-flex',
                      fontFamily: 'HK Grotesk',
                      color: '#2C2C2C',
                      fontSize: '24px',
                      fontWeight: 'bold',
                    }}
                    variant={'h5'}
                  >
                    Results for “{searchInput}”
                  </Typography>
                </Box>
                <List
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    zIndex: 1000,
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    maxHeight: '430px',
                    minHeight: '100px',
                    '& ul': { padding: 0 },
                    borderBottomLeftRadius: '20px',
                    borderBottomRightRadius: '20px',
                    paddingBottom: '10px',
                  }}
                  subheader={<li />}
                  className="customscroll"
                >
                  {mpList.map((i) => (
                    <Card
                      sx={{
                        margin: '2% 8% 2% 4%',
                        borderRadius: '14px',
                      }}
                      onClick={(e) => handleCardClick(e, i)}
                    >
                      <CardActionArea>
                        {i.user_avatar ? (
                          <Avatar
                            src={i.user_avatar}
                            alt={i.user_name}
                            className="card-image-search circularimagesearch"
                          />
                        ) : (
                          <Box>
                            <AccountCircleIcon
                              sx={{
                                fontSize: 'xx-large',
                                width: '70px',
                                height: 'auto',
                                border: 0,
                                background: 'white',
                                color: '#EEEEEE',
                                top: i.coverimage ? '45px' : '35px',
                              }}
                              className="card-image-search circularimagesearch"
                            />
                          </Box>
                        )}
                        {i.coverimage ? (
                          <CardMedia
                            className="mw-100"
                            component="img"
                            height="65px"
                            image={i.coverimage}
                            alt={i.user_name}
                            onError={(e) => (e.target.src = NoImageFound)}
                          />
                        ) : (
                          <CardMedia
                            className="mw-100"
                            component="img"
                            height="65px"
                            image={NoImageFound}
                            alt={i.user_name}
                            onError={(e) => (e.target.src = NoImageFound)}
                          />
                        )}
                        <CardContent sx={{ paddingLeft: '90px' }}>
                          <Typography className="serach-card-head" component="div">
                            {i.user_name}
                          </Typography>
                          <Typography className="serach-card-head-2 " color="text.secondary">
                            {i.designation && i.party && (
                              <>
                                {' '}
                                {i.designation} -{i.party},<br />
                              </>
                            )}
                            {i.constituency_name}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  ))}
                  {/* </ul> */}
                </List>{' '}
              </Box>
            </>
          ) : (
            searchInput?.length > 0 &&
            !isSearching && (
              <Box
                className="d-flex flex-column align-items-center searchfixed-2 pb-1"
                sx={{ width: `${searchInputWidth}px !important` }}
              >
                <Typography
                  className="text-center"
                  sx={{
                    margin: '2% 8% 2% 8%',
                    wordBreak: 'break-word',
                    display: 'inline-flex',
                    fontFamily: 'HK Grotesk',
                    color: '#2C2C2C',
                    fontSize: '24px',
                    fontWeight: 'bold',
                  }}
                  variant={'h5'}
                >
                  Results for “{searchInput.length > 8 ? searchInput.substring(0, 7) + '...' : searchInput}”
                </Typography>
                <Typography component="span">No Results Found</Typography>
              </Box>
            )
          )}
        </DialogContent>
      </Box>
    </Dialog>
  )
}

export default Search
