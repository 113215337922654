import { DELETE_ALERT_REQUEST, DELETE_ALERT_SUCCESS, DELETE_ALERT_FAILURE } from '../../action/types'

const initialState = {
  data: [],
  error: '',
}

export const deleteNotificationAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_ALERT_REQUEST:
      return {
        ...state,
      }
    case DELETE_ALERT_SUCCESS:
      return {
        data: action.payload,
      }
    case DELETE_ALERT_FAILURE:
      return {
        error: action.payload,
      }
    default:
      return state
  }
}
