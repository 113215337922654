import React from 'react'
import { Grid, Button, Breadcrumbs, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './index.css'

export default function MasterListBreadCrumb() {
  let navigate = useNavigate()

  const backToSettings = () => {
    navigate('/settings')
  }

  return (
    <Grid container sx={{ marginLeft: '-7px' }}>
      <Breadcrumbs aria-label="breadcrumb" separator={<Typography variant="h5">/</Typography>}>
        <Button underline="hover" className="ns-update" onClick={backToSettings}>
          Settings
        </Button>
        <Button underline="hover" className="ns-update os-text" onClick={backToSettings}>
          Admin Console
        </Button>
        <Button underline="hover" className="ns-update os-text breadcrumbs-title">
          Master List
        </Button>
      </Breadcrumbs>
    </Grid>
  )
}
