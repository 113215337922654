import { GET_ACTIVITY } from 'store/action/types'
const initialState = {
  data: [],
  error: '',
}
export const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVITY:
      return {
        data: action.payload,
      }

    default:
      return state
  }
}
