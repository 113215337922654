import {
  FETCH_TWITTER_MOST_NEW_FOLLOWERS_REQUEST,
  FETCH_TWITTER_MOST_NEW_FOLLOWERS_SUCCESS,
  FETCH_TWITTER_MOST_NEW_FOLLOWERS_FAILURE,
} from '../action/types';

const initialState = {
  data: [],
  error: '',
};

export const getTwitterMostNewFollowersReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TWITTER_MOST_NEW_FOLLOWERS_REQUEST:
      return {
        ...state,
      };
    case FETCH_TWITTER_MOST_NEW_FOLLOWERS_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_TWITTER_MOST_NEW_FOLLOWERS_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
