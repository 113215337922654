import { POST_CREATE_EVENT_FAILURE, POST_CREATE_EVENT_REQUEST, POST_CREATE_EVENT_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  error: '',
  loading: false,
};

export const createEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_CREATE_EVENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_CREATE_EVENT_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case POST_CREATE_EVENT_FAILURE:
      return {
        data: [],
        error: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};
