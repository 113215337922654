import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Box } from '@mui/material'
import {
  getSearchEventsList,
  getSearchMediaCoveragesList,
  getSearchDevProjectsList,
  getSearchMiscellaneousList,
} from 'store/action/searchSevaUpdates'
import {
  getOnlyMediaCoverageList,
  getOnlyMediaCoverageListByMpID,
  getOpedsAndBooksList,
  getOpedsAndBooksListByMpID,
} from 'store/action/mediaCoverageList'
import { getEventsList, getEventsListByMpId } from 'store/action/eventsList'
import { getMpProfile } from 'store/action/individualMP'
import { getDevelopmentProjectsList, getDevelopmentProjectsListByMpId } from 'store/action/developmentProjectList'
import { getSearchSevaInitiativesList } from 'store/action/searchSevaInitiatives'
import {
  getOngoingSevaIntiativesList,
  getOngoingSevaIntiativesListByMpId,
  getIntiativesReportByInitiativeId,
  getIntiativesEventByInitiativeId,
} from 'store/action/ongoingSevaInitiativesList'
import { useLoading } from 'utils/LoadingContext'
import { makeLogRequest } from 'utils/AuditLogs'
import ViewAllScreenHeader from 'Components/ViewAllScreenRefactored/ViewAllScreenHeader/ViewAllScreenHeader'
import ViewAllScreenContent from 'Components/ViewAllScreenRefactored/ViewAllScreenContent/ViewAllScreenContent'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import { getIds } from 'Components/ReusableComponents.js/getIds'
import './viewAllScreen.css'

const ViewAllScreen = () => {
  const { setLoading } = useLoading()
  const location = useLocation()
  const dispatch = useDispatch()
  const containerRef = useRef(null)
  const viewAllValue = location?.state?.viewAllValue || 'Seva Events'
  const mpId = location?.state?.mpId
  const isFromNationwide = location?.state?.isFromNationwide || true
  const eventsForInitiative = location?.state?.eventsForInitiative
  const InitiativeReports = location?.state?.InitiativeReports
  const initiativeId = location?.state?.initiativeId
  const tenure = location?.state?.tenure
  const [cardsData, setCardsData] = useState([])
  const [noRecordFound, setNoRecordFound] = useState(true)
  const [totalCardLength, setTotalCardLength] = useState(0)
  const [checkIsUpdate, setCheckIsUpdate] = useState(false)
  const [searchOffset, setSearchOffset] = useState(0)
  const [searchText, setSearchText] = useState('')
  const [lastPaginatedResult, setLastPaginatedResult] = useState([])
  const [isItemDeleted, setIsItemDeleted] = useState(false)
  const [isItemUpdated, setIsItemUpdated] = useState(false)
  const eventsLists = useSelector((state) => state?.eventsList?.data)
  const eventsList = eventsLists?.data
  const eventsListLength = eventsLists?.totalCount
  const eventListsByMpId = useSelector((state) => state.eventListByMpId?.data)
  const eventListByMpId = eventListsByMpId?.data
  const eventListLengthByMpId = eventListsByMpId?.totalCount
  const mediaCoverageLists = useSelector((state) => state?.onlyMediaCoverageList?.data)
  const mediaCoverageList = mediaCoverageLists?.data
  const mediaCoverageListLength = mediaCoverageLists?.totalCount
  const mediaCoverageListsByMpId = useSelector((state) => state?.onlyMediaCoverageListByMpId?.data)
  const mediaCoverageListByMpId = mediaCoverageListsByMpId?.data
  const mediaCoverageListLengthByMpId = mediaCoverageListsByMpId?.totalCount
  const opedAndBooksLists = useSelector((state) => state?.opedAndBooksList?.data)
  const opedAndBooksList = opedAndBooksLists?.data
  const opedAndBooksListLength = opedAndBooksLists?.totalCount
  const opedAndBooksListsByMpId = useSelector((state) => state?.opedAndBooksListByMpId?.data)
  const opedAndBooksListByMpId = opedAndBooksListsByMpId?.data
  const opedAndBooksListLengthByMpId = opedAndBooksListsByMpId?.totalCount
  const developmentProjectsLists = useSelector((state) => state?.developmentProjectsList?.data)
  const developmentProjectsList = developmentProjectsLists?.data
  const developmentProjectsListLength = developmentProjectsLists?.totalCount
  const developmentProjectListsByMpId = useSelector((state) => state.developmentProjectListByMpId?.data)
  const developmentProjectListByMpId = developmentProjectListsByMpId?.data
  const developmentProjectListLengthByMpId = developmentProjectListsByMpId?.totalCount
  const searchResultsSevaUpdates = useSelector((state) => state.searchSevaUpdates?.data)
  const searchResultsSevaInitiatives = useSelector((state) => state.searchSevaInitiatives?.data)
  const allInitiatives = useSelector((state) => state?.ongoingSevaInitiativesList?.data)
  const initiativesForMpList = useSelector((state) => state.ongoingSevaInitiativesListByMpId?.data)
  const allInitiative = allInitiatives?.initiative
  const initiativesForMp = initiativesForMpList?.initiative
  const allInitiativeLength = allInitiatives?.totalCount
  const initiativesForMpLength = initiativesForMpList?.totalCount
  const initiativeReportData = useSelector((state) => state?.intiativesReportByInitiativeId?.data)
  const initiativeReportDataLength = initiativeReportData?.totalReportCount
  const initiativeEventData = useSelector((state) => state?.intiativesEventByInitiativeId?.data)
  const initiativeEventDataLength = initiativeEventData?.totalEventCount

  const viewAlldata = location?.state?.data ? location?.state?.data : eventsList

  const walltype = {
    'Seva Events': 'seva_events_wall',
    'Media Coverage': 'books_and_media_coverage_wall',
    'Development Projects': 'development_project_wall',
    Miscellaneous: 'Miscellaneous_wall',
  }

  const mapSearchApiToViewAll = {
    'Initiative Events': getSearchEventsList,
    'Initiative Reports': getIntiativesReportByInitiativeId,
    'Ongoing Seva Initiatives': getSearchSevaInitiativesList,
    'Seva Events': getSearchEventsList,
    'Media Coverage': getSearchMediaCoveragesList,
    'Development Projects': getSearchDevProjectsList,
    Miscellaneous: getSearchMiscellaneousList,
  }

  const viewAllLengthForMp = {
    'Ongoing Seva Initiatives': initiativesForMpLength,
    'Seva Events': eventListLengthByMpId,
    'Media Coverage': mediaCoverageListLengthByMpId,
    Miscellaneous: opedAndBooksListLengthByMpId,
    'Development Projects': developmentProjectListLengthByMpId,
  }

  const viewAllLengthForAll = {
    'Initiative Events': initiativeEventDataLength,
    'Initiative Reports': initiativeReportDataLength,
    'Ongoing Seva Initiatives': allInitiativeLength,
    'Seva Events': eventsListLength,
    'Media Coverage': mediaCoverageListLength,
    Miscellaneous: opedAndBooksListLength,
    'Development Projects': developmentProjectsListLength,
  }

  const viewAllValueForAll = {
    'Initiative Events': initiativeEventData,
    'Initiative Reports': initiativeReportData,
    'Ongoing Seva Initiatives': allInitiative,
    'Seva Events': eventsList,
    'Media Coverage': mediaCoverageList,
    Miscellaneous: opedAndBooksList,
    'Development Projects': developmentProjectsList,
  }

  const viewAllValueForMp = {
    'Ongoing Seva Initiatives': initiativesForMp,
    'Seva Events': eventListByMpId,
    'Media Coverage': mediaCoverageListByMpId,
    Miscellaneous: opedAndBooksListByMpId,
    'Development Projects': developmentProjectListByMpId,
  }

  const mapPaginationApiToViewAll = {
    'Initiative Events': getIntiativesEventByInitiativeId,
    'Initiative Reports': getIntiativesReportByInitiativeId,
    'Ongoing Seva Initiatives': getOngoingSevaIntiativesList,
    'Seva Events': getEventsList,
    'Media Coverage': getOnlyMediaCoverageList,
    'Development Projects': getDevelopmentProjectsList,
    Miscellaneous: getOpedsAndBooksList,
  }

  const mapPaginationApiToViewAllForMp = {
    'Ongoing Seva Initiatives': getOngoingSevaIntiativesListByMpId,
    'Seva Events': getEventsListByMpId,
    'Media Coverage': getOnlyMediaCoverageListByMpID,
    'Development Projects': getDevelopmentProjectsListByMpId,
    Miscellaneous: getOpedsAndBooksListByMpID,
  }

  useEffect(() => {
    if (searchText?.length) {
      return
    }
    if (mpId) {
      setTotalCardLength(viewAllLengthForMp[viewAllValue])
    } else {
      setTotalCardLength(viewAllLengthForAll[viewAllValue])
    }
  }, [viewAllLengthForMp, viewAllLengthForAll, searchText, mpId])

  useEffect(() => {
    if (searchText !== '') {
      containerRef.current.scrollTop = 0
      setSearchOffset(0)
      setSearchText('')
    }
  }, [isItemUpdated])

  useEffect(() => {
    if (searchText?.length) {
      if (searchOffset === 0) {
        setCardsData(searchResultsSevaUpdates?.data)
      } else {
        pushingAPIResultsToCardsData(searchResultsSevaUpdates?.data)
      }
      setTotalCardLength(searchResultsSevaUpdates?.totalCount)
      if (searchResultsSevaUpdates?.data?.length > 0) {
        setNoRecordFound(true)
      } else if (searchResultsSevaUpdates?.data?.length === 0) {
        setTotalCardLength(0)
        setNoRecordFound(false)
      }
    }
  }, [searchResultsSevaUpdates])

  useEffect(() => {
    if (searchText?.length) {
      if (searchOffset === 0) {
        setCardsData(searchResultsSevaInitiatives?.data)
      } else {
        pushingAPIResultsToCardsData(searchResultsSevaInitiatives?.data)
      }
      setTotalCardLength(searchResultsSevaInitiatives?.totalCount)
      if (searchResultsSevaInitiatives?.data?.length > 0) {
        setNoRecordFound(true)
      } else if (searchResultsSevaInitiatives?.data?.length === 0) {
        setTotalCardLength(0)
        setNoRecordFound(false)
      }
    }
  }, [searchResultsSevaInitiatives])

  useEffect(() => {
    if (searchText?.length) {
      if (searchOffset === 0) {
        setCardsData(initiativeReportData?.reportdata)
      } else {
        pushingAPIResultsToCardsData(initiativeReportData?.reportdata)
      }
      setTotalCardLength(initiativeReportData?.totalReportCount)
      if (initiativeReportData?.length > 0) {
        setNoRecordFound(true)
      } else if (initiativeReportData?.totalReportCount === 0) {
        setTotalCardLength(0)
        setNoRecordFound(false)
      }
    }
  }, [initiativeReportData])

  const pushingAPIResultsToCardsData = (latestApiResponse) => {
    const newLastId = latestApiResponse[latestApiResponse?.length - 1]['id'] || 0
    const lastId = cardsData?.length ? cardsData[cardsData.length - 1]['id'] || 0 : Number.MAX_SAFE_INTEGER
    const currentFirstId = cardsData?.length ? cardsData[0]['id'] || 0 : Number.MIN_SAFE_INTEGER
    const newFirstId = latestApiResponse[0]['id'] || 0
    if (currentFirstId !== newFirstId && lastId !== newLastId) {
      const currentData = [...cardsData, ...latestApiResponse]
      setCardsData(currentData)
    } else {
      setCardsData(latestApiResponse)
    }
  }

  const handlePaginatedResults = () => {
    let latestApiResponse = []
    if (mpId) {
      latestApiResponse = viewAllValueForMp[viewAllValue]
    } else {
      latestApiResponse = viewAllValueForAll[viewAllValue]
    }
    if (InitiativeReports) {
      latestApiResponse = latestApiResponse?.reportdata
      if (latestApiResponse?.length === 0) {
        setNoRecordFound(false)
      }
    }
    if (eventsForInitiative) {
      latestApiResponse = latestApiResponse?.Eventdata
    }

    setLastPaginatedResult(latestApiResponse)
    if (isItemDeleted || isItemUpdated) {
      setCardsData(latestApiResponse)
      setIsItemDeleted(false)
      setIsItemUpdated(false)
      return
    }
    // if (latestApiResponse?.length && JSON.stringify(latestApiResponse) !== JSON.stringify(viewAlldata)) {
    //   pushingAPIResultsToCardsData(latestApiResponse)
    // } else if (JSON.stringify(latestApiResponse) === JSON.stringify(viewAlldata)) {
    //   setCardsData(latestApiResponse)
    // }
    if (latestApiResponse?.length) {
      pushingAPIResultsToCardsData(latestApiResponse)
    }
  }

  useEffect(() => {
    if (viewAllValue in viewAllValueForAll && !searchText?.length) {
      handlePaginatedResults()
    }
  }, [
    initiativesForMpList,
    allInitiatives,
    allInitiative,
    initiativesForMp,
    eventListByMpId,
    eventsList,
    mediaCoverageListByMpId,
    mediaCoverageList,
    opedAndBooksListByMpId,
    opedAndBooksList,
    developmentProjectListByMpId,
    developmentProjectsList,
    initiativeReportData,
    initiativeEventData,
  ])

  const getSevaUpdateResults = (payload) => {
    const fetchData = async (callBackFun) => {
      try {
        setLoading(true)
        await dispatch(callBackFun)
      } catch (error) {
        console.log('Error', error)
      } finally {
        setLoading(false)
      }
    }
    if (viewAllValue in mapPaginationApiToViewAll) {
      if (mpId) {
        payload.id = mpId
        fetchData(mapPaginationApiToViewAllForMp[viewAllValue](payload))
      } else if (InitiativeReports || eventsForInitiative) {
        payload.id = initiativeId
        fetchData(mapPaginationApiToViewAll[viewAllValue](payload))
      } else {
        fetchData(mapPaginationApiToViewAll[viewAllValue](payload))
      }
    } else {
      const data = viewAlldata
      setTotalCardLength(data?.length)
      return setCardsData(data)
    }
  }

  useEffect(() => {
    const payload = {
      id: 0,
      limit: 60,
      isPrevTenure: tenure === 'Modi 2.0' ? true : false,
    }
    getSevaUpdateResults(payload)
  }, [checkIsUpdate, mpId, viewAllValue])

  useEffect(() => {
    dispatch(getMpProfile(getIds()))
    const parameters = {
      useridtype: getIds(),
    }
    sendWallLogs('pageload', parameters)
  }, [])

  const sendWallLogs = async (type, parameters) => {
    await makeLogRequest(walltype[viewAllValue], type, parameters)
  }

  const callSearchAPI = (text, offset) => {
    const searchText = encodeURIComponent(text)
    const params = {
      searchText,
      offset,
      limit: 60,
    }
    if (eventsForInitiative || mpId) {
      params.mpId = mpId ? mpId : getIds()
    }
    if (eventsForInitiative) {
      params.initiativeId = initiativeId
    }
    if (InitiativeReports) {
      params.id = initiativeId
      params.prevId = offset
    }
    return dispatch(mapSearchApiToViewAll[viewAllValue](params))
  }

  return (
    <Box className="page-wrapper d-flex">
      <SideMenu activeTab={'Seva'} />

      <Box className="main-wrapper customscroll mainDiv">
        <Grid container className="cardGrid">
          <ViewAllScreenHeader
            viewAllTitle={viewAllValue}
            totalCardLength={totalCardLength}
            setTotalCardLength={setTotalCardLength}
            searchText={searchText}
            setSearchText={setSearchText}
            containerRef={containerRef}
            setCardsData={setCardsData}
            setIsItemUpdated={setIsItemUpdated}
            getSevaUpdateResults={getSevaUpdateResults}
            setSearchOffset={setSearchOffset}
            callSearchAPI={callSearchAPI}
            setLastPaginatedResult={setLastPaginatedResult}
            tenure={tenure}
          />
          <ViewAllScreenContent
            containerRef={containerRef}
            cardsData={cardsData}
            viewAllTitle={viewAllValue}
            searchText={searchText}
            totalCardLength={totalCardLength}
            sendWallLogs={sendWallLogs}
            getSevaUpdateResults={getSevaUpdateResults}
            setSearchOffset={setSearchOffset}
            callSearchAPI={callSearchAPI}
            noRecordFound={noRecordFound}
            checkIsUpdate={checkIsUpdate}
            setCheckIsUpdate={setCheckIsUpdate}
            lastPaginatedResult={lastPaginatedResult}
            tenure={tenure}
          />
        </Grid>
      </Box>
    </Box>
  )
}

export default ViewAllScreen
