import {
  INITIATIVES_OVERVIEW_FAILURE,
  INITIATIVES_OVERVIEW_REQUEST,
  INITIATIVES_OVERVIEW_SUCCESS,
} from '../action/types';

const initialState = {
  data: { requested: 0, ongoing: 0, events: 0 },
  error: '',
};

export const initiativesOverview = (state = initialState, action) => {
  switch (action.type) {
    case INITIATIVES_OVERVIEW_REQUEST:
      return {
        state,
      };
    case INITIATIVES_OVERVIEW_SUCCESS:
      return {
        data: action.payload,
      };
    case INITIATIVES_OVERVIEW_FAILURE:
      return {
        data: { requested: 4, ongoing: 0, events: 0 },
        error: action.payload,
      };
    default:
      return state;
  }
};
