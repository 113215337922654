import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import { getIds } from 'Components/ReusableComponents.js/getIds'
import { makeLogRequest } from 'utils/AuditLogs'
import { pushTag } from 'utils/gtmservice'
import { getMpProfile } from 'store/action/individualMP'
import MPHighlightsTable from 'Components/MpSevaUpdateRefactored/MPHighlightsTable/MPHighlightsTable'
import MPRankCard from 'Components/MpSevaUpdateRefactored/MPRankCard/MPRankCard'
import MpSevaUpdateContent from 'Components/MpSevaUpdateRefactored/MpSevaUpdateContent/MpSevaUpdateContent'
import SevaUpdateHeader from 'Components/SevaUpdateRefactored/SevaUpdateHeader/SevaUpdateHeader'
import MpSevaUpdateButton from 'Components/MpSevaUpdateRefactored/MPSevaUpdateButton/MpSevaUpdateButton'
import './MpSevaUpdates.css'
import Tenure from 'Components/ReusableComponents.js/Tenure/Tenure'

const MpSevaUpdateRefactored = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [active, setActive] = useState('Modi 3.0')
  const loggedInId = getIds()
  let mpName = location?.state?.MpName
  let mpId = location?.state?.mpId
  let MpClick = location?.state?.mpClick
  let mpData = location?.state?.mpData
  if (!mpName) {
    navigate('/SevaUpdates')
  }
  useEffect(() => {
    dispatch(getMpProfile(getIds()))
  }, [])
  const [pageTitle, setPageTitle] = useState('')

  useEffect(() => {
    document.getElementsByClassName('App')[0].scrollTop = 0
  }, [location])

  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const postAuditLogs = async (sqId) => {
    let requestData = {
      useridtype: getIds(),
    }
    requestData['mp_id'] = mpId && mpId
    await makeLogRequest('mp_seva_updates', sqId, requestData)
  }

  const sendGALog = (event, message = null, category = null, page_title_prop = null) => {
    const page_title = pageTitle || page_title_prop
    const payload = {
      event,
      page_title,
    }
    if (message) payload.message = message
    if (category) payload.category = category
    pushTag(payload)
  }

  useEffect(() => {
    const page_title = 'mp_seva_update_page'
    setPageTitle(page_title)
    postAuditLogs('pageload')
    sendGALog('pageload', null, null, page_title)
  }, [])
  return (
    <Box className="page-wrapper d-flex">
      <SideMenu activeTab={'Seva'} />
      <Box className="main-wrapper center-width customscroll">
        <Grid container>
          <Grid xs={12} md={8} lg={8} xl={8} className="max-width-max-content">
            <Box className="d-flex justify-content-between align-items-center">
              <Box className="w-100">
                <SevaUpdateHeader title={`${mpName}'s Seva Updates`} />

                <Box className="carouselpos">{active === 'Modi 3.0' && <MPRankCard mpId={mpId} mpName={mpName} />}</Box>
              </Box>
            </Box>

            <Tenure active={active} setActive={setActive} />

            <MpSevaUpdateContent
              pageTitle={pageTitle}
              sendGALog={sendGALog}
              mpProfileData={mpProfileData}
              mpId={mpId}
              mpName={mpName}
              MpClick={MpClick}
              mpData={mpData}
              active={active}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} xl={4}>
            <Box className={`rtcar Highlights-Table ${active !== 'Modi 3.0' ? 'is-active' : ''} `}>
              <Box className="right-card mb-3 ">
                <MPHighlightsTable mpId={mpId} active={active} />
              </Box>
              <MpSevaUpdateButton
                loggedInId={loggedInId}
                sendGALog={sendGALog}
                postAuditLogs={postAuditLogs}
                MpClick={MpClick}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default MpSevaUpdateRefactored
