import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { getEventsListByMpId, getEventsList } from '../../../store/action/eventsList'
import { useLoading } from '../../../utils/LoadingContext'
import { useNotificationContext } from '../../../utils/NotificationContext'
import CrossIcon from '../../../asserts/images/Close.svg'
import { getIntiativesReportByIdList } from '../../../store/action/ongoingSevaInitiativesList'
import { IconButton, Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import axiosInstance from '../../../utils/api'
import { getIds } from '../../ReusableComponents.js/getIds'
import { uploadMedia } from '../../../utils/mediaUpload'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { pushTag } from '../../../utils/gtmservice'
import { defaultErrorMessage } from '../../../utils/constant'

export default function DeleteIndividualEventDialog(props) {
  const {
    eventId,
    mpId,
    openDeleteDialog,
    handleCloseDeleteDialog,
    eventByMpId,
    handleCloseInitiativeDetails,
    details,
    isSevaEventEdit,
    setDeleteClick,
    handleCloseEventDetailsDialog,
    editEventDetails,
    setEventDeleted,
    limit = 10,
    tenure,
  } = props
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const dispatch = useDispatch()

  const postAuditLogs = async (sqId, e_id, i_id) => {
    let requestData = {
      useridtype: getIds(),
    }
    if (e_id !== null) {
      requestData['e_id'] = e_id
    }
    if (i_id !== null) {
      requestData['i_id'] = i_id
    }

    await makeLogRequest('delete_event_popup', sqId, requestData)
  }

  React.useEffect(() => {
    postAuditLogs('pageload', null, null)
  }, [])

  const onDeleteClick = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.delete(`/api/DeleteEvent/${mpId}/${eventId}`).then((response) => {
        return response
      })
      if (response.status === 200 || response.status === 201) {
        if (details) {
          handleCloseInitiativeDetails && handleCloseInitiativeDetails({ deleted: true })
        }
        if (isSevaEventEdit) {
          setDeleteClick(true)
        }
        if (editEventDetails) {
          if (setEventDeleted !== undefined) {
            setEventDeleted(true)
          }
          handleCloseEventDetailsDialog()
        }
        const payload = {
          id: 0,
          limit,
          isPrevTenure: tenure === 'Modi 2.0' ? true : false,
        }
        if (eventByMpId) {
          payload.id = mpId
          dispatch(getEventsListByMpId(payload))
        } else {
          dispatch(getEventsList(payload))
        }
      }
      showNotification('Success', response.data.message, 'success')
      handleCloseDeleteDialog()
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    } finally {
      setLoading(false)
    }
  }
  const buttonStyle = React.useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      ml: 2,
      width: '140px',
      mb: 4,
      height: '40px',
    }),
    []
  )
  return (
    <Box>
      <Dialog
        open={openDeleteDialog}
        onClose={async () => (
          postAuditLogs('close', null, null),
          pushTag({
            event: 'back',
            page_title: 'delete_event_pop_up',
          }),
          handleCloseDeleteDialog()
        )}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ maxWidth: '580px', margin: '0 auto', padding: '10px' }}
      >
        <DialogContent>
          <DialogContentText
            sx={{
              minHeight: '140px',
              display: 'flex',
              alignItems: 'center',
              color: '#6C6C6C',
              fontFamily: 'HK Grotesk',
              fontSize: '22px',
              textAlign: 'center',
              mt: 3,
            }}
          >
            Deleting this event will have an impact <br />
            on your Seva Score. Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            className="button-tr-2"
            sx={buttonStyle}
            onClick={async () => (
              postAuditLogs('delete', details?.id, details?.initiativeId),
              pushTag({
                event: 'proceed',
                page_title: 'delete_event_pop_up',
              }),
              onDeleteClick()
            )}
          >
            Yes
          </Button>
          <Button
            className="button-tr-citizen-cancel"
            sx={buttonStyle}
            onClick={async () => (
              postAuditLogs('close', null, null),
              pushTag({
                event: 'back',
                page_title: 'delete_event_pop_up',
              }),
              handleCloseDeleteDialog()
            )}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
