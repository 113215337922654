import { Grid, Box, FormHelperText, IconButton, ImageListItem, Typography } from '@mui/material'
import UploadIcon from '@mui/icons-material/Upload'
import { handleFileName, handleSrc, handleVideoSrc } from '../../ReusableComponents.js/reuseMethods'
import zipIcon from '../../../asserts/images/ZipIcon.svg'
import { useMemo } from 'react'
import { ImageLoader2 } from '../../ReusableComponents.js/ImageLoader2'
import unknownImg from '../../../asserts/images/folders.png'
import closeIconp from '../../../asserts/images/close-1.png'
import fileIcon from '../../../asserts/images/FileIcon.svg'
import { Link } from 'react-router-dom'
import VideoPlayerPreview from '../../ReusableComponents.js/VideoPlayerPreview'
import './UploadedFileComponent.css'

const UploadedFileComponent = ({
  i,
  input,
  ComponentTitle,
  getValues,
  iconButtonOnClick,
  iconButtonSx,
  registerFunction,
  fileFormats,
  assetRef,
  inputOnChange,
  boxClassName,
  handleOpenUploadDialog,
  selectedBanner,
  errors,
  handleDelete,
  inputOnClick,
}) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '10px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )

  return (
    <Grid item xs={6} md={6} lg={6} xl={6} sx={{ marginTop: '45px', marginBottom: '10px' }}>
      <Box className="preview-img">{ComponentTitle}</Box>
      {input.upload && (
        <Grid
          item
          sx={{
            pr: 1,
            pl: 1,
            margin: '0 auto',
            border: '3px #1976d2',
            width: '40%',
            borderRadius: '14px',
            ...(ComponentTitle === 'Image Preview'
              ? {
                  aspectRatio:
                    getValues(`${i}`)?.bannerSize === '16:9'
                      ? '16 / 9'
                      : getValues(`${i}`)?.bannerSize === '3:6'
                      ? '3 / 6'
                      : '0',
                  top: getValues(`${i}`)?.bannerSize === '3:6' ? '89px' : '45%',
                  left: getValues(`${i}`)?.bannerSize === '3:6' ? '-12px' : '0',
                }
              : {}),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              '& > :not(style)': {
                ...(ComponentTitle === 'Image Preview' ? { width: 13 } : { width: 50 }),
              },
            }}
          >
            <Box>
              <input
                type="file"
                multiple
                {...registerFunction()}
                accept={fileFormats.join(', ')}
                ref={assetRef}
                class="d-none"
                onChange={inputOnChange}
                onClick={inputOnClick}
              />
              {/* <Box className={boxClassName}>{ComponentTitle === 'Image Preview' ? 'Add Images' : 'Add Videos'}</Box> */}
            </Box>
            {(input?.imagesData?.length > 0 || input?.videosData?.length > 0) && (
              <Box>
                {ComponentTitle === 'Image Preview' ? (
                  ['zip', 'ai'].includes(handleSrc(input?.imagesData[0])) ? (
                    <ImageListItem key={i} className="bgfolder-zip  cursorshow">
                      <img
                        class="m-0"
                        // className='img-style-zip'
                        key={i}
                        src={
                          handleSrc(input?.imagesData[0]) === 'zip'
                            ? zipIcon
                            : handleSrc(input?.imagesData[0]) == 'ai' && fileIcon
                        } //?w=164&h=164&fit=crop&auto=format
                        srcSet={
                          handleSrc(input?.imagesData[0]) === 'zip'
                            ? zipIcon
                            : handleSrc(input?.imagesData[0]) == 'ai' && fileIcon && fileIcon
                        } //?w=164&h=164&fit=crop&auto=format
                        onClick={() => handleOpenUploadDialog(i, true, input?.imagesData)}
                      />
                      <Typography variant="subtitle1" sx={subtitleStyles}>
                        {handleFileName(input?.imagesData[0])}
                      </Typography>
                    </ImageListItem>
                  ) : (
                    <ImageLoader2
                      key={i}
                      src={handleSrc(input?.imagesData[0])}
                      srcSet={handleSrc(input?.imagesData[0])}
                      onClick={() => handleOpenUploadDialog(i, true, input?.imagesData)}
                      defaultImg={unknownImg}
                      subtitleStyles={subtitleStyles}
                      item={handleFileName(input?.imagesData[0])}
                      className={`${
                        selectedBanner[i]?.bannerSize === '16:9'
                          ? 'template-16-9'
                          : selectedBanner[i]?.bannerSize === '3:6'
                          ? 'template-3-6'
                          : 'template-1-1'
                      }`}
                    />
                  )
                ) : (
                  <>
                    {handleVideoSrc(input.videosData[0]) === 'video' && (
                      <VideoPlayerPreview
                        width="250"
                        height="150"
                        className="mar-class-video bg-white"
                        src={
                          input?.videosData[0].url || input?.videosData[0].url === ''
                            ? URL.createObjectURL(input?.videosData[0].file)
                            : input?.videosData[0]
                        }
                        controls
                        onClick={() => handleOpenUploadDialog(i, true, input?.videosData)}
                      />
                    )}
                    {['zip', 'unknown', 'mts'].includes(handleVideoSrc(input.videosData[0])) && (
                      <ImageListItem
                        key={i}
                        className={`${
                          handleVideoSrc(input.videosData[0]) === 'zip'
                            ? 'bgfolder-zip mar-class-video-zip '
                            : 'bgfolder-unknown'
                        }`}
                      >
                        <img
                          className="m-0"
                          key={i}
                          src={handleVideoSrc(input.videosData[0]) === 'zip' ? zipIcon : unknownImg}
                          onClick={() => handleOpenUploadDialog(i, true, input?.videosData)}
                        />
                        <Typography variant="subtitle1" sx={subtitleStyles}>
                          {handleFileName(input?.videosData[0])}
                        </Typography>
                      </ImageListItem>
                    )}
                  </>
                )}

                {input?.imagesData?.length > 1 || input?.videosData?.length > 1 ? (
                  <Link
                    style={
                      ComponentTitle === 'Video Preview'
                        ? {
                            top: handleVideoSrc(input.videosData[0]) !== 'video' ? '' : '',
                          }
                        : {}
                    }
                    className={
                      ComponentTitle === 'Image Preview'
                        ? `input-link-st-1 ${selectedBanner[i]?.bannerSize === '3:6' ? '' : ''}`
                        : 'add-files-link1'
                    }
                    // ${selectedBanner[i]?.bannerSize === '3:6' ? 'top1 left-1' : 'top2'}
                    onClick={() =>
                      handleOpenUploadDialog(
                        i,
                        true,
                        ComponentTitle === 'Image Preview' ? input?.imagesData : input?.videosData
                      )
                    }
                  >
                    +
                    {ComponentTitle === 'Image Preview' ? input?.imagesData?.length - 1 : input?.videosData?.length - 1}{' '}
                    More{' '}
                    {(
                      ComponentTitle === 'Image Preview' ? input?.imagesData?.length > 2 : input?.videosData?.length > 2
                    )
                      ? 'Files'
                      : 'File'}{' '}
                  </Link>
                ) : (
                  <Link
                    style={
                      ComponentTitle === 'Video Preview'
                        ? {
                            top: handleVideoSrc(input.videosData[0]) !== 'video' ? '' : '',
                          }
                        : {}
                    }
                    className={
                      ComponentTitle === 'Image Preview'
                        ? `input-link-st-1 ${selectedBanner[i]?.bannerSize === '3:6' ? '' : ''}`
                        : 'add-files-link2'
                    }
                    onClick={() =>
                      handleOpenUploadDialog(
                        i,
                        true,
                        ComponentTitle === 'Image Preview' ? input?.imagesData : input?.videosData
                      )
                    }
                  >
                    Add More Files{' '}
                  </Link>
                )}
                {ComponentTitle === 'Image Preview' ? (
                  <img
                    src={closeIconp}
                    id={i}
                    alt="Close"
                    onClick={() => handleDelete(i)}
                    className={`
                  ${
                    selectedBanner[i]?.bannerSize === '3:6'
                      ? 'imageclose-imge-36'
                      : selectedBanner[i]?.bannerSize === '16:9'
                      ? 'imageclose-imge-169'
                      : 'imageclose-imge' + (isSafari ? ' safari-specific-margin' : '')
                  }
                        ${['zip', 'ai'].includes(handleSrc(input?.imagesData[0])) && 'zip-mg'}
                `}
                  />
                ) : (
                  <img
                    src={closeIconp}
                    id={i}
                    alt="Close"
                    onClick={() => handleDelete(i)}
                    className="imageclose-imge-preview-video mg-mozilla-2"
                    style={{
                      marginLeft:
                        handleVideoSrc(input.videosData[0]) !== 'video'
                          ? !isSafari
                            ? '9px'
                            : '100px'
                          : !isSafari
                          ? '25px'
                          : '100px',
                      marginTop:
                        handleVideoSrc(input.videosData[0]) !== 'video'
                          ? !isSafari
                            ? '-300px'
                            : '-335px'
                          : !isSafari
                          ? '-300px'
                          : '-345px',
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
        </Grid>
      )}
      {errors[i] && (
        <FormHelperText class="p-error center ml-0">
          {ComponentTitle === 'Image Preview'
            ? errors && errors[i] && errors[i].images?.message
            : errors && errors[i] && errors[i].images?.videos}
        </FormHelperText>
      )}
    </Grid>
  )
}

export default UploadedFileComponent
