import { Dialog, IconButton, Grid, Box, DialogContent, DialogActions, Avatar } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useRef, useEffect } from 'react'
import { useNotificationContext } from '../../../utils/NotificationContext'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { useSelector } from 'react-redux'
import { allowedImageFormat, acceptedImageFormat } from '../../../utils/constant'
import { pushTag } from '../../../utils/gtmservice'
import './UploadCoverImage.css'
import TemplateDialogTitle from '../TemplateDialogTitle/TemplateDialogTitle'
import TemplateDialogCloseButton from '../TemplateDialogCloseButton/TemplateDialogCloseButton'
import TemplateDialogSubmitButton from '../TemplateDialogSubmitButton/TemplateDialogSubmitButton'

const UploadCoverImage = ({
  openUploadImageDialog,
  handleCloseUploadImageDialog,
  setImageName,
  setImage,
  preview,
  setPreview,
  handleClick,
  createNewClick,
}) => {
  const hiddenFileInput = useRef(null)
  const { showNotification } = useNotificationContext()
  const userProfile = useSelector((state) => state?.userProfile?.data)

  const onFileUpload = (event) => {
    const type = event.target.files[0].type
    const isRightFormat = allowedImageFormat.includes(type)
    if (!isRightFormat) {
      showNotification('Error', 'Please upload image in JPG or PNG format only', 'error')
      return
    }
    setImage(event.target.files[0])
    setPreview(URL.createObjectURL(event.target.files[0]))
    setImageName(event.target.files[0].name)
  }
  const onDelete = () => {
    setImage(null)
    setImageName(null)
    setPreview(null)
  }

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('upload_cover_popup', sqId, data)
  }

  useEffect(() => {
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'image_preview_pop_up_seva_initiative_page',
    })
  }, [])

  return (
    <Dialog onClose={handleCloseUploadImageDialog} open={openUploadImageDialog}>
      <TemplateDialogTitle title={'Image Preview'} />
      <TemplateDialogCloseButton
        onClick={async () => {
          await postAuditLogs('close')
          handleCloseUploadImageDialog()
        }}
      />
      <DialogContent>
        <Box>
          <Grid container sx={{ justifyContent: 'center' }}>
            {preview && (
              <Box sx={{ position: 'relative' }}>
                <Avatar
                  variant="square"
                  src={
                    createNewClick ? preview : preview?.toString().startsWith('blob') ? preview : JSON.parse(preview)[0]
                  }
                  alt="Preview"
                  className="preview-image"
                />

                <IconButton
                  aria-label="close"
                  onClick={onDelete}
                  sx={{
                    width: '12px',
                    height: '12px',
                    right: '5px',
                    top: '5px',
                    position: 'absolute',
                    color: (theme) => theme.palette.grey[500],
                    border: '1px solid #2C2C2C',
                    borderRadius: '50%',
                    color: '#fff',
                    backgroundColor: '#2C2C2C',
                    padding: '12px',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <React.Fragment>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <input
              type="file"
              ref={hiddenFileInput}
              className="d-none"
              onChange={onFileUpload}
              accept={acceptedImageFormat}
            />
            <TemplateDialogSubmitButton
              title={'Replace'}
              buttonSx={{
                p: 1,
                backgroundColor: '#ef7335',
                borderRadius: 4,
              }}
              onClick={async (e) => {
                handleClick(e, false)
                await postAuditLogs('replace')
                pushTag({
                  event: 'select_option',
                  page_title: 'seva_initiative_page',
                  message: 'image_preview_replace',
                })
              }}
            />
            <Box sx={{ flex: '1 1 auto' }} />
          </Box>
        </React.Fragment>
      </DialogActions>
    </Dialog>
  )
}

export default UploadCoverImage
