import React, { useMemo, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { useCookies } from 'react-cookie'
import CrossIcon from '../../asserts/images/Close.svg'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Typography, Box } from '@mui/material'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { useSelector } from 'react-redux'

export default function WhatsNewSection({ popupOpen, setPopupOpen, selectedWhatsNewData }) {
  const [openDialog, setOpenDialog] = React.useState(false)
  const [cookies, setCookies] = useCookies(['whatsnew'])
  const whatsNewData = useSelector((state) => state?.userProfile?.data?.whatsNew)
  const isOldWhatsNew = useSelector((state) => state?.userProfile?.data?.showOldWhatsNew)

  const newFeatures = useMemo(() => {
    if (selectedWhatsNewData) {
      return selectedWhatsNewData?.newFeatures?.some((item) => item.trim() !== '')
        ? selectedWhatsNewData?.newFeatures
        : []
    }
    return whatsNewData?.newFeatures?.some((item) => item.trim() !== '') ? whatsNewData?.newFeatures : []
  }, [whatsNewData, selectedWhatsNewData])

  const refinements = useMemo(() => {
    if (selectedWhatsNewData) {
      return selectedWhatsNewData?.refinements?.some((item) => item.trim() !== '')
        ? selectedWhatsNewData?.refinements
        : []
    }
    return whatsNewData?.refinements?.some((item) => item.trim() !== '') ? whatsNewData?.refinements : []
  }, [whatsNewData, selectedWhatsNewData])

  useEffect(() => {
    if (isOldWhatsNew === undefined) return
    if (!cookies.whatsnew && !isOldWhatsNew) {
      setOpenDialog(true)
    }
    if (popupOpen) {
      setOpenDialog(true)
    }
  }, [popupOpen, isOldWhatsNew])

  const handleCloseDialog = () => {
    setOpenDialog(false)
    setCookie()
    popupOpen && setPopupOpen(false)
  }

  const setCookie = () => {
    let d = new Date()
    d.setTime(d.getTime() + 60 * 60 * 24 * 1000 * 7)
    setCookies('whatsnew', true, { path: '/', expires: d })
  }

  return (
    <>
      <Dialog
        open={openDialog}
        sx={{
          height: '100vh',
          marginTop: '15px',
          '& .MuiDialog-container': {
            '& .MuiDialog-paperScrollPaper': {
              backgroundColor: 'transparent !important',
              boxShadow: 'none !important',
            },
          },
        }}
      >
        <Box className="d-flex justify-content-end mb-3 bg-transparent">
          <img alt="crossddd" onClick={() => handleCloseDialog()} src={CrossIcon} className="whats-new__closeIcon" />
        </Box>
        <DialogTitle className="customize-whatsnew-title">
          <Typography
            component="h1"
            sx={{
              fontSize: '24px',
              fontFamily: 'HK Grotesk',
              letterSpacing: 'normal',
            }}
          >
            WHAT'S NEW?
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: '#fff' }}>
          <Box className="mt-4">
            <Box className="pb-3">
              {newFeatures?.length > 0 && (
                <>
                  <List className="custom-whatnew-li mt-2" class="p-0">
                    <Box className="d-flex align-items-center mb-2">
                      {/* <h1 className='whats-new__heading--main'>
                    All views:{" "}
                  </h1> */}
                      <Button className="whats-new__btn--new-feature whats-new__btn">New Features</Button>
                    </Box>
                    {newFeatures
                      .filter((feature) => feature.length > 0)
                      .map((feature) => (
                        <ListItem className="justify-content-between mb-1" sx={{ padding: '0px' }}>
                          <Box className="d-flex align-items-start">
                            <CheckCircleIcon sx={{ color: '#47D764', width: '15px' }} />
                            <Typography
                              component="body1"
                              className="whats-new__li--para"
                              sx={{ fontFamily: 'HK Grotesk', letterSpacing: 'normal' }}
                            >
                              {feature}
                            </Typography>
                          </Box>
                        </ListItem>
                      ))}
                  </List>
                  <hr />
                </>
              )}
              <List className="custom-whatnew-li mt-3" class="p-0">
                {refinements?.length > 0 && (
                  <>
                    <Box className="d-flex align-items-center mb-2">
                      {/* <h1 className='whats-new__heading--main'>
                    MP view:
                  </h1> */}
                      <Button className="whats-new__btn--improvement whats-new__btn">Refinements</Button>
                    </Box>
                    {refinements
                      .filter((feature) => feature.length > 0)
                      .map((feature) => (
                        <ListItem className="justify-content-between mb-1" sx={{ padding: '0px' }} key={feature}>
                          <Box className="d-flex align-items-start">
                            <CheckCircleIcon sx={{ color: '#47D764', width: '15px' }} />
                            <Typography
                              component="body1"
                              className="whats-new__li--para"
                              sx={{ fontFamily: 'HK Grotesk', letterSpacing: 'normal' }}
                            >
                              {feature}
                            </Typography>
                          </Box>
                        </ListItem>
                      ))}

                    <br />
                  </>
                )}
                <ListItem className="mb-1" sx={{ padding: '0px' }}>
                  <Box className="d-flex align-items-start">
                    <Typography
                      component="body1"
                      className="whats-new__li--para"
                      sx={{ fontFamily: 'HK Grotesk', letterSpacing: 'normal' }}
                    >
                      To know more about what's new in Mera Saansad please check the{' '}
                      <a
                        href="https://dojg9gmo0iaw8.cloudfront.net/Release_Summary/Production_Release_Summary.pdf"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Release Note
                      </a>
                      .
                    </Typography>
                  </Box>
                </ListItem>
              </List>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}
