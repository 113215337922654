import { Box, Button } from '@mui/material'

const UploadAssetDialogButton = ({ className, autoFocus, buttonSx, buttonTitle, onClick, variant }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, mt: 2 }}>
      <Button variant={variant} className={className} autoFocus={autoFocus} onClick={onClick} sx={buttonSx}>
        {buttonTitle}
      </Button>
    </Box>
  )
}

export default UploadAssetDialogButton
