import { Box } from '@mui/material'
import InitiativeDetailCardHeader from '../InitiativeDetailCardHeader/InitiativeDetailCardHeader'
import IntiativesDetailsCard from './IntiativesDetailsCard'
import { useState } from 'react'
import { useSelector } from 'react-redux'
function InitiativeData({ data, setData, setDetails }) {
  const loading = useSelector((state) => state?.allInitiatives?.loading)
  const searchData = useSelector((state) => state?.searchInitiative?.data)
  const searchLoading = useSelector((state) => state?.searchInitiative?.loading)
  const [searchStatus, setSearchStatus] = useState(false)
  const allInitiatives = useSelector((state) => state?.allInitiatives?.data)

  return (
    <>
      <Box class="overview-card-details Testdemo all-initiatives-heading table-pos">
        <Box>
          <Box class="all-intiatives-header">
            <InitiativeDetailCardHeader setSearchStatus={setSearchStatus} />
            <Box className="all-initiatives--list-wrapper customscroll">
              <IntiativesDetailsCard
                data={data}
                passChildData={setData}
                setDetails={setDetails}
                loading={searchStatus ? searchLoading : loading}
                searchStatus={searchStatus}
                filteredList={searchStatus ? searchData : allInitiatives}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  )
}
export default InitiativeData
