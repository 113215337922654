import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, Box } from '@mui/material'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import Breadcrumb from 'Components/AllInitiativeReportRefactored/Breadcrumb/Breadcrumb'
import CreateReportButton from 'Components/AllInitiativeReportRefactored/CreateReportButton/CreateReportButton'
import ReportCarousel from 'Components/AllInitiativeReportRefactored/ReportCarousel/ReportCarousel'
import EventsUnderInitiative from 'Components/AllInitiativeReportRefactored/EventsUnderInitiative/EventsUnderInitiative'
import { getMpProfile } from 'store/action/individualMP'
import {
  getIntiativesReportByInitiativeId,
  getIntiativesEventByInitiativeId,
} from 'store/action/ongoingSevaInitiativesList'
import { getIds, getRoles } from 'Components/ReusableComponents.js/getIds'
import { makeLogRequest } from 'utils/AuditLogs'
import './AllInitiativesReport.css'

const AllInitiativeReports = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [value, setValue] = useState(0)
  const [personDetails, setPersonDetails] = useState()
  const [open, setOpen] = useState(false)
  const [openInitiativeDetailsDialog, setOpenInitiativeDetailsDialog] = useState(false)
  const [compareIdForCreateButton, setCompareIdForCreateButton] = useState(false)
  const initiativeReportData = useSelector((state) => state?.intiativesReportByInitiativeId?.data)
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const userId = parseInt(getRoles())

  let initiativeId = location?.state?.initiativeId
  let initiativeName = location?.state?.initiativeName
  let readonly = location?.state?.readonly
  let tenure = location?.state?.tenure

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const postAuditLogs = async (sqId, i_id, report_id, e_id) => {
    let requestData = {
      useridtype: getIds(),
    }
    if (i_id !== null) {
      requestData['i_id'] = i_id && i_id
    } else if (report_id !== null) {
      requestData['report_id'] = report_id
    } else if (e_id !== null) {
      requestData['e_id'] = e_id
    }
    await makeLogRequest('individual_initiatives_wall', sqId, requestData)
  }

  useEffect(() => {
    dispatch(getIntiativesReportByInitiativeId({ id: initiativeId, limit: 10 }))
    dispatch(getIntiativesEventByInitiativeId({ id: initiativeId, limit: 10 }))
    dispatch(getMpProfile(getIds()))
    postAuditLogs('pageload', initiativeId)
  }, [])

  useEffect(() => {
    const reportArray = initiativeReportData?.reportdata
    for (let i = 0; i < reportArray?.length; i++) {
      if (reportArray[i]?.userId === mpProfileData?.id) {
        setCompareIdForCreateButton(true)
        return
      } else if (reportArray[i]?.userId !== mpProfileData?.id) {
        setCompareIdForCreateButton(false)
      }
    }
  }, [initiativeReportData])

  return (
    <Box className="page-wrapper d-flex overflow-x-hidden">
      <SideMenu active={'Seva'} />

      <Box className="main-wrapper center-width customscroll scroll-property">
        <Box>
          <Box className="middle-wrapper">
            <Box className="d-flex justify-content-between align-items-center mb-4" sx={{ height: '1.5rem' }}>
              <Typography
                component="h1"
                className="page-title mb-0 header-text cmn-text"
                title={initiativeName && initiativeName}
              >
                {initiativeName ? initiativeName : 'Seva aur Samarpan Campaign'}
              </Typography>
            </Box>
            <Box>
              <Breadcrumb />

              {!readonly && userId !== 2 && userId !== 3 && !compareIdForCreateButton && (
                <CreateReportButton tenure={tenure} handleClickOpen={handleClickOpen} postAuditLogs={postAuditLogs} />
              )}
            </Box>
            <ReportCarousel
              open={open}
              setOpen={setOpen}
              value={value}
              handleChange={handleChange}
              personDetails={personDetails}
              setPersonDetails={setPersonDetails}
              setOpenInitiativeDetailsDialog={setOpenInitiativeDetailsDialog}
              tenure={tenure}
            />
            <EventsUnderInitiative
              value={value}
              handleChange={handleChange}
              openInitiativeDetailsDialog={openInitiativeDetailsDialog}
              personDetails={personDetails}
              setOpenInitiativeDetailsDialog={setOpenInitiativeDetailsDialog}
              tenure={tenure}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default AllInitiativeReports
