import {
  FETCH_MEDIA_COVERAGE_LIST_REQUEST,
  FETCH_MEDIA_COVERAGE_LIST_SUCCESS,
  FETCH_MEDIA_COVERAGE_LIST_FAILURE,
  FETCH_MEDIA_COVERAGE_TYPES_LIST_REQUEST,
  FETCH_MEDIA_COVERAGE_TYPES_LIST_SUCCESS,
  FETCH_MEDIA_COVERAGE_TYPES_LIST_FAILURE,
  FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST,
  FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS,
  FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE,
  FETCH_OPED_AND_BOOKS_LIST_REQUEST,
  FETCH_OPED_AND_BOOKS_LIST_SUCCESS,
  FETCH_OPED_AND_BOOKS_LIST_FAILURE,
  FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_REQUEST,
  FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_SUCCESS,
  FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_FAILURE,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_REQUEST,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_SUCCESS,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_FAILURE,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE,
} from './types'
import axiosInstance from '../../utils/api'

export const fetchMediaCoverageListRequest = () => {
  return {
    type: FETCH_MEDIA_COVERAGE_LIST_REQUEST,
  }
}
export const fetchMediaCoverageListSuccess = (data) => {
  return {
    type: FETCH_MEDIA_COVERAGE_LIST_SUCCESS,
    payload: data,
  }
}
export const fetchMediaCoverageListFailure = (error) => {
  return {
    type: FETCH_MEDIA_COVERAGE_LIST_FAILURE,
    payload: error,
  }
}

export const fetchMediaCoverageListByMpIdRequest = () => {
  return {
    type: FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST,
  }
}
export const fetchMediaCoverageListByMpIdSuccess = (data) => {
  return {
    type: FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS,
    payload: data,
  }
}
export const fetchMediaCoverageListByMpIdFailure = (error) => {
  return {
    type: FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE,
    payload: error,
  }
}

export const fetchMediaCoverageTypesListRequest = () => {
  return {
    type: FETCH_MEDIA_COVERAGE_TYPES_LIST_REQUEST,
  }
}
export const fetchMediaCoverageTypesListSuccess = (data) => {
  return {
    type: FETCH_MEDIA_COVERAGE_TYPES_LIST_SUCCESS,
    payload: data,
  }
}
export const fetchMediaCoverageTypesListFailure = (error) => {
  return {
    type: FETCH_MEDIA_COVERAGE_TYPES_LIST_FAILURE,
    payload: error,
  }
}

export const fetchOpedsAndBooksListRequest = () => {
  return {
    type: FETCH_OPED_AND_BOOKS_LIST_REQUEST,
  }
}
export const fetchOpedsAndBooksListSuccess = (data) => {
  return {
    type: FETCH_OPED_AND_BOOKS_LIST_SUCCESS,
    payload: data,
  }
}
export const fetchOpedsAndBooksListFailure = (error) => {
  return {
    type: FETCH_OPED_AND_BOOKS_LIST_FAILURE,
    payload: error,
  }
}

export const fetchOpedsAndBooksListByMpIdRequest = () => {
  return {
    type: FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_REQUEST,
  }
}
export const fetchOpedsAndBooksListByMpIdSuccess = (data) => {
  return {
    type: FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_SUCCESS,
    payload: data,
  }
}
export const fetchOpedsAndBooksListByMpIdFailure = (error) => {
  return {
    type: FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_FAILURE,
    payload: error,
  }
}

export const fetchOnlyMediaCoverageListRequest = () => {
  return {
    type: FETCH_ONLY_MEDIA_COVERAGE_LIST_REQUEST,
  }
}
export const fetchOnlyMediaCoverageListSuccess = (data) => {
  return {
    type: FETCH_ONLY_MEDIA_COVERAGE_LIST_SUCCESS,
    payload: data,
  }
}
export const fetchOnlyMediaCoverageListFailure = (error) => {
  return {
    type: FETCH_ONLY_MEDIA_COVERAGE_LIST_FAILURE,
    payload: error,
  }
}

export const fetchOnlyMediaCoverageListByMpIdRequest = () => {
  return {
    type: FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST,
  }
}
export const fetchOnlyMediaCoverageListByMpIdSuccess = (data) => {
  return {
    type: FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS,
    payload: data,
  }
}
export const fetchOnlyMediaCoverageListByMpIdFailure = (error) => {
  return {
    type: FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE,
    payload: error,
  }
}

export const getMediaCoverageList = (id) => async (dispatch) => {
  dispatch(fetchMediaCoverageListRequest)
  await axiosInstance
    .get(`/api/opeds/getallopeds/${id ? id : 0}`)
    .then((response) => {
      const result = response.data
      // const result = mpList
      dispatch(fetchMediaCoverageListSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchMediaCoverageListFailure(errorMsg))
    })
}

export const getMediaCoverageListByMpID = (mpId) => async (dispatch) => {
  dispatch(fetchMediaCoverageListByMpIdRequest)
  await axiosInstance
    .get(`/api/getOpEdDataByMpId/${mpId}`)
    .then((response) => {
      const result = response.data
      // const result = mpList
      dispatch(fetchMediaCoverageListByMpIdSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchMediaCoverageListByMpIdFailure(errorMsg))
    })
}

export const getMediaCoverageTypesList = () => async (dispatch) => {
  dispatch(fetchMediaCoverageTypesListRequest)
  await axiosInstance
    .get('/api/opeds/types')
    .then((response) => {
      const result = response.data
      // const result = mpList
      dispatch(fetchMediaCoverageTypesListSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchMediaCoverageTypesListFailure(errorMsg))
    })
}

export const getOnlyMediaCoverageList = (payload) => async (dispatch) => {
  const { id, limit, prevId = 0, type = 'Media Coverage', isPrevTenure = false } = payload
  let endPoint = `/api/opeds/getallopeds/${id ? id : 0}?opedtype=${type}&prevId=${prevId}&isPrevTenure=${isPrevTenure}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  dispatch(fetchOnlyMediaCoverageListRequest)
  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response.data
      // const result = mpList
      dispatch(fetchOnlyMediaCoverageListSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchOnlyMediaCoverageListFailure(errorMsg))
    })
}

export const getOnlyMediaCoverageListByMpID = (payload) => async (dispatch) => {
  const { id, limit, prevId = 0, type = 'Media Coverage', isPrevTenure = false } = payload
  let endPoint = `/api/getOpEdDataByMpId/${id}?opedtype=${type}&prevId=${prevId}&isPrevTenure=${isPrevTenure}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  dispatch(fetchOnlyMediaCoverageListByMpIdRequest)
  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response.data
      // const result = mpList
      dispatch(fetchOnlyMediaCoverageListByMpIdSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchOnlyMediaCoverageListByMpIdFailure(errorMsg))
    })
}

export const getOpedsAndBooksList = (payload) => async (dispatch) => {
  const { id, limit = 60, prevId = 0, type = 'Books,Book,Op-eds', isPrevTenure = false } = payload
  let endPoint = `/api/opeds/getallopeds/${id ? id : 0}?opedtype=${type}&prevId=${prevId}&isPrevTenure=${isPrevTenure}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  dispatch(fetchOpedsAndBooksListRequest)
  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response.data
      // const result = mpList
      dispatch(fetchOpedsAndBooksListSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchOpedsAndBooksListFailure(errorMsg))
    })
}

export const getOpedsAndBooksListByMpID = (payload) => async (dispatch) => {
  const { id, limit = 60, prevId = 0, type = 'Books,Book,Op-eds', isPrevTenure = false } = payload
  let endPoint = `/api/getOpEdDataByMpId/${id}?opedtype=${type}&prevId=${prevId}&isPrevTenure=${isPrevTenure}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  dispatch(fetchOpedsAndBooksListByMpIdRequest)
  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response.data
      // const result = mpList
      dispatch(fetchOpedsAndBooksListByMpIdSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchOpedsAndBooksListByMpIdFailure(errorMsg))
    })
}
