import { SEARCH_INITIATIVE_FAILURE, SEARCH_INITIATIVE_REQUEST, SEARCH_INITIATIVE_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  loading: true,
  error: '',
};

export const searchInitiative = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_INITIATIVE_REQUEST:
      return {
        state,
        loading: true,
      };
    case SEARCH_INITIATIVE_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case SEARCH_INITIATIVE_FAILURE:
      return {
        data: [],
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
