import React, { createContext, useContext, useState, useMemo } from 'react'
import { Snackbar, Alert, AlertTitle, Typography } from '@mui/material'
import RightIcon from '../asserts/images/Right Icon.svg'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

const NotificationContext = createContext({})

export const useNotificationContext = () => {
  return useContext(NotificationContext)
}

export const NotificationProvider = (props) => {
  const [notificationState, setNotificationState] = useState({
    isOpen: false,
    heading: 'Error',
    message: [],
    variant: 'error',
    size: 'medium',
    isFromMaster: false,
  })

  const hideNotification = () => {
    setNotificationState({
      ...notificationState,
      isOpen: false,
    })
  }

  const showNotification = (heading, message, variant, size, isFromMaster) => {
    setNotificationState({
      ...notificationState,
      isOpen: true,
      heading: heading ?? 'Error',
      message: message ?? 'Something Went Wrong',
      size: size ?? 'medium',
      variant: variant ?? 'error',
      isFromMaster: isFromMaster ?? false,
    })
  }

  const snackbarStyles = useMemo(
    () => ({
      minWidth: '450px',
      '& .MuiAlert-action': {
        color: '#000',
        marginTop: '-66px',
        marginRight: '-11px',
      },
    }),
    []
  )

  const alertStyles = useMemo(
    () => ({
      width: '100%',
      fontSize: '16px',
      fontWeight: '150',
      borderRadius: '15px',
      position: 'relative',
      marginTop: '4px !important',
      backgroundColor: '#ffffff',
      display: 'flex',
      alignItems: 'center',
      opacity: 1,
    }),
    []
  )

  const alertTitleStyles = useMemo(
    () => ({
      fontSize: '20px',
      fontWeight: '600',
      color: '#000000',
      marginLeft: '15px',
      marginTop: '9px',
    }),
    []
  )

  const errorOutlineIconStyles = useMemo(
    () => ({
      height: '90px',
      width: '29px',
      marginLeft: '31px',
      color: '#d32f2f',
    }),
    []
  )

  return (
    <NotificationContext.Provider value={{ showNotification, hideNotification }}>
      {props.children}
      <Snackbar
        open={notificationState.isOpen}
        onClose={hideNotification}
        sx={snackbarStyles}
        autoHideDuration={3000}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <Alert
          onClose={hideNotification}
          onPointerEnter={() =>
            setTimeout(() => {
              hideNotification()
            }, 3000)
          }
          icon={false}
          severity={notificationState.variant ?? 'error'}
          sx={alertStyles}
        >
          <div
            style={{
              backgroundColor: notificationState.variant === 'error' ? '#d32f2f' : '#47D764',
            }}
            className="notificationToastAlert"
          >
            {notificationState.variant !== 'error' ? (
              <img src={RightIcon} alt="Success Icon" className="successIcon" />
            ) : (
              <ErrorOutlineIcon className="toast-notification__icon" sx={errorOutlineIconStyles} />
            )}
          </div>
          <div className="alertTitle2">
            <AlertTitle sx={alertTitleStyles}>{notificationState?.heading ?? ' Error'}</AlertTitle>
            {notificationState?.isFromMaster ? (
              <div className="alertTitle3">
                {notificationState?.message.map((text) => (
                  <div className="toast-text mt-1" dangerouslySetInnerHTML={{ __html: text }} />
                ))}
              </div>
            ) : (
              <div className="alertTitle">{notificationState?.message ?? 'Something went wrong'}</div>
            )}
          </div>
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  )
}
