import {
  GET_USER_PROFILE_FAILURE,
  USER_PROFILE_REQUEST,
  GET_USER_PROFILE_SUCCESS,
  ERROR_UNAUTH,
  ERROR_CLOSED_CONNECTION,
} from '../action/types'

const initialState = {
  data: {},
  error: '',
  is401: false,
  is444: false,
}

export const getUserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_PROFILE_REQUEST:
      return {
        state,
      }
    case GET_USER_PROFILE_SUCCESS:
      return {
        data: action.payload,
      }
    case GET_USER_PROFILE_FAILURE:
      return {
        data: [],
        error: action.payload,
      }
    case ERROR_UNAUTH:
      return {
        is401: true,
      }
    case ERROR_CLOSED_CONNECTION:
      return {
        is444: true,
      }
    default:
      return state;
  }
}
