import { Box, Typography } from '@mui/material'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import SettingsBreadCrumb from 'Components/Settings/SettingsBreadCrumb'
import SettingsContent from 'Components/Settings/SettingsContent'
import 'pages/Settings/Settings.css'

const Settings = () => {
  return (
    <Box className="page-wrapper d-flex seva-initiatives-outer-container">
      <SideMenu activeTab={'Settings'} />
      <Box className="main-wrapper customscroll w-100 p-5">
        <Typography component="h1" className="seva-initiatives-title-text settings-header">
          Settings
        </Typography>
        <SettingsBreadCrumb />
        <SettingsContent />
      </Box>
    </Box>
  )
}

export default Settings
