import {
  CREATE_INITIATIVE_REPORT_BY_REQUEST,
  CREATE_INITIATIVE_REPORT_BY_SUCCESS,
  CREATE_INITIATIVE_REPORT_BY_FAILURE,
} from './types';
import axiosInstance from '../../utils/api';

export const createInitiativeReportByRequest = () => {
  return {
    type: CREATE_INITIATIVE_REPORT_BY_REQUEST,
  };
};
export const createInitiativeReportBySuccess = (value) => {
  return {
    type: CREATE_INITIATIVE_REPORT_BY_SUCCESS,
    payload: value,
  };
};
export const createInitiativeReportByFailure = (error) => {
  return {
    type: CREATE_INITIATIVE_REPORT_BY_FAILURE,
    payload: error,
  };
};

export const postCreateInitiativeReportBy = (id, payload, config, showNotification) => async (dispatch) => {
  dispatch(createInitiativeReportByRequest);
  return axiosInstance
    .post(`/api/report/create/${id ? id : 0}`, payload, config)
    .then((response) => {
      const result = response.data;
      dispatch(createInitiativeReportBySuccess(result));
      return response;
    })
    .catch((error) => {
      const errorMsg = error.message;
      showNotification('Error', error.response.data.message, 'error');
      dispatch(createInitiativeReportByFailure(errorMsg));
      return error;
    });
};
