import { Box, Typography } from '@mui/material'
import './MpDetails.css'

const MpDetails = ({ mpData }) => {
  return (
    <Box className={'d-flex flex-column  classnamrleaderelli'}>
      <Box className="cut-text">{mpData.user_name ? mpData.user_name : ' '}</Box>
      <Typography component="span" className="details-text cut-text">
        {mpData.designation ? mpData.designation : ' '}
      </Typography>
      <Typography component="span" className="details-text cut-text">
        {mpData.party ? mpData.party : ' '}
      </Typography>
      {mpData?.house === 'Rajya Sabha' ? (
        <Typography className="ellipsewe1" sx={{ color: '#707a89', fontSize: '12px', fontFamily: 'HK Grotesk medium' }}>
          State: {mpData?.state_name}
        </Typography>
      ) : (
        <Typography className="ellipsewe1" sx={{ color: '#707a89', fontSize: '12px', fontFamily: 'HK Grotesk medium' }}>
          Constituency: {mpData?.constituency_name}
        </Typography>
      )}
    </Box>
  )
}

export default MpDetails
