import { Typography } from '@mui/material'
import './ViewAllScreenHeaderTitle.css'
import { useLocation } from 'react-router-dom'

const ViewAllScreenHeaderTitle = ({ viewAllTitle, totalCardLength }) => {
  const location = useLocation()
  const InitiativeReports = location?.state?.InitiativeReports
  const eventsForInitiative = location?.state?.eventsForInitiative
  const title = location?.state?.title

  return (
    <Typography component="h1" className="page-title mb-0 font-style-property">
      {InitiativeReports ? title + ' ' + viewAllTitle : eventsForInitiative ? `All Events in ${title}` : viewAllTitle}{' '}
      {totalCardLength ? '(' + totalCardLength?.toLocaleString('en-IN') + ')' : '(0)'}
    </Typography>
  )
}

export default ViewAllScreenHeaderTitle
