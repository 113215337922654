import './ProjectStatus.css'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import PendingPng from '../../asserts/images/Pending.png'
import CompletePng from '../../asserts/images/Complete.png'
import StartPng from '../../asserts/images/About-to-Start.png'
import ProgressPng from '../../asserts/images/In-Progress.png'
function ProjectStatus({ status, colorSetter }) {
  function CustomIcons({ statusIcon }) {
    return <img src={statusIcon} width={'13px'} height={'13px'} alt="project status" />
  }
  return (
    <Box className="dev-project__status--container">
      <Box>
        {status?.toLowerCase() === 'completed' ? (
          <CustomIcons statusIcon={CompletePng} />
        ) : status?.toLowerCase() === 'in progress' ? (
          <CustomIcons statusIcon={ProgressPng} />
        ) : status?.toLowerCase() === 'pending' ? (
          <CustomIcons statusIcon={PendingPng} />
        ) : (
          status?.toLowerCase() === 'about to start' && <CustomIcons statusIcon={StartPng} />
        )}
      </Box>
      {status == 'In Progress' && (
        <Typography className="status__text--style status__text-color--progress">{status}</Typography>
      )}
      {status == 'About To Start' && (
        <Typography className={`status__text--style status__text-color--start-${colorSetter}`}>{status}</Typography>
      )}
      {status == 'Completed' && (
        <Typography className="status__text--style status__text-color--complete">{status}</Typography>
      )}
      {status == 'Pending' && (
        <Typography className="status__text--style status__text-color--pending">{status}</Typography>
      )}
    </Box>
  )
}

export default ProjectStatus
