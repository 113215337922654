import {
  FETCH_MEDIA_COVERAGE_LIST_REQUEST,
  FETCH_MEDIA_COVERAGE_LIST_SUCCESS,
  FETCH_MEDIA_COVERAGE_LIST_FAILURE,
  FETCH_MEDIA_COVERAGE_TYPES_LIST_REQUEST,
  FETCH_MEDIA_COVERAGE_TYPES_LIST_SUCCESS,
  FETCH_MEDIA_COVERAGE_TYPES_LIST_FAILURE,
  FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST,
  FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS,
  FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE,
  FETCH_OPED_AND_BOOKS_LIST_REQUEST,
  FETCH_OPED_AND_BOOKS_LIST_SUCCESS,
  FETCH_OPED_AND_BOOKS_LIST_FAILURE,
  FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_REQUEST,
  FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_SUCCESS,
  FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_FAILURE,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_REQUEST,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_SUCCESS,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_FAILURE,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS,
  FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE,
} from '../action/types';

const initialState = {
  data: [],
  error: '',
};
const typesInitialState = {
  data: [],
  error: '',
};

export const MediaCoverageListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEDIA_COVERAGE_LIST_REQUEST:
      return {
        ...state,
      };
    case FETCH_MEDIA_COVERAGE_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_MEDIA_COVERAGE_LIST_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const MediaCoverageListByMpIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST:
      return {
        ...state,
      };
    case FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const MediaCoverageTypesListReducer = (state = typesInitialState, action) => {
  switch (action.type) {
    case FETCH_MEDIA_COVERAGE_TYPES_LIST_REQUEST:
      return {
        ...state,
      };
    case FETCH_MEDIA_COVERAGE_TYPES_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_MEDIA_COVERAGE_TYPES_LIST_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const OpedsAndBooksListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OPED_AND_BOOKS_LIST_REQUEST:
      return {
        ...state,
      };
    case FETCH_OPED_AND_BOOKS_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_OPED_AND_BOOKS_LIST_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const OpedsAndBooksListByMpIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_REQUEST:
      return {
        ...state,
      };
    case FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const OnlyMediaCoverageListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ONLY_MEDIA_COVERAGE_LIST_REQUEST:
      return {
        ...state,
      };
    case FETCH_ONLY_MEDIA_COVERAGE_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_ONLY_MEDIA_COVERAGE_LIST_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const OnlyMediaCoverageListByMpIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST:
      return {
        ...state,
      };
    case FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
