import React, { useEffect, useState } from 'react'
import './SevaUpdate.css'
import first from '../../asserts/images/1st.png'
import one from '../../asserts/images/1.jpg'
import second from '../../asserts/images/2nd.png'
import two from '../../asserts/images/4.jpg'
import third from '../../asserts/images/3rd.png'
import three from '../../asserts/images/2.jpg'
import infoic from '../../asserts/images/Info.svg'
import SideMenu from '../SideMenu/SideMenu'
import TableHighlights from '../Highlights/TableHighlights'
import AddIcon from '@mui/icons-material/Add'
import RowImage from './RowImage/RowImage'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import { Button, Card, CardContent, Grid, Box, Typography, CardMedia } from '@mui/material'
import { useLocation } from 'react-router-dom'
import AddMediaCoverage from './AddMediaCoverage/AddMediaCoverage'
import AddDevelopmentProjects from './AddDevelopmentProjects/AddDevelopmentProjects'
import AddSevaEvent from './AddSevaEvent/AddSevaEvent'
import ProfileCard from '../ReusableComponents.js/ProfileCard/ProfileCard'
import { useNavigate } from 'react-router-dom'
import ViewAllScreen from './ViewAllScreen'
import { useDispatch, useSelector } from 'react-redux'
import { getEventsList, getEventsListByMpId } from '../../store/action/eventsList'
import {
  getMediaCoverageList,
  getOpedsAndBooksList,
  getMediaCoverageListByMpID,
  getOpedsAndBooksListByMpID,
  getOnlyMediaCoverageList,
  getOnlyMediaCoverageListByMpID,
} from '../../store/action/mediaCoverageList'
import { getDevelopmentProjectsList, getDevelopmentProjectsListByMpId } from '../../store/action/developmentProjectList'
import InitiativeDetails from './AllInitiativeReports/initiativeDetails'
import {
  getOngoingSevaIntiativesList,
  getOngoingSevaIntiativesListByMpId,
  getIntiativesReportByInitiativeIdAndMpId,
} from '../../store/action/ongoingSevaInitiativesList'
import { getRanksCount } from '../../store/action/getRanksCount'
import { getMpProfile } from '../../store/action/individualMP'
// control image
import ViewAllOngoingInitiatives from './ViewAllOngoingInitiatives'
import { useLoading } from '../../utils/LoadingContext'
import { useNotificationContext } from '../../utils/NotificationContext'
import Constant, { defaultErrorMessage } from '../../utils/constant'
import TwitterHighlights from '../Highlights/TwitterHighlights/TwitterHighlights'
import { height } from '@mui/system'
import { getIds } from '../ReusableComponents.js/getIds'
import CreateNewEvent from './AddSevaEvent/CreateNewEvent'
import { makeLogRequest } from '../../utils/AuditLogs'
import { pushTag } from '../../utils/gtmservice'
import { BootstrapTooltip1 } from '../ReusableComponents.js/ToolTip/ToolTipComponent'
import ProfilePictureComponent from '../ReusableComponents.js/ProfilePictureComponent'
import { MPsevaProfile } from '../ReusableComponents.js/ProfilePictureStyles'
const defaultLimit = 10

const SevaUpdates = ({ mpName, user, readonly, mySeva, mpId, isEdit, MpClick, mpData }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { showNotification } = useNotificationContext()
  const [showViewAll, setShowViewAll] = useState(false)
  const [showViewAllSevaInitiatives, setShowViewAllSevaInitiatives] = useState(false)
  let newUser = location?.state?.user
  const [MPid, setMPid] = useState()
  const [eventByMpId, setEventByMpId] = useState(false)
  const [opedsByMpId, setOpedsByMpId] = useState(false)
  const [openNewEvent, setOpenNewEvent] = useState(false)
  const [developmentProjectsByMpId, setDevelopmentProjectsByMpId] = useState(false)
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })
  const [addMediaTitle, setAddMediaTitle] = useState('Media Coverage')
  const [pageTitle, setPageTitle] = useState('')
  const dispatch = useDispatch()
  const eventsLists = useSelector((state) => state?.eventsList?.data)
  const eventsList = eventsLists?.data
  const eventListsByMpId = useSelector((state) => state.eventListByMpId?.data)
  const eventListByMpId = eventListsByMpId?.data
  const { setLoading } = useLoading()
  const mediaCoverageLists = useSelector((state) => state?.onlyMediaCoverageList?.data)
  const mediaCoverageList = mediaCoverageLists?.data
  const mediaCoverageListsByMpId = useSelector((state) => state?.onlyMediaCoverageListByMpId?.data)
  const mediaCoverageListByMpId = mediaCoverageListsByMpId?.data
  const opedAndBooksLists = useSelector((state) => state?.opedAndBooksList?.data)
  const opedAndBooksList = opedAndBooksLists?.data
  const opedAndBooksListsByMpId = useSelector((state) => state?.opedAndBooksListByMpId?.data)
  const opedAndBooksListByMpId = opedAndBooksListsByMpId?.data
  const developmentProjectsLists = useSelector((state) => state?.developmentProjectsList?.data)
  const developmentProjectsList = developmentProjectsLists?.data
  const developmentProjectListsByMpId = useSelector((state) => state.developmentProjectListByMpId?.data)
  const developmentProjectListByMpId = developmentProjectListsByMpId?.data
  const ongoingSevaInitiativesLists = useSelector((state) => state?.ongoingSevaInitiativesList?.data)

  const ongoingSevaInitiativesList = ongoingSevaInitiativesLists?.initiative

  const ongoingSevaInitiativesListsByMpId = useSelector((state) => state?.ongoingSevaInitiativesListByMpId?.data)
  const ongoingSevaInitiativesListByMpId = ongoingSevaInitiativesListsByMpId?.initiative

  const ranksCount = useSelector((state) => state?.ranksCount?.data[0])
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])

  const { lastRefreshedAt } = useSelector((state) => state?.mostViewedTweets?.data)

  const postAuditLogs = async (sqId, id, title) => {
    let requestData = {
      useridtype: getIds(),
    }

    if (!mySeva) {
      requestData['mp_id'] = mpId && mpId
    }

    if (title === 'Ongoing Seva Initiatives') {
      requestData['i_id'] = id
    } else if (title === 'Seva aur Samarpan Campaign') {
      requestData['e_id'] = id
    } else if (title === 'Media Coverage of') {
      requestData['bm_id'] = id
    } else if (title === 'Development Projects') {
      requestData['dp_id'] = id
    }

    await makeLogRequest(
      !mpName ? 'nationwide_seva_updates' : mySeva ? 'my_seva_updates' : 'mp_seva_updates',
      sqId,
      requestData
    )
  }

  const fetchAllData = async () => {
    try {
      setLoading(true)
      const payload = {
        id: 0,
        limit: defaultLimit,
        prevId: 0,
      }
      dispatch(getMpProfile(getIds()))
      if (mySeva || mpId) {
        payload.id = mySeva ? getIds() : mpId
        dispatch(getRanksCount(mpId))
        dispatch(getOngoingSevaIntiativesListByMpId(payload))
        dispatch(getEventsListByMpId(payload))
        dispatch(getOnlyMediaCoverageListByMpID(payload))
        dispatch(getOpedsAndBooksListByMpID(payload))
        dispatch(getDevelopmentProjectsListByMpId(payload))
      } else {
        dispatch(getOngoingSevaIntiativesList(payload))
        dispatch(getEventsList(payload))
        dispatch(getOnlyMediaCoverageList(payload))
        dispatch(getOpedsAndBooksList(payload))
        dispatch(getDevelopmentProjectsList(payload))
      }
    } catch (error) {
      showNotification('Error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    //call api to update store
    fetchAllData()
  }, [])

  useEffect(() => {
    const page_title = !mpName ? 'nationwide_update_page' : mySeva ? 'my_seva_update_page' : 'mp_seva_update_page'
    setPageTitle(page_title)
    postAuditLogs('pageload')
    sendGALog('pageload', null, null, page_title)
  }, [])

  const sendGALog = (event, message = null, category = null, page_title_prop = null) => {
    const page_title = pageTitle || page_title_prop
    const payload = {
      event,
      page_title,
    }
    if (message) payload.message = message
    if (category) payload.category = category
    pushTag(payload)
  }

  useEffect(() => {
    document.getElementsByClassName('App')[0].scrollTop = 0
  }, [location])

  useEffect(() => {
    setMPid(mpId)
  }, [mpId])

  const ranking = {
    1: first,
    2: second,
    3: third,
  }
  const sevaData = [
    {
      id: 1,
      image: one,
      heading: 'Seva Aur Samarpan Campaign',
      heading1: 'Indian Political System',
      name: 'Book',
      date: '12/12/2022',
      personName: 'Shri C.R. Patil',
      designation: 'President, BJP,Gujarat Pradesh',
      place: 'Navsari (Gujarat)',
      desc: "As part of prime minister Modi's birthday , I offered my seva to the nation , by conducting 6 events across my consituency",
    },
    {
      id: 2,
      image: two,
      heading: 'Atal Innovation Mission',
      heading1: 'Indian Political System',
      name: 'Book',
      date: '12/12/2022',
      personName: 'Shri C.R. Patil',
      designation: 'President, BJP,Gujarat Pradesh',
      place: 'Navsari (Gujarat)',
      desc: "As part of prime minister Modi's birthday , I offered my seva to the nation , by conducting 6 events across my consituency",
    },
    {
      id: 3,
      image: three,
      heading: 'Fit India Movement',
      heading1: 'Indian Political System',
      name: 'Book',
      date: '12/12/2022',
      personName: 'Shri C.R. Patil',
      designation: 'President, BJP,Gujarat Pradesh',
      place: 'Navsari (Gujarat)',
      desc: "As part of prime minister Modi's birthday , I offered my seva to the nation , by conducting 6 events across my consituency",
    },
    {
      id: 4,
      image: three,
      heading: 'Seva Aur Samarpan Campaign',
      heading1: 'Indian Political System',
      name: 'Book',
      date: '12/12/2022',
      personName: 'Shri C.R. Patil',
      designation: 'President, BJP,Gujarat Pradesh',
      place: 'Navsari (Gujarat)',
      desc: "As part of prime minister Modi's birthday , I offered my seva to the nation , by conducting 6 events across my consituency",
    },
    {
      id: 5,
      image: three,
      heading: 'Atal Innovation Mission',
      heading1: 'Indian Political System',
      name: 'Book',
      date: '12/12/2022',
      personName: 'Shri C.R. Patil',
      designation: 'President, BJP,Gujarat Pradesh',
      place: 'Navsari (Gujarat)',
      desc: "As part of prime minister Modi's birthday , I offered my seva to the nation , by conducting 6 events across my consituency",
    },
    {
      id: 6,
      image: three,
      heading: 'Fit India Movement',
      heading1: 'Indian Political System',
      name: 'Book',
      date: '12/12/2022',
      personName: 'Shri C.R. Patil',
      designation: 'President, BJP,Gujarat Pradesh',
      place: 'Navsari (Gujarat)',
      desc: "As part of prime minister Modi's birthday , I offered my seva to the nation , by conducting 6 events across my consituency",
    },
    {
      id: 7,
      image: three,
      heading: 'Seva Aur Samarpan Campaign',
      heading1: 'Indian Political System',
      name: 'Book',
      date: '12/12/2022',
      personName: 'Shri C.R. Patil',
      designation: 'President, BJP,Gujarat Pradesh',
      place: 'Navsari (Gujarat)',
      desc: "As part of prime minister Modi's birthday , I offered my seva to the nation , by conducting 6 events across my consituency",
    },
    {
      id: 8,
      image: three,
      heading: 'Atal Innovation Mission',
      heading1: 'Indian Political System',
      name: 'Book',
      date: '12/12/2022',
      personName: 'Shri C.R. Patil',
      designation: 'President, BJP,Gujarat Pradesh',
      place: 'Navsari (Gujarat)',
      desc: "As part of prime minister Modi's birthday , I offered my seva to the nation , by conducting 6 events across my consituency",
    },
    {
      id: 9,
      image: three,
      heading: 'Fit India Movement',
      heading1: 'Indian Political System',
      name: 'Book',
      date: '12/12/2022',
      personName: 'Shri C.R. Patil',
      designation: 'President, BJP,Gujarat Pradesh',
      place: 'Navsari (Gujarat)',
      desc: "As part of prime minister Modi's birthday , I offered my seva to the nation , by conducting 6 events across my consituency",
    },
    {
      id: 10,
      image: three,
      heading: 'Atal Innovation Mission',
      heading1: 'Indian Political System',
      name: 'Book',
      date: '12/12/2022',
      personName: 'Shri C.R. Patil',
      designation: 'President, BJP,Gujarat Pradesh',
      place: 'Navsari (Gujarat)',
      desc: "As part of prime minister Modi's birthday , I offered my seva to the nation , by conducting 6 events across my consituency",
    },
    {
      id: 11,
      image: one,
      heading: 'Fit India Movement',
      heading1: 'Indian Political System',
      name: 'Book',
      date: '12/12/2022',
      personName: 'Shri C.R. Patil',
      designation: 'President, BJP,Gujarat Pradesh',
      place: 'Navsari (Gujarat)',
      desc: "As part of prime minister Modi's birthday , I offered my seva to the nation , by conducting 6 events across my consituency",
    },
  ]

  const [openMediaCoverage, setOpenMediaCoverage] = useState(false)
  const [openDevelopmentProjects, setOpenDevelopmentProjects] = useState(false)
  const [openSevaEvent, setOpenSevaEvent] = useState(false)
  const [onMySevaUpdateClick, setOnMySevaUpdateClick] = useState(false)
  const [openInitiativeDetailsDialog, setOpenInitiativeDetailsDialog] = useState(false)
  const [viewAllValue, setViewAllValue] = useState('')
  const [personDetails, setPersonDetails] = useState()
  const [onViewClick, setOnViewClick] = useState()
  const initiativeReportDetailsByMPIdAndIntiativeId = useSelector(
    (state) => state?.initiativeReportDetailsByMPIdAndIntiativeId?.data
  )
  const [rankingHeaders, setRankingHeaders] = useState([])
  const isBdayCampaignEnabled = JSON.parse(localStorage.getItem('isBdayCampaignEnabled') || false)

  useEffect(() => {
    if (ranksCount) {
      const headerTabsData = [
        { id: 1, value: 'MP Seva Score', score: ranksCount?.sevaScore1 },
        { id: 2, value: 'Initiatives', score: ranksCount?.initiatives1 },
        { id: 3, value: 'Members Added', score: ranksCount?.memberAdded1 },
        { id: 4, value: 'Followers', score: ranksCount?.followers1 },
        {
          id: 5,
          value: 'Twitter Performance',
          score: ranksCount?.twitterPerformance1,
        },
        { id: 6, value: 'Op-Eds & Media Coverage', score: ranksCount?.opEds1 },
        { id: 7, value: 'Donations', score: ranksCount?.donation1 },
      ]
      if (isBdayCampaignEnabled) {
        headerTabsData.unshift({ id: 0, value: 'E-Cards Score', score: ranksCount?.bdayCampaignRank })
      }
      const sortedRankings = headerTabsData.sort((prev, current) => prev?.score - current?.score)
      setRankingHeaders(sortedRankings)
    }
  }, [ranksCount])

  const handleOpenMediaCoverage = (title) => {
    sendGALog('add_new', null, title.toLowerCase().includes('media') ? 'books_media' : 'miscellaneous')
    if (mySeva || mpId) {
      setOpedsByMpId(true)
    }
    setAddMediaTitle(title)
    setOpenMediaCoverage(true)
  }
  const handleCloseMediaCoverage = (reset, setImages, setFiles) => {
    reset()
    setImages([])
    setFiles([])
    setOpenMediaCoverage(false)
    setOpedsByMpId(false)
  }

  const handleOpenDevelopmentProjects = () => {
    sendGALog('add_new', null, 'dev_projects')
    if (mySeva || mpId) {
      setDevelopmentProjectsByMpId(true)
    }
    setOpenDevelopmentProjects(true)
  }
  const handleCloseDevelopmentProjects = (reset, setFiles, setUploadFiles, setTargetDate) => {
    reset()
    setFiles([])
    setUploadFiles([])
    setTargetDate(null)
    setOpenDevelopmentProjects(false)
    setDevelopmentProjectsByMpId(false)
  }

  const handleOpenSevaEvent = () => {
    if (mySeva || mpId) {
      setEventByMpId(true)
    }
    if (ongoingSevaInitiativesListByMpId?.length == 0) {
      setOpenNewEvent(true)
    } else {
      setOpenSevaEvent(true)
    }
  }

  // useEffect(() => {
  //   dispatch(getOngoingSevaIntiativesListByMpId(loggedInId));
  //   // dispatch(getInitiativeList())
  // }, []);

  const handleCloseNewEvent = () => {
    setOpenNewEvent(false)
    // setEventByMpId(false);
  }

  const handleCloseSevaEvent = () => {
    setOpenSevaEvent(false)
    // setEventByMpId(false);
  }
  const loggedInId = getIds()

  const handleMySevaUpdateClick = async (e) => {
    e.stopPropagation()
    await postAuditLogs(!mySeva ? 'my_seva_updates' : 'nationwide_seva_updates')
    const message = !mySeva ? 'my_seva_updates' : 'nationwide_seva_updates'
    sendGALog('select_option', message)
    // setOnMySevaUpdateClick(true);
    // if (onMySevaUpdateClick) {
    if (mySeva) {
      navigate('/SevaUpdates')
    } else {
      navigate('/MySevaUpdates', {
        state: {
          MpName: 'My Seva Rank',
          user: user,
          mpId: loggedInId,
          isEdit,
          MpClick,
        },
      })
    }
    // }
  }

  const viewAllhandleClick = (value, data, title) => {
    sendGALog('view', null, category[value])
    setShowViewAll(true)
    navigate('/SevaUpdates/viewAllSevaEvents', {
      state: {
        viewAllValue: value,
        data,
        title: title,
        mpName: mpName,
        mpData: mpData,
        isEdit: isEdit,
        checkMpIdOrMySeva: mpId || mySeva !== undefined ? true : false,
        mySeva: mySeva && true,
        mpId: mpId,
        isFromNationwide: !mySeva,
      },
    })
  }

  const category = {
    'Seva Events': 'seva_events',
    'Media Coverage': 'books_media',
    'Development Projects': 'dev_projects',
    Miscellaneous: 'miscellaneous',
  }

  const viewAllOngoingIntiativesHandleClick = (value, data, title) => {
    sendGALog('view', null, 'ongoing')
    setShowViewAll(true)
    navigate('/SevaUpdates/viewAllOngoingInitiatives', {
      state: {
        viewAllValue: value,
        data,
        title: title,
        mpName: mpName,
        mpId: mpId,
        mySeva: mySeva,
      },
    })
  }

  const handleCloseInitiativeDetails = () => {
    setOpenInitiativeDetailsDialog(false)
  }

  const handleCardClick = async (data) => {
    navigate('/SevaUpdates/allInitiativeReports', {
      state: {
        readonly: readonly,
        user: user,
        initiativeId: data?.id,
        initiativeName: data?.initiativeName,
        eventByMpId: eventByMpId,
        mpName: mpName,
        mpId: mpId,
      },
    })
  }

  const handleOpenInitiativeDetails = async (item, title, isOngoingSevaClick) => {
    await postAuditLogs(
      title === 'Ongoing Seva Initiatives'
        ? 'ongoing_seva_initiatives'
        : title === 'Seva aur Samarpan Campaign'
          ? 'seva_events'
          : title === 'Media Coverage of'
            ? 'books_and_media_coverage'
            : 'development_projects',
      item.id,
      title
    )
    if (isOngoingSevaClick) {
      try {
        const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(item?.id, mpId))
        if (response.status === 200 || response.status === 201) {
          if (response.data?.message) {
            showNotification('Error', response.data?.message, 'error')
          } else if (response?.data?.reportdata.length === 0) {
            handleCardClick(item)
          } else {
            setPersonDetails(item)
            setOnViewClick(title)
            setOpenInitiativeDetailsDialog(true)
          }
        }
      } catch (error) {
        showNotification('Error', defaultErrorMessage)
      }
      // dispatch(getIntiativesReportByInitiativeIdAndMpId(item?.id, mpId));
      // dispatch(getIntiativesReportByInitiativeIdAndMpId(59, 32))
    } else {
      setPersonDetails(item)
      setOnViewClick(title)
      setOpenInitiativeDetailsDialog(true)
    }
  }

  return (
    <Box className="page-wrapper d-flex">
      {/* component 1 */}

      {newUser ? (
        <SideMenu active="Seva" user={newUser} />
      ) : (
        <SideMenu active="Seva" user={user} profileData={mpProfileData} />
      )}

      <Box className="main-wrapper center-width customscroll">
        <Grid container>
          {/* <div className="row"> */}
          {/* <Grid md={12} lg={8} xl={8}> */}
          <Grid xs={12} md={8} lg={8} xl={8} className="max-width-max-content">
            <Box className="d-flex justify-content-between align-items-center carousel-pos">
              {mpName ? (
                <Box>
                  <Typography variant="h1" className="page-title mb-0 text-overflow-clip page-header">
                    {' '}
                    {mySeva ? 'My Seva Updates' : mpName + "'s Seva Updates"}
                  </Typography>

                  <Card sx={{ marginTop: '20px', borderRadius: '20px' }}>
                    <Box className={`ranks-card-div ${mySeva ? 'width-my-ranks' : 'width-mp-ranks'}`}>
                      <Box
                        className={`carousel-format ${mySeva && mpName ? 'classnamrleaderelli-sevarank' : 'classnamrleaderelli'
                          }`}
                      >
                        {
                          !mySeva && (
                            <ProfilePictureComponent ProfilPic={mpData?.user_avatar} sxProfile={MPsevaProfile} alt="" />
                          )
                          // (mpData?.user_avatar ? (
                          //   <Box>
                          //     <Avatar className="circular-image " src={mpData?.user_avatar} alt="" />
                          //   </Box>
                          // ) : (
                          //   <Box className="text-center pt-3">
                          //     <AccountCircleIcon
                          //       sx={{
                          //         fontSize: 'xx-large',
                          //         width: '70px',
                          //         height: 'auto',
                          //         color: '#EEEEEE',
                          //       }}
                          //     />
                          //   </Box>
                          // ))
                        }
                        <Box className={` ${mySeva && mpName ? 'textwe-next' : ''}`} sx={{ position: 'relative' }}>
                          <Box>
                            {mySeva && mpName}
                            {mySeva && (
                              <BootstrapTooltip1
                                title="Showing results of last 15 days. Refreshes everyday at midnight."
                                placement="bottom"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      fontSize: '16px',
                                      fontFamily: 'HK Grotesk',
                                      padding: '10px',
                                    },
                                  },
                                }}
                              >
                                <img src={infoic} className="info-icon ml-2 pt-0" alt="tooltip" />
                              </BootstrapTooltip1>
                            )}
                          </Box>
                        </Box>
                        {!mySeva && (
                          <Box
                            className={`d-flex flex-column  ${mySeva && mpName ? 'classnamrleaderelli-sevarank' : 'classnamrleaderelli '
                              }`}
                          >
                            <Box className="truncate-text">{mpName}</Box>
                            <Typography component="span" className="details-text1 truncate-text">
                              {mpData?.designation}
                            </Typography>
                            <Typography component="span" className="details-text1 truncate-text">
                              {mpData?.party}
                            </Typography>
                            <Typography component="span" className="details-text2 truncate-text">
                              Constituency: {mpData?.constituency_name ? mpData?.constituency_name : '-'}
                            </Typography>
                          </Box>
                        )}
                        {!mySeva && (
                          <BootstrapTooltip1
                            title="Showing results of last 15 days. Refreshes everyday at midnight."
                            placement="bottom"
                            componentsProps={{
                              tooltip: {
                                sx: {
                                  fontSize: '16px',
                                  fontFamily: 'HK Grotesk',
                                  padding: '10px',
                                },
                              },
                            }}
                          >
                            <img
                              src={infoic}
                              className="info-icon ml-0 mr-n3 pt-0 align-self-start mt-n3"
                              alt="tooltip"
                            />
                          </BootstrapTooltip1>
                        )}
                      </Box>
                      {ranksCount && (
                        <Box className={`nav-icon ${mySeva && mpName ? 'width-all' : 'widthall900'}`}>
                          <Tabs
                            // value={value}
                            // onChange={handleChange}
                            variant="scrollable"
                            scrollButtons={true}
                            aria-label="visible arrows tabs example"
                            sx={{
                              [`& .${tabsClasses.scrollButtons}`]: {
                                '&.Mui-disabled': { opacity: 0.3 },
                              },
                              '& .MuiTabs-flexContainer': {
                                marginLeft: '-25px',
                              },
                            }}
                          >
                            {rankingHeaders?.map((item, index) => (
                              <Card
                                sx={{
                                  borderRadius: 0,
                                  overflow: 'visible',
                                  minWidth: item.value.length > 22 ? '250px' : '200px',
                                }}
                              >
                                <CardContent>
                                  <Box className="d-flex justify-content-center align-items-center flex-column">
                                    {/* {item.score <= 3 ? <img
                                    key={index}
                                    src={item.score === 1 ? first : (item.score === 2 ? second : third)}
                                    // className="img-circle leader-circle-img mr-1"
                                    width="40"

                                  /> : */}
                                    {Object.keys(ranking).includes(item?.score?.toString()) ? (
                                      <Box sx={{ maxWidth: '35px' }}>
                                        <CardMedia
                                          component="img"
                                          alt="rank"
                                          src={ranking[item.score]}
                                          className="topicon"
                                        ></CardMedia>
                                      </Box>
                                    ) : (
                                      <Box>
                                        {/* <span className="scoreicon">
                                          {' '}
                                          {item?.score}
                                          <sup>th</sup>
                                        </span> */}

                                        <Typography
                                          // className="scoreicon "
                                          // display="flex"
                                          // alignItems="center"
                                          // justifyContent="center"
                                          className="scoreicon d-flex justify-content-center align-items-center"
                                        >
                                          <Typography className="score-icon-number" variant="span">
                                            {item?.score}
                                            <Typography variant="span" className="rank-super-tag">
                                              th
                                            </Typography>
                                          </Typography>
                                        </Typography>
                                      </Box>
                                    )}
                                    {/* } */}
                                    {/* <img width="38.49" src={first} className="position-absolute" alt="" /> */}
                                    {/* <img key={index} src={image.url} alt="" className="form-img__img-preview" /> */}
                                    <Box className="rank-format" key={index}>
                                      {item.value}
                                    </Box>
                                  </Box>
                                  {/* <IconButton
                                  className="card__icon" sx={{ position: "absolute", right: "5vh" }}
                                  onClick={() => handleRemove(file.id)}
                              >
                                  <CancelIcon role="button"></CancelIcon>
                              </IconButton> */}
                                </CardContent>
                              </Card>
                            ))}
                          </Tabs>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Box>
              ) : (
                <Typography variant="h1" className="page-title mb-0 page-header2">
                  Nationwide Seva Updates
                </Typography>
              )}
            </Box>

            <Box className="box-title d-flex justify-content-between align-items-center">
              <Box>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontFamily: 'HK Grotesk',
                    color: '#2C2C2C',
                    marginLeft: '38px',
                  }}
                >
                  {' '}
                  Ongoing Seva Initiatives
                </Typography>
              </Box>
              <Box className="view-btn-pos">
                {(mpId ? ongoingSevaInitiativesListByMpId : ongoingSevaInitiativesList)?.length > 3 && (
                  <Button
                    className="button-tr-citizen"
                    onClick={() =>
                      viewAllOngoingIntiativesHandleClick(
                        'Ongoing Seva Initiatives',
                        mpId ? ongoingSevaInitiativesListByMpId : ongoingSevaInitiativesList,
                        'Ongoing Seva Initiatives'
                      )
                    }
                  >
                    View All
                  </Button>
                )}
              </Box>
            </Box>

            <Box className="row-margin">
              <RowImage
                title="Ongoing Seva Initiatives"
                data={mpId ? ongoingSevaInitiativesListByMpId : ongoingSevaInitiativesList}
                user={user ? user : newUser}
                isOngoingSevaClick={true}
                mpName={mpName}
                handleOpenInitiativeDetails={handleOpenInitiativeDetails}
                readonly={readonly}
                eventByMpId={eventByMpId}
                mySeva={mySeva && true}
                mpId={mpId}
              />
            </Box>

            <Grid container sx={user === 'Admin' && readonly && { marginLeft: '38px' }}>
              <Grid item xs={user !== 'Admin' && !readonly ? 7.5 : 9.5}>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontFamily: 'HK Grotesk',
                    color: '#2C2C2C',
                    marginLeft: '38px',
                  }}
                >
                  Seva Events
                </Typography>
              </Grid>
              <Box
                className={
                  (mpId || mySeva ? eventListByMpId && eventListByMpId.length < 3 : eventsList && eventsList.length < 3)
                    ? 'consitioncss1'
                    : 'anotwe'
                }
              >
                <Box>
                  {user !== 'Admin' && !readonly && !MpClick && (
                    <Button
                      className="button-tr-citizen"
                      startIcon={<AddIcon sx={{ fontSize: '20', marginTop: '4px' }} />}
                      onClick={() => {
                        handleOpenSevaEvent()
                        postAuditLogs('add_new_seva_events')
                        sendGALog('add_new', null, 'seva_events')
                      }}
                      sx={{ mr: 1 }}
                    >
                      Add New
                    </Button>
                  )}
                </Box>
                <Box>
                  {(mpId || mySeva
                    ? eventListByMpId && eventListByMpId.length > 3
                    : eventsList && eventsList.length > 3) && (
                      <Button
                        className="button-tr-citizen"
                        onClick={() => (
                          viewAllhandleClick(
                            'Seva Events',
                            mpId || mySeva ? eventListByMpId : eventsList,
                            'Seva aur Samarpan Campaign'
                          ),
                          postAuditLogs('view_all_seva_events')
                        )}
                      >
                        View All
                      </Button>
                    )}
                </Box>
              </Box>
            </Grid>
            <Box className="row-margin">
              <RowImage
                title="Seva aur Samarpan Campaign"
                category="seva_events"
                page_title={pageTitle}
                data={mpId || mySeva ? eventListByMpId : eventsList}
                user={user ? user : newUser}
                onSevaEvent={true}
                handleOpenInitiativeDetails={handleOpenInitiativeDetails}
                mySeva={mySeva && true}
                mpName={mpName}
              // noImage={true}
              />
            </Box>
            <Grid container sx={user === 'Admin' && readonly && { marginLeft: '38px' }}>
              <Grid item xs={user !== 'Admin' && !readonly ? 7.5 : 9.5}>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontFamily: 'HK Grotesk',
                    color: '#2C2C2C',
                    marginLeft: '38px',
                  }}
                >
                  {' '}
                  Media Coverage
                </Typography>
              </Grid>

              <Box
                className={
                  (mpId || mySeva ? mediaCoverageListByMpId?.length < 3 : mediaCoverageList?.length < 3)
                    ? 'consitioncss1'
                    : 'anotwe'
                }
              >
                <Box>
                  {user !== 'Admin' && !readonly && !MpClick && (
                    <Button
                      className="button-tr-citizen"
                      startIcon={<AddIcon sx={{ fontSize: '20', marginTop: '4px' }} />}
                      onClick={() => (
                        handleOpenMediaCoverage('Media Coverage'), postAuditLogs('add_new_books_and_media')
                      )}
                      sx={{ mr: 1 }}
                    >
                      Add New
                    </Button>
                  )}
                </Box>
                <Box>
                  {(mpId || mySeva ? mediaCoverageListByMpId?.length > 3 : mediaCoverageList?.length > 3) && (
                    <Button
                      className="button-tr-citizen"
                      onClick={() => (
                        viewAllhandleClick(
                          'Media Coverage',
                          mpId || mySeva ? mediaCoverageListByMpId : mediaCoverageList,
                          'Media Coverage of'
                        ),
                        postAuditLogs('view_all_books_and_media')
                      )}
                    >
                      View All
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
            <Box className="row-margin">
              <RowImage
                title="Media Coverage of"
                category="books_media"
                page_title={pageTitle}
                data={mpId || mySeva ? mediaCoverageListByMpId : mediaCoverageList}
                user={user ? user : newUser}
                onMediaCoverage={true}
                handleOpenInitiativeDetails={handleOpenInitiativeDetails}
                mySeva={mySeva && true}
                mpName={mpName}
              // noImage={true}
              />
            </Box>
            <Grid container sx={user === 'Admin' && readonly && { marginLeft: '38px' }}>
              <Grid item xs={user !== 'Admin' && !readonly ? 7.5 : 9.5}>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontFamily: 'HK Grotesk',
                    color: '#2C2C2C',
                    marginLeft: '38px',
                  }}
                >
                  Development Projects
                </Typography>
              </Grid>

              <Box
                className={
                  (
                    mySeva || mpId
                      ? developmentProjectListByMpId && developmentProjectListByMpId.length < 3
                      : developmentProjectsList && developmentProjectsList.length < 3
                  )
                    ? 'consitioncss1'
                    : 'anotwe'
                }
              >
                <Box>
                  {user !== 'Admin' && !readonly && !MpClick && (
                    <Button
                      className="button-tr-citizen"
                      startIcon={<AddIcon sx={{ fontSize: '20', marginTop: '4px' }} />}
                      onClick={() => (handleOpenDevelopmentProjects(), postAuditLogs('add_new_development_projects'))}
                      sx={{ mr: 1 }}
                    >
                      Add New
                    </Button>
                  )}
                </Box>
                <Box>
                  {(mySeva || mpId
                    ? developmentProjectListByMpId && developmentProjectListByMpId.length > 3
                    : developmentProjectsList && developmentProjectsList.length > 3) && (
                      <Button
                        className="button-tr-citizen"
                        onClick={() => (
                          viewAllhandleClick(
                            'Development Projects',
                            mySeva || mpId ? developmentProjectListByMpId : developmentProjectsList,
                            'Development Projects'
                          ),
                          postAuditLogs('view_all_development_projects')
                        )}
                      >
                        View All
                      </Button>
                    )}
                </Box>
              </Box>
            </Grid>
            <Box className="row-margin">
              <RowImage
                title="Development Projects"
                category="dev_projects"
                page_title={pageTitle}
                data={mySeva || mpId ? developmentProjectListByMpId : developmentProjectsList}
                user={user ? user : newUser}
                isOngoingSevaClick={false}
                developmentProject={true}
                handleOpenInitiativeDetails={handleOpenInitiativeDetails}
                mySeva={mySeva && true}
                mpName={mpName}
              // noImage={true}
              />
            </Box>
            <Grid container sx={user === 'Admin' && readonly && { marginLeft: '38px' }}>
              <Grid item xs={user !== 'Admin' && !readonly ? 7.5 : 9.5}>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 'bold',
                    fontFamily: 'HK Grotesk',
                    color: '#2C2C2C',
                    marginLeft: '38px',
                  }}
                >
                  {' '}
                  Miscellaneous
                </Typography>
              </Grid>

              <Box
                className={
                  (mpId || mySeva ? opedAndBooksListByMpId?.length < 3 : opedAndBooksList?.length < 3)
                    ? 'consitioncss1'
                    : 'anotwe'
                }
              >
                <Box>
                  {user !== 'Admin' && !readonly && !MpClick && (
                    <Button
                      className="button-tr-citizen"
                      startIcon={<AddIcon sx={{ fontSize: '20', marginTop: '4px' }} />}
                      onClick={() => handleOpenMediaCoverage('Miscellaneous of')}
                      sx={{ mr: 1 }}
                    >
                      Add New
                    </Button>
                  )}
                </Box>
                <Box>
                  {(mpId || mySeva ? opedAndBooksListByMpId?.length > 3 : opedAndBooksList?.length > 3) && (
                    <Button
                      className="button-tr-citizen"
                      onClick={() => {
                        viewAllhandleClick(
                          'Miscellaneous',
                          mpId || mySeva ? opedAndBooksListByMpId : opedAndBooksList,
                          'Miscellaneous of'
                        )
                      }}
                    >
                      View All
                    </Button>
                  )}
                </Box>
              </Box>
            </Grid>
            <Box className="row-margin">
              <RowImage
                title="Miscellaneous of"
                category="misscellaneous"
                page_title={pageTitle}
                data={mpId || mySeva ? opedAndBooksListByMpId : opedAndBooksList}
                user={user ? user : newUser}
                onMediaCoverage={true}
                handleOpenInitiativeDetails={handleOpenInitiativeDetails}
              // noImage={true}
              />
            </Box>
          </Grid>
          {/* <Grid md={12} lg={4} xl={4}> */}
          <Grid xs={12} md={4} lg={4} xl={4} className={mySeva || !mpName ? 'mtl-20' : 'mtl20'} sx={mpName && {}}>
            <Box className="rtcar" style={{ height: !mySeva || (!mpName && '100%') }}>
              {mpName ? (
                <>
                  <Box className="right-card mb-3">
                    {(mySeva || (!mpName && user !== 'Admin' && user !== 'Leader')) && (
                      <ProfileCard
                        profile={mpProfileData ? mpProfileData : {}}
                        mpName={mpName}
                        mySeva={mySeva}
                        page_title={pageTitle}
                      />
                    )}
                    {mySeva ? (
                      <Button class="sevaupdate-nationbutton" onClick={(e) => handleMySevaUpdateClick(e)}>
                        {' '}
                        {mySeva && 'NATIONWIDE SEVA UPDATES'}
                      </Button>
                    ) : (
                      <Box className="highlights-title highlights-box">
                        <Typography component="h1" className="highlight-text">
                          Highlights
                        </Typography>
                      </Box>
                    )}
                    <TableHighlights mpId={mpId} dataFilter="alltime" />{' '}
                  </Box>
                  {!newUser && !user && !mySeva && (
                    <Button
                      class={`button-sevaupdate seva-update-btn ${mySeva ? 'sevaupdate-nationbutton' : ''} ${mpName && !mySeva && 'sevaUpdate-button-margin25'
                        }`}
                      onClick={(e) => handleMySevaUpdateClick(e)}
                    >
                      {' '}
                      {mySeva ? 'NATIONWIDE SEVA UPDATES' : 'MY SEVA UPDATES'}{' '}
                    </Button>
                  )}
                </>
              ) : (
                <Box className="right-card mb-3">
                  {(mySeva || (!mpName && user !== 'Admin' && user !== 'Leader')) && (
                    <ProfileCard
                      profile={mpProfileData ? mpProfileData : {}}
                      mpName={mpName}
                      mySeva={mySeva}
                      page_title={pageTitle}
                    />
                  )}

                  {!newUser && !user && !mySeva && (
                    <Button
                      class={`button-sevaupdate ${mySeva ? 'sevaupdate-nationbutton' : ''} ${mpName && !mySeva && 'sevaUpdate-button-margin'
                        }`}
                      onClick={(e) => handleMySevaUpdateClick(e)}
                    >
                      {' '}
                      {mySeva ? 'NATIONWIDE SEVA UPDATES' : 'MY SEVA UPDATES'}{' '}
                    </Button>
                  )}
                  <Box
                    className={`highlights-box ${((user == 'Admin' && !readonly) || user == 'Leader') && !MpClick ? 'highlights-title' : 'dnon'
                      }`}
                  >
                    <Typography variant="h1" className="highlight-text">
                      <Typography variant="h1" className="adminTwitterHighlightText">
                        MP Twitter Highlights
                      </Typography>
                    </Typography>
                  </Box>
                  <TwitterHighlights user={user} />
                </Box>
              )}
            </Box>
            <Box className="d-none">
              {openMediaCoverage && (
                <AddMediaCoverage
                  openMediaCoverage={openMediaCoverage}
                  handleCloseMediaCoverage={handleCloseMediaCoverage}
                  onViewClickTitle={addMediaTitle}
                  opedsByMpId={opedsByMpId}
                  mpId={mySeva ? getIds() : mpId}
                  limit={defaultLimit}
                />
              )}
              {openDevelopmentProjects && (
                <AddDevelopmentProjects
                  openDevelopmentProjects={openDevelopmentProjects}
                  handleCloseDevelopmentProjects={handleCloseDevelopmentProjects}
                  developmentProjectsByMpId={developmentProjectsByMpId}
                  mpId={mySeva ? getIds() : mpId}
                  limit={defaultLimit}
                />
              )}
              {/* {openSevaEvent && ( */}
              <AddSevaEvent
                openSevaEvent={openSevaEvent}
                handleCloseSevaEvent={handleCloseSevaEvent}
                eventByMpId={eventByMpId}
                mpId={mySeva ? getIds() : mpId}
                limit={defaultLimit}
              />
              {/* )} */}
              {openNewEvent && (
                <CreateNewEvent
                  openCreateEventDialog={openNewEvent}
                  handleCloseCreateEvent={handleCloseNewEvent}
                  eventByMpId={eventByMpId}
                  mpId={mySeva ? getIds() : mpId}
                  limit={defaultLimit}
                />
              )}

              {showViewAll && (
                <ViewAllScreen
                  user={user}
                  newUser={newUser}
                  handleOpenInitiativeDetails={handleOpenInitiativeDetails}
                />
              )}
              {showViewAllSevaInitiatives && <ViewAllOngoingInitiatives user={user} newUser={newUser} />}
              {openInitiativeDetailsDialog && (
                <InitiativeDetails
                  handleCloseInitiativeDetails={handleCloseInitiativeDetails}
                  openInitiativeDetailsDialog={openInitiativeDetailsDialog}
                  details={personDetails}
                  onViewClickTitle={onViewClick}
                  mpName={mpName}
                  user={user}
                  readonly={readonly}
                  mpId={MPid}
                  isEdit={isEdit}
                  MpClick={MpClick}
                  mpProfileData={mpProfileData}
                  initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
                  checkMySeva={mpId || mySeva ? true : false}
                  setDevelopmentProjectsByMpId={setDevelopmentProjectsByMpId}
                  setOpedsByMpId={setOpedsByMpId}
                  setEventByMpId={setEventByMpId}
                  opedsByMpId={opedsByMpId}
                  developmentProjectsByMpId={developmentProjectsByMpId}
                  eventByMpId={eventByMpId}
                  limit={defaultLimit}
                />
              )}
            </Box>
          </Grid>
          {/* </div> */}
        </Grid>
      </Box>
    </Box>
  )
}

export default SevaUpdates
