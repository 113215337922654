import { Box, Grid, Paper, Typography } from '@mui/material'
import { useState } from 'react'
import TemporaryAlert from 'Components/SendNotification/TemporaryAlert'
import WhatsNewAlert from 'Components/SendNotification/WhatsNewAlert'
import PreviousAlert from 'Components/SendNotification/PreviousAlerts'

let alertTabs = [
  {
    index: 0,
    title: 'Temporary Alert',
  },
  {
    index: 1,
    title: "What's New",
  },
  {
    index: 2,
    title: 'Previous Notifications',
  },
]

const SetAlert = ({ setIsChange }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [selectedItem, setSelectedItem] = useState(null)
  const [isTempSet, setIsTempSet] = useState(false)
  const [isSetBefore, setIsSetBefore] = useState(false)
  const handleTabClick = (item) => {
    setSelectedItem(null)
    setActiveTabIndex(item.index)
  }

  const displayTabContent = () => {
    switch (activeTabIndex) {
      case 1:
        return (
          <WhatsNewAlert
            selectedItem={selectedItem}
            isSetBefore={isSetBefore}
            setIsSetBefore={setIsSetBefore}
            setIsChange={setIsChange}
          />
        )
      case 2:
        return (
          <PreviousAlert
            setEditItem={setSelectedItem}
            setActiveTabIndex={setActiveTabIndex}
            setIsSetBefore={setIsSetBefore}
            setIsTempSet={setIsTempSet}
          />
        )
      default:
        return (
          <TemporaryAlert
            selectedItem={selectedItem}
            isTempSet={isTempSet}
            setIsTempSet={setIsTempSet}
            setIsChange={setIsChange}
          />
        )
    }
  }

  return (
    <Grid md={12} lg={12} xl={12}>
      <Paper
        className="d-flex flex-column"
        sx={{
          pt: 3,
          pb: 3,
          pl: 4.5,
          pr: 4.5,
          mt: 4,
          ml: 1,
          width: '100%',
          borderRadius: 6,
          overflow: 'auto',
        }}
      >
        <Typography variant="h5" className="send-alert-heading">
          Set an Alert
        </Typography>
        <Box className="d-flex mt-4">
          {alertTabs.map((item, index) => (
            <Box
              key={index}
              className={`cursorshow py-2 px-3 mr-4 tab-btns${activeTabIndex === index ? '-active' : ''}`}
              onClick={() => handleTabClick(item)}
            >
              {item.title}
            </Box>
          ))}
        </Box>
        <hr className="w-100 mt-4" />
        {displayTabContent()}
      </Paper>
    </Grid>
  )
}

export default SetAlert
