import {
  FETCH_DEVELOPMENT_PROJECTS_LIST_REQUEST,
  FETCH_DEVELOPMENT_PROJECTS_LIST_SUCCESS,
  FETCH_DEVELOPMENT_PROJECTS_LIST_FAILURE,
  FETCH_DEVELOPMENT_STATUS_LIST_REQUEST,
  FETCH_DEVELOPMENT_STATUS_LIST_SUCCESS,
  FETCH_DEVELOPMENT_STATUS_LIST_FAILURE,
  FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_REQUEST,
  FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_SUCCESS,
  FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_FAILURE,
} from './types'
import axiosInstance from '../../utils/api'

export const fetchDevelopmentProjectsListRequest = () => {
  return {
    type: FETCH_DEVELOPMENT_PROJECTS_LIST_REQUEST,
  }
}
export const fetchDevelopmentProjectsListSuccess = (data) => {
  return {
    type: FETCH_DEVELOPMENT_PROJECTS_LIST_SUCCESS,
    payload: data,
  }
}
export const fetchDevelopmentProjectsListFailure = (error) => {
  return {
    type: FETCH_DEVELOPMENT_PROJECTS_LIST_FAILURE,
    payload: error,
  }
}

export const fetchDevelopmentProjectsListByMpIdRequest = () => {
  return {
    type: FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_REQUEST,
  }
}
export const fetchDevelopmentProjectsListByMpIdSuccess = (data) => {
  return {
    type: FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_SUCCESS,
    payload: data,
  }
}
export const fetchDevelopmentProjectsListByMpIdFailure = (error) => {
  return {
    type: FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_FAILURE,
    payload: error,
  }
}

export const fetchDevelopmentCompletionStatusListRequest = () => {
  return {
    type: FETCH_DEVELOPMENT_STATUS_LIST_REQUEST,
  }
}
export const fetchDevelopmentCompletionStatusListSuccess = (carMakes) => {
  return {
    type: FETCH_DEVELOPMENT_STATUS_LIST_SUCCESS,
    payload: carMakes,
  }
}
export const fetchDevelopmentCompletionStatusListFailure = (error) => {
  return {
    type: FETCH_DEVELOPMENT_STATUS_LIST_FAILURE,
    payload: error,
  }
}

export const getDevelopmentProjectsList = (payload) => async (dispatch) => {
  const { id, limit, prevId = 0, isPrevTenure = false } = payload
  let endPoint = `/api/development/getalldevelopment/${id ? id : 0}?prevId=${prevId}&isPrevTenure=${isPrevTenure}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  dispatch(fetchDevelopmentProjectsListRequest)
  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response.data
      // const result = mpList
      dispatch(fetchDevelopmentProjectsListSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchDevelopmentProjectsListFailure(errorMsg))
    })
}

export const getDevelopmentProjectsListByMpId = (payload) => async (dispatch) => {
  const { id, limit, prevId = 0, isPrevTenure = false } = payload
  let endPoint = `/api/getDevelopDataByMpId/${id}?prevId=${prevId}&isPrevTenure=${isPrevTenure}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  dispatch(fetchDevelopmentProjectsListByMpIdRequest)
  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response.data
      // const result = mpList
      dispatch(fetchDevelopmentProjectsListByMpIdSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchDevelopmentProjectsListByMpIdFailure(errorMsg))
    })
}

export const getDevelopmentCompletionStatusList = () => async (dispatch) => {
  dispatch(fetchDevelopmentCompletionStatusListRequest)
  await axiosInstance
    .get('/api/getdevelopmentstatus')
    .then((response) => {
      const result = response.data
      // const result = mpList
      dispatch(fetchDevelopmentCompletionStatusListSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchDevelopmentCompletionStatusListFailure(errorMsg))
    })
}
