import { Grid } from '@mui/material'
import BannerRatio from './BannerRatio'
const handleBannerImagesRefactor = (selectedBannerRatio, handleFilter) => {
  return (
    <Grid container sx={{ mb: 1 }}>
      <BannerRatio selectedBannerRatio={selectedBannerRatio} handleFilter={handleFilter} Ratio="1:1" />
      <BannerRatio selectedBannerRatio={selectedBannerRatio} handleFilter={handleFilter} Ratio="16:9" />
      <BannerRatio selectedBannerRatio={selectedBannerRatio} handleFilter={handleFilter} Ratio="3:6" />
    </Grid>
  )
}
export default handleBannerImagesRefactor
