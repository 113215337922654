import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './MpSevaUpdateButton.css'
import { getRoles } from '../../ReusableComponents.js/getIds'

const MpSevaUpdateButton = ({ postAuditLogs, sendGALog, loggedInId, MpClick }) => {
  const navigate = useNavigate()
  const userId = parseInt(getRoles())

  const handleMpSevaUpdateClick = async (e) => {
    e.stopPropagation()
    await postAuditLogs('my_seva_updates')
    const message = 'my_seva_updates'
    sendGALog('select_option', message)
    navigate('/MySevaUpdates', {
      state: {
        MpName: 'My Seva Rank',
        mpId: loggedInId,
        MpClick,
      },
    })
  }
  return (
    <Box>
      {userId !== 2 && userId !== 3 && (
        <Button class="Mpsevaupdate" onClick={(e) => handleMpSevaUpdateClick(e)}>
          {'MY SEVA UPDATES'}
        </Button>
      )}
    </Box>
  )
}
export default MpSevaUpdateButton
