import { Box, Grid, Divider } from '@mui/material'
import AdminTwitterHighlightsText from '../AdminTwitterHighlightsText/AdminTwitterHighlightsText'
import TwitterHighlights from '../../Highlights/TwitterHighlights/TwitterHighlights'
import SevaUpdateButton from '../SevaUpdateButton/SevaUpdateButton'
import './SevaUpdateHighlights.css'
import ProfileCard from '../../ReusableComponents.js/ProfileCard/ProfileCard'
import MostNewFollowers from '../../Highlights/MostNewFollowers'
import { getRoles } from '../../ReusableComponents.js/getIds'
import MpTwitterHighlightsDescription from '../MpTwitterHighlightsDescription/MpTwitterHighlightsDescription'

const SevaUpdateHighlights = ({ loggedInId, postAuditLogs, sendGALog, pageTitle }) => {
  const userId = parseInt(getRoles())
  return (
    <Grid xs={12} md={4} lg={4} xl={4} className="mtl-20">
      <Box className="rtcar" style={{ height: '100%' }}>
        <Box className="right-card mb-3">
          {userId !== 2 && userId !== 3 && <ProfileCard page_title={pageTitle} />}

          {userId !== 2 && userId !== 3 && (
            <SevaUpdateButton postAuditLogs={postAuditLogs} sendGALog={sendGALog} loggedInId={loggedInId} />
          )}
          <AdminTwitterHighlightsText />
          {(userId === 2 || userId === 3) && <MostNewFollowers />}
          <MpTwitterHighlightsDescription />
          <Divider className="hrdivi highlights-divider"></Divider>
          <TwitterHighlights />
        </Box>
      </Box>
    </Grid>
  )
}

export default SevaUpdateHighlights
