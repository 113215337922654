import axios from 'axios'
import store from '../store'
import { ERROR_UNAUTH } from '../store/action/types'
import { env } from '../env'
import { clearLocalStorageOnLogout, clearCapaignStorage, throttle } from './helper'
import { browserName, mobileModel, osName, osVersion, fullBrowserVersion } from 'react-device-detect'

const baseURL = env.REACT_APP_BASE_URL
const { dispatch } = store

const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    browser: browserName,
    browserVersion: fullBrowserVersion,
    device: mobileModel,
    os: osName,
    osVersion: osVersion,
    portal: 'mp_corner',
  },
})

//validate response
axiosInstance.interceptors.response.use(
  (response) => {
    if (response.status === 244) {
      return throttle(clearCapaignStorage(), 1000)
    }
    return response
  },
  (error) => {
    if (error.response.status === 444) {
      return throttle(clearLocalStorageOnLogout(), 1000)
    }
    if (error.response.status === 401) {
      dispatch({ type: ERROR_UNAUTH })
    }
    return Promise.reject(error)
  }
)

// Set the AUTH token for any request
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('tokenDetails')
  config.headers.Authorization = token ? `Bearer ${token}` : ''
  return config
})

export default axiosInstance
