import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CrossIcon from '../../../asserts/images/Close.svg'
import one from '../../../asserts/images/1.jpg'
import two from '../../../asserts/images/4.jpg'
import NoImageFound from '../../../asserts/images/noImageFound.jpg'
import playIcon from '../../../asserts/images/Play.svg'
import AddMediaCoverage from '../../SevaUpdateRefactored/AddMediaCoverage/AddMediaCoverage'
import Moment from 'moment'
import AddDevelopmentProjects from '../../SevaUpdateRefactored/AddDevelopmentProjects/AddDevelopmentProjects'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AddSevaEvent from '../../SevaUpdateRefactored/AddSevaEvent/AddSevaEvent'
import CreateNewEvent from '../../SevaUpdateRefactored/AddSevaEvent/CreateNewEvent'
import { Box, Card, CardContent, CardMedia, Divider, Grid, Typography } from '@mui/material'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import PreviewImagesDialog from '../PreviewImagesDialog/PreviewImagesDialog'
import Share from '../../ReusableComponents.js/Share'
import Linkify from 'linkify-react'
import EventDetailsDialog from '../EventDetailsDialog/EventDetailsDialog'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { getIds, getRoles } from '../../ReusableComponents.js/getIds'
import PdfCard from '../../ReusableComponents.js/PdfCard'
import { handleFileName } from '../../ReusableComponents.js/reuseMethods'
import { useMemo } from 'react'
import './Initiativedetails.css'
import { formatUtcDateToLocal } from '../../../utils/helper'

const InitiativeDetails = ({
  handleCloseInitiativeDetails,
  openInitiativeDetailsDialog,
  details,
  onViewClickTitle,
  onViewClickTitleValue,
  mpName,
  uniqueInitiativeClick,
  isEdit,
  mpId,
  mpProfileData,
  initiativeName,
  initiativeReportDetailsByMPIdAndIntiativeId,
  checkMySeva,
  setDevelopmentProjectsByMpId,
  setOpedsByMpId,
  setEventByMpId,
  opedsByMpId,
  developmentProjectsByMpId,
  viewAllCheck,
  eventByMpId,
  setCheckIsUpdate,
  limit = 10,
  tenure,
}) => {
  const userId = parseInt(getRoles())
  const [openMediaCoverage, setOpenMediaCoverage] = useState(false)
  const [isMediaCoverageEdit, setIsMediaCoverageEdit] = useState(false)
  const [isDevelopmentProjectsEdit, setIsDevelopmentProjectsEdit] = useState(false)
  const [isSevaEventEdit, setIsSevaEventEdit] = useState(false)
  const [eventDetailsData, setEventDetailsData] = useState()
  const [openEventDetailsDialog, setOpenEventDetailsDialog] = useState(false)
  const [openDevelopmentProjects, setOpenDevelopmentProjects] = useState(false)
  const [openCreateEventDialog, setOpenCreateEventDialog] = useState(false)
  const [openPreviewImages, setOpenPreviewImages] = useState(false)
  const [previewImageIndex, setPreviewImageIndex] = useState(0)
  const [files, setFiles] = useState([])
  const [onFilesSelect, setOnFilesSelect] = useState(false)
  const [reportFiles, setReportFiles] = useState([])
  const [mediaShare, setMediaShare] = useState(false)
  const [shareData, setShareData] = useState({})
  const reportsData =
    initiativeReportDetailsByMPIdAndIntiativeId && initiativeReportDetailsByMPIdAndIntiativeId?.reportdata
  const eventsData =
    initiativeReportDetailsByMPIdAndIntiativeId && initiativeReportDetailsByMPIdAndIntiativeId?.Eventdata

  const postAuditLogs = async (sqId, id, eventCard) => {
    let requestData = {
      useridtype: getIds(),
    }
    if (onViewClickTitleValue && onViewClickTitleValue === 3) {
      requestData['bm_id'] = id
    } else if (onViewClickTitleValue && onViewClickTitleValue === 4) {
      requestData['dp_id'] = id
    } else if (uniqueInitiativeClick) {
      requestData[eventCard ? 'e_id' : 'report_id'] = id
    }
    await makeLogRequest(
      onViewClickTitleValue === 3
        ? (isEdit && mpName) || details?.userId === mpProfileData?.id
          ? 'my_books_and_media_coverage_popup'
          : 'other_mp_books_and_media_coverage_popup'
        : onViewClickTitleValue === 4
        ? (isEdit && mpName) || details?.userId === mpProfileData?.id
          ? 'my_development_project_popup'
          : 'other_mp_development_project_popup'
        : uniqueInitiativeClick && 'other_mp_initiatives_report_popup',
      sqId,
      requestData
    )
  }
  const minWidthStyle = useMemo(
    () => ({
      minWidth: 200,
    }),
    []
  )
  const minCardStyle = useMemo(
    () => ({
      minWidth: 200,
      mr: 2,
    }),
    []
  )
  const cardStyle = useMemo(
    () => ({
      minWidth: 200,
      mr: 2,
      marginLeft: '40px',
      borderRadius: '20px',
    }),
    []
  )

  useEffect(() => {
    if (onViewClickTitleValue === 3 || onViewClickTitleValue === 4) {
      postAuditLogs('pageload', details?.id)
    }
  }, [])

  useEffect(() => {
    if (uniqueInitiativeClick === true) {
      postAuditLogs('pageload', details?.id)
    }
  }, [uniqueInitiativeClick])

  useEffect(() => {
    if (details) {
      setFiles(details?.media && JSON.parse(details?.media))
    }
  }, [details])
  // const url = details?.url ? new URL(details?.url.startsWith("http") ? details?.url : "http://" + details?.url) : "";
  useEffect(() => {
    if (reportsData) {
      setReportFiles(reportsData?.media && JSON.parse(reportsData?.media))
    }
  }, [reportsData])

  const handleOpenMediaCoverage = () => {
    setOpenMediaCoverage(true)
  }
  const handleCloseMediaCoverage = () => {
    if (!viewAllCheck) {
      setOpedsByMpId(false)
    }
    setOpenMediaCoverage(false)
  }

  const handleOpenDevelopmentProjects = () => {
    setOpenDevelopmentProjects(true)
  }
  const handleCloseDevelopmentProjects = () => {
    if (!viewAllCheck) {
      setDevelopmentProjectsByMpId(false)
    }
    setOpenDevelopmentProjects(false)
  }

  const handleOpenCreateEvent = () => {
    setOpenCreateEventDialog(true)
  }
  const handleCloseCreateEvent = () => {
    if (!viewAllCheck) {
      setEventByMpId(false)
    }
    setOpenCreateEventDialog(false)
  }

  const handleOpenPreviewImages = (file, index = 0) => {
    setPreviewImageIndex(index)
    setOpenPreviewImages(true)
  }
  const handleClosePreviewImages = () => {
    setOnFilesSelect(false)
    setOpenPreviewImages(false)
  }

  const handleOpenEventDetailsDialog = (data) => {
    setEventDetailsData(data)
    setOpenEventDetailsDialog(true)
  }

  const handleCloseEventDetailsDialog = () => {
    setOpenEventDetailsDialog(false)
  }

  const titleName = () => {
    if (onViewClickTitleValue === 2) {
      return `Event Conducted by ${details?.user?.user_name}`
    } else if (onViewClickTitleValue === 5 || onViewClickTitleValue === 3) {
      return `${details.title ?? ''}`
    } else if (onViewClickTitleValue === 4) {
      return `${details.projecttitle ?? ''}`
    } else if (onViewClickTitleValue === 1 || uniqueInitiativeClick) {
      return `${initiativeName ? initiativeName ?? '' : details?.initiativeName ?? ''}`
    }
  }

  const onEditClick = () => {
    if (onViewClickTitleValue === 2) {
      setIsSevaEventEdit(true)
      handleOpenCreateEvent()
      if (checkMySeva) {
        setEventByMpId(true)
      }
      // return `Event Conducted by ${details.personName}`
    } else if (onViewClickTitleValue === 5 || onViewClickTitleValue === 3) {
      setIsMediaCoverageEdit(true)
      handleOpenMediaCoverage()
      if (checkMySeva) {
        setOpedsByMpId(true)
      }
      // handleCloseInitiativeDetails();
    } else if (onViewClickTitleValue === 4) {
      setIsDevelopmentProjectsEdit(true)
      handleOpenDevelopmentProjects()
      if (checkMySeva) {
        setDevelopmentProjectsByMpId(true)
      }
      // return `${details.projecttitle}`
    }
  }

  const handleShare = (e, data) => {
    setShareData(data)
    setMediaShare(true)
  }

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString))
    } catch (e) {
      return false
    }
  }

  const handlePreview = (file) => {
    const images = ['jpg', 'jpeg', 'gif', 'png', 'svg', 'jfif']
    const videos = ['mp4', '3gp', 'ogg']
    let url = ''
    if (isValidUrl(file)) {
      url = new URL(file)
    }
    const extension = url?.search?.split('.')[1] || ''
    const pathExtension = url?.pathname?.slice(url.pathname.lastIndexOf('.') + 1, url.pathname.length) || ''
    if (details && (images.includes(extension.toLowerCase()) || images.includes(pathExtension.toLowerCase()))) {
      return (
        <img
          src={file}
          alt="Preview"
          onError={(e) => (e.target.src = NoImageFound)}
          className="form-img__img-preview-invide-ini"
        />
      )
    } else if (details && (videos.includes(extension.toLowerCase()) || videos.includes(pathExtension.toLowerCase()))) {
      return (
        <Box className="position-relative">
          <img alt="Play video" src={playIcon} className="play-btn" />
          <video className="form-img__img-preview-invide-ini">
            <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      )
    }
  }

  const descWithLineBreaks = details?.desc ? details.desc : reportsData?.desc
  const descArray = descWithLineBreaks && descWithLineBreaks.split(/\r?\n/)
  const titleText = descArray?.join('\n')

  return (
    // <div style={{
    //     minWidth: "70vh",
    //     margin: "0 50% 0 50%",

    //     minHeight: "95vh"
    // }}>
    <>
      <Dialog
        open={openInitiativeDetailsDialog}
        onClose={() => (
          handleCloseInitiativeDetails(),
          (onViewClickTitleValue === 3 || onViewClickTitleValue === 4) && postAuditLogs('close'),
          uniqueInitiativeClick && postAuditLogs('close')
        )}
        sx={{ height: '96vh', marginTop: '15px' }}
      >
        <img
          onClick={() => (
            handleCloseInitiativeDetails(),
            (onViewClickTitleValue === 3 || onViewClickTitleValue === 4) && postAuditLogs('close'),
            uniqueInitiativeClick && postAuditLogs('close')
          )}
          src={CrossIcon}
          className="closeIcon"
        />
        <DialogTitle id="customized-dialog-title" onClose={handleCloseInitiativeDetails}>
          <Box className="dialog-title-event-div" sx={{ padding: '0' }}>
            <Typography
              component="h6"
              className="dialog-title-event-h3"
              sx={{
                marginBottom: '8px',
                lineHeight: '1.5rem',
                letterSpacing: '0.0075em',
              }}
              title={details?.eventTitle ? details?.eventTitle : ''}
            >
              {onViewClickTitleValue
                ? onViewClickTitleValue === 5
                  ? 'Miscellaneous of'
                  : onViewClickTitleValue === 3
                  ? 'Media Coverage of'
                  : onViewClickTitleValue === 1
                  ? `Initiative Report by ${reportsData?.user?.user_name ?? ''}`
                  : details?.eventTitle
                  ? details?.eventTitle
                  : `${onViewClickTitle}`
                : `Initiative Report by ${details?.user?.user_name ?? ''}`}
            </Typography>
            <Typography
              component="h1"
              sx={{
                letterSpacing: '0.0075em',
                lineHeight: '1.2',
                marginBottom: '8px',
                fontWeight: 'bolder',
              }}
              className="title-h1"
              title={titleName()}
            >
              {titleName()}
            </Typography>
          </Box>
          <Divider sx={{ margin: '16px' }}></Divider>
        </DialogTitle>
        <DialogContent sx={{ mt: -1.5 }}>
          {onViewClickTitleValue === 1 || uniqueInitiativeClick ? (
            <Box className="text-center pt-1">
              {reportsData?.user?.user_avatar ? (
                <img
                  src={reportsData?.user?.user_avatar}
                  className="img-circle leader-circle-img mr-1 img-w-h"
                  onError={(e) =>
                    (e.target.value = (
                      <AccountCircleIcon
                        sx={{
                          fontSize: 'xx-large',
                          width: '80px',
                          height: '80px',
                          color: '#EEEEEE',
                        }}
                      />
                    ))
                  }
                />
              ) : (
                <AccountCircleIcon
                  sx={{
                    fontSize: 'xx-large',
                    width: '80px',
                    height: '80px',
                    color: '#EEEEEE',
                  }}
                />
              )}
            </Box>
          ) : (
            <Box className="text-center pt-1">
              {details?.user?.user_avatar ? (
                <img src={details?.user?.user_avatar} className="img-circle leader-circle-img mr-1 img-w-h" />
              ) : (
                <AccountCircleIcon
                  sx={{
                    fontSize: 'xx-large',
                    width: '80px',
                    height: '80px',
                    color: '#EEEEEE',
                  }}
                />
              )}
            </Box>
          )}
          <Box className="card-content">
            {(onViewClickTitleValue === 1 || uniqueInitiativeClick) && (
              <Box>
                <Typography variant="body-1" className="designation-span">
                  {reportsData?.user?.designation ?? ''}
                  {reportsData?.user?.party?.length > 0 ? reportsData?.user?.party : ''}
                </Typography>
                <br />
                <Typography variant="body-1" className="constituency constituency-span">
                  Constituency : {reportsData?.user?.constituency_name ? reportsData?.user?.constituency_name : ''}
                </Typography>
                <br />
                <Box className="participants-div">Participants : {reportsData?.participant ?? ''}</Box>
              </Box>
            )}
            {(onViewClickTitleValue === 2 ||
              onViewClickTitleValue === 4 ||
              onViewClickTitleValue === 5 ||
              onViewClickTitleValue === 3) && (
              <Typography component="h2" className="user-name-h2">
                {details?.user?.user_name ?? ''}
              </Typography>
            )}
            {(onViewClickTitleValue === 5 || onViewClickTitleValue === 3 || onViewClickTitleValue === 4) && (
              <>
                <Typography variant="body-1" className="constituency-span-style">
                  {details?.user?.constituency_name ?? ''}
                </Typography>
                <Box className={details?.user?.designation ? 'margin-1' : 'margin-2'}>
                  <Typography variant="body-1" className="designation-span-style">
                    {details?.user?.designation ?? ''}
                  </Typography>
                  <br />
                  <Typography variant="body-1" className="state-span">
                    {details?.user?.state_name ?? ''}
                  </Typography>
                  <br />
                </Box>
              </>
            )}
            {onViewClickTitleValue === 2 && (
              <Typography variant="body-1" className="initial designation-span-style">
                {details?.user?.designation ?? '' + ', ' + details?.user?.state_name ?? ''}
              </Typography>
            )}
            <br />
            {onViewClickTitleValue === 2 && (
              <Typography variant="body-1" className="constituency constituency-span-style">
                {'Constituency' + ' : ' + details?.user?.constituency_name ?? ''}
              </Typography>
            )}
            <br />
            {onViewClickTitleValue === 4 && (
              <Box className="tg-date">Target Date : {Moment(details?.target).format('DD MMM YYYY')}</Box>
            )}
            {onViewClickTitleValue === 2 && (
              <Typography
                variant="body-1"
                className="eventTitle-span"
                title={details?.eventTitle ? details?.eventTitle : ''}
              >
                {details?.eventTitle}
              </Typography>
            )}
          </Box>
          <Box className="div-mg">
            <Box className="title-style">
              {onViewClickTitleValue === 5 || onViewClickTitleValue === 3
                ? 'About'
                : onViewClickTitleValue === 4
                ? 'About'
                : onViewClickTitleValue === 2
                ? 'Event Description'
                : 'Initiative Description'}{' '}
              <br />
            </Box>
            <Box className="discription-div">
              <Typography variant="body-1" className="accept-multiple-spaces" title={titleText}>
                {descArray?.map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </Typography>
            </Box>
          </Box>
          <br />

          {(onViewClickTitleValue === 5 || onViewClickTitleValue === 3 || onViewClickTitleValue === 4) && (
            <Box>
              <Typography component="span" className="title-style" sx={{ letterSpacing: 'normal' }}>
                Media
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  minWidth: { xs: 300, sm: 300 },
                  marginLeft: '38px',
                }}
              >
                {files && files.length > 1 ? (
                  <Tabs
                    variant="scrollable"
                    scrollButtons
                    aria-label="visible arrows tabs example"
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                      },
                      marginLeft: '-45px',
                    }}
                  >
                    {files &&
                      files.map((file, index) => (
                        <Card
                          sx={minWidthStyle}
                          className="bshadow"
                          onClick={() => {
                            handleOpenPreviewImages(file, index)
                            setOnFilesSelect(true)
                          }}
                        >
                          <CardContent>
                            <Box key={index}>{handlePreview(file)}</Box>
                          </CardContent>
                        </Card>
                      ))}
                  </Tabs>
                ) : (
                  files &&
                  files.map((file, index) => (
                    <Card
                      sx={minCardStyle}
                      className=" bshadow"
                      onClick={() => {
                        handleOpenPreviewImages(file, index)
                        setOnFilesSelect(true)
                      }}
                    >
                      <CardContent>
                        <Box key={index}>{handlePreview(file)}</Box>
                      </CardContent>
                    </Card>
                  ))
                )}
              </Box>
            </Box>
          )}
          {(onViewClickTitleValue === 1 || uniqueInitiativeClick) && (
            <Box>
              <Box>
                <Typography
                  component="span"
                  className="title-style"
                  sx={{
                    letterSpacing: 'normal',
                  }}
                >
                  Images
                </Typography>
                <Box
                  sx={{
                    flexGrow: 1,
                    minWidth: { xs: 300, sm: 300 },
                  }}
                  className="box-mg"
                >
                  {reportFiles && reportFiles.length > 1 ? (
                    <Tabs
                      variant="scrollable"
                      scrollButtons
                      aria-label="visible arrows tabs example"
                      sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                          '&.Mui-disabled': { opacity: 0.3 },
                        },
                      }}
                    >
                      {reportFiles &&
                        reportFiles.map((file, index) => (
                          <Card
                            sx={{ overflow: 'visible' }}
                            className="bshadow"
                            onClick={() => handleOpenPreviewImages(file, index)}
                          >
                            <CardContent onClick={handleOpenPreviewImages}>
                              {/* <img src={file} alt="Preview" style={{ borderRadius: "20px" }} className="form-img__img-preview" /> */}
                              <Box key={index}>{handlePreview(file)}</Box>
                            </CardContent>
                          </Card>
                        ))}
                    </Tabs>
                  ) : (
                    reportFiles &&
                    reportFiles.map((file, index) => (
                      <Card sx={{ minWidth: 100, mr: 2 }} className="bshadow">
                        <CardContent onClick={handleOpenPreviewImages}>
                          <Box key={index}>{handlePreview(file)}</Box>
                          {/* <img src={file} alt="Preview" style={{ borderRadius: "20px" }} className="form-img__img-preview" /> */}
                        </CardContent>
                      </Card>
                    ))
                  )}
                </Box>
              </Box>
              <Box className="div-mg1">
                <Typography component="span" className="title-style" sx={{ letterSpacing: 'normal' }}>
                  {' '}
                  Event Conducted :{' '}
                  {(initiativeReportDetailsByMPIdAndIntiativeId &&
                    (initiativeReportDetailsByMPIdAndIntiativeId?.eventcount < 10
                      ? '0' + initiativeReportDetailsByMPIdAndIntiativeId?.eventcount
                      : initiativeReportDetailsByMPIdAndIntiativeId?.eventcount)) ||
                    0}
                </Typography>
                <Box className="gridinirepo">
                  {eventsData && eventsData.length > 1 ? (
                    <Tabs
                      variant="scrollable"
                      scrollButtons
                      aria-label="visible arrows tabs example"
                      sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                          '&.Mui-disabled': { opacity: 0.3 },
                        },
                        '& .MuiTabs-flexContainer': { marginLeft: '-25px' },
                      }}
                    >
                      {eventsData &&
                        eventsData.map((item) => (
                          <Card
                            sx={{
                              minWidth: '190px',
                              maxWidth: '242px',
                              // width: 180,
                              ml: '35px',
                              mr: '0!important',
                            }}
                            className="bshadow cardini2 cursorshow"
                            onClick={() => {
                              handleOpenEventDetailsDialog(item)
                              uniqueInitiativeClick && postAuditLogs('event_card', item?.id, true)
                            }}
                          >
                            <CardMedia>
                              <img
                                src={item?.coverimage && JSON.parse(item?.coverimage)[0]}
                                onError={(e) => (e.target.src = NoImageFound)}
                                alt="Preview"
                                className="form-img__img-preview-ec coverimg-st"
                              />
                            </CardMedia>

                            <CardContent>
                              <Box className="cd-div-st">
                                <Typography
                                  component="span"
                                  title={item?.eventTitle ? item?.eventTitle : ''}
                                  className="event-title-span"
                                >
                                  {(item?.eventTitle && item?.eventTitle.slice(0, 20)) || '-'}
                                  {item?.eventTitle && item?.eventTitle.length > 20 ? '...' : ''}
                                </Typography>
                                <br />
                                <Typography component="span" className="time-st">
                                  {Moment(item?.statTime).utc().format('DD MMM YYYY')},
                                  <Typography
                                    component="span"
                                    title={item?.location ? item?.location : ''}
                                    className="location-st"
                                  >
                                    {(item.location && item.location.slice(0, 20)) || '-'}
                                    {item.location && item.location.length > 20 ? '...' : ''}
                                  </Typography>
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        ))}
                    </Tabs>
                  ) : (
                    eventsData &&
                    eventsData.map((item) => (
                      <Card
                        sx={{
                          minWidth: '190px',
                          maxWidth: '242px',
                          // width: 190,
                          ml: '25px',
                        }}
                        className=" bshadow cardini2 cursorshow"
                        onClick={() => {
                          handleOpenEventDetailsDialog(item)
                          uniqueInitiativeClick && postAuditLogs('event_card', item?.id, true)
                        }}
                      >
                        <CardMedia>
                          <img
                            src={item?.coverimage && JSON.parse(item?.coverimage)[0]}
                            alt="Preview"
                            className="form-img__img-preview-ec coverimg-st"
                          />
                        </CardMedia>
                        <CardContent
                          sx={{
                            padding: '12px 12px 24px',
                            lineHeight: '1.2',
                          }}
                        >
                          <Typography
                            component="span"
                            sx={{
                              fontFamily: 'HK Grotesk',
                              fontSize: '13px',
                              color: '#2c2c2c',
                              fontWeight: 'bold',
                              letterSpacing: 'normal',
                            }}
                            title={item?.eventTitle ? item?.eventTitle : ''}
                          >
                            {(item?.eventTitle && item?.eventTitle.slice(0, 20)) || '-'}
                            {item?.eventTitle && item?.eventTitle.length > 20 ? '...' : ''}
                          </Typography>
                          <br />
                          <Typography
                            component="span"
                            sx={{
                              fontFamily: 'HK Grotesk',
                              fontSize: '12px',
                              color: '#2c2c2c',
                              fontWeight: 'bold',
                              letterSpacing: 'normal',
                            }}
                          >
                            {Moment(item?.statTime).utc().format('DD MMM YYYY')},
                            <Typography
                              component="span"
                              title={item?.location ? item?.location : ''}
                              sx={{
                                fontFamily: 'HK Grotesk',
                                fontSize: '12px',
                                color: '#2c2c2c',
                                fontWeight: 'bold',
                                letterSpacing: 'normal',
                              }}
                            >
                              {(item.location && item.location.slice(0, 20)) || '-'}
                              {item.location && item.location.length > 20 ? '...' : ''}
                            </Typography>
                          </Typography>
                        </CardContent>
                      </Card>
                    ))
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {onViewClickTitleValue === 4 && (
            <Typography component="span" className="completion-st">
              <Typography variant="h1" className="title-style">
                Completion Status :
              </Typography>{' '}
              {details?.status}
            </Typography>
          )}
          {(onViewClickTitleValue === 5 || onViewClickTitleValue === 3) && (
            <>
              <Typography component="span" className="completion-st">
                <Typography variant="h1" className="title-style link-text">
                  {onViewClickTitleValue === 5 ? (details?.url === '' ? '' : 'Link :') : 'Link :'}
                </Typography>{' '}
                <Linkify
                  as="p"
                  options={{ target: 'blank' }}
                  className="link-url-text"
                  onClick={() =>
                    ((isEdit && !mpName) || details?.userId !== mpProfileData?.id) && postAuditLogs('link')
                  }
                >
                  {details?.url}
                </Linkify>
                {/* <a href={details?.url} target="_blank" rel="noopener noreferrer">{details?.url}</a> */}
              </Typography>
            </>
          )}
          {onViewClickTitleValue === 2 && (
            <Box>
              {files?.length > 0 && (
                <Typography component="span" className="title-style">
                  Event Images : {files?.length === 0 || (files === null && 0)}
                </Typography>
              )}
              <Box>
                {files?.length > 1 ? (
                  <Tabs
                    variant="scrollable"
                    scrollButtons
                    aria-label="visible arrows tabs example"
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                      },
                      '& .MuiTabs-flexContainer': { marginLeft: '-5px' },
                    }}
                  >
                    {files?.map((file, index) => (
                      <Card
                        sx={{ minWidth: 200, mr: 2, borderRadius: '20px' }}
                        className=" bshadow"
                        onClick={() => {
                          handleOpenPreviewImages(file, index)
                          setOnFilesSelect(true)
                        }}
                      >
                        <CardContent>
                          <Box key={index}>{handlePreview(file)}</Box>
                        </CardContent>
                      </Card>
                    ))}
                  </Tabs>
                ) : (
                  files?.map((file, index) => (
                    <Card
                      sx={cardStyle}
                      className="bshadow"
                      onClick={() => {
                        handleOpenPreviewImages(file, index)
                        setOnFilesSelect(true)
                      }}
                    >
                      <CardContent>
                        <Box key={index}>{handlePreview(file)}</Box>
                      </CardContent>
                    </Card>
                  ))
                )}
              </Box>
              <Grid container>
                <Grid item xs={6}>
                  <Typography component="span" className="title-style">
                    Start Date :
                  </Typography>{' '}
                  {formatUtcDateToLocal(details?.statTime)}
                </Grid>
                <Grid item xs={6}>
                  <Typography component="span" className="title-style">
                    End Date :
                  </Typography>{' '}
                  {details?.endTime !== null ? formatUtcDateToLocal(details?.endTime) : '-'}
                </Grid>
              </Grid>
              <Box className="title-style location-div"> Location</Box>
              <Grid container className="accept-multiple-spaces">
                <Grid
                  item
                  xs={12}
                  sx={{
                    fontFamily: 'HK Grotesk',
                    color: '#505050',
                    fontSize: '18px',
                    fontWeight: 'bold',
                    marginLeft: '55px',
                  }}
                >
                  {details?.location ?? ''}
                </Grid>
              </Grid>
            </Box>
          )}
          <Grid sx={{ pl: 6 }}>
            {((typeof details?.detailsPdf == 'string' && details.detailsPdf !== '[]') ||
              (typeof details?.detailsPdf == 'object' && details?.detailsPdf?.[0])) && (
              <>
                <Box className="pdfUploadClass">
                  {onViewClickTitleValue === 4 && 'Development Projects PDF :'}
                  {onViewClickTitleValue === 2 && 'Seva Event PDF :'}
                  {onViewClickTitleValue === 5 && 'Miscellaneous PDF :'}
                  {onViewClickTitleValue === 3 && 'Media Coverage PDF :'}
                </Box>
                <PdfCard url={details?.detailsPdf} name={handleFileName(details?.detailsPdf)} />
              </>
            )}
          </Grid>
        </DialogContent>
        {
          <DialogActions sx={{ justifyContent: 'Center', paddingBottom: '32px' }}>
            {((isEdit && mpName) || details?.userId === mpProfileData?.id) && userId !== 2 && userId !== 3 && (
              <Button
                variant="contained"
                className="button-tr-2"
                autoFocus
                onClick={() => (onEditClick(), postAuditLogs('edit'))}
                disabled={tenure === 'Modi 2.0'}
                sx={
                  tenure === 'Modi 2.0'
                    ? {
                        color: 'rgba(0, 0, 0, 0.26) !important',
                        boxShadow: 'none !important',
                        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                        border: 'none !important',
                      }
                    : { backgroundColor: '#ef7335' }
                }
              >
                Edit
              </Button>
            )}
            {((isEdit && mpName) || details?.userId === mpProfileData?.id) &&
            (onViewClickTitleValue === 5 || onViewClickTitleValue === 3 || onViewClickTitleValue === 4) ? (
              <Box />
            ) : (
              ((isEdit && mpName) || details?.userId === mpProfileData?.id) &&
              userId !== 2 &&
              userId !== 3 && (
                <Button
                  variant="outlined"
                  className="button-tr-citizen-cancel"
                  autoFocus
                  onClick={handleCloseInitiativeDetails}
                >
                  Cancel
                </Button>
              )
            )}
          </DialogActions>
        }
        {openMediaCoverage && (
          <AddMediaCoverage
            openMediaCoverage={openMediaCoverage}
            handleCloseMediaCoverage={handleCloseMediaCoverage}
            isMediaCoverageEdit={isMediaCoverageEdit}
            onViewClickTitle={onViewClickTitle}
            onViewClickTitleValue={onViewClickTitleValue}
            details={details}
            mpId={mpId}
            opedsByMpId={opedsByMpId}
            handleCloseInitiativeDetails={handleCloseInitiativeDetails}
            setCheckIsUpdate={setCheckIsUpdate}
            limit={limit}
            tenure={tenure}
          />
        )}
        {openDevelopmentProjects && (
          <AddDevelopmentProjects
            openDevelopmentProjects={openDevelopmentProjects}
            handleCloseDevelopmentProjects={handleCloseDevelopmentProjects}
            isDevelopmentProjectsEdit={isDevelopmentProjectsEdit}
            details={details}
            handleCloseInitiativeDetails={handleCloseInitiativeDetails}
            developmentProjectsByMpId={developmentProjectsByMpId}
            mpId={mpId}
            setCheckIsUpdate={setCheckIsUpdate}
            limit={limit}
            tenure={tenure}
          />
        )}
        {openCreateEventDialog && (
          <CreateNewEvent
            handleCloseCreateEvent={handleCloseCreateEvent}
            openCreateEventDialog={openCreateEventDialog}
            isSevaEventEdit={isSevaEventEdit}
            details={details}
            mpId={mpId}
            handleCloseInitiativeDetails={handleCloseInitiativeDetails}
            eventByMpId={eventByMpId}
            setCheckIsUpdate={setCheckIsUpdate}
            limit={limit}
            tenure={tenure}
          />
        )}
        {openPreviewImages && (
          <PreviewImagesDialog
            openPreviewImages={openPreviewImages}
            handleClosePreviewImages={handleClosePreviewImages}
            files={onFilesSelect ? files : reportFiles}
            previewImageIndex={previewImageIndex}
            one={one}
            two={two}
          />
        )}
        {openEventDetailsDialog && (
          <EventDetailsDialog
            openEventDetailsDialog={openEventDetailsDialog}
            handleCloseEventDetailsDialog={handleCloseEventDetailsDialog}
            details={eventDetailsData}
            mpProfileData={mpProfileData}
            eventByMpId={eventByMpId}
            limit={limit}
          />
        )}
        <Dialog open={mediaShare} onClose={() => setMediaShare(false)}>
          <DialogTitle>
            <img onClick={() => setMediaShare(false)} src={CrossIcon} className="closeIcon" />
            <Typography
              sx={{
                display: 'flex',
                justifyContent: 'center',
                color: '#357092',
                fontFamily: 'HK Grotesk',
                fontSize: '26px',
                fontWeight: 'bold',
              }}
            >
              Share to Social Media
            </Typography>

            <Box className="share-div">
              <Share
                data={shareData}
                title={
                  onViewClickTitleValue === 3
                    ? 'Media Coverage of'
                    : onViewClickTitleValue === 5
                    ? 'Miscellaneous of'
                    : 'Development Projects'
                }
              />
            </Box>
            {/* <CloseIcon onClick={() => setAddMembers(false)} /> */}
          </DialogTitle>
        </Dialog>
        {/* </BootstrapDialog> */}
      </Dialog>
    </>
  )
}

export default InitiativeDetails
