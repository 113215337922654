import { Button, SvgIcon } from '@mui/material'
import './SocialMediaKit.css'
function MediaButtons({ title, viewBox, id, dataName, d, transform, onClick }) {
  return (
    <>
      <Button className="button-heading" onClick={onClick}>
        {title === 'Text' ? (
          <SvgIcon
            className={'enabletextsvg'}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            margin-right="10px"
            viewBox="0 0 190.783 235.229"
          >
            <g
              id="Icon_feather-file-text"
              data-name="Icon feather-file-text"
              transform="translate(6.5 6.5)"
              className={'enabletextsvg'}
            >
              <path
                id="Path_497528"
                data-name="Path 497528"
                d="M117.114,3H28.223A22.223,22.223,0,0,0,6,25.223V203.006a22.223,22.223,0,0,0,22.223,22.223H161.56a22.223,22.223,0,0,0,22.223-22.223V69.669Z"
                transform="translate(-6 -3)"
              ></path>
              <path
                id="Path_497529"
                data-name="Path 497529"
                d="M21,3V69.669H87.669"
                transform="translate(90.115 -3)"
              ></path>
              <path
                id="Path_497530"
                data-name="Path 497530"
                d="M100.892,19.5H12"
                transform="translate(32.446 102.726)"
              ></path>
              <path
                id="Path_497531"
                data-name="Path 497531"
                d="M100.892,25.5H12"
                transform="translate(32.446 141.172)"
              ></path>
              <path
                id="Path_497532"
                data-name="Path 497532"
                d="M34.223,13.5H12"
                transform="translate(32.446 64.28)"
              ></path>
            </g>
          </SvgIcon>
        ) : (
          <SvgIcon
            className={'enablesvg'}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            margin-right="10px"
            viewBox={viewBox}
          >
            <path id={id} data-name={dataName} d={d} transform={transform} />
          </SvgIcon>
        )}
        {title}
      </Button>
    </>
  )
}
export default MediaButtons
