import {
  POST_CREATE_DEVELOPMENT_PROJECT_FAILURE,
  POST_CREATE_DEVELOPMENT_PROJECT_REQUEST,
  POST_CREATE_DEVELOPMENT_PROJECT_SUCCESS,
} from './types';
import axiosInstance from '../../utils/api';

export const postCreateDevelopmentProjectRequest = () => {
  return {
    type: POST_CREATE_DEVELOPMENT_PROJECT_REQUEST,
  };
};
export const postCreateDevelopmentProjectSuccess = (value) => {
  return {
    type: POST_CREATE_DEVELOPMENT_PROJECT_SUCCESS,
    payload: value,
  };
};
export const postCreateDevelopmentProjectFailure = (error) => {
  return {
    type: POST_CREATE_DEVELOPMENT_PROJECT_FAILURE,
    payload: error,
  };
};

export const postCreateDevelopmentProject = (id, payload, config) => async (dispatch) => {
  dispatch(postCreateDevelopmentProjectRequest);
  return await axiosInstance
    .post(`/api/development/create/${id}`, payload, config)
    .then((response) => {
      const result = response.data;
      dispatch(postCreateDevelopmentProjectSuccess(result));
      return response;
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(postCreateDevelopmentProjectFailure(errorMsg));
      throw new Error(error?.response?.data?.message);
    });
};
