import { GET_ALERT_REQUEST, GET_ALERT_SUCCESS, GET_ALERT_FAILURE } from '../types'
import axiosInstance from '../../../utils/api'

export const getAlertRequest = () => {
  return {
    type: GET_ALERT_REQUEST,
  }
}

export const getAlertSuccess = (value) => {
  return {
    type: GET_ALERT_SUCCESS,
    payload: value,
  }
}

export const getAlertError = (error) => {
  return {
    type: GET_ALERT_FAILURE,
    payload: error,
  }
}

export const getAlertAction =
  (active = null) =>
  async (dispatch) => {
    dispatch(getAlertRequest)
    return axiosInstance
      .get(`/api/admin/notification/get${active ? '?active=true' : ''}`)
      .then((response) => {
        const result = response.data
        dispatch(getAlertSuccess(result))
      })
      .catch((error) => {
        const errorMsg = error.message
        dispatch(getAlertError(errorMsg))
        throw new Error(error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message)
      })
  }
