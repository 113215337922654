import { FETCH_HIGHLIGHTS_FAILURE, FETCH_HIGHLIGHTS_REQUEST, FETCH_HIGHLIGHTS_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  error: '',
  loading: false,
};

export const HighlightsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_HIGHLIGHTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_HIGHLIGHTS_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case FETCH_HIGHLIGHTS_FAILURE:
      return {
        data: [],
        error: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};
