import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { Box, Typography, Select, MenuItem, TextField, Button, TextareaAutosize, Divider } from '@mui/material'
import { iconComponent, iconComponentDown } from 'Components/ReusableComponents.js/reuseMethods'
import InfoAlertIcon from 'asserts/images/InfoAlert.svg'
import WarningAlertIcon from 'asserts/images/Warning.svg'
import ErrorAlert from 'asserts/images/ErrorAlert.svg'
import { createAlertAction } from 'store/action/NotificationAlerts/createNotificationAlerts'
import { defaultErrorMessage } from 'utils/constant'
import { useNotificationContext } from 'utils/NotificationContext'
import ConfirmationDialog from 'Components/SendNotification/ConfirmationDialog'
import Moment from 'moment'
import ReactQuill from 'react-quill'
import { useNavigate } from 'react-router-dom'
import 'react-quill/dist/quill.snow.css'
import 'quill-paste-smart'

let alertTypes = [
  {
    title: 'Information',
    icon: InfoAlertIcon,
  },
  {
    title: 'Error',
    icon: ErrorAlert,
  },
  {
    title: 'Warning',
    icon: WarningAlertIcon,
  },
]

const TemporaryAlert = ({ selectedItem, isTempSet, setIsTempSet, setIsChange }) => {
  const [selectArrow, setSelectArrow] = useState(true)
  const [alertType, setAlertType] = useState(selectedItem ? selectedItem.alertType : 'selectdefault')
  const [description, setDescription] = useState(selectedItem ? selectedItem.desc : '')
  const [startDate, setStartDate] = useState(selectedItem ? selectedItem.startDate : null)
  const [endDate, setEndDate] = useState(selectedItem ? selectedItem.endDate : null)
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const [showErrorBorder, setShowErrorBorder] = useState(false)
  const dispatch = useDispatch()
  const { showNotification } = useNotificationContext()
  const [descLength, setDescLength] = useState(selectedItem ? selectedItem.desc : '')
  let navigate = useNavigate()

  const isUpdate = useMemo(() => {
    return alertType !== 'selectdefault' || description?.length > 0 || startDate?.length > 0 || endDate?.length > 0
  }, [alertType, description, startDate, endDate])

  useEffect(() => {
    setIsChange(isUpdate)
  }, [isUpdate])

  let setTimerData = [
    {
      title: 'Start Date',
      setter: setStartDate,
      getter: startDate,
      minDate: new Date(),
    },
    {
      title: 'End Date',
      setter: setEndDate,
      getter: endDate,
      minDate: new Date(startDate),
    },
  ]

  const handleAlertTypeChange = (e) => {
    setAlertType(e.target.value)
  }

  const handleDescriptionChange = (e) => {
    if (e.target.value?.length >= 226) {
      setShowErrorBorder(true)

      setTimeout(() => {
        setShowErrorBorder(false)
      }, 3000)
    }

    if (e.target.value?.length <= 225) {
      setDescription(e.target.value)
    }
  }

  const handleDescriptionOnPasteEvent = (e) => {
    const pastedText = e.clipboardData.getData('text')
    if (pastedText?.length + e.target.value?.length > 225) {
      e.preventDefault()
      const truncatedText = pastedText.substring(0, 225 - e.target.value?.length)
      document.execCommand('insertText', false, truncatedText)
    }
  }

  const dateConvert = (dateStr) => {
    const date = new Date(dateStr)
    let month = date.getMonth() + 1
    let day = date.getDate()
    let year = date.getFullYear()
    const formattedDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`
    return formattedDate
  }

  const resetForm = () => {
    if (!isUpdate) {
      navigate('/settings')
    } else {
      setAlertType('selectdefault')
      setDescription('')
      setStartDate(null)
      setEndDate(null)
    }
  }

  const handleSubmit = () => {
    if (alertType === 'selectdefault') {
      showNotification('Error', 'Please select type of alert')
      return
    } else if (description?.trim()?.length < 1) {
      showNotification('Error', 'Please enter description')
      return
    }
    if (startDate == null || endDate == null) {
      showNotification('Error', 'Please select both start and end dates')
      return
    }
    if (Moment(startDate).isAfter(endDate, 'day')) {
      showNotification('Error', 'Start Date cannot be greater than End Date')
      return
    }

    setIsConfirmationDialogOpen(true)
  }
  const updateTemporaryAlert = async () => {
    const tkn = localStorage.getItem('tokenDetails')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tkn}`,
      },
    }
    let formattedStartDate = Moment(startDate).format('YYYY-MM-DD HH:mm:ss')
    let formattedEndDate = Moment(endDate)
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .format('YYYY-MM-DD HH:mm:ss')
    const payload = {
      type: 'temporary',
      desc: description,
      alertType: alertType.toLowerCase(),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }
    try {
      const response = await dispatch(createAlertAction(selectedItem.id, payload, config))
      if (response.status === 200 || response.status === 201) {
        showNotification('Success', response.data.message, 'success')
        resetForm()
      } else {
        showNotification('Error', response?.data?.message || defaultErrorMessage)
      }
    } catch (error) {
      showNotification('Error', error?.message || defaultErrorMessage)
    } finally {
      setIsConfirmationDialogOpen(false)
      setIsTempSet(false)
    }
  }
  const setTemporaryAlert = async () => {
    const tkn = localStorage.getItem('tokenDetails')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tkn}`,
      },
    }
    let formattedStartDate = Moment(startDate).format('YYYY-MM-DD HH:mm:ss')
    let formattedEndDate = Moment(endDate)
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .format('YYYY-MM-DD HH:mm:ss')
    const payload = {
      type: 'temporary',
      desc: description,
      alertType: alertType.toLowerCase(),
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }
    try {
      const response = await dispatch(createAlertAction(null, payload, config))
      if (response.status === 200 || response.status === 201) {
        showNotification('Success', response.data.message, 'success')
        resetForm()
      } else {
        showNotification('Error', response?.data?.message || defaultErrorMessage)
      }
    } catch (error) {
      showNotification('Error', error?.message || defaultErrorMessage)
    } finally {
      setIsConfirmationDialogOpen(false)
      setIsTempSet(false)
    }
  }

  const modules = {
    toolbar: [[{ link: 'link' }]],
  }
  const handleChange = (value) => {
    const cleanString = value.replace(new RegExp('<[^>]+>', 'g'), '').trimStart()
    const finalValue = value.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27BF]|[\uE000-\uF8FF]/g, '')
    setDescLength(cleanString.length)
    if (cleanString.length <= 0) {
      setDescription('')
    } else if (cleanString.length <= 225) {
      setDescription(finalValue)
    } else if (cleanString.length > 225) {
      const limitReached = cleanString.substring(0, 225)
      setDescription(limitReached)
      setShowErrorBorder(true)
      setTimeout(() => {
        setShowErrorBorder(false)
      }, 3000)
    }
  }

  return (
    <Box className="ml-3">
      <Box className="d-flex flex-column">
        <Typography variant="label" className="ml-2 alert-type-label">
          Types of Alert
        </Typography>
        <Select
          className="mt-3 alert-type-select"
          IconComponent={selectArrow ? iconComponent : iconComponentDown}
          onOpen={() => setSelectArrow(false)}
          onClose={() => setSelectArrow(true)}
          value={alertType}
          onChange={handleAlertTypeChange}
        >
          <MenuItem className="d-none" value="selectdefault">
            <Typography variant="p" className="alert-type-placeholder">
              Select type of Alert
            </Typography>
          </MenuItem>
          {alertTypes.map((item, index) => (
            <MenuItem key={index} value={item.title.toLowerCase()} className="menu-item">
              <Box className="d-flex align-items-center">
                <img src={item.icon} alt="" />
                <Typography variant="p" className="ml-2 alert-type-menu-item-title">
                  {item.title}
                </Typography>
              </Box>
            </MenuItem>
          ))}
        </Select>
        <Box className="DescContainer">
          <Box className={`${showErrorBorder && 'alert-text-area-warning'} desc-Box-error`}>
            <ReactQuill
              className={`text-editor  `}
              placeholder="Write a Message..."
              value={description}
              modules={modules}
              onChange={handleChange}
            />
          </Box>
          <Box className="desc-box2">
            <Typography variant="p" className={`${showErrorBorder && 'warning-text'}  mt-1 `}>
              {descLength > 0 ? (descLength > 225 ? '225/225' : `${descLength}/225`) : '0/225'}
            </Typography>
          </Box>
        </Box>
        <Box className="d-flex w-50 justify-content-between mt-1 gap-between">
          {setTimerData.map((item, index) => (
            <Box key={index} className="d-flex flex-column">
              <Typography variant="label" className="ml-2 alert-type-label">
                {item.title}
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  components={{
                    OpenPickerIcon: CalendarMonthIcon,
                  }}
                  InputProps={{
                    sx: {
                      '& .MuiSvgIcon-root': {
                        color: '#fff',
                        background: '#356f92',
                        width: '35px',
                        height: '35px',
                        padding: '5px',
                        borderRadius: '5px',
                      },
                    },
                  }}
                  inputFormat="DD MMM YYYY"
                  value={item.getter}
                  onChange={(newValue) => {
                    item.setter(dateConvert(newValue))
                  }}
                  minDate={item.minDate}
                  renderInput={(params) => (
                    <TextField
                      sx={{
                        '& .MuiInputBase-input': {
                          width: '14rem',
                          textTransform: 'uppercase',
                        },
                      }}
                      {...params}
                      className="bdrdate"
                      onKeyDown={(e) => {
                        e.preventDefault()
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          ))}
        </Box>
        <Box className="d-flex mt-5">
          {isTempSet ? (
            <Button
              variant="contained"
              sx={{ p: 1, mr: 4, backgroundColor: '#ef7335', fontWeight: 'bold' }}
              className="set-btn button-tr-2-13-disa"
              onClick={handleSubmit}
            >
              Update
            </Button>
          ) : (
            <Button
              variant="contained"
              sx={{ p: 1, mr: 4, backgroundColor: '#ef7335', fontWeight: 'bold' }}
              className="set-btn button-tr-2-13-disa"
              onClick={handleSubmit}
            >
              Set
            </Button>
          )}
          <Button variant="outlined" sx={{ p: 1 }} className="set-btn button-tr-2-1" onClick={resetForm}>
            Cancel
          </Button>
        </Box>
      </Box>
      {isConfirmationDialogOpen && isTempSet ? (
        <ConfirmationDialog
          isConfirmationDialogOpen={isConfirmationDialogOpen}
          message="Are you sure you want to Update this alert?"
          confirmBtnContent="Update"
          rejectBtnContent="CANCEL"
          onConfirm={updateTemporaryAlert}
          onReject={() => setIsConfirmationDialogOpen(false)}
        />
      ) : (
        <ConfirmationDialog
          isConfirmationDialogOpen={isConfirmationDialogOpen}
          message="Are you sure you want to set this alert?"
          confirmBtnContent="SET"
          rejectBtnContent="CANCEL"
          onConfirm={setTemporaryAlert}
          onReject={() => setIsConfirmationDialogOpen(false)}
        />
      )}
    </Box>
  )
}

export default TemporaryAlert
