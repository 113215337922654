export const MpColumnHeaders = {
  id: '#',
  icon: ' ',
  user_name: 'MP Name',
  constituency_name: 'Constituency',
  sevaScore: 'MP Seva Score',
  initiatives: 'Initiatives',
  eventOrganized: 'Events Organized',
  citizen: 'Citizens Engagement with Events',
  memberAdded: 'Members Added',
  followers: 'Followers',
  twitterPerformance: 'Twitter Performance',
  opEds: 'Op-Eds',
  mediaCoverage: 'Media Coverage',
  donation: 'Donations Raised',
  cardsCreated: 'E-Cards created by MP',
  likes: 'Likes on E-Cards created by MP',
  wishes: 'Wishes on E-Cards created by MP',
  campaignScore: 'E-Cards Campaign Score',
  campaignScore: 'Survey completed in constituency',
}

export const MpColumnFilter = [
  {
    screenNumber: 'sevaScore',
    columns: ['#', ' ', 'MP Name', 'Constituency', 'MP Seva Score'],
  },
  {
    screenNumber: 'survey',
    columns: ['#', ' ', 'MP Name', 'Constituency', 'Survey completed in constituency'],
  },
  {
    screenNumber: 'ecards',
    columns: [
      '#',
      ' ',
      'MP Name',
      'Constituency',
      'E-Cards created by MP',
      'Likes on E-Cards created by MP',
      'Wishes on E-Cards created by MP',
      'E-Cards Campaign Score',
    ],
  },
  {
    screenNumber: 'initiatives',
    columns: [
      '#',
      ' ',
      'MP Name',
      'Constituency',
      'Initiatives',
      'Events Organized',
      'Citizens Engagement with Events',
    ],
  },
  {
    screenNumber: 'memberAdded',
    columns: ['#', ' ', 'MP Name', 'Constituency', 'Members Added'],
  },
  {
    screenNumber: 'followers',
    columns: ['#', ' ', 'MP Name', 'Constituency', 'Followers'],
  },
  {
    screenNumber: 'twitterPerformance',
    columns: ['#', ' ', 'MP Name', 'Constituency', 'Twitter Performance'],
  },
  {
    screenNumber: 'mediaCoverage',
    columns: ['#', ' ', 'MP Name', 'Constituency', 'Op-Eds', 'Media Coverage'],
  },
  {
    screenNumber: 'donation',
    columns: ['#', ' ', 'MP Name', 'Constituency', 'Donations Raised'],
  },
]

export const MpSeva = [
  {
    constituency_name: '',
    designation: 'MP',
    house: 'Lok Sabha MP',
    party: 'Bharathiya Janatha Party(BJP)',
    sevaScore: 898,
    state_name: '',
    user_avatar: 'http://cdn.narendramodi.in/cmsuploads/0.18007300_1489647592_1489647577418.jpg',
    user_name: 'Pushkal saini1',
  },
]
