import { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails, Typography, Box, TextField, Button } from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { useNotificationContext } from 'utils/NotificationContext'

const WhatsNewAlertAccordionComponent = ({ title, dataState, accordionState, errorBorder }) => {
  const [isExpanded, setIsExpanded] = accordionState
  const [data, setData] = dataState
  const { showNotification } = useNotificationContext()
  const [showErrorBorder, setShowErrorBorder] = errorBorder

  const handleChangeEvent = (e, index) => {
    const inputValue = e.target.value
    const validInput = /^[a-zA-Z0-9!@#$%^&*()_+[\]{};:'",.<>/?\\| -]*$/.test(inputValue)
    if (!validInput) {
      return
    } else {
      if (e.target.value?.length >= 95) {
        const updatedShowErrorBorder = [...showErrorBorder]
        updatedShowErrorBorder[index] = true
        setShowErrorBorder(updatedShowErrorBorder)

        setTimeout(() => {
          const updatedShowErrorBorder = [...showErrorBorder]
          updatedShowErrorBorder[index] = false
          setShowErrorBorder(updatedShowErrorBorder)
        }, 3000)
      }

      if (e.target.value?.length <= 95) {
        const newArr = [...data]
        newArr[index] = e.target.value
        setData(newArr)
      }
    }
  }

  const addItem = () => {
    const newData = [...data]
    newData[newData?.length - 1] = newData[newData?.length - 1]?.trim()
    if (newData[newData?.length - 1]?.length < 1) {
      showNotification('Error', `${title} cannot be empty`)
    } else {
      setData([...newData, ''])
      showErrorBorder.push(false)
    }
  }

  return (
    <Accordion
      className="mb-5"
      sx={{
        borderRadius: '14px',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        position: 'static',
        '&:first-of-type': {
          borderTopLeftRadius: '14px',
          borderTopRightRadius: '14px',
        },
        '&:last-of-type': {
          borderBottomLeftRadius: '14px',
          borderBottomRightRadius: '14px',
        },
      }}
      expanded={isExpanded}
    >
      <AccordionSummary
        sx={{
          borderRadius: `${isExpanded ? '14px 14px 0px 0px' : '14px'}`,
          backgroundColor: '#e3f5ff',
        }}
        expandIcon={isExpanded ? <RemoveIcon color="primary" /> : <AddIcon color="primary" />}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <Typography variant="label" className="accordionTitle">
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          border: `${isExpanded ? '1px solid #D3D3D3' : 'none'}`,
          borderRadius: '0px 0px 14px 14px',
          borderWidth: '0px 1px 1px 1px',
        }}
      >
        <Box>
          {data?.map((item, index) => (
            <Box key={index} className="d-flex flex-column my-3">
              <TextField
                placeholder="Write a Message..."
                className="msg-txt-fld"
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                  },
                }}
                InputProps={{
                  style: {
                    border: showErrorBorder[index] === true ? '2px solid red' : 'none',
                  },
                }}
                value={item}
                onChange={(e) => handleChangeEvent(e, index)}
              />
              <Typography variant="p" className="txt-fld-counter mt-1">
                {data[index]?.length}/95
              </Typography>
            </Box>
          ))}
          <Box>
            <Button
              variant="outlined"
              sx={{ boxShadow: 'none' }}
              className="add-more-btn"
              startIcon={
                <AddIcon
                  sx={{
                    '&:nth-of-type(1)': {
                      fontSize: '1.7rem',
                    },
                  }}
                />
              }
              onClick={addItem}
            >
              Add More
            </Button>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default WhatsNewAlertAccordionComponent
