import React, { useState, useRef, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import closeIconp from 'asserts/images/close.png'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Paper,
  IconButton,
  FormHelperText,
  Typography,
  DialogContent,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Avatar,
} from '@mui/material'
import { set, useForm } from 'react-hook-form'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import UploadIcon from '@mui/icons-material/Upload'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import CrossIcon from 'asserts/images/Close.svg'
import { postCreateEvent } from 'store/action/createNewEvent'
import Moment from 'moment'
import { useLoading } from 'utils/LoadingContext'
import { useNotificationContext } from 'utils/NotificationContext'
import Constant, { defaultErrorMessage } from 'utils/constant'
import { getEventsListByMpId, getEventsList } from 'store/action/eventsList'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled'
import { getIntiativesReportByIdList } from 'store/action/ongoingSevaInitiativesList'
import imagedelete from 'asserts/images/bin-1.png'
import DeleteIndividualEventDialog from './DeleteIndividualEventDialog'
import { getIds } from 'Components/ReusableComponents.js/getIds'
import { handleFileName, onPdfFileUpload, validateNotEmpty } from 'Components/ReusableComponents.js/reuseMethods'
import { uploadMediaFiles } from 'store/action/uploadMedia'
import { uploadMedia } from 'utils/mediaUpload'
import { getAllInitiative } from 'store/action/allInitiatives'
import dayjs from 'dayjs'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { allowedImageFormat, acceptedImageFormat } from 'utils/constant'
import { pushTag } from 'utils/gtmservice'
import AddPdfComponent from 'Components/ReusableComponents.js/AddPdfComponent'
import PdfCard from 'Components/ReusableComponents.js/PdfCard'
import './CreateNewEvent.css'

const CreateNewEvent = ({
  handleCloseCreateEvent,
  openCreateEventDialog,
  isSevaEventEdit,
  details,
  handleCloseInitiativeDetails,
  mpId,
  initiativeId,
  initiativeName,
  eventByMpId,
  editEventDetails,
  handleCloseEventDetailsDialog,
  setCheckIsUpdate,
  setEventDeleted,
  limit = 10,
}) => {
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const [images, setImages] = useState([])
  const [files, setFiles] = useState([])
  // const [value, setValue] = useState(0);
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [coverImage, setCoverImage] = useState(null)
  const [coverImageName, setCoverImageName] = useState('')
  const [coverImageFile, setCoverImageFile] = useState(null)
  const [image, setImage] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteClick, setDeleteClick] = useState(false)
  const [checkCoverImage, setCheckCoverImage] = useState(false)
  const [checkUpdateChanges, setCheckUpdateChanges] = useState(true)
  const [prevImages, setPrevImages] = useState([])
  const [checkDescError, setCheckDescError] = useState(false)
  const [checkDescValue, setCheckDescValue] = useState(false)
  const [dateChange, setDateChange] = useState({
    startDate: true,
    endDate: true,
  })
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const [endDateCheck, setEndDateCheck] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm()
  const hiddenFileInput = useRef(null)
  const hiddenCoverImageInput = useRef(null)
  const PdfEventFileInput = useRef(null)
  const dispatch = useDispatch()
  const fileFormats = ['image/png', 'image/jpg']
  const loggedInId = getIds()
  // const handleChange = (event, newValue) => {
  //     setValue(newValue);
  // };

  const postAuditLogs = async (sqId, e_id, i_id) => {
    let requestData = {
      useridtype: getIds(),
    }
    if (i_id !== null) {
      requestData['i_id'] = i_id && i_id
    }
    if (e_id !== null) {
      requestData['e_id'] = e_id && e_id
    }
    await makeLogRequest('edit_event_popup', sqId, requestData)
  }

  const sendGALog = (event) => {
    const page_title = 'create_individual_event_popup'
    const payload = {
      event,
      page_title,
    }
    payload.match = initiativeName ? initiativeName : 'NA'
    pushTag(payload)
  }

  const handleEndDate = () => {
    if (endDateCheck) {
      setEndDate(null)
      setEndTime(null)
      setCheckUpdateChanges(false)
    }
    setEndDateCheck(!endDateCheck)
  }
  useEffect(() => {
    if (!details?.detailsPdf?.[0]) {
      setDetailPdf([])
    }
    if (isSevaEventEdit) {
      postAuditLogs('pageload', details?.id, null)
    }
  }, [])
  useEffect(() => {
    if (details) {
      setCoverImage(details?.coverimage && JSON.parse(details?.coverimage))
      if (details?.media !== null) {
        const imageArray = details?.media && JSON.parse(details?.media)
        setImages(imageArray)
        setPrevImages(imageArray)
      }
    }
  }, [details])

  useEffect(() => {
    if (deleteClick) {
      handleCloseCreateEvent(reset, setImages, setFiles, setStartDate, setEndDate, setStartTime, setEndTime)
    }
  }, [deleteClick])

  useEffect(() => {
    if (details && details?.statTime) {
      const utcDateTime = Moment.utc(details?.statTime)
      const formattedStartDate = utcDateTime.format()
      const formattedDate = formattedStartDate.split('T')[0]
      setStartDate(formattedDate)
      setStartTime(formattedStartDate.slice(0, formattedStartDate.length - 1))
      if (details?.endTime) {
        const utcEndDateTime = Moment.utc(details?.endTime)
        const formattedEndDate = utcEndDateTime.format()
        setEndTime(formattedEndDate.slice(0, formattedEndDate.length - 1))
        setEndDate(formattedEndDate?.split('T')[0] || '')
        setEndDateCheck(true)
      }
    }
    setDateChange({ startDate: false, endDate: false })
  }, [])

  useEffect(() => {
    const parameters = {
      useridtype: getIds(),
    }
    sendCreateEventLogs('pageload', parameters)
    sendGALog('pop_up')
  }, [])

  const [detailPdf, setDetailPdf] = useState(
    (typeof details?.detailsPdf == 'string' && details?.detailsPdf !== '[]') ||
      (typeof details?.detailsPdf == 'object' && details?.detailsPdf?.[0])
      ? { name: handleFileName(details?.detailsPdf) }
      : null
  )

  const handlePdfClick = (event) => {
    PdfEventFileInput.current.value = ''
    PdfEventFileInput.current?.click()
    event?.stopPropagation()
    PdfEventFileInput.current.addEventListener('change', () => {
      if (PdfEventFileInput.current.value === '') {
        setCheckUpdateChanges(true)
      } else {
        setCheckUpdateChanges(false)
      }
    })
  }

  const sendCloseDialogLog = () => {
    const parameters = {
      useridtype: getIds(),
    }
    sendCreateEventLogs('close', parameters)
  }

  const sendCreateEventLogs = async (type, parameters) => {
    await makeLogRequest('create_event_popup', type, parameters)
  }

  const addImagesToFormData = async (formData, images) => {
    for (let i = 0; i < images.length; i++) {
      const response = await fetch(images[i].src)
      const blob = await response.blob()
      const file = new File([blob], `image${i}.jpg`, { type: 'image/*' })
      formData.append(`media`, file, file.name)
    }
  }

  const addCoverImageToFormData = async (formData, coverImage) => {
    const response = await fetch(coverImage)
    const blob = await response.blob()
    const file = new File([blob], `image.jpg`, { type: blob.type })
    formData.append(`coverimage`, file, file.name)
  }

  const uploadImagesToFormData = async (formData, images) => {
    for (let i = 0; i < images.length; i++) {
      const response = await fetch(images[i].src)
      const blob = await response.blob()
      const file = new File([blob], `image${i}.jpg`, { type: 'image/*' })
      formData.append(`media`, file, file.name)
    }
  }

  const calculateEndMin = () => {
    if (startTime && startDate === endDate) {
      return dayjs(startTime).add(1, 'minute')
    }
  }

  // Use this method instead of Moment.js as it is not supported in Safari
  const getTimeValue = (date, time) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(':')
    // Create a new Date object using individual components
    const formattedDateTime = new Date(
      Date.UTC(
        Number(date.slice(0, 4)),
        Number(date.slice(5, 7)) - 1,
        Number(date.slice(8, 10)),
        Number(hours),
        Number(minutes)
      )
    )

    // Format the date to ISO 8601 format (yyyy-MM-ddTHH:mm:ss)
    const year = formattedDateTime.getUTCFullYear()
    const month = String(formattedDateTime.getUTCMonth() + 1).padStart(2, '0')
    const day = String(formattedDateTime.getUTCDate()).padStart(2, '0')
    const hoursUTC = String(formattedDateTime.getUTCHours()).padStart(2, '0')
    const minutesUTC = String(formattedDateTime.getUTCMinutes()).padStart(2, '0')
    const secondsUTC = String(formattedDateTime.getUTCSeconds()).padStart(2, '0')

    return `${year}-${month}-${day}T${hoursUTC}:${minutesUTC}:${secondsUTC}`
  }

  const onAddSevaEvent = async (data) => {
    // if (isSevaEventEdit && images.length === 0) {
    //   showNotification("Error", "Please add event images");
    //   return;
    // }
    await postAuditLogs('update', details?.id, details?.initiativeId)
    if (!coverImageName && !coverImage) return
    setLoading(true)
    const tkn = localStorage.getItem('tokenDetails')
    const formattedDate = '2023-05-19T18:29:00.000Z'
    const etime = endTime ? endTime : formattedDate

    const imgList = document.querySelectorAll('.imageupload') || []
    const mediaImages = Array.from(imgList)
    image && mediaImages.push(image)

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tkn}`,
      },
    }
    let mediaFiles = coverImage
    if (!coverImage?.length || coverImage?.toString()?.startsWith('blob')) {
      mediaFiles = await uploadMedia(config, { coverImage: coverImageFile })
    }
    let imageMediaFiles = []
    if (files?.length > 0) {
      imageMediaFiles = await uploadMedia(config, { imageFiles: files }, true)
    }
    const prevImagesArray = prevImages.map((url) => ({ media: url }))
    const coverImageUrl = mediaFiles?.coverImageUrl != null ? mediaFiles?.coverImageUrl : coverImage

    let formattedStartDate = Moment(startDate).format('YYYY-MM-DD')
    const formattedEndDate = endDate ? Moment(endDate).format('YYYY-MM-DD') : null

    let stTime = ''
    if (typeof startTime === 'string') {
      const st = new Date(startTime)
      stTime = `${st.getHours()}:${st.getMinutes()}`
    } else {
      const st = startTime
      stTime = `${st.$H}:${st.$m}`
    }
    let etTime = ''
    if (typeof etime === 'string') {
      const et = new Date(etime)
      etTime = `${et.getHours()}:${et.getMinutes()}`
    } else {
      const et = etime
      etTime = `${et.$H}:${et.$m}`
    }
    const sTime = getTimeValue(formattedStartDate, stTime)
    let eTime = null
    if (formattedEndDate) {
      eTime = getTimeValue(formattedEndDate, etTime)
    }

    let stepUrl = detailPdf ? detailPdf : {}
    if (detailPdf?.size) {
      stepUrl = await uploadMedia(dispatch, { stepPdf: detailPdf })
    } else if (detailPdf) stepUrl.stepPdfUrl = details?.detailsPdf
    else stepUrl.stepPdfUrl = []

    const payloadObj = {
      eventTitle: data?.eventTitle,
      location: data?.location,
      startDate: formattedStartDate,
      detailsPdf: stepUrl?.stepPdfUrl || [],
      endDate: formattedEndDate || null,
      startTime: sTime,
      endTime: eTime,
      desc: data?.desc,
      participants: parseInt('participants' in data ? data?.participants : 0),
      initiativeId: initiativeId ? initiativeId : 0,
      mpmodelId: loggedInId,
      coverimage: Array.isArray(coverImageUrl) ? [...coverImageUrl] : [coverImageUrl || null],
    }
    if (isSevaEventEdit) {
      if (imageMediaFiles?.imageUrls?.length > 0) {
        const mediaArr = [...imageMediaFiles?.imageUrls, ...prevImagesArray] || []
        payloadObj.media = mediaArr
      } else {
        payloadObj.media = prevImagesArray
      }
    }

    const id = details?.id ? details?.id : 0

    const parameters = {
      useridtype: getIds(),
      e_id: id,
    }
    if (initiativeId) parameters.i_id = initiativeId

    sendCreateEventLogs('save', parameters)
    sendGALog(isSevaEventEdit ? 'edit' : 'proceed')

    try {
      // setLoading(true);
      // if (!isSevaEventEdit && files.length === 0)
      const response = await dispatch(postCreateEvent(id, payloadObj, config))
      if (response.status === 200 || response.status === 201) {
        dispatch(getAllInitiative(loggedInId))

        showNotification('Success', response.data.message, 'success')
        handleCloseCreateEvent(reset, setImages, setFiles, setStartDate, setEndDate, setStartTime, setEndTime)
        if (details) {
          handleCloseInitiativeDetails && handleCloseInitiativeDetails({ updated: true })
        }
        if (editEventDetails) {
          handleCloseEventDetailsDialog()
          dispatch(getIntiativesReportByIdList(initiativeId))
        }
        const payload = {
          id: 0,
          limit,
        }
        if (eventByMpId) {
          payload.id = mpId
          dispatch(getEventsListByMpId(payload))
        } else {
          dispatch(getEventsList(payload))
        }
        Object.keys(data).map((val) => resetField(val))
        setImages([])
        setFiles([])
      } else {
        showNotification('Error', response?.data?.message || defaultErrorMessage)
      }
    } catch (error) {
      showNotification('Error', error?.message || defaultErrorMessage)
    } finally {
      setLoading(false)
      setCheckIsUpdate && setCheckIsUpdate(true)
    }
  }

  const handleDelete = (index) => {
    hiddenFileInput.current.value = ''
    const tempImages = [...images]
    tempImages.splice(index, 1)
    setImages(tempImages)
    const tempFiles = [...files]
    tempFiles.splice(index, 1)
    setFiles(tempFiles)
    setCheckUpdateChanges(false)
    const deletedImagesArr = prevImages.splice(index, 1)
    setPrevImages(prevImages)
  }

  const handleImageChange = (e) => {
    const uploadedFiles = e.target.files
    //todo coverimage
    // setCoverImage("")
    let newImages = []
    let processedImages = 0
    for (let i = 0; i < uploadedFiles.length; i++) {
      const isRightFormat = allowedImageFormat.includes(uploadedFiles[i].type)
      const fileSizeInBytes = uploadedFiles[i].size
      const maxSizeInBytes = 20 * 1024 * 1024 // 20MB
      if (fileSizeInBytes > maxSizeInBytes) {
        showNotification('Error', 'Please upload image with size less than 20MB', 'error')
        e.target.value = null
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(uploadedFiles[i])
      reader.onload = () => {
        newImages.push({ url: reader.result, file: uploadedFiles[i] })
        processedImages++

        if (processedImages === uploadedFiles.length) {
          if (!isRightFormat) {
            showNotification('Error', 'Please upload image in JPG or PNG format only', 'error')
            e.target.value = null
            return
          }
          setCheckUpdateChanges(false)
          setImages([...newImages, ...images])
          setFiles([...files, ...uploadedFiles])
        }
      }
    }
  }

  const handleCoverImageChange = (event) => {
    const type = event.target.files[0]?.type
    const fileSizeInBytes = event.target.files[0].size
    const maxSizeInBytes = 20 * 1024 * 1024 // 20MB
    if (fileSizeInBytes > maxSizeInBytes) {
      showNotification('Error', 'Please upload image with size less than 20MB', 'error')
      return
    }
    const isRightFormat = allowedImageFormat.includes(type)
    if (!isRightFormat) {
      showNotification('Error', 'Please upload image in JPG or PNG format only', 'error')
      return
    }
    const files = event.target.files[0]
    setCoverImageFile(event.target.files[0])
    setCheckUpdateChanges(false)
    setCoverImage(URL.createObjectURL(files))
    setCoverImageName(event.target.files[0].name)
  }

  const handleDeleteCoverImage = () => {
    setImage(null)
    setCoverImage('')
    setCoverImageName(null)
    setCheckUpdateChanges(false)
  }
  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const handleCoverImageClick = (event) => {
    hiddenCoverImageInput.current.click()
    const parameters = {
      useridtype: getIds(),
    }
    sendCreateEventLogs('browse', parameters)
  }

  const dateConvert = (dateStr) => {
    const date = new Date(dateStr)
    return Moment(date).format('YYYY-MM-DD')
  }

  const timeConvert = (timeInput) => {
    const date = new Date(timeInput)
    let time = date.getTime()
    return parseInt(time)
  }
  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false)

  const blockInvalidChar = (e) => {
    if (['e', 'E', '+', '-', '.'].includes(e.key) || e.keyCode === 40) {
      e.preventDefault()
    }
    if (e.keyCode === 40) {
      // 40 is the key code for down arrow
      const currentValue = e.target.value
      if (currentValue > 1) {
        e.target.value = Number(currentValue) - 1
      }
      e.preventDefault()
    }
  }

  const onPasteCLick = (e) => {
    var clipboardData = e.clipboardData.getData('text')
    var cleanedData = clipboardData.replace(/[-e]/gi, '')
    if (cleanedData.length === 0) {
      e.preventDefault()
    } else {
      document.execCommand('insertText', false, cleanedData)
      e.preventDefault()
    }
  }

  const capitalizeFirstChar = (str) => {
    if (!str) return ''
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const [capitalizedLocation, setCapitalizedLocation] = useState(details && capitalizeFirstChar(details?.location))

  const handleLocationChange = (event) => {
    const inputValue = event.target.value
    const capitalizedInput = capitalizeFirstChar(inputValue)
    setCapitalizedLocation(capitalizedInput)
  }

  const getTimeComparison = (startTime, endTime) => {
    const startDate = new Date(startTime)
    const endDate = new Date(endTime)
    const startHours =
      startDate.getHours().toString().padStart(2, '0') + ':' + startDate.getMinutes().toString().padStart(2, '0')
    const endHours =
      endDate.getHours().toString().padStart(2, '0') + ':' + endDate.getMinutes().toString().padStart(2, '0')
    return endHours <= startHours
  }

  const checkTextField = (value, maxLength, minLength, register_name, fieldName) => {
    if (value.length == 0) {
      setError(`${register_name}`, {
        type: 'value',
        message: `Please enter ${fieldName}`,
      })
    } else if (value.length > 0 && value.length < minLength) {
      setError(`${register_name}`, {
        type: 'minLength',
        message: `Minimum character length is ${minLength}`,
      })
    } else if (value.length == maxLength) {
      setError(`${register_name}`, {
        type: 'maxLength',
        message: 'Maximum limit reached',
      })
    } else {
      clearErrors(`${register_name}`)
    }
  }

  const setDescription = (e) => {
    let inputValue = e.target.value?.trimStart()
    inputValue = inputValue.replace(/\s{2,}/g, ' ')
    checkTextField(inputValue, 5000, 10, 'desc', 'description')
    setValue('desc', inputValue)
    setCheckUpdateChanges(false)
    if (inputValue !== '') {
      setCheckDescValue(true)
    } else {
      setCheckDescValue(false)
    }
    if (inputValue.replaceAll('\n', '').length > 5000) {
      setCheckDescValue(false)
      setCheckDescError(true)
      setCheckUpdateChanges(true)
    } else {
      setCheckDescError(false)
      setCheckUpdateChanges(false)
    }
  }

  const setEventTitle = (e) => {
    let inputValue = e.target.value?.trimStart()
    inputValue = inputValue.replace(/\s{2,}/g, ' ')
    checkTextField(inputValue, 300, 10, 'eventTitle', 'event title')
    setValue('eventTitle', inputValue)
    setCheckUpdateChanges(false)
  }

  const setLocation = (e) => {
    let inputValue = e.target.value?.trimStart()
    inputValue = inputValue.replace(/\s{2,}/g, ' ')
    checkTextField(inputValue, 255, 3, 'location', 'a valid address')
    setValue('location', inputValue)
    setCheckUpdateChanges(false)
    handleLocationChange(e)
  }

  const handleDialogClose = () => {
    postAuditLogs('close', null, null)
    handleCloseCreateEvent(reset, setImages, setFiles, setStartDate, setEndDate, setStartTime, setEndTime)
  }

  const handleCloseIconClick = () => {
    sendCloseDialogLog()
    sendGALog('back')
    handleCloseCreateEvent(reset, setImages, setFiles, setStartDate, setEndDate, setStartTime, setEndTime)
  }
  const handleParticipant = (e) => {
    if (e.target.value > 0 && e.target.value < 10) {
      clearErrors('participants')
      setValue('participants', e.target.value)
    }
    if (e.target.value !== 0 && e.target.value.length <= 10) {
      clearErrors('participants')
      setValue('participants', e.target.value)
    }
    setCheckUpdateChanges(false)
  }

  return (
    <>
      <Dialog open={openCreateEventDialog} onClose={handleDialogClose} className="card-modal-height" sx={{ mt: 2 }}>
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              color: '#357092',
              ml: '25px',
            }}
          >
            <Box className="initiative-title">
              {initiativeName ? (
                <>
                  <Typography className="initiative-title-text">
                    {isSevaEventEdit ? 'Edit' : 'Create'} an Event for
                  </Typography>
                  <Box />
                  <Typography className="ellipsewehe12 initiative-name-text">{initiativeName}</Typography>
                </>
              ) : (
                <Typography className="initiative-title">
                  {isSevaEventEdit || editEventDetails ? 'Edit' : 'Create'} an Event
                </Typography>
              )}
            </Box>
            <Avatar
              onClick={handleCloseIconClick}
              src={CrossIcon}
              className="closeIcon"
              sx={{ height: '30px', width: '30px', position: 'absolute' }}
            />
          </Box>
        </DialogTitle>
        <DialogContent>
          <Grid container className="bg-white">
            <Grid item xs={12} md={12}>
              <Grid item xs={12} md={12} sx={{ pt: 4, pl: 4 }}>
                <Box>
                  <form>
                    <Grid container spacing={1} justifyContent="left" alignItems="center">
                      <Grid container sx={{ mb: 2 }}>
                        <Grid item xs={12} sx={{ pr: 1 }}>
                          <Typography className="createnew-event-textfield-label">Event Title*</Typography>
                          <FormControl
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '14px',
                              },
                            }}
                          >
                            <TextField
                              className="stepperFormInput"
                              // label="Event Title"
                              name="eventTitle"
                              fullWidth
                              placeholder="Enter title"
                              size="small"
                              required
                              maxLength={2}
                              defaultValue={details && details?.eventTitle}
                              autoComplete="off"
                              inputProps={{
                                maxLength: 300,
                                minLength: 10,
                              }}
                              {...register('eventTitle', {
                                required: 'Please enter event title',
                                maxLength: {
                                  value: 300,
                                  message: 'Maximum character length is 300',
                                },
                                minLength: {
                                  value: 10,
                                  message: 'Minimum character length is 10',
                                },
                                validate: (value) => validateNotEmpty(value, 'event title'),
                              })}
                              //   error={Boolean(errors?.employeeId?.message)}
                              //   helperText={errors?.employeeId?.message}
                              onChange={setEventTitle}
                            />
                          </FormControl>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {errors && errors?.eventTitle?.message}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ mb: 2 }}>
                        <Grid item xs={6} sx={{ pr: 1 }}>
                          <Typography className="createnew-event-textfield-label">Location*</Typography>
                          <FormControl
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '14px',
                              },
                            }}
                          >
                            <TextField
                              className="stepperFormInput"
                              // label="Location"
                              name="location"
                              fullWidth
                              placeholder="Enter location"
                              size="small"
                              required
                              value={capitalizedLocation}
                              defaultValue={details && details?.location}
                              autoComplete="off"
                              inputProps={{
                                maxLength: 255,
                                minLength: 3,
                              }}
                              {...register('location', {
                                required: 'Please enter a valid address',
                                maxLength: {
                                  value: 255,
                                  message: 'Maximum limit reached',
                                },
                                minLength: {
                                  value: 3,
                                  message: 'Minimum character length is 3',
                                },
                                validate: (value) => validateNotEmpty(value, 'location'),
                              })}
                              onChange={(e) => setLocation(e)}
                            />
                          </FormControl>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {errors && errors?.location?.message}
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={6} sx={{ pr: 1 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography className="createnew-event-textfield-label">
                              Participant Count{(isSevaEventEdit || editEventDetails) && '*'}
                            </Typography>
                            {!(isSevaEventEdit || editEventDetails) && (
                              <Typography className="createnew-event-textfield-label-optional ml-1">
                                {' (Optional)'}
                              </Typography>
                            )}
                          </Box>
                          <FormControl
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '14px',
                              },
                            }}
                          >
                            <TextField
                              className="stepperFormInput partic"
                              name="participants"
                              fullWidth
                              placeholder="Enter participants count"
                              size="small"
                              required={isSevaEventEdit || editEventDetails ? true : false}
                              type="number"
                              defaultValue={details && details.participants}
                              autoComplete="off"
                              {...(isSevaEventEdit || editEventDetails
                                ? register('participants', {
                                    maxLength: {
                                      value: 10,
                                      message: 'Maximum number of participants is a 10-digit number',
                                    },
                                    validate: (value) => {
                                      if (value === '0') {
                                        return 'Participants count cannot be 0'
                                      } else if (/^0+$/.test(value)) {
                                        return 'Participants count cannot be 0'
                                      } else if (value === '') {
                                        return 'Participants count cannot be empty'
                                      }
                                      return true
                                    },
                                  })
                                : {})}
                              onChange={(e) => handleParticipant(e)}
                              onKeyDown={blockInvalidChar}
                              onPaste={onPasteCLick}
                              onWheel={(e) => {
                                e.target.blur()
                                e.stopPropagation()
                              }}
                            />
                          </FormControl>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {errors && errors?.participants?.message}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid container sx={{ mb: 2 }}>
                        <Grid item xs={6}>
                          <Typography className="createnew-event-textfield-label">Start Date*</Typography>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            sx={{
                              '& .MuiInputBase-input': {
                                width: '180px',
                                height: '25px!important',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '14px',
                                paddingRight: '-32px',
                              },
                              '& .MuiOutlinedInput-input': {
                                borderRadius: '14px',
                                paddingRight: '14px!important',
                              },
                            }}
                          >
                            <DatePicker
                              inputFormat="DD-MM-YYYY"
                              // label="Start Date"
                              components={{
                                OpenPickerIcon: CalendarMonthIcon,
                              }}
                              InputProps={{
                                sx: {
                                  '& .MuiSvgIcon-root': {
                                    color: '#fff',
                                    background: '#356f92',
                                    width: '35px',
                                    height: '35px',
                                    padding: '5px',
                                    borderRadius: '5px',
                                  },
                                },
                              }}
                              value={startDate}
                              onChange={(newValue) => {
                                const dateChangeObj = dateChange
                                dateChange.startDate = true
                                setDateChange(dateChangeObj)
                                setStartDate(dateConvert(newValue))
                                setCheckUpdateChanges(false)
                              }}
                              minDate={new Date('2023-01-01')}
                              maxDate={endDate}
                              defaultValue={startDate}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="bdrdate"
                                  {...register('startDate', {
                                    required: !startDate ? 'Please set a start date' : false,
                                  })}
                                  onKeyDown={(e) => {
                                    e.preventDefault()
                                  }}
                                  error={!startDate && Boolean(errors?.startDate?.message)}
                                  helperText={!startDate && errors?.startDate?.message}
                                />
                              )}
                            />
                          </LocalizationProvider>
                          <InputLabel className="start-date-tooltip">
                            Start date must be 1st January 2023 or later.
                          </InputLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography className="createnew-event-textfield-label">Start Time*</Typography>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              components={{
                                OpenPickerIcon: AccessTimeFilledIcon,
                              }}
                              // label="Start Time"
                              maxTime={endTime && startDate === endDate && dayjs(endTime).subtract(1, 'minute')}
                              value={startTime}
                              onChange={(newValue) => {
                                setStartTime(newValue)
                                setCheckUpdateChanges(false)
                                errors.startTime = null
                              }}
                              inputProps={{
                                placeholder: 'hh:mm am/pm', // Add a placeholder text
                              }}
                              InputProps={{
                                sx: {
                                  '& .MuiSvgIcon-root': {
                                    color: '#fff',
                                    background: '#356f92',
                                    width: '35px',
                                    height: '35px',
                                    padding: '5px',
                                    borderRadius: '5px',
                                  },
                                },
                              }}
                              // defaultValue={startTime}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  className="bdrdate"
                                  {...register('startTime', {
                                    required: !startTime ? 'Please set a start time' : false,
                                  })}
                                  onKeyDown={(e) => {
                                    e.preventDefault()
                                  }}
                                  error={!startTime && Boolean(errors?.startTime?.message)}
                                  helperText={errors?.startTime?.message}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                      <FormControlLabel
                        control={<Checkbox checked={endDateCheck} onChange={handleEndDate} />}
                        label={
                          <Typography variant="body2" className="add-end-date-and-time-text">
                            Add End Date and Time
                          </Typography>
                        }
                      />
                      {endDateCheck && (
                        <Grid container sx={{ mb: 2 }}>
                          <Grid item xs={6} sx={{ pr: 1 }}>
                            <Typography className="createnew-event-textfield-label">End Date*</Typography>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                inputFormat="DD-MM-YYYY"
                                // label="End Date"
                                components={{
                                  OpenPickerIcon: CalendarMonthIcon,
                                }}
                                InputProps={{
                                  sx: {
                                    '& .MuiSvgIcon-root': {
                                      color: '#fff',
                                      background: '#356f92',
                                      width: '35px',
                                      height: '35px',
                                      padding: '5px',
                                      borderRadius: '5px',
                                    },
                                  },
                                }}
                                value={endDate}
                                onChange={(newValue) => {
                                  const dateChangeObj = dateChange
                                  dateChange.endDate = true
                                  setDateChange(dateChangeObj)
                                  setEndDate(dateConvert(newValue))
                                  setCheckUpdateChanges(false)
                                }}
                                minDate={startDate ? startDate : new Date()}
                                defaultValue={endDate}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="bdrdate"
                                    {...register('endDate', {
                                      required: !endDate ? 'End date is required' : false,
                                    })}
                                    onKeyDown={(e) => {
                                      e.preventDefault()
                                    }}
                                    error={endDate < startDate && Boolean(errors?.endDate?.message)}
                                    helperText={!endDate && errors?.endDate?.message}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className="createnew-event-textfield-label">End Time*</Typography>

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <TimePicker
                                components={{
                                  OpenPickerIcon: AccessTimeFilledIcon,
                                }}
                                inputProps={{
                                  placeholder: 'hh:mm am/pm', // Add a placeholder text
                                }}
                                InputProps={{
                                  sx: {
                                    '& .MuiSvgIcon-root': {
                                      color: '#fff',
                                      background: '#356f92',
                                      width: '35px',
                                      height: '35px',
                                      padding: '5px',
                                      borderRadius: '5px',
                                    },
                                  },
                                }}
                                // label="End Time"
                                minTime={calculateEndMin()}
                                value={endTime}
                                onChange={(newValue) => {
                                  setEndTime(newValue)
                                  setCheckUpdateChanges(false)
                                }}
                                // defaultValue={endTime}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    className="bdrdate"
                                    {...register('endTime', {
                                      required: !endTime ? 'End time is required' : false,
                                      validate: (value) => {
                                        if (endTime && startDate === endDate && getTimeComparison(startTime, endTime)) {
                                          return 'The end time must be later than the start time.'
                                        }
                                      },
                                    })}
                                    onKeyDown={(e) => {
                                      e.preventDefault()
                                    }}
                                    error={
                                      (!endTime && false) ||
                                      (startDate !== endDate && false) ||
                                      (endTime &&
                                        startDate === endDate &&
                                        getTimeComparison(startTime, endTime) &&
                                        true)
                                    }
                                    helperText={
                                      (startDate && startDate === endDate && errors?.endTime?.message) ||
                                      (!endTime && errors?.endTime?.message)
                                    }
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container sx={{ mb: 2, mt: 1 }}>
                        <Grid item xs={12} sx={{ pr: 1 }}>
                          <Typography className="createnew-event-textfield-label">About Event*</Typography>
                          {/* <FormControl
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: "14px",
                              },
                            }}
                          > */}
                          <TextField
                            className="stepperFormInput"
                            InputProps={{ sx: { height: 80, borderRadius: '14px' } }}
                            name="desc"
                            placeholder="About Event"
                            fullWidth
                            required
                            multiline
                            rows={3}
                            defaultValue={details?.desc}
                            size="small"
                            autoComplete="off"
                            inputProps={{
                              maxLength: 5000,
                              minLength: 10,
                              wrap: 'soft',
                            }}
                            {...register('desc', {
                              required: 'Please enter a description',
                              maxLength: {
                                value: 5000,
                                message: 'Maximum 5000 characters allowed',
                              },
                              minLength: {
                                value: 10,
                                message: 'Minimum character length is 10',
                              },
                              validate: (value) => validateNotEmpty(value, 'description'),
                            })}
                            onChange={setDescription}
                            onPaste={(e) => {
                              const pastedText = e.clipboardData.getData('text')

                              if (
                                pastedText.replace(/[\r\n]/gm, '').length +
                                  e.target.value.replace(/[\r\n]/gm, '').length >
                                5000
                              ) {
                                e.preventDefault()
                                const truncatedText = pastedText.substring(0, 5000 - e.target.value.length)
                                document.execCommand('insertText', false, truncatedText)
                                setCheckDescError(true)
                              }
                            }}
                          />
                          <FormControl>
                            <FormHelperText
                              sx={{
                                color: errors && errors?.desc?.message ? '#d32f2f !important' : 'blue !important',
                              }}
                            >
                              {errors && errors?.desc?.message
                                ? errors && errors?.desc?.message
                                : checkDescError || checkDescValue
                                ? 'Maximum 5000 characters allowed'
                                : errors?.desc?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      {
                        <>
                          {' '}
                          <Grid container>
                            <Box className="upload-cover-image-label-container">
                              <Typography className="upload-asset-label">Upload Cover Image*</Typography>
                            </Box>
                          </Grid>
                          {coverImage && (
                            <>
                              {' '}
                              <Avatar
                                src={coverImage && coverImage}
                                alt=""
                                className="form-img__img-preview-4 "
                                sx={{
                                  display: 'block',
                                  width: '150px',
                                  height: '150px',
                                  objectFit: 'cover',
                                  borderRadius: '20px',
                                }}
                              />
                              <Avatar
                                src={closeIconp}
                                onClick={handleDeleteCoverImage}
                                className="imageclose-2-spac"
                                sx={{
                                  width: '22px',
                                  height: '22px',
                                  padding: '5px',
                                }}
                              />
                            </>
                          )}
                          <Grid item xs={6} sx={{ pr: 1, pl: 1 }} className="pt-0">
                            {!coverImage && (
                              <>
                                <Box className="cls-input-sty">
                                  <input
                                    type="file"
                                    accept={acceptedImageFormat}
                                    ref={hiddenCoverImageInput}
                                    onChange={handleCoverImageChange}
                                    className="upload-asset-input-button"
                                  />
                                  <Box className="d-flex align-items-center">
                                    <Button onClick={handleCoverImageClick} className="browse-button">
                                      {'Browse'}
                                    </Button>
                                    {coverImageName ? (
                                      <Typography
                                        sx={{
                                          display: 'block',
                                          fontSize: '14px',
                                        }}
                                        className="ellipsewehe"
                                      >
                                        {coverImageName}
                                      </Typography>
                                    ) : null}
                                  </Box>
                                </Box>
                                <FormHelperText sx={{ color: '#d32f2f' }}>
                                  {checkCoverImage && !coverImageName && 'Please upload a cover image'}
                                </FormHelperText>
                              </>
                            )}
                          </Grid>
                        </>
                      }
                      {isSevaEventEdit && images?.length > 0 && (
                        <>
                          <Grid container>
                            <Box className="upload-event-image-label-container">
                              <Typography className="upload-asset-label">Upload Event Image</Typography>
                              <Typography className="upload-asset-note-text">
                                Note: Select one of the image as a cover photo*
                              </Typography>
                            </Box>
                          </Grid>
                          <Box className={images?.length === 1 ? 'ml-3' : ''}>
                            {images?.length > 0 ? (
                              <Grid item xs={6} sx={{ display: 'inline', pr: 1 }}>
                                <Box className="postionabs itemfixed3   uploaded-event-images-outer-container">
                                  <Box className="uploaded-event-images-inner-container">
                                    <Tabs
                                      // value={value}
                                      // onChange={handleChange}

                                      variant="scrollable"
                                      scrollButtons={images?.length > 1}
                                      aria-label="visible arrows tabs example"
                                      sx={{
                                        [`& .${tabsClasses.scrollButtons}`]: {
                                          '&.Mui-disabled': { opacity: 0.3 },
                                        },
                                      }}
                                    >
                                      {images?.map((image, index) => (
                                        <Card
                                          sx={{
                                            minWidth: 200,
                                            minHeight: 200,
                                            borderRadius: 0,
                                            boxShadow: 'none',
                                          }}
                                          className=""
                                        >
                                          <CardContent>
                                            {/* <img width="38.49" src={first} className="position-absolute" alt="" /> */}
                                            <img
                                              key={index}
                                              src={typeof image === 'string' ? image : image.url}
                                              alt=""
                                              id="imageupload"
                                              className="imageupload form-img__img-preview-4 "
                                              sx={{
                                                display: 'block',
                                                width: '150px',
                                                height: '150px',
                                                objectFit: 'cover',
                                                borderRadius: '20px',
                                              }}
                                            />
                                            {/* <Button onClick={() => handleDelete(index)}>delete</Button> */}
                                            <Avatar
                                              variant="circular"
                                              alt="cross"
                                              src={closeIconp}
                                              onClick={() => handleDelete(index)}
                                              className="imageclose-2"
                                              sx={{
                                                width: '22px',
                                                height: '22px',
                                                padding: '5px',
                                              }}
                                            />
                                          </CardContent>
                                        </Card>
                                      ))}
                                    </Tabs>
                                  </Box>
                                </Box>
                                {/* <h6>Event Images</h6>
<input type="file" ref={hiddenFileInput} style={{ display: 'none' }} multiple onChange={handleImageChange} />
<div>
{images.map((image, index) => (
    <img key={index} src={image.url} alt="" className="form-img__img-preview" />
))}
</div> */}
                              </Grid>
                            ) : null}
                            {/* <div  style={{ fontFamily: 'HK Grotesk', color: "#356F92", fontSize: "18px", fontWeight: 'bold',marginLeft:"16px" }}> <span  style={{marginRight:"10px",fontSize: "22px",fontWeight: 'bold'}}>+</span>Add More </div> */}
                          </Box>
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ ml: -2 }}>
                      {detailPdf?.name ? (
                        <>
                          <Typography className="seva-event-pdf-label">Seva Event PDF</Typography>
                          <PdfCard
                            url={detailPdf?.size ? detailPdf : details?.detailsPdf}
                            name={detailPdf.name}
                            detailPdf={setDetailPdf}
                            editable={true}
                            checkUpdate={setCheckUpdateChanges}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            ref={PdfEventFileInput}
                            style={{ display: 'none' }}
                            type="file"
                            accept=".pdf"
                            onChange={(e) => {
                              onPdfFileUpload(e, setDetailPdf, showNotification)
                            }}
                          />
                          <AddPdfComponent onClick={(e) => handlePdfClick(e)} text="Add a PDF for your event" />
                        </>
                      )}
                    </Grid>
                    <Grid item xs={12} sx={{ pr: 1, pl: 1 }}>
                      <FormControl>
                        <input
                          type="file"
                          ref={hiddenFileInput}
                          className="upload-pdf-button"
                          multiple
                          onChange={handleImageChange}
                          accept={acceptedImageFormat}
                          // {...register("coverImage", {
                          //   required: "Please add cover image",
                          // })}
                        />
                        {/* <FormHelperText sx={{ color: "#d32f2f" }}>
                          {errors && errors?.coverImage?.message}
                        </FormHelperText> */}
                      </FormControl>
                      {/* <Box
                              sx={{
                                display: "flex",
                                "& > :not(style)": {
                                  m: 1,
                                  width: 160,
                                  height: 140,
                                },
                              }}
                            >
                              <Paper
                                variant="outlined"
                                square
                                sx={{
                                  margin: "0 auto",
                                  border: "dotted 3px #1976d2",
                                  padding: "50px",
                                  width: "40%",
                                  borderRadius: "14px",
                                }}
                              >
                                <IconButton
                                  color="primary"
                                  aria-label="Upload"
                                  onClick={handleClick}
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "relative",
                                    top: "25%",
                                    margin: "0 auto",
                                  }}
                                >
                                  <UploadIcon />
                                </IconButton>
                                <b
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    position: "relative",
                                    top: "25%",
                                    whiteSpace: "nowrap",
                                    padding: "10px",
                                  }}
                                >
                                  {" "}
                                  Add More Images
                                </b>
                              </Paper>
                            </Box> */}
                    </Grid>
                  </form>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: 'center',
            marginBottom: '10px',
            alignItems: 'center',
          }}
        >
          <React.Fragment>
            <Box className="d-flex">
              {isSevaEventEdit ? (
                <Button
                  className={`button-tr-2-12-disa ${checkUpdateChanges ? 'button-tr-2' : ''}`}
                  onClick={handleSubmit(onAddSevaEvent)}
                  onMouseUp={() => (setCheckCoverImage(true), setCheckDescValue(false))}
                >
                  Update
                </Button>
              ) : (
                <Button
                  className={`button-tr-2-12-disa ${checkDescError ? 'button-tr-2' : ''}`}
                  onClick={handleSubmit(onAddSevaEvent)}
                  onMouseUp={() => (setCheckCoverImage(true), setCheckDescValue(false))}
                  // disabled={image ? false : true}
                >
                  Save
                </Button>
              )}

              {isSevaEventEdit && (
                <Button
                  className="button-tr-citizen-cancel-upl"
                  // sx={{ p: 1, mr: 1, backgroundColor: "#ef7335" }}
                  // className="button-tr-4 "
                  onClick={async () => (
                    handleClick(), await postAuditLogs('upload_event_images', details?.id, details?.initiativeId)
                  )}
                >
                  Upload Event Images
                </Button>
              )}
              <Box className="delete-event-icon-container">
                {isSevaEventEdit && (
                  <Avatar
                    src={imagedelete}
                    onClick={async () => (
                      await postAuditLogs('delete', details?.id, details?.initiativeId),
                      pushTag({
                        event: 'pop_up',
                        page_title: 'delete_event_pop_up',
                      }),
                      handleClickOpenDeleteDialog()
                    )}
                    className="deleteimgcss1 cursorshow delete-event-icon"
                    alt="delete"
                  />
                )}
              </Box>
            </Box>
          </React.Fragment>
          {openDeleteDialog && (
            <DeleteIndividualEventDialog
              openDeleteDialog={openDeleteDialog}
              handleCloseDeleteDialog={handleCloseDeleteDialog}
              eventId={details?.id}
              mpId={mpProfileData?.id}
              handleCloseEventDetailsDialog={handleCloseEventDetailsDialog}
              editEventDetails={editEventDetails}
              eventByMpId={eventByMpId}
              initiativeId={initiativeId}
              handleCloseInitiativeDetails={handleCloseInitiativeDetails}
              details={details}
              setDeleteClick={setDeleteClick}
              isSevaEventEdit={isSevaEventEdit}
              setEventDeleted={setEventDeleted}
              limit={limit}
            />
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateNewEvent
