import React, { useState, useRef, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import {
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Paper,
  IconButton,
  FormHelperText,
  Typography,
  Avatar,
  CardMedia,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import './AddMediaCoverage.css'
import UploadIcon from '@mui/icons-material/Upload'
import { useDispatch, useSelector } from 'react-redux'
import { getMediaCoverageTypesList } from '../../../store/action/mediaCoverageList'
import { postCreateOpeds } from '../../../store/action/createNewOpeds'
import { useLoading } from '../../../utils/LoadingContext'
import { useNotificationContext } from '../../../utils/NotificationContext'
import {
  getMediaCoverageList,
  getMediaCoverageListByMpID,
  getOpedsAndBooksList,
  getOpedsAndBooksListByMpID,
  getOnlyMediaCoverageList,
  getOnlyMediaCoverageListByMpID,
} from '../../../store/action/mediaCoverageList'
import closeIconp from '../../../asserts/images/close.png'
import Constant, { allowedImageFormat, acceptedImageFormat, defaultErrorMessage } from '../../../utils/constant'
import { getIds } from '../../ReusableComponents.js/getIds'
import DeleteMediaCoverageDialog from './DeleteMediaCoverageDialog'
import {
  validateNotEmpty,
  iconComponent,
  iconComponentDown,
  handleFileName,
  onPdfFileUpload,
} from '../../ReusableComponents.js/reuseMethods'
import CrossIcon from '../../../asserts/images/Close.svg'
import NoImageFound from '../../../asserts/images/noImageFound.jpg'
import { uploadMedia } from '../../../utils/mediaUpload'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { pushTag } from '../../../utils/gtmservice'
import AddPdfComponent from '../../ReusableComponents.js/AddPdfComponent'
import PdfCard from '../../ReusableComponents.js/PdfCard'

const AddMediaCoverage = ({
  handleCloseMediaCoverage,
  openMediaCoverage,
  onViewClickTitle,
  isMediaCoverageEdit,
  details,
  handleCloseInitiativeDetails,
  mpId,
  opedsByMpId,
  setCheckIsUpdate,
  limit = 10,
}) => {
  const [images, setImages] = useState([])
  // const [value, setValue] = useState(0);
  const [files, setFiles] = useState([])
  const dispatch = useDispatch()
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const [selectArrow, setSelectArrow] = useState(true)
  const [checkUpdateChanges, setCheckUpdateChanges] = useState(true)
  const mediaCoverageTypesListData = useSelector((state) => state?.mediaCoverageTypesList?.data)
  const {
    register,
    handleSubmit,
    formState: { errors },
    resetField,
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm()
  const hiddenFileInput = useRef(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteClick, setDeleteClick] = useState(false)
  const [checkImages, setCheckImages] = useState(false)
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const [checkDescError, setCheckDescError] = useState(false)
  const [checkDescValue, setCheckDescValue] = useState(false)
  const [url, setUrl] = useState('')
  const [checkDescChanges, setCheckDescChanges] = useState(false)
  const loggedInId = getIds() // const handleChange = (event, newValue) => {
  //     setValue(newValue);
  // };
  const fileFormats = ['image/png', 'image/jpg']
  const mediaCoverageTypesList =
    onViewClickTitle === 'Miscellaneous of'
      ? mediaCoverageTypesListData.filter((val) => val.type !== 'Media Coverage')
      : mediaCoverageTypesListData.filter((val) => val.type == 'Media Coverage')
  const [type, setType] = React.useState(mediaCoverageTypesList && mediaCoverageTypesList[0]?.type)

  const postAuditLogs = async (sqId, id) => {
    await makeLogRequest('my_books_and_media_coverage_edit_popup', sqId, {
      useridtype: getIds(),
      bm_id: id && id,
    })
  }
  const PdfEventFileInput = useRef(null)

  const [detailPdf, setDetailPdf] = useState(
    (typeof details?.detailsPdf == 'string' && details?.detailsPdf !== '[]') ||
      (typeof details?.detailsPdf == 'object' && details?.detailsPdf?.[0])
      ? { name: handleFileName(details?.detailsPdf) }
      : null
  )

  const handlePdfClick = (event) => {
    PdfEventFileInput.current.value = ''

    PdfEventFileInput.current?.click()
    event?.stopPropagation()
    PdfEventFileInput.current.addEventListener('change', () => {
      if (PdfEventFileInput.current.value === '') {
        setCheckUpdateChanges(true)
      } else {
        setCheckUpdateChanges(false)
      }
    })
  }
  useEffect(() => {
    if (!details?.detailsPdf?.[0]) {
      setDetailPdf([])
    }
    if (isMediaCoverageEdit) {
      postAuditLogs('pageload', details?.id)
      sendGALog('edit')
    }
  }, [])

  const handleTypeChange = (event) => {
    setType(event.target.value)
    setError('type', '')
    setCheckUpdateChanges(false)
  }
  const setMediaTitle = (e) => {
    if (e.target.value !== '') {
      clearErrors('projectTitle')
    }
    let inputValue = e.target.value?.trimStart()
    inputValue = inputValue.replace(/\s{2,}/g, ' ')
    errors.projectTitle = null
    setValue('projectTitle', inputValue)
    setCheckUpdateChanges(false)
  }
  const handlePaste = async (event) => {
    try {
      const pastedText = await navigator.clipboard.readText()

      // Trim and limit the pasted text to 50 characters
      const trimmedText = pastedText.trim().substring(0, 50)

      // Check if the trimmed text is a valid URL
      if (validateURL(trimmedText)) {
        event.preventDefault()
        setUrl(trimmedText)
        setValue('url', trimmedText)
      }
    } catch (error) {
      console.error('Error reading clipboard data:', error)
    }
  }

  const validateURL = (url) => {
    const trimmedURL = url.trim() // Trim the input URL
    if (trimmedURL === '') {
      return true
    }
    try {
      const normalizedURL =
        trimmedURL.startsWith('http://') || trimmedURL.startsWith('https://') ? trimmedURL : 'http://' + trimmedURL
      const parsedURL = new URL(normalizedURL)

      // Custom function to validate the TLD
      const isValidTLD = (tld) => /^[a-z]{1,3}$/.test(tld)

      if (parsedURL.protocol !== 'http:' && parsedURL.protocol !== 'https:') {
        return 'Not a valid URL'
      }

      const domainParts = parsedURL.hostname.split('.')
      const tld = domainParts[domainParts.length - 1].toLowerCase()
      const subdomain = domainParts.length > 2 ? domainParts[0].toLowerCase() : ''

      // Check for at least one subdomain with more than one character before the top-level domain
      if (!isValidTLD(tld) || (subdomain.length > 0 && !/^[a-z\d-]{2,3}$/.test(subdomain))) {
        return 'Not a valid URL'
      }

      // Perform additional validation if needed

      return true // Return true if the URL is valid
    } catch (error) {
      return 'Not a valid URL' // Return an error message if the URL is not valid
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Backspace' && url === '') {
      // Remove the characters one by one when Backspace is pressed and the field is empty
      event.preventDefault()
      const trimmedValue = url.trim()
      const newValue = trimmedValue.slice(0, trimmedValue.length - 1)
      setValue('url', newValue)
      setUrl(newValue)
    } else if (event.key === 'v' && event.ctrlKey && !url) {
      // Allow paste when pressing Ctrl+V and the field is empty
      event.preventDefault()
      handlePaste(event)
    } else if (url !== '' && event.key !== 'Backspace') {
      // Prevent any key press when the field already contains a valid URL
      event.preventDefault()
    }
  }

  const handleInput = (event) => {
    const inputValue = event.target.value
    const trimmedInput = inputValue.trim() // Trim the input value

    if (trimmedInput === '') {
      setUrl('')
      setValue('url', '')
    } else {
      if (trimmedInput.startsWith('http://') || trimmedInput.startsWith('https://')) {
        setUrl(trimmedInput)
        setValue('url', trimmedInput)
      }
    }
  }

  useEffect(() => {
    if (details) {
      setImages(details?.media && JSON.parse(details?.media))
    }
  }, [details])

  useEffect(() => {
    if (deleteClick) {
      handleCloseMediaCoverage(reset, setImages, setFiles)
    }
  }, [deleteClick])

  const onAddMediaCoverage = async (data) => {
    if (images.length === 0) return
    setLoading(true)
    const tkn = localStorage.getItem('tokenDetails')
    const filesList = document.querySelectorAll('.imageupload')
    const existingImageUrls = []
    const mediaImages = await addImagesToFormData(existingImageUrls, filesList)

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tkn}`,
      },
    }

    const imagesObj = await uploadMedia(config, { imageFiles: mediaImages }, true)

    let stepUrl = detailPdf ? detailPdf : {}
    if (detailPdf?.size) {
      stepUrl = await uploadMedia(dispatch, { stepPdf: detailPdf })
    } else if (detailPdf) stepUrl.stepPdfUrl = details?.detailsPdf
    else stepUrl.stepPdfUrl = []

    const payloadObj = {
      title: data?.projectTitle,
      url: data?.url,
      type: onViewClickTitle === 'Miscellaneous of' ? data?.type : 'Media Coverage',
      desc: data?.desc,
      detailsPdf: stepUrl?.stepPdfUrl || [],
      mpmodelId: loggedInId,
      media: [...imagesObj?.imageUrls, ...existingImageUrls],
    }
    const id = details?.id ? details?.id : 0
    const parameters = {
      useridtype: getIds(),
      bm_id: id,
    }
    sendCreateMedaiCoverageLogs('submit', parameters)
    sendGALog(isMediaCoverageEdit ? 'edit' : 'proceed')
    try {
      const response = await dispatch(postCreateOpeds(id, payloadObj, config))
      if (response?.status === 200 || response?.status === 201) {
        showNotification('Success', response.data.message, 'success')
        handleCloseMediaCoverage(reset, setImages, setFiles)
        if (details) {
          handleCloseInitiativeDetails({ updated: true })
        }
        const payload = {
          id: 0,
          limit,
        }
        if (onViewClickTitle === 'Miscellaneous of') {
          if (opedsByMpId) {
            payload.id = mpId
            dispatch(getOpedsAndBooksListByMpID(payload))
          } else {
            dispatch(getOpedsAndBooksList(payload))
          }
        } else {
          if (opedsByMpId) {
            payload.id = mpId
            dispatch(getOnlyMediaCoverageListByMpID(payload))
          } else {
            dispatch(getOnlyMediaCoverageList(payload))
          }
        }
        // Object.keys(data).map(val => resetField(val));
        // setImages([]);
        // setFiles([]);
      }
    } catch (error) {
      showNotification('Error', error)
    } finally {
      setLoading(false)
      if (typeof setCheckIsUpdate === 'function') {
        setCheckIsUpdate(true)
      }
    }
  }

  const addImagesToFormData = async (existingImageUrls, files) => {
    const filesArr = []
    for (let i = 0; i < files.length; i++) {
      try {
        if (files[i].src.toString().toLowerCase().startsWith('https')) {
          existingImageUrls.push({ media: files[i].src })
        } else {
          const response = await fetch(files[i].src)
          const blob = await response.blob()
          const file = new File([blob], `image${i}.jpg`, { type: 'image/*' })
          filesArr.push(file)
        }
      } catch (error) {
        existingImageUrls.push({ media: files[i]?.src })
      }
    }
    return filesArr
  }

  useEffect(() => {
    //call api to update store
    dispatch(getMediaCoverageTypesList())
  }, [])

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false)

  useEffect(() => {
    setType(mediaCoverageTypesList && mediaCoverageTypesList[0]?.type)
  }, [mediaCoverageTypesList])

  const handleDelete = (index) => {
    hiddenFileInput.current.value = ''
    const tempImages = [...images]
    tempImages.splice(index, 1)
    setImages(tempImages)
    const tempFiles = [...files]
    tempFiles.splice(index, 1)
    setFiles(tempFiles)
    setCheckUpdateChanges(false)
  }

  useEffect(() => {
    const parameters = {
      useridtype: getIds(),
    }
    sendCreateMedaiCoverageLogs('pageload', parameters)
    sendGALog('pop_up')
  }, [])

  const sendGALog = (event) => {
    const page_title =
      onViewClickTitle === 'Miscellaneous of' ? 'create_miscellaneous_popup' : 'create_books_media_popup'
    const payload = {
      event,
      page_title,
    }
    pushTag(payload)
  }

  const sendCloseDialogLog = () => {
    const parameters = {
      useridtype: getIds(),
    }
    sendCreateMedaiCoverageLogs('cancel', parameters)
    sendGALog('back')
  }

  const sendCreateMedaiCoverageLogs = async (type, parameters) => {
    await makeLogRequest('create_books_and_media_coverage_popup', type, parameters)
  }

  const handleImageChange = (e) => {
    const parameters = {
      useridtype: getIds(),
    }
    sendCreateMedaiCoverageLogs('add_more_images', parameters)
    const uploadedFiles = e.target.files

    let newFiles = []
    let processedImages = 0

    for (let i = 0; i < uploadedFiles.length; i++) {
      const isDuplicate = files.some((file) => file.name === uploadedFiles[i].name)
      if (isDuplicate) {
        showNotification('Error', 'This image is already uploaded.', 'error')
        e.target.value = null
        return
      }

      const isRightFormat = allowedImageFormat.includes(uploadedFiles[i].type)
      const fileSizeInBytes = uploadedFiles[i].size
      const maxSizeInBytes = 20 * 1024 * 1024 // 20MB
      if (!isRightFormat) {
        showNotification('Error', 'Please upload image in JPG or PNG format only', 'error')
        e.target.value = null
        return
      }
      if (fileSizeInBytes > maxSizeInBytes) {
        showNotification('Error', 'Please upload image with size less than 20MB', 'error')
        e.target.value = null
        return
      }
      setCheckUpdateChanges(false)
      setFiles([...files, uploadedFiles[i]])
      const reader = new FileReader()
      reader.readAsDataURL(uploadedFiles[i])
      reader.onload = () => {
        if (uploadedFiles[i].type.startsWith('image')) {
          newFiles.push({
            type: 'image',
            url: reader.result,
            file: uploadedFiles[i],
          })
        } else if (uploadedFiles[i].type.startsWith('video')) {
          newFiles.push({
            type: 'video',
            url: reader.result,
            file: uploadedFiles[i],
          })
        }
        processedImages++

        if (processedImages === uploadedFiles.length) {
          setImages([...images, ...newFiles])
        }
      }
    }
  }

  // const handleImageChange = (e) => {
  //     const uploadedFiles = e.target.files;
  //     setFiles([...files, ...uploadedFiles]);
  //     let newImages = [];
  //     for (let i = 0; i < uploadedFiles.length; i++) {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(uploadedFiles[i]);
  //         reader.onload = () => {
  //             newImages.push({ url: reader.result, file: uploadedFiles[i] });
  //             if (i === uploadedFiles.length - 1) {
  //                 setImages([...images, ...newImages]);
  //             }
  //         };
  //     }
  // };

  const handlePreview = (file) => {
    // if (file.type === "image" || details) {
    //     return <img src={typeof file === 'string' ? file : file.url} alt="Preview" className="imageupload form-img__img-preview-ed1" id="imageupload" />;
    // }
    // if (file.type === "video" || details) {
    //     return (
    //         <video controls className="imageupload form-img__img-preview-ed1" id="imageupload">
    //             <source src={typeof file === 'string' ? file : file.url} />
    //             Your browser does not support the video tag.
    //         </video>
    //     );
    // }
    if (file.type === 'image') {
      return (
        <img
          src={typeof file === 'string' ? file : file.url}
          alt="Preview"
          className="imageupload form-img__img-preview-ed1"
          id="imageupload"
          onESrror={(e) => (e.target.src = NoImageFound)}
          sx={{
            width: '150px',
            height: '150px',
            borderRadius: '20px',
          }}
        />
      )
    }
    if (file.type === 'video') {
      return (
        <video controls className="imageupload form-img__img-preview-ed1" id="imageupload">
          <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }
    const images = ['jpg', 'gif', 'png', 'svg', 'jfif']
    const videos = ['mp4', '3gp', 'ogg']
    const url = new URL(file)
    const extension = url.search.split('.')[1]
    const pathExtension = url?.pathname?.slice(url.pathname.lastIndexOf('.') + 1, url.pathname.length) || ''
    if (images.includes(extension) || images.includes(pathExtension)) {
      return <img src={file} alt="Preview" className="imageupload form-img__img-preview-ed1" id="imageupload" />
    }
    if (videos.includes(extension) || videos.includes(pathExtension)) {
      return (
        <Box>
          {/* <img src={playicon} onClick={handleHideImage} /> */}
          <video controls className="imageupload form-img__img-preview-ed1" id="imageupload">
            <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      )
    }
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const handleCancelClick = () => {
    handleCloseMediaCoverage(reset, setImages, setFiles)
    sendGALog('back')
  }

  return (
    <>
      <Dialog
        open={openMediaCoverage}
        onClose={() => (
          handleCloseMediaCoverage(reset, setImages, setFiles), isMediaCoverageEdit && postAuditLogs('close')
        )}
        sx={{ height: '96vh', mt: 2.5, overflowX: 'hidden' }}
      >
        <DialogTitle>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                color: '#357092',
                fontFamily: 'HK Grotesk',
                fontSize: '26px',
                fontWeight: 'bold',
              }}
            >
              {onViewClickTitle === 'Miscellaneous of' ? 'Miscellaneous' : 'Media Coverage'}
            </Typography>
            <Avatar
              onClick={() => {
                sendCloseDialogLog()
                handleCloseMediaCoverage(reset, setImages, setFiles)
                isMediaCoverageEdit && postAuditLogs('close')
              }}
              src={CrossIcon}
              className="closeIcon"
              sx={{
                height: '30px',
                width: '30px',
                position: 'absolute',
              }}
            />
          </Box>
        </DialogTitle>
        <Grid container className="bg-white" sx={{ overflowX: 'hidden' }}>
          <Grid item xs={12} md={12}>
            <Grid item xs={12} md={12} sx={{ p: 4 }}>
              <Box>
                <form>
                  <Grid container spacing={1} justifyContent="left" alignItems="center">
                    <Grid container sx={{ mb: 2 }}>
                      <Grid item xs={12} sx={{ pr: 1 }}>
                        <FormControl
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderRadius: '14px',
                            },
                          }}
                        >
                          <Typography className="add-media-coverage-input-label">Title*</Typography>
                          <TextField
                            className="stepperFormInput"
                            // label="Title"
                            name="title"
                            fullWidth
                            placeholder="Enter title"
                            size="small"
                            required
                            defaultValue={details && details?.title}
                            autoComplete="off"
                            {...register('projectTitle', {
                              required: 'Please enter title',
                              maxLength: {
                                value: 300,
                                message: 'Max length 300',
                              },
                              minLength: {
                                value: 10,
                                message: 'Min length 10',
                              },
                              validate: (value) => validateNotEmpty(value, 'title'),
                            })}
                            //   error={Boolean(errors?.employeeId?.message)}
                            //   helperText={errors?.employeeId?.message}
                            onChange={setMediaTitle}
                          />
                        </FormControl>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {errors && errors?.projectTitle?.message}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    {onViewClickTitle === 'Miscellaneous of' ? (
                      <Grid container sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                          <Typography className="add-media-coverage-input-label">Type*</Typography>

                          <FormControl
                            fullWidth
                            sx={{
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '14px',
                              },
                            }}
                          >
                            <InputLabel id="demo-simple-select-label" sx={{ marginTop: '-6px' }}></InputLabel>
                            <Select
                              className="stepperFormInput w-50"
                              // label="Type"
                              name="type"
                              IconComponent={selectArrow ? iconComponent : iconComponentDown}
                              onOpen={() => setSelectArrow(false)}
                              onClose={() => setSelectArrow(true)}
                              defaultValue={details?.type}
                              required
                              size="small"
                              autoComplete="off"
                              {...register('type', {
                                required: 'Please select type',
                              })}
                              SelectDisplayProps={{
                                renderValue: (defaultValue) => defaultValue,
                              }}
                              onChange={handleTypeChange}
                              // value={type}
                              // error={errors?.roleId}
                              // helperText={errors?.roleId?.message}
                              // onChange={() => setIsUpdateButton(false)}
                            >
                              {mediaCoverageTypesList &&
                                mediaCoverageTypesList.map((s) => {
                                  return (
                                    <MenuItem native key={s.type} sx={{ width: '100%' }} value={s.type} size="small">
                                      {s.type}
                                    </MenuItem>
                                  )
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f', ml: '10px', mr: 0 }}>
                              {errors && errors?.type?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container sx={{ mb: 2 }}>
                        <Grid item xs={12}>
                          <Typography className="add-media-coverage-input-label">Type*</Typography>
                          <Box className="Media-coverage-button">
                            <Typography className="media-coverage-text">Media Coverage</Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    )}

                    <Grid container>
                      <Typography className="add-media-coverage-input-label mb-0">Attach Media*</Typography>
                      <Box className="contpopup1 media-box">
                        {/* {images?.length > 0 ? ( */}
                        <>
                          <Box className="postionabs-2 itemfixed-update media-container">
                            {images?.length > 1 ? (
                              <Tabs
                                // value={value}
                                // onChange={handleChange}
                                variant="scrollable"
                                scrollButtons
                                aria-label="visible arrows tabs example"
                                sx={{
                                  [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                  },
                                }}
                              >
                                {images?.map((file, index) => (
                                  <Card
                                    sx={{
                                      minWidth: 200,
                                      borderRadius: 0,
                                      boxShadow: 'none',
                                    }}
                                    className="form-img__img-preview-ed1 "
                                  >
                                    <CardContent>
                                      {/* <img width="38.49" src={first} className="position-absolute" alt="" /> */}
                                      <Box key={index}>{handlePreview(file)}</Box>
                                      {/* <img key={index} src={typeof image === 'string' ? image : image.url} alt="" className="form-img__img-preview" /> */}
                                      <Avatar
                                        src={closeIconp}
                                        onClick={() => handleDelete(index)}
                                        className="imageclose-2"
                                        sx={{
                                          width: '22px',
                                          height: '22px',
                                          padding: '5px',
                                          position: 'absolute',
                                        }}
                                      />
                                      {/* <Button onClick={() => handleDelete(index)}>delete</Button> */}
                                    </CardContent>
                                  </Card>
                                ))}
                              </Tabs>
                            ) : (
                              images?.map((file, index) => (
                                <Card
                                  sx={{
                                    minWidth: 200,
                                    borderRadius: 0,
                                    boxShadow: 'none',
                                  }}
                                  className="form-img__img-preview-ed1"
                                >
                                  <CardContent>
                                    {/* <img width="38.49" src={first} className="position-absolute" alt="" /> */}
                                    <Box key={index}>{handlePreview(file)}</Box>
                                    {/* <img key={index} src={typeof image === 'string' ? image : image.url} alt="" className="form-img__img-preview" /> */}
                                    {/* <Button onClick={() => handleDelete(index)}>delete</Button> */}
                                    <Avatar
                                      src={closeIconp}
                                      onClick={() => handleDelete(index)}
                                      className="imageclose-2"
                                      sx={{
                                        width: '22px',
                                        height: '22px',
                                        padding: '5px',
                                        position: 'absolute',
                                      }}
                                    />
                                  </CardContent>
                                </Card>
                              ))
                            )}
                          </Box>
                          <Grid item xs={6}>
                            <input
                              type="file"
                              ref={hiddenFileInput}
                              className="d-none"
                              multiple
                              onChange={handleImageChange}
                              accept={acceptedImageFormat}
                            />
                            {/* <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} multiple onChange={handleImageChange} /> */}
                            <Box
                              sx={{
                                // ml: "-95px",
                                display: 'flex',

                                // position: 'relative',
                                '& > :not(style)': {
                                  width: 150,
                                  height: 140,
                                  // position: "relative",
                                  //  left: "-31%",
                                  // top: "8%",
                                  // marginTop: "32px",
                                },
                              }}
                            >
                              <Paper
                                variant="outlined"
                                square
                                sx={{
                                  border: 'dotted 3px #1976d2',
                                  padding: '50px',
                                  width: '40%',
                                  borderRadius: '14px',
                                }}
                              >
                                <IconButton
                                  color="primary"
                                  aria-label="Upload"
                                  onClick={() => (
                                    handleClick(), isMediaCoverageEdit && postAuditLogs('add_more_images')
                                  )}
                                  sx={{
                                    display: 'flex',
                                    marginTop: '-10px',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <UploadIcon />
                                </IconButton>

                                <Typography
                                  className="d-flex justify-content-center position-relative text-nowrap"
                                  sx={{
                                    fontFamily: 'HK Grotesk',
                                    fontWeight: 700,
                                  }}
                                >
                                  {' '}
                                  {images?.length > 0 ? 'Add More Images' : 'Add Images'}
                                </Typography>
                                <FormHelperText
                                  sx={{
                                    color: '#d32f2f',
                                    width: 'max-content',
                                    marginTop: '38px',
                                    marginLeft: '-45px !important',
                                  }}
                                >
                                  {checkImages && images.length === 0 && 'Please upload images'}
                                </FormHelperText>
                              </Paper>
                            </Box>
                          </Grid>
                        </>
                        {/* ) : null} */}
                        {/*  */}
                      </Box>
                    </Grid>

                    <Grid container sx={{ mb: 2, mt: 1 }}>
                      <Grid item xs={12} sx={{ pr: 1 }}>
                        <FormControl
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderRadius: '14px',
                            },
                          }}
                        >
                          <Typography className="add-media-coverage-input-label desc-box">Description*</Typography>
                          <TextField
                            className="stepperFormInput"
                            // label="Description"
                            name="desc"
                            placeholder="Enter Description"
                            fullWidth
                            required
                            multiline
                            rows={4}
                            defaultValue={details && details.desc}
                            size="small"
                            autoComplete="off"
                            {...register('desc', {
                              required: 'Please enter description',
                              maxLength: {
                                value: 5000,
                                message: 'Maximum 5000 characters allowed',
                              },
                              minLength: {
                                value: 10,
                                message: 'Min length 10',
                              },
                              validate: (value) => validateNotEmpty(value, 'description'),
                            })}
                            onChange={(e) => {
                              let inputValue = e.target.value?.trimStart()
                              inputValue = inputValue.replace(/\s{2,}/g, ' ')
                              setValue('desc', inputValue)
                              setCheckUpdateChanges(false)
                              if (e.target.value !== '') {
                                setCheckDescValue(true)
                              } else {
                                setCheckDescValue(false)
                              }
                              if (e.target.value.replaceAll('\n', '').length > 5000) {
                                setCheckDescValue(false)
                                setCheckDescError(true)
                                setCheckDescChanges(true)
                              } else {
                                setCheckDescError(false)
                                setCheckDescChanges(false)
                              }
                              setCheckUpdateChanges(false)
                            }}
                            onPaste={(e) => {
                              const pastedText = e.clipboardData.getData('text')
                              if (
                                pastedText.replace(/[\r\n]/gm, '').length +
                                  e.target.value.replace(/[\r\n]/gm, '').length >
                                5000
                              ) {
                                e.preventDefault()
                                const truncatedText = pastedText.substring(0, 5000 - e.target.value.length)
                                document.execCommand('insertText', false, truncatedText)
                                setCheckDescError(true)
                              }
                            }}
                            inputProps={{ wrap: 'soft' }}
                          />
                        </FormControl>
                        <FormHelperText
                          sx={{
                            color: checkDescValue ? 'blue !important' : '#d32f2f !important',
                          }}
                        >
                          {checkDescError || checkDescValue ? 'Maximum 5000 characters allowed' : errors?.desc?.message}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sx={{ pr: 1 }}>
                        <FormControl
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderRadius: '14px',
                            },
                          }}
                        >
                          <Typography className="add-media-coverage-input-label link-box">Link</Typography>

                          <TextField
                            className="stepperFormInput"
                            // label="Link"
                            name="url"
                            // placeholder="Link"
                            fullWidth
                            required
                            defaultValue={details && details.url}
                            size="small"
                            autoComplete="off"
                            inputProps={{ onPaste: handlePaste }}
                            onInput={handleInput}
                            {...register('url', {
                              //   required: "Please enter url",
                              maxLength: {
                                value: 50,
                                message: 'Max length 50',
                              },
                              //   validate: (value) =>
                              //     validateNotEmpty(value, "url"),
                              validate: (value) => validateURL(value),
                              // , validate: {
                              //     pattern: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g,
                              //     // message: "Invalid "
                              // }
                            })}
                            onChange={() => {
                              setCheckUpdateChanges(false)
                            }}
                          />
                        </FormControl>
                        <FormHelperText sx={{ color: '#d32f2f' }}>{errors && errors?.url?.message}</FormHelperText>
                      </Grid>
                      <Grid item xs={12}>
                        {detailPdf?.name ? (
                          <>
                            <Typography
                              className="pdfUploadClass"
                              sx={{
                                fontWeight: 'bold',
                                marginTop: '20px',
                                fontFamily: 'HK Grotesk',
                              }}
                            >
                              {onViewClickTitle === 'Miscellaneous of' ? 'Miscellaneous PDF :' : 'Media Coverage PDF :'}
                            </Typography>
                            <PdfCard
                              url={detailPdf?.size ? detailPdf : details?.detailsPdf}
                              name={detailPdf.name}
                              detailPdf={setDetailPdf}
                              editable={true}
                              checkUpdate={setCheckUpdateChanges}
                            />
                          </>
                        ) : (
                          <>
                            <input
                              ref={PdfEventFileInput}
                              className="d-none"
                              type="file"
                              accept=".pdf"
                              onChange={(e) => {
                                onPdfFileUpload(e, setDetailPdf, showNotification)
                              }}
                            />
                            <AddPdfComponent
                              onClick={(e) => handlePdfClick(e)}
                              left="274px"
                              text={
                                onViewClickTitle === 'Miscellaneous of'
                                  ? 'Add a PDF as your Miscellaneous'
                                  : 'Add a PDF as your Media Coverage'
                              }
                            />
                          </>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 2, pl: 30 }}>
                {isMediaCoverageEdit ? (
                  <Button
                    variant="contained"
                    sx={{ p: 1, mr: 1, backgroundColor: '#ef7335' }}
                    // className="button-tr-2"
                    className={`button-tr-2-13-disa ${checkDescError || checkDescChanges ? 'button-tr-2-1' : ''}`}
                    onClick={handleSubmit(onAddMediaCoverage)}
                    onMouseUp={() => (
                      setCheckImages(true), setCheckDescValue(false), isMediaCoverageEdit && postAuditLogs('update')
                    )}
                    disabled={checkUpdateChanges}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ p: 1, mr: 1, backgroundColor: '#ef7335' }}
                    // className="button-tr-2"
                    className={`button-tr-2-13-disa ${checkDescError || checkDescChanges ? 'button-tr-2-1' : ''}`}
                    onClick={handleSubmit(onAddMediaCoverage)}
                    onMouseUp={() => (
                      setCheckImages(true), setCheckDescValue(false), isMediaCoverageEdit && postAuditLogs('update')
                    )}
                    disabled={(checkDescChanges || checkDescError) && true}
                  >
                    Submit
                  </Button>
                )}

                {isMediaCoverageEdit && (
                  <Button
                    variant="outlined"
                    onClick={() => (handleClickOpenDeleteDialog(), postAuditLogs('delete'))}
                    sx={{ p: 1, mr: 1 }}
                    className="button-tr-2-1 "
                  >
                    Delete
                  </Button>
                )}
                {!isMediaCoverageEdit && (
                  <Button
                    variant="outlined"
                    onClick={handleCancelClick}
                    sx={{ p: 1, mr: 1 }}
                    className="button-tr-2-1 "
                  >
                    Cancel
                  </Button>
                )}
                {/* <div style={{ marginTop: "12px" }}>
                                    {isMediaCoverageEdit && (
                                        <img
                                            src={imagedelete}
                                            onClick={handleClickOpenDeleteDialog}
                                            className="deleteimgcss1 cursorshow"
                                            style={{ marginTop: "0px", marginLeft: "15px" }}
                                            alt="delete"
                                        />
                                    )}
                                </div> */}
                <Box sx={{ flex: '1 1 auto' }} />
              </Box>
            </React.Fragment>
            {openDeleteDialog && (
              <DeleteMediaCoverageDialog
                openDeleteDialog={openDeleteDialog}
                handleCloseDeleteDialog={handleCloseDeleteDialog}
                opedId={details?.id}
                mpId={mpProfileData?.id || mpId}
                handleCloseMediaCoverage={handleCloseMediaCoverage}
                opedsByMpId={opedsByMpId}
                handleCloseInitiativeDetails={handleCloseInitiativeDetails}
                details={details}
                setDeleteClick={setDeleteClick}
                isMediaCoverageEdit={isMediaCoverageEdit}
                isMiscellaneous={onViewClickTitle === 'Miscellaneous of'}
                limit={limit}
              />
            )}
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default AddMediaCoverage
