import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

const AccordionComponentEditButton = ({ onClick }) => {
  return (
    <Button
      variant="outlined"
      className="button-tr-citizen-admin"
      startIcon={<AddIcon sx={{ mb: '1px' }} />}
      sx={{
        mt: 1,
        ml: 1,
        mb: 1,
        marginRight: '15px',
        fontFamily: 'HK Grotesk',
      }}
      onClick={onClick}
    >
      Edit
    </Button>
  )
}

export default AccordionComponentEditButton
