import React, { useState, useEffect, useMemo } from 'react'
import {
  Grid,
  Breadcrumbs,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Box,
  TextField,
  InputAdornment,
  Paper,
} from '@mui/material'
import SideMenu from '../SideMenu/SideMenu'
import { Link } from 'react-router-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import SevaUpdates from './SevaUpdates'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useDispatch, useSelector } from 'react-redux'
import { getEventsList } from '../../store/action/eventsList'
import NoImageFound from '../../asserts/images/noImageFound.jpg'
import Moment from 'moment'
import InitiativeDetails from './AllInitiativeReports/initiativeDetails'
import ShareIcon from '@mui/icons-material/Share'
import CrossIcon from '../../asserts/images/Close.svg'
import Share from '../ReusableComponents.js/Share'
import { getMpProfile } from '../../store/action/individualMP'
import { getIds } from '../ReusableComponents.js/getIds'
import {
  getIntiativesReportByInitiativeIdAndMpId,
  getOngoingSevaIntiativesList,
  getOngoingSevaIntiativesListByMpId,
} from '../../store/action/ongoingSevaInitiativesList'
import { useNotificationContext } from '../../utils/NotificationContext'
import UpdateInitiativeReportDetails from './AllInitiativeReports/UpdateInitiativeReportDialog'
import { defaultErrorMessage } from '../../utils/constant'
import './viewAllScreen.css'
import './ViewAllOngoingInitiatives.css'
import searchIcon from '../../asserts/images/Search.svg'
import NotFoundImg from '../../asserts/images/searchNotFound.png'
import { getSearchSevaInitiativesList } from '../../store/action/searchSevaInitiatives'

const ViewAllOngoingInitiatives = ({ user, newUser }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const viewAllValue = location?.state?.viewAllValue
  // const cardsData = location?.state?.data;
  const isEdit = location?.state?.isEdit
  const mySeva = location?.state?.mySeva
  const MpClick = location?.state?.MpClick
  const title = location?.state?.title
  const mpName = location?.state?.mpName
  const mpId = location?.state?.mpId
  const [openInitiativeDetailsDialog, setOpenInitiativeDetailsDialog] = useState(false)
  const [totalCardLength, setTotalCardLength] = useState(0)
  const [personDetails, setPersonDetails] = useState()
  const [onViewClick, setOnViewClick] = useState()
  const [mediaShare, setMediaShare] = useState(false)
  const [shareData, setShareData] = useState({})
  const [searchText, setSearchText] = useState('')
  const [noRecordFound, setNoRecordFound] = useState(true)

  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const initiativeReportDetailsByMPIdAndIntiativeId = useSelector(
    (state) => state?.initiativeReportDetailsByMPIdAndIntiativeId?.data
  )
  const { showNotification } = useNotificationContext()

  const [openUpdateIntiativeDialog, setOpenUpdateInitiativeDialog] = useState(false)
  const [initiativeName, setInitiativeName] = useState()
  const [ongoingDetails, setOngoingDetails] = useState()
  const [cardsData, setCardsData] = useState([])
  const [lastPaginatedResult, setLastPaginatedResult] = useState([])

  const allInitiatives = useSelector((state) => state?.ongoingSevaInitiativesList?.data)
  const initiativesForMpList = useSelector((state) => state.ongoingSevaInitiativesListByMpId?.data)
  const allInitiative = allInitiatives?.initiative
  const initiativesForMp = initiativesForMpList?.initiative
  const allInitiativeLength = allInitiatives?.totalCount
  const initiativesForMpLength = initiativesForMpList?.totalCount

  const searchResults = useSelector((state) => state.searchSevaInitiatives?.data)

  useEffect(() => {
    if (searchText?.length) {
      setCardsData(searchResults?.data)
      if (searchResults?.data?.length > 0) {
        setTotalCardLength(searchResults?.totalCount)
        setNoRecordFound(true)
      } else if (searchResults?.data?.length === 0) {
        setTotalCardLength(0)
        setNoRecordFound(false)
      }
    }
  }, [searchResults])

  const getInitiatives = (payload) => {
    if (mySeva) {
      payload.id = mpId
      dispatch(getOngoingSevaIntiativesListByMpId(payload))
    } else {
      dispatch(getOngoingSevaIntiativesList(payload))
    }
  }

  useEffect(() => {
    //call api to update store
    dispatch(getMpProfile(getIds()))
  }, [])

  useEffect(() => {
    const payload = {
      id: 0,
      limit: 60,
    }
    getInitiatives(payload)
  }, [mySeva])

  useEffect(() => {
    let newList = []
    if (mySeva) {
      newList = initiativesForMp
    } else {
      newList = allInitiative
    }
    setLastPaginatedResult(newList)
    // Todo handle - if results are less than equal to 10 - need to reset redux state
    if (newList?.length && JSON.stringify(newList) !== JSON.stringify(location?.state?.data)) {
      const newLastId = newList[newList?.length - 1]['id'] || 0
      const lastId = cardsData?.length ? cardsData[cardsData.length - 1]['id'] || 0 : Number.MAX_SAFE_INTEGER
      if (lastId !== newLastId) {
        if (cardsData?.length === 10) {
          setCardsData(newList)
        } else {
          const currentData = [...cardsData, ...newList]
          setCardsData(currentData)
        }
      }
    } else if (JSON.stringify(newList) === JSON.stringify(location?.state?.data)) {
      setCardsData(newList)
    }
  }, [allInitiative, initiativesForMp, mySeva])

  useEffect(() => {
    if (mySeva) {
      setTotalCardLength(initiativesForMpLength)
    } else {
      setTotalCardLength(allInitiativeLength)
    }
  }, [allInitiativeLength, initiativesForMpLength])

  const onFolderClick = () => {
    navigate('/SevaUpdates', {
      state: {
        user: newUser,
      },
    })
  }
  const handleCloseInitiativeDetails = () => {
    setOpenInitiativeDetailsDialog(false)
  }

  const handleOpenInitiativeDetails = async (item, title, isOngoingSevaClick) => {
    if (isOngoingSevaClick) {
      try {
        const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(item?.id, mpId))
        if (response.status === 200 || response.status === 201) {
          if (response.data?.message) {
            showNotification('Error', response.data?.message, 'error')
          } else if (response?.data?.reportdata.length === 0) {
            handleCardClick(item)
          } else {
            setPersonDetails(item)
            setOnViewClick(title)
            setOpenInitiativeDetailsDialog(true)
          }
        }
      } catch (error) {
        showNotification('Error', defaultErrorMessage)
      }
    }
  }

  const handleOpenInitiativeReport = async (dataVal) => {
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(dataVal?.id, mpId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          showNotification('Error', response.data?.message, 'error')
        } else if (response?.data?.reportdata.length === 0) {
          handleCardClick(dataVal)
        } else {
          setInitiativeName(dataVal?.initiativeName)
          setOpenUpdateInitiativeDialog(true)
          setOngoingDetails(dataVal)
        }
      }
    } catch (error) {
      showNotification('Error', error, 'error')
    }
  }

  const handleCloseUpdateInitiativeDetails = (reset, reportFiles, setUploadFiles) => {
    reset()
    reportFiles([])
    setUploadFiles([])
    setOpenUpdateInitiativeDialog(false)
  }

  const handleCardClick = (data) => {
    navigate('/SevaUpdates/allInitiativeReports', {
      state: {
        user: user,
        // initiativeDetails: initiativeDetails,
        initiativeId: data?.id,
        initiativeName: data?.initiativeName,
        mpName: mpName,
        mpId: mpId,
        mySeva: mySeva,
      },
    })
  }

  const handleShare = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    setShareData(data)
    setMediaShare(true)
  }

  const getMoreResults = () => {
    const currentData = cardsData
    if (lastPaginatedResult?.length || !cardsData?.length) {
      const lastObj = currentData[currentData?.length - 1]
      const payload = {
        id: 0,
        prevId: lastObj.id,
        limit: 60,
      }
      getInitiatives(payload)
    }
  }

  const setDefaultCardData = () => {
    const payload = {
      id: 0,
      limit: 60,
    }
    getInitiatives(payload)
    setTotalCardLength(mySeva ? initiativesForMpLength : allInitiativeLength)
  }

  const handleSearch = (e) => {
    const searchText = e.target.value
    const regex = /[$&+,:;=?[\]@#|{}'<>.^*()%!-/]/
    if (regex.test(searchText)) {
      return
    }
    setSearchText(searchText)
    if (searchText?.length === 0) {
      setCardsData([])
      return setDefaultCardData()
    }
    const params = {
      searchText,
      offset: 0,
      limit: 50,
    }
    if (mySeva) {
      params.mpId = mpId
    }
    return dispatch(getSearchSevaInitiativesList(params))
  }

  const handleSearchCrossClick = () => {
    if (searchText?.length === 0) {
      return
    }
    setSearchText('')
    setDefaultCardData()
  }

  const noRecordStyles = useMemo(
    () => ({
      mt: 4,
      ml: 1,
      width: '75%',
      height: '60vh',
      borderRadius: 6,

      minWidth: '405%',
    }),
    []
  )

  return (
    <Box className="page-wrapper d-flex">
      {/* component 1 */}

      {newUser ? (
        <SideMenu active="Seva" user={newUser} profileData={mpProfileData} />
      ) : (
        <SideMenu active="Seva" user={user} profileData={mpProfileData} />
      )}

      <Box className="main-wrapper customscroll mainDiv">
        <Grid container sx={{ flexDirection: 'column' }}>
          <Box className="row viewAllHeader">
            <Typography component="h1" className="title-heading page-title mb-0">
              {viewAllValue} {totalCardLength >= 0 ? '(' + totalCardLength?.toLocaleString('en-IN') + ')' : ''}
            </Typography>
            <Box>
              <TextField
                className="search-filter-icon cursorshow"
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                value={searchText}
                inputProps={{ maxLength: 200, autoComplete: 'off' }}
                onChange={handleSearch}
                placeholder={`Search Seva Initiatives`}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <img
                        className="searchIcon cursorshow"
                        alt="Search"
                        width={20}
                        height={21}
                        src={searchText?.length ? CrossIcon : searchIcon}
                        onClick={handleSearchCrossClick}
                      />
                    </InputAdornment>
                  ),
                }}
              >
                {' '}
              </TextField>
            </Box>
          </Box>
          <Grid container sx={{ mb: 2, mt: 2 }}>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" sx={{ marginLeft: '-15px' }} />}
            >
              <Button underline="hover" onClick={onFolderClick}>
                <Typography className="breadcrumb-btn1">Nationwide Seva Updates</Typography>
              </Button>
              <Button underline="hover">
                <Typography className="breadcrumb-btn2">{viewAllValue}</Typography>
              </Button>
            </Breadcrumbs>
          </Grid>

          <Box className="customscroll scrollDiv">
            <Box className="itemfixed4">
              {/* <Grid item xs={8}> */}
              {cardsData?.length > 0
                ? cardsData?.map((item, index) => (
                    <Card
                      sx={{
                        minWidth: 200,
                        mr: 3,
                        mb: 3,
                        borderRadius: '14px',
                        position: 'relative',
                        cursor: 'pointer',
                        height: '200px',
                      }}
                      onClick={() =>
                        mpName
                          ? mySeva
                            ? handleOpenInitiativeReport(item)
                            : handleOpenInitiativeDetails(item, title, true)
                          : handleCardClick(item)
                      }
                    >
                      <CardMedia
                        component="img"
                        height="150"
                        width="190"
                        className="cardmedia-dimension image2acrd"
                        onError={(e) => (e.target.src = NoImageFound)}
                        src={JSON.parse(item?.coverimage)[0]}
                        // image={(item?.media && JSON.parse(item?.media)[0])}
                        // image={NoImageFound}
                        alt="new Image"
                      />

                      <CardContent>
                        <Typography
                          sx={{
                            fontSize: '12px',
                            fontWeight: '500',
                            fontFamily: 'HK Grotesk',
                            color: '#505050',
                          }}
                          className="ellipsewehe12-qw "
                        >
                          {item?.initiativeName}
                        </Typography>
                      </CardContent>
                      {/* <ShareIcon className='shareicon-fix' key={index} onClick={(e) => {
                                    handleShare(e, item)
                                }} /> */}
                    </Card>
                  ))
                : !noRecordFound && (
                    <Box>
                      <Paper sx={noRecordStyles}>
                        <img className="imagecenter image-center-width-height" src={NotFoundImg} alt="" />
                        {
                          <Box className="viewAll-NoRecord">
                            <Typography variant="span" className="viewAll-span">
                              No Results found :(
                            </Typography>
                          </Box>
                        }
                      </Paper>
                    </Box>
                  )}
            </Box>
            {(searchText?.length > 0 && totalCardLength > cardsData?.length) ||
            (!searchText?.length && lastPaginatedResult?.length && !(lastPaginatedResult?.length < 60)) ? (
              <Box className="centerDiv">
                <Button variant="outlined" className="loadMoreButton" onClick={getMoreResults}>
                  Load More
                </Button>
              </Box>
            ) : null}
          </Box>
          <Box className="d-none"></Box>
          <Dialog open={mediaShare} onClose={() => setMediaShare(false)}>
            <DialogTitle>
              <img alt="close" onClick={() => setMediaShare(false)} src={CrossIcon} className="closeIcon" />
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: '#357092',
                  fontFamily: 'HK Grotesk',
                  fontSize: '26px',
                  fontWeight: 'bold',
                }}
              >
                Share to Social Media
              </Typography>
              <Box className="share-container">
                <Share data={shareData} title={'Ongoing Seva Initiatives'} />
              </Box>
              {/* <CloseIcon onClick={() => setAddMembers(false)} /> */}
            </DialogTitle>
          </Dialog>
        </Grid>
        {openUpdateIntiativeDialog && (
          <UpdateInitiativeReportDetails
            openUpdateIntiativeDialog={openUpdateIntiativeDialog}
            details={ongoingDetails}
            initiativeName={initiativeName}
            handleCloseUpdateInitiativeDetails={handleCloseUpdateInitiativeDetails}
            initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
          />
        )}
      </Box>
    </Box>
  )
}

export default ViewAllOngoingInitiatives
