import { useLocation } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getIds } from 'Components/ReusableComponents.js/getIds'
import { Typography, Card, Box, IconButton, Tooltip, Grid, Dialog, DialogTitle } from '@mui/material'
import { styled } from '@mui/material/styles'
import CreateNewEvent from 'Components/SevaUpdateRefactored/AddSevaEvent/CreateNewEvent'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SharingText from 'Components/YourInitiativeRefactor/sharingText/sharingText'
import CreateInitiativeReportDialog from 'Components/AllInitiativeReportRefactored/CreateInitiativeReportDialog/createInitiativeReportDialog'
import UpdateInitiativeReportDetails from 'Components/AllInitiativeReportRefactored/UpdateInitiativeReportDetails/UpdateInitiativeReportDialog'
import InitiativeHeaderImg from './InitiativeHeaderImg/InitiativeHeaderImg'
import { pushTag } from 'utils/gtmservice'
import { useNotificationContext } from 'utils/NotificationContext'
import { defaultErrorMessage } from 'utils/constant'
import { getIntiativesReportByInitiativeIdAndMpId } from 'store/action/ongoingSevaInitiativesList'
import { handleFileName, handlePdfClickOpen } from 'Components/ReusableComponents.js/reuseMethods'
import { getSocialMediaKit } from 'store/action/socialMediaKit'
import CrossIcon from 'asserts/images/Close.svg'
import Share from 'Components/ReusableComponents.js/Share'
import Details from './Details/Details'
import SocialMediaKit from './SocialMediaKit/SocialMediaKit'
import YourEvents from './YourEvents/YourEvents'
import EventInitiativeReport from './EventInitiativeReport/EventInitiativeReport'
import { getRoles } from 'Components/ReusableComponents.js/getIds'
import { makeLogRequest } from 'utils/AuditLogs'
const mpUserId = getIds()
const postAuditLogs = async (sqId, data = {}, flag = 'your_initiatives_page') => {
  data.user_role = getRoles() == 2 ? 'admin' : getRoles() == 3 ? 'leadership' : 'mp'
  data.useridtype = mpUserId
  await makeLogRequest(flag, sqId, data)
}
function IntiativesDetails({ data }) {
  const initiativeReportDetailsByMPIdAndIntiativeId = useSelector(
    (state) => state?.initiativeReportDetailsByMPIdAndIntiativeId?.data
  )
  const [initDetailsChange, setInitDetailsChange] = useState(false)

  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const mpId = mpProfileData?.id
  const [createButtonClick, setCreateButtonClick] = useState(false)
  const [openImage, setOpenImage] = useState(false)
  const [openText, setOpenText] = useState(false)
  const [openVideo, setOpenVideo] = useState(false)
  const [myValues, setMyValues] = useState(data)
  const [initiativeEvents, setInitiativeEvents] = useState([])
  const [editEvent, setEditEvent] = useState(false)
  const [openUpdateIntiativeDialog, setOpenUpdateInitiativeDialog] = useState(false)
  const [openEventDialog, setOpenEventDialog] = useState(false)
  const [eventDetails, setEventDetails] = useState()
  const [isEventEdit, setIsEventEdit] = useState(false)
  const [createEventDialog, setCreateEventDialog] = useState(false)
  const [initReportDetails, setInitReportDetails] = useState([])
  const [participants, setInitiativeParticipants] = useState(0)
  const [expanded, setExpanded] = useState(false)
  const [yourEventsExpand, setYourEventsExpand] = useState(false)
  const [mediaShare, setMediaShare] = useState(false)
  const [shareData, setShareData] = useState({})
  const [checkInitiativeStatus, setCheckInitiativeStatus] = useState(false)
  let MPId = getIds()
  const { showNotification } = useNotificationContext()
  const dispatch = useDispatch()
  const timestamp1 = new Date(data?.createdAt)
  const timestamp2 = new Date('2024-07-04T18:29:59Z')
  const isTimestamp1GreaterThanTimestamp2 = timestamp1 > timestamp2
  const fetchInitiativeData = async () => {
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(data?.id, mpId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          setInitiativeEvents([])
        } else {
          setInitiativeEvents(response?.data?.Eventdata)
          setInitReportDetails(response?.data?.reportdata)
          setInitiativeParticipants(response?.data?.participant)
        }
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    }
  }

  useEffect(() => {
    if (checkInitiativeStatus) {
      setInitDetailsChange(!initDetailsChange)
    }
  }, [createButtonClick])

  useEffect(() => {
    if (data) {
      setMyValues(data)
      dispatch(getSocialMediaKit(data?.id))
    }
    if (data?.id && mpId) fetchInitiativeData()
  }, [data, openUpdateIntiativeDialog, createButtonClick, createEventDialog, openEventDialog, mpId])

  const sendGALog = (event, message) => {
    pushTag({
      event: event,
      page_title: 'your_initiative_page',
      message: message,
    })
  }

  const handleClickingOpen = async () => {
    await postAuditLogs('text')
    sendGALog('select_option', 'text')
    await postAuditLogs('pageload', { i_id: data?.id }, 'sharing_text_popup')
    pushTag({
      event: 'pop_up',
      page_title: 'sharing_text_pop_up',
    })
    setOpenText(true)
  }

  const handleImageClickOpen = async () => {
    await postAuditLogs('images')
    sendGALog('select_option', 'image')
    setOpenImage(true)
  }
  const handleVideoClickOpen = async () => {
    await postAuditLogs('videos')
    sendGALog('select_option', 'videos')
    setOpenVideo(true)
  }

  const handleCloseText = (value) => {
    setOpenText(false)
  }

  const handleImageClose = (value) => {
    setOpenImage(false)
  }

  const handleVideoClose = (value) => {
    setOpenVideo(false)
  }

  const handlecreateClickOpen = () => {
    setCreateButtonClick(!createButtonClick)
  }

  const handleCloseEvent = () => {
    setOpenEventDialog(false)
  }

  const handleCloseCreateEvent = () => {
    setCreateEventDialog(false)
  }

  const handleEdit = (item) => {
    if (!isTimestamp1GreaterThanTimestamp2) {
      return
    }
    setEventDetails(item)
    setOpenEventDialog(true)
    setEditEvent(true)
    setIsEventEdit(true)
  }

  const handleExpandClick = async () => {
    await postAuditLogs('expand_initiavtive_details')
    setExpanded(!expanded)
  }

  const handleYourEventsExpandClick = async () => {
    await postAuditLogs('expand_your_events')
    setYourEventsExpand(!yourEventsExpand)
  }

  const handleCloseUpdateInitiativeDetails = (reset, reportFiles, setUploadFiles) => {
    // setShowUpdate(false);
    reset()
    reportFiles([])
    setUploadFiles([])
    setOpenUpdateInitiativeDialog(false)
  }
  const handleInitiativeEvent = async () => {
    await postAuditLogs('create_an_event')
    sendGALog('select_option', 'create_event')
    setCreateEventDialog(true)
  }
  const handleInitiativeReport = async () => {
    await postAuditLogs('create_initiavtive_report')
    sendGALog('select_option', 'create_initiative_report')
    if (data.status === 'Completed' || initReportDetails?.id) {
      setOpenUpdateInitiativeDialog(true)
    } else {
      handlecreateClickOpen()
      // setCreateButtonClick(true)
    }
  }

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props
    return <IconButton {...other} />
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }))

  const handleShare = (e, item) => {
    e.stopPropagation()
    e.preventDefault()
    setShareData(item)
    setMediaShare(true)
  }

  const dataSteps = data?.steps?.split('\n')

  return (
    <Grid item xs={6} md={6}>
      <Box className="container2-alligment-2">
        <Box className="customscroll scroll-property">
          <Card card-style>
            {data ? (
              <Box>
                <InitiativeHeaderImg
                  data={data}
                  handleImageClose={handleImageClose}
                  openImage={openImage}
                  handleVideoClose={handleVideoClose}
                  openVideo={openVideo}
                />
                <Details
                  expanded={expanded}
                  handleExpandClick={handleExpandClick}
                  data={data}
                  handlePdfClickOpen={handlePdfClickOpen}
                  dataSteps={dataSteps}
                  handleFileName={handleFileName}
                  ExpandMore={ExpandMore}
                  ExpandMoreIcon={ExpandMoreIcon}
                />

                <SocialMediaKit
                  handleClickingOpen={handleClickingOpen}
                  handleImageClickOpen={handleImageClickOpen}
                  handleVideoClickOpen={handleVideoClickOpen}
                />
                <SharingText open={openText} onClose={handleCloseText} />

                <YourEvents
                  ExpandMore={ExpandMore}
                  ExpandMoreIcon={ExpandMoreIcon}
                  initiativeEvents={initiativeEvents}
                  yourEventsExpand={yourEventsExpand}
                  handleYourEventsExpandClick={handleYourEventsExpandClick}
                  postAuditLogs={postAuditLogs}
                  handleEdit={handleEdit}
                  handleShare={handleShare}
                  tohide={isTimestamp1GreaterThanTimestamp2}
                />

                <EventInitiativeReport
                  handleInitiativeEvent={handleInitiativeEvent}
                  handleInitiativeReport={handleInitiativeReport}
                  data={data}
                  initReportDetails={initReportDetails}
                  tohide={isTimestamp1GreaterThanTimestamp2}
                />
                {createEventDialog && (
                  <CreateNewEvent
                    openCreateEventDialog={createEventDialog}
                    handleCloseCreateEvent={handleCloseCreateEvent}
                    initiativeId={data.id}
                    initiativeName={data.initiativeName}
                  />
                )}
                {editEvent && openEventDialog && (
                  <CreateNewEvent
                    mpId={mpId}
                    initiativeId={data.id}
                    initiativeName={data.initiativeName}
                    details={eventDetails}
                    handleCloseCreateEvent={handleCloseEvent}
                    openCreateEventDialog={openEventDialog}
                    isSevaEventEdit={isEventEdit}
                    toHide={isTimestamp1GreaterThanTimestamp2}
                  />
                )}
                {createButtonClick ? (
                  <CreateInitiativeReportDialog
                    open={createButtonClick}
                    handleClose={handlecreateClickOpen}
                    initiativeName={data.initiativeName}
                    initiativeId={data.id}
                    participants={participants}
                    setCheckInitiativeStatus={setCheckInitiativeStatus}
                    insideYourInitiative={true}
                    mpId={MPId}
                  />
                ) : null}
                {openUpdateIntiativeDialog ? (
                  <UpdateInitiativeReportDetails
                    participants={participants}
                    handleCloseUpdateInitiativeDetails={handleCloseUpdateInitiativeDetails}
                    openUpdateIntiativeDialog={openUpdateIntiativeDialog}
                    details={initReportDetails}
                    initiativeName={data.initiativeName}
                    initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
                  />
                ) : null}
                <Dialog open={mediaShare} onClose={() => setMediaShare(false)}>
                  <DialogTitle>
                    <img onClick={() => setMediaShare(false)} src={CrossIcon} className="closeIcon" />
                    <Typography
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#357092',
                        fontFamily: 'HK Grotesk',
                        fontSize: '26px',
                        fontWeight: 'bold',
                      }}
                    >
                      Share to Social Media
                    </Typography>

                    <Box className="social-media-icons">
                      <Share data={shareData} title="Seva aur Samarpan Campaign" />
                    </Box>
                  </DialogTitle>
                </Dialog>
              </Box>
            ) : null}
          </Card>
        </Box>
      </Box>
    </Grid>
  )
}
export default IntiativesDetails
