import { Button, Typography } from '@mui/material'
import leaderboard1 from '../../../asserts/images/Ic_Leaderboard.svg'
import leaderboard2 from '../../../asserts/images/Ic_Logout.svg'
import leaderboard3 from '../../../asserts/images/Ic_Seva Updates.svg'
import leaderboard4 from '../../../asserts/images/Ic_Your Initiatives.svg'
import leaderboard5 from '../../../asserts/images/sevaini.svg'
import leaderboard6 from '../../../asserts/images/settingIcon.svg'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { getIds, getRoles } from '../../ReusableComponents.js/getIds'
import { pushTag } from '../../../utils/gtmservice'
import { useNavigate } from 'react-router-dom'
import { useInitiativeContext } from '../../../utils/InitiativeContext'
import { useState, useEffect } from 'react'
import { env } from '../../../env'
import { useSelector, useDispatch } from 'react-redux'
import getUserProfile from '../../../store/action/getUserProfile'

const SideMenuNav = ({
  active,
  setactive,
  setOpenWarningDialog,
  createInitiative,
  settingsTab,
  setOpenLogoutDialog,
  checkWarningClick,
  checkLogoutClick,
}) => {
  const userId = parseInt(getRoles())
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { setResetInitiatives } = useInitiativeContext()
  const [logoutClick, setLogoutClick] = useState(false)
  const userProfile = useSelector((state) => state?.userProfile?.data)

  useEffect(() => {
    dispatch(getUserProfile())
  }, [])

  useEffect(() => {
    if (checkWarningClick) {
      if (active === 'Leader') {
        userId === 2 || userId === 3 ? navigate('/AdminHome') : navigate('/MpHome')
      } else if (active === 'SevaInitiative') {
        if (userId === 2) navigate('/SevaInitiatives')
      } else if (active === 'Seva') {
        if (userId === 2 || userId === 3) {
          navigate('/SevaUpdates')
        }
      } else if (active === 'Settings') {
        if (userId === 2) navigate('/Settings')
      }
    }
  }, [checkWarningClick])

  useEffect(() => {
    if (checkLogoutClick) {
      localStorage.clear()
      if (env.REACT_APP_TESTLOGIN === '1') {
        navigate('/LoginUsers')
        window.location.reload()
      } else window.location.replace(env.REACT_APP_WEBSITE_URL)
    }
  }, [checkLogoutClick])

  const postAuditLogs = async (sqId) => {
    let user_role = userId === 2 ? 'admin' : userId === 3 ? 'leadership' : 'mp'
    await makeLogRequest('navigation', sqId, {
      useridtype: getIds(),
      user_role,
    })
  }

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'navigation_panel',
      message: message,
    })
  }

  const handleClickOpenWarningDialog = () => {
    setOpenWarningDialog(true)
  }

  const handleClickOpenLogoutDialog = () => {
    setOpenLogoutDialog(true)
  }

  const navItems = [
    {
      buttonTitle: 'Leaderboard',
      buttonIcon: leaderboard1,
      className: active === 'Leader' && 'active',
      onClick: async () => {
        setactive('Leader')
        await postAuditLogs('leaderboard')
        sendGALog('leaderboard')
        if (createInitiative || settingsTab) {
          handleClickOpenWarningDialog(true)
        } else {
          {
            userId === 2 || userId === 3 ? navigate('/AdminHome') : navigate('/MpHome')
          }
        }
      },
    },
    {
      buttonTitle: userId === 2 ? 'Seva Initiatives' : 'Seva Updates',
      buttonIcon: userId === 2 ? leaderboard5 : leaderboard3,
      className: userId === 2 ? active === 'SevaInitiative' && 'active' : active === 'Seva' && 'active',
      onClick:
        userId === 2
          ? async () => {
              setactive('SevaInitiative')
              await postAuditLogs('seva_initiatives')
              sendGALog('seva_initiative')
              if (createInitiative || settingsTab) {
                handleClickOpenWarningDialog(true)
              } else {
                navigate('/SevaInitiatives')
              }
            }
          : async () => {
              setactive('Seva')
              await postAuditLogs('seva_updates')
              sendGALog('seva_updates')
              if (createInitiative || settingsTab) {
                handleClickOpenWarningDialog(true)
              } else {
                navigate('/SevaUpdates')
              }
            },
    },
    userId !== 3 && {
      buttonTitle: userId !== 2 ? 'Your Initiatives' : 'Seva Updates',
      buttonIcon: userId !== 2 ? leaderboard4 : leaderboard3,
      className: userId !== 2 ? active === 'Initiatives' && 'active' : active === 'Seva' && 'active',
      onClick:
        userId !== 2
          ? async () => {
              setactive('Initiatives')
              await postAuditLogs('your_initiatives')
              sendGALog('your_initiatives')
              setResetInitiatives(true)
              navigate('/Initiatives')
            }
          : async () => {
              setactive('Seva')
              await postAuditLogs('seva_updates')
              sendGALog('seva_updates')
              if (createInitiative || settingsTab) {
                handleClickOpenWarningDialog(true)
              } else {
                navigate('/SevaUpdates')
              }
            },
    },
    userId === 2 &&
      userProfile?.showSettings === true && {
        buttonTitle: 'Settings',
        buttonIcon: leaderboard6,
        className: active === 'Settings' && 'active',
        onClick: () => {
          setactive('Settings')
          if (createInitiative || settingsTab) {
            handleClickOpenWarningDialog(true)
          } else {
            navigate('/Settings')
          }
        },
      },
    {
      buttonTitle: 'Log Out',
      buttonIcon: leaderboard2,
      className: '',
      onClick: async () => {
        setLogoutClick(true)
        await postAuditLogs('logout')
        sendGALog('logout')
        handleClickOpenLogoutDialog()
      },
    },
  ]

  return (
    <nav>
      <ul>
        {navItems.map((item, index) => {
          if (userId === 3 && index === 2) {
            return null
          }
          if (index === 3 && userProfile?.showSettings === false) {
            return null
          }
          return (
            <li className="justify-content-between">
              <Button className={item?.className} onClick={item?.onClick}>
                <Typography variant="span">
                  <img className="mr-3" src={item?.buttonIcon} width="15" alt="" />
                  {item?.buttonTitle}
                </Typography>
              </Button>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default SideMenuNav
