import { Tab, Tabs } from '@mui/material'
import './Tenure.css'
import { useState } from 'react'

const Tenure = ({ active, setActive }) => {
  const handleTabClick = (label) => {
    setActive(label)
  }

  return (
    <Tabs defaultValue={active} className="mt-5">
      <Tab
        onClick={() => handleTabClick('Modi 3.0')}
        className={`tenure-tab ${active === 'Modi 3.0' ? '' : 'tenure-tab-active'}`}
        value={active}
        label="Modi 3.0"
      />
      <Tab
        onClick={() => handleTabClick('Modi 2.0')}
        className={`tenure-tab ${active === 'Modi 2.0' ? '' : 'tenure-tab-active'}`}
        value={active}
        label="Modi 2.0"
      />
    </Tabs>
  )
}

export default Tenure
