import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Typography } from '@mui/material'
import WarningMessageDialog from '../SevaInitiatives/WarningMessageDialog'
import LogoutDialog from '../../utils/LogoutDialog'
import ConnectionCloseError from '../../utils/ConnectionCloseError'
import getUserProfile from '../../store/action/getUserProfile'
import SideMenuProfileComponent from './SideMenuProfileComponent/SideMenuProfieComponent'
import SideMenuNav from './SideMenuNav/SideMenuNav'
import './SideMenu.css'
import WhatsNewSection from '../ReusableComponents.js/WhatsNewSection'

const SideMenu = ({ activeTab, createInitiative, settingsTab }) => {
  const [active, setactive] = useState(activeTab)
  const [openWarningDialog, setOpenWarningDialog] = useState(false)
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false)
  const [connectionClosedDialog, setOpenConnectionClosedDialog] = useState(false)
  const [checkWarningClick, setCheckWarningClick] = useState(false)
  const [checkLogoutClick, setCheckLogoutClick] = useState(false)
  const is401ErrorReceived = useSelector((state) => state?.userProfile?.is401)
  const is444ErrorReceived = useSelector((state) => state?.userProfile?.is444)
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const dispatch = useDispatch()
  const [popupOpen, setPopupOpen] = useState(false)

  const handleCloseWarningDialog = () => {
    setOpenWarningDialog(false)
    if (createInitiative) {
      setactive('SevaInitiative')
    }
    if (settingsTab) {
      setactive('Settings')
    }
  }

  const handleCloseLogoutDialog = () => setOpenLogoutDialog(false)

  const handlePopUp = () => {
    setPopupOpen((prev) => !prev)
  }

  useEffect(() => {
    if (is401ErrorReceived === true) {
      setOpenLogoutDialog(true)
    }
  }, [is401ErrorReceived])

  useEffect(() => {
    is444ErrorReceived && setOpenConnectionClosedDialog(true)
  }, [is444ErrorReceived])

  useEffect(() => {
    dispatch(getUserProfile())
  }, [])

  return (
    <Box className="left-sidebar-b d-flex flex-column">
      <SideMenuProfileComponent />

      <SideMenuNav
        active={active}
        setactive={setactive}
        setOpenWarningDialog={setOpenWarningDialog}
        createInitiative={createInitiative}
        settingsTab={settingsTab}
        setOpenLogoutDialog={setOpenLogoutDialog}
        checkWarningClick={checkWarningClick}
        checkLogoutClick={checkLogoutClick}
      />

      <Box className="d-flex flex-column mt-auto mb-2 ml-2 whats-new-wrapper">
        <Button className="whats-new-btn" onClick={handlePopUp}>
          What's New!
        </Button>
        <Typography className="version-wrapper">{userProfile?.version}</Typography>
      </Box>

      <WarningMessageDialog
        openWarningDialog={openWarningDialog}
        handleCloseWarningDialog={handleCloseWarningDialog}
        setCheckWarningClick={setCheckWarningClick}
      />

      <LogoutDialog
        openLogoutDialog={openLogoutDialog}
        handleCloseLogoutDialog={handleCloseLogoutDialog}
        setCheckLogoutClick={setCheckLogoutClick}
        isSessionTimedOut={is401ErrorReceived}
      />

      <ConnectionCloseError isOpen={connectionClosedDialog} />
      <WhatsNewSection popupOpen={popupOpen} setPopupOpen={setPopupOpen}></WhatsNewSection>
    </Box>
  )
}

export default SideMenu
