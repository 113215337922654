import {
  FETCH_MOST_VIEWED_TWEETS_FAILURE,
  FETCH_MOST_VIEWED_TWEETS_REQUEST,
  FETCH_MOST_VIEWED_TWEETS_SUCCESS,
} from './types'
import axiosInstance from '../../utils/api'

export const fetchMostViewedTweetsRequest = () => {
  return {
    type: FETCH_MOST_VIEWED_TWEETS_REQUEST,
  }
}
export const fetchMostViewedTweetsSuccess = (value) => {
  return {
    type: FETCH_MOST_VIEWED_TWEETS_SUCCESS,
    payload: value,
  }
}
export const fetchMostViewedTweetsFailure = (error) => {
  return {
    type: FETCH_MOST_VIEWED_TWEETS_FAILURE,
    payload: error,
  }
}

export const getMostViewedTweets = () => async (dispatch) => {
  dispatch(fetchMostViewedTweetsRequest)
  await axiosInstance
    .get('/api/mp/tweets/topMostViewedTweets')
    .then(({ data }) => {
      dispatch(fetchMostViewedTweetsSuccess(data))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchMostViewedTweetsFailure(errorMsg))
    })
}
