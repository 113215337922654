import { DialogActions, Button } from '@mui/material'

const TemplateDialogSubmitButton = ({
  title,
  onClick,
  onFocus,
  onMouseUp,
  isLoading,
  CircularProgress,
  dialogActionSx,
  buttonSx,
  buttonClassName,
  disabled,
  variant,
}) => {
  return (
    <DialogActions sx={dialogActionSx}>
      <Button
        sx={buttonSx}
        className={`${buttonClassName ? buttonClassName : 'button-tr-2'}`}
        type="submit"
        onClick={onClick}
        onFocus={onFocus}
        onMouseUp={onMouseUp}
        disabled={disabled}
        variant={variant}
      >
        {isLoading && <CircularProgress size={30} />}
        {title}
      </Button>
    </DialogActions>
  )
}

export default TemplateDialogSubmitButton
