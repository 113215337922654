import axiosInstance from 'utils/api'
import { GET_ACTIVITY } from './types'
export const fetchAcitivity = (data) => {
  return {
    type: GET_ACTIVITY,
    payload: data,
  }
}
export const getDevelopementAcitivites =
  (mpIds = [], sortOption) =>
  async (dispatch) => {
    const mpListQueryParam = mpIds.join(',')
    let endPoint
    if (sortOption) {
      endPoint = `api/admin/development-projects?mpList=${mpListQueryParam}&sortBy=${sortOption}`
    } else {
      endPoint = `api/admin/development-projects?mpList=${mpListQueryParam}&sortBy=interest`
    }
    await axiosInstance
      .get(endPoint)
      .then((response) => {
        const result = response.data
        dispatch(fetchAcitivity(result))
      })
      .catch((error) => {
        const errorMsg = error.message
      })
  }
