import Papa from 'papaparse'
const CryptoJS = require('crypto-js')

function generateRandomNumber() {
  const byteLength = 32
  const randomBytes = CryptoJS.lib.WordArray.random(byteLength)
  const randomNumber = randomBytes.toString()

  return randomNumber
}

export const CsvToJson = (csvFile, headerTitles = ['language', 'text', 'hashtag']) => {
  return new Promise((resolve, reject) => {
    const parsedData = []
    const allLangs = [
      'english',
      'hindi',
      'assamese',
      'bengali',
      'bodo',
      'dogri',
      'gujarati',
      'kannada',
      'kashmiri',
      'konkani',
      'maithili',
      'malayalam',
      'manipuri',
      'marathi',
      'nepali',
      'odia',
      'punjabi',
      'sanskrit',
      'santali',
      'sindhi',
      'tamil',
      'telugu',
      'urdu',
    ]
    let headers = null
    return Papa.parse(csvFile, {
      header: false,
      dynamicTyping: true,
      worker: true,
      skipEmptyLines: true,
      step(row, parser) {
        const { data } = row
        const currentColumns = Object.keys(data || {}).length
        if (currentColumns !== 3) {
          return parser.abort()
        }
        if (!headers) {
          headers = Object.keys(data)
        }
        let language =
          typeof data[headers[0]] === 'string'
            ? data[headers[0]]?.charAt(0)?.toUpperCase() + data[headers[0]]?.slice(1)
            : data[headers[0]]
        const text = data[headers[1]]
        const hashtag = data[headers[2]]
        if (
          (language == null && text == null && hashtag == null) ||
          (language?.length === 0 && text?.trim()?.length === 0 && hashtag?.trim()?.length === 0)
        ) {
          return
        }
        if (typeof language !== 'string' || !allLangs.includes(language.toString().trim().toLowerCase())) {
          language = null
        }
        parsedData.push({ id: generateRandomNumber(), language: language?.trim(), text, hashtag })
      },
      complete(results, file) {
        if (results?.meta?.aborted) {
          return reject('Number of columns should be 3')
        }
        return resolve(parsedData)
      },
      error(err, file) {
        reject(err?.message ? err.message : err)
      },
    })
  })
}
