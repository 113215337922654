import {
  SEARCH_SEVA_EVENTS_REQUEST,
  SEARCH_SEVA_EVENTS_SUCCESS,
  SEARCH_SEVA_EVENTS_FAILURE,
  SEARCH_MEDIA_COVERAGE_REQUEST,
  SEARCH_MEDIA_COVERAGE_SUCCESS,
  SEARCH_MEDIA_COVERAGE_FAILURE,
  SEARCH_DEVELOPMENT_PROJECTS_REQUEST,
  SEARCH_DEVELOPMENT_PROJECTS_SUCCESS,
  SEARCH_DEVELOPMENT_PROJECTS_FAILURE,
  SEARCH_MISCELLANEOUS_REQUEST,
  SEARCH_MISCELLANEOUS_SUCCESS,
  SEARCH_MISCELLANEOUS_FAILURE,
} from '../action/types';

const initialState = {
  data: [],
  loading: true,
  error: '',
};

export const searchSevaUpdates = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_SEVA_EVENTS_REQUEST:
    case SEARCH_MEDIA_COVERAGE_REQUEST:
    case SEARCH_DEVELOPMENT_PROJECTS_REQUEST:
    case SEARCH_MISCELLANEOUS_REQUEST:
      return {
        state,
        loading: true,
      };
    case SEARCH_SEVA_EVENTS_SUCCESS:
    case SEARCH_MEDIA_COVERAGE_SUCCESS:
    case SEARCH_DEVELOPMENT_PROJECTS_SUCCESS:
    case SEARCH_MISCELLANEOUS_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case SEARCH_SEVA_EVENTS_FAILURE:
    case SEARCH_MEDIA_COVERAGE_FAILURE:
    case SEARCH_DEVELOPMENT_PROJECTS_FAILURE:
    case SEARCH_MISCELLANEOUS_FAILURE:
      return {
        data: [],
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
