import { SEARCH_INITIATIVE_FAILURE, SEARCH_INITIATIVE_REQUEST, SEARCH_INITIATIVE_SUCCESS } from './types';
import axiosInstance from '../../utils/api';

export const searchInitiativeRequest = () => {
  return {
    type: SEARCH_INITIATIVE_REQUEST,
    payload: [],
  };
};
export const searchInitiativeSuccess = (value) => {
  return {
    type: SEARCH_INITIATIVE_SUCCESS,
    payload: value,
  };
};
export const searchInitiativeFailure = (error) => {
  return {
    type: SEARCH_INITIATIVE_FAILURE,
    payload: [],
  };
};

export const searchInitiative = (userId, searchQuery) => async (dispatch) => {
  dispatch(searchInitiativeRequest);
  await axiosInstance
    .get(`/api/search/initiativesummary/${userId}?searchText=${searchQuery}`)
    .then((response) => {
      const result = response.data;
      if (Array.isArray(result)) {
        dispatch(searchInitiativeSuccess(result));
      } else {
        dispatch(searchInitiativeSuccess([]));
      }
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(searchInitiativeFailure(errorMsg));
    });
};
