import { Divider, Paper, Typography } from '@mui/material'
import './PinnedActivityContainer.css'
import FilterSortComponent from './FilterSortComponent/FilterSortComponent'
import { Box } from '@mui/system'
import DevelopementProjectCarousel from 'Components/PinnedActivityContainer/DevelopementProjectCarousel/DevelopementProjectCarousel'
import { useState } from 'react'
import Sortbutton from './FilterSortComponent/sortbutton'
import { useSelector } from 'react-redux'
const PinnedActivityContainer = () => {
  const activity = useSelector((state) => state?.activity?.data)
  const [pinnedCount, setPinnedCount] = useState(activity?.pinnedCount || 0)
  const [sortBy, setSortBy] = useState('interest')

  const [mpIdLists, setMpIdLists] = useState([])
  return (
    <>
      <Paper className="PinActivity-Paper-container d-flex flex-column">
        <Typography variant="h5" className="send-alert-heading" sx={{ fontSize: '1.5rem !important' }}>
          Activity Pinned : {activity?.pinnedCount ? activity?.pinnedCount : '0'}/10
        </Typography>
        <Box className="sortFilterBox">
          <FilterSortComponent
            Title={'Filter'}
            mpIdLists={mpIdLists}
            setMpIdLists={setMpIdLists}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
          <Sortbutton
            Title={'Sort'}
            mpIdLists={mpIdLists}
            setMpIdLists={setMpIdLists}
            sortBy={sortBy}
            setSortBy={setSortBy}
          />
        </Box>
        <Divider />
        <Box sx={{ overflow: 'auto' }}>
          <DevelopementProjectCarousel
            setPinnedCount={setPinnedCount}
            pinnedCount={pinnedCount}
            mpIdLists={mpIdLists}
            setMpIdLists={setMpIdLists}
            sortBy={sortBy}
          />
        </Box>
      </Paper>
    </>
  )
}
export default PinnedActivityContainer
