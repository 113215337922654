import {
  FETCH_MOST_RETWEETED_TWEETS_FAILURE,
  FETCH_MOST_RETWEETED_TWEETS_REQUEST,
  FETCH_MOST_RETWEETED_TWEETS_SUCCESS,
} from './types';
import axiosInstance from '../../utils/api';

export const fetchMostRetweetedTweetsRequest = () => {
  return {
    type: FETCH_MOST_RETWEETED_TWEETS_REQUEST,
  };
};
export const fetchMostRetweetedTweetsSuccess = (value) => {
  return {
    type: FETCH_MOST_RETWEETED_TWEETS_SUCCESS,
    payload: value,
  };
};
export const fetchMostRetweetedTweetsFailure = (error) => {
  return {
    type: FETCH_MOST_RETWEETED_TWEETS_FAILURE,
    payload: error,
  };
};

export const getMostRetweetedTweets = () => async (dispatch) => {
  dispatch(fetchMostRetweetedTweetsRequest);
  await axiosInstance
    .get('/api/mp/tweets/topMostRetweetedTweets')
    .then((response) => {
      const result = response.data;
      dispatch(fetchMostRetweetedTweetsSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchMostRetweetedTweetsFailure(errorMsg));
    });
};
