import { useState, useRef, useEffect, useCallback, useReducer } from 'react'
import { Dialog, DialogContent, DialogActions, Box, Typography, Button } from '@mui/material'
import { convertToHHMMSS } from '../reuseMethods'
import { useNotificationContext } from '../../../utils/NotificationContext'
import { useLoading } from '../../../utils/LoadingContext'
import { getTrimmedVideo } from 'utils/VideoTrimService'
import './VideoTrimDialog.css'
import { defaultErrorMessage } from 'utils/constant'
import WarningPopUp from './WarningPopUp/WarningPopUp'

const maxVideoDurationInSec = 179 // 180 secs = 3 mins

const initialState = (duration) => ({
  startPosition: 0,
  endPosition: duration > maxVideoDurationInSec ? maxVideoDurationInSec : duration,
  seekPosition: 0.0,
  duration,
})

function reducer(state, action) {
  return {
    ...state,
    ...action,
  }
}

function VideoTrimDialog({ openVideoTrimDialog, onUpload, onCancel, videoData, setVideoData }) {
  const [state, dispatch] = useReducer(reducer, initialState(videoData.duration))
  const videoRef = useRef(null)
  const playbackBarRef = useRef(null)
  const [videoTrimmedUrl, setVideoTrimmedUrl] = useState(videoData.videoUrl)
  const { showNotification } = useNotificationContext()
  const { setLoading } = useLoading()
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const [openWarningDialog, setOpenWarningDialog] = useState(false)
  const [isUpload, setIsUpload] = useState(true)
  const handleCloseWarningDialog = () => setOpenWarningDialog(false)

  const handleTrim = useCallback(async () => {
    setIsUpload(false)

    try {
      setLoading(true)
      const trimmedVideoResponse = await getTrimmedVideo({
        videoUrl: videoData.videoUrl,
        startTime: Number(state.startPosition.toFixed()),
        endTime: Math.ceil(Number(state.endPosition.toFixed())),
      })
      setVideoTrimmedUrl(trimmedVideoResponse?.trimUrl)
      onUpload(trimmedVideoResponse?.trimUrl)
      setTimeout(() => {
        onCancel(false)
      }, 2000)
      showNotification('Success', 'Your Video was uploaded successfully', 'success')
    } catch (err) {
      showNotification('Error', defaultErrorMessage)
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [state, videoData])

  return (
    <>
      <Dialog
        open={openVideoTrimDialog}
        PaperProps={{
          sx: {
            backgroundColor: 'rgba(0,0,0,.8)',
            height: '100vh',
            width: '100vw',
            maxWidth: '100vw !important',
            margin: '0',
          },
        }}
        onClose={() => onCancel(false)}
      >
        <DialogContent className="d-flex flex-column justify-content-center">
          <video
            id="video-trimmer"
            preload="metadata"
            className="w-50 h-50 align-self-center mb-5"
            ref={videoRef}
            src={videoTrimmedUrl}
            controls
            disablePictureInPicture
            controlsList="nodownload noplaybackrate"
          />
          <Box className="playback h-63 w-75 align-self-center">
            <Grabbers
              videoRef={videoRef}
              duration={videoData.duration}
              playbackBarRef={playbackBarRef}
              state={state}
              dispatch={dispatch}
            />
            <Box className="d-flex seekable" ref={playbackBarRef}>
              {videoData.frames &&
                videoData.frames.length > 0 &&
                videoData.frames.map((item, index) => (
                  <img
                    className="h-100 flex-grow-1"
                    src={`data:image/jpeg;base64,${item}`}
                    key={index}
                    alt="video thumbnail"
                    draggable={false}
                  />
                ))}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions className="d-flex justify-content-center mb-5">
          {isUpload ? (
            <Button variant="contained" className="trim-vid-upload-btn mr-3" onClick={handleTrim}>
              Upload
            </Button>
          ) : (
            <Button variant="contained" className="trim-vid-upload-btn mr-3">
              Upload
            </Button>
          )}
          <Button variant="outlined" className="trim-vid-cancel-btn" onClick={() => setOpenWarningDialog(true)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {openWarningDialog && (
        <WarningPopUp
          openWarningDialog={openWarningDialog}
          handleCloseWarningDialog={handleCloseWarningDialog}
          onCancel={onCancel}
        />
      )}
    </>
  )
}

const Grabbers = ({ videoRef, duration, playbackBarRef, state, dispatch }) => {
  const [seekStart, setSeekStart] = useState(false)
  const [seekEnd, setSeekEnd] = useState(false)
  const [seekPostion, setSeekPosition] = useState(false)

  const seekStartListeners = useRef([])
  const seekEndListeners = useRef([])
  const seekPositionListeners = useRef([])

  const disableSeekStart = useCallback(() => {
    setSeekStart(false)
  }, [])

  const disableSeekEnd = useCallback(() => {
    setSeekEnd(false)
  }, [])

  const disableSeekPosition = useCallback(() => {
    setSeekPosition(false)
  }, [])

  const onTimelineChange = useCallback(
    (event) => {
      // if (state.seekPosition) return

      if (event.srcElement.currentTime >= state.endPosition) {
        videoRef.current.pause()
        videoRef.current.currentTime = state.startPosition
        dispatch({ seekPosition: state.startPosition })
      } else {
        dispatch({ seekPosition: event.srcElement.currentTime })
      }
    },
    [state, videoRef]
  )

  const handleSeekMove = useCallback(
    (event) => {
      videoRef.current.pause()

      const playbackRect = playbackBarRef.current.getBoundingClientRect()
      const seek = ((event.clientX - playbackRect.left) / playbackRect.width) * duration

      if (
        state.seekPosition != seek &&
        Math.ceil(seek) >= Math.ceil(state.startPosition) &&
        Math.floor(seek) <= Math.floor(state.endPosition)
      ) {
        const acutalSeek = seek <= 0 ? 0 : seek >= duration ? duration : seek
        videoRef.current.currentTime = acutalSeek
        dispatch({ seekPosition: acutalSeek })

        seekPositionListeners.current.forEach((listener) => {
          window.removeEventListener(listener[0], listener[1])
        })

        window.addEventListener('mouseup', disableSeekPosition)
        seekPositionListeners.current = [['mouseup', disableSeekPosition]]
      }
    },
    [window, state, videoRef, playbackBarRef, dispatch, seekPositionListeners]
  )

  const handleStartMove = useCallback(
    (event) => {
      videoRef.current.pause()
      const playbackRect = playbackBarRef.current.getBoundingClientRect()
      const seekRatio = (event.clientX - playbackRect.left) / playbackRect.width
      const seek = duration * seekRatio <= 0 ? 0.0 : duration * seekRatio

      const endSeek =
        state.endPosition - seek > maxVideoDurationInSec ? seek + maxVideoDurationInSec : state.endPosition
      const newState = {}
      if (seek >= 0 && seek < state.endPosition && state.startPosition < state.endPosition && seek < duration) {
        newState.startPosition = seek
        newState.endPosition = endSeek

        if (state.seekPosition < seek || state.seekPosition > endSeek) {
          const currentSeek =
            state.seekPosition > state.endPosition
              ? state.endPosition
              : state.seekPosition < seek
              ? seek
              : state.seekPosition
          videoRef.current.currentTime = currentSeek
          newState.seekPosition = currentSeek
        }

        seekStartListeners.current.forEach((listener) => {
          window.removeEventListener(listener[0], listener[1])
        })
        window.addEventListener('mouseup', disableSeekStart)
        seekStartListeners.current = [['mouseup', disableSeekStart]]

        dispatch(newState)
      }
    },
    [dispatch, state, videoRef, playbackBarRef, window, seekStartListeners]
  )

  const handleEndMove = useCallback(
    (event) => {
      videoRef.current.pause()
      const playbackRect = playbackBarRef.current.getBoundingClientRect()
      const seekRatio = (event.clientX - playbackRect.left) / playbackRect.width
      const seek = duration * seekRatio <= 0 ? 0.0 : duration * seekRatio

      const startSeek =
        seek - state.startPosition > maxVideoDurationInSec ? seek - maxVideoDurationInSec : state.startPosition
      const newState = {}
      if (seek > state.startPosition && seek <= duration && state.startPosition < state.endPosition) {
        newState.startPosition = startSeek
        newState.endPosition = seek

        if (state.seekPosition < startSeek || state.seekPosition > seek) {
          const currentSeek =
            state.seekPosition > seek ? seek : startSeek > state.seekPosition ? startSeek : state.seekPosition
          videoRef.current.currentTime = currentSeek
          newState.seekPosition = currentSeek
        }

        seekEndListeners.current.forEach((listener) => {
          window.removeEventListener(listener[0], listener[1])
        })

        window.addEventListener('mouseup', disableSeekEnd)
        seekEndListeners.current = [['mouseup', disableSeekEnd]]

        dispatch(newState)
      }
    },
    [dispatch, state, videoRef, playbackBarRef, window, seekEndListeners]
  )

  useEffect(() => {
    if (seekStart) {
      window.addEventListener('mousemove', handleStartMove)
    } else {
      window.removeEventListener('mousemove', handleStartMove)
    }

    if (seekEnd) {
      window.addEventListener('mousemove', handleEndMove)
    } else {
      window.removeEventListener('mousemove', handleEndMove)
    }

    if (seekPostion) {
      window.addEventListener('mousemove', handleSeekMove)
    } else {
      window.removeEventListener('mousemove', handleSeekMove)
    }

    return () => {
      window.removeEventListener('mousemove', handleStartMove)
      window.removeEventListener('mousemove', handleEndMove)
      window.removeEventListener('mousemove', handleSeekMove)
    }
  }, [window, seekStart, seekEnd, seekPostion, state])

  useEffect(() => {
    videoRef.current.addEventListener('timeupdate', onTimelineChange)

    return () => {
      if (videoRef.current) videoRef.current.removeEventListener('timeupdate', onTimelineChange)
    }
  }, [state])

  useEffect(() => {
    return () => {
      seekPositionListeners.current.forEach((listener) => {
        window.removeEventListener(listener[0], listener[1])
      })

      seekStartListeners.current.forEach((listener) => {
        window.removeEventListener(listener[0], listener[1])
      })

      seekEndListeners.current.forEach((listener) => {
        window.removeEventListener(listener[0], listener[1])
      })
    }
  }, [])

  return (
    <Box>
      <Box
        className="d-flex position-absolute hide h-63 left-0"
        style={{ width: `${(state.startPosition / duration) * 100}%` }}
      />
      <Box
        className="d-flex position-absolute"
        style={{ left: `${(state.startPosition / duration) * 100 - 1.7}%` }}
        onMouseDown={() => setSeekStart(true)}
        onMouseUp={disableSeekStart}
      >
        <Box className="grabber zi-2 h-63 start">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="kebabmenu">
            <circle cx="12" cy="3" r="3" fill="#ffffff" class="color000000 svgShape"></circle>
            <circle cx="12" cy="21" r="3" fill="#ffffff" class="color000000 svgShape"></circle>
            <circle cx="12" cy="12" r="3" fill="#ffffff" class="color000000 svgShape"></circle>
          </svg>
        </Box>
        <Typography variant="p" className="mt-65 ml-n2 text-light grabbers-typo">
          {convertToHHMMSS(state.startPosition)}
        </Typography>
      </Box>

      <Box
        className="position-absolute zi-3 cursorshow"
        style={{ left: `${(state.seekPosition / duration) * 100}%` }}
        onMouseDown={() => setSeekPosition(true)}
        onMouseUp={disableSeekPosition}
      >
        <Box className="d-flex position-absolute rounded-pill bg-white px-2 seek-current-time">
          <Typography variant="p" className="seek-typo">
            {convertToHHMMSS(state.seekPosition)}
          </Typography>
        </Box>
        <Box className="position-absolute current" />
      </Box>

      <Box
        className="d-flex position-absolute"
        style={{ left: `${(state.endPosition / duration) * 100}%` }}
        onMouseDown={() => setSeekEnd(true)}
        onMouseUp={disableSeekEnd}
      >
        <Box className="grabber zi-2 h-63 end">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="kebabmenu">
            <circle cx="12" cy="3" r="3" fill="#ffffff" class="color000000 svgShape"></circle>
            <circle cx="12" cy="21" r="3" fill="#ffffff" class="color000000 svgShape"></circle>
            <circle cx="12" cy="12" r="3" fill="#ffffff" class="color000000 svgShape"></circle>
          </svg>
        </Box>
        <Typography variant="p" className="mt-65 ml-n2 text-light grabbers-typo">
          {convertToHHMMSS(state.endPosition)}
        </Typography>
      </Box>
      <Box
        className="d-flex position-absolute hide h-63 right-0"
        style={{ left: `${(state.endPosition / duration) * 100}%` }}
      />
    </Box>
  )
}

export default VideoTrimDialog
