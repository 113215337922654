import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { IconButton, Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import CrossIcon from '../../../asserts/images/Close.svg'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import one from '../../../asserts/images/1.jpg'
import two from '../../../asserts/images/4.jpg'
import three from '../../../asserts/images/2.jpg'
import NoImageFound from '../../../asserts/images/noImageFound.jpg'
import ShareIcon from '@mui/icons-material/Share'
import CancelSharpIcon from '@mui/icons-material/CancelSharp'
import BorderColorIcon from '../../../asserts/images/Create.svg'
import './AddSevaEvent.css'
import CreateNewEvent from './CreateNewEvent'
import { useDispatch, useSelector } from 'react-redux'
import { getInitiativeList } from '../../../store/action/initiativeList'
import { getOngoingSevaIntiativesListByMpId } from '../../../store/action/ongoingSevaInitiativesList'
import Constant from '../../../utils/constant'
import { getIds } from '../../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { pushTag } from '../../../utils/gtmservice'

const AddSevaEvent = ({ handleCloseSevaEvent, openSevaEvent, details, eventByMpId, mpId }) => {
  const [value, setValue] = useState(0)
  const initiativeLists = useSelector((state) => state?.ongoingSevaInitiativesListByMpId?.data)
  const initiativeList = initiativeLists?.initiative
  const [openCreateEventDialog, setOpenCreateEventDialog] = useState(false)
  const [initiativeId, setInitiativeId] = useState(null)
  const [initiativeName, setInitiativeName] = useState(null)
  const dispatch = useDispatch()
  const loggedInId = getIds()
  useEffect(() => {
    if (mpId) {
      dispatch(getOngoingSevaIntiativesListByMpId({ id: mpId }))
    } else {
      dispatch(getOngoingSevaIntiativesListByMpId({ id: loggedInId }))
    }
    // dispatch(getInitiativeList())
  }, [])

  useEffect(() => {
    const parameters = {
      useridtype: getIds(),
    }
    if (openSevaEvent) {
      sendNewEventLogs('pageload', parameters)
      sendGALog('pop_up')
    }
  }, [openSevaEvent])

  const sendCloseDialogLog = () => {
    const parameters = {
      useridtype: getIds(),
    }
    sendNewEventLogs('close', parameters)
    sendGALog('back')
  }

  const sendNewEventLogs = async (type, parameters) => {
    await makeLogRequest('add_new_event_popup', type, parameters)
  }

  const sendGALog = (event, category = null, match = null) => {
    const page_title = 'create_event_popup'
    const payload = {
      event,
      page_title,
    }
    if (category) payload.category = category
    if (match) payload.match = match
    pushTag(payload)
  }

  const handleOpenCreateEvent = (initiativeId, initiativeName) => {
    const parameters = {
      useridtype: getIds(),
    }
    if (initiativeId) parameters.i_id = initiativeId
    const type = initiativeId ? 'select_initiative' : 'create_individual_event'
    if (initiativeName) {
      sendGALog('select_option', 'seva_events', initiativeName)
    } else {
      sendGALog('proceed')
    }
    sendNewEventLogs(type, parameters)
    setInitiativeId(initiativeId)
    setInitiativeName(initiativeName)
    setOpenCreateEventDialog(true)
    handleCloseSevaEvent()
  }

  const handleCloseCreateEvent = (reset, setImages, setFiles, setStartDate, setEndDate, setStartTime, setEndTime) => {
    setInitiativeId(null)
    reset()
    setImages([])
    setFiles([])
    setStartDate(null)
    setEndDate(null)
    setStartTime(null)
    setEndTime(null)
    setInitiativeName(null)
    setOpenCreateEventDialog(false)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }))
  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? <img onClick={onClose} src={CrossIcon} className="closeIcon" /> : null}
      </DialogTitle>
    )
  }

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  }

  return (
    <Box className="dialog-title-style">
      <BootstrapDialog
        onClose={() => {
          handleCloseSevaEvent()
          sendCloseDialogLog()
        }}
        aria-labelledby="customized-dialog-title"
        open={openSevaEvent}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '63%!important', // Set your width here
            },
          },
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => {
            handleCloseSevaEvent()
            sendCloseDialogLog()
          }}
        >
          <Typography variant="h5" className="Create-event-popUp__title">
            Choose an initiative for which you would
            <Box /> like to create an event for
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>
          <Box className="d-flex justify-content-between leaders-cards">
            <Box
              sx={{
                flexGrow: 1,
                minWidth: { xs: 400, sm: 400 },
                // bgcolor: 'background.paper',
                // marginTop:"-10px"
              }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                  '& .MuiTabs-indicator': { display: 'none' },
                }}
              >
                {initiativeList &&
                  initiativeList?.map((data, index) => (
                    <Card
                      className="cursorshow initiativeCard"
                      onClick={() => handleOpenCreateEvent(data?.id, data?.initiativeName)}
                    >
                      <Box className="position-relative">
                        <CardMedia
                          id={index}
                          component="img"
                          height="150"
                          src={JSON.parse(data?.coverimage)[0]}
                          onError={(e) => (e.target.src = NoImageFound)}
                          alt="new Image"
                          className="blackoverlay"
                        />
                        <Box className="dibgover dibgover-margintop"></Box>
                        <Box className="ecclipseonwidthnewrow-uyt ecclipseonwidthnewrow-uyt-style">
                          {' '}
                          {data?.initiativeName}
                        </Box>
                      </Box>
                    </Card>
                  ))}
              </Tabs>
            </Box>
          </Box>
        </DialogContent>
        <Box className="dialog-style">
          <DialogActions>
            <Box className="dialog-actions-style"> OR</Box>
            <Button className="button-tr-01" sx={{ ml: 2 }} onClick={() => handleOpenCreateEvent(null, null)}>
              <Typography variant="body-2" className="button-tr-01-onclick">
                Create an Individual Event
              </Typography>
              <img src={BorderColorIcon} className="border-color-icon" />
            </Button>
          </DialogActions>
        </Box>
      </BootstrapDialog>
      {openCreateEventDialog && (
        <CreateNewEvent
          openCreateEventDialog={openCreateEventDialog}
          handleCloseCreateEvent={handleCloseCreateEvent}
          initiativeId={initiativeId}
          initiativeName={initiativeName}
          eventByMpId={eventByMpId}
          mpId={mpId}
        />
      )}
    </Box>
  )
}

export default AddSevaEvent
