import { useMemo } from 'react'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import CardComponent from '../CardComponent/CardComponent'

const MpCard = ({ selectedCard, tenure }) => {
  const mpList = useSelector((state) => state?.mpList?.data)

  const mpData = useMemo(() => {
    return mpList.slice(0, 9)
  }, [mpList])

  return (
    <Grid container spacing={3}>
      {mpData.map((mp, index) => (
        <CardComponent selectedCard={selectedCard} mp={mp} index={++index} />
      ))}
    </Grid>
  )
}

export default MpCard
