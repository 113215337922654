import { Typography } from '@mui/material'

const convertToHtml = (description, padding) => {
  var output = description.replace(/(<\/?(?:a)[^>]*>)|<[^>]+>/gi, '$1')
  const res = `${output}`
  const outputText = res.replace(/<p><br><\/p>/g, '')
  const inHtmlFormat = (
    <Typography
      dangerouslySetInnerHTML={{ __html: outputText }}
      sx={{ paddingLeft: `${padding}px`, fontFamily: 'HK Grotesk SemiBold', fontSize: '20px', color: '#505050' }}
    />
  )
  return inHtmlFormat
}
export default convertToHtml
