import { DialogActions, Button } from '@mui/material'

const DialogButton = ({ onClickForYesButton, onClickForNoButton }) => {
  return (
    <DialogActions sx={{ justifyContent: 'center', mt: 0 }}>
      <Button
        className="button-tr-2"
        sx={{ fontFamily: 'HK Grotesk', mb: 3, height: '35px', mt: 0 }}
        onClick={onClickForYesButton}
      >
        Yes
      </Button>
      <Button
        className="button-tr-citizen-admin"
        sx={{
          fontFamily: 'HK Grotesk',
          mb: 3,
          width: '140px',
          height: '35px',
          fontSize: '13px!important',
          mt: 0,
        }}
        onClick={onClickForNoButton}
      >
        No
      </Button>
    </DialogActions>
  )
}

export default DialogButton
