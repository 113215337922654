import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import SettingsBreadCrumb from 'Components/Settings/SettingsBreadCrumb'
import SetAlert from 'Components/SendNotification/SetAlert'
import './SendNotifications.css'

const SendNotification = () => {
  const [isChange, setIsChange] = useState(false)
  return (
    <Box className="page-wrapper d-flex seva-initiatives-outer-container">
      <SideMenu activeTab={'Settings'} settingsTab={isChange} />
      <Box className="main-wrapper customscroll w-100 p-5">
        <Typography component="h1" className="seva-initiatives-title-text settings-header">
          Send Notifications
        </Typography>
        <SettingsBreadCrumb selectedOption="Send Notifications" />
        <SetAlert setIsChange={setIsChange} />
      </Box>
    </Box>
  )
}

export default SendNotification
