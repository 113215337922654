import React, { useState, useRef, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import {
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Card,
  CardContent,
  IconButton,
  Paper,
  CardMedia,
  Typography,
  Avatar,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import UploadIcon from '@mui/icons-material/Upload'
import CancelIcon from '@mui/icons-material/Cancel'
import CrossIcon from 'asserts/images/Close.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getDevelopmentCompletionStatusList } from 'store/action/developmentProjectList'
import { postCreateDevelopmentProject } from 'store/action/createDevelopmentProject'
import Moment from 'moment'
import { getDevelopmentProjectsListByMpId, getDevelopmentProjectsList } from 'store/action/developmentProjectList'
import { useLoading } from 'utils/LoadingContext'
import { useNotificationContext } from 'utils/NotificationContext'
import closeIconp from 'asserts/images/close.png'
import Constant, { allowedImageFormat, acceptedImageFormat, defaultErrorMessage } from 'utils/constant'
import { getIds } from 'Components/ReusableComponents.js/getIds'
import DeleteDevelopmentProjectsDialog from 'Components/SevaUpdateRefactored/AddDevelopmentProjects/DeleteDevelopmentProjectsDialog'
import {
  handleFileName,
  onPdfFileUpload,
  validateNotEmpty,
  getVideoFileDuration,
} from 'Components/ReusableComponents.js/reuseMethods'
import { uploadMedia, uploadFile } from 'utils/mediaUpload'
import { makeLogRequest } from 'utils/AuditLogs'
import { pushTag } from 'utils/gtmservice'
import PdfCard from 'Components/ReusableComponents.js/PdfCard'
import AddPdfComponent from 'Components/ReusableComponents.js/AddPdfComponent'
import VideoTrimDialog from 'Components/ReusableComponents.js/VideoTrimDialog/VideoTrimDialog'
import { getFramesFromVideo, getTrimmedVideo } from 'utils/VideoTrimService'
import './AddDevelopmentProjects.css'

const AddDevelopmentProjects = ({
  openDevelopmentProjects,
  handleCloseDevelopmentProjects,
  details,
  isDevelopmentProjectsEdit,
  handleCloseInitiativeDetails,
  mpId,
  developmentProjectsByMpId,
  setCheckIsUpdate,
  limit = 10,
  tenure,
}) => {
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const [targetDate, setTargetDate] = useState(null)
  const [files, setFiles] = useState([])
  const [uploadFiles, setUploadFiles] = useState([])
  const [checkStatus, setCheckStatus] = useState(false)
  const [checkUpdateChanges, setCheckUpdateChanges] = useState(true)
  const [videoData, setVideoData] = useState(null)
  const [openVideoTrimDialog, setOpenVideoTrimDialog] = useState(false)

  const dispatch = useDispatch()
  const developmentStatusList = useSelector((state) => state?.developmentCompletionStatusList?.data)
  const hiddenFileInput = useRef(null)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm()
  const [status, setStatus] = useState(developmentStatusList && developmentStatusList[0]?.status)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [deleteClick, setDeleteClick] = useState(false)
  const [checkImages, setCheckImages] = useState(false)
  const [checkDescError, setCheckDescError] = useState(false)
  const [checkDescValue, setCheckDescValue] = useState(false)
  const [checkDescChanges, setCheckDescChanges] = useState(false)
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const fileFormats = ['image/png', 'image/jpg']

  const postAuditLogs = async (sqId, id) => {
    await makeLogRequest('my_development_projects_edit_popup', sqId, {
      useridtype: getIds(),
      dp_id: id && id,
    })
  }

  const checkTextField = (value, maxLength, minLength, register_name, fieldName) => {
    if (value.length == 0) {
      setError(`${register_name}`, {
        type: 'value',
        message: `Please enter ${fieldName}`,
      })
    } else if (value.length > 0 && value.length < minLength) {
      setError(`${register_name}`, {
        type: 'minLength',
        message: `Minimum character length is ${minLength}`,
      })
    } else if (value.length == maxLength) {
      setError(`${register_name}`, {
        type: 'maxLength',
        message: 'Maximum limit reached',
      })
    } else {
      clearErrors(`${register_name}`)
    }
  }

  const setProjectTitle = (e) => {
    let inputValue = e.target.value?.trimStart()
    inputValue = inputValue.replace(/\s{2,}/g, ' ')
    checkTextField(inputValue, 300, 10, 'projectTitle', 'project title')
    setValue('projectTitle', inputValue)
    setCheckUpdateChanges(false)
  }

  useEffect(() => {
    //call api to update store
    if (!details?.detailsPdf?.[0]) {
      setDetailPdf([])
    }
    dispatch(getDevelopmentCompletionStatusList())
    if (isDevelopmentProjectsEdit) {
      postAuditLogs('pageload', details?.id)
      sendGALog('edit')
    }
  }, [])

  useEffect(() => {
    setStatus(developmentStatusList && developmentStatusList[0]?.status)
  }, [developmentStatusList])

  useEffect(() => {
    if (details) {
      setFiles(details?.media && JSON.parse(details?.media))
      setTargetDate(details?.target)
    }
  }, [details])

  useEffect(() => {
    if (deleteClick) {
      handleCloseDevelopmentProjects(reset, setFiles, setUploadFiles, setTargetDate)
    }
  }, [deleteClick])

  useEffect(() => {
    const parameters = {
      useridtype: getIds(),
    }
    sendCreateDevelopmentProjectLogs('pageload', parameters)
    sendGALog('pop_up')
  }, [])

  const PdfEventFileInput = useRef(null)

  const [detailPdf, setDetailPdf] = useState(
    (typeof details?.detailsPdf == 'string' && details?.detailsPdf !== '[]') ||
      (typeof details?.detailsPdf == 'object' && details?.detailsPdf?.[0])
      ? { name: handleFileName(details?.detailsPdf) }
      : null
  )

  const handlePdfClick = (event) => {
    PdfEventFileInput.current.value = ''

    PdfEventFileInput.current?.click()
    event?.stopPropagation()
    PdfEventFileInput.current.addEventListener('change', () => {
      if (PdfEventFileInput.current.value === '') {
        setCheckUpdateChanges(true)
      } else {
        setCheckUpdateChanges(false)
      }
    })
  }

  const sendCloseDialogLog = () => {
    const parameters = {
      useridtype: getIds(),
    }
    sendCreateDevelopmentProjectLogs('cancel', parameters)
    sendGALog('back')
  }

  const sendCreateDevelopmentProjectLogs = async (type, parameters) => {
    await makeLogRequest('create_development_project_popup', type, parameters)
  }

  const sendGALog = (event) => {
    const page_title = 'create_dev_project_popup'
    const payload = {
      event,
      page_title,
    }
    pushTag(payload)
  }

  const handleStatusChange = (event) => {
    setStatus(event.target.value)

    setCheckUpdateChanges(false)
    if (errors.status) {
      errors.status.message = null
      setCheckStatus(true)
    }
  }

  const processFile = async (file) => {
    const reader = new FileReader()
    const readerPromise = new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve({
          type: file.type.startsWith('image') ? 'image' : 'video',
          url: reader.result,
          file: file,
        })
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })

    try {
      const result = await readerPromise // Wait for the FileReader to complete
      return result
    } catch (error) {
      console.error('Error reading file:', error)
      throw error
    }
  }

  const handleImageChange = async (e) => {
    const parameters = {
      useridtype: getIds(),
    }
    sendCreateDevelopmentProjectLogs('add_more_images', parameters)
    let uploadedFiles = Array.from(e.target.files)
    let processedImages = 0
    let imgFiles = uploadedFiles.filter((x) => x.type.includes('image'))

    if (uploadedFiles.some((x) => !x.type.includes('image') && !x.type.includes('video'))) {
      showNotification('Error', 'Please upload media in JPG, PNG or MP4 format only', 'error')
    }

    for (let i = 0; i < imgFiles.length; i++) {
      const isDuplicate = uploadFiles.some((file) => typeof file != 'string' && file.file.name === imgFiles[i].name)
      if (isDuplicate) {
        showNotification('Error', 'This image is already uploaded.', 'error')
        imgFiles.splice(i, 1)
        i--
        continue
      }
      const isRightFormat = allowedImageFormat.includes(imgFiles[i].type)
      const fileSizeInBytes = imgFiles[i].size
      const maxSizeInBytes = 20 * 1024 * 1024 // 20MB
      if (!isRightFormat) {
        showNotification('Error', 'Please upload image in JPG or PNG format only', 'error')
        e.target.value = null
        return
      }
      if (fileSizeInBytes > maxSizeInBytes) {
        showNotification('Error', 'Please upload image with size less than 20MB', 'error')
        e.target.value = null
        return
      }
      setCheckUpdateChanges(false)
      processedImages++
    }
    const processingPromises = imgFiles.map((file) => processFile(file))
    await Promise.all(processingPromises)
      .then((newFiles) => {
        setUploadFiles((prevFiles) => [...prevFiles, ...newFiles])
        if (processedImages === imgFiles.length) {
          setFiles([...files, ...newFiles])
        }
      })
      .catch((error) => {
        console.error('Error processing files:', error)
      })
    handleVideoFilesUpload(uploadedFiles)
  }

  const uploadTrimmedVideo = (video) => {
    setUploadFiles((prevFiles) => [...prevFiles, video])
    setFiles([...files, video])
  }

  const handleVideoFilesUpload = (uploadedFiles) => {
    let videoFiles = uploadedFiles.filter((x) => x.type.includes('video'))
    if (!videoFiles || videoFiles?.length < 1) return
    let maxAllowedVideoSize = 100 * 1024 * 1024 // 100MB
    let maxAllowedVideoDuration = 10 * 60 // 10 mins
    let videoFilesAdded =
      files?.some((x) => typeof x === 'string' && x.endsWith('.mp4')) || uploadFiles?.some((x) => x.type === 'video')
    if (videoFilesAdded || videoFiles?.length > 1) {
      showNotification('Error', 'Only one video file is allowed', 'error')
    } else if (videoFiles[0]?.type !== 'video/mp4') {
      showNotification('Error', 'Please upload video in MP4 format only', 'error')
    } else if (videoFiles[0]?.size > maxAllowedVideoSize) {
      showNotification('Error', 'Please upload video with size less than 100MB', 'error')
    } else if (!videoFilesAdded && videoFiles?.length > 0) {
      getVideoFileDuration(videoFiles[0], async (duration) => {
        if (duration > maxAllowedVideoDuration) {
          showNotification('Error', 'Please upload a video with duration less than 10 minutes', 'error')
        } else {
          setCheckUpdateChanges(false)
          await handleOpenTrimDialog(duration, videoFiles)
        }
      })
    }
  }

  const handleOpenTrimDialog = async (duration, videoFiles) => {
    try {
      setLoading(true)
      let videoFilesObject = {
        videoFiles: [...videoFiles],
      }
      let vidsURLObj = await uploadFile(videoFilesObject, 'media.mp4')
      let videoUrl = vidsURLObj?.videoUrls[0]
      let framesResponse = await getFramesFromVideo({
        videoUrl,
        thumbnailCount: 7,
      })
      setVideoData({
        videoUrl,
        duration,
        frames: framesResponse.data.slice(0, 7),
      })
      setOpenVideoTrimDialog(true)
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = (index) => {
    hiddenFileInput.current.value = ''
    let elementToRemove = files[index]
    const tempImages = [...files]
    tempImages.splice(index, 1)
    setFiles(tempImages)
    let uploadIndex = uploadFiles.findIndex((ele) => ele === elementToRemove)
    const tempFiles = [...uploadFiles]
    if (uploadIndex > -1) {
      tempFiles.splice(uploadIndex, 1)
    }
    setUploadFiles(tempFiles)
    setCheckUpdateChanges(false)
  }

  const addImagesToFormData = async (existingImageUrls) => {
    const imageFiles = []
    const videoFiles = []
    for (let i = 0; i < files.length; i++) {
      try {
        if (typeof files[i] === 'string' && files[i].toLowerCase().startsWith('https')) {
          existingImageUrls.push({ media: files[i] })
        } else if (files[i].type === 'image') {
          imageFiles.push(files[i].file)
        } else {
          videoFiles.push(files[i].file)
        }
      } catch (error) {
        existingImageUrls.push({ media: files[i] })
      }
    }
    return { imageFiles, videoFiles }
  }

  const handleClickOpenDeleteDialog = () => {
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false)

  const handlePreview = (file) => {
    if (file.type === 'image') {
      return (
        <img
          src={typeof file === 'string' ? file : file.url}
          alt="Preview"
          className="imageupload form-img__img-preview-4"
          id="imageupload"
          sx={{
            display: 'block',
            width: '150px',
            height: '150px',
            objectFit: 'cover',
            borderRadius: '20px',
          }}
        />
      )
    }
    if (file.type === 'video') {
      return (
        <video
          controls
          disablePictureInPicture
          controlsList="nodownload noplaybackrate"
          className="imageupload form-img__img-preview-4"
          id="imageupload"
        >
          <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }
    const images = ['jpg', 'gif', 'png', 'svg', 'jfif', 'jpeg']
    const videos = ['mp4', '3gp', 'ogg']
    const url = new URL(file)
    const extension = url.search.split('.')[1]
    const pathExtension = url?.pathname?.slice(url.pathname.lastIndexOf('.') + 1, url.pathname.length) || ''
    if (images.includes(extension?.toLowerCase()) || images.includes(pathExtension?.toLowerCase())) {
      return <img src={file} alt="Preview" className="imageupload form-img__img-preview-ed1" id="imageupload" />
    }
    if (videos.includes(extension?.toLowerCase()) || videos.includes(pathExtension?.toLowerCase())) {
      return (
        <Box>
          {/* <img src={playicon} onClick={handleHideImage} /> */}
          <video
            controls
            disablePictureInPicture
            controlsList="nodownload noplaybackrate"
            className="imageupload form-img__img-preview-ed1"
            id="imageupload"
          >
            <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      )
    }
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const onAddDevelopmentProject = async (data) => {
    if (files?.length === 0) return
    setLoading(true)
    const loggedInId = getIds()
    const tkn = localStorage.getItem('tokenDetails')
    const existingImageUrls = []
    const { imageFiles } = await addImagesToFormData(existingImageUrls)

    // Array.from(uploadFiles).forEach((file, i) => formData.append(`media`, file, file.name));
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tkn}`,
      },
    }

    const imagesObj = await uploadMedia(config, { imageFiles: imageFiles }, true)
    let stepUrl = detailPdf ? detailPdf : {}
    if (detailPdf?.size) {
      stepUrl = await uploadMedia(dispatch, { stepPdf: detailPdf })
    } else if (detailPdf) stepUrl.stepPdfUrl = details?.detailsPdf
    else stepUrl.stepPdfUrl = []

    const payloadObj = {
      projecttitle: data?.projectTitle,
      target: Moment(targetDate).format('YYYY-MM-DD'),
      status: data?.status,
      desc: data?.desc,
      mpmodelId: loggedInId,
      media: [...imagesObj?.imageUrls, ...existingImageUrls],
      detailsPdf: stepUrl?.stepPdfUrl || [],
    }

    const id = details?.id ? details?.id : 0
    const parameters = {
      useridtype: getIds(),
      dp_id: id,
    }
    sendCreateDevelopmentProjectLogs('submit', parameters)
    sendGALog('proceed')
    try {
      //   if (files?.length === 0) {
      //     showNotification("Error", "Please add image");
      //     return;
      //   } else {
      const response = await dispatch(postCreateDevelopmentProject(id, payloadObj, config))
      if (response.status === 200 || response.status === 201) {
        showNotification('Success', response.data.message, 'success')
        handleCloseDevelopmentProjects(reset, setFiles, setUploadFiles, setTargetDate)
        if (details) {
          handleCloseInitiativeDetails({ updated: true })
        }
        const payload = {
          id: 0,
          limit,
          isPrevTenure: tenure === 'Modi 2.0' ? true : false,
        }
        if (developmentProjectsByMpId) {
          payload.id = mpId
          dispatch(getDevelopmentProjectsListByMpId(payload))
        } else {
          dispatch(getDevelopmentProjectsList(payload))
        }
        // Object.keys(data).map(val => resetField(val));
        // setFiles([]);
        // setUploadFiles([]);
      }
      //   }
    } catch (error) {
      showNotification('Error', error?.message || defaultErrorMessage)
    } finally {
      setLoading(false)
      if (typeof setCheckIsUpdate === 'function') {
        setCheckIsUpdate(true)
      }
    }
    // dispatch(postCreateDevelopmentProject(0, formData, config));
  }

  const dateConvert = (dateStr) => {
    const date = new Date(dateStr)
    let month = date.getMonth() + 1
    let day = date.getDate()
    let year = date.getFullYear()
    const formattedDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`
    return formattedDate
  }

  return (
    <>
      <Dialog
        open={openDevelopmentProjects}
        onClose={() => (
          handleCloseDevelopmentProjects(reset, setFiles, setUploadFiles, setTargetDate),
          isDevelopmentProjectsEdit && postAuditLogs('close')
        )}
        sx={{ height: '96vh', mt: 2.5, overflowX: 'hidden' }}
      >
        <DialogTitle
        // sx={{
        //     backgroundColor: "#003f93",
        //     color: "#ffffff",
        //     fontSize: "16px",
        // }}
        >
          <Box className="d-flex justify-content-center">
            <Box>
              <Typography
                sx={{
                  fontFamily: 'HK Grotesk',
                  fontSize: '26px',
                  fontWeight: 'bold',
                  color: '#357092',
                }}
              >
                Development Project
              </Typography>
            </Box>
            <Avatar
              onClick={() => {
                sendCloseDialogLog()
                handleCloseDevelopmentProjects(reset, setFiles, setUploadFiles, setTargetDate)
                isDevelopmentProjectsEdit && postAuditLogs('close')
              }}
              src={CrossIcon}
              className="closeIcon"
              sx={{
                height: '30px',
                width: '30px',
                position: 'absolute',
              }}
            />
          </Box>
        </DialogTitle>
        <Grid container className="bg-white" sx={{ overflowX: 'hidden' }}>
          <Grid item xs={12} md={12}>
            <Grid item xs={12} md={12} sx={{ p: 4 }}>
              <Box>
                <form>
                  <Grid container spacing={1} justifyContent="left" alignItems="center">
                    <Grid container sx={{ mb: 2 }}>
                      <Grid item xs={12} sx={{ pr: 1 }}>
                        <FormControl
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderRadius: '14px',
                            },
                          }}
                        >
                          <Typography className="adddevelopment-project-textfield-label">Project Title</Typography>

                          <TextField
                            className="stepperFormInput"
                            // label="Title"
                            name="title"
                            fullWidth
                            placeholder="Enter title"
                            size="small"
                            required
                            defaultValue={details && details?.projecttitle}
                            autoComplete="off"
                            inputProps={{ maxLength: 300, minLength: 10 }}
                            {...register('projectTitle', {
                              required: 'Please enter project title',
                              maxLength: {
                                value: 300,
                                message: 'Max length 300',
                              },
                              minLength: {
                                value: 10,
                                message: 'Minimum character length is 10',
                              },
                              validate: (value) => validateNotEmpty(value, 'project title'),
                            })}
                            //   error={Boolean(errors?.employeeId?.message)}
                            //   helperText={errors?.employeeId?.message}
                            onChange={setProjectTitle}
                          />
                        </FormControl>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {errors && errors?.projectTitle?.message}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mb: 2, columnGap: '15px', flexWrap: 'nowrap' }}>
                      <Grid item xs={6} sx={{ pr: 1 }}>
                        <Typography className="adddevelopment-project-textfield-label">Target Date</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            // label="End Date"
                            components={{
                              OpenPickerIcon: CalendarMonthIcon,
                            }}
                            InputProps={{
                              sx: {
                                '& .MuiSvgIcon-root': {
                                  color: '#fff',
                                  background: '#356f92',
                                  width: '35px',
                                  height: '35px',
                                  padding: '5px',
                                  borderRadius: '5px',
                                },
                              },
                            }}
                            // label="Target Date"
                            // sx={{width: '400px'}}
                            inputFormat="DD/MM/YYYY"
                            value={targetDate}
                            onChange={(newValue) => {
                              setTargetDate(dateConvert(newValue))
                              setCheckUpdateChanges(false)
                            }}
                            minDate={new Date('2014-05-01')}
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  '& .MuiInputBase-input': {
                                    width: '270px', // Set your height here.
                                  },
                                }}
                                {...params}
                                className="bdrdate"
                                defaultValue={details && details?.target}
                                {...register('targetDate', {
                                  required: !targetDate ? 'Please choose target date' : false,
                                })}
                                onKeyDown={(e) => {
                                  e.preventDefault()
                                }}
                                error={!targetDate && Boolean(errors?.targetDate?.message)}
                                helperText={!targetDate && errors?.targetDate?.message}
                                autoComplete="off"
                              />
                            )}
                          />
                        </LocalizationProvider>
                        <InputLabel className="start-date-tooltip">
                          Target date must be 1st May 2014 or later.
                        </InputLabel>
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <Typography className="adddevelopment-project-textfield-label">Completion Status</Typography>

                        <FormControl
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderRadius: '14px',
                            },
                          }}
                        >
                          <InputLabel id="demo-simple-select-label" sx={{ marginTop: '-6px' }}></InputLabel>
                          <Select
                            className="stepperFormInput"
                            // label="Completion Status"
                            name="status"
                            fullWidth
                            required
                            defaultValue={details?.status}
                            size="small"
                            autoComplete="off"
                            sx={{ height: '56px' }}
                            {...register('status', {
                              required: 'Please select status',
                            })}
                            SelectDisplayProps={{
                              renderValue: (defaultValue) => defaultValue,
                            }}
                            // value={details ? details?.status : status}
                            onChange={handleStatusChange}
                            // error={errors?.roleId}
                            // helperText={errors?.roleId?.message}
                            // onChange={() => setIsUpdateButton(false)}
                          >
                            {developmentStatusList &&
                              developmentStatusList.map((s) => {
                                return (
                                  <MenuItem native key={s.status} sx={{ width: '100%' }} value={s.status} size="small">
                                    {s.status}
                                  </MenuItem>
                                )
                              })}
                          </Select>
                          <FormHelperText sx={{ color: '#d32f2f' }}>
                            {errors?.status?.message !== null && !checkStatus && errors?.status?.message}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sx={{ pr: 1 }}>
                        <FormControl
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderRadius: '14px',
                            },
                          }}
                        >
                          <Typography className="adddevelopment-project-textfield-label">About Project</Typography>
                          <TextField
                            className="stepperFormInput"
                            // label="About Project"
                            name="about"
                            placeholder="About Project"
                            fullWidth
                            required
                            multiline
                            rows={3}
                            size="small"
                            defaultValue={details && details?.desc}
                            inputProps={{ maxLength: 5000, minLength: 10, wrap: 'soft' }}
                            autoComplete="off"
                            {...register('desc', {
                              required: 'Please enter description',
                              maxLength: {
                                value: 5000,
                                message: 'Maximum 5000 characters allowed',
                              },
                              minLength: {
                                value: 10,
                                message: 'Minimum character length is 10',
                              },
                              validate: (value) => validateNotEmpty(value, 'description'),
                            })}
                            onChange={(e) => {
                              let inputValue = e.target.value?.trimStart()
                              inputValue = inputValue.replace(/\s{2,}/g, ' ')
                              checkTextField(inputValue, 5000, 10, 'desc', 'description')
                              setValue('desc', inputValue)
                              setCheckUpdateChanges(false)
                              if (e.target.value !== '') {
                                setCheckDescValue(true)
                              } else {
                                setCheckDescValue(false)
                              }
                              if (e.target.value.replaceAll('\n', '').length > 5000) {
                                setCheckDescValue(false)
                                setCheckDescError(true)
                                setCheckDescChanges(true)
                              } else {
                                setCheckDescError(false)
                                setCheckDescChanges(false)
                              }
                              setCheckUpdateChanges(false)
                            }}
                            onPaste={(e) => {
                              const pastedText = e.clipboardData.getData('text')

                              if (
                                pastedText.replace(/[\r\n]/gm, '').length +
                                  e.target.value.replace(/[\r\n]/gm, '').length >
                                5000
                              ) {
                                e.preventDefault()
                                const truncatedText = pastedText.substring(0, 5000 - e.target.value.length)
                                document.execCommand('insertText', false, truncatedText)
                                setCheckDescError(true)
                              }
                            }}

                            //   error={Boolean(errors?.mobileNumber)}
                            //   helperText={errors?.mobileNumber?.message}
                            //   onChange={() => setIsUpdateButton(false)}
                          />
                        </FormControl>
                        <FormHelperText
                          sx={{
                            color: errors && errors?.desc?.message ? '#d32f2f !important' : 'blue !important',
                          }}
                        >
                          {errors && errors?.desc?.message
                            ? errors && errors?.desc?.message
                            : checkDescError || checkDescValue
                            ? 'Maximum 5000 characters allowed'
                            : errors?.desc?.message}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                      <Typography className="adddevelopment-project-textfield-label">Attach Media</Typography>
                      <Box className="contpopup1 attach-media-container">
                        {/* {files?.length > 0 ? ( */}
                        <Box className="postionabs-2 itemfixed-update display-attach-media-container">
                          {files?.length > 1 ? (
                            <Tabs
                              // value={value}
                              // onChange={handleChange}
                              variant="scrollable"
                              scrollButtons
                              aria-label="visible arrows tabs example"
                              sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                  '&.Mui-disabled': { opacity: 0.3 },
                                },
                              }}
                            >
                              {files?.map((file, index) => (
                                <Card
                                  sx={{
                                    minWidth: 200,
                                    borderRadius: 0,
                                    boxShadow: 'none',
                                  }}
                                  className="form-img__img-preview-4"
                                >
                                  <CardMedia className="position-relative">
                                    {/* <img width="38.49" src={first} className="position-absolute" alt="" /> */}
                                    {/* <img key={index} src={image.url} alt="" className="form-img__img-preview" /> */}
                                    <Box key={index}>{handlePreview(file)}</Box>
                                    <Avatar
                                      variant="circular"
                                      src={closeIconp}
                                      onClick={() => handleDelete(index)}
                                      className="imageclose-2"
                                      sx={{
                                        width: '22px',
                                        height: '22px',
                                        padding: '5px',
                                      }}
                                    />
                                    {/* <Button onClick={() => handleDelete(index)}>delete</Button> */}
                                    {/* <IconButton
                                                                        className="card__icon" sx={{ position: "absolute", right: "5vh" }}
                                                                        onClick={() => handleRemove(file.id)}
                                                                    >
                                                                        <CancelIcon role="button"></CancelIcon>
                                                                    </IconButton> */}
                                  </CardMedia>
                                </Card>
                              ))}
                            </Tabs>
                          ) : (
                            files?.map((file, index) => (
                              <Card
                                sx={{
                                  minWidth: 200,
                                  borderRadius: 0,
                                  boxShadow: 'none',
                                }}
                                className="form-img__img-preview-4 "
                              >
                                <CardMedia>
                                  {/* <img width="38.49" src={first} className="position-absolute" alt="" /> */}
                                  {/* <img key={index} src={image.url} alt="" className="form-img__img-preview" /> */}
                                  <Box key={index}>{handlePreview(file)}</Box>
                                  {/* <Button onClick={() => handleDelete(index)}>delete</Button> */}
                                  <Avatar
                                    variant="circular"
                                    src={closeIconp}
                                    onClick={() => handleDelete(index)}
                                    className="imageclose-2"
                                    sx={{
                                      width: '22px',
                                      height: '22px',
                                      padding: '5px',
                                    }}
                                  />
                                </CardMedia>
                              </Card>
                            ))
                          )}
                        </Box>
                        <Grid item xs={6}>
                          <input
                            type="file"
                            ref={hiddenFileInput}
                            className="d-none"
                            multiple
                            onChange={handleImageChange}
                            accept={`${acceptedImageFormat}, video/mp4`}
                            onClick={(event) => {
                              event.target.value = null
                            }}
                          />
                          <Box
                            sx={{
                              // ml: "-95px",
                              display: 'flex',
                              '& > :not(style)': {
                                // ml: "-8px",
                                width: 150,
                                height: 140,
                              },
                            }}
                          >
                            <Paper
                              variant="outlined"
                              square
                              sx={{
                                border: 'dotted 3px #1976d2',
                                padding: '50px',
                                width: '40%',
                                borderRadius: '14px',
                              }}
                            >
                              <IconButton
                                color="primary"
                                aria-label="Upload"
                                onClick={() => (
                                  handleClick(), isDevelopmentProjectsEdit && postAuditLogs('add_more_images')
                                )}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  position: 'relative',
                                  top: '15%',
                                  margin: '0 auto',
                                }}
                              >
                                <UploadIcon />
                              </IconButton>
                              <Typography className="add-image-text">
                                {' '}
                                {files?.length > 0 ? 'Add More Media' : 'Add Media'}
                              </Typography>
                              <FormHelperText
                                sx={{
                                  color: '#d32f2f',
                                  width: 'max-content',
                                  marginTop: '28px',
                                  marginLeft: '-47px!important',
                                }}
                              >
                                {checkImages && files.length === 0 && 'Please upload media'}
                              </FormHelperText>
                            </Paper>
                          </Box>
                        </Grid>
                        {/* <h6>Event Images</h6>
                                                <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} multiple onChange={handleImageChange} />
                                                <div>
                                                    {files.map((image, index) => (
                                                        <img key={index} src={image.url} alt="" className="form-img__img-preview" />
                                                    ))}
                                                </div> */}

                        {/* ) : null} */}
                      </Box>
                    </Grid>

                    {/* <input type="file" ref={hiddenFileInput} onChange={handleVideoChange} multiple accept="video/*" />
                                        // <button onClick={handleClick}>Upload Videos</button> */}
                    {/* {videos.map((video, index) => */}
                    {/* <Card sx={{ minWidth: 100, mr: 2 }} className="photo-size"> */}
                    {/* <CardContent> */}
                    {/* <img width="38.49" src={first} className="position-absolute" alt="" /> */}
                    {/* <video key={index} src={video.url} alt="" className="form-img__img-preview" /> */}
                    {/* </CardContent> */}
                    {/* </Card> */}
                    {/* // )} */}
                  </Grid>
                  <Grid item xs={12}>
                    {detailPdf?.name ? (
                      <>
                        <Typography
                          className="pdfUploadClass"
                          sx={{
                            marginTop: '20px',
                            fontWeight: 'bold',
                            fontFamily: 'HK Grotesk',
                          }}
                        >
                          Development Project PDF :
                        </Typography>
                        <PdfCard
                          url={detailPdf?.size ? detailPdf : details?.detailsPdf}
                          name={detailPdf.name}
                          detailPdf={setDetailPdf}
                          editable={true}
                          checkUpdate={setCheckUpdateChanges}
                        />
                      </>
                    ) : (
                      <>
                        <input
                          ref={PdfEventFileInput}
                          className="d-none"
                          type="file"
                          accept=".pdf"
                          onChange={(e) => {
                            onPdfFileUpload(e, setDetailPdf, showNotification)
                          }}
                        />
                        <AddPdfComponent
                          onClick={(e) => handlePdfClick(e)}
                          left="274px"
                          text="Add a PDF as your Development Project"
                        />
                      </>
                    )}
                  </Grid>
                </form>
              </Box>
            </Grid>
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row', p: 2, pl: 30 }}>
                {isDevelopmentProjectsEdit ? (
                  <Button
                    variant="contained"
                    sx={{ p: 1, mr: 1, backgroundColor: '#ef7335' }}
                    className={`button-tr-2-13-disa ${checkDescError || checkDescChanges ? 'button-tr-2-1' : ''}`}
                    onClick={handleSubmit(onAddDevelopmentProject)}
                    onMouseUp={() => (
                      setCheckImages(true),
                      setCheckDescValue(false),
                      isDevelopmentProjectsEdit && postAuditLogs('update')
                    )}
                    disabled={checkUpdateChanges}
                  >
                    Update
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ p: 1, mr: 1, backgroundColor: '#ef7335' }}
                    className={`button-tr-2-13-disa ${checkDescError || checkDescChanges ? 'button-tr-2-1' : ''}`}
                    onClick={handleSubmit(onAddDevelopmentProject)}
                    onMouseUp={() => (
                      setCheckImages(true),
                      setCheckDescValue(false),
                      isDevelopmentProjectsEdit && postAuditLogs('update')
                    )}
                    disabled={checkDescChanges && true}
                  >
                    Submit
                  </Button>
                )}
                {isDevelopmentProjectsEdit && (
                  <Button
                    variant="outlined"
                    onClick={() => (handleClickOpenDeleteDialog(), postAuditLogs('delete'))}
                    sx={{ p: 1, mr: 1 }}
                    className="button-tr-2-1 "
                  >
                    Delete
                  </Button>
                )}
                {!isDevelopmentProjectsEdit && (
                  <Button
                    variant="outlined"
                    onClick={() => handleCloseDevelopmentProjects(reset, setFiles, setUploadFiles, setTargetDate)}
                    sx={{ p: 1, mr: 1 }}
                    className="button-tr-2-1 "
                  >
                    Cancel
                  </Button>
                )}
                <Box sx={{ flex: '1 1 auto' }} />
              </Box>
            </React.Fragment>
            {openDeleteDialog && (
              <DeleteDevelopmentProjectsDialog
                openDeleteDialog={openDeleteDialog}
                handleCloseDeleteDialog={handleCloseDeleteDialog}
                developmentId={details?.id}
                mpId={mpProfileData?.id}
                handleCloseDevelopmentProjects={handleCloseDevelopmentProjects}
                developmentProjectsByMpId={developmentProjectsByMpId}
                handleCloseInitiativeDetails={handleCloseInitiativeDetails}
                details={details}
                setDeleteClick={setDeleteClick}
                isDevelopmentProjectsEdit={isDevelopmentProjectsEdit}
                limit={limit}
                tenure={tenure}
              />
            )}
            {openVideoTrimDialog && (
              <VideoTrimDialog
                openVideoTrimDialog={openVideoTrimDialog}
                onUpload={uploadTrimmedVideo}
                onCancel={setOpenVideoTrimDialog}
                videoData={videoData}
              />
            )}
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default AddDevelopmentProjects
