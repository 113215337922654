import { Box, TextField, InputAdornment, Avatar } from '@mui/material'
import { useEffect, useState, useRef } from 'react'
import Search from '../Search/Search'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { pushTag } from '../../../utils/gtmservice'
import { getIds, getRoles } from '../../ReusableComponents.js/getIds'
import searchIcon from '../../../asserts/images/Search.svg'
import './SearchBar.css'
import { browserName } from 'react-device-detect'

const SearchBar = ({ isMarqueeVisible, handleScroll }) => {
  const inputRef = useRef(null)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const userId = parseInt(getRoles())

  useEffect(() => {
    if (!isSearchOpen) {
      inputRef.current.blur()
    }
  }, [isSearchOpen])

  const sendGALog = (event, message) => {
    pushTag({
      event: event,
      page_title: 'leaderboard_page',
      message: message,
    })
  }

  const sendSearchLog = async () => {
    const parameters = {
      useridtype: getIds(),
    }
    await makeLogRequest('mp_leaderboard', 'search', parameters)
    sendGALog('select_option', 'search')
  }

  return (
    <Box className="mg-15">
      <TextField
        id="leaderboard-search-input"
        className="search-filter-icon cursorshow"
        sx={{
          width: '100% !important',
          '& fieldset': { border: 'none' },
        }}
        onClick={(e) => {
          setIsSearchOpen(true)
          handleScroll()
        }}
        inputRef={inputRef}
        onFocus={(e) => {
          sendSearchLog()
        }}
        placeholder="Search MP / Constituency"
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Avatar
                variant="square"
                className="search-icon-mphome cursorshow"
                alt="Search"
                src={searchIcon}
                onClick={() => {
                  setIsSearchOpen(true)
                }}
              />
            </InputAdornment>
          ),
        }}
      >
        {' '}
      </TextField>
      {isSearchOpen && (
        <Search
          marginRightValue={
            userId === 3 || userId === 2
              ? browserName === 'Firefox'
                ? '40'
                : '30'
              : browserName === 'Firefox'
              ? '26'
              : '15'
          }
          marginTopValue={isMarqueeVisible ? '100' : '18'}
          inputValue={isSearchOpen}
          clearParentTextField={setIsSearchOpen}
        />
      )}
    </Box>
  )
}

export default SearchBar
