import { INITIATIVE_LIST_FAILURE, INITIATIVE_LIST_SUCCESS, INITIATIVE_LIST_REQUEST } from './types';
import axiosInstance from '../../utils/api';

export const getMp = () => {
  return {
    type: INITIATIVE_LIST_REQUEST,
  };
};
export const getInitiativeListSuccess = (value) => {
  return {
    type: INITIATIVE_LIST_SUCCESS,
    payload: value,
  };
};
export const getInitiativeListFailure = (error) => {
  return {
    type: INITIATIVE_LIST_FAILURE,
    payload: error,
  };
};

const getInitiativeList = (search) => async (dispatch) => {
  dispatch(getMp);
  let url = search ? `/api/search/intiative?searchText=${search}` : `/api/initiative/getall/0`;
  await axiosInstance
    .get(url)
    .then((response) => {
      const result = search ? response.data : response.data;
      dispatch(getInitiativeListSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(getInitiativeListFailure(errorMsg));
    });
};

export default getInitiativeList;
