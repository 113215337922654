import { Box, TextField, InputAdornment } from '@mui/material'
import { useState, useCallback, useMemo } from 'react'
import CrossIcon from '../../../asserts/images/Close.svg'
import searchIcon from '../../../asserts/images/Search.svg'
import { debounce } from 'lodash'
import './index.css'

const MasterListSearchBar = ({ searchText, setSearchText, searchContent }) => {
  const handleSearch = (e) => {
    const searchText = e.target.value
    setSearchText(searchText)
    debouncedSendRequest(searchText)
  }

  const clickSearch = () => {
    searchContent(searchText)
  }

  const sendRequest = useCallback((value) => {
    searchContent(value)
  }, [])

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendRequest, 1000)
  }, [sendRequest])

  // const handleSearchCrossClick = () => {
  //   if (searchText?.length === 0) {
  //     return
  //   }
  //   setSearchText('')
  //   debouncedSendRequest('')
  // }

  return (
    <Box>
      <TextField
        className="search-filter-icon masterlist-searchIcon cursorshow"
        sx={{
          '& fieldset': { border: 'none' },
        }}
        value={searchText}
        inputProps={{ maxLength: 200 }}
        onChange={handleSearch}
        placeholder={`Search `}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <img className="cursorshow" alt="Search" width={20} height={21} src={searchIcon} onClick={clickSearch} />
            </InputAdornment>
          ),
        }}
      ></TextField>
    </Box>
  )
}

export default MasterListSearchBar
