import { ALL_INITIATIVES_FAILURE, ALL_INITIATIVES_REQUEST, ALL_INITIATIVES_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  loading: true,
  error: '',
};

export const allInitiatives = (state = initialState, action) => {
  switch (action.type) {
    case ALL_INITIATIVES_REQUEST:
      return {
        state,
        loading: true,
      };
    case ALL_INITIATIVES_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case ALL_INITIATIVES_FAILURE:
      return {
        data: [],
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
