import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Grid, Typography, Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import InitiativesOverview from 'Components/YourInitiativeRefactor/Initiativesoverview/InitiativesOverview'
import IntiativesDetails from 'Components/YourInitiativeRefactor/IntiativesDetails/IntiativesDetails'
import InitiativeData from 'Components/YourInitiativeRefactor/InitiativeDetailContainer/InitiativeData'
import { getAllInitiative } from 'store/action/allInitiatives'
import { overViewInitiatives } from 'store/action/initiativesOverview'
import { getMpProfile } from 'store/action/individualMP'
import { getIds, getRoles } from 'Components/ReusableComponents.js/getIds'
import { useInitiativeContext } from 'utils/InitiativeContext'
import { makeLogRequest } from 'utils/AuditLogs'
import { pushTag } from 'utils/gtmservice'
import './YourInitiatives.css'

const mpUserId = getIds()
const postAuditLogs = async (sqId, data = {}, flag = 'your_initiatives_page') => {
  data.user_role = getRoles() == 2 ? 'admin' : getRoles() == 3 ? 'leadership' : 'mp'
  data.useridtype = mpUserId
  await makeLogRequest(flag, sqId, data)
}

const YourIntiatives = () => {
  const [details, setDetails] = useState(false)
  const [initDetailsChange, setInitDetailsChange] = useState(false)
  const [data, setData] = useState()
  const dispatch = useDispatch()
  const location = useLocation()
  const loggedInId = getIds()
  const { setResetInitiatives } = useInitiativeContext()
  const [maxHeight, setMaxHeight] = useState(0)
  useEffect(() => {
    function handleResize() {
      // Set the max height based on the user's screen resolution
      const screenHeight = window.innerHeight
      if (screenHeight > 700 && screenHeight < 800) setMaxHeight(`107vh`)
      else if (screenHeight > 600 && screenHeight < 700) setMaxHeight(`125vh`)
      else if (screenHeight > 800 && screenHeight < 900) setMaxHeight(`97vh`)
      else if (screenHeight > 900 && screenHeight < 1000) setMaxHeight(`83vh`)
      else setMaxHeight(`77vh`)
    }
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'your_initiative_page',
    })
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    dispatch(getMpProfile(loggedInId))
    dispatch(getAllInitiative(loggedInId, ''))
    dispatch(overViewInitiatives(loggedInId))
    setResetInitiatives(false)
  }, [initDetailsChange])

  return (
    <Box className="d-flex page-wrapper1">
      <SideMenu activeTab={'Initiatives'} />

      <Box className="intiatives-container main-wrapper center-width customscroll">
        <Typography component="h1" className="header-design header-alligment" sx={{ fontSize: '23px' }}>
          Your Initiatives
        </Typography>
        <Grid container>
          <Grid item xs={6} md={6}>
            <Box className="container1-alligment">
              <InitiativesOverview />
              <InitiativeData data={data} setData={setData} setDetails={setDetails} />
            </Box>
          </Grid>
          {details ? <IntiativesDetails data={data} /> : null}
        </Grid>
      </Box>
    </Box>
  )
}

export default YourIntiatives
