import { CardMedia, ImageList, ImageListItem, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import folder from '../../../asserts/images/sharing/Folder.png'
import { getImageData } from '../../../store/action/image'
import './ItemData.css'

const ItemData = ({ setFolderName, imageResponse }) => {
  //this will be used for sending dynamic initiatives id in query of get Image
  //   const query = useSelector((state) => state?.eventsList?.data[0]);
  const [items, setItems] = useState([])
  const [folderList, setFolderList] = useState([])
  const dispatch = useDispatch()

  const itemDataImages = [
    {
      id: 1,
      title: 'Certificate',
      img: folder,
    },
    {
      id: 2,
      title: 'Outdoor Banners',
      img: folder,
    },
    {
      id: 3,
      title: 'Social Media',
      img: folder,
    },
  ]

  const handleFolders = () => {
    let folders = []
    imageResponse &&
      imageResponse.forEach((data) => {
        if (!folders.includes(data.typeof_image)) {
          folders.push(data.typeof_image)
        }
      })

    setFolderList(folders)
  }

  useEffect(() => {
    // dispatch(getImageData(0));
    handleFolders()
  }, [])

  return (
    <>
      {folderList.length === 0 ? (
        <Typography variant="span" className="no-images-uploaded-by-admin-text">
          No images uploaded by admin
        </Typography>
      ) : (
        <ImageList>
          {folderList &&
            folderList?.map((item) => (
              <ImageListItem
                key={item?.index}
                className="bgfolder cursorshow"
                sx={{ marginRight: '10px' }}
                onClick={() => {
                  setFolderName(item)
                }}
              >
                <CardMedia
                  component="img"
                  className="uploaded-images-folder"
                  key={item?.index}
                  src={`${folder}`} //?w=164&h=164&fit=crop&auto=format
                  srcSet={`${folder}`} //?w=164&h=164&fit=crop&auto=format&dpr=2 2x
                  alt={item}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: 'HK Grotesk',
                    color: '#356F92',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    // ml:"10px",
                    mt: '25px',
                    alignSelf: 'center',
                  }}
                >
                  {item}
                </Typography>
              </ImageListItem>
            ))}
        </ImageList>
      )}
    </>
  )
}

export default ItemData
