import * as React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import Avatar from '@mui/material/Avatar'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import { MpColumnFilter, MpColumnHeaders } from './MpTableData'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useDispatch, useSelector } from 'react-redux'
import { getMpList } from '../../store/action/mpList'
import { useNavigate } from 'react-router-dom'
import { Hidden, Typography } from '@mui/material'
import { useLoading } from '../../utils/LoadingContext'
import { useNotificationContext } from '../../utils/NotificationContext'
import './MpTables.css'
import Constant from '../../utils/constant'
import { getOngoingSevaIntiativesListByMpId } from '../../store/action/ongoingSevaInitiativesList'
import { getIds } from '../ReusableComponents.js/getIds'
import { convertNumberIntoLacsCr } from '../ReusableComponents.js/reuseMethods'
import { MPTableprofile } from '../ReusableComponents.js/ProfilePictureStyles'
import ProfilePictureComponent from '../ReusableComponents.js/ProfilePictureComponent'

const hardcodedValuesForTabs = ['memberAdded', 'followers', 'twitterPerformance', 'donation']

export default function StickyHeadTable({ cardClick, flag, count, user, mpProfileData, dataFilter }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [page, setPage] = React.useState(count)
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  let keys = [
    'id',
    'icon',
    'user_name',
    'imageSrc',
    'position',
    'constituency_name',
    'sevaScore',
    'initiatives',
    'eventOrganized',
    'citizen',
    'memberAdded',
    'followers',
    'twitterPerformance',
    'opEds',
    'mediaCoverage',
    'donation',
    'dateIssued',
    'cardsCreated',
    'likes',
    'wishes',
    'campaignScore',
  ]
  const numberValues = [
    'sevaScore',
    'initiatives',
    'eventOrganized',
    'memberAdded',
    'followers',
    'twitterPerformance',
    'opEds',
    'mediaCoverage',
    'donation',
    'cardsCreated',
    'likes',
    'wishes',
    'campaignScore',
  ]

  const mpList = useSelector((state) => state?.mpList?.data)
  const scrollRef = React.useRef()
  const loggedInId = getIds()
  let columnsAll = keys
  let column = []

  const fetchMpData = async () => {
    try {
      setLoading(true)
      await dispatch(getMpList(flag, page, dataFilter))
    } catch (error) {
      showNotification('Error', error)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    fetchMpData()
  }, [page])

  React.useEffect(() => {
    scrollRef.current.scrollTop = 0
    setPage(1)
  }, [dataFilter])

  React.useEffect(() => {
    scrollRef.current.scrollTop = 0
    setPage(1)
  }, [flag])

  let filteredColumns = MpColumnFilter.filter((s) => s.screenNumber === cardClick)[0]?.columns

  column = columnsAll
    .filter((s) => filteredColumns?.includes(MpColumnHeaders[s]))
    .map((x) => ({ id: x, label: x == 'id' ? 'Rank' : MpColumnHeaders[x] }))

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current

      if (
        Math.floor(scrollTop) + clientHeight == scrollHeight ||
        Math.floor(scrollTop) + clientHeight + 1 == scrollHeight ||
        Math.ceil(scrollTop) + clientHeight == scrollHeight
      ) {
        setPage(page + 1)
      }
    }
  }

  const handleNavigate = async (item) => {
    await dispatch(getOngoingSevaIntiativesListByMpId({ id: item.id }))
    if (item.id === loggedInId) {
      navigate('/MySevaUpdates', {
        state: {
          MpName: 'My Seva Rank',
          user: user,
          mpId: loggedInId,
        },
      })
    } else {
      navigate('/MpSevaUpdates', {
        state: {
          MpName: item.user_name,
          user: user,
          mpId: item.id,
          mpClick: true,
          mpData: item && item,
        },
      })
    }
  }
  return (
    <TableContainer className={` ${column[4]?.id === 'initiatives' ? 'initiatives-table__body-wrapper' : ''}`}>
      <Table className="tableheader">
        <TableHead>
          <TableRow className={` ${column[4]?.id === 'initiatives' ? 'initiatives-table__header' : ''}`}>
            {column.map((column) => (
              <TableCell key={column?.id} id={'header' + column.id} align={column.align} className="tablecellstyle">
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>

      <Box ref={scrollRef} onScroll={() => onScroll()} className="customscroll scroll-bg-transparent table-div-style">
        <Table className="cursorshow tablebodystyle">
          <TableBody>
            {/* {tdData()} */}

            <React.Fragment>
              {mpList &&
                mpList.map(
                  (item, index) =>
                    index > 2 && (
                      <TableRow
                        hover
                        role="checkbox"
                        clickable
                        tabIndex={-1}
                        key={index + 1}
                        onClick={(e) => {
                          if (!window.getSelection().toString()) {
                            handleNavigate(item)
                          }
                        }}
                        className={`${item?.id === mpProfileData?.id && 'tablerow-bgcolor'}`}
                      >
                        {column.map((column, key) =>
                          key == 2 ? (
                            <TableCell id={column.id}>
                              <Box className="table-name-container">
                                <Box>
                                  <Typography variant="body1" className="item-user-name">
                                    {item.user_name}
                                  </Typography>
                                  <Typography variant="body1" className="item-party">
                                    {item.party}
                                  </Typography>
                                </Box>
                              </Box>
                            </TableCell>
                          ) : (
                            <TableCell
                              key={index + 1}
                              id={column.id}
                              align={column.align}
                              className={`${column.id == 'memberAdded' ? 'padding-left-members-added' : ''} ${
                                key === 0 ? 'rank-style-0' : 'rank-style'
                              }`}
                            >
                              {key == 0 ? (
                                item.dense_rank
                              ) : key == 1 ? (
                                <Box>
                                  <ProfilePictureComponent
                                    ProfilPic={item.user_avatar}
                                    sxProfile={MPTableprofile}
                                    alt="avatar"
                                  />
                                  {/* {item.user_avatar ? (
                                    <Avatar
                                      alt="avatar"
                                      src={item.user_avatar}
                                      className=" leader-circle-img item-user-avatar"
                                      draggable="false"
                                      sx={{ padding: '0px' }}
                                    />
                                  ) : (
                                    <Box>
                                      <AccountCircleIcon
                                        sx={{
                                          fontSize: 'xx-large',
                                          width: '50px',
                                          height: '50px',
                                          color: '#EEEEEE',
                                        }}
                                      />
                                    </Box>
                                  )} */}
                                </Box>
                              ) : column.id == 'donation' ? (
                                '₹ ' + convertNumberIntoLacsCr(parseInt(item[column.id], 10) || 0)
                              ) : column.id == 'campaignScore' ? (
                                convertNumberIntoLacsCr(parseInt(item[column.id] < 0 ? 0 : item[column.id], 10) || 0)
                              ) : numberValues.includes(column.id) ? (
                                convertNumberIntoLacsCr(parseInt(item[column.id], 10) || 0)
                              ) : (
                                convertNumberIntoLacsCr(item[column.id])
                              )}
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    )
                )}
            </React.Fragment>
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  )
}
