import { Box, Typography, Grid } from '@mui/material'
import Overview from './Overview'
import { useSelector } from 'react-redux'
import './InitiativesOverview.css'

const InitiativesOverview = () => {
  const initiative = useSelector((state) => state?.initiativesOverview?.data)

  return (
    <card>
      <Box className="overview-card">
        <Typography variant="body1" className="header-design-1">
          Initiatives Overview
        </Typography>
        <Grid container spacing={2} sx={{ mb: '10px' }}>
          <Overview title={'Requested Initiatives'} id={1} count={initiative?.requestCount} />
          <Overview title={'Ongoing Initiatives'} id={2} count={initiative?.ongoingCount} />
          <Overview title={'Events Conducted'} id={3} count={initiative?.eventcount1} />
        </Grid>
      </Box>
    </card>
  )
}

export default InitiativesOverview
