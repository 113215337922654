import { SEARCH_MP_FAILURE, SEARCH_MP_REQUEST, SEARCH_MP_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  loading: true,
  error: '',
};

export const MpSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_MP_REQUEST:
      return {
        ...state,
      };
    case SEARCH_MP_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case SEARCH_MP_FAILURE:
      return {
        data: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
