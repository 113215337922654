import { Grid, Breadcrumbs, Button } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './Breadcrumb.css'
function Breadcrumb() {
  const navigate = useNavigate()
  const location = useLocation()
  let mpName = location?.state?.mpName
  let mpId = location?.state?.mpId
  let mySeva = location?.state?.mySeva
  let initiativeName = location?.state?.initiativeName
  const ongoingSevaInitiativesLists = useSelector((state) => state?.ongoingSevaInitiativesList?.data)
  const ongoingSevaInitiativesListsByMpId = useSelector((state) => state?.ongoingSevaInitiativesListByMpId?.data)
  const ongoingSevaInitiativesList = ongoingSevaInitiativesLists?.initiative
  const ongoingSevaInitiativesListByMpId = ongoingSevaInitiativesListsByMpId?.initiative
  let user = location?.state?.user
  const onFolderClick = () => {
    navigate('/SevaUpdates', {
      state: {
        user: user,
      },
    })
  }
  const ongoingBreadCrumbClick = () => {
    navigate('/SevaUpdates/viewAllOngoingInitiatives', {
      state: {
        viewAllValue: 'Ongoing Seva Initiatives',
        mpName: mpName,
        mpId: mpId,
        mySeva: mySeva,
        data: mpId ? ongoingSevaInitiativesListByMpId : ongoingSevaInitiativesList,
        ongoingSevaInitiatives: true,
      },
    })
  }
  return (
    <>
      <Grid container sx={{ mb: 1, marginLeft: '30px', marginBottom: '20px' }}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateNextIcon fontSize="small" sx={{ marginLeft: '-15px' }} />}
        >
          <Button underline="hover" className="ns-update" onClick={onFolderClick}>
            Nationwide Seva Updates
          </Button>
          <Button underline="hover" className="ns-update os-text" onClick={ongoingBreadCrumbClick}>
            Ongoing Seva Initiative
          </Button>
          <Button
            className="seva-update__breadcrumbs-title1 initiative-title "
            underline="hover"
            title={initiativeName ? initiativeName : ''}
          >
            {initiativeName && initiativeName}
          </Button>
        </Breadcrumbs>
      </Grid>
    </>
  )
}
export default Breadcrumb
