import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  EmailIcon,
  MailruShareButton,
} from 'react-share'
import CopyAndShareClick from './CopyAndShareAPI'
import exampleImage from '../../asserts/images/profile.jpg'
import { makeLogRequest } from '../..//utils/AuditLogs'
import { getIds } from '../ReusableComponents.js/getIds'
import './Share.css'
import DonationClick from './DonationClick'
import { pushTag } from '../../utils/gtmservice'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'

const Share = ({
  data,
  content,
  linkurl,
  title,
  initiativeReport,
  donation,
  addMembers,
  sharingTextMediumPopUp,
}) => {
  const eventLink = 'https://nm-4.com/mymp'
  let shareText = `Join an exciting and interesting event "" organised by ${
    data?.user?.user_name || ''
  } ${eventLink} via NaMo app.
  Install NaMo app on App store or play store.`

  if (title) {
    const organizerName = data?.user?.user_name || ''
    switch (title) {
      case 'Ongoing Seva Initiatives':
        shareText = `Mera Saansad on NaMo App: Mark the date for ${
          initiativeReport ? data?.eventTitle : data.initiativeName
        } organised by ${organizerName}. Discover more Seva activities by your MP at ${eventLink}`
        break
      case 'Seva aur Samarpan Campaign':
        shareText = `Mera Saansad on NaMo App: Mark the date for ${data.eventTitle} organised by ${organizerName}. Discover more Seva activities by your MP at ${eventLink}`
        break
      case 'Media Coverage of':
        shareText = `Mera Saansad on NaMo App: Read now ${data.title} by ${organizerName}. Discover more Seva activities by your MP at ${eventLink}`
        break
      case 'Miscellaneous of':
        shareText = `Mera Saansad on NaMo App: Check out ${data.title} by ${organizerName}. Discover more Seva activities by your MP at ${eventLink}`
        break
      case 'Development Projects':
        shareText = `Mera Saansad on NaMo App: Learn more about ${data.projecttitle} within the constituency of ${organizerName}. Discover more Seva activities by your MP at ${eventLink}`
        break
      default:
        break
    }
  }

  const [link, setLink] = useState(
    'http://apps.apple.com/ or https://play.google.com/'
  )
  const [shareMessage, setShareMessage] = useState(shareText)

  const sendGALog = (message) => {
    if (sharingTextMediumPopUp) {
      pushTag({
        event: 'share',
        page_title: 'sharing_text_medium_pop_up',
        message: message,
      })
    } else if (addMembers) {
      pushTag({
        event: 'share',
        page_title: 'expand_movement_popup',
        message: message,
      })
    } else if (donation) {
      pushTag({
        event: 'share',
        page_title: 'invite_donations_popup',
        message: message,
      })
    }
  }

  const postAuditLogsforPopups = async (sqId) => {
    await makeLogRequest(
      donation ? 'invite_donations_popup' : 'add_members_popup',
      sqId,
      { useridtype: getIds() }
    )
  }

  const handleShare = () => {
    // const link = "https://example.com";
    const subject = 'Share NAMO APP'
    navigator
      .share({ title: subject, url: link, body: shareMessage })
      .then(() => {})
      .catch((error) => {
        if (error.message === 'cancelled') {
        } else {
        }
      })
  }
  useEffect(() => {
    if (content) {
      setShareMessage(content)
      linkurl && setLink(linkurl)
    }
  }, [])

  const sendShareLog = async (type) => {
    const parameters = {
      useridtype: getIds(),
    }
    makeLogRequest('share_on_socialmedia_popup', type, parameters)
  }

  const onFacebookClick = () => {
    sendShareLog('facebook')
    sendGALog('facebook')
    ;(donation || addMembers) && postAuditLogsforPopups('facebook')
    donation ? DonationClick() : CopyAndShareClick()
  }

  const onEmailClick = () => {
    sendShareLog('email')
    sendGALog('email')
    ;(donation || addMembers) && postAuditLogsforPopups('email')
    donation ? DonationClick() : CopyAndShareClick()
  }

  return (
    <>
      <Box>
        <FacebookShareButton
          url={link}
          hashtag="NamoApp"
          hasBlankTarget
          quote={shareMessage}
          // onClick={donation=="check" ? DonationClick : CopyAndShareClick }
          onClick={onFacebookClick}
          className="cursorshow icon-pos"
        >
          <FacebookIcon size={60} round />
        </FacebookShareButton>
        <Typography className="share-icons">Facebook</Typography>
      </Box>

      <Box>
        <Box
          className="cursorshow"
          onClick={() => {
            sendShareLog('twitter')
            sendGALog('twitter')
            let tlink = 'https://twitter.com/intent/tweet?text='
            var twitterLink = `${tlink}${encodeURIComponent(shareMessage)}`
            window.open(twitterLink, 'blank')
            ;(donation || addMembers) && postAuditLogsforPopups('twitter')
          }}
        >
          <TwitterIcon size={60} round />
        </Box>
        <Typography className="share-icons">Twitter</Typography>
      </Box>

      <Box className="Demo__some-network">
        <EmailShareButton
          subject={'Share NAMO APP'}
          // body={shareMessage}
          openShareDialogOnClick
          hasBlankTarget
          url={link}
          onClick={onEmailClick}
          className="cursorshow"
        >
          <EmailIcon size={60} round />
        </EmailShareButton>
        <Typography className="share-icons">Email</Typography>
      </Box>

      <Box className="Demo__some-network">
        <Box
          className="cursorshow"
          onClick={() => {
            sendShareLog('whatsapp')
            sendGALog('whatsapp')
            var whatsappLink =
              'https://api.whatsapp.com/send?text=' +
              encodeURIComponent(shareMessage)
            window.open(whatsappLink, 'blank')
            ;(donation || addMembers) && postAuditLogsforPopups('whatsapp')
          }}
        >
          <WhatsappIcon size={60} round />
        </Box>

        <Typography className="share-icons">WhatsApp</Typography>
      </Box>
    </>
  )
}

export default Share
