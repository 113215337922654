import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'

const ContentCarouselButton = ({ buttonTitle, onClick }) => {
  return (
    <Button
      className="button-tr-citizen"
      startIcon={buttonTitle === 'Add New' && <AddIcon sx={{ fontSize: '20' }} />}
      onClick={onClick}
    >
      {buttonTitle}
    </Button>
  )
}

export default ContentCarouselButton
