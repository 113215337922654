import React, { useState, useEffect } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import CloseIcon from '@mui/icons-material/Close'
import './CreateInitiative.css'
import './NewAssingMP.css'

import {
  FormControl,
  IconButton,
  Select,
  Grid,
  MenuItem,
  Button,
  Checkbox,
  ListItemText,
  OutlinedInput,
  DialogContent,
  FormLabel,
  InputBase,
  ListItem,
  ListItemAvatar,
  Avatar,
  List,
  Chip,
  Typography,
  Box,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAssignMpList } from '../../../store/action/assignMpList'
import { postIntiative } from '../../../store/action/createInitiative'
import Search from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { getStateList } from '../../../store/action/stateList'
import { useNotificationContext } from '../../../utils/NotificationContext'
import { useLoading } from '../../../utils/LoadingContext'
import CancelIcon from '@mui/icons-material/Cancel'
import './CreateInitiative.css'
import './assignMp.css'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { iconComponent } from '../../ReusableComponents.js/reuseMethods'
import CrossIcon from '../../../asserts/images/close-2.svg'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { pushTag } from '../../../utils/gtmservice'
import ProfilePictureComponent from '../../ReusableComponents.js/ProfilePictureComponent'
import { AssignMPProfile } from '../../ReusableComponents.js/ProfilePictureStyles'

const AssignToMP = ({
  handleCloseAssingToMPDialog,
  openAssingToMPDialog,
  intiativeData: initiativeData,
  id,
  selectedMP,
  createNewClick,
  eventList,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { assignMpList } = useSelector((state) => state?.assignMpList)
  const { statedata: stateData } = useSelector((state) => state?.stateList?.data)
  const mpSearchList = useSelector((state) => state?.mpSearchList?.data)
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const { setLoading } = useLoading()
  const [initiativeDetail, setInitiativeDetail] = useState(initiativeData)
  const [confirm, setConfirm] = useState(false)
  const { showNotification } = useNotificationContext()

  const [stateApiResult, setStateApiResult] = useState([])
  const [selectedState, setSelectedState] = useState([]) // format id, state_name
  const [stateSearchClearIcon, setStateSearchClearIcon] = useState(false)
  const [stateSearchState, setStateSearchState] = useState('')

  const [mpApiResult, setMpApiResult] = useState([])
  const [selectedMps, setSelectedMps] = useState([]) // format id, name, user_name, state_name
  const [mpClearIcon, setMPSearchClearIcon] = useState(false)
  const [mpSearchState, setMPSearchState] = useState('')

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('assign_to_mp_popup', sqId, data)
  }

  useEffect(() => {
    if (confirm) {
      pushTag({
        event: 'pageload',
        page_title: 'update_initiative_pop_up',
      })
    }
  }, [confirm])

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'assign_mp_pop_up',
      message: message,
    })
  }

  useEffect(() => {
    dispatch(getStateList())
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'assign_mp_pop_up',
    })
  }, [])

  useEffect(() => {
    const states = selectedState?.map((state) => {
      return state.state_name
    })
    dispatch(getAssignMpList(states.toString()))
  }, [selectedState])

  useEffect(() => {
    const stateResult = stateData?.length > 0 ? [...stateData] : []
    stateResult.unshift({ id: 'all', state_name: 'All' })
    setStateApiResult(stateResult)
  }, [stateData])

  useEffect(() => {
    const mpResult = assignMpList?.mpAssignData?.length > 0 ? [...assignMpList.mpAssignData] : []
    mpResult.unshift({ id: 'all', user_name: 'All' })
    setMpApiResult(mpResult)
  }, [assignMpList?.mpAssignData])

  const setUniqueStates = (mps) => {
    const uniqueStates = [...new Set(mps.map((item) => item.state_name))]
    setSelectedState([])
    uniqueStates.forEach((state) => {
      const index = stateData?.findIndex((item) => item?.state_name?.toLowerCase() == state?.toLowerCase())
      const stateItem = stateData && stateData[index] ? stateData[index] : []
      setSelectedState((prev) => [...prev, { id: stateItem?.id, state_name: state }])
    })
  }
  useEffect(() => {
    if (id && selectedMP?.length) {
      setSelectedMps([])
      selectedMP?.map((val) => {
        setSelectedMps((prev) => [
          ...prev,
          {
            id: val?.id,
            user_name: val?.user_name,
            state_name: val?.state_name,
            house: val?.house,
            user_avatar: val?.user_avatar,
          },
        ])
      })
      setUniqueStates(selectedMP)
    }
  }, [])

  const handleStateDelete = (e, state) => {
    const resultAfter = selectedState.filter((item) => item.id !== state.id)
    setSelectedState(resultAfter)
    const mpResults = selectedMps.filter((item) => item.state_name !== state.state_name)
    setSelectedMps(mpResults)
  }

  const handleStateClick = (state) => {
    if (state.id === 'all') {
      if (stateData.length === selectedState.length) {
        setSelectedState([])
        setSelectedMps([])
      } else {
        const stateResults = [...stateData]
        const allResults = stateResults.map((item) => {
          return {
            id: item.id,
            state_name: item.state_name,
          }
        })
        setSelectedState(allResults)
      }
      return
    }

    if (selectedState.findIndex((item) => item.id === state.id) > -1) {
      const resultAfter = selectedState.filter((item) => item.id !== state.id)
      setSelectedState(resultAfter)
    } else {
      setSelectedState((prev) => [...prev, { id: state.id, state_name: state.state_name }])
    }
  }

  const handleStateSearch = (searchText) => {
    setStateSearchState(searchText)
    const stateResults = [...stateData]
    if (searchText.length > 0) {
      setStateSearchClearIcon(true)
      const results = stateResults.filter((item) => item.state_name.toLowerCase().includes(searchText.toLowerCase()))
      results.unshift({ id: 'all', state_name: 'All' })
      setStateApiResult(results)
    } else {
      setStateSearchClearIcon(false)
      stateResults.unshift({ id: 'all', state_name: 'All' })
      setStateApiResult(stateResults)
    }
  }

  const handleStateCheckedState = (state) => {
    if (state.id === 'all' && selectedState?.length === stateData?.length) {
      return true
    }
    return selectedState?.findIndex((item) => item.id === state.id) > -1 || false
  }

  const handleMPDelete = (e, mp) => {
    const resultAfter = selectedMps.filter((item) => item.id !== mp.id)
    setSelectedMps(resultAfter)
    if (resultAfter?.length === 0) {
      setSelectedState([])
    }
  }

  const handleMPSearch = (searchText) => {
    setMPSearchState(searchText)
    const mpResults = [...assignMpList?.mpAssignData]
    if (searchText.length > 0) {
      setMPSearchClearIcon(true)
      const results = mpResults.filter((item) => item.user_name.toLowerCase().includes(searchText.toLowerCase()))
      results.unshift({ id: 'all', user_name: 'All' })
      setMpApiResult(results)
    } else {
      setMPSearchClearIcon(false)
      mpResults.unshift({ id: 'all', user_name: 'All' })
      setMpApiResult(assignMpList?.mpAssignData)
    }
  }

  const handleMPClick = (mp) => {
    if (mp.id === 'all') {
      if (assignMpList?.mpAssignData?.length === selectedMps.length) {
        setSelectedMps([])
      } else {
        const mpResults = [...assignMpList.mpAssignData]
        const allResults = mpResults.map((item) => {
          return {
            id: item.id,
            user_name: item.user_name,
            state_name: item.state_name,
            house: item?.house,
            user_avatar: item?.user_avatar,
          }
        })
        setSelectedMps(allResults)
      }
      return
    }

    if (selectedMps.findIndex((item) => item.id === mp.id) > -1) {
      const resultAfter = selectedMps.filter((item) => item.id !== mp.id)
      setSelectedMps(resultAfter)
    } else {
      setSelectedMps((prev) => [
        ...prev,
        {
          id: mp.id,
          state_name: mp.state_name,
          user_name: mp.user_name,
          house: mp?.house,
          user_avatar: mp?.user_avatar,
        },
      ])
    }
  }

  const handleMPCheckedState = (mp) => {
    if (mp.id === 'all' && selectedMps?.length === assignMpList?.mpAssignData?.length) {
      return true
    }
    return selectedMps?.findIndex((item) => item.id === mp.id) > -1 || false
  }

  const handlePost = async (event) => {
    try {
      setLoading(true)
      const response = await dispatch(postIntiative(initiativeDetail, id))

      if (response.status === 200 || response.status === 201) {
        navigate('/SevaInitiatives')
        showNotification('Success', response.data.message, 'success')
      }
    } catch (error) {
      showNotification('Error', 'Failed to post the data')
    } finally {
      setLoading(false)
    }
    setConfirm(false)
    handleCloseAssingToMPDialog()
  }

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: '15px',
            height: 'auto',
            position: 'absolute',
            top: '20%',
            maxWidth: '650px !important',
          },
        }}
        fullScreen
        onClose={() => {
          setStateSearchState('')
          handleCloseAssingToMPDialog()
        }}
        open={openAssingToMPDialog}
      >
        <DialogTitle
          sx={{
            color: '#356f92 !important',
            fontWeight: 'bold !important',
            textAlign: 'center !important',
            fontSize: '1.8rem !important',
          }}
        >
          Assign To MP
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={async () => {
            await postAuditLogs('close')
            sendGALog('close')
            handleCloseAssingToMPDialog()
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            border: '1px solid #9e9e9e',
            borderRadius: '50%',
          }}
        >
          <CloseIcon />
        </IconButton>

        <Box className="centered-container">
          <Grid container>
            <FormControl sx={{ m: 1, width: 400 }} size="small" fullWidth>
              <Typography component="b" sx={{ fontWeight: 'bold' }} className="stateFontStyle">
                Select State
              </Typography>
              <FormLabel id="demo-multiple-chip-label" className="label"></FormLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                IconComponent={iconComponent}
                multiple
                value={selectedState}
                input={<OutlinedInput className={selectedState?.length >= 2 && 'textArea'} />}
                renderValue={(selected) => (
                  <Box>
                    {selected?.map((item) => (
                      <Chip
                        sx={{
                          backgroundColor: '#e3f5ff !important',
                          margin: '3px !important',
                          fontWeight: 'bold',
                          color: '#505050 !important',
                        }}
                        key={item.id}
                        label={item.state_name}
                        clickable
                        deleteIcon={
                          <Avatar
                            src={CrossIcon}
                            sx={{ width: '22px', height: '22px' }}
                            className="filters"
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={(e) => handleStateDelete(e, item)}
                      />
                    ))}
                  </Box>
                )}
              >
                <Box>
                  {stateSearchClearIcon ? (
                    <ClearIcon
                      position="start"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleStateSearch('')
                        setStateSearchClearIcon(false)
                        setStateSearchState('')
                      }}
                    />
                  ) : (
                    <Search sx={{ color: '#356F92', ml: 1 }} position="start"></Search>
                  )}
                  <InputBase
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      handleStateSearch(e.target.value)
                    }}
                    sx={{ width: '85%' }}
                    value={stateSearchState}
                    placeholder="Search State Name"
                  />
                </Box>

                <Box className="menu-container">
                  {stateApiResult?.length > 0 &&
                    stateApiResult?.map((state) => (
                      <MenuItem
                        key={state.id}
                        value={state.state_name}
                        sx={{
                          borderBottom: '1px solid #F5F5F5',
                          margin: '0 10px',
                        }}
                        onClick={(e) => {
                          handleStateClick(state)
                        }}
                      >
                        <Checkbox
                          sx={{ padding: '2px', transform: 'scale(.7)' }}
                          checked={handleStateCheckedState(state)}
                        />
                        <List sx={{ padding: '0px' }}>
                          <ListItem sx={{ padding: '0px' }}>
                            <ListItemText primary={state.state_name} className="listItem" />
                          </ListItem>
                        </List>
                      </MenuItem>
                    ))}
                </Box>
              </Select>
            </FormControl>
          </Grid>
          <Grid container>
            <FormControl sx={{ m: 1, width: 400 }} size="small" fullWidth>
              <Typography component="b" sx={{ fontWeight: 'bold' }} className="stateFontStyle">
                Select MP's
              </Typography>
              <FormLabel id="demo-multiple-chip-label" className="label"></FormLabel>
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                IconComponent={iconComponent}
                multiple
                disabled={selectedState?.length === 0}
                value={selectedMps}
                input={<OutlinedInput className={selectedMps?.length >= 2 && 'textArea'} />}
                renderValue={(selected) => (
                  <Box>
                    {selected?.map((item) => (
                      <Chip
                        sx={{
                          height: item.house ? '50px' : '40px',
                          backgroundColor: '#e3f5ff !important',
                          borderRadius: '37px !important',
                          margin: '4px',
                        }}
                        key={item.id}
                        label={
                          <Grid container>
                            <Grid
                              xs={3}
                              sx={{
                                mt: item.user_avatar
                                  ? item.house
                                    ? '12px'
                                    : '2px'
                                  : !item.user_avatar && !item.house
                                  ? '2px'
                                  : item?.house
                                  ? '9px'
                                  : '12px',
                              }}
                            >
                              <Avatar sx={{ height: '25px', width: '25px' }}>
                                {item?.user_avatar ? (
                                  <Avatar src={item.user_avatar}></Avatar>
                                ) : (
                                  <AccountCircleIcon
                                    sx={{
                                      fontSize: 'xx-large',
                                      width: '26px',
                                      height: 'auto',
                                      border: 0,
                                    }}
                                  />
                                )}
                              </Avatar>
                            </Grid>
                            <Grid
                              xs={8}
                              sx={{
                                mr: '-7px',
                                ml: '31px',
                                mt: item?.user_avatar
                                  ? item?.house
                                    ? '-38px'
                                    : '-25px'
                                  : !item?.user_avatar && !item?.house
                                  ? '-26px'
                                  : item?.house
                                  ? '-33px'
                                  : '-37px',
                              }}
                            >
                              <Typography component="span" variant="inherit">
                                {item.user_name}
                              </Typography>
                              <br />
                              <Typography
                                component="span"
                                sx={{
                                  fontSize: '12px',
                                  marginTop: '-6px !important',
                                }}
                              >
                                {item.house}
                              </Typography>
                            </Grid>
                          </Grid>
                        }
                        clickable
                        deleteIcon={
                          <Avatar
                            src={CrossIcon}
                            sx={{ width: '22px', height: '22px' }}
                            className="filters"
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                        onDelete={(e) => handleMPDelete(e, item)}
                      />
                    ))}
                  </Box>
                )}
              >
                <Box>
                  {mpClearIcon ? (
                    <ClearIcon
                      position="start"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setMPSearchClearIcon(false)
                        setMPSearchState('')
                        const mpResults = [...assignMpList?.mpAssignData]
                        mpResults.unshift({ id: 'all', user_name: 'All' })
                        setMpApiResult(assignMpList?.mpAssignData)
                      }}
                    />
                  ) : (
                    <Search sx={{ color: '#356F92', ml: 1 }} position="start"></Search>
                  )}
                  <InputBase
                    onKeyDown={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      handleMPSearch(e.target.value)
                    }}
                    sx={{ width: '85%' }}
                    value={mpSearchState}
                    placeholder="Search MP Name"
                  />
                </Box>
                {mpApiResult?.length > 1 ? (
                  <Box className="menu-container">
                    {mpApiResult?.length > 0 &&
                      mpApiResult.map((mp) => (
                        <MenuItem
                          key={mp.id}
                          value={mp.user_name}
                          sx={{
                            borderBottom: '1px solid #F5F5F5',
                            margin: '0 10px',
                          }}
                          onClick={(e) => {
                            handleMPClick(mp)
                          }}
                        >
                          <Checkbox
                            sx={{ padding: '2px', transform: 'scale(.7)' }}
                            checked={handleMPCheckedState(mp)}
                          />
                          <List sx={{ padding: '0px' }}>
                            <ListItem sx={{ padding: '0px' }}>
                              {mp.id !== 'all' && (
                                <ListItemAvatar sx={{ minWidth: '30px' }}>
                                  <ProfilePictureComponent ProfilPic={mp.user_avatar} sxProfile={AssignMPProfile} />
                                  {/* <Avatar sx={{ height: '25px', width: '25px' }}>
                                    {mp.user_avatar ? (
                                      <Avatar src={mp.user_avatar}></Avatar>
                                    ) : (
                                      <AccountCircleIcon
                                        sx={{
                                          fontSize: 'xx-large',
                                          width: '26px',
                                          height: 'auto',
                                          border: 0,
                                          color: '#EEEEEE',
                                        }}
                                      />
                                    )}
                                  </Avatar> */}
                                </ListItemAvatar>
                              )}
                              <ListItemText primary={mp.user_name} secondary={mp.house} className="listItem" />
                            </ListItem>
                          </List>
                        </MenuItem>
                      ))}
                  </Box>
                ) : (
                  <Typography className="no-mp-found-position" variant="body1">
                    No mp found
                  </Typography>
                )}
              </Select>
            </FormControl>
          </Grid>
        </Box>
        <React.Fragment>
          <Button
            disabled={selectedMps?.length === 0}
            variant="contained"
            sx={{
              p: 1,
              mr: 1,
              mt: 4,
              backgroundColor: '#ef7335',
              borderRadius: 4,
              position: 'relative',
              alignSelf: 'center',
              width: '300px',
              padding: '8px',
              marginBottom: '40px',
            }}
            className="button-primary-alt-contained"
            onClick={async () => {
              let jsonObj = initiativeData
              const mpIds = []
              selectedMps.map((mp, i) => {
                mpIds.push(mp.id)
              })
              jsonObj.mplist = mpIds
              setUniqueStates(selectedMps)
              setInitiativeDetail(jsonObj)
              let stateNamesForLogs = [...new Set(selectedMps.map((item) => item.state_name))]
              sendGALog(createNewClick ? 'create' : 'update')
              {
                createNewClick ? handlePost() : eventList?.length > 0 ? setConfirm(true) : handlePost()
              }
            }}
          >
            {createNewClick ? 'CREATE AND ASSIGN INITIATIVE' : 'UPDATE AND ASSIGN INITIATIVE'}
          </Button>
        </React.Fragment>
      </Dialog>
      <Dialog
        open={confirm}
        PaperProps={{
          sx: {
            borderRadius: '15px',
            position: 'fixed',
            minHeight: '250px',
            width: '450px',
          },
        }}
        onClose={() => setConfirm(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setConfirm(false)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
            border: '1px solid #9e9e9e',
            borderRadius: '50%',
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            padding: '55px 55px 0 55px',
            fontSize: '20px',
            textAlign: 'center',
          }}
        >
          Multiple MPs have created events for this initiative. Are you sure you want to update this initiative?
        </DialogContent>
        <Button
          variant="contained"
          sx={{
            p: 1,
            mr: 1,
            width: '300px',
            alignSelf: 'center',
            backgroundColor: '#ef7335',
            borderRadius: 4,
            position: 'relative',
            margin: '15px 15px 35px 15px',
          }}
          className="button-primary-alt-contained"
          onClick={() => {
            pushTag({
              event: 'select_option',
              page_title: 'update_initiative_pop_up',
              message: 'confirm',
            })
            handlePost()
          }}
        >
          {' '}
          Confirm
        </Button>
      </Dialog>
    </>
  )
}

export default AssignToMP
