import { GET_LOGIN_FAILURE, GET_LOGIN_REQUEST, GET_LOGIN_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  error: '',
};

export const getloginReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGIN_REQUEST:
      return {
        state,
      };
    case GET_LOGIN_SUCCESS:
      return {
        data: action.payload,
      };
    case GET_LOGIN_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
