import { Avatar, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { tooltipClasses } from '@mui/material/Tooltip'
import './ToolTip.css'

export const BootstrapTooltip1 = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #356F92',
    },
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #356F92',
    boxShadow: theme.shadows[1],
    width: 220,
    borderRadius: '15px',
    marginTopValue: '-30px',
  },
}))

export const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #356F92',
    },
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #356F92',
    boxShadow: theme.shadows[1],
    width: 370,
    maxWidth: '370px',
    borderRadius: '15px',
  },
}))

export const BootstrapTooltipInfo = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #356F92',
    },
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #356F92',
    boxShadow: theme.shadows[1],
    width: 240,
    maxWidth: '370px',
    borderRadius: '15px',
  },
}))

const ToolTipComponent = ({ dataFilter, icon, defaultTenure }) => {
  return (
    <BootstrapTooltip1
      title={
        defaultTenure === 'Modi2.0'
          ? 'The data present in Modi 2.0 is of the previous tenure till 4th June 2024.'
          : dataFilter === '15days'
          ? 'Showing results of last 15 days. Refreshes everyday at midnight.'
          : 'Showing all results from the date 4th June 2024 till present. Refreshes every day at midnight'
      }
      placement="bottom"
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: '16px',
            fontFamily: 'HK Grotesk',
            padding: '10px',
            textAlign: 'center',
          },
        },
      }}
    >
      <Avatar variant="square" src={icon} className="info-icon" />
    </BootstrapTooltip1>
  )
}

export default ToolTipComponent
