import { UPLOAD_MEDIA_REQUEST, UPLOAD_MEDIA_SUCCESS, UPLOAD_MEDIA_FAILURE } from '../action/types';

const initialState = {
  loading: true,
  data: [],
  error: '',
};

export const uploadMediaFiles = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_MEDIA_REQUEST:
      return {
        state,
      };
    case UPLOAD_MEDIA_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case UPLOAD_MEDIA_FAILURE:
      return {
        data: [],
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
