import { INDIVIDUAL_MP_VIEW_REQUEST, INDIVIDUAL_MP_VIEW_FAILURE, INDIVIDUAL_MP_VIEW_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  error: '',
};

export const getMpProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case INDIVIDUAL_MP_VIEW_REQUEST:
      return {
        state,
      };
    case INDIVIDUAL_MP_VIEW_SUCCESS:
      return {
        data: action.payload,
      };
    case INDIVIDUAL_MP_VIEW_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
