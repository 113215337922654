import { CircularProgress, Box } from '@mui/material'
import { convertNumberIntoLacsCr } from '../../ReusableComponents.js/reuseMethods.js'
import './Highlights.css'

const TableHighlights = ({ dataLoading, data }) => {
  const keys = {
    InitiativeReports: 'Initiative Reports',
    InitiativesUndertaken: 'Initiatives Undertaken',
    EventsConducted: 'Events Conducted',
    memberadded: 'Members Added',
    twtterfollowers: 'Twitter Followers',
    // twittermention: 'Twitter Mentions',
    twttertweet: 'Twitter Retweets',
    DevelopmentProjects: 'Development Projects',
    DonationReceived: 'Donations Raised',
    MediaCoverage: 'Media Coverage',
    Op_Eds: 'Op-Eds',
    BookPublished: 'Books Published',
  }
  return (
    <Box className="mt-3 tableHighlightText">
      {dataLoading ? (
        <CircularProgress />
      ) : (
        data &&
        data?.map((row, index) => {
          const isLastElement = index === data.length - 1
          const tableWrapClass = isLastElement
            ? 'd-flex justify-content-between py-3 tableWrap'
            : 'd-flex justify-content-between border-bottom py-3 tableWrap'

          return (
            <Box className={tableWrapClass} key={row.heading}>
              <Box className="tableHighlightTitle">{keys[row.heading]}</Box>
              <Box className="seva-nos tableHighlightItem">
                {row.heading === 'DonationReceived'
                  ? '₹ ' + convertNumberIntoLacsCr(row?.value)
                  : convertNumberIntoLacsCr(row?.value)}
              </Box>
            </Box>
          )
        })
      )}
    </Box>
  )
}

export default TableHighlights
