import { Box, Grid, Typography, Collapse, CardContent, Button, Divider } from '@mui/material'
import pdf2 from '../../../../asserts/images/pdf2.svg'
import Moment from 'moment'
import './Details.css'
import '../InitiativesDetails.css'

function Details({
  expanded,
  handleExpandClick,
  data,
  handlePdfClickOpen,
  dataSteps,
  handleFileName,
  ExpandMore,
  ExpandMoreIcon,
}) {
  return (
    <>
      <Box>
        <Grid container>
          <Grid item xs={11}>
            <Typography variant="span" className="initiative-heading initiative-title-style sort-styling">
              Initiative Details
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
              <ExpandMoreIcon
                sx={{
                  background: '#E3F5FF',
                  color: '#356F92',
                  borderRadius: '50%',
                }}
              />
            </ExpandMore>
          </Grid>
        </Grid>
      </Box>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography
            sx={{
              ml: '25px',
              fontFamily: 'HK Grotesk',
              fontSize: '18px',
              fontWeight: '500',
              color: '#505050',
            }}
          >
            {data?.initiativeDetails}
          </Typography>
          <Typography
            sx={{
              ml: '25px',
              fontFamily: 'HK Grotesk',
              fontSize: '18px',
              fontWeight: 'bold',
              color: '#3571ED',
            }}
          >
            {' '}
            #{data?.hashTags?.replace(/^#+/, '')}{' '}
          </Typography>
          <Typography
            sx={{
              ml: '25px',
              mt: '11px',
              fontFamily: 'HK Grotesk',
              fontSize: '18px',
              fontWeight: 'bold',
              color: '#505050',
            }}
          >
            {' '}
            Target Date :
            <Typography variant="span" className="target-date-span-style name-color">
              {' '}
              {data?.tagetDate && Moment(data?.tagetDate).format('DD MMMM YYYY')}
            </Typography>{' '}
          </Typography>
          <Typography
            sx={{
              ml: '25px',
              mt: '15px',
              fontFamily: 'HK Grotesk',
              fontSize: '18px',
              fontWeight: 'bold',
              color: '#505050',
            }}
          >
            Steps:
          </Typography>
          {dataSteps.map((line) => (
            <Typography
              key={line}
              sx={{
                ml: '25px',
                fontFamily: 'HK Grotesk',
                fontSize: '18px',
                fontWeight: '500',
                color: '#505050',
              }}
            >
              {line}
            </Typography>
          ))}
          {data?.stepsPdf[0] && (
            <Button
              sx={{ maxWidth: '400px' }}
              onClick={() => {
                handlePdfClickOpen(data?.stepsPdf[0])
              }}
              className="pdfButton ml15 pdf-button-open-style "
            >
              <Typography sx={{ fontFamily: 'HK Grotesk', fontWeight: 'bold' }} className="uploadPdfClass">
                <img src={pdf2} alt="pdf" width="20px" />
              </Typography>

              <Typography className="pdfEllipse" sx={{ whiteSpace: 'pre-wrap' }}>
                {handleFileName(data?.stepsPdf[0])}
              </Typography>
            </Button>
          )}
          {/*  */}
        </CardContent>
      </Collapse>
      <Divider className="cover-divider"></Divider>
    </>
  )
}
export default Details
