import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SevaUpdates from '../SevaUpdates'
import { getMpProfile } from '../../../store/action/individualMP'
import { getIds } from '../../ReusableComponents.js/getIds'
import { Box } from '@mui/material'

const MySevaUpdate = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  // getting userId to support opening link in new page, react-router-dom(useLocation) don't support this
  const loggedInId = getIds()
  // const ranksCount = useSelector(state => state?.ranksCount?.data)
  // get userId
  let MpName = location?.state?.MpName || 'My Seva Rank'
  let user = location?.state?.user
  let Mpid = location?.state?.mpId || loggedInId

  useEffect(() => {
    dispatch(getMpProfile(getIds()))
  }, [])

  return (
    <Box>
      <SevaUpdates mpName={MpName} user={user} mySeva={true} isEdit={true} mpId={Mpid} />
    </Box>
  )
}

export default MySevaUpdate
