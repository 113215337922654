import { Button } from '@mui/material'
import axiosInstance from 'utils/api'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNotificationContext } from 'utils/NotificationContext'
import './PinButtons.css'
import { getDevelopementAcitivites } from 'store/action/pinActivity'
const PinButtons = ({ title, setOpen, activityId, mpIdLists, pinnedCount, setPinnedCount, sortBy }) => {
  const dispatch = useDispatch()
  const { showNotification } = useNotificationContext()
  const activity = useSelector((state) => state?.activity?.data)

  const handleCancel = () => {
    setOpen(false)
  }

  const handleYes = async (activityId) => {
    if (activity?.pinnedCount !== 10) {
      let endPoint = `api/admin/pin-dev-projects/${activityId}?action=pin`
      await axiosInstance
        .get(endPoint)
        .then((response) => {
          const result = response.data
        })
        .catch((error) => {
          const errorMsg = error.message
        })
      dispatch(getDevelopementAcitivites(mpIdLists, sortBy))
      setOpen(false)
      showNotification('Success', 'This activity is pinned on the Home page carousel of Citizen View', 'success')
    } else {
      setOpen(false)
      showNotification('Error', '10 activities are already pinned', 'error')
    }
  }

  return (
    <>
      <Button
        variant="contained"
        sx={{ backgroundColor: '#ef7335' }}
        className={`${title === 'yes' ? 'button-2' : 'button-tr-citizen-cancel1'}`}
        onClick={() => {
          title === 'yes' ? handleYes(activityId) : handleCancel()
        }}
      >
        {title === 'yes' ? 'Yes' : 'cancel'}
      </Button>
    </>
  )
}
export default PinButtons
