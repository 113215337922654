import { Box, Typography, TextField, FormControl, Grid, FormHelperText } from '@mui/material'
import './AddTextTemplateInputField.css'

const AddTextTemplateInputField = ({
  textFieldText,
  boxClassName,
  formControlSx,
  textFieldSx,
  inputProps,
  rows,
  registerFunction,
  placeholder,
  onKeyDown,
  errorMessage,
  name,
  SelectProps,
  defaultValue,
  data,
  onChange,
  select,
}) => {
  const errorStyles = {
    color: '#D93025',
    position: 'absolute',
    top: '100%',
    fontWeight: 'bold',
  }

  return (
    <Box className={boxClassName}>
      <Typography component="b" className="titles">
        {textFieldText}
      </Typography>
      <FormControl sx={formControlSx}>
        <TextField
          className="stepperFormInput"
          fullWidth
          placeholder={placeholder}
          size="small"
          multiline
          rows={rows}
          required
          sx={textFieldSx}
          inputProps={inputProps}
          autoComplete="off"
          onKeyDown={onKeyDown}
          {...registerFunction()}
          name={name}
          SelectProps={SelectProps}
          defaultValue={defaultValue}
          onChange={onChange}
          select={select}
        >
          {data}
        </TextField>
        <Grid container>
          <Grid item xs={8}>
            <FormHelperText sx={errorStyles}>{errorMessage}</FormHelperText>
          </Grid>
        </Grid>
      </FormControl>
    </Box>
  )
}

export default AddTextTemplateInputField
