import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import SevaUpdateHeader from 'Components/SevaUpdateRefactored/SevaUpdateHeader/SevaUpdateHeader'
import SevaUpdateContent from 'Components/SevaUpdateRefactored/SevaUpdateContent/SevaUpdateContent'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import { pushTag } from 'utils/gtmservice'
import { getIds } from 'Components/ReusableComponents.js/getIds'
import { makeLogRequest } from 'utils/AuditLogs'
import SevaUpdateHighlights from 'Components/SevaUpdateRefactored/SevaUpdateHighlights/SevaUpdateHighlights'
import './SevaUpdates.css'

const SevaUpdates = () => {
  const location = useLocation()
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const [pageTitle, setPageTitle] = useState('')
  const loggedInId = getIds()

  useEffect(() => {
    document.getElementsByClassName('App')[0].scrollTop = 0
  }, [location])

  useEffect(() => {
    const page_title = 'nationwide_update_page'
    setPageTitle(page_title)
    postAuditLogs('pageload')
    sendGALog('pageload', null, null, page_title)
  }, [])

  const postAuditLogs = async (sqId) => {
    let requestData = {
      useridtype: getIds(),
    }
    await makeLogRequest('nationwide_seva_updates', sqId, requestData)
  }

  const sendGALog = (event, message = null, category = null, page_title_prop = null) => {
    const page_title = pageTitle || page_title_prop
    const payload = {
      event,
      page_title,
    }
    if (message) payload.message = message
    if (category) payload.category = category
    pushTag(payload)
  }

  return (
    <Box className="page-wrapper d-flex">
      <SideMenu activeTab={'Seva'} />

      <Box className="main-wrapper center-width customscroll">
        <Grid container>
          <Grid xs={12} md={8} lg={8} xl={8} className="max-width-max-content">
            <SevaUpdateHeader title={'Nationwide Seva Updates'} />
            <SevaUpdateContent sendGALog={sendGALog} pageTitle={pageTitle} mpProfileData={mpProfileData} />
          </Grid>
          <SevaUpdateHighlights
            loggedInId={loggedInId}
            postAuditLogs={postAuditLogs}
            sendGALog={sendGALog}
            pageTitle={pageTitle}
          />
        </Grid>
      </Box>
    </Box>
  )
}

export default SevaUpdates
