import * as Yup from 'yup';

export const createInitiativeSchema = Yup.object().shape({
  initiativeName: Yup.string().required('Please enter Initiative Name'),
  // .matches(alphabetValidation, languageJson.firstname_Not_valid_txt)
  // .trim()
  // .min(1, languageJson.firstname_1characters_txt),
  initiativeDetails: Yup.string().required('Please enter Initiative Details'),
  hashTags: Yup.string().required('Please enter a hashtag'),
  targetDate: Yup.string().required('Please choose a target date'),
  steps: Yup.string().required('Please enter Steps'),
});

// export const textTemplatesSchema = Yup.object().shape({
//     language: Yup.string()
//         .required("Required"),
//     text: Yup.string()
//         .required("Required"),
//     text1: Yup.string()
//         .required("Required"),
//     hashTag: Yup.string()
//         .required("Required"),
// });
