import { Box, Tabs } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { useState, useEffect, useRef } from 'react'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { useDispatch } from 'react-redux'
import { getMpList } from '../../../store/action/mpList'
import { getMpProfile } from '../../../store/action/individualMP'
import LeaderBoardTab from '../LeaderBoardTab/LeaderBoardTab'
import { pushTag } from '../../../utils/gtmservice'
import { getIds } from '../../ReusableComponents.js/getIds'
import './LeaderBoardTabs.css'

const LeaderBoardTabs = ({ dataFilter, setSelectedCard, flag, setFlag, setCardLoading, tenure }) => {
  const isBdayCampaignEnabled = JSON.parse(localStorage.getItem('isBdayCampaignEnabled') || false)
  const isSurveyCampaignEnabled = JSON.parse(localStorage.getItem('isSurveyCampaignEnabled') || false)
  const [tabactive, settabactive] = useState(!isSurveyCampaignEnabled ? 0 : 1)
  const containerRef = useRef(null)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollRight, setScrollRight] = useState(true)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const loggedInId = getIds()
  const dispatch = useDispatch()

  const sendGALog = (event, message) => {
    pushTag({
      event: event,
      page_title: 'leaderboard_page',
      message: message,
    })
  }

  useEffect(() => {
    const lastData = JSON.parse(localStorage.getItem('leaderBoardSelectedItem') || '{}')
    const { tabId, card, flag, scrollDirection } = lastData
    setSelection(tabId, card, flag, scrollDirection, false)
  }, [])

  useEffect(() => {
    fetchAllData()
  }, [flag, dataFilter, tenure])
  const fetchAllData = async () => {
    try {
      setCardLoading(true)
      const lastData = JSON.parse(localStorage.getItem('leaderBoardSelectedItem') || '{}')
      const { flag: previouslySelected } = lastData
      await dispatch(getMpProfile(loggedInId))
      await dispatch(getMpList(previouslySelected || flag, 1, dataFilter, tenure === 'Modi2.0' ? '2.o' : null))
    } catch (error) {
      console.log('Error', error)
    } finally {
      setCardLoading(false)
    }
  }
  const handleMouseMove = (event) => {
    if (!isDragging) return
    event.preventDefault()
    const x = event.pageX - containerRef.current.offsetLeft
    const distance = (x - startX) * 2
    containerRef.current.scrollLeft = scrollLeft - distance
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const handleMouseDown = (event) => {
    setIsDragging(true)
    setStartX(event.pageX - containerRef.current.offsetLeft)
    setScrollLeft(containerRef.current.scrollLeft)
  }

  useEffect(() => {
    setScrollLeft(containerRef.current.scrollLeft)
    const container = containerRef.current
    if (
      Math.floor(container.scrollLeft) + container.clientWidth == container.scrollWidth ||
      Math.floor(container.scrollLeft) + container.clientWidth + 1 == container.scrollWidth ||
      Math.ceil(container.scrollLeft) + container.clientWidth == container.scrollWidth
    ) {
      setScrollRight(false)
    } else {
      setScrollRight(true)
    }
  }, [containerRef?.current?.scrollLeft])

  const scrollLeftTab = () => {
    const container = containerRef.current
    if (
      Math.floor(container.scrollLeft) + container.clientWidth == container.scrollWidth ||
      Math.floor(container.scrollLeft) + container.clientWidth + 1 == container.scrollWidth ||
      Math.ceil(container.scrollLeft) + container.clientWidth == container.scrollWidth
    ) {
      setScrollRight(false)
    } else {
      setScrollRight(true)
    }
    if (container.scrollLeft > 0) {
      containerRef.current.scrollLeft = containerRef?.current?.scrollLeft - container.offsetWidth / 2
      // container.scrollTo({
      //   left: container.scrollLeft - container.offsetWidth / 2,
      //   behavior: "smooth",
      // });
    }
  }

  const scrollRightTab = () => {
    const container = containerRef.current
    if (
      Math.floor(container.scrollLeft) + container.clientWidth == container.scrollWidth ||
      Math.floor(container.scrollLeft) + container.clientWidth + 1 == container.scrollWidth ||
      Math.ceil(container.scrollLeft) + container.clientWidth == container.scrollWidth
    ) {
      setScrollRight(false)
    } else {
      setScrollRight(true)
    }
    if (container.scrollLeft < container.scrollWidth - container.offsetWidth) {
      container.scrollTo({
        left: container.scrollLeft + container.offsetWidth,
        behavior: 'smooth',
      })
    }
  }

  const defaultTab = isSurveyCampaignEnabled ? 0 : 1
  const defaultCard = isSurveyCampaignEnabled ? 'survey' : 'sevaScore'
  const defaultFlag = isSurveyCampaignEnabled ? 99 : 101

  const setSelection = (
    tabId = defaultTab,
    card = defaultCard,
    flag = defaultFlag,
    scrollDirection = 'left',
    saveSelection = true
  ) => {
    settabactive(tabId)
    setSelectedCard(card)
    setFlag(flag)
    scrollDirection === 'left' ? scrollLeftTab() : scrollRightTab()
    saveSelection &&
      localStorage.setItem('leaderBoardSelectedItem', JSON.stringify({ tabId, card, flag, scrollDirection }))
    const parameters = {
      useridtype: getIds(),
    }
    makeLogRequest('mp_leaderboard', log[card], parameters)
    sendGALog('select_option', log[card])
  }

  const log = {
    sevaScore: 'mp_seva_score',
    memberAdded: 'members_added',
    followers: 'followers',
    twitterPerformance: 'twitter_performance',
    mediaCoverage: 'op_eds_and_media_coverage',
    donation: 'donations',
    initiatives: 'initiatives',
  }

  const tabsData = [
    {
      tabTitle: 'E-Cards Campaign',
      currentIndex: -1,
      type: 'ecards',
      value: 100,
      direction: 'left',
      disabled: !isBdayCampaignEnabled,
    },
    {
      tabTitle: 'MP Seva Score',
      currentIndex: 1,
      type: 'sevaScore',
      value: 101,
      direction: 'left',
    },
    {
      tabTitle: 'Jan Man Survey',
      currentIndex: 0,
      type: 'survey',
      value: 99,
      direction: 'left',
      disabled: !isSurveyCampaignEnabled,
    },
    {
      tabTitle: 'Initiatives',
      currentIndex: 2,
      type: 'initiatives',
      value: 102,
      direction: 'left',
    },
    {
      tabTitle: 'Members Added',
      currentIndex: 3,
      type: 'memberAdded',
      value: 103,
      direction: 'left',
    },
    {
      tabTitle: 'Followers',
      currentIndex: 4,
      type: 'followers',
      value: 104,
      direction: 'left',
    },
    {
      tabTitle: 'Twitter Performance',
      currentIndex: 5,
      type: 'twitterPerformance',
      value: 105,
      direction: 'right',
    },
    {
      tabTitle: 'Op-Eds & Media Coverage',
      currentIndex: 6,
      type: 'mediaCoverage',
      value: 106,
      direction: 'right',
    },
    {
      tabTitle: 'Donations',
      currentIndex: 7,
      type: 'donation',
      value: 107,
      direction: 'right',
    },
  ]

  return (
    <Box className="filter-btns no-gutters pt-3 leaderboard-options-outer-container cursorshow">
      <ChevronLeft
        sx={{
          color: scrollLeft === 0 ? 'grey' : 'black',
          cursor: scrollLeft !== 0 && 'pointer',
        }}
        onClick={scrollLeftTab}
      />
      <Tabs
        variant="scrollable"
        scrollButtons={false}
        aria-label="scrollable auto tabs example"
        sx={{ width: '94.5%' }}
      >
        <Box
          aria-label="scrollable auto tabs example"
          ref={containerRef}
          className="leaderBoardScrollbar leaderboard-options-inner-container"
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
        >
          {tabsData.map((tabData, index) =>
            tabData?.disabled ? null : (
              <LeaderBoardTab
                key={tabData.currentIndex}
                tabTitle={tabData.tabTitle}
                tabactive={tabactive}
                currentIndex={tabData.currentIndex}
                onClick={() => {
                  setSelection(tabData.currentIndex, tabData.type, tabData.value, tabData.direction)
                }}
              />
            )
          )}
        </Box>
      </Tabs>
      <ChevronRight
        sx={{
          color: !scrollRight ? 'grey' : 'black',
          cursor: scrollRight && 'pointer',
        }}
        onClick={scrollRightTab}
      />
    </Box>
  )
}

export default LeaderBoardTabs
