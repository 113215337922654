import { Tabs, TabsList, Tab, tabClasses, buttonClasses } from '@mui/base'
import { styled } from '@mui/system'

const StyledTab = styled(Tab)`
  font-family: 'HK Grotesk';
  color: #707a89;
  cursor: pointer;
  font-size: 0.875rem;
  background-color: transparent;
  width: 90%;
  line-height: 1.5;
  padding: 8px 12px;
  margin: 6px;
  border: none;
  border-radius: 70px;
  display: flex;
  justify-content: center;

  &.${tabClasses.selected} {
    background-color: #356f92;
    color: #fff;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
    min-width: 230px;
    background-color: #fff;
    border-radius: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    };
    `
)

const IntervalTabs = ({ setDataFilter, defaultVal, setDefaultVal, firstValue, secondValue }) => {
  const handleTabChange = (event, newValue) => {
    setDataFilter(newValue)
    setDefaultVal(newValue)
  }

  return (
    <Tabs defaultValue={defaultVal} onChange={handleTabChange} className="ml-2">
      <StyledTabsList>
        <StyledTab value={firstValue === '15 Days' ? '15days' : 'Modi3.0'}>{firstValue}</StyledTab>
        <StyledTab value={secondValue === 'All Time' ? 'alltime' : 'Modi2.0'}>{secondValue}</StyledTab>
      </StyledTabsList>
    </Tabs>
  )
}

export default IntervalTabs
