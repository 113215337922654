import { USER_PROFILE_REQUEST, GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_FAILURE } from './types';
import axiosInstance from '../../utils/api';

export const getUser = () => {
  return {
    type: USER_PROFILE_REQUEST,
  };
};
export const getUserProfileSuccess = (value) => {
  return {
    type: GET_USER_PROFILE_SUCCESS,
    payload: value,
  };
};
export const getUserProfileFailure = (error) => {
  return {
    type: GET_USER_PROFILE_FAILURE,
    payload: error,
  };
};

const getUserProfile = () => async (dispatch) => {
  dispatch(getUser);
  await axiosInstance
    .get(`/api/user/profile`)
    .then((response) => {
      const result = response.data;
      // const result = mpList
      dispatch(getUserProfileSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(getUserProfileFailure(errorMsg));
    });
};

export default getUserProfile;
