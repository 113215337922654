import { Card, Box } from '@mui/material'
import React, { useEffect } from 'react'
import rightArrow from '../../../asserts/images/arrowRight.png'
import MemberIcon from '../../../asserts/images/MemberIcon.png'
import DonationIcon from '../../../asserts/images/InviteDonation.png'
import CrossIcon from '../../../asserts/images/Close.svg'
import Copyo from '../../../asserts/images/copy1.svg'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { getIds } from '../getIds'
import { pushTag } from '../../../utils/gtmservice'
import './ProfileCard.css'
import ProfileCardComponent from './ProfileCardComponent/ProfileCardComponent'
import ProfileCardDialog from './ProfileCardDialog/ProfileCardDialog'
import { useSelector } from 'react-redux'

const ProfileCard = ({ page_title }) => {
  const [addMembers, setAddMembers] = React.useState(false)
  const [inviteDonations, setInviteDonations] = React.useState(false)
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  let code = localStorage.getItem('Referal')
  let link = `https://www.narendramodi.in/downloadapp?referralCode=${code}`
  let link2 = `https://www.narendramodi.in/donation/${code}`

  const postAuditLogsforPopups = async (sqId) => {
    await makeLogRequest(addMembers ? 'add_members_popup' : inviteDonations && 'invite_donations_popup', sqId, {
      useridtype: getIds(),
    })
  }

  useEffect(() => {
    if (addMembers || inviteDonations) {
      postAuditLogsforPopups('pageload')
    }
    if (addMembers) {
      pushTag({
        event: 'pop_up',
        page_title: 'expand_movement_popup',
      })
    }
    if (inviteDonations) {
      pushTag({
        event: 'pop_up',
        page_title: 'invite_donations_popup',
      })
    }
  }, [addMembers, inviteDonations])

  const handleProfileCardLog = (type) => {
    const parameters = {
      useridtype: mpProfileData?.id,
    }
    makeLogRequest('mp_leaderboard', type, parameters)
    pushTag({
      event: 'select_option',
      page_title: page_title,
      message: type,
    })
  }

  return (
    <>
      <Card className="right-card-3 card">
        <Box className="right-card-1">
          <Box className="d-flex-gap-1 right-card-bottom-elements-wrapper">
            <ProfileCardComponent
              AddMembersCard={true}
              ProfileIcon={MemberIcon}
              rightArrow={rightArrow}
              ProfileCardComponentText={'Add members to NaMo App'}
              onClick={() => {
                setAddMembers(true)
                handleProfileCardLog('add_members_to_namo_app')
              }}
            />
            <ProfileCardComponent
              ProfileIcon={DonationIcon}
              rightArrow={rightArrow}
              ProfileCardComponentText={'Invite <br/> Donations'}
              onClick={() => {
                setInviteDonations(true)
                handleProfileCardLog('invite_donations')
              }}
            />
          </Box>
        </Box>
      </Card>
      <ProfileCardDialog
        option={addMembers}
        CrossIcon={CrossIcon}
        onClose={() => (
          setAddMembers(false),
          postAuditLogsforPopups('close'),
          pushTag({
            event: 'back',
            page_title: 'expand_movement_popup',
          })
        )}
        PaperProps={{
          style: {
            width: '600px',
          },
        }}
        onClickOnClose={() => (
          setAddMembers(false),
          postAuditLogsforPopups('close'),
          pushTag({
            event: 'back',
            page_title: 'expand_movement_popup',
          })
        )}
        ProfileCardDialogTitle={'Expand the Movement'}
        ProfileCardDialogSubTitle={'Invite more members to be New India Volunteer'}
        code={code}
        Copyo={Copyo}
        postAuditLogsforPopups={postAuditLogsforPopups}
        content={`Join me on NM App in the journey to transform India. Click ${link} & Enter code ${code}`}
        linkurl={link}
        selectedOption={'addMembers'}
      />

      <ProfileCardDialog
        option={inviteDonations}
        CrossIcon={CrossIcon}
        onClose={() => (
          setInviteDonations(false),
          postAuditLogsforPopups('close'),
          pushTag({
            event: 'back',
            page_title: 'invite_donations_popup',
          })
        )}
        PaperProps={{
          style: {
            width: '600px',
            height: '480px',
            overflowY: 'hidden',
          },
        }}
        onClickOnClose={() => (
          setInviteDonations(false),
          postAuditLogsforPopups('close'),
          pushTag({
            event: 'back',
            page_title: 'invite_donations_popup',
          })
        )}
        ProfileCardDialogTitle={'Invite Donations'}
        ProfileCardDialogSubTitle={'Encourage contributions towards strengthening <br/>a party of New India Builders.'}
        code={code}
        Copyo={Copyo}
        postAuditLogsforPopups={postAuditLogsforPopups}
        linkurl={link2}
        selectedOption={'donation'}
        content={`I donated to the party of aspirations of all Indians. I contributed towards strengthening a party of New India builders under PM Modi. You can do it too! Use my Code ${code}  
${link2} via Namo App`}
      />
    </>
  )
}

export default ProfileCard
