import { DialogActions } from '@mui/material'
import { useRef } from 'react'
import AddMoreButton from '../AddMoreButton/AddMoreButton'
import UploadAssetDialogButton from '../UploadAssetDialogButton/UploadAssetDialogButton'

const UploadAssetDialogButtonsSection = ({ onChange, onClickForSaveButton, onClickForCancelButton, fileFormats }) => {
  const AddMoreFileInput = useRef(null)

  const handleAddMore = (event) => {
    AddMoreFileInput.current.value = ''
    AddMoreFileInput.current?.click()
    event?.stopPropagation()
  }

  return (
    <DialogActions sx={{ justifyContent: 'flex-start', marginBottom: '10px', marginLeft: '15px' }}>
      <input
        type="file"
        multiple
        onChange={onChange}
        accept={fileFormats.join(', ')}
        ref={AddMoreFileInput}
        class="d-none"
      />

      <AddMoreButton
        boxSx={{
          display: 'flex',
          flexDirection: 'row',
          mb: 2,
          marginLeft: '-15px',
        }}
        buttonTitle={'Add More'}
        type={'asset'}
        onClick={(e) => handleAddMore(e)}
      />

      <UploadAssetDialogButton
        variant={'contained'}
        autoFocus={true}
        className={'button-tr-2'}
        buttonTitle={'Save'}
        buttonSx={{ backgroundColor: '#ef7335', left: '50%' }}
        onClick={onClickForSaveButton}
      />

      <UploadAssetDialogButton
        variant={'outlined'}
        className={'button-tr-citizen-cancel'}
        buttonSx={{
          p: 1,
          mr: 1,
          backgroundColor: '#ef7335',
          borderRadius: 4,
          position: 'relative',
          left: '40%',
        }}
        onClick={onClickForCancelButton}
        buttonTitle={'Cancel'}
      />
    </DialogActions>
  )
}

export default UploadAssetDialogButtonsSection
