import {
  Breadcrumbs,
  Button,
  Dialog,
  Divider,
  Grid,
  Hidden,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
  Box,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import ItemData from './itemData'
import { useSelector } from 'react-redux'
import { saveAs } from 'file-saver'
// import VideosFolder from "./VideosFolder";
import close from '../../../asserts/images/sharing/Close.svg'
import groupIcon from '../../../asserts/images/sharing/Illustration.svg'
import '../ImagesShare/ShareRefactor.css'
import LanguageData from '../ImagesShare/LanguageData'
import VideosFolder from './VideosFolder'
import { VideoLoader } from '../../ReusableComponents.js/VideoLoader'
import DownloadScore from '../../ReusableComponents.js/DownloadScore'
import { getIds } from '../../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../../utils/AuditLogs'
import zipIcon from '../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../asserts/images/FileIcon.svg'
import unknownImg from '../../../asserts/images/folders.png'
import { handleFileName } from '../../ReusableComponents.js/reuseMethods'
import { pushTag } from '../../../utils/gtmservice'
import './VideoModal.css'
import { margin } from '@mui/system'
const VideosModal = ({ open, setOpenVideo }) => {
  const [folderName, setFolderName] = useState()
  const [languageName, setLanguageName] = useState()
  const [viewVideo, setViewVideo] = useState('')
  const [videoResponse, setVideoResponse] = useState([])
  const [allVideos, setAllVideos] = useState([])
  const videotemplates = useSelector(
    (state) => state?.socialMediaKit?.data !== [] && state?.socialMediaKit?.data?.videotemplates
  )

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'download_video_pop_up',
      message: message,
    })
  }

  const handleClose = () => {
    setOpenVideo(false)
    setFolderName('')
    setViewVideo('')
    setLanguageName('')
  }
  const zipFormat = ['application/x-zip-compressed', 'application/x-zip', 'zip']
  const aiFormat = ['application/postscript', 'application/illustrator', 'ai', 'postscript']
  const navigate = useNavigate()

  const onFolderClick = () => {
    setLanguageName('')
    setViewVideo('')
    navigate(<ItemData />)
  }

  const onVideosLink = () => {
    setFolderName('')
    setViewVideo('')
    setLanguageName('')
  }

  useEffect(() => {
    const updatedVideoTemplates = videotemplates?.filter((item) => item.video?.length > 5)
    setVideoResponse(updatedVideoTemplates && updatedVideoTemplates)
    setFolderName('')
    setViewVideo('')
    setLanguageName('')
  }, [videotemplates])

  const downloadVideo = async () => {
    await fetch(viewVideo, { headers: { 'Cache-Control': 'no-cache' } })
      .then((res) => res.blob())
      .then((blob) => {
        saveAs(blob, `video.${viewVideo?.split('.')?.pop()}`)
      })
    // DownloadScore(1);
  }

  const downloadAllVideo = () => {
    allVideos.forEach(async (data) => {
      await fetch(data, { headers: { 'Cache-Control': 'no-cache' } })
        .then((res) => res.blob())
        .then((blob) => {
          saveAs(blob, `video.${data?.split('.')?.pop()}`)
        })
    })
    // DownloadScore(allVideos.length);
  }

  useEffect(() => {
    if (open) {
      sendMediaVideoPopUpLog('pageload')
    }
  }, [open])

  useEffect(() => {
    if (languageName) {
      const parameters = {
        useridtype: getIds(),
        content_type: folderName,
      }
      sendMediaVideosLanguagePopUpLog('pageload', parameters)
    }
  }, [languageName])

  useEffect(() => {
    if (languageName) {
      const parameters = {
        useridtype: getIds(),
        content_type: folderName,
        language_selected: languageName,
      }
      sendMediaVideosLanguagePopUpLog('select_folder', parameters)
    }
  }, [languageName])

  const sendMediaVideoPopUpLog = async (type) => {
    const parameters = {
      useridtype: getIds(),
    }
    await makeLogRequest('social_media_videos_popup', type, parameters)
  }

  const sendMediaVideosLanguagePopUpLog = async (type, parameters) => {
    await makeLogRequest('sm_videos_language_folder_selection', type, parameters)
  }

  const sendDowanloadVideosLog = async (type) => {
    const parameters = {
      useridtype: getIds(),
      content_type: folderName,
      language_selected: languageName,
    }
    sendMediaVideosLanguagePopUpLog(type, parameters)
  }

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '70%',
            minWidth: '70%',
            minHeight: '90%', // Set your width here
          },
        },
      }}
    >
      <img
        src={close}
        className="cancel-icon cursorshow"
        onClick={() => {
          sendMediaVideoPopUpLog('close')
          handleClose()
        }}
      />
      <Box className="div-st">
        <Typography
          sx={{
            fontFamily: 'HK Grotesk',
            color: '#2e739c',
            fontWeight: '700',
            fontSize: '20px',
            mt: 1,
            ml: 1,
          }}
        >
          Videos
        </Typography>
        <Typography
          sx={{
            fontFamily: 'HK Grotesk',
            color: '#2C2C2C',
            fontWeight: '700',
            fontSize: '16px',
            ml: 1,
          }}
        >
          Select and download the Videos from the below directory.
        </Typography>
        <Divider
          sx={{
            border: '1px solid #356f92',
            width: '98%',
            marginTop: '16px',
            marginBottom: '16px',
            marginLeft: '9px',
          }}
        />
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            fontFamily: 'HK Grotesk',
            color: '#2C2C2C',
            fontWeight: '700',
            fontSize: '16px',
            mt: 1,
          }}
        >
          <Link underline="hover" className="custom-link mgl" href="/" onClick={onVideosLink}>
            Campaign Videos
          </Link>
          {/* {folderName && (
            <Link onClick={onFolderClick} href="/">
              {folderName && folderName?.title}
            </Link>
          )} */}
          {languageName && <Link>{languageName && languageName}</Link>}
        </Breadcrumbs>
        <Box className="mgl mgt">
          <Grid container spacing={1.8}>
            <Grid
              item
              xs={3}
              md={5.2}
              margin="13px"
              sx={{
                backgroundColor: '#f5f6fa',
                borderRadius: '20px',
                minHeight: '70vh',
                maxHeight: '71vh',
                overflowY: 'scroll',
                paddingBottom: '5px',
              }}
              className="video-folder-box"
            >
              {/* {!folderName ? (
                <ItemData setFolderName={setFolderName} />
              ) : folderName && languageName ? (
                <VideosFolder
                  videoResponse={videoResponse}
                  languageData={languageName}
                  setViewVideo={setViewVideo}
                  folderName={folderName}
                />
              ) : (
                <LanguageData setLanguageName={setLanguageName} />
              )} */}
              {languageName ? (
                <VideosFolder
                  videoResponse={videoResponse}
                  languageData={languageName}
                  setViewVideo={setViewVideo}
                  folderName={folderName}
                  setAllVideos={setAllVideos}
                />
              ) : (
                <LanguageData setLanguageName={setLanguageName} videoResponse={videoResponse} />
              )}
            </Grid>
            <Grid
              item
              xs={9}
              md={5.9}
              margin="15px"
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #DDDDDD',
                borderRadius: '20px',
              }}
            >
              {viewVideo ? (
                // <>
                //   <img
                //     src={`${viewVideo?.img}?w=164&h=164&fit=crop&auto=format`}
                //     srcSet={`${viewVideo.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                //     alt={viewVideo.subTitle}
                //     loading="lazy"
                //   />
                //   <Button>Download</Button>
                //   <Button>Download All</Button>
                // </>
                <>
                  <Box
                    className="set-video-div"
                    onClick={() => {
                      setViewVideo(viewVideo)
                    }}
                  >
                    {!['webm', 'mp4', 'webM'].includes(viewVideo.split('.').pop()) ? (
                      <ImageListItem
                        // key={}
                        className="bgfolder cursorshow"
                        sx={{
                          margin: '40px 100px',
                          width: '300px !important',
                          padding: '10px',
                          aspectRatio: '1 / 0.9 !important',
                        }}
                      >
                        <img
                          className="video-zip"
                          // key={i}
                          src={
                            viewVideo.split('.').pop() === 'zip'
                              ? zipIcon
                              : ['ai', 'postscript'].includes(viewVideo.split('.').pop())
                              ? fileIcon
                              : unknownImg
                          } //?w=164&h=164&fit=crop&auto=format
                          srcSet={
                            viewVideo.split('.').pop() === 'zip'
                              ? zipIcon
                              : ['ai', 'postscript'].includes(viewVideo.split('.').pop())
                              ? fileIcon
                              : unknownImg
                          } //?w=164&h=164&fit=crop&auto=format
                          // alt={viewVideo.title}
                        />
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontFamily: 'HK Grotesk',
                            color: '#356F92',
                            fontSize: '14px',
                            fontWeight: 'bold',
                            margin: '0 auto',
                            textAlign: 'center',
                          }}
                        >
                          {handleFileName(viewVideo)}
                        </Typography>
                      </ImageListItem>
                    ) : (
                      // {viewVideo.split('.').pop()==="zip" &&
                      //             <div>
                      //             <img
                      //             // className=" im100 cursorshow"
                      //             style={{
                      //               width:"100%",
                      //               padding:"20px",
                      //             position: "relative",
                      //             src={zipIcon} onClick={() => setViewVideo(viewVideo)}/>
                      //             </div>
                      // {aiFormat.includes(viewVideo.split('.').pop()) &&
                      //             <div>
                      //             <img
                      // className=" im100 cursorshow"
                      // style={{
                      //   width:"100%",
                      //   padding:"20px",
                      // position: "relative",
                      //  src={fileIcon} onClick={() => setViewVideo(viewVideo)}/>
                      // </div>
                      // {!aiFormat.includes(viewVideo.split('.').pop()) && !zipFormat.includes(viewVideo.split('.').pop() )&&
                      <VideoLoader
                        // iframe={true}
                        width="470"
                        top="44%"
                        height="300"
                        left="240"
                        src={viewVideo || 'https://www.youtube.com/embed/phIuP8XU9KQ'}
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      />
                    )}
                  </Box>
                  {/* <iframe
                    width="320"
                    height="300"
                    src={
                      // viewVideo?.video?.replace(
                      //   /[\|&;\$%@"<>[\]\(\)\+,']/g,
                      //   ""
                      // )
                      viewVideo || "https://www.youtube.com/embed/phIuP8XU9KQ"
                    }
                    // title={
                    //   viewVideo.title ||
                    //   "Swachh Bharat Abhiyan- Swachh Bharat ka Irada Kar Liya Hum Ne"
                    // }
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  ></iframe> */}
                  <Box className="layout-div">
                    <Button
                      className="button-tr-2"
                      onClick={() => {
                        sendGALog('download')
                        sendDowanloadVideosLog('download')
                        downloadVideo()
                      }}
                    >
                      {' '}
                      Download{' '}
                    </Button>
                    <Button
                      className="w-auto button-tr-citizen-cancel"
                      onClick={() => {
                        sendGALog('download_all')
                        sendDowanloadVideosLog('download_all')
                        downloadAllVideo()
                      }}
                    >
                      Download All
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <Box className="imagemodalfilemana">
                    <img src={groupIcon} />
                    <Typography
                      sx={{
                        fontFamily: 'HK Grotesk',
                        color: '#2e739c',
                        fontWeight: '700',
                        fontSize: '20px',
                        mt: 1,
                      }}
                    >
                      Select an Item to download
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'HK Grotesk',
                        color: '#2C2C2C',
                        fontWeight: '700',
                        fontSize: '16px',
                      }}
                    >
                      Nothing is selected
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  )
}

export default VideosModal
