import { Grid, InputLabel, Typography, Box, FormHelperText, Button } from '@mui/material'
import { BootstrapTooltip } from '../../ReusableComponents.js/ToolTip/ToolTipComponent'
import { clearInputValue } from 'Components/ReusableComponents.js/reuseMethods'
import './CreateInitiativeUploadCoverImageInputField.css'

const CreateInitiativeUploadCoverImageInputField = ({
  acceptedImageFormatsForInitiativeCover,
  hiddenFileInput,
  onFileUpload,
  handleClick,
  imageName,
  setOpenUploadImageDialog,
  checkCoverImage,
  preview,
  sendGALog,
}) => {
  const handleOpenUploadImageDialog = () => {
    setOpenUploadImageDialog(true)
  }

  return (
    <Grid item xs={10} md={10} lg={10} xl={10} sx={{ marginTop: '25px' }}>
      <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#2e739c' }}>
        <Typography className="headincreateini initiative-titles-st">Upload Initiative Cover Image</Typography>
      </InputLabel>
      <BootstrapTooltip
        title="Accepted file formats for open & zip uploads : .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .tiff, .psd, .eps, .ai, .indd, .raw, .webp, .svg, .svgz, .heif"
        placement="right-start"
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: '18px',
              fontFamily: 'HK Grotesk',
              padding: '15px',
            },
          },
        }}
        className="mg-t-1"
      >
        <Box className="cls-input-sty texcretei" sx={{ width: '421px' }}>
          <input
            type="file"
            accept={acceptedImageFormatsForInitiativeCover}
            ref={hiddenFileInput}
            className="in-st-div"
            onChange={onFileUpload}
            onClick={clearInputValue}
            required
          />
          <Box className="div-pre">
            <Button
              onClick={(e) => {
                if (!preview) {
                  sendGALog('browse')
                  handleClick(e, true)
                } else {
                  handleOpenUploadImageDialog()
                }
              }}
              className="div-br-pr"
            >
              {preview ? 'Preview' : 'Browse'}
            </Button>
            {imageName ? (
              <Typography
                sx={{ display: 'block', fontSize: '14px' }}
                className="ellipsewehe"
                onClick={handleOpenUploadImageDialog}
              >
                {imageName}
              </Typography>
            ) : null}
          </Box>
          <FormHelperText sx={{ color: '#d32f2f', marginTop: '6px', fontWeight: '700' }}>
            {checkCoverImage && !imageName && 'Please upload a cover image'}
          </FormHelperText>
        </Box>
      </BootstrapTooltip>
    </Grid>
  )
}

export default CreateInitiativeUploadCoverImageInputField
