import { GET_ALERT_REQUEST, GET_ALERT_SUCCESS, GET_ALERT_FAILURE } from '../../action/types'

const initialState = {
  data: [],
  error: '',
}

export const getNotificationAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALERT_REQUEST:
      return {
        ...state,
      }
    case GET_ALERT_SUCCESS:
      return {
        data: action.payload,
      }
    case GET_ALERT_FAILURE:
      return {
        error: action.payload,
      }
    default:
      return state
  }
}
