import { Button } from '@mui/material'
import './LeaderBoardTab.css'

const LeaderBoardTab = ({ tabTitle, tabactive, onClick, currentIndex }) => {
  return (
    <Button
      type="button"
      className={
        tabactive === currentIndex
          ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
          : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
      }
      onClick={onClick}
    >
      {tabTitle}
    </Button>
  )
}

export default LeaderBoardTab
