import axiosInstance from './api';

export const makeLogRequest = async (flag, sqId, parameters) => {
  delete parameters.user_role;
  delete parameters.useridtype;
  const data = { flag, sqId, parameters };
  try {
    await axiosInstance.post(`/api/log/mpcorner/pa`, data);
  } catch (e) {
    console.log(e);
  }
};
