import React, { useEffect, useMemo, useRef, useState } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import CrossIcon from '../../../../asserts/images/Close.svg'
import unknownImg from '../../../../asserts/images/folders.png'
import { IconButton, Grid, Box, Button, DialogContent, Hidden, ImageListItem, Typography } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import closeIconp from '../../../../asserts/images/close-1.png'
import { useNotificationContext } from '../../../../utils/NotificationContext'
import AddIcon from '@mui/icons-material/Add'
import VideoPlayer from '../../../ReusableComponents.js/VideoPlayer'
import { useSelector } from 'react-redux'
import { makeLogRequest } from '../../../../utils/AuditLogs'
import { VideoLoader } from '../../../ReusableComponents.js/VideoLoader'
import { handleFileName, handleVideoSrc } from '../../../ReusableComponents.js/reuseMethods'
import { useLoading } from '../../../../utils/LoadingContext'
import zipIcon from '../../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../../asserts/images/FileIcon.svg'
import './UploadVideoDialog.css'
const UploadVideos = ({
  handleCloseUploadDialog,
  state,
  setValue,
  getValue,
  setVideosNew,
  initialVideos,
  setInputData,
  fileFormats,
  setCheckWarningPopup,
}) => {
  const [isCheckFirstTime, setIsCheckFirstTime] = useState(true)
  const videos = useRef([])
  const [videosCount, setVideosCount] = useState(-1)
  const tempVideosForOperationsRef = useRef([])
  const originalStateForVideosRef = useRef([])
  const tempForRemovingVideosRef = useRef([])
  const [isVideoDeleted, setIsVideoDeleted] = useState(false)

  useEffect(() => {
    if (isCheckFirstTime) {
      setIsVideoDeleted(false)
      videos.current = initialVideos.length > 0 && initialVideos
      setVideosCount(videos.current.length)
      originalStateForVideosRef.current = [...videos.current]
      tempVideosForOperationsRef.current = originalStateForVideosRef.current
      setIsCheckFirstTime(false)
    }
  }, [])

  const [uploadMore, setUploadMore] = useState(false)
  const { showNotification } = useNotificationContext()
  const fileInputRef = useRef(null)
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const { setLoading } = useLoading()

  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )
  const countExistingZips = () => {
    let zipCount = 0
    videos.current.forEach((file) => {
      if (file.type === 'zip') {
        zipCount++
      }
    })
    return zipCount
  }
  const zipFormat = ['application/x-zip-compressed', 'application/x-zip', 'zip']
  const aiFormat = ['application/postscript', 'application/illustrator', 'ai']
  const mtsFormat = ['video/vnd.dlna.mpeg-tts', 'video/mp2t']

  const handleVideoChange = async (e) => {
    setLoading(true)
    await postAuditLogs('upload_more')
    const uploadedFiles = e.target.files

    if (uploadedFiles.length > 0) {
      setCheckWarningPopup(true)
    }
    let newFiles = []
    let processedVideos = 0
    let remainingZipCount = 5 - countExistingZips()
    let newVideos = []
    let countId = videos[videos.current.length - 1] ? videos[videos.current.length - 1].id : -1
    for (let i = 0; i < uploadedFiles.length; i++) {
      let nameExists = videos.current.some((file) => file?.file?.name === uploadedFiles[i]?.name)
      if (!nameExists) {
        const file = uploadedFiles[i]
        const isRightFormat =
          fileFormats.includes(file.type) || fileFormats.includes('.' + file?.name?.split('.').pop())
        const fileSizeInBytes = file.size
        const maxSizeInBytes = 1024 * 1024 * 1024 // 1GB
        if (fileSizeInBytes > maxSizeInBytes) {
          showNotification('Error', 'Please upload video with size less than 1GB', 'error')
          return
        }
        if (file.type === 'application/x-zip-compressed' && remainingZipCount > 0) {
          remainingZipCount--
        } else if (file.type === 'application/x-zip-compressed' && remainingZipCount <= 0) {
          // Reject the upload and display an error message
          showNotification('Error', 'Only a maximum of 5 zip files are allowed in total.', 'error')
          return
        }
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          if (!isRightFormat) {
            showNotification('Error', 'You can only upload mp4 videos', 'error')
            return
          } else {
            newVideos.push({
              type: zipFormat.includes(file.type) ? 'zip' : aiFormat.includes(file.type) ? 'ai' : 'video',

              url: zipFormat.includes(file.type)
                ? zipIcon
                : aiFormat.includes(file.type)
                ? fileIcon
                : fileFormats.includes(file.type)
                ? reader.result
                : reader.result,
              file: file,
              id: ++countId,
            })

            processedVideos++

            if (processedVideos === uploadedFiles.length) {
              videos.current = [...videos.current, ...newVideos]
              tempVideosForOperationsRef.current = [...tempVideosForOperationsRef.current, ...newVideos]
              setVideosCount(videos.current.length)
              setUploadMore(true)
            }
          }
        }
      }
    }
  }

  const handleDelete = (index) => {
    setCheckWarningPopup(true)
    const tempVideos = [...videos.current]
    tempVideos.splice(index, 1)
    videos.current = tempVideos
    setVideosCount(videos.current.length)
    tempForRemovingVideosRef.current = tempVideos
    setIsVideoDeleted(true)
  }

  const closeDialog = (event, reason) => {
    if (reason && reason == 'backdropClick') return

    setInputData((prevState) => {
      const newState = [...prevState] // make a copy of the state array
      newState[`${state.for}`] = {
        ...newState[`${state.for}`],
        videosData: originalStateForVideosRef.current,
      }

      return newState
    })

    tempVideosForOperationsRef.current = [...originalStateForVideosRef.current]
    setValue(`${state.for}.videos`, tempVideosForOperationsRef.current)
    videos.current = tempVideosForOperationsRef.current
    setVideosCount(videos.current.length)
    setVideosNew([...tempVideosForOperationsRef.current])

    if (isVideoDeleted) {
      setIsVideoDeleted(false)
    }
    handleCloseUploadDialog(true)
  }

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('video_preview_popup', sqId, data)
  }

  useEffect(() => {
    postAuditLogs('pageload')
  }, [])

  useEffect(() => {
    if (uploadMore) {
      setLoading(false)
      // getValue(`${state.for}`) && setVideos(getValue(`${state.for}`))
      setUploadMore(false)
    }
  }, [videos.current])

  const handleAddMore = (event) => {
    fileInputRef.current.value = ''
    fileInputRef.current?.click()
    event?.stopPropagation()
  }

  const handleSaveButton = async (e) => {
    await postAuditLogs('save')
    handleCloseUploadDialog(true)

    setInputData((prevState) => {
      const newState = [...prevState]

      newState[`${state.for}`] = {
        ...newState[`${state.for}`],
        videosData: isVideoDeleted ? tempForRemovingVideosRef.current : tempVideosForOperationsRef.current,
      }

      return newState
    })

    if (isVideoDeleted) {
      originalStateForVideosRef.current = [...tempForRemovingVideosRef.current]
      setValue(`${state.for}.videos`, tempForRemovingVideosRef.current)
      setVideosNew([...tempForRemovingVideosRef.current])
      videos.current = tempForRemovingVideosRef.current
      tempVideosForOperationsRef.current = [...tempForRemovingVideosRef.current]
      setVideosCount(videos.current.length)
      setIsVideoDeleted(false)
    } else {
      originalStateForVideosRef.current = [...tempVideosForOperationsRef.current]
      setValue(`${state.for}.videos`, tempVideosForOperationsRef.current)
      setVideosNew([...tempVideosForOperationsRef.current])
      videos.current = tempVideosForOperationsRef.current
      setVideosCount(videos.current.length)
    }
  }

  return (
    <Dialog
      onClose={closeDialog}
      open={state.open}
      sx={{ borderRadius: '14px' }}
      disableEscapeKeyDown
      PaperProps={{
        style: {
          height: '500px',
          maxHeight: '500px',
          overflow: 'hidden',
        },
      }}
    >
      <DialogTitle
        sx={{
          fontFamily: 'HK Grotesk ExtraBold',
          color: '#2e739c',
          fontWeight: '700',
          textAlign: 'center',
          fontSize: '26px',
        }}
      >
        Video Preview
      </DialogTitle>
      <img
        onClick={async (e) => {
          await postAuditLogs('close')
          closeDialog(e, undefined)
        }}
        src={CrossIcon}
        className="closeIcon"
      />
      <DialogContent style={{ overflow: 'hidden' }}>
        {/* <span style={{ fontFamily: 'HK Grotesk', color: "#2C2C2C", fontSize: "18px", fontWeight: 'bold', marginLeft: "15px", position: "relative", bottom: "10px" }}>Video Preview</span> */}
        <Box sx={{ height: '85%', overflow: 'auto' }}>
          <div className="image-column-align">
            <>
              {videos.current &&
                videos.current.length > 0 &&
                videosCount &&
                videos.current.map((video, index) => (
                  <>
                    <div key={video.id} className="video-preview-element-position">
                      {handleVideoSrc(video) === 'video' && (
                        <VideoLoader
                          key={video.id}
                          width="200"
                          top="35%"
                          height="150"
                          src={video.url || video.url === '' ? URL.createObjectURL(video.file) : video}
                          controls
                        />
                      )}
                      {['ai', 'unknown', 'zip', 'mts'].includes(handleVideoSrc(video)) && (
                        <div>
                          <ImageListItem
                            key={index}
                            className="bgfolder cursorshow"
                            sx={{
                              margin: '0px',
                              width: '200px !important',
                              padding: '7px 33px 39px',
                              aspectRatio: '1 / 0.9 !important',
                            }}
                          >
                            <img
                              style={{ margin: '0' }}
                              key={index}
                              src={
                                handleVideoSrc(video) === 'ai'
                                  ? fileIcon
                                  : handleVideoSrc(video) === 'zip'
                                  ? zipIcon
                                  : unknownImg
                              }
                              // alt={video.title}
                            />
                            <Typography variant="subtitle1" sx={subtitleStyles}>
                              {handleFileName(video)}
                            </Typography>
                          </ImageListItem>
                        </div>
                      )}

                      {/* <VideoPlayer
                                            key={video.id}
                                            width="200"
                                            top="35%"
                                            height="150"
                                            // className={`fetchVideo${index}`}
                                            src={video.url ? video.url : video}
                                            controls
                                        />
                                              <img src={closeIconp}
                                            onClick={() => handleDelete(index)}
                                            className="imageclose-video"
                                       
                                        /> */}
                      <img src={closeIconp} onClick={() => handleDelete(index)} className="imageclose-video" />
                    </div>
                  </>
                ))}
            </>
          </div>
          {/* <Button onClick={handleClick} variant="contained" sx={{ p: 1, mr: 1, backgroundColor: "#ef7335" }}
                        className="button-primary-alt-contained">Upload Videos</Button> */}
          {/* Add More Videos */}
          {/* </Paper> */}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'start' }}>
        <Button
          variant="outlined"
          className="button-tr-citizen-admin-add-more"
          startIcon={<AddIcon sx={{ mt: '5px' }} />}
          onClick={(e) => handleAddMore(e)}
        >
          <input
            type="file"
            ref={fileInputRef}
            multiple
            class="d-none"
            onChange={handleVideoChange}
            accept={fileFormats.join(', ')}
          />
          Add More
        </Button>

        {/* <Button variant="contained" className='button-tr-2 whte={Styles.button}>
                    <input type="file" multiple onChange={handleVideoChange} accept="video/mp4" style={Styles.input} />
                    Upload More</Button> */}
        <div style={{ display: 'flex' }}>
          <Button
            variant="contained"
            className="button-tr-2"
            autoFocus
            onClick={(e) => handleSaveButton(e)}
            sx={{ backgroundColor: '#ef7335' }}
          >
            Save
          </Button>

          <Button
            variant="outlined"
            className="button-tr-citizen-cancel"
            // className="button-tr-2"
            type="submit"
            onClick={async (e) => {
              await postAuditLogs('cancel')
              closeDialog(e, undefined)
            }}
          >
            Cancel
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  )
}

const Styles = {
  // button: {
  //     position: 'relative',
  //     overflow: 'hidden',
  //     width: 'fit-content'
  // },
  input: {
    position: 'absolute',
    opacity: '0',
    cursor: 'pointer',
  },
}

export default UploadVideos
