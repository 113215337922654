import { Dialog, DialogTitle, Avatar, Typography, Box } from '@mui/material'
import { toast } from 'react-hot-toast'
import { pushTag } from '../../../../utils/gtmservice'
import CopyAndShareClick from '../../CopyAndShareAPI'
import Share from '../../Share'
import './ProfileCardDialog.css'

const ProfileCardDialog = ({
  onClose,
  PaperProps,
  option,
  CrossIcon,
  Copyo,
  onClickOnClose,
  code,
  ProfileCardDialogTitle,
  ProfileCardDialogSubTitle,
  postAuditLogsforPopups,
  content,
  linkurl,
  selectedOption,
}) => {
  return (
    <Dialog open={option} onClose={onClose} PaperProps={PaperProps}>
      <DialogTitle sx={{ mt: 2 }}>
        <Avatar src={CrossIcon} className="closeIcon" onClick={onClickOnClose} />
        <Typography
          sx={{
            color: '#357092',
            fontFamily: 'HK Grotesk ExtraBold !important',
            fontSize: '30px',
            fontWeight: 'bold',
          }}
          className="d-flex justify-content-center"
        >
          {ProfileCardDialogTitle}
        </Typography>
        <Typography
          sx={{
            color: '#505050',
            fontFamily: 'HK Grotesk Bold !important',
            fontSize: '22px',
            fontWeight: 'bold',
            mt: 1,
          }}
          className="d-flex justify-content-center text-center"
          dangerouslySetInnerHTML={{ __html: ProfileCardDialogSubTitle }}
        />
        {selectedOption === 'donation' && (
          <Typography className="refer-now-to-your-friends-text">Refer now to your friends</Typography>
        )}
        <Box className="your-unique-code-wrapper">
          <Box className="sharepopupbg">
            <Typography component="span" className="your-unique-code-text">
              YOUR UNIQUE CODE <Typography className="your-unique-code">{code}</Typography>
            </Typography>
          </Box>
          <Avatar
            variant="square"
            src={Copyo}
            className="copysv cursorshow"
            onClick={() => {
              window.navigator.clipboard
                .writeText(code)
                .then((res) => {
                  toast.dismiss()
                  toast.success('Copied to clipboard.')
                })
                .catch((err) => console.log(err))
              postAuditLogsforPopups('copy_code')
              pushTag({
                event: 'copy',
                page_title: 'expand_movement_popup',
              })
            }}
          />
        </Box>
        <Box className="share-options-container">
          <Share
            CopyAndShareClick={CopyAndShareClick}
            content={content}
            linkurl={linkurl}
            addMembers={selectedOption === 'addMembers' ? true : false}
            donation={selectedOption === 'donation' ? true : false}
          />
        </Box>
      </DialogTitle>
    </Dialog>
  )
}

export default ProfileCardDialog
