import {
  CREATE_INITIATIVE_REPORT_BY_REQUEST,
  CREATE_INITIATIVE_REPORT_BY_SUCCESS,
  CREATE_INITIATIVE_REPORT_BY_FAILURE,
} from '../action/types';

const initialState = {
  data: {},
  error: '',
};

export const createInitiativeReportBy = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_INITIATIVE_REPORT_BY_REQUEST:
      return {
        state,
      };
    case CREATE_INITIATIVE_REPORT_BY_SUCCESS:
      return {
        data: action.payload,
      };
    case CREATE_INITIATIVE_REPORT_BY_FAILURE:
      return {
        data: {},
        error: action.payload,
      };
    default:
      return state;
  }
};
