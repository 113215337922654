import { Box, Typography } from '@mui/material'
import './MPTableHeaderHighlights.css'
const MPTableHeaderHighlights = ({ title }) => {
  return (
    <Box className="highlightsTitle highlightsBox">
      <Typography component="h1" className="highlightText">
        {title}
      </Typography>
    </Box>
  )
}
export default MPTableHeaderHighlights
