import React, { useEffect, useState } from 'react'
import leaderboard from '../../asserts/images/leaderboard-icon.png'
import profile from '../../asserts/images/Main Profile Picture@2x.png'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Box, Typography } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import leaderboard1 from '../../asserts/images/Ic_Leaderboard.svg'
import leaderboard2 from '../../asserts/images/Ic_Logout.svg'
import leaderboard3 from '../../asserts/images/Ic_Seva Updates.svg'
import leaderboard4 from '../../asserts/images/Ic_Your Initiatives.svg'
import leaderboard5 from '../../asserts/images/sevaini.svg'
import leaderboard6 from '../../asserts/images/settingIcon.svg'
import WarningMessageDialog from '../SevaInitiatives/WarningMessageDialog'
import profileicon from '../../asserts/images/profile.png'
import LogoutDialog from '../../utils/LogoutDialog'
import ConnectionCloseError from '../../utils/ConnectionCloseError'
import { useInitiativeContext } from '../../utils/InitiativeContext'
import { getIds, getRoles } from '../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../utils/AuditLogs'
import { pushTag } from '../../utils/gtmservice'
import { env } from '../../env'
import getUserProfile from '../../store/action/getUserProfile'
import ProfilePictureComponent from '../ReusableComponents.js/ProfilePictureComponent'
import { SideMenuProfile } from '../ReusableComponents.js/ProfilePictureStyles'
import WhatsNewSection from '../ReusableComponents.js/WhatsNewSection'

const SideMenu = (props) => {
  const [active, setactive] = useState(props.active)
  const userId = getRoles()
  const { profileData, createInitiative } = props
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const [openWarningDialog, setOpenWarningDialog] = useState(false)
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false)
  const [connectionClosedDialog, setOpenConnectionClosedDialog] = useState(false)
  const [checkWarningClick, setCheckWarningClick] = useState(false)
  const [checkLogoutClick, setCheckLogoutClick] = useState(false)
  const [logoutClick, setLogoutClick] = useState(false)
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const is401ErrorReceived = useSelector((state) => state?.userProfile?.is401)
  const is444ErrorReceived = useSelector((state) => state?.userProfile?.is444)
  const dispatch = useDispatch()
  const [popupOpen, setPopupOpen] = useState(false)
  const handleClickOpenWarningDialog = () => {
    setOpenWarningDialog(true)
  }

  const { resetInitiatives, setResetInitiatives } = useInitiativeContext()

  const handleCloseWarningDialog = () => {
    setOpenWarningDialog(false)
    if (createInitiative) {
      setactive('SevaInitiative')
    }
  }

  const handleClickOpenLogoutDialog = () => {
    setOpenLogoutDialog(true)
  }

  const handleCloseLogoutDialog = () => setOpenLogoutDialog(false)

  const handlePopUp = () => {
    setPopupOpen((prev) => !prev)
  }
  const postAuditLogs = async (sqId) => {
    let user_role = userId == 2 ? 'admin' : userId == 3 ? 'leadership' : 'mp'
    await makeLogRequest('navigation', sqId, {
      useridtype: getIds(),
      user_role,
    })
  }

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'navigation_panel',
      message: message,
    })
  }

  const navigate = useNavigate()
  useEffect(() => {
    if (checkWarningClick) {
      if (active === 'Leader') {
        userId == 2 || userId == 3 ? navigate('/AdminHome') : navigate('/MpHome')
      } else if (active == 'SevaInitiative') {
        if (userId == 2)
          navigate('/SevaInitiatives', {
            state: {
              user: props.user,
            },
          })
      } else if (active === 'Seva') {
        if (userId == 2) {
          userId == 2 || userId == 3
            ? navigate('/SevaUpdates', { state: { user: props.user } })
            : navigate('/SevaUpdates')
        }
      }
      // else if (logoutClick && active === "logout") {
      //   localStorage.removeItem("userId");
      //   localStorage.removeItem("tokenDetails")
      //   localStorage.removeItem("mpId")
      //   navigate("/loginusers.html")
      //   window.location.reload();
      // }
    }
  }, [checkWarningClick])

  useEffect(() => {
    if (checkLogoutClick) {
      localStorage.clear()
      if (env.REACT_APP_TESTLOGIN === '1') {
        navigate('/LoginUsers')
        window.location.reload()
      } else window.location.replace(env.REACT_APP_WEBSITE_URL)
    }
  }, [checkLogoutClick])

  useEffect(() => {
    if (is401ErrorReceived === true) {
      setOpenLogoutDialog(true)
    }
  }, [is401ErrorReceived])

  useEffect(() => {
    is444ErrorReceived && setOpenConnectionClosedDialog(true)
  }, [is444ErrorReceived])

  useEffect(() => {
    dispatch(getUserProfile())
  }, [])

  return (
    <Box className="left-sidebar-b d-flex flex-column">
      <Box className="d-flex d-flex-C" style={{ gap: '5px' }}>
        <Box className="img-circle circularimage3">
          {userId == 2 || userId == 3 ? (
            <img src={profileicon} className="img-circle mr-1 profile-img" width="48" height="48" draggable="false" />
          ) : (
            <ProfilePictureComponent
              ProfilPic={
                profileData?.user_avatar
                  ? profileData?.user_avatar
                  : mpProfileData?.user_avatar
                  ? mpProfileData?.user_avatar
                  : profileicon
              }
              sxProfile={SideMenuProfile}
            />

            // <img
            //   src={
            //     profileData?.user_avatar
            //       ? profileData?.user_avatar
            //       : mpProfileData?.user_avatar
            //         ? mpProfileData?.user_avatar
            //         : profileicon
            //   }
            //   className="img-circle mr-1 profile-img"
            //   width="48"
            //   height="48"
            //   draggable="false"
            //   onError={(e) => {
            //     e.target.src = <AccountCircleIcon sx={{ fontSize: 'xx-large', color: '#EEEEEE' }} />
            //   }}
            // />
          )}
        </Box>
        {userId == 2 || userId == 3 ? (
          <Box className="align-self-center pr-2 sidebarLeaderName">
            {userProfile?.user_name}
            <Box />
          </Box>
        ) : (
          <>
            <Box className="align-self-center pr-2 sidebarLeaderName d-text-align-c">
              {profileData ? profileData?.user_name : mpProfileData?.user_name}

              <span className="logininfo-ui">
                {profileData
                  ? profileData?.house
                    ? profileData?.house + ','
                    : ''
                  : mpProfileData?.house
                  ? mpProfileData?.house + ','
                  : ''}{' '}
              </span>
              <Typography component="span" className="logininfo-ui">
                {profileData
                  ? profileData?.designation
                    ? profileData.designation + ','
                    : ''
                  : mpProfileData?.designation
                  ? mpProfileData?.designation + ','
                  : ''}
              </Typography>
              <Typography component="span" className="logininfo-ui constituencyTitle">
                {' '}
                {profileData
                  ? profileData?.constituency_name
                    ? profileData?.constituency_name + ','
                    : ''
                  : mpProfileData?.constituency_name
                  ? mpProfileData?.constituency_name + ','
                  : ''}
                <br />
                {profileData ? profileData?.state_name : mpProfileData?.state_name}
              </Typography>
            </Box>
          </>
        )}
      </Box>

      <nav>
        <ul>
          <li className="justify-content-between">
            <Button
              className={active == 'Leader' && 'active'}
              onClick={async () => {
                setactive('Leader')
                await postAuditLogs('leaderboard')
                sendGALog('leaderboard')
                if (createInitiative) {
                  handleClickOpenWarningDialog(true)
                } else {
                  {
                    userId == 2 || userId == 3 ? navigate('/AdminHome') : navigate('/MpHome')
                  }
                }
              }}
            >
              <Typography variant="span">
                <img className="mr-3" src={leaderboard1} width="15" alt="" />
                Leaderboard
              </Typography>
            </Button>
            {/* <a href="" className="active" onClick={() => navigate('/MpHome')}><span><img className="mr-3" src={leaderboard} width="15" alt=""/>
                        Leaderboard</span></a> */}
          </li>
          {userId == 2 && (
            <li className="justify-content-between">
              <Button
                className={active == 'SevaInitiative' && 'active'}
                onClick={async () => {
                  setactive('SevaInitiative')
                  await postAuditLogs('seva_initiatives')
                  sendGALog('seva_initiative')
                  if (createInitiative) {
                    handleClickOpenWarningDialog(true)
                  } else {
                    navigate('/SevaInitiatives', {
                      state: {
                        user: props.user,
                      },
                    })
                  }
                }}
              >
                <Typography variant="span">
                  <img className="mr-3" src={leaderboard5} width="15" alt="" />
                  Seva Initiatives
                </Typography>
              </Button>
            </li>
          )}
          <li className="justify-content-between">
            <Button
              className={active == 'Seva' && 'active'}
              onClick={async () => {
                setactive('Seva')
                await postAuditLogs('seva_updates')
                sendGALog('seva_updates')
                if (createInitiative) {
                  handleClickOpenWarningDialog(true)
                } else {
                  userId == 2 || userId == 3
                    ? navigate('/SevaUpdates', { state: { user: props.user } })
                    : navigate('/SevaUpdates')
                }
              }}
            >
              <Typography variant="span">
                <img className="mr-3" src={leaderboard3} width="15" alt="" />
                Seva Updates
              </Typography>
            </Button>
            {/* <a href=""><span><img className="mr-3" src={leaderboard} width="15" alt="" />
                        Save Updates</span></a> */}
          </li>
          {userId != 2 && userId != 3 && (
            <li className="justify-content-between">
              <Button
                className={active == 'Initiatives' && 'active'}
                onClick={async () => {
                  setactive('Initiatives')
                  await postAuditLogs('your_initiatives')
                  sendGALog('your_initiatives')
                  setResetInitiatives(true)
                  navigate('/Initiatives')
                  // if (createInitiative) {
                  //   handleClickOpenWarningDialog(true);
                  // }
                  // else {
                  // handleNavigate()
                  // window.location.reload(false);
                  // }
                }}
              >
                <Typography variant="span">
                  <img className="mr-3" src={leaderboard4} width="15" alt="" />
                  Your Initiatives
                </Typography>
              </Button>
            </li>
          )}
          {userId == 2 && userProfile?.showSettings && (
            <li className="justify-content-between">
              <Button
                className={active == 'Settings' && 'active'}
                onClick={() => {
                  setactive('Settings')
                  navigate('/Settings')
                }}
              >
                <Typography variant="span">
                  <img className="mr-3" src={leaderboard6} width="15" alt="" />
                  Settings
                </Typography>
              </Button>
            </li>
          )}
          <li className="justify-content-between">
            <Button
              onClick={async () => {
                setLogoutClick(true)
                setactive('logout')
                await postAuditLogs('logout')
                sendGALog('logout')
                // if (createInitiative) {
                //   handleClickOpenWarningDialog(true);
                // } else {
                handleClickOpenLogoutDialog()
                // if (checkLogoutClick) {
                // localStorage.removeItem("userId");
                // localStorage.removeItem("tokenDetails")
                // localStorage.removeItem("mpId")
                // navigate("/loginusers.html")
                // window.location.reload();
                // }
                // }
              }}
            >
              <Typography variant="span">
                <img className="mr-3" src={leaderboard2} width="15" alt="" />
                Log Out
              </Typography>
            </Button>
          </li>
        </ul>
      </nav>
      <Box className="d-flex flex-column mt-auto mb-2 ml-2 whats-new-wrapper">
        <Button className="whats-new-btn" onClick={handlePopUp}>
          What's New!
        </Button>
        <Typography className="version-wrapper">{userProfile?.version}</Typography>
      </Box>
      <WarningMessageDialog
        openWarningDialog={openWarningDialog}
        handleCloseWarningDialog={handleCloseWarningDialog}
        setCheckWarningClick={setCheckWarningClick}
      />
      <LogoutDialog
        openLogoutDialog={openLogoutDialog}
        handleCloseLogoutDialog={handleCloseLogoutDialog}
        setCheckLogoutClick={setCheckLogoutClick}
        isSessionTimedOut={is401ErrorReceived}
      />
      <ConnectionCloseError isOpen={connectionClosedDialog} />
      <WhatsNewSection popupOpen={popupOpen} setPopupOpen={setPopupOpen} selectedWhatsNewData={null} />
    </Box>
  )
}

export default SideMenu
