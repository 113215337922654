import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Box } from '@mui/material'
import TemplateDialogCloseButton from '../TemplateDialogCloseButton/TemplateDialogCloseButton'
import DialogButton from '../DialogButton/DialogButton'

export default function RemoveFileDialog({ callback, openRemoveDialog }) {
  return (
    <Box>
      <Dialog
        open={openRemoveDialog}
        onClose={callback}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ maxWidth: '550px', margin: '0 auto' }}
      >
        <TemplateDialogCloseButton onClick={callback} />
        <DialogContent sx={{ justifyContent: 'center' }}>
          <DialogContentText
            sx={{
              minHeight: '130px',
              display: 'flex',
              alignItems: 'center',
              color: '#6C6C6C',
              fontFamily: 'HK Grotesk',
              fontSize: '28px',
              textAlign: 'center',
              ml: 4,
              paddingTop: '20px',
            }}
          >
            All rows created during CSV upload will be deleted
          </DialogContentText>
        </DialogContent>
        <DialogButton onClickForYesButton={() => callback(true)} onClickForNoButton={callback} />
      </Dialog>
    </Box>
  )
}
