import {
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_REQUEST,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_SUCCESS,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_FAILURE,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_REQUEST,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_SUCCESS,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_FAILURE,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_REQUEST,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_SUCCESS,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_FAILURE,
  FETCH_INITIATIVE_REPORT_BY_ID_LIST_REQUEST,
  FETCH_INITIATIVE_REPORT_BY_ID_LIST_SUCCESS,
  FETCH_INITIATIVE_REPORT_BY_ID_LIST_FAILURE,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_REQUEST,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_SUCCESS,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_FAILURE,
  FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_REQUEST,
  FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_SUCCESS,
  FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_FAILURE,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_REQUEST,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_SUCCESS,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_FAILURE,
  FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_REQUEST,
  FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_SUCCESS,
  FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_FAILURE,
} from './types'
import axiosInstance from '../../utils/api'

export const fetchOngoingSevaIntiativesListRequest = () => {
  return {
    type: FETCH_ONGOING_SEVA_INITIATIVES_LIST_REQUEST,
  }
}
export const fetchOngoingSevaIntiativesListSuccess = (data) => {
  return {
    type: FETCH_ONGOING_SEVA_INITIATIVES_LIST_SUCCESS,
    payload: data,
  }
}
export const fetchOngoingSevaIntiativesListFailure = (error) => {
  return {
    type: FETCH_ONGOING_SEVA_INITIATIVES_LIST_FAILURE,
    payload: error,
  }
}

export const fetchOngoingSevaIntiativesListWithInitiativeIdRequest = () => {
  return {
    type: FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_REQUEST,
  }
}
export const fetchOngoingSevaIntiativesListWithInitiativeIdSuccess = (data) => {
  return {
    type: FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_SUCCESS,
    payload: data,
  }
}
export const fetchOngoingSevaIntiativesListWithInitiativeIdFailure = (error) => {
  return {
    type: FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_FAILURE,
    payload: error,
  }
}

export const fetchOngoingSevaIntiativesListByMpIdRequest = () => {
  return {
    type: FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_REQUEST,
  }
}
export const fetchOngoingSevaIntiativesListByMpIdSuccess = (data) => {
  return {
    type: FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_SUCCESS,
    payload: data,
  }
}
export const fetchOngoingSevaIntiativesListByMpIdFailure = (error) => {
  return {
    type: FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_FAILURE,
    payload: error,
  }
}

export const fetchIntiativesReportByIdListRequest = () => {
  return {
    type: FETCH_INITIATIVE_REPORT_BY_ID_LIST_REQUEST,
  }
}

export const fetchIntiativesReportByInitiativeIdRequest = () => {
  return {
    type: FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_REQUEST,
  }
}

export const fetchIntiativesReportByIdListSuccess = (data) => {
  return {
    type: FETCH_INITIATIVE_REPORT_BY_ID_LIST_SUCCESS,
    payload: data,
  }
}

export const fetchIntiativesReportByInitiativeIdSuccess = (data) => {
  return {
    type: FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_SUCCESS,
    payload: data,
  }
}

export const fetchIntiativesEventByInitiativeIdRequest = () => {
  return {
    type: FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_REQUEST,
  }
}

export const fetchIntiativesEventByInitiativeIdSuccess = (data) => {
  return {
    type: FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_SUCCESS,
    payload: data,
  }
}

export const fetchIntiativesEventByInitiativeIdFailure = (data) => {
  return {
    type: FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_FAILURE,
    payload: data,
  }
}

export const fetchIntiativesReportByInitiativeIdFailure = (error) => {
  return {
    type: FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_FAILURE,
    payload: error,
  }
}

export const fetchIntiativesReportByIdListFailure = (error) => {
  return {
    type: FETCH_INITIATIVE_REPORT_BY_ID_LIST_FAILURE,
    payload: error,
  }
}

export const fetchIntiativesReportByInitiativeIdAndMpIdtRequest = () => {
  return {
    type: FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_REQUEST,
  }
}
export const fetchIntiativesReportByInitiativeIdAndMpIdSuccess = (data) => {
  return {
    type: FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_SUCCESS,
    payload: data,
  }
}
export const fetchIntiativesReportByInitiativeIdAndMpIdFailure = (error) => {
  return {
    type: FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_FAILURE,
    payload: error,
  }
}

export const fetchSocialMediaInitiativeByIdRequest = () => {
  return {
    type: FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_REQUEST,
  }
}
export const fetchSocialMediaInitiativeByIdSuccess = (data) => {
  return {
    type: FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_SUCCESS,
    payload: data,
  }
}
export const fetchSocialMediaInitiativeByIdFailure = (error) => {
  return {
    type: FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_FAILURE,
    payload: error,
  }
}

export const getOngoingSevaIntiativesListWithInitiativeId = (id) => async (dispatch) => {
  dispatch(fetchOngoingSevaIntiativesListWithInitiativeIdRequest)
  await axiosInstance
    .get(`/api/initiative/getall/${id}`)
    .then((response) => {
      const result = response.data
      dispatch(fetchOngoingSevaIntiativesListWithInitiativeIdSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchOngoingSevaIntiativesListWithInitiativeIdFailure(errorMsg))
    })
}

export const getOngoingSevaIntiativesList = (payload) => async (dispatch) => {
  dispatch(fetchOngoingSevaIntiativesListRequest)
  const { id = 0, limit, prevId = 0, isPrevTenure = false } = payload
  let endPoint = `/api/initiative/getall/${id}?offset=${prevId}&isPrevTenure=${isPrevTenure}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response?.data
      dispatch(fetchOngoingSevaIntiativesListSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchOngoingSevaIntiativesListFailure(errorMsg))
    })
}

export const getOngoingSevaIntiativesListByMpId = (payload) => async (dispatch) => {
  dispatch(fetchOngoingSevaIntiativesListByMpIdRequest)
  const { id = 0, limit, prevId = 0, isPrevTenure = false } = payload
  let endPoint = `/api/initiative/getbymp/${id}?prevId=${prevId}&isPrevTenure=${isPrevTenure}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response?.data
      dispatch(fetchOngoingSevaIntiativesListByMpIdSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchOngoingSevaIntiativesListByMpIdFailure(errorMsg))
    })
}

export const getIntiativesReportByIdList = (initiativeId) => async (dispatch) => {
  dispatch(fetchIntiativesReportByIdListRequest)
  await axiosInstance
    .get(`/api/report/getbyintiativeid/${initiativeId}`)
    .then((response) => {
      const result = response?.data
      dispatch(fetchIntiativesReportByIdListSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchIntiativesReportByIdListFailure(errorMsg))
    })
}

export const getIntiativesReportByInitiativeId = (payload) => async (dispatch) => {
  const { id = 0, limit, prevId = 0, searchText } = payload
  dispatch(fetchIntiativesReportByInitiativeIdRequest)
  let endPoint = `/api/report/${id}?&offset=${prevId}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  if (searchText) {
    endPoint += `&searchText=${searchText}`
  }

  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response?.data
      dispatch(fetchIntiativesReportByInitiativeIdSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchIntiativesReportByInitiativeIdFailure(errorMsg))
    })
}

export const getIntiativesEventByInitiativeId = (payload) => async (dispatch) => {
  const { id = 0, limit, prevId = 0 } = payload
  dispatch(fetchIntiativesEventByInitiativeIdRequest)
  let endPoint = `/api/report/initiatives/${id}?&offset=${prevId}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response?.data
      dispatch(fetchIntiativesEventByInitiativeIdSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchIntiativesEventByInitiativeIdFailure(errorMsg))
    })
}

export const getIntiativesReportByInitiativeIdAndMpId = (initiativeId, mpId) => async (dispatch) => {
  dispatch(fetchIntiativesReportByInitiativeIdAndMpIdtRequest)
  return axiosInstance
    .get(`/api/report/getByInitiativeIdAndMpId/${initiativeId}/${mpId}`)
    .then((response) => {
      const result = response?.data
      dispatch(fetchIntiativesReportByInitiativeIdAndMpIdSuccess(result))
      return response
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchIntiativesReportByInitiativeIdAndMpIdFailure(errorMsg))
    })
}

export const getSocialMediaIntiativesListById = (id) => async (dispatch) => {
  dispatch(fetchSocialMediaInitiativeByIdRequest)
  await axiosInstance
    .get(`/api/socialMedia/getbyInitiative/${id}`)
    .then((response) => {
      const result = response?.data
      // const result = mpList
      dispatch(fetchSocialMediaInitiativeByIdSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchSocialMediaInitiativeByIdFailure(errorMsg))
    })
}
