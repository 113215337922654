import React from 'react';
import './TestLogin.css';
const TestLogin = () => {
  return (
    <div class="container">
      <h1 class="textcenter mb-4">User Table</h1>
      <table class="table">
        <thead className="thead">
          <tr className="tr">
            <th className="th">User Name</th>
            <th className="th">Role</th>
          </tr>
        </thead>
        <tbody className="tbody">
          <tr className="tr">
            <td className="td">Manas Ghodinde</td>
            <td className="td">
              <a href="/?userid='2c33e78513b806727b3a3071e706d8689de0340d63e0579415894eec70d7c4cd'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Manas Ghodinde(poonam_mahajan)</td>
            <td className="td">
              <a href="/?userid='8a7ae63c11bce9fdfca39937a74a37840b37cc06beb93b7988133ec783c4e020'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Rushikesh Joglekar</td>
            <td className="td">
              <a href="/?userid='c913750a10c2d00400198c9b39a122a62d97c27c184ad832494721ae7b99fb51'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Rushikesh Joglekar(MP_Meerut)</td>
            <td className="td">
              <a href="/?userid='206259f21efb6b1117c2724104adb3e38a1569f1122ac30f46e17b662d3b6770'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Pranay Patil</td>
            <td className="td">
              <a href="/?userid='094c0977afd4b2474ca334ddbd54d67ed5ff21b4e24d19470d5cc90359353691'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Pranay Patil</td>
            <td className="td">
              <a href="/?userid='3d6dfcbfb5582e1bbed72dd4b3303be5c900273fa4270fa3b95208c3e73260c0'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Manas Shettigar</td>
            <td className="td">
              <a href="/?userid='cb990ae37b3a84710eaef76030c334ae407686ccdf18809aa1d02676c744bfa5'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Manas Shettigar(KailashBaytu )</td>
            <td className="td">
              <a href="/?userid='c8ea233bd94021755efae9e6a02ee8b62e4830f4900b803a7e09d1bddde7c7cd'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Komal Menger</td>
            <td className="td">
              <a href="/?userid='2235590e056bf10daa6b535f2eedf8f96a783aaab58ea08401cb515c5abe865e'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Komal Menger (KMenger1509)</td>
            <td className="td">
              <a href="/?userid='4f61d7eb53e7e4b12039722603dd0123881a815ace6fff6e5d9691fb28ee2d0d'">Admin</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Angad Singh</td>
            <td className="td">
              <a href="/?userid='f83b7dbc3d0c0833dd9dc689e6b27005b9736c02f7bd198a9d077edea1f876f3'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Nilesh</td>
            <td className="td">
              <a href="/?userid='e8578b4ed301b13b39e02ecaf2172a1c24dac26569df6c2a252b8cbfbfd2e2ff'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Sameer Mehta</td>
            <td className="td">
              <a href="/?userid='e8fbed14563d0824a0faec3cca3e0a68ff22785702840c8a9dd7a564fc94055b'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Krishna Karanth</td>
            <td className="td">
              <a href="/?userid='eded52f47d06b6013494a18e19a89cc4d97a36d03e30aea93a2a8becd7f35a6a'">Admin</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Akhil Panicker</td>
            <td className="td">
              <a href="/?userid='40ee17c65e0d57975dc52963ddd779b99f67de46d641df1e55905065646304d4'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Akhil Panicker</td>
            <td className="td">
              <a href="/?userid='7370e68128c630c6f44d77950bba3b4a463f231a1f68e19647bb930d659c6bc7'">Admin</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Ritul Yadav</td>
            <td className="td">
              <a href="/?userid='aac7e3cb9c4c067d98354544218f8b089d780c13587583219c5cff2f4079e586'">
                Senior Leadership
              </a>
            </td>
          </tr>

          <tr className="tr">
            <td className="td">Shreya Vettiyatil</td>
            <td className="td">
              <a href="/?userid='0818448ff2d9df492460d24bc4699cab658c283ff88d7206b314a4e45b15658c'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Shreya Vettiyatil</td>
            <td className="td">
              <a href="/?userid='ecf3446c09c605587fd6e8bd6b49c800fc215a07f7176b7dd13b818daab0518e'">Admin</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Milan Gupta</td>
            <td className="td">
              <a href="/?userid='793dfd3b768b02ebb78ec7c626ebb6ddefca8583102b69e5dd7b65fecd628286'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Anuj Vishwakarma</td>
            <td className="td">
              <a href="/?userid='69bb0e1ada6fc0f653a69bc5e576f0e050e731669e8f21426ec2b7f301bf2937'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Utkarsh Jumle</td>
            <td className="td">
              <a href="/?userid='f13f00a1297ba9bffeca247e72ec4bbc1c11498b0b01cff46ea55bce1df5aae5'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Utkarsh Jumle</td>
            <td className="td">
              <a href="/?userid='f13f00a1297ba9bffeca247e72ec4bbc1c11498b0b01cff46ea55bce1df5aae5'">MP</a>
            </td>
          </tr>
          <tr className="tr">
            <td className="td">Utkarsh Jumle</td>
            <td className="td">
              <a href="/?userid='0f65032f6be11e42bb2acbb999f6a21359d089166e304194845430c71603017c'">Admin</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TestLogin;
