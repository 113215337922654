import { Box, Button } from '@mui/material'
import UploadFile from '@mui/icons-material/UploadFile'
import './UploadCsvFileButton.css'

const UploadCsvFileButton = ({
  isLoading,
  fileName,
  CircularProgress,
  hiddenFileInput,
  postAuditLogs,
  setRemoveDialogVisibleState,
}) => {
  const handleClick = async (event) => {
    if (fileName?.length !== 0) {
      setRemoveDialogVisibleState(true)
      return
    }
    hiddenFileInput.current?.click()
    event?.stopPropagation()
    await postAuditLogs('upload_csv')
  }

  return (
    <Box className="upload-button-style">
      <Button onClick={handleClick} className="uploadCsvButton" disabled={isLoading}>
        <UploadFile sx={{ mr: '5px' }} />
        {isLoading && <CircularProgress size={30} />}
        {!isLoading && fileName ? 'Remove uploaded CSV' : 'Upload a .CSV file'}
      </Button>
    </Box>
  )
}

export default UploadCsvFileButton
