import { env } from '../env'

// get Data layer
const getDataLayer = () => {
  window['dataLayer'] = window['dataLayer'] || []
  return window['dataLayer']
}

// push data to Data layer
const pushOnDataLayer = (obj) => {
  const dataLayer = getDataLayer()
  dataLayer.push(obj)
}

// function to send data to data layer
export const pushTag = (tags) => {
  const CryptoJS = require('crypto-js')
  let mp_name =
    localStorage.getItem('mpName') &&
    CryptoJS.AES.decrypt(localStorage.getItem('mpName'), env.REACT_APP_ID_KEY).toString(CryptoJS.enc.Utf8)
  let userid =
    localStorage.getItem('uuid') &&
    CryptoJS.AES.decrypt(localStorage.getItem('uuid'), env.REACT_APP_ID_KEY).toString(CryptoJS.enc.Utf8)
  let brand =
    localStorage.getItem('role') &&
    CryptoJS.AES.decrypt(localStorage.getItem('role'), env.REACT_APP_ID_KEY).toString(CryptoJS.enc.Utf8)
  let tagsObject = {
    app_name: 'namo_admin',
    env: env.REACT_APP_ENVIRONMENT,
    platform: 'namoapp/browser',
    device: 'web',
    language: 'english',
    mp_name,
    userid,
    brand,
    ...tags,
  }
  pushOnDataLayer(tagsObject)
}
