import { Grid, Button } from '@mui/material'
const BannerRatio = ({ selectedBannerRatio, handleFilter, Ratio }) => {
  return (
    <Grid item xs={4} sx={{ mr: '-14% !important', mb: 1.5 }}>
      <Button
        className="butbanner"
        sx={[
          {
            borderRadius: '17px',
            height: '30px',
            color: '#2c2c2c',
            font: 'HK Grotesk, SemiBold',
            fontSize: '15px',
            font: ' normal normal 600 20px/26px HK Grotesk',
            minWidth: '65px',
            background: ' #FFFFFF 0% 0% no-repeat padding-box',
            boxShadow: '0px 2px 6px #0000001C',
          },
          selectedBannerRatio === Ratio && {
            backgroundColor: '#356f92',
            color: '#fff',
          },
        ]}
        onClick={() => handleFilter(Ratio)}
      >
        {Ratio}
      </Button>
    </Grid>
  )
}
export default BannerRatio
