import { Typography } from '@mui/material'

const CreateInitiativeSectionTitle = ({ title, className, sx }) => {
  return (
    <Typography variant="h1" className={className} sx={sx}>
      {title}
    </Typography>
  )
}

export default CreateInitiativeSectionTitle
