import { Grid, Paper, Typography, Tooltip, CardMedia, Box } from '@mui/material'
import editIcon from '../../../asserts/images/Edit.svg'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { pushTag } from '../../../utils/gtmservice'
import { getEvents } from '../../../store/action/getEvents'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  getOngoingSevaIntiativesListWithInitiativeId,
  getSocialMediaIntiativesListById,
} from '../../../store/action/ongoingSevaInitiativesList'

const InitiativeItem = ({ initiativeItem }) => {
  const navigate = useNavigate()
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const dispatch = useDispatch()

  const handleEdit = async (id) => {
    pushTag({
      userid: userProfile?.uuid,
      event: 'select_option',
      message: 'edit',
      page_title: 'seva_initiative_page',
    })
    await postAuditLogs('edit_initiative', { i_id: id })
    await dispatch(getSocialMediaIntiativesListById(id))
    await dispatch(getOngoingSevaIntiativesListWithInitiativeId(id))
    dispatch(getEvents(id))

    navigate(`/SevaInitiatives/editInitiative/${id}`, {
      state: {
        id: id,
        create: false,
      },
    })
  }

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('seva_initiatives', sqId, data)
  }

  return (
    <Box key={initiativeItem.id} className="create-dash-new card created-initiative-item-element">
      <Grid
        container
        sx={{
          ml: 2,
          justifyContent: 'space-between',
          alignItems: 'center',
          marginTop: '-10px',
        }}
      >
        <Tooltip title={initiativeItem.initiativeName} arrow>
          <Typography variant="span" className="d-flex create-initiative-title">
            {' '}
            Seva {initiativeItem.id} -{' '}
            <Typography variant="span" className="ellipsewehe12 ml-1">
              {' '}
              {initiativeItem.initiativeName}{' '}
            </Typography>{' '}
          </Typography>
        </Tooltip>
        <Box className="edit-parent-box">
          <CardMedia
            className="edit-initiative-button"
            onClick={() => {
              handleEdit(initiativeItem?.id)
            }}
            component="img"
            image={editIcon}
          />
        </Box>
      </Grid>
    </Box>
  )
}
export default InitiativeItem
