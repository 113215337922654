import { getIds } from './getIds';
import axiosInstance from '../../utils/api';

const DonationClick = async () => {
  const loggedInId = getIds();
  try {
    await axiosInstance.post(`/api/mp/inviteDonationScore/${loggedInId}`, false).then((response) => {
      return response;
    });
  } catch (error) {
    console.log('Error', error);
  }
};

export default DonationClick;
