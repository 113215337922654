import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Hidden,
  InputAdornment,
  List,
  TextField,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Avatar,
  Box,
} from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import searchIcon from '../../asserts/images/Search.svg'
import closeIcon from '../../asserts/images/close.png'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { searchMpList } from '../../store/action/mpSearch'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import NoImageFound from '../../asserts/images/noImageFound1.jpg'
import { getIds } from '../ReusableComponents.js/getIds'
import './Search.css'
import ProfilePictureComponent from '../ReusableComponents.js/ProfilePictureComponent'
import { searchMPProfile, searchmpNoProfile } from '../ReusableComponents.js/ProfilePictureStyles'

const Search = ({ user, marginRightValue, marginTopValue, inputValue, clearParentTextField }) => {
  const searchRef = useRef(null)
  const inputRef = useRef(null)
  const [searchInput, setSearchInput] = useState('')
  const [anchorEl, setAnchorEl] = React.useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const mpList = useSelector((state) => state?.mpSearchList?.data)
  const isSearching = useSelector((state) => state?.mpSearchList?.loading, shallowEqual)
  const handleClose = () => {
    setSearchInput([])
    setAnchorEl(false)
    clearParentTextField(false)
  }
  const loggedInId = getIds()

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleClickOutside = (event) => {
    if (searchRef.current && !searchRef.current.contains(event.target)) {
      handleClose()
    }
  }

  const handleSearch = (event) => {
    const trimmedValue = event.trimStart()
    setSearchInput(event)
    if (event.length >= 1) {
      setAnchorEl(true)
    } else {
      setAnchorEl(false)
    }
    dispatch(searchMpList(trimmedValue))
    // open = true;
  }

  const blockInvalidChar = (e) => {
    const invalidChars = ['#', '&', '+', '_', '!', '@', '%', '[', ']', '=', '*', '^', '-', '?', '/', '$', ')', '(']
    if ((e.keyCode === 32 && e.target.selectionStart === 0) || invalidChars.includes(e.key)) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    const trimmedValue = searchInput.trimStart()
    if (searchInput?.length > 0) dispatch(searchMpList(trimmedValue))
  }, [searchInput])

  useEffect(() => {
    setTimeout(() => {
      if (inputRef.current !== null) {
        inputRef.current.focus()
      }
    }, 0)
  }, [inputRef])

  return (
    <Dialog
      open={inputValue}
      onClose={() => clearParentTextField(false)}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiDialog-paperScrollPaper': {
            backgroundColor: 'transparent !important',
            boxShadow: 'none !important',
            right: `${marginRightValue}px`,
            top: `${marginTopValue}px`,
            position: 'absolute',
            height: searchInput.length > 0 ? (mpList.length !== 0 ? '40rem !important' : '11rem !important') : '11vh',
          },
        },
      }}
    >
      {/* <Box className="search-dialog cursorshow" > */}
      <DialogTitle>
        <TextField
          className="search-filter-icon cursorshow"
          sx={{
            '& fieldset': { border: 'none' },
          }}
          autoFocus
          onChange={(e) => {
            handleSearch(e.target.value)
          }}
          onKeyDown={blockInvalidChar}
          inputRef={inputRef}
          value={searchInput}
          placeholder="Search MP / Constituency"
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                {/* search icon  */}
                <Avatar
                  variant="square"
                  className="searchIcon cursorshow"
                  alt="Search"
                  sx={{ width: '21px', height: '21px' }}
                  src={searchIcon}
                  onClick={() => {
                    inputRef.current.focus()
                  }}
                />
              </InputAdornment>
            ),
          }}
        >
          {' '}
        </TextField>
      </DialogTitle>
      <DialogContent>
        {mpList && mpList.length !== 0 && anchorEl ? (
          <>
            <Box ref={searchRef} className="searchfixed-2 search-result-border-radius">
              <Typography
                sx={{
                  margin: '2% 8% 2% 8%',
                  wordBreak: 'break-word',
                  display: 'inline-flex',
                  fontFamily: 'HK Grotesk',
                  color: '#2C2C2C',
                  fontSize: '24px',
                  fontWeight: 'bold',
                  width: '275px',
                }}
                variant={'h5'}
              >
                Results for “{searchInput}”
              </Typography>
              <Avatar
                src={closeIcon}
                sx={{ width: '23px', height: '20px' }}
                className="m-3 close-icon"
                onClick={handleClose}
                alt="close"
              />
              <List
                sx={{
                  width: '400px',
                  bgcolor: 'background.paper',
                  position: 'absolute',
                  zIndex: 1000,
                  // marginTop: "8px",
                  // borderRadius: "5%",
                  overflowX: 'hidden',
                  overflowY: 'auto',
                  maxHeight: '430px',
                  minHeight: '100px',
                  '& ul': { padding: 0 },
                  borderBottomLeftRadius: '20px',
                  borderBottomRightRadius: '20px',
                  paddingBottom: '10px',
                }}
                subheader={<li />}
                className="customscroll"
              >
                {/* <ul style={{position:"absolute",zIndex:1000,background:"white",width:"500px",textAlign:"center"}}> */}
                {mpList.map((i) => (
                  // <li key={i.name}>{i.name} - {i.constituency}</li>
                  <Card
                    sx={{
                      margin: '2% 8% 2% 4%',
                      width: '360px',
                      borderRadius: '14px',
                    }}
                    onClick={() => {
                      if (i.id === loggedInId) {
                        navigate('/MySevaUpdates', {
                          state: {
                            MpName: 'My Seva Rank',
                            user: user,
                            mpId: loggedInId,
                          },
                        })
                      } else {
                        navigate('/MpSevaUpdates', {
                          state: {
                            MpName: i.user_name,
                            user: user,
                            mpId: i.id,
                            mpClick: true,
                            mpData: i && i,
                          },
                        })
                      }
                    }}
                  >
                    <CardActionArea>
                      <Box>
                        <ProfilePictureComponent
                          ProfilPic={i.user_avatar}
                          sxProfile={searchMPProfile}
                          sxCircleIcon={searchmpNoProfile}
                          alt={i.user_name}
                        />
                      </Box>
                      {/* {i.user_avatar ? (
                        <Avatar
                          src={i.user_avatar}
                          alt={i.user_name}
                          className="card-image-search circularimagesearch"
                        />
                      ) : (
                        <Box>
                          <AccountCircleIcon
                            sx={{
                              fontSize: 'xx-large',
                              width: '70px',
                              height: 'auto',
                              border: 0,
                              background: 'white',
                              color: '#EEEEEE',

                            }}
                            className="card-image-search circularimagesearch"
                          />
                        </Box>
                      )} */}
                      {i.coverimage ? (
                        <CardMedia
                          className="mw-100"
                          component="img"
                          height="65px"
                          image={i.coverimage}
                          alt={i.user_name}
                          onError={(e) => (e.target.src = NoImageFound)}
                        />
                      ) : (
                        <CardMedia
                          className="mw-100"
                          component="img"
                          height="65px"
                          image={NoImageFound}
                          alt={i.user_name}
                          onError={(e) => (e.target.src = NoImageFound)}
                        />
                        // <Box height="6.3em" sx={{ backgroundColor: "#DFF6FF" }} />
                      )}
                      <CardContent sx={{ paddingLeft: '90px' }}>
                        <Typography className="serach-card-head" component="div">
                          {i.user_name}
                        </Typography>
                        <Typography className="serach-card-head-2 " color="text.secondary">
                          {i.designation && i.party && (
                            <>
                              {' '}
                              {i.designation} -{i.party},<br />
                            </>
                          )}
                          {i.constituency_name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
                {/* </ul> */}
              </List>{' '}
            </Box>
          </>
        ) : (
          searchInput?.length > 0 &&
          !isSearching && (
            <Box className="d-flex flex-column align-items-center searchfixed-2 pb-1">
              <Typography
                sx={{
                  margin: '2% 8% 2% 8%',
                  wordBreak: 'break-word',
                  display: 'inline-flex',
                  fontFamily: 'HK Grotesk',
                  color: '#2C2C2C',
                  fontSize: '24px',
                  fontWeight: 'bold',
                }}
                variant={'h5'}
              >
                Results for “{searchInput.length > 8 ? searchInput.substring(0, 7) + '...' : searchInput}”
              </Typography>
              <Typography component="span">No Results Found</Typography>
            </Box>
          )
        )}
      </DialogContent>
      {/* </Box> */}
    </Dialog>
  )
}

export default Search
