import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import SevaUpdates from '../SevaUpdates'
import { useDispatch, useSelector } from 'react-redux'
import { getRanksCount } from '../../../store/action/getRanksCount'
import { Box } from '@mui/system'

function MpSevaUpdate() {
  const location = useLocation()
  const dispatch = useDispatch()
  // const ranksCount = useSelector(state => state?.ranksCount?.data)

  // get userId
  let MpName = location?.state?.MpName
  let user = location?.state?.user
  let MPId = location?.state?.mpId
  let MpClick = location?.state?.mpClick
  let mpData = location?.state?.mpData
  // useEffect(() => {
  //   dispatch(getRanksCount(MPId));
  // }, [])

  return (
    <Box>
      <SevaUpdates mpName={MpName} user={user} mpId={MPId} MpClick={MpClick} mpData={mpData} />
    </Box>
  )
}

export default MpSevaUpdate
