import { Box } from '@mui/material'
import ViewAllScreenHeaderTitle from '../ViewAllScreenHeaderTitle/ViewAllScreenHeaderTitle'
import ViewAllScreenHeaderBreadCrumb from '../ViewAllScreenHeaderBreadCrumb/ViewAllScreenHeaderBreadCrumb'
import ViewAllScreenHeaderSearchBar from '../ViewAllScreenHeaderSearchBar/ViewAllScreenHeaderSearchBar'
import './ViewAllScreenHeader.css'
import ViewAllScreenSubHeader from '../ViewAllScreenSubHeader/ViewAllScreenSubHeader'

const ViewAllScreenHeader = ({
  viewAllTitle,
  totalCardLength,
  searchText,
  setSearchText,
  containerRef,
  setCardsData,
  setTotalCardLength,
  setIsItemUpdated,
  getSevaUpdateResults,
  setSearchOffset,
  callSearchAPI,
  setLastPaginatedResult,
  tenure,
}) => {
  return (
    <>
      <Box className="row viewAllHeader" sx={{ marginLeft: '6px' }}>
        <ViewAllScreenHeaderTitle viewAllTitle={viewAllTitle} totalCardLength={totalCardLength} />

        <ViewAllScreenHeaderSearchBar
          searchText={searchText}
          viewAllTitle={viewAllTitle}
          setCardsData={setCardsData}
          setTotalCardLength={setTotalCardLength}
          containerRef={containerRef}
          setSearchText={setSearchText}
          setIsItemUpdated={setIsItemUpdated}
          getSevaUpdateResults={getSevaUpdateResults}
          setSearchOffset={setSearchOffset}
          callSearchAPI={callSearchAPI}
          setLastPaginatedResult={setLastPaginatedResult}
        />
      </Box>

      <ViewAllScreenHeaderBreadCrumb tenure={tenure} viewAllTitle={viewAllTitle} />
      <ViewAllScreenSubHeader />
    </>
  )
}

export default ViewAllScreenHeader
