import React, { useState, useEffect, memo } from 'react'
import Box from '@mui/material/Box'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import SideMenu from '../../SideMenu/SideMenu'
import first from '../../../asserts/images/1st.png'
import one from '../../../asserts/images/1.jpg'
import two from '../../../asserts/images/4.jpg'
import three from '../../../asserts/images/2.jpg'
import ShareIcon from '@mui/icons-material/Share'
import './AllInitiativeReports.css'
import {
  Card,
  CardContent,
  TextField,
  Typography,
  Button,
  CardMedia,
  Breadcrumbs,
  Grid,
  capitalize,
  Dialog,
  DialogTitle,
  IconButton,
  Divider,
  Avatar,
} from '@mui/material'
import CrossIcon from '../../../asserts/images/Close.svg'
import Share from '../../ReusableComponents.js/Share'
import BorderColorIcon from '../../../asserts/images/Create.svg'
import CreateInitiativeReportDialog from './createInitiativeReportDialog.js'
import InitiativeDetails from './initiativeDetails'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import NoImageFound from '../../../asserts/images/noImageFound.jpg'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import Moment from 'moment'
import {
  getIntiativesReportByIdList,
  getIntiativesReportByInitiativeIdAndMpId,
} from '../../../store/action/ongoingSevaInitiativesList'
import { getMpProfile } from '../../../store/action/individualMP'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import UpdateInitiativeReportDetails from './UpdateInitiativeReportDialog'
import { useNotificationContext } from '../../../utils/NotificationContext'
import EventDetailsDialog from './EventDetailsDialog'
import { getIds } from '../../ReusableComponents.js/getIds'
import EmptyDataCard from '../../ReusableComponents.js/EmptyStateCard'
import { makeLogRequest } from '../../../utils/AuditLogs'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { defaultErrorMessage } from '../../../utils/constant'
import ProfilePictureComponent from '../../ReusableComponents.js/ProfilePictureComponent'
import { InitiativeProfile, AllInitiativeProfile } from '../../ReusableComponents.js/ProfilePictureStyles'

const AllInitiativeReports = () => {
  const dispatch = useDispatch()
  const { showNotification } = useNotificationContext()
  const location = useLocation()
  const [value, setValue] = useState(0)
  const [open, setOpen] = useState(false)
  const [mediaShare, setMediaShare] = useState(false)
  const [shareData, setShareData] = useState({})
  const [compareIdForCreateButton, setCompareIdForCreateButton] = useState(false)
  const [openInitiativeDetailsDialog, setOpenInitiativeDetailsDialog] = useState(false)
  const [openUpdateIntiativeDialog, setOpenUpdateInitiativeDialog] = useState(false)
  const [participants, setInitiativeParticipants] = useState(0)
  const [personDetails, setPersonDetails] = useState()
  const [openEventDetailsDialog, setOpenEventDetailsDialog] = useState(false)
  const [eventDetailsData, setEventDetailsData] = useState()
  const [eventDeleted, setEventDeleted] = useState(false)
  const navigate = useNavigate()
  const ongoingSevaIntiativesReportsList = useSelector((state) => state?.ongoingSevaIntiativesReportsList?.data)
  const initiativeReportDetailsByMPIdAndIntiativeId = useSelector(
    (state) => state?.initiativeReportDetailsByMPIdAndIntiativeId?.data
  )
  const ongoingSevaInitiativesLists = useSelector((state) => state?.ongoingSevaInitiativesList?.data)
  const ongoingSevaInitiativesListsByMpId = useSelector((state) => state?.ongoingSevaInitiativesListByMpId?.data)
  const ongoingSevaInitiativesList = ongoingSevaInitiativesLists?.initiative
  const ongoingSevaInitiativesListByMpId = ongoingSevaInitiativesListsByMpId?.initiative
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  let user = location?.state?.user
  let initiativeId = location?.state?.initiativeId
  let initiativeName = location?.state?.initiativeName
  let readonly = location?.state?.readonly
  let eventByMpId = location?.state?.eventByMpId
  let mpName = location?.state?.mpName
  let mpId = location?.state?.mpId
  let mySeva = location?.state?.mySeva

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = (reset, setImages, setFiles) => {
    setOpen(false)
    reset()
    setImages([])
    setFiles([])
  }

  const postAuditLogs = async (sqId, i_id, report_id, e_id) => {
    let requestData = {
      useridtype: getIds(),
    }
    if (i_id !== null) {
      requestData['i_id'] = i_id && i_id
    } else if (report_id !== null) {
      requestData['report_id'] = report_id
    } else if (e_id !== null) {
      requestData['e_id'] = e_id
    }
    await makeLogRequest('individual_initiatives_wall', sqId, requestData)
  }

  useEffect(() => {
    //call api to update store
    dispatch(getIntiativesReportByIdList(initiativeId))
    dispatch(getMpProfile(getIds()))
    postAuditLogs('pageload', initiativeId)
  }, [])

  useEffect(() => {
    const reportArray = ongoingSevaIntiativesReportsList[0]?.reportdata
    for (let i = 0; i < reportArray?.length; i++) {
      if (reportArray[i]?.userId === mpProfileData?.id) {
        setCompareIdForCreateButton(true)
        return
      } else if (reportArray[i]?.userId !== mpProfileData?.id) {
        setCompareIdForCreateButton(false)
      }
    }
  }, [ongoingSevaIntiativesReportsList])

  const handleOpenInitiativeDetails = async (item) => {
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(item?.initiativeId, item?.userId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          showNotification('Error', response.data?.message, 'error')
        } else {
          setPersonDetails(item)
          await setInitiativeParticipants(response.data?.participant)
          setOpenInitiativeDetailsDialog(true)
        }
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    }
  }
  const handleCloseInitiativeDetails = () => {
    setOpenInitiativeDetailsDialog(false)
  }

  const handleOpenUpdateInitiativeDetails = async (item) => {
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(item?.initiativeId, item?.userId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          showNotification('Error', response.data?.message, 'error')
        } else {
          await setInitiativeParticipants(response?.data.participant)
          setPersonDetails(item)
          setOpenUpdateInitiativeDialog(true)
        }
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    }
  }
  const handleCloseUpdateInitiativeDetails = (reset, reportFiles, setUploadFiles) => {
    reset()
    reportFiles([])
    setUploadFiles([])
    setOpenUpdateInitiativeDialog(false)
    dispatch(getIntiativesReportByIdList(initiativeId))
  }

  const onFolderClick = () => {
    navigate('/SevaUpdates', {
      state: {
        user: user,
      },
    })
  }

  const ongoingBreadCrumbClick = () => {
    navigate('/SevaUpdates/viewAllOngoingInitiatives', {
      state: {
        // user: newUser,
        viewAllValue: 'Ongoing Seva Initiatives',
        mpName: mpName,
        mpId: mpId,
        mySeva: mySeva,
        data: mpId ? ongoingSevaInitiativesListByMpId : ongoingSevaInitiativesList,
      },
    })
  }

  const handleOpenEventDetailsDialog = (data) => {
    setEventDetailsData(data)
    setOpenEventDetailsDialog(true)
  }

  useEffect(() => {
    if (eventDeleted) {
      dispatch(getIntiativesReportByIdList(initiativeId))
    }
  }, [eventDeleted])

  const handleCloseEventDetailsDialog = () => {
    setOpenEventDetailsDialog(false)
  }

  const handleShare = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    setShareData(data)
    setMediaShare(true)
  }

  const handleViewAllEvents = () => {
    navigate('/SevaUpdates/viewAllSevaEvents', {
      state: {
        viewAllValue: `All Events in ${initiativeName}`,
        data: ongoingSevaIntiativesReportsList[0]?.Eventdata,
        title: initiativeName,
        eventsForInitiative: true,
        initiativeId: initiativeId,
        mpId: mpId,
        mpName: mpName,
      },
    })
  }

  const arrowStyles = {
    color: '#356F92',
    backgroundColor: '#E3F5FF',
    borderRadius: '50%',
    padding: '7px',
    width: '1.5em',
    height: '1.5em',
  }
  const iconButtonStylesLeft = {
    borderRadius: 0,
    marginRight: '-6px',
    zIndex: 11,
  }
  const iconButtonStylesRight = {
    borderRadius: 0,
    marginLeft: '-6px',
    zIndex: 11,
  }

  return (
    <Box className="page-wrapper d-flex overflow-x-hidden">
      <SideMenu active="Seva" user={user} profileData={mpProfileData} />

      <Box className="main-wrapper center-width customscroll scroll-property">
        <Box>
          <Box className="middle-wrapper">
            <Box className="d-flex justify-content-between align-items-center mb-4">
              <Typography
                component="h1"
                className="page-title mb-0 header-text cmn-text"
                title={initiativeName && initiativeName}
              >
                {initiativeName ? initiativeName : 'Seva aur Samarpan Campaign'}
              </Typography>
              {/* search filter commented below */}
              {/* <div className="search-filter-icon d-flex">
                                {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" sx={{ borderRadius: 50 }}>
                                </TextField> */}
              {/* <a className="d-block mr-1" href="">
                                    <svg className="searchIcon position-absolute" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9.58317 18.6253C4.87484 18.6253 1.0415 14.792 1.0415 10.0837C1.0415 5.37533 4.87484 1.54199 9.58317 1.54199C14.2915 1.54199 18.1248 5.37533 18.1248 10.0837C18.1248 14.792 14.2915 18.6253 9.58317 18.6253ZM9.58317 2.79199C5.55817 2.79199 2.2915 6.06699 2.2915 10.0837C2.2915 14.1003 5.55817 17.3753 9.58317 17.3753C13.6082 17.3753 16.8748 14.1003 16.8748 10.0837C16.8748 6.06699 13.6082 2.79199 9.58317 2.79199Z" fill="#5c819e" />
                                        <path d="M18.3335 19.4585C18.1752 19.4585 18.0169 19.4002 17.8919 19.2752L16.2252 17.6085C15.9835 17.3669 15.9835 16.9669 16.2252 16.7252C16.4669 16.4835 16.8669 16.4835 17.1085 16.7252L18.7752 18.3919C19.0169 18.6335 19.0169 19.0335 18.7752 19.2752C18.6502 19.4002 18.4919 19.4585 18.3335 19.4585Z" fill="#5c819e" />
                                    </svg>
                                </a>
                            </div>  */}
            </Box>
            <Box>
              <Grid container sx={{ mb: 1, marginLeft: '30px', marginBottom: '20px' }}>
                <Breadcrumbs
                  aria-label="breadcrumb"
                  separator={<NavigateNextIcon fontSize="small" sx={{ marginLeft: '-15px' }} />}
                >
                  <Button underline="hover" className="ns-update" onClick={onFolderClick}>
                    Nationwide Seva Updates
                  </Button>
                  <Button underline="hover" className="ns-update os-text" onClick={ongoingBreadCrumbClick}>
                    Ongoing Seva Initiative
                  </Button>
                  <Button
                    className="seva-update__breadcrumbs-title initiative-title "
                    underline="hover"
                    title={initiativeName ? initiativeName : ''}
                  >
                    {initiativeName && initiativeName}
                  </Button>
                </Breadcrumbs>
              </Grid>
              {!readonly && user !== 'Admin' && user !== 'Leader' && !compareIdForCreateButton && (
                <Card
                  className="bshadow-1"
                  sx={{
                    display: 'inline-block',
                    marginLeft: '38px',
                    marginTop: '30px',
                    borderRadius: '24px',
                    background: '#f5eeed',
                  }}
                >
                  <CardContent>
                    <Box className="d-flex">
                      <Box>
                        <Typography
                          component="div"
                          sx={{
                            fontSize: '24px',
                            fontWeight: 'bold',
                            fontFamily: 'HK Grotesk ExtraBold !important',
                            color: '#505050',
                            marginLeft: '15px',
                          }}
                        >
                          Create an initiative report
                        </Typography>

                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                          sx={{
                            fontSize: '20px',
                            fontFamily: 'HK Grotesk',
                            color: '#505050',
                            marginLeft: '15px',
                          }}
                        >
                          Have you taken part in this initiative? Add the details here
                        </Typography>
                      </Box>
                      <Box className="create-init-report-box">
                        <Button
                          // className="button-tr1-admin-big"
                          className="button-tr1-admin"
                          sx={{
                            fontFamily: 'HK Grotesk ExtraBold !important',
                            textTransform: 'none !important',
                            fontWeight: 'bolder !important',
                            fontSize: '20px !important',
                            padding: '8px 20px',
                            width: '242px',
                          }}
                          // endIcon={<BorderColorIcon sx={{ mt: 1.5 }} />}
                          onClick={() => (handleClickOpen(), postAuditLogs('create_report'))}
                        >
                          Create Now
                          <img src={BorderColorIcon} width="20px" className="btn-margin" />
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              )}
              <Typography component="h3" className="report-Author cmn-text">
                Initiative Reports by
              </Typography>
            </Box>
            {/* <hr className="mt-2 mb-4" /> */}
            {ongoingSevaIntiativesReportsList == null ||
            ongoingSevaIntiativesReportsList.length < 1 ||
            ongoingSevaIntiativesReportsList[0]?.reportdata == null ||
            ongoingSevaIntiativesReportsList[0]?.reportdata.length < 1 ? (
              <EmptyDataCard cardFor="Initiative Report" />
            ) : (
              <Box className="d-flex justify-content-between leaders-cards ">
                <Box
                  sx={{
                    flexGrow: 1,
                    minWidth: { xs: 920, sm: 1080 },
                    // bgcolor: 'background.paper',
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons
                    aria-label="visible arrows tabs example"
                    ScrollButtonComponent={memo((props) => {
                      if (props.direction === 'left') {
                        return (
                          <IconButton {...props} sx={iconButtonStylesLeft}>
                            <ArrowBackIcon sx={arrowStyles} />
                          </IconButton>
                        )
                      } else if (props.direction === 'right') {
                        return (
                          <IconButton {...props} sx={iconButtonStylesRight}>
                            <ArrowForwardIcon sx={arrowStyles} />
                          </IconButton>
                        )
                      } else {
                        return null
                      }
                    })}
                    TabIndicatorProps={{
                      style: { display: 'none' },
                    }}
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                      },
                    }}
                  >
                    {ongoingSevaIntiativesReportsList &&
                      ongoingSevaIntiativesReportsList[0]?.reportdata?.map((item) => (
                        <Card
                          sx={{ minWidth: 305, mr: 2, borderRadius: '20px' }}
                          className="bshadow cursorshow"
                          onClick={() =>
                            mpProfileData?.id === item?.userId && user !== 'Admin' && user !== 'Leader'
                              ? (handleOpenUpdateInitiativeDetails(item),
                                postAuditLogs('initiative_report_card', null, item?.id, null))
                              : handleOpenInitiativeDetails(item)
                          }
                        >
                          <CardContent>
                            {/* <img width="38.49" src={first} className="position-absolute" alt="" /> */}
                            <Box className="text-center pt-3 d-flex justify-content-center">
                              <ProfilePictureComponent
                                ProfilPic={item?.user?.user_avatar}
                                sxProfile={InitiativeProfile}
                              />
                            </Box>
                            {/* {item?.user?.user_avatar ? (
                              <Box className="text-center pt-3 d-flex justify-content-center">
                                <CardMedia
                                  component="img"
                                  src={item?.user?.user_avatar}
                                  className="img-circle leader-circle-img mr-1 circularimage init-report-mpcard-profile"
                                />
                              </Box>
                            ) : (
                              <AccountCircleIcon
                                sx={{
                                  fontSize: 'xx-large',
                                  width: '80px',
                                  height: '80px',
                                  position: 'relative',
                                  left: '37%',
                                  color: '#EEEEEE',
                                }}
                              />
                            )} */}
                            <Box className="card-content">
                              <Typography component="h2" className="mp-name cmn-text">
                                {item?.user?.user_name}
                              </Typography>
                              <Typography variant="span" className="initial cmn-text mp-detail">
                                {item?.user?.house}
                              </Typography>
                              <Box mt={4} />
                              <Typography variant="span" className="constituency cmn-text mp-constituency">
                                Constituency : {item?.user?.constituency_name ? item?.user?.constituency_name : ' -'}
                              </Typography>
                            </Box>
                          </CardContent>
                        </Card>
                      ))}
                  </Tabs>
                </Box>
              </Box>
            )}
            <Box className="d-flex justify-content-between mt-4 pr-4">
              <Typography variant="h3" className="cmn-text evnts-under-init">
                Events under this Initiative
              </Typography>
              {ongoingSevaIntiativesReportsList[0]?.Eventdata?.length > 4 && (
                <Box>
                  <Button className="button-tr-citizen" onClick={handleViewAllEvents}>
                    View All
                  </Button>
                </Box>
              )}
            </Box>
            {ongoingSevaIntiativesReportsList == null ||
            ongoingSevaIntiativesReportsList.length < 1 ||
            ongoingSevaIntiativesReportsList[0]?.Eventdata == null ||
            ongoingSevaIntiativesReportsList[0]?.Eventdata.length < 1 ? (
              <EmptyDataCard cardFor="Initiative Event" />
            ) : (
              <Box className="d-flex justify-content-between leaders-cards horizontal-padding">
                <Box
                  sx={{
                    flexGrow: 1,
                    minWidth: { xs: 920, sm: 1080 },
                    // bgcolor: 'background.paper',
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="visible arrows tabs example"
                    TabIndicatorProps={{
                      style: { display: 'none' },
                    }}
                    sx={{
                      [`& .${tabsClasses.scrollButtons}`]: {
                        '&.Mui-disabled': { opacity: 0.3 },
                      },
                    }}
                  >
                    {ongoingSevaIntiativesReportsList &&
                      ongoingSevaIntiativesReportsList[0]?.Eventdata?.map((item, index) => (
                        <Card
                          className="cursorshow allInitiative-reports__event-card--wrapper"
                          sx={{
                            mr: 3,
                            mb: 3,
                            borderRadius: '15px',
                            marginRight: '16px',
                          }}
                          onClick={() => (
                            handleOpenEventDetailsDialog(item), postAuditLogs('event_card', null, null, item?.id)
                          )}
                        >
                          <Box className="position-relative">
                            <ShareIcon
                              className="shareicone3"
                              key={index}
                              onClick={(e) => {
                                handleShare(e, item)
                                postAuditLogs('share_event', null, null, item?.id)
                              }}
                            />
                            <CardMedia
                              sx={{
                                filter: 'brightness(50%)',
                                maxWidth: '305px!important',
                              }}
                              id={index}
                              component="img"
                              loading="lazy"
                              height="205"
                              onError={(e) => (e.target.src = NoImageFound)}
                              src={item?.coverimage && JSON.parse(item?.coverimage)[0]}
                              alt="new Image"
                              className="blackoverlay"
                            />

                            <CardContent sx={{ minHeight: '153px', maxHeight: '153px', padding: '10px' }}>
                              <Box className="cmn-text event-title"> {item?.eventTitle}</Box>
                              <Box className="event-date">Date : {Moment(item?.startDate).format('DD/MM/YYYY')}</Box>

                              <Divider className="saperating-hr"></Divider>
                              <Box className="d-flex os-text">
                                <ProfilePictureComponent
                                  ProfilPic={item?.user?.user_avatar}
                                  sxProfile={AllInitiativeProfile}
                                />
                                {/* {item?.user?.user_avatar ? (
                                  <Box className="text-center pt-3">
                                    <CardMedia
                                      component="img"
                                      src={item?.user?.user_avatar}
                                      className="img-circle leader-circle-img mr-1 circularimage2 mp-avatar"
                                    />
                                  </Box>
                                ) : (
                                  <AccountCircleIcon
                                    sx={{
                                      fontSize: 'xx-large',
                                      width: '50px',
                                      height: 'auto',
                                      border: 0,
                                      marginLeft: '15px',
                                      marginTop: '-14px',
                                      marginBottom: '5px',
                                      color: '#EEEEEE',
                                    }}
                                  />
                                )} */}
                                <Box>
                                  <Box className="cmn-text mp-uname">{item?.user?.user_name}</Box>
                                  <Box className="mp-post">{item?.user?.designation}</Box>
                                  <Box className="mp-post" title={item?.user?.party ? item?.user?.party : ''}>
                                    {(item?.user?.party && item?.user?.party.slice(0, 24)) || '-'}
                                    {item?.user?.party && item?.user?.party.length > 24 ? '...' : ''}
                                  </Box>
                                  <Box className="mp-post mp-state-btm">
                                    {item?.user?.constituency_name}, {item?.user?.state_name}
                                  </Box>
                                </Box>
                              </Box>
                            </CardContent>
                          </Box>
                        </Card>
                      ))}
                  </Tabs>
                </Box>
              </Box>
            )}
          </Box>
          <Box className="d-none">
            <CreateInitiativeReportDialog
              open={open}
              handleClose={handleClose}
              initiativeName={initiativeName}
              initiativeId={initiativeId}
              mpId={mpId}
              participants={participants}
            />
            {openInitiativeDetailsDialog && (
              <InitiativeDetails
                handleCloseInitiativeDetails={handleCloseInitiativeDetails}
                openInitiativeDetailsDialog={openInitiativeDetailsDialog}
                details={personDetails}
                uniqueInitiativeClick={true}
                initiativeName={initiativeName}
                mpProfileData={mpProfileData}
                initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
                user={user}
              />
            )}
            {openUpdateIntiativeDialog && (
              <UpdateInitiativeReportDetails
                handleCloseUpdateInitiativeDetails={handleCloseUpdateInitiativeDetails}
                openUpdateIntiativeDialog={openUpdateIntiativeDialog}
                details={personDetails}
                initiativeName={initiativeName}
                initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
                allInitiativeReportsPage={true}
                participants={participants}
                eventByMpId={eventByMpId}
                user={user}
                initiativeID={initiativeId}
              />
            )}
            {openEventDetailsDialog && (
              <EventDetailsDialog
                openEventDetailsDialog={openEventDetailsDialog}
                handleCloseEventDetailsDialog={handleCloseEventDetailsDialog}
                details={eventDetailsData}
                setEventDeleted={setEventDeleted}
                mpProfileData={mpProfileData}
                eventByMpId={eventByMpId}
                initiativeId={initiativeId}
                user={user}
              />
            )}
          </Box>
          <Dialog open={mediaShare} onClose={() => setMediaShare(false)}>
            <DialogTitle>
              <img onClick={() => setMediaShare(false)} src={CrossIcon} className="closeIcon" />
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: '#357092',
                  fontFamily: 'HK Grotesk',
                  fontSize: '26px',
                  fontWeight: 'bold',
                }}
              >
                Share to Social Media
              </Typography>

              <Box className="d-flex justify-content-center share-details">
                <Share data={shareData} title={'Ongoing Seva Initiatives'} initiativeReport={true} />
              </Box>
              {/* <CloseIcon onClick={() => setAddMembers(false)} /> */}
            </DialogTitle>
          </Dialog>
        </Box>
      </Box>
    </Box>
  )
}
export default AllInitiativeReports
