import { Box } from '@mui/material'
import infoic from '../../../asserts/images/Info.svg'
import LeaderBoardText from '../LeaderBoardText/LeaderBoardText'
import IntervalTabs from '../../ReusableComponents.js/IntervalTabs'
import ToolTipComponent from '../../ReusableComponents.js/ToolTip/ToolTipComponent'
import SearchBar from '../SearchBar/SearchBar'

const LeaderBoardHeader = ({
  title,
  selectedCard,
  setDataFilter,
  dataFilter,
  isMarqueeVisible,
  defaultVal,
  setDefaultVal,
  setTenure,
  defaultTenure,
  setCurrentTenure,
}) => {
  return (
    <>
      <Box className="d-flex justify-content-between header-parent pt-3">
        <Box className="child-1">
          <LeaderBoardText title={title} selectedCard={selectedCard} />
        </Box>
        <Box className="d-flex">
          {selectedCard !== 'survey' && (
            <Box className="d-flex">
              {defaultTenure === 'Modi3.0' && (
                <IntervalTabs
                  firstValue={'15 Days'}
                  secondValue={'All Time'}
                  setDataFilter={setDataFilter}
                  defaultVal={defaultVal}
                  setDefaultVal={setDefaultVal}
                />
              )}
              <IntervalTabs
                firstValue={'Modi 3.0'}
                secondValue={'Modi 2.0'}
                setDataFilter={setCurrentTenure}
                defaultVal={defaultTenure}
                setDefaultVal={setTenure}
              />

              <ToolTipComponent defaultTenure={defaultTenure} dataFilter={dataFilter} icon={infoic} />
            </Box>
          )}
        </Box>
      </Box>
    </>
  )
}

export default LeaderBoardHeader
