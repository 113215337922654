import {
  FETCH_MOST_RETWEETED_TWEETS_FAILURE,
  FETCH_MOST_RETWEETED_TWEETS_REQUEST,
  FETCH_MOST_RETWEETED_TWEETS_SUCCESS,
} from '../action/types';

const initialState = {
  data: [],
  error: '',
};

export const getMostRetweetedTweetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MOST_RETWEETED_TWEETS_REQUEST:
      return {
        ...state,
      };
    case FETCH_MOST_RETWEETED_TWEETS_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_MOST_RETWEETED_TWEETS_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
