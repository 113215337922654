import { Box, Typography, Tooltip, Button, Divider } from '@mui/material'
import Moment from 'moment'
import { useState } from 'react'
import { getRoles } from '../../ReusableComponents.js/getIds'
import './InitiativeData.css'

const DetailsColumn = ({ item, index, functionHandler }) => {
  const [openTooltip, setOpenTooltip] = useState(null)

  function handleWheel() {
    setOpenTooltip(null)
  }
  return (
    <Box className="onhovertes">
      <Box className="onhoverte" onClick={() => functionHandler(item)}>
        <Box className="details-wrapper" key={item.id}>
          <p onMouseLeave={() => setOpenTooltip(null)}>
            <Tooltip title={item.initiativeName} arrow key={index} open={openTooltip === index}>
              <Typography
                variant="span"
                className="name-alligment ellipsewehe12 name-color"
                onMouseEnter={() => setOpenTooltip(index)}
                onWheel={handleWheel}
              >
                {getRoles() == 2 && (
                  <Typography variant="span" className="get-role-style">
                    Seva-{item.id}
                  </Typography>
                )}{' '}
                {item.initiativeName}
              </Typography>
            </Tooltip>

            <button
              className={`${
                item.status == 'Ongoing' || item.status == 'Completed'
                  ? 'details-btn-ongoing'
                  : item.status == 'Report_Pending'
                  ? 'details-btn-report'
                  : 'details-btn-alligment'
              } button-mt`}
            >
              {item.status}
            </button>
          </p>
          <Typography component="p">
            {item?.tagetDate && (
              <Typography variant="span" className="target-date-style">
                Target Date:{' '}
                <Typography variant="span" className="target-date">
                  {Moment(item?.tagetDate).format('DD MMMM YYYY')}
                </Typography>
              </Typography>
            )}

            <Button className="details-btn-alligments ellipsewehe12-h ">
              #
              {item?.hashTags?.replace(/^#+/, '').length > 20
                ? item?.hashTags?.replace(/^#+/, '').substring(0, 20) + '...'
                : item?.hashTags?.replace(/^#+/, '')}
            </Button>
          </Typography>
        </Box>
      </Box>
      <Divider className="initiativ-divider" />
    </Box>
  )
}
export default DetailsColumn
