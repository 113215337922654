import { Button, Box, CardMedia, Typography } from '@mui/material'
import deleteIcon from '../../../asserts/images/bin.png'
import './DeleteRowButton.css'

const DeleteRowButton = ({ buttonTitle, onClick }) => {
  return (
    <Button variant="outlined" sx={{ borderRadius: 4 }} className="button-tr-citizen-admin-dl w-auto" onClick={onClick}>
      <Box className="d-flex align-items-center w-100 mr-1">
        <CardMedia component="img" image={deleteIcon} className="addimagetemplate__delete-row-btn-media" />
        <Typography variant="span">{buttonTitle}</Typography>
      </Box>
    </Button>
  )
}

export default DeleteRowButton
