import { FormControl, Button, Typography, Grid } from '@mui/material'
import pdf from '../../../asserts/images/pdf.svg'
import CrossIcon from '../../../asserts/images/Close.svg'
import { onPdfFileUpload, handlePdfClickOpen } from '../../ReusableComponents.js/reuseMethods'
import { useNotificationContext } from '../../../utils/NotificationContext'
import './CreateInitiativeUploadPdfInputField.css'

const CreateInitiativeUploadPdfInputField = ({
  stepPdfName,
  stepPdf,
  PdfFileInput,
  setStepPdf,
  setStepPdfName,
  setUpload,
}) => {
  const { showNotification } = useNotificationContext()

  const handlePdfClick = async (event) => {
    if ((typeof stepPdf == 'string' && stepPdf?.length > 0) || (typeof stepPdf != 'string' && stepPdf != null)) {
      handlePdfClickOpen(stepPdf)
    } else {
      PdfFileInput.current.value = ''
      PdfFileInput.current?.click()
      event?.stopPropagation()
    }
  }

  return (
    <FormControl
      sx={{
        maxWidth: '240px',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <input
        ref={PdfFileInput}
        className="in-st-div"
        type="file"
        accept=".pdf"
        onChange={(e) => {
          if (onPdfFileUpload(e, setStepPdf, showNotification)) {
            setStepPdfName(e.target.files[0].name)
          }
          setUpload(true)
        }}
      />
      <Button
        onClick={(e) => {
          handlePdfClick(e)
        }}
        className="pdfButton"
      >
        <Typography className="uploadPdfClass">
          <img src={pdf} alt="pdf" width="20px" />
        </Typography>
        {stepPdfName ? (
          <>
            <Typography className="pdfEllipse" onClick={(e) => handlePdfClick(e)}>
              {stepPdfName}
            </Typography>
            <img
              onClick={(e) => {
                e?.stopPropagation()
                PdfFileInput.current.value = ''
                setStepPdf(null)
                setStepPdfName(null)
              }}
              src={CrossIcon}
              className="pdfCloseIcon"
            />
          </>
        ) : (
          <Typography component="span" className="pdfEllipse" sx={{ fontFamily: 'HK Grotesk' }}>
            Upload a PDF File
          </Typography>
        )}
      </Button>
      <Grid item xs={4} className="mt-3">
        <Typography component="span" className="opt-span-st">
          Optimal Upload size: 50MB
        </Typography>
      </Grid>
    </FormControl>
  )
}

export default CreateInitiativeUploadPdfInputField
