import { Grid, Paper } from '@mui/material'
import { useSelector } from 'react-redux'
import NoDataFound from '../NoDataFound/NoDataFound'
import InitiativeItem from '../InitiativeItem/InitiativeItem'

const InitiativesList = () => {
  const initiativeLists = useSelector((state) => state?.initiativeList?.data)
  const initiativeList = initiativeLists?.initiative

  return (
    <Grid md={12} lg={12} xl={12}>
      <Paper
        sx={{
          mt: 4,
          ml: 1,
          width: '100%',
          height: '56vh',
          borderRadius: 6,
          overflow: 'auto',
        }}
      >
        {initiativeList?.length > 0 ? (
          initiativeList.map((initiativeItem) => <InitiativeItem initiativeItem={initiativeItem} />)
        ) : (
          <NoDataFound />
        )}
      </Paper>
    </Grid>
  )
}
export default InitiativesList
