import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import AddIcon from '@mui/icons-material/Add'
import IconButton from '@mui/material/IconButton'
import one from 'asserts/images/1.jpg'
import NoImageFound from 'asserts/images/noImageFound.jpg'
import two from 'asserts/images/4.jpg'
import infoic from 'asserts/images/Info.svg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import './UpdateInitiativeReportDialog.css'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Tooltip,
  Typography,
  tooltipClasses,
} from '@mui/material'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import { useForm } from 'react-hook-form'
import UploadIcon from '@mui/icons-material/Upload'
import { postCreateInitiativeReportBy } from 'store/action/createInitiativeReportBy'
import { useDispatch, useSelector } from 'react-redux'
import { useLoading } from 'utils/LoadingContext'
import { useNotificationContext } from 'utils/NotificationContext'
import closeIconp from 'asserts/images/close.png'
import PreviewImagesDialog from 'Components/AllInitiativeReportRefactored/PreviewImagesDialog/PreviewImagesDialog'
import Moment from 'moment'
import Constant, { allowedImageFormat, acceptedImageFormat, defaultErrorMessage } from 'utils/constant'
import DeleteEventDialog from '../DeleteEventDialog/DeleteEventDialog'
import { getIds } from 'Components/ReusableComponents.js/getIds'
import { uploadMedia } from 'utils/mediaUpload'
import EventDetailsDialog from 'Components/AllInitiativeReportRefactored/EventDetailsDialog/EventDetailsDialog'
import { getMpProfile } from '../../../store/action/individualMP'
import { makeLogRequest } from 'utils/AuditLogs'
import CrossIcon from 'asserts/images/Close.svg'
import ProfilePictureComponent from 'Components/ReusableComponents.js/ProfilePictureComponent'
import { InitiativeProfile } from 'Components/ReusableComponents.js/ProfilePictureStyles'

const UpdateInitiativeReportDetails = ({
  openUpdateIntiativeDialog,
  handleCloseUpdateInitiativeDetails,
  initiativeReportDetailsByMPIdAndIntiativeId,
  details,
  initiativeName,
  allInitiativeReportsPage,
  participants,
  eventByMpId,
  initiativeID,
  tenure,
}) => {
  const [previewImageIndex, setPreviewImageIndex] = useState(0)
  const [reportFiles, setReportFiles] = useState([])
  const [openPreviewImages, setOpenPreviewImages] = useState(false)
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const reportsData =
    initiativeReportDetailsByMPIdAndIntiativeId && initiativeReportDetailsByMPIdAndIntiativeId?.reportdata
  const eventsData =
    initiativeReportDetailsByMPIdAndIntiativeId && initiativeReportDetailsByMPIdAndIntiativeId?.Eventdata
  const { setLoading } = useLoading()
  const dispatch = useDispatch()
  const [eventDetailsData, setEventDetailsData] = useState()
  const [openEventDetailsDialog, setOpenEventDetailsDialog] = useState(false)
  const { showNotification } = useNotificationContext()
  const [uploadFiles, setUploadFiles] = useState([])
  const [eventId, setEventId] = useState()
  const [mpId, setMpId] = useState()
  const [initiativeId, setInitiativeId] = useState()
  const hiddenFileInput = useRef(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [checkDescError, setCheckDescError] = useState(false)
  const [checkDescValue, setCheckDescValue] = useState(false)
  const [descValue, setDescValue] = useState('')
  const [checkChanges, setCheckChanges] = useState(false)
  const fileFormats = ['image/png', 'image/jpg']
  const loggedInId = getIds()
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm()

  const postAuditLogs = async (sqId, report_id) => {
    await makeLogRequest('my_initiative_report_edit_popup', sqId, {
      useridtype: getIds(),
      report_id: report_id && report_id,
    })
  }

  useEffect(() => {
    postAuditLogs('pageload', reportsData?.id)
  }, [])

  useEffect(() => {
    if (reportsData) {
      setReportFiles(reportsData?.media && JSON.parse(reportsData?.media))
    }
  }, [reportsData])

  useEffect(() => {
    //call api to update store
    dispatch(getMpProfile(getIds()))
  }, [])

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #356F92',
      },
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: '1px solid #356F92',
      borderRadius: '15px',
      boxShadow: theme.shadows[1],
      left: '20px',
      width: 210,
    },
  }))

  const handleImageChange = (e) => {
    const uploadedFiles = e.target.files
    if (tenure !== 'Modi 2.0') {
      setCheckChanges(true)
    }
    setUploadFiles([...uploadFiles, ...uploadedFiles])
    let newFiles = []
    let processedImages = 0

    for (let i = 0; i < uploadedFiles.length; i++) {
      const isRightFormat = allowedImageFormat.includes(uploadedFiles[i].type)
      const fileSizeInBytes = uploadedFiles[i].size
      const maxSizeInBytes = 20 * 1024 * 1024 // 20MB

      if (!isRightFormat) {
        showNotification('Error', 'Please upload image in JPG or PNG format only', 'error')
        return
      }

      if (fileSizeInBytes > maxSizeInBytes) {
        showNotification('Error', 'Please upload image with size less than 20MB', 'error')
        return
      }

      const reader = new FileReader()
      reader.readAsDataURL(uploadedFiles[i])
      reader.onload = () => {
        if (uploadedFiles[i].type.startsWith('image')) {
          newFiles.push({
            type: 'image',
            url: reader.result,
            file: uploadedFiles[i],
          })
        } else if (uploadedFiles[i].type.startsWith('video')) {
          newFiles.push({
            type: 'video',
            url: reader.result,
            file: uploadedFiles[i],
          })
        }
        processedImages++

        if (processedImages === uploadedFiles.length) {
          setReportFiles([...newFiles, ...reportFiles])
        }
      }
    }
  }

  const handlePreview = (file) => {
    if (file.type === 'image') {
      return (
        <img
          src={typeof file === 'string' ? file : file.url}
          onError={(e) => (e.target.src = NoImageFound)}
          alt="Preview"
          className="imageupload form-img__img-preview-4"
          id="imageupload"
        />
      )
    }
    if (file.type === 'video') {
      return (
        <video controls className="imageupload form-img__img-preview-4" id="imageupload">
          <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }
    const images = ['jpg', 'gif', 'png', 'jpeg', 'svg', 'jfif']
    const videos = ['mp4', '3gp', 'ogg']
    const url = new URL(file)
    const extension = url.search.split('.')[1]
    const pathExtension = url?.pathname?.slice(url.pathname.lastIndexOf('.') + 1, url.pathname.length) || ''
    if (details && (images.includes(extension) || images.includes(pathExtension))) {
      return (
        <img
          src={file}
          onError={(e) => (e.target.src = NoImageFound)}
          alt="Preview"
          className="imageupload form-img__img-preview-4"
          id="imageupload"
        />
      )
    }
    if (details && (videos.includes(extension) || images.includes(pathExtension))) {
      return (
        <video controls className="imageupload form-img__img-preview-4" id="imageupload">
          <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }
  }

  const handleClickOpenDeleteDialog = (mpId, eventId, initiativeId) => {
    setInitiativeId(initiativeId)
    setEventId(eventId)
    setMpId(mpId)
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => setOpenDeleteDialog(false)

  const handleOpenPreviewImages = (index) => {
    setPreviewImageIndex(index)
    setOpenPreviewImages(true)
  }
  const handleClosePreviewImages = () => {
    setOpenPreviewImages(false)
  }
  const checkTextField = (value, maxLength, minLength, register_name, fieldName) => {
    if (value.length == 0) {
      setError(`${register_name}`, {
        type: 'value',
        message: `Please enter ${fieldName}`,
      })
    } else if (value.length > 0 && value.length < minLength) {
      setError(`${register_name}`, {
        type: 'minLength',
        message: `Minimum character length is ${minLength}`,
      })
    } else if (value.length == maxLength) {
      setError(`${register_name}`, {
        type: 'maxLength',
        message: 'Maximum limit reached',
      })
    } else {
      clearErrors(`${register_name}`)
    }
  }

  const addImagesToFormData = async (existingImageUrls, images) => {
    const imageFiles = []
    for (let i = 0; i < images.length; i++) {
      try {
        if (images[i].src.toString().toLowerCase().startsWith('https')) {
          existingImageUrls.push({ media: images[i].src })
        } else {
          const response = await fetch(images[i].src)
          const blob = await response.blob()
          const file = new File([blob], `image${i}.jpg`, { type: 'image/*' })
          imageFiles.push(file)
        }
      } catch (error) {
        existingImageUrls.push({ media: images[i]?.src })
      }
    }
    return imageFiles
  }

  const handleOpenEventDetailsDialog = (data) => {
    setEventDetailsData(data)
    setOpenEventDetailsDialog(true)
  }

  const handleCloseEventDetailsDialog = () => {
    setOpenEventDetailsDialog(false)
  }

  const onUpdateSubmit = async (data) => {
    await postAuditLogs('update', reportsData?.id)
    if (reportFiles.length === 0) return
    setLoading(true)
    const tkn = localStorage.getItem('tokenDetails')
    const imgList = document.querySelectorAll('.imageupload')
    const existingImageUrls = []
    const imageFiles = await addImagesToFormData(existingImageUrls, imgList)
    // Array.from(uploadFiles).forEach((file, i) =>
    //     formData.append(`media[${[i]}]`, file, file.name)
    // );
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tkn}`,
      },
    }

    const mediaFiles = await uploadMedia(dispatch, { imageFiles: imageFiles }, true)
    const payloadObj = {
      participant: data?.participant,
      desc: data?.desc,
      initiativeId: reportsData?.initiativeId,
      mpmodelId: loggedInId,
      media: [...mediaFiles?.imageUrls, ...existingImageUrls],
    }

    try {
      const response = await dispatch(postCreateInitiativeReportBy(reportsData?.id, payloadObj, config))
      if (response.status === 200 || response.status === 201) {
        showNotification('Success', response.data.message, 'success')
        handleCloseUpdateInitiativeDetails(reset, setReportFiles, setUploadFiles)
        // Object.keys(data).map(val => resetField(val));
        // setFiles([]);
        // setUploadFiles([]);
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    } finally {
      setLoading(false)
    }
  }

  const handleDelete = (index) => {
    hiddenFileInput.current.value = ''
    const tempImages = [...reportFiles]
    tempImages.splice(index, 1)
    setReportFiles(tempImages)
    if (tenure !== 'Modi 2.0') {
      setCheckChanges(true)
    }
  }

  const blockInvalidChar = (e) => {
    if (['e', 'E', '+', '-', '.'].includes(e.key) || e.keyCode === 40) {
      e.preventDefault()
    }
    if (e.keyCode === 40) {
      // 40 is the key code for down arrow
      const currentValue = e.target.value
      if (currentValue > 1) {
        e.target.value = Number(currentValue) - 1
      }
      e.preventDefault()
    }
  }

  const onPasteCLick = (e) => {
    var clipboardData = e.clipboardData.getData('text')
    var cleanedData = clipboardData.replace(/[-e]/gi, '')
    if (cleanedData.length === 0) {
      e.preventDefault()
    } else {
      document.execCommand('insertText', false, cleanedData)
      e.preventDefault()
    }
  }

  const onDescChange = (e) => {
    let inputValue = e.target.value?.trimStart()
    inputValue = inputValue.replace(/\s{2,}/g, ' ')
    checkTextField(inputValue, 5000, 10, 'desc', 'the initiative description')
    e.target.value = inputValue
    setValue('desc', inputValue)
    if (e.target.value.trimStart() !== '') {
      setCheckDescValue(true)
      setDescValue(inputValue)
    } else {
      setCheckDescValue(false)
    }
    if (e.target.value.length > 5000) {
      setCheckDescValue(false)
      setCheckDescError(true)
      if (tenure !== 'Modi 2.0') {
        setCheckChanges(true)
      }
    } else {
      setCheckDescError(false)
      if (tenure !== 'Modi 2.0') {
        setCheckChanges(true)
      }
    }
  }

  return (
    <>
      <Dialog
        open={openUpdateIntiativeDialog}
        onClose={() => (
          handleCloseUpdateInitiativeDetails(reset, setReportFiles, setUploadFiles), postAuditLogs('close')
        )}
        sx={{ height: '96vh', marginTop: '25px' }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              onClick={async () => (
                await postAuditLogs('close'), handleCloseUpdateInitiativeDetails(reset, setReportFiles, setUploadFiles)
              )}
              src={CrossIcon}
              className="closeIcon"
            />
          </Box>
          <Box className="d-flex flex-column justify-content-center align-items-center">
            <Typography component="h6" className="update-initiative-report-title ">
              {`Initiative Report by ${reportsData?.user?.user_name ?? ''}`}
            </Typography>
            <Typography component="h1" className="initiative-title1">
              {initiativeName && (initiativeName ?? '')}
            </Typography>
          </Box>
          {/* <Divider className="divider"></Divider> */}
        </DialogTitle>

        <DialogContent>
          <Box className="text-center pt-1">
            <ProfilePictureComponent ProfilPic={reportsData?.user?.user_avatar} sxProfile={InitiativeProfile} />
            {/* {reportsData?.user?.user_avatar ? (
              <CardMedia
                component="img"
                src={reportsData?.user?.user_avatar}
                className="img-circle leader-circle-img mr-1 avatarimg"
              />
            ) : (
              <AccountCircleIcon
                sx={{
                  fontSize: 'xx-large',
                  width: '80px',
                  height: '80px',
                  color: '#EEEEEE',
                }}
              />
            )} */}
          </Box>
          <Box className="card-content">
            <Box>
              <Typography component="span" sx={{ fontWeight: 'bold' }} className="party-heading-text">
                {reportsData?.user?.designation?.length > 0 ? reportsData?.user?.designation + ' ,' : ''}{' '}
                {reportsData?.user?.party ?? ''}BJP
              </Typography>
              <Box />
              {reportsData?.user?.house === 'RS' ? (
                <Typography component="span" className="constituency-text">
                  {'State' + ' : ' + (reportsData?.user?.state_name ? reportsData?.user?.state_name : '-')}
                </Typography>
              ) : (
                <Typography component="span" className="constituency-text">
                  {'Constituency' +
                    ' : ' +
                    (reportsData?.user?.constituency_name ? reportsData?.user?.constituency_name : '-')}
                </Typography>
              )}
              <Box />
              {/* <Box style={{ display: "inline-block", background: "#fef6f2", padding: "5px", borderRadius: "10px", marginTop: "10px" }}>Participants: {reportsData?.participant ?? ""}</Box> */}
            </Box>
          </Box>
          <Grid container className="bg-white" sx={{ overflowX: 'hidden' }}>
            <Grid item xs={12} md={12}>
              <Grid item xs={12} md={12} sx={{ p: 4 }}>
                <Box>
                  <form>
                    <Grid container spacing={1} justifyContent="left" alignItems="center">
                      <Grid item xs={12} sx={{ pr: 1 }}>
                        <Typography component="b" sx={{ letterSpacing: 0 }} className="fieldname-bold-text">
                          Participants
                        </Typography>
                        <FormControl
                          fullWidth
                          height="60px"
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderRadius: '14px',
                            },
                            height: '60px',
                          }}
                        >
                          <TextField
                            className="stepperFormInput partic"
                            width="300px"
                            size="small"
                            type="number"
                            autoComplete="off"
                            disabled
                            sx={{
                              '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: 'black',
                                height: '40px',
                              },
                              width: '300px',
                              height: '56px',
                              backgroundColor: '#E8E8E8',
                              borderRadius: '13px',
                            }}
                            defaultValue={
                              details?.participant
                                ? allInitiativeReportsPage
                                  ? reportsData?.participant
                                  : details?.participant
                                : reportsData?.participant
                              // participants ? participants : 0
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <BootstrapTooltip
                                    title="This is the sum of participant count across all events under this initiative"
                                    placement="right"
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          fontSize: '18px',
                                          fontFamily: 'HK Grotesk',
                                          padding: '15px',
                                        },
                                      },
                                    }}
                                  >
                                    <img src={infoic} className="info-icon" />
                                    {/* <ErrorOutlineIcon sx={{ ml: 2, color: "#387194" }} /> */}
                                  </BootstrapTooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sx={{ pr: 1 }}>
                        <FormControl
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderRadius: '14px',
                            },
                          }}
                        >
                          <Box className="fieldname-bold-text">
                            <Typography
                              component="b"
                              sx={{
                                fontFamily: 'HK Grotesk',
                                color: '#357092',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                marginLeft: '5px',
                                marginBottom: '2px',
                                letterSpacing: 0,
                              }}
                            >
                              {' '}
                              Initiative Description
                            </Typography>
                          </Box>

                          <TextField
                            className="stepperFormInput"
                            // label="Title"
                            name="desc"
                            fullWidth
                            placeholder="Enter description"
                            size="small"
                            required
                            multiline
                            rows={3}
                            defaultValue={details?.desc ? details.desc : reportsData?.desc}
                            autoComplete="off"
                            {...register('desc', {
                              required: 'Please enter the initiative description',
                              maxLength: {
                                value: 5000,
                                message: 'Maximum 5000 characters allowed',
                              },
                              minLength: {
                                value: 10,
                                message: 'Minimum character length is 10',
                              },
                            })}
                            inputProps={{ maxLEngth: 5000, wrap: 'soft' }}
                            onChange={onDescChange}
                            onPaste={(e) => {
                              const pastedText = e.clipboardData.getData('text')
                              if (pastedText.length + e.target.value.length > 5000) {
                                e.preventDefault()
                                const truncatedText = pastedText.substring(0, 5000 - e.target.value.length)
                                document.execCommand('insertText', false, truncatedText)
                                setCheckDescError(true)
                              }
                            }}
                          />
                        </FormControl>
                        <FormHelperText
                          sx={{
                            color: errors && errors?.desc?.message ? '#d32f2f !important' : 'blue !important',
                          }}
                        >
                          {errors && errors?.desc?.message
                            ? errors && errors?.desc?.message
                            : checkDescValue
                            ? 'Maximum 5000 characters allowed'
                            : errors?.desc?.message}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </form>
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Typography component="span" className="fieldname-images-text">
                Images
              </Typography>
              <Box
                sx={{
                  paddingLeft: '20px',
                }}
                className="contpopup1 imagesection flex-row-reverse ml-0"
              >
                <Box className="ml-0 postionabs-2 itemfixed-update">
                  {reportFiles && reportFiles.length > 1 ? (
                    <Tabs
                      variant="scrollable"
                      scrollButtons
                      aria-label="visible arrows tabs example"
                      sx={{
                        [`& .${tabsClasses.scrollButtons}`]: {
                          '&.Mui-disabled': { opacity: 0.3 },
                        },
                        // marginLeft: "-45px",
                      }}
                    >
                      {reportFiles &&
                        reportFiles.map((file, index) => (
                          <Card sx={{ minWidth: 200, mr: 2 }} className=" bshadow cursorshow">
                            <CardContent>
                              <Box key={index} onClick={() => handleOpenPreviewImages(index)}>
                                {handlePreview(file)}
                              </Box>
                            </CardContent>
                            <img
                              src={closeIconp}
                              onClick={() => handleDelete(index)}
                              className="imageclose-updateinire"
                            />
                          </Card>
                        ))}
                    </Tabs>
                  ) : (
                    reportFiles &&
                    reportFiles.map((file, index) => (
                      <Card sx={{ minWidth: 200, mr: 2, position: 'relative' }} className=" bshadow">
                        <CardContent>
                          <Box key={index} onClick={() => handleOpenPreviewImages(index)} className="cursorshow">
                            {handlePreview(file)}
                          </Box>
                        </CardContent>
                        <img src={closeIconp} onClick={() => handleDelete(index)} className="imageclose-updateinire" />
                      </Card>
                    ))
                  )}
                </Box>
              </Box>
              <Grid item xs={6}>
                <input
                  type="file"
                  ref={hiddenFileInput}
                  className="d-none"
                  multiple
                  onChange={handleImageChange}
                  accept={acceptedImageFormat}
                />
                {!(reportFiles && reportFiles.length === 0) ? (
                  <Button
                    className="button-tr-citizen w-auto customaddmore"
                    startIcon={<AddIcon sx={{ fontSize: '20' }} />}
                    onClick={() => (handleClick(), postAuditLogs('add_more'))}
                    sx={{ mr: 1 }}
                  >
                    Add More
                  </Button>
                ) : (
                  <Box
                    sx={{
                      mt: 2,
                      display: 'flex',
                      '& > :not(style)': {
                        width: 150,
                        height: 140,
                        border: '3px dotted #356F92',
                        ml: '30px',
                      },
                    }}
                  >
                    <Paper
                      variant="outlined"
                      square
                      sx={{
                        border: 'dotted 3px #1976d2',
                        padding: '50px',
                        width: '40%',
                        borderRadius: '14px',
                        marginTop: '7px',
                      }}
                    >
                      <IconButton
                        color="primary"
                        aria-label="Upload"
                        onClick={async () => (postAuditLogs('add_images'), handleClick())}
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          position: 'relative',
                          top: '25%',
                          margin: '0 auto',
                        }}
                      >
                        <UploadIcon />
                      </IconButton>
                      <Box mt={3} />
                      <Box className="add-images-text">{'Add Images'}</Box>
                    </Paper>
                  </Box>
                )}
              </Grid>
              <FormHelperText className="FormHelperText-upload-event-image ">
                {reportFiles && reportFiles.length === 0 && 'Please upload event images'}
              </FormHelperText>
            </Box>
            <Grid item xs={12}>
              <Box className="events-conducted-margin-top">
                <Typography component="span" className="events-conducted-text">
                  {' '}
                  Event Conducted :{' '}
                  {(initiativeReportDetailsByMPIdAndIntiativeId &&
                    (initiativeReportDetailsByMPIdAndIntiativeId?.eventcount < 10
                      ? '0' + initiativeReportDetailsByMPIdAndIntiativeId?.eventcount
                      : initiativeReportDetailsByMPIdAndIntiativeId?.eventcount)) ||
                    0}
                </Typography>
                <Box className="gridinirepo events-conducted-margin-top">
                  <Box>
                    {eventsData && eventsData.length > 1 ? (
                      <Tabs
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 },
                          },
                        }}
                      >
                        {eventsData &&
                          eventsData.map((item, index) => (
                            <Card
                              sx={{
                                minWidth: '240px',
                                position: 'relative',
                                ml: 2,
                                maxWidth: '242px',
                              }}
                              className=" bshadow cardini2 cursorshow"
                              onClick={() => handleOpenEventDetailsDialog(item)}
                            >
                              <CardMedia>
                                <img
                                  src={item?.coverimage && JSON.parse(item?.coverimage)[0]}
                                  onError={(e) => (e.target.src = NoImageFound)}
                                  alt="Preview"
                                  className="form-img__img-preview-ec events-conducted-card-borderradius"
                                />
                              </CardMedia>

                              <CardContent sx={{ height: '62px', padding: '16px' }}>
                                <Box className="events-conducted-cardtext-position">
                                  <Typography
                                    component="span"
                                    className="events-conducted-card-name"
                                    title={item?.eventTitle ? item?.eventTitle : ''}
                                  >
                                    {(item.eventTitle && item.eventTitle.slice(0, 20)) || '-'}
                                    {item.eventTitle && item.eventTitle.length > 20 ? '...' : ''}
                                  </Typography>
                                  <Box />
                                  <Typography component="span" className="events-conducted-card-locatn-date">
                                    {Moment(item?.startDate).format('DD MMM YYYY')},
                                    <Typography component="span" title={item?.location ? item?.location : ''}>
                                      {(item.location && item.location.slice(0, 20)) || '-'}
                                      {item.location && item.location.length > 20 ? '...' : ''}
                                    </Typography>
                                  </Typography>
                                </Box>
                              </CardContent>
                              {/* <img
                              src={closeIconp}
                              className="imageclose-up2-new"
                              onClick={() =>
                                handleClickOpenDeleteDialog(
                                  item?.userId,
                                  item?.id,
                                  item?.initiativeId
                                )
                              }
                            /> */}
                            </Card>
                          ))}
                      </Tabs>
                    ) : (
                      eventsData &&
                      eventsData.map((item, index) => (
                        <Card
                          sx={{
                            position: 'relative',
                            minWidth: '190px',
                            maxWidth: '242px',
                            // width: "190px",
                            marginLeft: '25px',
                          }}
                          className=" bshadow cardini2 cursorshow"
                          onClick={() => handleOpenEventDetailsDialog(item)}
                        >
                          <CardMedia>
                            <img
                              src={item?.coverimage && JSON.parse(item?.coverimage)[0]}
                              onError={(e) => (e.target.src = NoImageFound)}
                              alt="Preview"
                              className="form-img__img-preview-ec2 events-conducted-card-borderradius"
                            />
                          </CardMedia>
                          <CardContent
                            sx={{
                              fontFamily: 'HK Grotesk',
                              color: '#2C2C2C',
                              fontSize: '12px',
                              fontWeight: 'bold',
                              height: '62px',
                              padding: '12px',
                            }}
                          >
                            <Typography
                              component="span"
                              className="custom-event-title"
                              title={item?.eventTitle ? item?.eventTitle : ''}
                            >
                              {(item.eventTitle && item.eventTitle.slice(0, 20)) || '-'}
                              {item.eventTitle && item.eventTitle.length > 20 ? '...' : ''}
                            </Typography>
                            <Box />
                            <Typography component="span" sx={{ letterSpacing: 0 }} className="custom-date-location">
                              {Moment(item?.startDate).format('DD MMM YYYY')},
                              <Typography
                                component="span"
                                sx={{
                                  letterSpacing: 0,
                                  fontFamily: 'HK Grotesk',
                                  fontSize: '12px',
                                  fontWeight: 'Bold',
                                }}
                                title={item?.location ? item?.location : ''}
                              >
                                {(item.location && item.location.slice(0, 20)) || '-'}
                                {item.location && item.location.length > 20 ? '...' : ''}
                              </Typography>
                            </Typography>
                          </CardContent>
                        </Card>
                      ))
                    )}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <React.Fragment>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                variant="contained"
                className={`button-tr-2-12-disa ${checkChanges ? 'button-tr-2' : ''}`}
                autoFocus
                sx={{ backgroundColor: '#ef7335' }}
                onClick={handleSubmit(onUpdateSubmit)}
                onFocus={() => setCheckDescValue(false)}
                disabled={checkChanges ? false : true && tenure === 'Modi 2.0'}
              >
                Update
              </Button>
              <Button
                variant="outlined"
                className="button-tr-citizen-cancel"
                autoFocus
                onClick={() => (
                  handleCloseUpdateInitiativeDetails(reset, setReportFiles, setUploadFiles), postAuditLogs('cancel')
                )}
              >
                Cancel
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </React.Fragment>
        </DialogActions>
        <PreviewImagesDialog
          openPreviewImages={openPreviewImages}
          handleClosePreviewImages={handleClosePreviewImages}
          files={reportFiles}
          previewImageIndex={previewImageIndex}
        />
        {openDeleteDialog && (
          <DeleteEventDialog
            openDeleteDialog={openDeleteDialog}
            handleCloseDeleteDialog={handleCloseDeleteDialog}
            mpId={mpId}
            eventId={eventId}
            initiativeId={initiativeId}
          />
        )}
        {openEventDetailsDialog && (
          <EventDetailsDialog
            openEventDetailsDialog={openEventDetailsDialog}
            handleCloseEventDetailsDialog={handleCloseEventDetailsDialog}
            details={eventDetailsData}
            mpProfileData={mpProfileData}
            eventByMpId={eventByMpId}
            initiativeId={initiativeID}
            tenure={tenure}
          />
        )}
      </Dialog>
    </>
  )
}

export default UpdateInitiativeReportDetails
