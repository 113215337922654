import React, { useEffect, useState, createRef } from 'react'
import Dialog from '@mui/material/Dialog'
import { Grid, MenuItem, Divider } from '@mui/material'
import { Box } from '@mui/system'
import UploadImages from '../UploadImageDialog/UploadImageDialog'
import { useForm } from 'react-hook-form'
import zipIcon from '../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../asserts/images/FileIcon.svg'
import { useNotificationContext } from '../../../utils/NotificationContext'
import './ImageTemplate.css'
import { useSelector } from 'react-redux'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { pushTag } from '../../../utils/gtmservice'
import TemplateDialogTitle from '../TemplateDialogTitle/TemplateDialogTitle'
import TemplateDialogCloseButton from '../TemplateDialogCloseButton/TemplateDialogCloseButton'
import TemplateDialogSubmitButton from '../TemplateDialogSubmitButton/TemplateDialogSubmitButton'
import AddMoreButton from '../AddMoreButton/AddMoreButton'
import DeleteRowButton from '../DeleteRowButton/DeleteRowButton'
import UploadFileComponent from '../UploadFileComponent/UploadFileComponent'
import AddAssetInputField from '../AddAssetInputField/AddAssetInputField'
import UploadedFileComponent from '../UploadedFileComponent/UploadedFileComponent'

const AddImageTemplates = ({
  handleCloseImageTemplateDialog,
  openImageTemplateDialog,
  setImageData,
  languages,
  editDetails,
  setUpdateValue,
}) => {
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
    reset,
  } = useForm()
  const zipFormat = ['application/x-zip-compressed', 'application/x-zip', 'zip']
  const aiFormat = ['application/postscript', 'application/illustrator', 'ai']
  const fileFormats = [
    'image/jpeg',
    'image/png',
    'image/tiff',
    'image/jpe',
    'image/jif',
    'image/jfif',
    'image/jfi',
    'image/vnd.adobe.photoshop',
    'application/x-photoshop',
    'application/postscript',
    'application/zip',
    'application/illustrator',
    'image/raw',
    'image/jpg',
    'image/webp',
    'image/svg',
    'image/svg+xml',
    'application/zip',
    'image/RAW',
    '.heif',
    '.indd',
    '.psd',
    '.eps',
    '.svgz',
    '.webp',
    '.zip',
    '.png',
    '.jpeg',
    'jpg',
    '.jif',
    '.jfif',
    '.jfi',
    '.rar',
    '.7z',
    '.gz',
    'application/x-zip-compressed',
  ]
  const { showNotification } = useNotificationContext()
  const [checkWarningPopup, setCheckWarningPopup] = useState(false)
  const [selectedBanner, setSelectedBanner] = useState([])
  const userProfile = useSelector((state) => state?.userProfile?.data)

  const [dialogState, setDialogState] = useState({
    open: false,
    for: undefined,
  })
  const [fileRefs, setFileRefs] = useState([])
  const [images, setImages] = useState([])
  const [files, setFiles] = useState([])
  const [currentImageIndex, setCurrentImageIndex] = useState(-1)
  const [initialId, setInitialId] = useState(-1)
  const [inputState, setInputState] = useState([
    {
      id: Date.now(),
      inputType: true,
      bannerSize: false,
      language: true,
      upload: true,
      remove: true,
    },
  ])
  const bannerSize = [
    { id: 1, value: '1:1' },
    { id: 2, value: '16:9' },
    { id: 3, value: '3:6' },
  ]

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('add_image_template_popup', sqId, data)
  }

  useEffect(() => {
    setFileRefs(inputState.map(() => createRef()))
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'add_image_template_pop_up',
    })
  }, [inputState])

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'add_image_template_pop_up',
      message: message,
    })
  }

  const fetchEditDetailsData = () => {
    if (editDetails) {
      const temp = []
      editDetails?.forEach((template, i) => {
        temp.push({
          id: i,
          inputType: true,
          inputTypeValue: template.typeof_image,
          bannerSize: template.typeof_image === 'Outdoor Banner',
          bannerSizeValue: template.banner_size !== null ? template.banner_size.trim() : '',
          language: true,
          languageValue: template.imageLanguage,
          upload: true,
          remove: true,
          imagesData: typeof template.url === 'string' ? JSON.parse(template.url) : template.url,
        })
        !getValues(`${i}`)?.length > 0 &&
          setValue(`${i}.images`, typeof template.url === 'string' ? JSON.parse(template.url) : template.url)
        !getValues(`${i}`)?.length > 0 && setValue(`${i}.imageType`, template.typeof_image)
        !getValues(`${i}`)?.length > 0 && setValue(`${i}.language`, template.imageLanguage)
      })
      setInputState(temp)
      setFileRefs(temp.map(() => createRef()))
    }
  }

  useEffect(() => {
    fetchEditDetailsData()
  }, [editDetails])

  const imagesTypes = [
    { id: 1, value: 'Certificate' },
    { id: 2, value: 'Social Media' },
    { id: 3, value: 'Outdoor Banner' },
  ]

  const removeInput = (id) => {
    const tempData = [...inputState]
    const temp2 = [...selectedBanner]
    const newBanner = temp2.filter((input) => input?.id !== id)
    const newData = tempData.filter((input) => input.id !== id)
    const index = inputState.findIndex((data) => data.id === id)
    const newRefs = fileRefs.filter((_, i) => tempData[i].id !== id)
    unregister(index.toString())
    setInputState(newData)
    setSelectedBanner(newBanner)
    setFileRefs(newRefs)
    const newFormData = {}
    const formData = getValues()
    const formDataValues = Object.values(formData)
    formDataValues.forEach((item, ind) => {
      newFormData[ind] = item
    })
    reset(newFormData)
  }

  const addInput = () => {
    const newInput = {
      id: inputState[inputState?.length - 1]?.id + 1,
      inputType: true,
      bannerSize: false,
      language: true,
      upload: true,
      remove: true,
    }
    setInputState((prevState) => [...prevState, newInput])
    setFileRefs((prevRefs) => [...prevRefs, createRef()])
  }

  const showBannerField = (e, input) => {
    const tempData = [...inputState]
    const index = tempData.findIndex((data) => data.id === input.id)
    if (e.target.value === 'Outdoor Banner') {
      tempData[index].bannerSize = true
    } else {
      tempData[index].bannerSize = false
    }
    setInputState(tempData)
  }

  const isUploadImageEnabledOrNot = (i) => {
    const typeOfImage = getValues(`${i}.imageType`)
    const typeOfBanner = getValues(`${i}.bannerSize`)
    const language = getValues(`${i}.language`)
    let isDisabled = false
    if (typeOfImage === 'Outdoor Banner') {
      isDisabled = Boolean(typeOfImage && typeOfBanner && language)
    } else {
      isDisabled = Boolean(typeOfImage && language)
    }
    return isDisabled
  }

  const handleClick = async (e, i) => {
    setImages([])
    if (isUploadImageEnabledOrNot(i)) {
      fileRefs[i].current?.click()
      await postAuditLogs('browse', { image_type: getValues(`${i}.imageType`) })
    }
    e?.stopPropagation()
  }
  const handleDelete = (i) => {
    setCheckWarningPopup(true)
    const tempImages = [...images]
    tempImages.splice(0, 1)
    setImages(tempImages)
    if (inputState?.length > 0) {
      let updatedInputState = [...inputState] // create a copy of inputState
      updatedInputState[i] = {
        // update the object at index i
        ...updatedInputState[i], // spread the current object's properties
        imagesData: inputState[i]?.imagesData.slice(1), // create a new array without the first element
      }
      setInputState(updatedInputState)
      let val = getValues(`${i}`)

      delete val.images[0]
      val.images.splice(0, 1)
      setValue(`${i}.images`, val.images)
    } else {
      let val = getValues(`${i}`)

      delete val.images[0]
      val.images.splice(0, 1)
      setValue(`${i}.images`, val.images)
    }
  }

  const imageValidation = (file) => {
    const isRightFormat = fileFormats.includes(file.type) || fileFormats.includes('.' + file.name?.split('.').pop())
    const fileSizeInBytes = file.size
    const maxSizeInBytes = 20 * 1024 * 1024 // 20MB
    if (fileSizeInBytes > maxSizeInBytes) {
      showNotification('Error', 'Please upload image with size less than 20MB', 'error')
      return false
    }
    if (!isRightFormat) {
      showNotification('Error', 'You can only upload jpg, jpeg and png images', 'error')
      return false
    }
    return true
  }

  const processFile = async (file) => {
    const reader = new FileReader()
    const readerPromise = new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve({
          type: zipFormat.includes(file.type) ? 'zip' : aiFormat.includes(file.type) ? 'ai' : 'image',
          url: zipFormat.includes(file.type) ? zipIcon : aiFormat.includes(file.type) ? fileIcon : reader.result,
          file: file,
        })
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })

    try {
      const result = await readerPromise // Wait for the FileReader to complete
      return result
    } catch (error) {
      console.error('Error reading file:', error)
      throw error
    }
  }

  const handleImageChange = async (e, i) => {
    let uploadedFiles = Array.from(e.target.files)
    if (uploadedFiles.length > 0) {
      setCheckWarningPopup(true)
    }
    let remainingZipCount = 5 - countExistingZips()
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i]
      if (!imageValidation(file)) {
        uploadedFiles = []

        return
      }
      if (file.type === 'application/x-zip-compressed' && remainingZipCount > 0) {
        remainingZipCount--
      } else if (file.type === 'application/x-zip-compressed' && remainingZipCount <= 0) {
        // Reject the upload and display an error message
        showNotification('Error', 'Only a maximum of 5 zip files are allowed in total.', 'error')
        return
      }
    }
    if (uploadedFiles.length > 0) {
      const processingPromises = uploadedFiles.map((file) => processFile(file))
      let newImages = []

      await Promise.all(processingPromises)
        .then((newFiles) => {
          setFiles((prevFiles) => [...prevFiles, ...newFiles])
          newImages = newFiles
        })
        .catch((error) => {
          console.error('Error processing files:', error)
        })
      setImages((prevImages) => [...prevImages, ...newImages])
      handleOpenUploadDialog(`${currentImageIndex}`)
    }
  }

  const countExistingZips = () => {
    let zipCount = 0
    images.forEach((file) => {
      if (file.type === 'zip') {
        zipCount++
      }
    })
    return zipCount
  }

  const handleOpenUploadDialog = (id, edit = false, images = []) => {
    if (!edit) {
      setDialogState({ open: true, for: id })
    } else {
      setImages(images)
      setDialogState({ open: true, images, for: id })
    }
  }

  const handleCloseUploadDialog = (changesMade = false) => {
    setDialogState({ open: false, for: undefined })
    if (!changesMade) {
      fetchEditDetailsData()
    }
  }

  const onSubmit = async (data) => {
    const objectArray = Object.values(data)
    let fileTypes = []
    for (let i = 0; i < objectArray.length; i++) {
      let currentImages = objectArray[i].images
      for (let j = 0; j < currentImages.length; j++) {
        if (currentImages[j].file && !fileTypes.includes(currentImages[j].file?.type)) {
          fileTypes.push(currentImages[j].file.type)
        }
      }
    }
    await postAuditLogs(editDetails?.length > 0 ? 'update' : 'add_image_template', { file_type: fileTypes.join('~') })
    setImageData([])
    setImageData((prevData) => [
      ...prevData,
      prevData.length > 0 ? objectArray?.map((val) => prevData[0]?.push(val)) : objectArray,
    ])
    setUpdateValue(true)
    handleCloseImageTemplateDialog()
  }

  const registerFunction = (i) => {
    return register(`${i}.images`, {
      required: 'Please add Images',
    })
  }

  const registerFunctionForLanguage = (i, input) => {
    return register(`${i}.language`, {
      required: `Please select Language of the ${input.bannerSize ? 'banner' : 'Images'}`,
    })
  }

  const registerFunctionForBannerSize = (i) => {
    return register(`${i}.bannerSize`, {
      required: 'Please select a Banner Size',
    })
  }

  const registerFunctionForImageType = (i) => {
    return register(`${i}.imageType`, {
      required: 'Please select the Type of images',
    })
  }

  return (
    <Dialog
      onClose={() => handleCloseImageTemplateDialog(checkWarningPopup)}
      open={openImageTemplateDialog}
      sx={{ height: '90vh', marginTop: '25px' }}
    >
      <TemplateDialogTitle title={editDetails?.length > 0 ? 'Update Image Templates' : 'Add Image Templates'} />
      <TemplateDialogCloseButton
        onClick={async () => {
          await postAuditLogs('close')
          sendGALog('close')
          handleCloseImageTemplateDialog(checkWarningPopup, true)
        }}
      />
      <Box sx={{ m: 3, overflowX: 'hidden' }}>
        <form>
          {inputState?.map((input, i) => {
            return (
              <Box className="box" key={input.id}>
                <Grid container>
                  <Grid item xs={6} md={6} lg={6} xl={6}>
                    {input.inputType && (
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <AddAssetInputField
                          labelName={'Type of Images'}
                          onChange={(e) => {
                            showBannerField(e, input)
                            setCheckWarningPopup(true)
                            setError(`${i}.imageType`, null)
                          }}
                          defaultValue={input?.inputTypeValue}
                          errorMessage={errors && errors[i] && errors[i].imageType?.message}
                          data={
                            imagesTypes &&
                            imagesTypes.map((s) => {
                              return (
                                <MenuItem
                                  native="true"
                                  key={s.value}
                                  sx={{ width: '100%' }}
                                  value={s.value}
                                  size="small"
                                >
                                  {s.value}
                                </MenuItem>
                              )
                            })
                          }
                          registerFunction={() => registerFunctionForImageType(i)}
                        />
                      </Grid>
                    )}

                    {input.bannerSize && (
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <AddAssetInputField
                          labelName={'Banner Size'}
                          defaultValue={input?.bannerSizeValue}
                          onChange={(e) => {
                            let updatedBanner = [...selectedBanner]
                            updatedBanner[i] = {
                              ...selectedBanner[i],
                              id: input.id,
                              bannerSize: e.target.value,
                            }
                            setSelectedBanner(updatedBanner)
                            setCheckWarningPopup(true)
                            setError(`${i}.bannerSize`, null)
                          }}
                          errorMessage={errors && errors[i] && errors[i].bannerSize?.message}
                          data={
                            bannerSize &&
                            bannerSize.map((s) => {
                              return (
                                <MenuItem
                                  native="true"
                                  key={s.value}
                                  sx={{ width: '100%' }}
                                  value={s.value}
                                  size="small"
                                >
                                  {s.value}
                                </MenuItem>
                              )
                            })
                          }
                          registerFunction={() => registerFunctionForBannerSize(i)}
                        />
                      </Grid>
                    )}

                    {input.language && (
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <AddAssetInputField
                          labelName={'Language'}
                          defaultValue={input?.languageValue}
                          data={
                            languages &&
                            languages.map((s) => {
                              return (
                                <MenuItem native key={s.id} sx={{ width: '100%' }} value={s.language} size="small">
                                  {s.language}
                                </MenuItem>
                              )
                            })
                          }
                          onChange={() => {
                            setError(`${i}.language`, null)
                            setCheckWarningPopup(true)
                          }}
                          errorMessage={errors && errors[i] && errors[i].language?.message}
                          registerFunction={() => registerFunctionForLanguage(i, input)}
                        />
                      </Grid>
                    )}

                    {input.remove && inputState.length !== 1 && (
                      <Grid item xs={3} sx={{ mt: 1, marginLeft: '-15px', filter: 'grayscale(900%) brightness(52%)' }}>
                        <DeleteRowButton
                          buttonTitle={'Delete Row'}
                          onClick={() => {
                            removeInput(input.id)
                            setCheckWarningPopup(true)
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {!input?.imagesData?.[0] ? (
                    <UploadFileComponent
                      ComponentTitle={'Image Preview'}
                      input={input}
                      errors={errors}
                      i={i}
                      fileFormats={fileFormats}
                      assetRef={fileRefs[i]}
                      onClick={async (e) => {
                        await handleClick(e, i)
                        setCurrentImageIndex(i)
                      }}
                      onChange={(e) => {
                        handleImageChange(e, i)
                        setError(`${i}.images`, null)
                      }}
                      registerFunction={() => registerFunction(i)}
                    />
                  ) : (
                    <UploadedFileComponent
                      i={i}
                      input={input}
                      ComponentTitle={'Image Preview'}
                      getValues={getValues}
                      iconButtonOnClick={(e) => {
                        handleClick(e, i)
                        setCurrentImageIndex(i)
                      }}
                      iconButtonSx={{ ml: selectedBanner[i]?.bannerSize === '16:9' && '18px' }}
                      registerFunction={() => registerFunction(i)}
                      fileFormats={fileFormats}
                      assetRef={fileRefs[i]}
                      inputOnChange={(e) => {
                        handleImageChange(e, i)
                        setError(`${i}.images`, null)
                      }}
                      boxClassName={`add-img-st ${
                        selectedBanner[i]?.bannerSize === '16:9' ? 'mg-left-1' : 'mg-left-2'
                      }`}
                      handleOpenUploadDialog={handleOpenUploadDialog}
                      selectedBanner={selectedBanner}
                      errors={errors}
                      handleDelete={handleDelete}
                    />
                  )}
                  <Box className="d-1-st"></Box>
                  <Divider className="hr-last"></Divider>
                </Grid>
              </Box>
            )
          })}
        </form>
        <Box className="btn-box">
          <AddMoreButton
            buttonTitle={'Add Row'}
            boxSx={{
              display: 'flex',
              flexDirection: 'row',
              mb: 2,
              mt: 2,
              marginLeft: '-15px',
            }}
            onClick={async () => {
              await postAuditLogs('add_row')
              sendGALog('add_row')
              addInput()
            }}
          />
        </Box>
      </Box>
      <TemplateDialogSubmitButton
        dialogActionSx={{ justifyContent: 'center', mb: 2 }}
        buttonSx={{
          p: 1,
          backgroundColor: '#ef7335',
          borderRadius: 4,
          width: '250px',
        }}
        onClick={handleSubmit(onSubmit)}
        onFocus={() => sendGALog(editDetails?.length > 0 ? 'update' : 'add_image')}
        title={editDetails?.length > 0 ? 'Update Image Template' : 'Add Image Template'}
      />

      {dialogState?.open && (
        <UploadImages
          handleCloseUploadDialog={handleCloseUploadDialog}
          state={dialogState}
          setValue={setValue}
          getValue={getValues}
          setImagesNew={setImages}
          initialImages={dialogState.for === 'edit' ? dialogState.images : images}
          initialId={initialId}
          setInputState={setInputState}
          inputState={inputState}
          fileFormats={fileFormats}
          zipFormat={zipFormat}
          aiFormat={aiFormat}
          setCheckWarningPopup={setCheckWarningPopup}
        />
      )}
    </Dialog>
  )
}

export default AddImageTemplates
