import { Height } from '@mui/icons-material'
import { Avatar, ImageList, ImageListItem, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import folder from '../../../asserts/images/sharing/Folder.png'
import { getLanguageList } from '../../../store/action/languageList'
import './LanguageData.css'

const LanguageData = ({
  setLanguageName,
  imageResponse,
  folderName,
  videoResponse,
}) => {
  // const languageList = useSelector(
  //   (state) => state.languageList?.data?.languagedata
  // );

  const [langData, setLangData] = useState([])

  const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(getLanguageList());
  // }, []);

  // const langFolder = languageList?.map((obj) => ({
  //   id: obj.id,
  //   title: obj.language,
  //   img: folder,
  // }));
  // [
  //   {
  //     id: 1,
  //     title: "English",
  //     img: folder,
  //   },

  //   {
  //     id: 4,
  //     title: "Hindi",
  //     img: folder,
  //   },
  // ];

  const handleImageLangFolders = () => {
    let imageLangs = []
    const data =
      imageResponse &&
      imageResponse.filter((data) => {
        return data.typeof_image === folderName
      })
    data.forEach((value) => {
      if (!imageLangs.includes(value.imageLanguage)) {
        imageLangs.push(value.imageLanguage)
      }
    })

    setLangData(imageLangs)
  }

  const handleVideoLangFolders = () => {
    let videoLangs = []
    videoResponse &&
      videoResponse.forEach((data) => {
        if (!videoLangs.includes(data.language)) {
          videoLangs.push(data.language)
        }
      })
    setLangData(videoLangs)
  }

  useEffect(() => {
    if (imageResponse) {
      handleImageLangFolders()
    }
    if (videoResponse) {
      handleVideoLangFolders()
    }
  }, [])

  return (
    <>
      {langData.length === 0 ? (
        <Typography component="span" className="no-videos-message">
          No videos uploaded by admin
        </Typography>
      ) : (
        <ImageList>
          {langData &&
            langData?.map((item, index) => (
              <ImageListItem
                key={index}
                className="bgfolder cursorshow"
                sx={{ marginRight: '10px' }}
                onClick={() => {
                  setLanguageName(item)
                }}
              >
                <Avatar
                  sx={{ width: '130px', height: '80px' }}
                  className="image-container"
                  key={item}
                  src={`${folder}`} //?w=164&h=164&fit=crop&auto=format
                  srcSet={`${folder}`} //?w=164&h=164&fit=crop&auto=format&dpr=2 2x
                  alt={item}
                />
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontFamily: 'HK Grotesk',
                    color: '#356F92',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    // ml:"10px",
                    mt: '25px',
                    alignSelf: 'center',
                  }}
                >
                  {item}
                </Typography>
              </ImageListItem>
            ))}
        </ImageList>
      )}
    </>
  )
}

export default LanguageData
