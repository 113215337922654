import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import ShareAlert from 'asserts/images/ShareAlert.svg'
import EditAlert from 'asserts/images/EditAlert.svg'
import DeleteAlert from 'asserts/images/DeleteAlert.svg'
import { getAlertAction } from 'store/action/NotificationAlerts/getNotificationAlerts'
import { createAlertAction } from 'store/action/NotificationAlerts/createNotificationAlerts'
import { defaultErrorMessage } from 'utils/constant'
import { useNotificationContext } from 'utils/NotificationContext'
import ConfirmationDialog from 'Components/SendNotification/ConfirmationDialog'
import { deleteAlertAction } from 'store/action/NotificationAlerts/deleteNotificationAlerts'
import WhatsNewSection from 'Components/ReusableComponents.js/WhatsNewSection'
import Moment from 'moment'
import convertToHtml from 'Components/ReusableComponents.js/ConvertToHtml'

let tableHeaders = [
  {
    id: 0,
    title: 'Alert Type',
    dataKey: 'type',
  },
  {
    id: 1,
    title: 'Content',
    dataKey: 'content',
  },
  {
    id: 2,
    title: 'Duration',
    dataKey: 'startDate',
  },
  {
    id: 3,
    title: 'Action',
    dataKey: '',
  },
]

const PreviousAlert = ({ setEditItem, setActiveTabIndex, setIsSetBefore, setIsTempSet }) => {
  const [isShareConfirmationDialogOpen, setIsShareConfirmationDialogOpen] = useState(false)
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false)
  const [isWhatsNewPopupOpen, setIsWhatsNewPopupOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const { showNotification } = useNotificationContext()
  const dispatch = useDispatch()
  const getNotificationAlerts = useSelector((state) => state?.getNotificationAlerts?.data)

  useEffect(() => {
    dispatch(getAlertAction())
  }, [])

  const shareAlert = async () => {
    const tkn = localStorage.getItem('tokenDetails')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tkn}`,
      },
    }
    let formattedStartDate = Moment(selectedItem.startDate).format('YYYY-MM-DD')
    let formattedEndDate = Moment(selectedItem.endDate).format('YYYY-MM-DD')
    const payload = {
      type: selectedItem.type,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }
    if (selectedItem.type === 'temporary') {
      payload.desc = selectedItem.desc
      payload.alertType = selectedItem.alertType
    } else {
      payload.newFeatures = selectedItem.newFeatures
      payload.refinements = selectedItem.refinements
    }

    try {
      const response = await dispatch(createAlertAction(selectedItem.id, payload, config))
      if (response.status === 200 || response.status === 201) {
        showNotification('Success', response.data.message, 'success')
      } else {
        showNotification('Error', response?.data?.message || defaultErrorMessage)
      }
    } catch (error) {
      showNotification('Error', error?.message || defaultErrorMessage)
    } finally {
      setIsShareConfirmationDialogOpen(false)
    }
  }

  const onShareClicked = (item) => {
    setSelectedItem(item)
    setIsShareConfirmationDialogOpen(true)
  }

  const editAlert = (item) => {
    setActiveTabIndex(item.type === 'temporary' ? 0 : 1)
    setEditItem(item)
    item.type === 'temporary' ? setIsTempSet(true) : setIsSetBefore(true)
  }

  const onDeleteClicked = (item) => {
    setSelectedItem(item)
    setIsDeleteConfirmationDialogOpen(true)
  }

  const deleteAlert = async () => {
    try {
      const response = await dispatch(deleteAlertAction(selectedItem.id))
      if (response.status === 200 || response.status === 201) {
        showNotification('Success', response.data.message, 'success')
      } else {
        showNotification('Error', response?.data?.message || defaultErrorMessage)
      }
    } catch (error) {
      showNotification('Error', error?.message || defaultErrorMessage)
    } finally {
      setIsDeleteConfirmationDialogOpen(false)
      dispatch(getAlertAction())
    }
  }

  const openWhatNewPopup = (item) => {
    setSelectedItem(item)
    setIsWhatsNewPopupOpen(true)
  }
  const displayData = (item, id) => {
    switch (id) {
      case 0:
        return (
          <Typography variant="p" className="body-type-typography">
            {item.type?.toLowerCase() === 'temporary' ? 'Temporary Alert' : "What's New"}
          </Typography>
        )
      case 1:
        return (
          <Box>
            {item.type?.toLowerCase() === 'temporary' ? (
              <Typography variant="p" className="text-break">
                {convertToHtml(item.desc)}
              </Typography>
            ) : (
              <Typography
                variant="p"
                className="whats-new-content-decs cursorshow"
                onClick={() => openWhatNewPopup(item)}
              >
                Click here to read the Previous what's new content
              </Typography>
            )}
          </Box>
        )
      case 2:
        return (
          <Typography variant="p" className="body-type-typography">
            {Moment(item.startDate).format('DD MMM YYYY').toUpperCase()} to
            <br />
            {Moment(item.endDate).format('DD MMM YYYY').toUpperCase()}
          </Typography>
        )
      default:
        return (
          <Box className="d-flex mr-3">
            <img
              src={ShareAlert}
              alt="Share Alert"
              className="action-icons cursorshow"
              onClick={() => onShareClicked(item)}
            />
            <img src={EditAlert} alt="Edit Alert" className="action-icons cursorshow" onClick={() => editAlert(item)} />
            <img
              src={DeleteAlert}
              alt="Delete Alert"
              className="action-icons cursorshow"
              onClick={() => onDeleteClicked(item)}
            />
          </Box>
        )
    }
  }

  return (
    <TableContainer className="table-container my-3">
      <Table className="tableheader">
        <TableHead>
          <TableRow>
            {tableHeaders?.map((item, index) => (
              <TableCell
                key={index}
                className={`alerts-tablecellstyle ${item.dataKey === 'type' ? 'alert-type-header' : ''}`}
              >
                {item.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>
      <Box className="table-body-box-container customscroll">
        <Table>
          <TableBody>
            {getNotificationAlerts != null && getNotificationAlerts?.length > 0
              ? getNotificationAlerts?.map((item, index) => (
                  <TableRow key={index} className="body-row">
                    {tableHeaders?.map((data, index) => (
                      <TableCell key={index} className="table-body-cell">
                        {displayData(item, data.id)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </Box>
      {isShareConfirmationDialogOpen && (
        <ConfirmationDialog
          isConfirmationDialogOpen={isShareConfirmationDialogOpen}
          message="Are you sure you want to set this alert?"
          confirmBtnContent="SET"
          rejectBtnContent="CANCEL"
          onConfirm={shareAlert}
          onReject={() => setIsShareConfirmationDialogOpen(false)}
        />
      )}
      {isDeleteConfirmationDialogOpen && (
        <ConfirmationDialog
          isConfirmationDialogOpen={isDeleteConfirmationDialogOpen}
          message="Are you sure you want to delete this Alert?"
          confirmBtnContent="DELETE"
          rejectBtnContent="CANCEL"
          onConfirm={deleteAlert}
          onReject={() => setIsDeleteConfirmationDialogOpen(false)}
        />
      )}
      <WhatsNewSection
        popupOpen={isWhatsNewPopupOpen}
        setPopupOpen={setIsWhatsNewPopupOpen}
        selectedWhatsNewData={selectedItem}
      />
    </TableContainer>
  )
}

export default PreviousAlert
