import React, { useEffect, useMemo, useRef, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import unknownImg from '../../../asserts/images/folders.png'
import { Box, DialogContent, ImageListItem, Typography } from '@mui/material'
import closeIconp from '../../../asserts/images/close-1.png'
import { useNotificationContext } from '../../../utils/NotificationContext'
import { useSelector } from 'react-redux'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { VideoLoader } from '../../ReusableComponents.js/VideoLoader'
import { handleFileName, handleVideoSrc } from '../../ReusableComponents.js/reuseMethods'
import zipIcon from '../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../asserts/images/FileIcon.svg'
import './UploadVideoDialog.css'
import UploadAssetDialogButtonsSection from '../UploadAssetDialogButtonsSection/UploadAssetDialogButtonsSection'
import TemplateDialogTitle from '../TemplateDialogTitle/TemplateDialogTitle'
import TemplateDialogCloseButton from '../TemplateDialogCloseButton/TemplateDialogCloseButton'
import { useLoading } from '../../../utils/LoadingContext'

const UploadVideos = ({
  handleCloseUploadDialog,
  state,
  setValue,
  setVideosNew,
  initialVideos,
  fileFormats,
  setCheckWarningPopup,
  setInputState,
  videoRefs,
}) => {
  const [videosArr, setVideosArr] = useState([])
  const [initialVideosData, setInitialVideosData] = useState(initialVideos.length > 0 && initialVideos)

  useEffect(() => {
    setVideosArr([...initialVideosData])
  }, [initialVideosData])

  const [uploadMore, setUploadMore] = useState(false)
  const { showNotification } = useNotificationContext()
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const { setLoading } = useLoading()

  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )

  const countExistingZips = () => {
    let zipCount = 0
    videosArr.forEach((file) => {
      if (file.type === 'zip') {
        zipCount++
      }
    })
    return zipCount
  }
  const zipFormat = ['application/x-zip-compressed', 'application/x-zip', 'zip']
  const aiFormat = ['application/postscript', 'application/illustrator', 'ai']

  const handleVideoChange = async (e) => {
    setLoading(true)
    await postAuditLogs('upload_more')
    const uploadedFiles = e.target.files
    if (uploadedFiles.length > 0) {
      setCheckWarningPopup(true)
    }
    let processedVideos = 0
    let remainingZipCount = 5 - countExistingZips()
    let newVideos = []
    let countId = videosArr[videosArr.length - 1] ? videosArr[videosArr.length - 1].id : -1
    for (let i = 0; i < uploadedFiles.length; i++) {
      let nameExists = videosArr.some((file) => file?.file?.name === uploadedFiles[i]?.name)
      if (nameExists) {
        showNotification('Error', 'File Already Uploaded', 'error')
        setLoading(false)
        return
      } else {
        const file = uploadedFiles[i]
        const isRightFormat =
          fileFormats.includes(file.type) || fileFormats.includes('.' + file?.name?.split('.').pop())
        const fileSizeInBytes = file.size
        const maxSizeInBytes = 1024 * 1024 * 1024 // 1GB
        if (fileSizeInBytes > maxSizeInBytes) {
          showNotification('Error', 'Please upload video with size less than 1GB', 'error')
          setLoading(false)
          return
        }
        if (file.type === 'application/x-zip-compressed' && remainingZipCount > 0) {
          remainingZipCount--
        } else if (file.type === 'application/x-zip-compressed' && remainingZipCount <= 0) {
          // Reject the upload and display an error message
          showNotification('Error', 'Only a maximum of 5 zip files are allowed in total.', 'error')
          setLoading(false)
          return
        }
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          if (!isRightFormat) {
            showNotification(
              'Error',
              'Accepted file formats for open & zip uploads: .mp4, .mov, .wmv, .webm, .avi, .flv, .mkv, .mts',
              'error'
            )
            setLoading(false)
            return
          } else {
            newVideos.push({
              type: zipFormat.includes(file.type) ? 'zip' : aiFormat.includes(file.type) ? 'ai' : 'video',

              url: zipFormat.includes(file.type)
                ? zipIcon
                : aiFormat.includes(file.type)
                ? fileIcon
                : fileFormats.includes(file.type)
                ? reader.result
                : reader.result,
              file: file,
              id: ++countId,
            })
            processedVideos++
            if (processedVideos === uploadedFiles.length) {
              setVideosArr((prevState) => [...prevState, ...newVideos])
              setUploadMore(true)
            }
          }
        }
      }
    }
  }

  const handleDelete = (index) => {
    setCheckWarningPopup(true)
    const videoArr = videosArr
    videoArr.splice(index, 1)
    setVideosArr([...videoArr])
  }

  // const closeDialog = (event, reason) => {
  //   if (reason && reason == 'backdropClick') return

  //   setValue(`${state.for}.videos`, [])
  //   setVideosNew([])

  //   setInputState((prevState) => {
  //     const newState = [...prevState]
  //     newState[`${state.for}`] = {
  //       ...newState[`${state.for}`],
  //       videosData: [],
  //     }
  //     return newState
  //   })
  //   handleCloseUploadDialog(true)
  // }

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('video_preview_popup', sqId, data)
  }

  useEffect(() => {
    postAuditLogs('pageload')
  }, [])

  useEffect(() => {
    if (uploadMore) {
      setLoading(false)
      setUploadMore(false)
    }
  }, [videosArr])

  const handleSaveButton = async (e) => {
    await postAuditLogs('save')
    handleCloseUploadDialog(true)

    setInputState((prevState) => {
      const newState = [...prevState]
      newState[`${state.for}`] = {
        ...newState[`${state.for}`],
        videosData: videosArr,
      }
      return newState
    })

    setValue(`${state.for}.videos`, videosArr)
    setVideosNew([...videosArr])
  }
  const handleCancelButton = async (e) => {
    setVideosArr([])

    setInputState((prevState) => {
      const newState = [...prevState]
      newState[`${state.for}`] = {
        ...newState[`${state.for}`],
        videosData: [],
      }
      return newState
    })

    const inputField = videoRefs[state.for].current
    if (inputField) {
      inputField.value = null
    }

    setValue(`${state.for}.videos`, [])
    handleCloseUploadDialog(true)
  }

  return (
    <Dialog
      onClose={handleCancelButton}
      open={state.open}
      sx={{ borderRadius: '14px' }}
      disableEscapeKeyDown
      PaperProps={{
        style: {
          height: '500px',
          maxHeight: '500px',
          overflow: 'hidden',
        },
      }}
    >
      <TemplateDialogTitle title={'Video Preview'} type={'preview'} />
      <TemplateDialogCloseButton
        onClick={async (e) => {
          await postAuditLogs('close')
          handleCancelButton()
        }}
      />
      <DialogContent style={{ overflow: 'hidden' }}>
        <Box sx={{ height: '85%', overflow: 'auto' }}>
          <div className="image-column-align">
            <>
              {videosArr &&
                videosArr?.length > 0 &&
                videosArr.map((video, index) => (
                  <>
                    <div key={video.id} className="video-preview-element-position">
                      {handleVideoSrc(video) === 'video' && (
                        <VideoLoader
                          key={video.id}
                          width="200"
                          top="35%"
                          height="150"
                          src={video.url || video.url === '' ? URL.createObjectURL(video.file) : video}
                          controls
                        />
                      )}
                      {['ai', 'unknown', 'zip', 'mts'].includes(handleVideoSrc(video)) && (
                        <div>
                          <ImageListItem
                            key={index}
                            className="bgfolder cursorshow"
                            sx={{
                              margin: '0px',
                              width: '200px !important',
                              padding: '7px 33px 39px',
                              aspectRatio: '1 / 0.9 !important',
                            }}
                          >
                            <img
                              style={{ margin: '0' }}
                              key={index}
                              src={
                                handleVideoSrc(video) === 'ai'
                                  ? fileIcon
                                  : handleVideoSrc(video) === 'zip'
                                  ? zipIcon
                                  : unknownImg
                              }
                            />
                            <Typography variant="subtitle1" sx={subtitleStyles}>
                              {handleFileName(video)}
                            </Typography>
                          </ImageListItem>
                        </div>
                      )}
                      <img src={closeIconp} onClick={() => handleDelete(index)} className="imageclose-video" />
                    </div>
                  </>
                ))}
            </>
          </div>
        </Box>
      </DialogContent>

      <UploadAssetDialogButtonsSection
        onChange={handleVideoChange}
        onClickForSaveButton={(e) => handleSaveButton(e)}
        onClickForCancelButton={async (e) => {
          await postAuditLogs('cancel')
          handleCancelButton(e)
        }}
        fileFormats={fileFormats}
      />
    </Dialog>
  )
}

export default UploadVideos
