import { Typography, Tooltip } from '@mui/material'
import ImagesModalRefactor from '../../ImagesShare/ImagesModalRefactor'
import VideosModal from '../../VideoSHare/VideosModal'
import NoImageFound from '../../../../asserts/images/noImageFound.jpg'
import './InitiativeHeaderImg.css'
import '../InitiativesDetails.css'
const InitiativeHeaderImg = ({ data, handleImageClose, openImage, handleVideoClose, openVideo }) => {
  return (
    <>
      <Tooltip title={data?.initiativeName} arrow>
        <Typography variant="body1" className="ellipsewehe12 cursorshow initiative-title-style name-color">
          {data?.initiativeName}
        </Typography>
      </Tooltip>

      <ImagesModalRefactor setOpen={handleImageClose} open={openImage} />
      <VideosModal setOpenVideo={handleVideoClose} open={openVideo}></VideosModal>
      <img
        src={JSON.parse(data?.coverimage)[0]}
        className="seva-image seva-image-style"
        onError={(e) => (e.target.src = NoImageFound)}
      ></img>
    </>
  )
}
export default InitiativeHeaderImg
