import { Box } from '@mui/material'
import buttonicon2 from '../../../../asserts/images/Card2your.svg'
import buttonicon from '../../../../asserts/images/Card1your.svg'
import desabled_event from '../../../../asserts/images/EventDisabled.svg'
import desabled_report from '../../../../asserts/images/ReportDisabled.svg'
import EventInitiativeButton from './EventInitiativeButton'
import '../InitiativesDetails.css'
import './EventInitiativeReport.css'

function EventInitiativeReport({ handleInitiativeEvent, handleInitiativeReport, data, initReportDetails, tohide }) {
  //
  return (
    <>
      <Box>
        <Box className="twobu">
          <EventInitiativeButton
            handleOnclick={handleInitiativeEvent}
            button={!tohide ? desabled_event : buttonicon}
            id={1}
            toHide={!tohide}
          />
          <EventInitiativeButton
            toHide={!tohide}
            handleOnclick={handleInitiativeReport}
            button={!tohide ? desabled_report : buttonicon2}
            id={2}
            data={data}
            initReportDetails={initReportDetails}
          />
        </Box>
      </Box>
    </>
  )
}
export default EventInitiativeReport
