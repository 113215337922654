import { POST_CREATE_OPEDS_FAILURE, POST_CREATE_OPEDS_REQUEST, POST_CREATE_OPEDS_SUCCESS } from './types'
import axiosInstance from '../../utils/api'

export const postCreateOpedsRequest = () => {
  return {
    type: POST_CREATE_OPEDS_REQUEST,
  }
}
export const postCreateOpedsSuccess = (value) => {
  return {
    type: POST_CREATE_OPEDS_SUCCESS,
    payload: value,
  }
}
export const postCreateOpedsFailure = (error) => {
  return {
    type: POST_CREATE_OPEDS_FAILURE,
    payload: error,
  }
}

export const postCreateOpeds = (id, payload, config) => async (dispatch) => {
  dispatch(postCreateOpedsRequest)
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(`/api/opeds/create/${id}`, payload, config)
      const result = response.data
      dispatch(postCreateOpedsSuccess(result))
      resolve(response)
    } catch (error) {
      const { response } = error
      const errorMsg = response?.data?.message
      dispatch(postCreateOpedsFailure(errorMsg))
      reject(errorMsg)
    }
  })
}
