import { Button, Box, CardMedia } from '@mui/material'

const CreateInitiativesButton = ({ buttonTitle, onClick, image }) => {
  return (
    <Button
      className="button-tr1-admin"
      sx={{
        fontFamily: 'HK Grotesk ExtraBold !important',
        textTransform: 'none !important',
        fontWeight: 'bolder !important',
        fontSize: '20px !important',
        padding: '8px 20px',
        width: '242px',
      }}
      onClick={onClick}
    >
      {}
      <Box className="create-container">
        {buttonTitle}
        <Box className="create-button">
          <CardMedia component="img" image={image} className="create-now-button-icon" />
        </Box>
      </Box>
    </Button>
  )
}

export default CreateInitiativesButton
