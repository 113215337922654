import {
  POST_CREATE_DEVELOPMENT_PROJECT_FAILURE,
  POST_CREATE_DEVELOPMENT_PROJECT_REQUEST,
  POST_CREATE_DEVELOPMENT_PROJECT_SUCCESS,
} from '../action/types';

const initialState = {
  data: [],
  error: '',
  loading: false,
};

export const createDevelopmentProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_CREATE_DEVELOPMENT_PROJECT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case POST_CREATE_DEVELOPMENT_PROJECT_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case POST_CREATE_DEVELOPMENT_PROJECT_FAILURE:
      return {
        data: [],
        error: action.payload,
        loading: true,
      };
    default:
      return state;
  }
};
