import { Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getEventsList } from '../../../store/action/eventsList'
import { getOpedsAndBooksList, getOnlyMediaCoverageList } from '../../../store/action/mediaCoverageList'
import { getDevelopmentProjectsList } from '../../../store/action/developmentProjectList'
import {
  getOngoingSevaIntiativesList,
  getOngoingSevaIntiativesListByMpId,
} from '../../../store/action/ongoingSevaInitiativesList'
import { useLoading } from '../../../utils/LoadingContext'
import { useNotificationContext } from '../../../utils/NotificationContext'
import { getIds } from '../../ReusableComponents.js/getIds'
import { getMpProfile } from '../../../store/action/individualMP'
import ContentCarousel from '../ContentCarousel/ContentCarousel'

const SevaUpdateContent = ({ pageTitle, sendGALog, mpProfileData }) => {
  const eventsLists = useSelector((state) => state?.eventsList?.data)
  const eventsList = eventsLists?.data
  const mediaCoverageLists = useSelector((state) => state?.onlyMediaCoverageList?.data)
  const mediaCoverageList = mediaCoverageLists?.data
  const opedAndBooksLists = useSelector((state) => state?.opedAndBooksList?.data)
  const opedAndBooksList = opedAndBooksLists?.data
  const developmentProjectsLists = useSelector((state) => state?.developmentProjectsList?.data)
  const developmentProjectsList = developmentProjectsLists?.data
  const ongoingSevaInitiativesLists = useSelector((state) => state?.ongoingSevaInitiativesList?.data)
  const ongoingSevaInitiativesList = ongoingSevaInitiativesLists?.initiative

  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const defaultLimit = 10
  const dispatch = useDispatch()

  const fetchAllData = async () => {
    try {
      setLoading(true)
      const payload = {
        id: 0,
        limit: defaultLimit,
        prevId: 0,
        isPrevTenure: false,
      }
      dispatch(getMpProfile(getIds()))
      dispatch(getOngoingSevaIntiativesList(payload))
      dispatch(getEventsList(payload))
      dispatch(getOnlyMediaCoverageList(payload))
      dispatch(getOpedsAndBooksList(payload))
      dispatch(getDevelopmentProjectsList(payload))
      dispatch(getOngoingSevaIntiativesListByMpId({ id: getIds() }))
    } catch (error) {
      showNotification('Error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAllData()
  }, [])

  return (
    <Box>
      <ContentCarousel
        title={'Ongoing Seva Initiatives'}
        data={ongoingSevaInitiativesList}
        page_title={pageTitle}
        sendGALog={sendGALog}
        tenure={'Modi 3.0'}
      />

      <ContentCarousel
        title={'Seva Events'}
        data={eventsList}
        page_title={pageTitle}
        sendGALog={sendGALog}
        mpProfileData={mpProfileData}
        tenure={'Modi 3.0'}
      />

      <ContentCarousel
        title={'Media Coverage'}
        data={mediaCoverageList}
        page_title={pageTitle}
        sendGALog={sendGALog}
        mpProfileData={mpProfileData}
        tenure={'Modi 3.0'}
      />

      <ContentCarousel
        title={'Development Projects'}
        data={developmentProjectsList}
        page_title={pageTitle}
        sendGALog={sendGALog}
        mpProfileData={mpProfileData}
        tenure={'Modi 3.0'}
      />

      <ContentCarousel
        title={'Miscellaneous'}
        data={opedAndBooksList}
        page_title={pageTitle}
        sendGALog={sendGALog}
        mpProfileData={mpProfileData}
        tenure={'Modi 3.0'}
      />
    </Box>
  )
}

export default SevaUpdateContent
