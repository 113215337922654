import { INDIVIDUAL_MP_VIEW_FAILURE, INDIVIDUAL_MP_VIEW_SUCCESS, INDIVIDUAL_MP_VIEW_REQUEST } from './types';
import axiosInstance from '../../utils/api';

export const getMp = () => {
  return {
    type: INDIVIDUAL_MP_VIEW_REQUEST,
  };
};
export const getMpProfileSuccess = (value) => {
  return {
    type: INDIVIDUAL_MP_VIEW_SUCCESS,
    payload: value,
  };
};
export const getMpProfileFailure = (error) => {
  return {
    type: INDIVIDUAL_MP_VIEW_FAILURE,
    payload: error,
  };
};

const getMpProfile = (query) => async (dispatch) => {
  dispatch(getMp);
  await axiosInstance
    .get(`/api/user/getmpforcitizen/${query}`)
    .then((response) => {
      const result = response.data;
      // const result = mpList
      dispatch(getMpProfileSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(getMpProfileFailure(errorMsg));
    });
};

const updateMpProfile = (formData, id, config) => async (dispatch) => {
  return axiosInstance.post(`/api/user/update-mpdata/${id}`, formData, config, {});
};

export { getMpProfile, updateMpProfile };
