import { Backdrop, Box, Typography } from '@mui/material'
import { useMemo } from 'react'
import CircularDotLoader from '../../../asserts/images/circular_dot_loader.svg'
import './BackdropComponent.css'

const BackdropComponent = ({ isLoading }) => {
  const backdropStyle = useMemo(
    () => ({
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
    }),
    []
  )

  return (
    <Backdrop sx={backdropStyle} open={isLoading} className="loadingText">
      <img alt="Search" width={40} height={40} src={CircularDotLoader} />
      <Box m={3} />
      <Typography component="span" variant="contained">
        Your file is being processed,
        <Box />
      </Typography>
      <Typography variant="span"> Please wait...</Typography>
    </Backdrop>
  )
}

export default BackdropComponent
