import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'

const InitiativeType = ({ initiativeType, setInitiativeType }) => {
  return (
    <Grid item xs={10} md={10} lg={10} xl={10} sx={{ marginTop: '25px' }}>
      <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#2e739c' }}>
        <Typography className="headincreateini initiative-titles-st ">Select Initiative type</Typography>
      </InputLabel>
      <FormControl>
        <Select
          value={initiativeType ? initiativeType : 'public'}
          sx={{
            width: '300px',
            maxHeight: '50px',
          }}
        >
          <MenuItem
            value="public"
            onClick={() => {
              setInitiativeType('public')
            }}
          >
            Public
          </MenuItem>
          <MenuItem
            value="private"
            onClick={() => {
              setInitiativeType('private')
            }}
          >
            Private
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
  )
}

export default InitiativeType
