import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { useSelector } from 'react-redux'
import { pushTag } from '../../../utils/gtmservice'
import { Box } from '@mui/system'
import './WarningMessageDialog.css'
import TemplateDialogCloseButton from '../TemplateDialogCloseButton/TemplateDialogCloseButton'
import DialogButton from '../DialogButton/DialogButton'

export default function WarningMessageDialog({ openWarningDialog, handleCloseWarningDialog, setCheckWarningClick }) {
  const userProfile = useSelector((state) => state?.userProfile?.data)

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('exit_form_popup', sqId, data)
  }

  React.useEffect(() => {
    postAuditLogs('pageload')
  }, [])

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'seva_initiative_page',
      message: message,
    })
  }

  React.useEffect(() => {
    if (openWarningDialog) {
      sendGALog('exit_pop_up')
    }
  }, [openWarningDialog])

  return (
    <Box>
      <Dialog
        open={openWarningDialog}
        onClose={() => {
          sendGALog('exit_no')
          handleCloseWarningDialog()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ maxWidth: '550px', margin: '0 auto' }}
      >
        <TemplateDialogCloseButton
          onClick={async () => {
            await postAuditLogs('close')
            sendGALog('exit_no')
            handleCloseWarningDialog()
          }}
        />
        <DialogContent sx={{ justifyContent: 'center' }}>
          <DialogContentText className="WarningMessageDialog-text ml-4">
            Are you sure you want to leave?
            <br />
            All entered details will be lost
          </DialogContentText>
        </DialogContent>
        <DialogButton
          onClickForYesButton={async () => {
            await postAuditLogs('yes')
            sendGALog('exit_yes')
            setCheckWarningClick(true)
          }}
          onClickForNoButton={async () => {
            await postAuditLogs('no')
            handleCloseWarningDialog()
          }}
        />
      </Dialog>
    </Box>
  )
}
