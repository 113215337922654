import { ImageListItem, Typography, Avatar } from '@mui/material'
import React from 'react'
import pdf3 from '../../asserts/images/pdf3.png'
import { useMemo } from 'react'
import close from '../../asserts/images/Cancel.svg'
import { useState } from 'react'
// import PdfModal from '../YourIntiative/PdfModal';
import { handlePdfClickOpen } from './reuseMethods'

const PdfCard = ({ url, name, editable, detailPdf, checkUpdate }) => {
  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )
  const [openPdf, setOpenPdf] = useState(false)
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  const handleClosePdf = (value) => {
    setOpenPdf(false)
  }

  const handleClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    checkUpdate && checkUpdate(false)
    detailPdf(null)
  }
  return (
    <>
      <ImageListItem
        className="bgfolder cursorshow"
        sx={{
          margin: '10px',
          width: '200px !important',
          padding: '20px',
          boxShadow: '0px 0px 10px 1px',
          aspectRatio: `${isSafari ? '2.7 / 1.9' : '1 / 0.9'} !important`,
        }}
        onClick={() => {
          handlePdfClickOpen(url)
        }}
      >
        <Avatar
          src={pdf3}
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            display: 'block',
            margin: '0',
            padding: '27px 37px',
          }}
        />
        {editable && (
          <Avatar src={close} onClick={handleClose} className="closeIcon cardCloseIcon" sx={{ position: 'absolute' }} />
        )}
        <Typography variant="subtitle1" sx={subtitleStyles}>
          {name}
        </Typography>
      </ImageListItem>
    </>
  )
}

export default PdfCard
