import { Box, Avatar } from '@mui/material'
import TwitterLoaderImg from '../../../asserts/images/loader-img.png'
import { TwitterTweetEmbed } from 'react-twitter-embed'
import { getRoles } from '../../ReusableComponents.js/getIds'

const TwitterHighlightsContainer = ({ twitterData }) => {
  const userId = parseInt(getRoles())
  const loaderStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }

  return (
    <Box className={`tweetsContainer ${userId === 2 || userId === 3 ? 'max-height-small' : 'max-height-large'}`}>
      {twitterData
        ? twitterData.map((data) => (
            <Box key={data.tweetId}>
              <TwitterTweetEmbed
                tweetId={data.tweetId}
                placeholder={
                  <Box sx={loaderStyle}>
                    <Box className="custom-loader">
                      <Avatar className="small-loader-img" src={TwitterLoaderImg} alt="Loading..." variant="square" />
                      <Box className="custom-small-spinner">
                        <div className="loader-box-small box-1"></div>
                        <div className="loader-box-small box-2"></div>
                        <div className="loader-box-small box-3"></div>
                      </Box>
                    </Box>
                  </Box>
                }
              />
            </Box>
          ))
        : null}
    </Box>
  )
}

export default TwitterHighlightsContainer
