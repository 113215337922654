import { Box, Card } from '@mui/material'
import './MPRankCard.css'
import MpSevaProfileDetails from '../MpSevaProfileDetails/MpSevaProfileDetails'
import MySevaRankCardCarousel from '../../MySevaUpdatesRefactor/MySevaRankCardCarousel/MySevaRankCardCarousel'

const MPRankCard = ({ mpId, mpName }) => {
  return (
    <Card sx={{ marginTop: '20px', borderRadius: '20px', overflow: 'auto' }}>
      <Box className="ranks-card-div width-mp-ranks">
        <MpSevaProfileDetails />
        <MySevaRankCardCarousel mpId={mpId} mpName={mpName} mySeva={false} />
      </Box>
    </Card>
  )
}

export default MPRankCard
