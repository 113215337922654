import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { env } from '../env'

export default function ConnectionCloseError(props) {
  const { isOpen } = props

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ maxWidth: '600px', margin: '0 auto' }}
      >
        <DialogContent>
          <DialogContentText
            sx={{
              minHeight: '150px',
              display: '-webkit-inline-box',
              alignItems: 'center',
              color: '#6C6C6C',
              fontFamily: 'HK Grotesk',
              fontSize: '22px',
              textAlign: 'center',
              fontWeight: 'Bold',
              mt: 2,
              mb: 2,
              justifyContent: 'center',
            }}
          >
            You were logged out of the system,
            <br />
            Please login again from{' '}
            <a href={env.REACT_APP_WEBSITE_URL}>{env.REACT_APP_WEBSITE_TEXT}</a>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}
