import store from '../store'
import { ERROR_CLOSED_CONNECTION } from '../store/action/types'
import * as moment from 'moment'

const { dispatch } = store

export const throttle = (fn, coolDown) => {
  let lastArgs
  const run = () => {
    if (lastArgs) {
      fn(...lastArgs)
      lastArgs = undefined
    }
  }

  const throttled = (...args) => {
    const isOnCoolDown = !!lastArgs
    lastArgs = args
    if (isOnCoolDown) {
      return
    }
    window.setTimeout(run, coolDown)
  }

  return throttled
}

export const clearLocalStorageOnLogout = () => {
  dispatch({ type: ERROR_CLOSED_CONNECTION })
  localStorage.clear()
}

export const clearCapaignStorage = () => {
  localStorage.removeItem('isBdayCampaignEnabled')
  localStorage.removeItem('isSurveyCampaignEnabled')
  localStorage.removeItem('leaderBoardSelectedItem')
  window.location.reload()
}

export const formatUtcDateToLocal = (date) => {
  return date ? moment(date).local().format('DD/MM/YYYY') : null
}

export const getCoverImageSource = (url) => {
  return url?.endsWith('.mp4') ? `${url}#t=0.1` : url
}

export const getCoverImageThumbnail = (data) => {
  const isVideo = data?.media && JSON?.parse(data?.media)?.[0]?.endsWith('.mp4')
  if (isVideo) {
    //if the fetched media is video
    if (data?.thumbnails) {
      // if the thumbnail is present
      return data?.thumbnails && JSON?.parse(data?.thumbnails)?.[0]
    } else {
      // if the thumbnail is not generated
      return data?.media && getCoverImageSource(JSON?.parse(data?.media)?.[0])
    }
  } else {
    // if the fetched media is an image
    return data?.media && JSON?.parse(data?.media)?.[0]
  }
}
