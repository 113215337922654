import { SEARCH_MP_REQUEST, SEARCH_MP_SUCCESS, SEARCH_MP_FAILURE } from './types';
import axiosInstance from '../../utils/api';

export const searchMpListRequest = () => {
  return {
    type: SEARCH_MP_REQUEST,
    payload: [],
  };
};
export const searchMpListSuccess = (value) => {
  return {
    type: SEARCH_MP_SUCCESS,
    payload: value,
  };
};
export const searchMpListFailure = (error) => {
  return {
    type: SEARCH_MP_FAILURE,
    payload: error,
  };
};

export const searchMpList = (query) => async (dispatch) => {
  dispatch(searchMpListRequest);
  await axiosInstance
    .get(`/api/search?searchText=${query}`)
    .then((response) => {
      const result = response.data;
      // const result = mpList
      dispatch(searchMpListSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(searchMpListFailure(errorMsg));
    });
};
