import { Box, Card, Avatar, Typography } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { BootstrapTooltip1 } from '../../MpHome/MpHome'
import infoic from '../../../asserts/images/Info.svg'
import MySevaRankCardCarousel from '../MySevaRankCardCarousel/MySevaRankCardCarousel'
import './MySevaRankCard.css'

const MySevaRankCard = ({ mySeva, mpName, mpId }) => {
  return (
    <Card sx={{ marginTop: '20px', borderRadius: '20px' }}>
      <Box className="ranks-card-div width-my-ranks">
        <Box className="carousel-format classnamrleaderelli-sevarank">
          <Box className="textwe-next" sx={{ position: 'relative' }}>
            <Box>
              {mySeva && mpName}
              {mySeva && (
                <BootstrapTooltip1
                  title="Showing results of last 15 days. Refreshes everyday at midnight."
                  placement="bottom"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: '16px',
                        fontFamily: 'HK Grotesk',
                        padding: '10px',
                      },
                    },
                  }}
                >
                  <img src={infoic} className="info-icon ml-2 pt-0" alt="tooltip" />
                </BootstrapTooltip1>
              )}
            </Box>
          </Box>
        </Box>
        <MySevaRankCardCarousel mpId={mpId} mySeva={mySeva} mpName={mpName} />
      </Box>
    </Card>
  )
}

export default MySevaRankCard
