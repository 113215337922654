import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import Avatar from '@mui/material/Avatar'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import { MpColumnFilter, MpColumnHeaders } from './MpTableData'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useDispatch, useSelector } from 'react-redux'
import { getMpList } from '../../../store/action/mpList'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'
import { useLoading } from '../../../utils/LoadingContext'
import { useNotificationContext } from '../../../utils/NotificationContext'
import './MpTables.css'
import { getOngoingSevaIntiativesListByMpId } from '../../../store/action/ongoingSevaInitiativesList'
import { getIds } from '../../ReusableComponents.js/getIds'
import { convertNumberIntoLacsCr } from '../../ReusableComponents.js/reuseMethods'

export default function StickyHeadTable({ selectedCard, flag, dataFilter, setTableLoad, tenure }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [page, setPage] = React.useState(1)
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  let keys = [
    'id',
    'icon',
    'user_name',
    'imageSrc',
    'position',
    'constituency_name',
    'sevaScore',
    'initiatives',
    'eventOrganized',
    'citizen',
    'memberAdded',
    'followers',
    'twitterPerformance',
    'opEds',
    'mediaCoverage',
    'donation',
    'dateIssued',
    'cardsCreated',
    'likes',
    'wishes',
    'campaignScore',
  ]
  const numberValues = [
    'sevaScore',
    'initiatives',
    'eventOrganized',
    'memberAdded',
    'followers',
    'twitterPerformance',
    'opEds',
    'mediaCoverage',
    'donation',
    'cardsCreated',
    'likes',
    'wishes',
    'campaignScore',
    'campaignScore',
  ]

  const mpList = useSelector((state) => state?.mpList?.data)
  const scrollRef = React.useRef()
  const loggedInId = getIds()
  let columnsAll = keys
  let column = []

  const mpFilteredData = React.useMemo(() => {
    return mpList.slice(9)
  }, [mpList])

  var prevDenseRank = mpList[8]?.dense_rank
  var rankNumber = 9

  const fetchMpData = async () => {
    try {
      setTableLoad(true)
      await dispatch(getMpList(flag, page, dataFilter, tenure === 'Modi2.0' ? '2.o' : null))
    } catch (error) {
      showNotification('Error', error)
    } finally {
      setTableLoad(false)
    }
  }

  React.useEffect(() => {
    fetchMpData()
  }, [page, tenure])

  React.useEffect(() => {
    scrollRef.current.scrollTop = 0
    setPage(1)
  }, [dataFilter, tenure])

  React.useEffect(() => {
    scrollRef.current.scrollTop = 0
    setPage(1)
  }, [flag, tenure])

  let filteredColumns = MpColumnFilter.filter((s) => s.screenNumber === selectedCard)[0]?.columns

  column = columnsAll
    .filter((s) => filteredColumns?.includes(MpColumnHeaders[s]))
    .map((x) => ({ id: x, label: x == 'id' ? 'Rank' : MpColumnHeaders[x] }))

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current

      if (
        Math.floor(scrollTop) + clientHeight == scrollHeight ||
        Math.floor(scrollTop) + clientHeight + 1 == scrollHeight ||
        Math.ceil(scrollTop) + clientHeight == scrollHeight
      ) {
        setPage(page + 1)
      }
    }
  }

  const handleNavigate = async (item) => {
    if (item?.hasOwnProperty('isMP') && item?.isMP == 0) {
      return
    }
    await dispatch(getOngoingSevaIntiativesListByMpId({ id: item.id }))
    if (item.id === loggedInId) {
      navigate('/MySevaUpdates', {
        state: {
          MpName: 'My Seva Rank',
          mpId: loggedInId,
        },
      })
    } else {
      navigate('/MpSevaUpdates', {
        state: {
          MpName: item.user_name,
          mpId: item.id,
          mpClick: true,
          mpData: item && item,
        },
      })
    }
  }

  const generateRankNumber = (item) => {
    let newRankNumber = rankNumber
    let newPrevDenseRank = prevDenseRank

    if (item?.dense_rank === prevDenseRank) {
      newPrevDenseRank = item?.dense_rank
    } else {
      newRankNumber += 1
      newPrevDenseRank = item?.dense_rank
    }
    rankNumber = newRankNumber
    prevDenseRank = newPrevDenseRank
    return newRankNumber
  }

  return (
    <TableContainer className={` ${column[4]?.id === 'initiatives' ? 'initiatives-table__body-wrapper' : ''}`}>
      <Table className="tableheader">
        <TableHead>
          <TableRow className={` ${column[4]?.id === 'initiatives' ? 'initiatives-table__header' : ''}`}>
            {column.map((column) => (
              <TableCell key={column?.id} id={'header' + column.id} align={column.align} className="tablecellstyle">
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      </Table>

      <Box ref={scrollRef} onScroll={() => onScroll()} className="customscroll scroll-bg-transparent table-div-style">
        <Table className="cursorshow tablebodystyle">
          <TableBody>
            <React.Fragment>
              {mpFilteredData.map((item, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  clickable
                  tabIndex={-1}
                  key={index + 1}
                  onClick={(e) => {
                    if (!window.getSelection().toString()) {
                      handleNavigate(item)
                    }
                  }}
                  className={`${item?.id === mpProfileData?.id && 'tablerow-bgcolor'}`}
                >
                  {column.map((column, key) =>
                    key == 2 ? (
                      <TableCell id={column.id}>
                        <Box className="table-name-container">
                          <Box>
                            <Typography variant="body1" className="item-user-name">
                              {item.user_name}
                            </Typography>
                            <Typography variant="body1" className="item-party">
                              {item.party}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell
                        key={index + 1}
                        id={column.id}
                        align={column.align}
                        // className={`${key === 0 ? 'rank-style-0' : 'rank-style'}`}
                        className={`${column.id == 'memberAdded' ? 'padding-left-members-added' : ''}
                               ${key === 0 ? 'rank-style-0' : 'rank-style'}`}
                      >
                        {key == 0 ? (
                          generateRankNumber(item)
                        ) : key == 1 ? (
                          <Box>
                            {item.user_avatar ? (
                              <Avatar
                                alt="avatar"
                                src={item.user_avatar}
                                className="img-circle leader-circle-img mr-1 item-user-avatar"
                                draggable="false"
                                sx={{ padding: '0px' }}
                              />
                            ) : (
                              <Box>
                                <AccountCircleIcon
                                  sx={{
                                    fontSize: 'xx-large',
                                    width: '50px',
                                    height: '50px',
                                    color: '#EEEEEE',
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        ) : column.id == 'donation' ? (
                          '₹ ' + convertNumberIntoLacsCr(parseInt(item[column.id], 10) || 0)
                        ) : column.id == 'campaignScore' ? (
                          convertNumberIntoLacsCr(parseInt(item[column.id] < 0 ? 0 : item[column.id], 10) || 0)
                        ) : numberValues.includes(column.id) ? (
                          convertNumberIntoLacsCr(parseInt(item[column.id], 10) || 0)
                        ) : (
                          convertNumberIntoLacsCr(item[column.id])
                        )}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </React.Fragment>
          </TableBody>
        </Table>
      </Box>
    </TableContainer>
  )
}
