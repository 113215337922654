import { Backdrop, Box, CircularProgress } from '@mui/material'
import React, { createContext, useContext, useState } from 'react'
import LoaderImg from '../asserts/images/loader-img.png'
import './LoadingContext.css'

const LoadingContext = createContext({ loading: false, setLoading: null })

export function LoadingProvider({ children }) {
  const [loading, setLoading] = useState(false)
  const value = { loading, setLoading }
  const loaderStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }
  return (
    <LoadingContext.Provider value={value}>
      {children}
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1600 }} open={loading}>
        {' '}
        <Box sx={loaderStyle}>
          <div className="custom-loader">
            <img className="loader-img" src={LoaderImg} alt="Loading..." />
            <div className="custom-spinner">
              <div className="loader-box box-1"></div>
              <div className="loader-box box-2"></div>
              <div className="loader-box box-3"></div>
            </div>
          </div>
        </Box>
      </Backdrop>
    </LoadingContext.Provider>
  )
}

export function useLoading() {
  const context = useContext(LoadingContext)
  if (!context) {
    throw new Error('useLoading must be used within LoadingProvider')
  }
  return context
}
