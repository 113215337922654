import { FETCH_MPLIST_FAILURE, FETCH_MPLIST_REQUEST, FETCH_MPLIST_SUCCESS, UPDATE_MPLIST_SUCCESS } from './types'
import axiosInstance from '../../utils/api'

export const fetchMpListRequest = () => {
  return {
    type: FETCH_MPLIST_REQUEST,
  }
}
export const fetchMpListSuccess = (value) => {
  return {
    type: FETCH_MPLIST_SUCCESS,
    payload: value,
  }
}
export const fetchMpListFailure = (error) => {
  return {
    type: FETCH_MPLIST_FAILURE,
    payload: error,
  }
}
export const updateMpListSuccess = (value) => {
  return {
    type: UPDATE_MPLIST_SUCCESS,
    payload: value,
  }
}

export const getMpList = (flag, page, filter, tenure) => async (dispatch) => {
  dispatch(fetchMpListRequest)
  page && page !== 1
    ? await axiosInstance
        .get(`/api/user/getallmpdata/${flag}${tenure ? '/' + tenure : ''}?page=${page}&filter=${filter}`)
        .then((response) => {
          const result = response.data.result
          // const result = mpList
          dispatch(updateMpListSuccess(result))
        })
        .catch((error) => {
          const errorMsg = error.message
          dispatch(fetchMpListFailure(errorMsg))
        })
    : await axiosInstance
        .get(`/api/user/getallmpdata/${flag}${tenure ? '/' + tenure : ''}?page=1&filter=${filter}`)
        .then((response) => {
          const result = response.data.result
          // const result = mpList
          dispatch(fetchMpListSuccess(result))
        })
        .catch((error) => {
          const errorMsg = error.message
          dispatch(fetchMpListFailure(errorMsg))
        })
}
