import { Box } from '@mui/material'
import MPTableHeaderHighlights from '../MPTableHeaderHighlights/MPTableHeaderHighlights'
import TableHighlights from '../../Highlights/TableHighlightsRefactor/TableHighlights'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { useNotificationContext } from '../../../utils/NotificationContext'
import { getAllHighlights } from '../../../store/action/globalHighlights'

const MPHighlightsTable = ({ mpId, active }) => {
  const { data, loading } = useSelector((state) => state?.highlights)
  const [dataLoading, setDataLoading] = useState(loading)
  const [rows, setRows] = useState([])
  const { showNotification } = useNotificationContext()
  const dispatch = useDispatch()

  function createData(heading, value) {
    return { heading, value }
  }

  useEffect(() => {
    fetchData()
  }, [active])

  useEffect(() => {
    const rowsData = Object.keys(data ? data : [])?.map((val) => {
      return createData(val, data[val])
    })
    rowsData.splice(10, 1)
    setRows(rowsData)
  }, [data])

  const fetchData = async () => {
    try {
      const response = await dispatch(getAllHighlights(mpId, 'alltime', active))
      if (response.status !== 200) {
        showNotification('Error', response?.data?.message, 'error')
      }
    } catch (error) {
      showNotification('Error', error, 'error')
    } finally {
      setDataLoading(false)
    }
  }

  return (
    <Box>
      <MPTableHeaderHighlights title={'Highlights'} />
      <TableHighlights data={rows} dataLoading={dataLoading} />
    </Box>
  )
}

export default MPHighlightsTable
