import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CrossIcon from '../../asserts/images/Close.svg'
import { Avatar, IconButton } from '@mui/material'
import { makeLogRequest } from '../../utils/AuditLogs'
import { useSelector } from 'react-redux'
import { pushTag } from '../../utils/gtmservice'
import { Box } from '@mui/system'

export default function WarningMessageDialog({
  openWarningDialog,
  handleCloseWarningDialog,
  setCheckWarningClick,
}) {
  const userProfile = useSelector((state) => state?.userProfile?.data)

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('exit_form_popup', sqId, data)
  }

  React.useEffect(() => {
    postAuditLogs('pageload')
  }, [])

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'seva_initiative_page',
      message: message,
    })
  }

  React.useEffect(() => {
    if (openWarningDialog) {
      sendGALog('exit_pop_up')
    }
  }, [openWarningDialog])

  return (
    <Box>
      <Dialog
        open={openWarningDialog}
        onClose={() => {
          sendGALog('exit_no')
          handleCloseWarningDialog()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ maxWidth: '550px', margin: '0 auto' }}
      >
        <Avatar
          onClick={async () => {
            await postAuditLogs('close')
            sendGALog('exit_no')
            handleCloseWarningDialog()
          }}
          src={CrossIcon}
          sx={{ height: '30px', width: '30px', position: 'absolute' }}
          className="closeIcon"
        />
        <DialogContent sx={{ justifyContent: 'center' }}>
          <DialogContentText
            sx={{
              minHeight: '130px',
              display: 'flex',
              alignItems: 'center',
              color: '#6C6C6C',
              fontFamily: 'HK Grotesk',
              fontSize: '27px',
              textAlign: 'center',
              ml: 4,
              paddingTop: '20px',
            }}
          >
            Are you sure you want to leave?
            <br />
            All entered details will be lost
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mt: 0 }}>
          <Button
            className="button-tr-2"
            sx={{ fontFamily: 'HK Grotesk', mb: 3, height: '35px', mt: 0 }}
            onClick={async () => {
              await postAuditLogs('yes')
              sendGALog('exit_yes')
              setCheckWarningClick(true)
            }}
          >
            Yes
          </Button>
          <Button
            className="button-tr-citizen-admin"
            sx={{
              fontFamily: 'HK Grotesk',
              mb: 3,
              width: '140px',
              height: '35px',
              fontSize: '13px!important',
              mt: 0,
            }}
            onClick={async () => {
              await postAuditLogs('no')
              handleCloseWarningDialog()
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
