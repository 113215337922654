import { Card, CardContent, Box, Typography, Button } from '@mui/material'
import BorderColorIcon from '../../../asserts/images/Create.svg'
import './CreateReportButton.css'

function CreateReportButton({ handleClickOpen, postAuditLogs, tenure }) {
  return (
    <>
      <Card
        className="bshadow-1"
        sx={{
          display: 'inline-block',
          marginLeft: '38px',
          marginTop: '30px',
          borderRadius: '24px',
          background: '#f5eeed',
        }}
      >
        <CardContent>
          <Box className="d-flex">
            <Box>
              <Typography
                component="div"
                sx={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  fontFamily: 'HK Grotesk ExtraBold !important',
                  color: '#505050',
                  marginLeft: '15px',
                }}
              >
                Create an initiative report
              </Typography>

              <Typography
                variant="subtitle1"
                color="text.secondary"
                component="div"
                sx={{
                  fontSize: '20px',
                  fontFamily: 'HK Grotesk',
                  color: '#505050',
                  marginLeft: '15px',
                }}
              >
                Have you taken part in this initiative? Add the details here
              </Typography>
            </Box>
            <Box className="create-init-report-box">
              <Button
                disabled={tenure === 'Modi 2.0'}
                className={`button-tr1-admin ${tenure === 'Modi 2.0' ? 'disable-creat-btn' : ''} `}
                sx={{
                  fontFamily: 'HK Grotesk ExtraBold !important',
                  textTransform: 'none !important',
                  fontWeight: 'bolder !important',
                  fontSize: '20px !important',
                  padding: '8px 20px',
                  width: '242px',
                }}
                onClick={() => (handleClickOpen(), postAuditLogs('create_report'))}
              >
                Create Now
                <img src={BorderColorIcon} width="20px" className="ml-2" />
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </>
  )
}
export default CreateReportButton
