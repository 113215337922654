import { CircularProgress, Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllHighlights } from '../../store/action/globalHighlights.js'
import { useLoading } from '../../utils/LoadingContext.js'
import { useNotificationContext } from '../../utils/NotificationContext.js'
import SideMenu from '../SideMenu/SideMenu.js'
import { convertNumberIntoLacsCr } from '../ReusableComponents.js/reuseMethods.js'
import './Highlights.css'

const TableHighlights = (props) => {
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const dispatch = useDispatch()
  const { data, loading } = useSelector((state) => state?.highlights)
  const [dataLoading, setDataLoading] = useState(loading)
  const [rows, setRows] = useState([])
  function createData(heading, value) {
    return { heading, value }
  }

  const keys = {
    InitiativeReports: 'Initiative Reports',
    InitiativesUndertaken: 'Initiatives Undertaken',
    EventsConducted: 'Events Conducted',
    memberadded: 'Members Added',
    twtterfollowers: 'Twitter Followers',
    // twittermention: 'Twitter Mentions',
    twttertweet: 'Twitter Retweets',
    DevelopmentProjects: 'Development Projects',
    DonationReceived: 'Donations Raised',
    MediaCoverage: 'Media Coverage',
    Op_Eds: 'Op-Eds',
    BookPublished: 'Books Published',
  }

  const fetchData = async () => {
    try {
      const response =
        props.mpId > 0
          ? await dispatch(getAllHighlights(props.mpId, props.dataFilter))
          : await dispatch(getAllHighlights(0, props.dataFilter))
      if (response.status !== 200) {
        showNotification('Error', response?.data?.message, 'error')
      }
    } catch (error) {
      showNotification('Error', error, 'error')
    } finally {
      // setLoading(false);
      setDataLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [props.dataFilter])

  useEffect(() => {
    const rowsData = Object.keys(data ? data : [])?.map((val) => {
      return createData(val, data[val])
    })
    rowsData.splice(10, 1)
    setRows(rowsData)
  }, [data])

  const abbreviateNumber = (value) => {
    var newValue = value
    if (value >= 1000) {
      var suffixes = ['', 'K', 'M', 'B', 'T']
      var suffixNum = Math.floor(('' + value).length / 3)
      var shortValue = ''
      for (var precision = 2; precision >= 1; precision--) {
        shortValue = parseFloat((suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision))
        var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '')
        if (dotLessShortValue.length <= 2) {
          break
        }
      }
      if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1)
      newValue = shortValue + suffixes[suffixNum]
    }
    return newValue
  }

  return (
    <Box className="mt-3 tableHighlightText">
      {dataLoading ? (
        <CircularProgress />
      ) : (
        rows &&
        rows?.map((row, index) => {
          const isLastElement = index === rows.length - 1
          const tableWrapClass = isLastElement
            ? 'd-flex justify-content-between py-3 tableWrap'
            : 'd-flex justify-content-between border-bottom py-3 tableWrap'

          return (
            <Box className={tableWrapClass} key={row.heading}>
              <Box className="tableHighlightTitle">{keys[row.heading]}</Box>
              <Box className="seva-nos tableHighlightItem">
                {row.heading === 'DonationReceived'
                  ? '₹ ' + convertNumberIntoLacsCr(row?.value)
                  : convertNumberIntoLacsCr(row?.value)}
              </Box>
            </Box>
          )
        })
      )}
    </Box>
  )
}

export default TableHighlights
