import { FETCH_RANKS_COUNT_REQUEST, FETCH_RANKS_COUNT_SUCCESS, FETCH_RANKS_COUNT_FAILURE } from '../action/types';

const initialState = {
  data: [],
  error: '',
};

export const RanksCountReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RANKS_COUNT_REQUEST:
      return {
        ...state,
      };
    case FETCH_RANKS_COUNT_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_RANKS_COUNT_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
