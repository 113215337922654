import React, { useEffect, useRef, useState, createRef, useMemo } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import CrossIcon from '../../../../asserts/images/Close.svg'
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  DialogActions,
  ImageListItem,
  Typography,
  Divider,
  CardMedia,
  styled,
  Tooltip,
  tooltipClasses,
  Avatar,
} from '@mui/material'
import { Box, getValue } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import UploadIcon from '@mui/icons-material/Upload'
import UploadImages from './UploadImageDialog'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import closeIconp from '../../../../asserts/images/close-1.png'
import unknownImg from '../../../../asserts/images/folders.png'
import zipIcon from '../../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../../asserts/images/FileIcon.svg'
import downloadIcon from '../../../../asserts/images/downloadIcon.svg'
import deleteIcon from '../../../../asserts/images/bin.png'
import infoic from '../../../../asserts/images/Info.svg'
import { useNotificationContext } from '../../../../utils/NotificationContext'
import { handleFileName, handleSrc, iconComponent } from '../../../ReusableComponents.js/reuseMethods'
import './ImageTemplate.css'
import { useSelector } from 'react-redux'
import { makeLogRequest } from '../../../../utils/AuditLogs'
import { pushTag } from '../../../../utils/gtmservice'
import { ImageLoader } from '../../../ReusableComponents.js/ImageLoader'
import { ImageLoader2 } from '../../../ReusableComponents.js/ImageLoader2'

const AddImageTemplates = ({
  handleCloseImageTemplateDialog,
  openImageTemplateDialog,
  setImageData,
  languages,
  editDetails,
  imageData,
  setUpdateValue,
  createNewClick,
}) => {
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
    reset,
  } = useForm()
  const zipFormat = ['application/x-zip-compressed', 'application/x-zip', 'zip']
  const aiFormat = ['application/postscript', 'application/illustrator', 'ai']
  const fileFormats = [
    'image/jpeg',
    'image/png',
    'image/tiff',
    'image/jpe',
    'image/jif',
    'image/jfif',
    'image/jfi',
    'image/vnd.adobe.photoshop',
    'application/x-photoshop',
    'application/postscript',
    'application/zip',
    'application/illustrator',
    'image/raw',
    'image/jpg',
    'image/webp',
    'image/svg',
    'image/svg+xml',
    'application/zip',
    '.heif',
    '.indd',
    '.psd',
    '.eps',
    '.svgz',
    '.webp',
    '.zip',
    '.png',
    '.jpeg',
    'jpg',
    '.jif',
    '.jfif',
    '.jfi',
    '.rar',
    '.7z',
    '.gz',
    'application/x-zip-compressed',
  ]
  const { showNotification } = useNotificationContext()
  const [checkWarningPopup, setCheckWarningPopup] = useState(false)
  const [selectedBanner, setSelectedBanner] = useState([])
  const userProfile = useSelector((state) => state?.userProfile?.data)

  const [dialogState, setDialogState] = useState({
    open: false,
    for: undefined,
  })
  const uploadImageRefMain = useRef()
  const [fileRefs, setFileRefs] = useState([])
  const [images, setImages] = useState([])
  const [files, setFiles] = useState([])
  const [currentImageIndex, setCurrentImageIndex] = useState(-1)
  const [initialId, setInitialId] = useState(-1)
  const [inputState, setInputState] = useState([
    {
      id: Date.now(),
      inputType: true,
      bannerSize: false,
      language: true,
      upload: true,
      remove: true,
    },
  ])
  const bannerSize = [
    { id: 1, value: '1:1' },
    { id: 2, value: '16:9' },
    { id: 3, value: '3:6' },
  ]
  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('add_image_template_popup', sqId, data)
  }

  useEffect(() => {
    setFileRefs(inputState.map(() => createRef()))
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'add_image_template_pop_up',
    })
  }, [])

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'add_image_template_pop_up',
      message: message,
    })
  }

  const fetchEditDetailsData = () => {
    if (editDetails) {
      const temp = []
      editDetails?.forEach((template, i) => {
        temp.push({
          id: i,
          inputType: true,
          inputTypeValue: template.typeof_image,
          bannerSize: template.typeof_image === 'Outdoor Banner',
          bannerSizeValue: template.banner_size !== null ? template.banner_size.trim() : '',
          language: true,
          languageValue: template.imageLanguage,
          upload: true,
          remove: true,
          imagesData: typeof template.url === 'string' ? JSON.parse(template.url) : template.url,
        })
        !getValues(`${i}`)?.length > 0 &&
          setValue(`${i}.images`, typeof template.url === 'string' ? JSON.parse(template.url) : template.url)
        !getValues(`${i}`)?.length > 0 && setValue(`${i}.imageType`, template.typeof_image)
        !getValues(`${i}`)?.length > 0 && setValue(`${i}.language`, template.imageLanguage)
      })
      setInputState(temp)
      setFileRefs(temp.map(() => createRef()))
    }
  }

  useEffect(() => {
    fetchEditDetailsData()
  }, [editDetails])

  const imagesTypes = [
    { id: 1, value: 'Certificate' },
    { id: 2, value: 'Social Media' },
    { id: 3, value: 'Outdoor Banner' },
  ]

  const removeInput = (id) => {
    const tempData = [...inputState]
    const temp2 = [...selectedBanner]
    const newBanner = temp2.filter((input) => input?.id !== id)
    const newData = tempData.filter((input) => input.id !== id)
    const index = inputState.findIndex((data) => data.id === id)
    const newRefs = fileRefs.filter((_, i) => tempData[i].id !== id)
    unregister(index.toString())
    setInputState(newData)
    setSelectedBanner(newBanner)
    setFileRefs(newRefs)
    const newFormData = {}
    const formData = getValues()
    const formDataValues = Object.values(formData)
    formDataValues.forEach((item, ind) => {
      newFormData[ind] = item
    })
    reset(newFormData)
  }
  const BootstrapTooltipInfo = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #356F92',
      },
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: '1px solid #356F92',
      boxShadow: theme.shadows[1],
      width: 240,
      maxWidth: '370px',
    },
  }))
  const addInput = () => {
    const newInput = {
      id: inputState[inputState.length - 1].id + 1,
      inputType: true,
      bannerSize: false,
      language: true,
      upload: true,
      remove: true,
    }
    setInputState((prevState) => [...prevState, newInput])
    setFileRefs((prevRefs) => [...prevRefs, createRef()])
  }

  const showBannerField = (e, input) => {
    const tempData = [...inputState]
    const index = tempData.findIndex((data) => data.id === input.id)
    if (e.target.value === 'Outdoor Banner') {
      tempData[index].bannerSize = true
    } else {
      tempData[index].bannerSize = false
    }
    setInputState(tempData)
  }

  const isUploadImageEnabledOrNot = (i) => {
    const typeOfImage = getValues(`${i}.imageType`)
    const typeOfBanner = getValues(`${i}.bannerSize`)
    const language = getValues(`${i}.language`)
    let isDisabled = false
    if (typeOfImage === 'Outdoor Banner') {
      isDisabled = Boolean(typeOfImage && typeOfBanner && language)
    } else {
      isDisabled = Boolean(typeOfImage && language)
    }
    return isDisabled
  }

  const handleClick = async (e, i) => {
    setImages([])
    // uploadImageRefMain.current?.click();
    if (isUploadImageEnabledOrNot(i)) {
      fileRefs[i].current?.click()
      await postAuditLogs('browse', { image_type: getValues(`${i}.imageType`) })
    }
    e?.stopPropagation()
  }
  const handleDelete = (i) => {
    // setInitialId(i)
    // if(images.length<1){
    //     setImages([])
    // }
    // else{
    setCheckWarningPopup(true)
    const tempImages = [...images]
    tempImages.splice(0, 1)
    setImages(tempImages)
    if (inputState.length > 0) {
      let updatedInputState = [...inputState] // create a copy of inputState
      updatedInputState[i] = {
        // update the object at index i
        ...updatedInputState[i], // spread the current object's properties
        imagesData: inputState[i].imagesData.slice(1), // create a new array without the first element
      }
      setInputState(updatedInputState)
      let val = getValues(`${i}`)

      delete val.images[0]
      val.images.splice(0, 1)
      setValue(`${i}.images`, val.images)
    } else {
      let val = getValues(`${i}`)

      delete val.images[0]
      val.images.splice(0, 1)
      setValue(`${i}.images`, val.images)
    }
  }

  const imageValidation = (file) => {
    const isRightFormat = fileFormats.includes(file.type) || fileFormats.includes('.' + file.name?.split('.').pop())
    const fileSizeInBytes = file.size
    const maxSizeInBytes = 20 * 1024 * 1024 // 20MB
    if (fileSizeInBytes > maxSizeInBytes) {
      showNotification('Error', 'Please upload image with size less than 20MB', 'error')
      return false
    }
    if (!isRightFormat) {
      showNotification('Error', 'You can only upload jpg, jpeg and png images', 'error')
      return false
    }
    return true
  }

  const processFile = async (file) => {
    const reader = new FileReader()
    const readerPromise = new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = () => {
        resolve({
          type: zipFormat.includes(file.type) ? 'zip' : aiFormat.includes(file.type) ? 'ai' : 'image',
          url: zipFormat.includes(file.type) ? zipIcon : aiFormat.includes(file.type) ? fileIcon : reader.result,
          file: file,
        })
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })

    try {
      const result = await readerPromise // Wait for the FileReader to complete
      return result
    } catch (error) {
      console.error('Error reading file:', error)
      throw error
    }
  }

  const handleImageChange = async (e, i) => {
    const uploadedFiles = Array.from(e.target.files)
    if (uploadedFiles.length > 0) {
      setCheckWarningPopup(true)
    }
    let remainingZipCount = 5 - countExistingZips()
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i]
      if (!imageValidation(file)) return
      if (file.type === 'application/x-zip-compressed' && remainingZipCount > 0) {
        remainingZipCount--
      } else if (file.type === 'application/x-zip-compressed' && remainingZipCount <= 0) {
        // Reject the upload and display an error message
        showNotification('Error', 'Only a maximum of 5 zip files are allowed in total.', 'error')
        return
      }
    }
    const processingPromises = uploadedFiles.map((file) => processFile(file))
    let newImages = []

    await Promise.all(processingPromises)
      .then((newFiles) => {
        setFiles((prevFiles) => [...prevFiles, ...newFiles])
        newImages = newFiles
      })
      .catch((error) => {
        console.error('Error processing files:', error)
      })
    setImages((prevImages) => [...prevImages, ...newImages])
    handleOpenUploadDialog(`${currentImageIndex}`)
  }

  const countExistingZips = () => {
    let zipCount = 0
    images.forEach((file) => {
      if (file.type === 'zip') {
        zipCount++
      }
    })
    return zipCount
  }

  const handleOpenUploadDialog = (id, edit = false, images = []) => {
    if (!edit) {
      // setImages(getValues(id))
      setDialogState({ open: true, for: id })
    } else {
      setImages(images)
      setDialogState({ open: true, images, for: id })
    }
  }

  const handleCloseUploadDialog = (changesMade = false) => {
    setDialogState({ open: false, for: undefined })
    if (!changesMade) {
      fetchEditDetailsData()
    }
  }

  const onSubmit = async (data) => {
    const objectArray = Object.values(data)
    let fileTypes = []
    for (let i = 0; i < objectArray.length; i++) {
      let currentImages = objectArray[i].images
      for (let j = 0; j < currentImages.length; j++) {
        if (currentImages[j].file && !fileTypes.includes(currentImages[j].file?.type)) {
          fileTypes.push(currentImages[j].file.type)
        }
      }
    }
    await postAuditLogs(editDetails?.length > 0 ? 'update' : 'add_image_template', { file_type: fileTypes.join('~') })
    // setImageData(objectArray)
    setImageData([])
    setImageData((prevData) => [
      ...prevData,
      prevData.length > 0 ? objectArray?.map((val) => prevData[0]?.push(val)) : objectArray,
    ])
    setUpdateValue(true)
    handleCloseImageTemplateDialog()
  }

  return (
    <Dialog
      onClose={() => handleCloseImageTemplateDialog(checkWarningPopup)}
      // onClose={() => handleCloseImageTemplateDialog()}
      open={openImageTemplateDialog}
      sx={{ height: '90vh', marginTop: '25px' }}
    >
      <DialogTitle
        sx={{
          fontFamily: 'HK Grotesk',
          color: '#2e739c',
          fontWeight: '700',
          textAlign: 'center',
          fontSize: '26px',
        }}
      >
        {editDetails?.length > 0 ? 'Update' : 'Add'} Image Templates
      </DialogTitle>
      <img
        onClick={async () => {
          await postAuditLogs('close')
          sendGALog('close')
          handleCloseImageTemplateDialog(checkWarningPopup, true)
        }}
        src={CrossIcon}
        className="closeIcon"
      />
      <Box sx={{ m: 3, overflowX: 'hidden' }}>
        <form>
          {inputState?.map((input, i) => {
            return (
              <Box className="box" key={input.id}>
                <Grid container>
                  <Grid item xs={6} md={6} lg={6} xl={6}>
                    {input.inputType && (
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={12} lg={12} xl={12} sx={{ width: '280px' }}>
                          <Typography variant="body-2" className="type-b">
                            Type of Images
                          </Typography>
                          <FormControl
                            size="small"
                            fullWidth
                            sx={{
                              '& .MuiInputBase-input': {
                                borderRadius: '14px!important',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '14px',
                              },
                            }}
                          >
                            {/* <InputLabel id="demo-select-small" ></InputLabel> */}
                            <Select
                              labelId="demo-select-small"
                              id="demo-select-small"
                              IconComponent={iconComponent}
                              // label="Type of Images"
                              // required
                              {...register(`${i}.imageType`, {
                                required: 'Please select the Type of images',
                              })}
                              onChange={(e) => {
                                showBannerField(e, input)
                                setCheckWarningPopup(true)
                                setError(`${i}.imageType`, null)
                              }}
                              defaultValue={input?.inputTypeValue}
                            >
                              {imagesTypes &&
                                imagesTypes.map((s) => {
                                  return (
                                    <MenuItem
                                      native="true"
                                      key={s.value}
                                      sx={{ width: '100%' }}
                                      value={s.value}
                                      size="small"
                                    >
                                      {s.value}
                                    </MenuItem>
                                  )
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#D93025' }} class="p-error">
                              {errors && errors[i] && errors[i].imageType?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                    {input.bannerSize && (
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                          <FormControl
                            size="small"
                            fullWidth
                            sx={{
                              '& .MuiInputBase-input': {
                                borderRadius: '14px!important',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '14px',
                              },
                            }}
                          >
                            <Typography variant="body-2" className="type-b">
                              Banner Size
                            </Typography>
                            <Select
                              labelId="demo-select-small"
                              // label="Banner Size"
                              IconComponent={iconComponent}
                              required
                              {...register(`${i}.bannerSize`, {
                                required: 'Please select a Banner Size',
                              })}
                              defaultValue={input?.bannerSizeValue}
                              onChange={(e) => {
                                let updatedBanner = [...selectedBanner]
                                updatedBanner[i] = {
                                  ...selectedBanner[i],
                                  id: input.id,
                                  bannerSize: e.target.value,
                                }
                                setSelectedBanner(updatedBanner)
                                setCheckWarningPopup(true)
                                setError(`${i}.bannerSize`, null)
                              }}
                            >
                              {bannerSize &&
                                bannerSize.map((s) => {
                                  return (
                                    <MenuItem
                                      native="true"
                                      key={s.value}
                                      sx={{ width: '100%' }}
                                      value={s.value}
                                      size="small"
                                    >
                                      {s.value}
                                    </MenuItem>
                                  )
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#D93025' }} class="p-error">
                              {errors && errors[i] && errors[i].bannerSize?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                    {input.language && (
                      <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={12} md={12} lg={12} xl={12}>
                          <Typography variant="body-2" className="type-b">
                            Language
                          </Typography>
                          <FormControl
                            size="small"
                            fullWidth
                            sx={{
                              '& .MuiInputBase-input': {
                                borderRadius: '14px!important',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '14px',
                              },
                            }}
                          >
                            {/* <InputLabel id="demo-select-small">Language</InputLabel> */}
                            <Select
                              labelId="demo-select-small"
                              id="demo-select-small"
                              IconComponent={iconComponent}
                              // label="Langauge"
                              required
                              {...register(`${i}.language`, {
                                required: `Please select Language of the ${input.bannerSize ? 'banner' : 'Images'}`,
                              })}
                              defaultValue={input?.languageValue}
                              onChange={() => {
                                setError(`${i}.language`, null)
                                setCheckWarningPopup(true)
                              }}
                            >
                              {languages &&
                                languages.map((s) => {
                                  return (
                                    <MenuItem native key={s.id} sx={{ width: '100%' }} value={s.language} size="small">
                                      {s.language}
                                    </MenuItem>
                                  )
                                })}
                            </Select>
                            <FormHelperText sx={{ color: '#D93025' }} class="p-error">
                              {errors && errors[i] && errors[i].language?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}

                    {input.remove && inputState.length !== 1 && (
                      <Grid item xs={3} sx={{ mt: 1, marginLeft: '-15px', filter: 'grayscale(900%) brightness(52%)' }}>
                        <Button
                          variant="outlined"
                          sx={{ borderRadius: 4 }}
                          className="button-tr-citizen-admin-dl"
                          onClick={() => {
                            removeInput(input.id)
                            setCheckWarningPopup(true)
                          }}
                        >
                          <Box className="d-flex align-items-center w-100 mr-1">
                            <CardMedia
                              component="img"
                              image={deleteIcon}
                              className="addimagetemplate__delete-row-btn-media"
                            />
                            <Typography variant="span">Delete Row</Typography>
                          </Box>
                        </Button>
                        {/* } */}
                      </Grid>
                    )}
                  </Grid>
                  {!input?.imagesData?.[0] ? (
                    <Grid item xs={6} md={6} lg={6} xl={6} sx={{ marginTop: '45px' }}>
                      <Box></Box>
                      {input.upload && (
                        <Grid
                          item
                          sx={{
                            pr: 1,
                            pl: 1,
                            margin: '0 auto',
                            border: 'dotted 3px #1976d2',
                            bgcolor: '#E0F6FF',
                            padding: '50px',
                            width: '75%',
                            borderRadius: '14px',
                            aspectRatio: '7 / 4',
                          }}
                        >
                          {' '}
                          <Box className="title-n-tooltip d-flex justify-content-center">
                            <Box className="upload-text upload-file-text">UPLOAD A FILE</Box>
                            <BootstrapTooltipInfo
                              title="Accepted file formats: .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .tiff, .psd, .eps, .ai, .indd, .raw, .jfif, .webp, .svg, .svgz, .heif"
                              placement="left"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    fontSize: '18px',
                                    fontFamily: 'HK Grotesk',
                                    padding: '15px',
                                  },
                                },
                              }}
                            >
                              <img src={infoic} className="infoic-tooltip" />
                              {/* <ErrorOutlineIcon sx={{ ml: 2, color: "#387194" }} /> */}
                            </BootstrapTooltipInfo>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              '& > :not(style)': {
                                width: 30,
                                height: 30,
                              },
                            }}
                          >
                            <Box>
                              <IconButton
                                color="primary"
                                aria-label="Upload"
                                className="upload-icon"
                                onClick={
                                  async (e) => {
                                    await handleClick(e, i)
                                    setCurrentImageIndex(i)
                                  }
                                  // handleOpenUploadDialog(`${i}.images`)
                                }
                                // sx={{ml: selectedBanner[i]?.bannerSize==="16:9" &&  "18px"}}
                              >
                                <img src={downloadIcon} width={40} />
                              </IconButton>
                              <br />
                              <input
                                type="file"
                                multiple
                                {...register(`${i}.images`, {
                                  required: 'Please add Images',
                                })}
                                accept={fileFormats.join(', ')}
                                ref={fileRefs[i]}
                                class="d-none"
                                onChange={(e) => {
                                  handleImageChange(e, i)
                                  setError(`${i}.images`, null)
                                }}
                              />
                              <Box className="upload-support">Supported File: Images,Open File, Zip File.</Box>
                              <Box
                                className="upload-browse cursorshow"
                                onClick={
                                  async (e) => {
                                    await handleClick(e, i)
                                    setCurrentImageIndex(i)
                                  }
                                  // handleOpenUploadDialog(`${i}.images`)
                                }
                              >
                                {/* Add Images */}
                                Browse
                              </Box>
                            </Box>
                            {/* {
                                                    getValues(`${i}.images`)?.length > 0 && (
                                                        <div>
                                                            <img src={getValues(`${i}.images`)[0].url} onClick={() => handleOpenUploadDialog(`${i}.images`)} alt=""
                                                                style={{ width: 210, height: 144, position: "relative", top: "45%", left: "0", transform: "translate(-50%, -50%)" }} />
                                                            <img src={closeIconp} id={i} onClick={() => handleDelete(i)} className="imageclose-imge" />
                                                            {
                                                                getValues(`${i}.images`)?.length > 1 ? <Link style={{ whiteSpace: 'nowrap', marginLeft: "-50px", position: "relative", top: "-240%" }} onClick={() => handleOpenUploadDialog(`${i}.images`)}>+{getValues(`${i}.images`).length - 1} More {getValues(`${i}.images`).length > 2 ? "Images" : "Image"} </Link> :
                                                                    <Link style={{ whiteSpace: 'nowrap', marginLeft: "-50px", position: "relative", top: "-240%" }} onClick={() => handleOpenUploadDialog(`${i}.images`)}>Add More Images </Link>
                                                            }
                                                        </div>
                                                    )
                                                } */}
                            {/* {input?.imagesData?.length > 0 && (
                            <div>
                              <img
                                src={
                                  input?.imagesData[0].url
                                    ? input?.imagesData[0].url
                                    : input?.imagesData[0]
                                }
                                onClick={() =>
                                  handleOpenUploadDialog(
                                    i,
                                    true,
                                    input?.imagesData
                                  )
                                }
                                alt=""
                                style={{
                                  width:  getValues(`${i}`)?.bannerSize==="16:9"? "250px" : getValues(`${i}`)?.bannerSize==="3:6"  ? "135px" :"210px",
                                  aspectRatio: getValues(`${i}`)?.bannerSize==="16:9"? "16 / 9" : getValues(`${i}`)?.bannerSize==="3:6" ?  "3 / 6" : "0",
                                  height: (!getValues(`${i}`)?.bannerSize || getValues(`${i}`)?.bannerSize==="1:1") && 144,
                                  position: "relative",
                                  top: getValues(`${i}`)?.bannerSize==="3:6"? "89px" : "45%",
                                  left: getValues(`${i}`)?.bannerSize==="3:6"? "-12px" :"0",
                                  transform: "translate(-50%, -50%)",
                                }}
                              />
                              <img
                                src={closeIconp}
                                id={i}
                                onClick={() => handleDelete(i)}
                                className= {getValues(`${i}`)?.bannerSize==="3:6"? "imageclose-imge-36":getValues(`${i}`)?.bannerSize==="16:9"?"imageclose-imge-169":"imageclose-imge"}
                              />
                              {input?.imagesData?.length > 1 ? (
                                <Link
                                  style={{
                                    whiteSpace: "nowrap",
                                    marginLeft: "-50px",
                                    position: "relative",
                                    top: getValues(`${i}`)?.bannerSize==="3:6"? "-200%" : "-240%",
                                    left: getValues(`${i}`)?.bannerSize==="3:6" && "-80%"
                                  }}
                                  onClick={() =>
                                    handleOpenUploadDialog(
                                      i,
                                      true,
                                      input?.imagesData
                                    )
                                  }
                                >
                                  +{input?.imagesData?.length - 1} More{" "}
                                  {input?.imagesData?.length > 2
                                    ? "Files"
                                    : "File"}{" "}
                                </Link>
                              ) : (
                                <Link
                                  style={{
                                    whiteSpace: "nowrap",
                                    marginLeft: "-50px",
                                    position: "relative",
                                    top: getValues(`${i}`)?.bannerSize==="3:6"? "-200%" : "-240%",
                                    left: getValues(`${i}`)?.bannerSize==="3:6" && "-80%"
                                  }}
                                  onClick={() =>
                                    handleOpenUploadDialog(
                                      i,
                                      true,
                                      input?.imagesData
                                    )
                                  }
                                >
                                  Add More Files{" "}
                                </Link>
                              )}
                            </div>
                          )} */}
                          </Box>
                          {/* <b style={{ fontFamily: 'HK Grotesk',color:"#505050",fontSize:"15px",position:"relative",left:"5%"}}>Add Images</b> */}
                        </Grid>
                      )}
                      {errors[i] && (
                        <FormHelperText class="p-error center ml-0">
                          {errors && errors[i] && errors[i].images?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={6} md={6} lg={6} xl={6} sx={{ marginTop: '45px' }}>
                      <Box className="preview-img">Image Preview</Box>
                      {input.upload && (
                        <Grid
                          item
                          sx={{
                            pr: 1,
                            pl: 1,
                            margin: '0 auto',
                            border: '3px #1976d2',
                            padding: '50px',
                            width: '40%',
                            borderRadius: '14px',
                            aspectRatio:
                              getValues(`${i}`)?.bannerSize === '16:9'
                                ? '16 / 9'
                                : getValues(`${i}`)?.bannerSize === '3:6'
                                ? '3 / 6'
                                : '0',
                            top: getValues(`${i}`)?.bannerSize === '3:6' ? '89px' : '45%',
                            left: getValues(`${i}`)?.bannerSize === '3:6' ? '-12px' : '0',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              '& > :not(style)': {
                                width: 30,
                                height: 30,
                              },
                            }}
                          >
                            <Box>
                              <IconButton
                                color="primary"
                                aria-label="Upload"
                                onClick={
                                  (e) => {
                                    handleClick(e, i)
                                    setCurrentImageIndex(i)
                                  }
                                  // handleOpenUploadDialog(`${i}.images`)
                                }
                                sx={{ ml: selectedBanner[i]?.bannerSize === '16:9' && '18px' }}
                              >
                                <UploadIcon />
                              </IconButton>
                              <br />
                              <input
                                type="file"
                                multiple
                                {...register(`${i}.images`, {
                                  required: 'Please add Images',
                                })}
                                accept={fileFormats.join(', ')}
                                ref={fileRefs[i]}
                                class="d-none"
                                onChange={(e) => {
                                  handleImageChange(e, i)
                                  setError(`${i}.images`, null)
                                }}
                              />
                              <Box
                                className={`add-img-st ${
                                  selectedBanner[i]?.bannerSize === '16:9' ? 'mg-left-1' : 'mg-left-2'
                                }`}
                              >
                                Add Images
                              </Box>
                            </Box>
                            {/* {
                                                  getValues(`${i}.images`)?.length > 0 && (
                                                      <div>
                                                          <img src={getValues(`${i}.images`)[0].url} onClick={() => handleOpenUploadDialog(`${i}.images`)} alt=""
                                                              style={{ width: 210, height: 144, position: "relative", top: "45%", left: "0", transform: "translate(-50%, -50%)" }} />
                                                          <img src={closeIconp} id={i} onClick={() => handleDelete(i)} className="imageclose-imge" />
                                                          {
                                                              getValues(`${i}.images`)?.length > 1 ? <Link style={{ whiteSpace: 'nowrap', marginLeft: "-50px", position: "relative", top: "-240%" }} onClick={() => handleOpenUploadDialog(`${i}.images`)}>+{getValues(`${i}.images`).length - 1} More {getValues(`${i}.images`).length > 2 ? "Images" : "Image"} </Link> :
                                                                  <Link style={{ whiteSpace: 'nowrap', marginLeft: "-50px", position: "relative", top: "-240%" }} onClick={() => handleOpenUploadDialog(`${i}.images`)}>Add More Images </Link>
                                                          }
                                                      </div>
                                                  )
                                              } */}
                            {input?.imagesData?.length > 0 && (
                              <Box>
                                {['zip', 'ai'].includes(handleSrc(input?.imagesData[0])) ? (
                                  <ImageListItem
                                    key={i}
                                    className="bgfolder cursorshow"
                                    sx={{
                                      margin: '0px 0px -67px',
                                      right: '107px',
                                      bottom: '110px',
                                      width: '200px !important',
                                      padding: '38px 33px 7px',
                                      aspectRatio: '1 / 0.9 !important',
                                    }}
                                  >
                                    <img
                                      class="m-0"
                                      key={i}
                                      src={
                                        handleSrc(input?.imagesData[0]) === 'zip'
                                          ? zipIcon
                                          : handleSrc(input?.imagesData[0]) == 'ai' && fileIcon
                                      } //?w=164&h=164&fit=crop&auto=format
                                      srcSet={
                                        handleSrc(input?.imagesData[0]) === 'zip'
                                          ? zipIcon
                                          : handleSrc(input?.imagesData[0]) == 'ai' && fileIcon && fileIcon
                                      } //?w=164&h=164&fit=crop&auto=format
                                      // alt={item.title}
                                      onClick={() => handleOpenUploadDialog(i, true, input?.imagesData)}
                                    />
                                    <Typography variant="subtitle1" sx={subtitleStyles}>
                                      {handleFileName(input?.imagesData[0])}
                                    </Typography>
                                  </ImageListItem>
                                ) : (
                                  <ImageLoader2
                                    key={i}
                                    src={handleSrc(input?.imagesData[0])}
                                    srcSet={handleSrc(input?.imagesData[0])}
                                    onClick={() => handleOpenUploadDialog(i, true, input?.imagesData)}
                                    defaultImg={unknownImg}
                                    subtitleStyles={subtitleStyles}
                                    item={handleFileName(input?.imagesData[0])}
                                    sx={{
                                      width:
                                        selectedBanner[i]?.bannerSize === '16:9'
                                          ? '250px'
                                          : selectedBanner[i]?.bannerSize === '3:6'
                                          ? '135px'
                                          : '210px',
                                      aspectRatio:
                                        selectedBanner[i]?.bannerSize === '16:9'
                                          ? '16 / 9'
                                          : selectedBanner[i]?.bannerSize === '3:6'
                                          ? '3 / 6'
                                          : '0',
                                      height:
                                        (!selectedBanner[i]?.bannerSize || selectedBanner[i]?.bannerSize === '1:1') &&
                                        144,
                                      position: 'relative',
                                      top: selectedBanner[i]?.bannerSize === '3:6' ? '89px' : '45%',
                                      left: selectedBanner[i]?.bannerSize === '3:6' ? '-12px' : '0',
                                      transform: 'translate(-50%, -50%)',
                                    }}
                                  />
                                )}

                                <img
                                  src={closeIconp}
                                  id={i}
                                  onClick={() => handleDelete(i)}
                                  className={`
                                    ${
                                      selectedBanner[i]?.bannerSize === '3:6'
                                        ? 'imageclose-imge-36'
                                        : selectedBanner[i]?.bannerSize === '16:9'
                                        ? 'imageclose-imge-169'
                                        : 'imageclose-imge'
                                    }
                                      ${['zip', 'ai'].includes(handleSrc(input?.imagesData[0])) && 'zip-mg'}
                                     `}
                                />
                                {input?.imagesData?.length > 1 ? (
                                  <Link
                                    className={`input-link-st ${
                                      selectedBanner[i]?.bannerSize === '3:6' ? 'top1 left-1' : 'top2'
                                    }`}
                                    onClick={() => handleOpenUploadDialog(i, true, input?.imagesData)}
                                  >
                                    +{input?.imagesData?.length - 1} More{' '}
                                    {input?.imagesData?.length > 2 ? 'Files' : 'File'}{' '}
                                  </Link>
                                ) : (
                                  <Link
                                    className={`input-link-st ${
                                      selectedBanner[i]?.bannerSize === '3:6' ? 'top1 left-1' : 'top2'
                                    }`}
                                    onClick={() => handleOpenUploadDialog(i, true, input?.imagesData)}
                                  >
                                    Add More Files{' '}
                                  </Link>
                                )}
                              </Box>
                            )}
                          </Box>
                          {/* <b style={{ fontFamily: 'HK Grotesk',color:"#505050",fontSize:"15px",position:"relative",left:"5%"}}>Add Images</b> */}
                        </Grid>
                      )}
                      {errors[i] && (
                        <FormHelperText class="p-error center ml-0">
                          {errors && errors[i] && errors[i].images?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}
                  <Box className="d-1-st"></Box>
                  <Divider className="hr-last"></Divider>
                </Grid>
              </Box>
            )
          })}
        </form>
        {/* <div style={{ marginLeft: "4px", marginTop: "45px" }}>
          <b>
            Note: Upload banner image in JPG or PNG or JPEG file Format only
          </b>
        </div> */}
        <Box className="btn-box">
          <React.Fragment>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                mb: 2,
                mt: 2,
                marginLeft: '-15px',
              }}
            >
              <Button
                variant="outlined"
                className="button-tr-citizen-admin"
                startIcon={<AddIcon sx={{ mt: '5px' }} />}
                onClick={async () => {
                  await postAuditLogs('add_row')
                  sendGALog('add_row')
                  addInput()
                }}
              >
                Add Row
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </React.Fragment>
        </Box>
      </Box>

      <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
        <Button
          sx={{
            p: 1,
            backgroundColor: '#ef7335',
            borderRadius: 4,
            width: '250px',
          }}
          className="button-tr-2"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          onFocus={() => sendGALog(editDetails?.length > 0 ? 'update' : 'add_image')}
        >
          {editDetails?.length > 0 ? 'Update Image Template' : 'Add Image Template'}
        </Button>
        {/* <Box sx={{ flex: "1 1 auto" }} /> */}
      </DialogActions>
      {images?.length > 0 && (
        <UploadImages
          handleCloseUploadDialog={handleCloseUploadDialog}
          state={dialogState}
          setValue={setValue}
          getValue={getValues}
          setImagesNew={setImages}
          initialImages={dialogState.for === 'edit' ? dialogState.images : images}
          initialId={initialId}
          setInputData={setInputState}
          inputData={inputState}
          fileFormats={fileFormats}
          zipFormat={zipFormat}
          aiFormat={aiFormat}
          setCheckWarningPopup={setCheckWarningPopup}
        />
      )}
    </Dialog>
  )
}

export default AddImageTemplates
