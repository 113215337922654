class Constant {
  static BASE_URL = 'https://mpcorner-dev.narendramodi.in/dev/mp';
  static UAT_URL = 'https://mpcorner-dev.narendramodi.in/uat/mp';
  static idKey = '30221';
  static roleKey = '49221';
}

export const allowedImageFormat = ['image/png', 'image/jpg', 'image/jpeg'];

export const acceptedImageFormat = 'image/png, image/jpg, image/jpeg';

export const defaultErrorMessage = 'Something went wrong! Please try again.';

export const acceptedImageFormatsForInitiativeCover =
  'image/png, image/jpg, image/jpeg, image/svg+xml, image/heif, image/webp, image/tiff, image/vnd.adobe.photoshop, application/vnd.adobe.illustrator, application/x-indesign, image/x-dcraw, image/x-panasonic-raw';

export default Constant;
