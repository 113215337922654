import {
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  DialogActions,
  Button,
  Paper,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import TemplateDialogCloseButton from 'Components/CreateInitiativesRefactored/TemplateDialogCloseButton/TemplateDialogCloseButton.js'
import './Sort.css'
import { useDispatch } from 'react-redux'
import { getDevelopementAcitivites } from 'store/action/pinActivity'
import { useState } from 'react'

const Sort = ({ setOpen, open, mpIdLists, sortBy, setSortBy, setSortByUpdated, setOpenfilter, setSortName }) => {
  const dispatch = useDispatch()
  const [tempSortBy, setTempSortBy] = useState(sortBy)
  const handleClose = () => {
    setSortBy(tempSortBy)
    setOpen(false)
  }

  const handleSortChange = (event) => {
    setSortBy(event.target.value)
    setSortByUpdated(true)
    setOpenfilter(false)
  }

  const handlePost = () => {
    setSortBy(sortBy)
    setOpen(false)
    dispatch(getDevelopementAcitivites(mpIdLists, sortBy))
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        className="paper-prop"
        sx={{
          '& .MuiPaper-root': {
            minHeight: '30vh',
            width: '30vw',
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: 'center !important',
            color: '#2e739c !important',
            fontFamily: 'HK Grotesk bold',
            fontSize: '24px',
          }}
        >
          Sort By
        </DialogTitle>
        <TemplateDialogCloseButton
          onClick={async () => {
            // handleCloseAssingToMPDialog()
            handleClose()
          }}
        />
        <DialogContent sx={{ padding: '22px 29px' }}>
          <RadioGroup column aria-label="sort-by" name="sort-by" value={sortBy} onChange={handleSortChange}>
            <FormControlLabel
              className={`radio-label ${sortBy === 'id' ? 'selected' : ''}`}
              labelPlacement="start"
              value="id"
              control={<Radio checkedIcon={<CheckCircleIcon className="small-icon__color" />} />}
              label={
                <Typography
                  className="sortOption"
                  sx={{
                    color: sortBy === 'interest' ? '#646576' : '#356F92',
                  }}
                >
                  Recent Activity
                </Typography>
              }
            />
            <FormControlLabel
              className={`radio-label ${sortBy === 'interest' ? 'selected' : ''}`}
              labelPlacement="start"
              value="interest"
              control={<Radio checkedIcon={<CheckCircleIcon className="small-icon__color" />} />}
              label={
                <Typography
                  className="sortOption sortOption2"
                  sx={{
                    color: sortBy === 'interest' ? '#356F92' : '#646576',
                  }}
                >
                  Most Liked
                </Typography>
              }
              sx={{ marginBottom: '10px' }}
            />
          </RadioGroup>
          <DialogActions className="Button-div">
            <Button
              variant="contained"
              className="button-tr-2"
              sx={{ backgroundColor: '#ef7335' }}
              onClick={handlePost}
            >
              Done
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  )
}
export default Sort
