import React, { useRef, useEffect } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import ProfilePictureComponent from '../../ReusableComponents.js/ProfilePictureComponent'
import { MPTableprofile } from '../../ReusableComponents.js/ProfilePictureStyles'
import NoRecordFoundComponent from '../../ViewAllScreenRefactored/NoRecordFoundComponent/NoRecordFoundComponent'
import './index.css'

const MasterUserList = ({ setPage, top }) => {
  const masterList = useSelector((state) => state?.masterList?.data)

  const scrollRef = useRef(null)

  const columns = [
    {
      id: 'SrNo',
      label: 'SrNo',
    },
    {
      id: 'uuid',
      label: 'UUID',
    },
    {
      id: 'user_name',
      label: 'UserName',
    },
    {
      id: 'constituency_name',
      label: 'Constituency',
    },
    {
      id: 'state_name',
      label: 'State',
    },
    {
      id: 'house',
      label: 'House',
    },
    {
      id: 'twitter_handle',
      label: 'Twitter Handle',
    },
  ]

  const onScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current

      if (
        Math.floor(scrollTop) + clientHeight == scrollHeight ||
        Math.floor(scrollTop) + clientHeight + 1 == scrollHeight ||
        Math.ceil(scrollTop) + clientHeight == scrollHeight
      ) {
        setPage((value) => value + 1)
      }
    }
  }

  useEffect(() => {
    if (top) {
      scrollRef.current.scrollTop = 0
    }
  }, [top])

  if (!masterList?.length) {
    return (
      <Box className="mt-4 master-nores">
        <NoRecordFoundComponent />
      </Box>
    )
  }

  return (
    <Box className="mt-4 learders-list user-list">
      <TableContainer>
        <Table className="tableheader">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column?.id} id={'header' + column.id} align={column.align} className="tablecellstyle">
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>
        <Box className="customscroll scroll-bg-transparent table-div-style" onScroll={() => onScroll()} ref={scrollRef}>
          <Table className="cursorshow tablebodystyle">
            <TableBody>
              {masterList.map((item, index) => (
                <TableRow>
                  {columns.map((column, key) =>
                    key == 2 ? (
                      <TableCell className="text-wrap" id={column.id}>
                        <Box className="table-name-container">
                          <Box className="d-flex-gap align-items-center">
                            <ProfilePictureComponent
                              ProfilPic={item.user_avatar}
                              sxProfile={MPTableprofile}
                              alt="avatar"
                            />

                            <Typography variant="body1" className="item-user-name text-wrap">
                              {item.user_name}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    ) : (
                      <TableCell className="text-wrap" id={column.id}>
                        {key == 0 ? index + 1 : item[column.id]}
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
    </Box>
  )
}

export default MasterUserList
