import { Box, Typography } from '@mui/material'
import './AdminTwitterHighlightsText.css'
import { getRoles } from '../../ReusableComponents.js/getIds'
import { useEffect, useState } from 'react'

const TwitterHighlightsText = () => {
  const userId = parseInt(getRoles())
  const [readonly, setReadOnly] = useState(false)

  useEffect(() => {
    if (userId === 3) {
      setReadOnly(true)
    }
  }, [userId])

  return (
    <Box className={`highlights-box ${(userId === 2 && !readonly) || userId === 3 ? 'highlights-title' : 'dnon'}`}>
      <Typography variant="h1" className="highlight-text">
        <Typography variant="h1" className="adminTwitterHighlightText">
          MP Twitter Highlights
        </Typography>
      </Typography>
    </Box>
  )
}

export default TwitterHighlightsText
