import { IMAGE_FAILURE, IMAGE_REQUEST, IMAGE_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  error: '',
};

export const getImage = (state = initialState, action) => {
  switch (action.type) {
    case IMAGE_REQUEST:
      return {
        state,
      };
    case IMAGE_SUCCESS:
      return {
        data: action.payload,
      };
    case IMAGE_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
