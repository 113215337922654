import axiosInstance from './api'

export const getFramesFromVideo = async (payload) => {
  try {
    let response = await axiosInstance.post('/api/video/thumbnails', payload)
    return response.data
  } catch (err) {
    console.error(err.message)
  }
}

export const getTrimmedVideo = async (payload) => {
  try {
    let response = await axiosInstance.post('/api/video/trim', payload)
    return response.data
  } catch (err) {
    console.error(err)
  }
}
