import { Card, CardMedia, CardContent, Typography } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import NoImageFound from '../../../asserts/images/noImageFound.jpg'
import { getIntiativesReportByInitiativeIdAndMpId } from '../../../store/action/ongoingSevaInitiativesList'
import { useNotificationContext } from '../../../utils/NotificationContext'

const ViewAllScreenOngoingSevaInitiativesCard = ({
  item,
  setInitiativeName,
  setOpenUpdateInitiativeDialog,
  setOngoingDetails,
  tenure,
}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const { showNotification } = useNotificationContext()

  const mpName = location?.state?.mpName
  const mpId = location?.state?.mpId
  const mySeva = location?.state?.mySeva

  const handleCardClick = (data) => {
    navigate('/SevaUpdates/allInitiativeReports', {
      state: {
        initiativeId: data?.id,
        initiativeName: data?.initiativeName,
        mpName: mpName,
        mpId: mpId,
        mySeva: mySeva,
        tenure: tenure,
        selectedOngoingSevaInitiativeDetails: item,
      },
    })
  }

  const handleOpenInitiativeReport = async (dataVal) => {
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(dataVal?.id, mpId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          showNotification('Error', response.data?.message, 'error')
        } else if (response?.data?.reportdata.length === 0) {
          handleCardClick(dataVal)
        } else {
          setInitiativeName(dataVal?.initiativeName)
          setOpenUpdateInitiativeDialog(true)
          setOngoingDetails(dataVal)
        }
      }
    } catch (error) {
      showNotification('Error', error, 'error')
    }
  }
  return (
    <Card
      sx={{
        minWidth: 200,
        mr: 3,
        mb: 3,
        borderRadius: '14px',
        position: 'relative',
        cursor: 'pointer',
        height: '200px',
      }}
      onClick={() => (mpName && mySeva ? handleOpenInitiativeReport(item) : handleCardClick(item))}
    >
      <CardMedia
        component="img"
        height="150"
        width="190"
        className="cardmedia-dimension image2acrd"
        onError={(e) => (e.target.src = NoImageFound)}
        src={item?.coverimage && JSON.parse(item?.coverimage)[0]}
        alt="new Image"
      />

      <CardContent>
        <Typography
          sx={{
            fontSize: '12px',
            fontWeight: '500',
            fontFamily: 'HK Grotesk',
            color: '#505050',
          }}
          className="ellipsewehe12-qw "
        >
          {item?.initiativeName}
        </Typography>
      </CardContent>
    </Card>
  )
}

export default ViewAllScreenOngoingSevaInitiativesCard
