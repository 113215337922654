import { Typography, Box } from '@mui/material'
import ProfilePictureComponent from '../../ReusableComponents.js/ProfilePictureComponent'
import profileicon from '../../../asserts/images/profile.png'
import { SideMenuProfile } from '../../ReusableComponents.js/ProfilePictureStyles'
import { getRoles } from '../../ReusableComponents.js/getIds'
import getUserProfile from '../../../store/action/getUserProfile'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './SideMenuProfileComponent.css'

const SideMenuProfileComponent = () => {
  const userId = parseInt(getRoles())
  const dispatch = useDispatch()
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const userProfile = useSelector((state) => state?.userProfile?.data)

  useEffect(() => {
    dispatch(getUserProfile())
  }, [])

  return (
    <Box className="d-flex " style={{ gap: '5px' }}>
      <Box className="img-circle circularimage3">
        {userId === 2 || userId === 3 ? (
          <img
            src={profileicon}
            className="img-circle mr-1 profile-img"
            width="48"
            height="48"
            draggable="false"
            alt="profile"
          />
        ) : (
          <ProfilePictureComponent
            ProfilPic={mpProfileData?.user_avatar ? mpProfileData?.user_avatar : profileicon}
            sxProfile={SideMenuProfile}
          />
        )}
      </Box>
      {userId === 2 || userId === 3 ? (
        <Box className="align-self-center pr-2 sidebarLeaderName">{userProfile?.user_name}</Box>
      ) : (
        <>
          <Box className="align-self-center pr-2 sidebarLeaderName ">
            {mpProfileData?.user_name}

            <span className="logininfo-ui">{mpProfileData?.house ? mpProfileData?.house + ',' : ''}</span>
            <Typography component="span" className="logininfo-ui">
              {mpProfileData?.designation ? mpProfileData?.designation + ',' : ''}
            </Typography>
            <Typography component="span" className="logininfo-ui constituencyTitle">
              {' '}
              {mpProfileData?.constituency_name ? mpProfileData?.constituency_name + ',' : ''}
              <br />
              {mpProfileData?.state_name}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  )
}

export default SideMenuProfileComponent
