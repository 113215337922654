import * as React from 'react'
import { useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CrossIcon from '../../asserts/images/Close.svg'
import Typography from '@mui/material/Typography'
import SharingTextBox from './sharingTextBox'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Grid from '@mui/material/Grid'
import { Box, padding } from '@mui/system'
import TextField from '@mui/material/TextField'
import {
  iconComponent,
  iconComponentDown,
} from '../ReusableComponents.js/reuseMethods'
import { getIds, getRoles } from '../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../utils/AuditLogs'
import { useEffect } from 'react'
import { pushTag } from '../../utils/gtmservice'
import { useDispatch, useSelector } from 'react-redux'
import { getLanguageList } from '../../store/action/languageList'
import './sharingText.css'
import { Avatar, Divider } from '@mui/material'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props

  const styles = {
    input: {
      WebkitBoxShadow: '0 0 0 1000px white inset',
    },
  }
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      <Box>{children}</Box>
    </DialogTitle>
  )
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
}

export default function CustomizedDialogs(props) {
  const { onClose, selectedValue, open } = props
  const texttemplates = useSelector(
    (state) => state?.socialMediaKit?.data?.texttemplates || []
  )

  const handleClose = async () => {
    await postAuditLogs('close')
    onClose(selectedValue)
  }

  const dispatch = useDispatch()

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role =
      getRoles() == 2 ? 'admin' : getRoles() == 3 ? 'leadership' : 'mp'
    data.useridtype = getIds()
    await makeLogRequest('sharing_text_popup', sqId, data)
  }

  const [language, setLanguage] = React.useState('All')
  const apiLanguageList = useSelector(
    (state) => state.languageList?.data?.languagedata
  )
  const [languageList, setLanguageList] = useState([{ id: 0, language: 'All' }])

  const [selectArrow, setSelectArrow] = useState(true)
  const handleChange = async (event) => {
    await postAuditLogs('language_filter', {
      language_selected: event.target.value,
    })
    setLanguage(event.target.value)
  }

  const hideAutoFillColorStyle = {
    WebkitBoxShadow: '0 0 0 1000px white inset',
  }

  useEffect(() => {
    if (texttemplates) {
      const arrayUniqueByLang = [
        ...new Map(
          texttemplates.map((item) => [item['language'], item])
        ).values(),
      ]
      const textTemplateLangs = arrayUniqueByLang?.map((item, index) => {
        return {
          id: index + 1,
          language: item?.language,
        }
      })
      setLanguageList([{ id: 0, language: 'All' }, ...textTemplateLangs])
    }
    return () => {
      setLanguage('All')
    }
  }, [texttemplates])

  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              minWidth: '75%', // Set your width here
              height: '90vh',
            },
          },
        }}
      >
        <Box>
          <Grid
            container
            spacing={2}
            mb={-3}
            pl={2}
            pr={2}
            justifyContent="space-between"
          >
            <Grid xs={8} sx={{ pl: '0.1%' }}>
              <BootstrapDialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              >
                <Typography
                  sx={{
                    fontFamily: 'HK Grotesk',
                    color: '#356F92',
                    fontSize: '20px',
                    fontWeight: 'bold',
                  }}
                >
                  Social Media Share Texts: Spread Your Message!
                </Typography>

                <Typography
                  sx={{
                    fontFamily: 'HK Grotesk',
                    color: '#2C2C2C',
                    fontSize: '16px',
                    fontWeight: 'bold',
                  }}
                  gutterBottom
                >
                  Copy or share the below text to your social accounts
                </Typography>
              </BootstrapDialogTitle>
            </Grid>
            <Box className="position-relative">
              <Grid xs={4}>
                <FormControl
                  variant="standard"
                  sx={{
                    boxShadow: 'none',
                    mt: 4,
                    mr: 7,
                    backgroundColor: '#F5F6FA',
                    borderRadius: '14px',
                    '& .MuiInputBase-input': {
                      paddingLeft: '10px',
                      fontFamily: 'HK Grotesk',
                      fontSize: '16px',
                      color: '#356F92',
                      backgroundColor: '#F5F6FA!important',
                      borderRadius: '14px!important',
                    },
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    IconComponent={
                      selectArrow ? iconComponent : iconComponentDown
                    }
                    sx={{
                      fontWeight: 'bolder',
                      fontSize: '18px',
                      width: '180px',
                      aspectRatio: '22 / 5',
                      fontFamily: 'HK Grotesk ExtraBold',
                    }}
                    label="Language"
                    onChange={handleChange}
                    onOpen={() => setSelectArrow(false)}
                    onClose={() => setSelectArrow(true)}
                    disableUnderline
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          width: '180px',
                          // marginLeft:"85px",
                          // marginTop:"10px",
                          borderRadius: '14px',
                          // bgcolor: 'pink',
                          '& .MuiMenuItem-root': {
                            fontFamily: 'HK Grotesk',
                            border: '1px solid #D3D3D3',
                            margin: '10px 20px 0',
                            borderRadius: '10px',
                            justifyContent: 'center',
                            fontSize: '14px',
                            fontWeight: 'bold',

                            '&$selected': {
                              backgroundColor: '#356F92',
                              color: '#000000',
                              '&:hover': {
                                backgroundColor: '#356F92',
                              },
                            },
                            '&:hover': {
                              backgroundColor: '#356F92',
                              color: '#fff',
                            },
                          },

                          '& .MuiSelect-select-root:focus': {
                            backgroundColor: '#fff',
                          },
                        },
                      },
                    }}
                  >
                    {languageList &&
                      languageList.map((s) => {
                        return (
                          <MenuItem
                            // native
                            key={s.id}
                            value={s.language}
                          >
                            {s.language}
                          </MenuItem>
                        )
                      })}
                  </Select>
                </FormControl>
              </Grid>
              <Typography component="span" sx={{ letterSpacing: 'normal' }}>
                <Typography
                  sx={{ fontWeight: 'bold' }}
                  className="language-text"
                >
                  Select your language
                </Typography>
              </Typography>
            </Box>
            <img onClick={handleClose} src={CrossIcon} className="closeIcon" />
          </Grid>
        </Box>
        <Box>
          <Divider component="hr" className="custom-border"></Divider>
        </Box>

        <Box>
          <Grid container spacing={2}>
            <DialogContent className="social-media__text-container">
              {/* <Box className="itemfixed5 "> */}
              <Box className="grid-item-1">
                {/* style={{display:'flex',flexWrap:'wrap',gap:'20px'}} */}
                <Grid>
                  <SharingTextBox
                    language={language}
                    postAuditLogs={postAuditLogs}
                  />
                </Grid>
              </Box>
              {/* </Box> */}
            </DialogContent>
          </Grid>
        </Box>

        <DialogActions>
          {/* <Button autoFocus onClick={handleClose}>
            Save changes
          </Button> */}
        </DialogActions>
      </BootstrapDialog>
    </Box>
  )
}
