import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CrossIcon from '../../../asserts/images/Close.svg'
import { Box } from '@mui/material'

export default function RemoveFileDialog({ callback, openRemoveDialog }) {
  return (
    <Box>
      <Dialog
        open={openRemoveDialog}
        onClose={callback}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ maxWidth: '550px', margin: '0 auto' }}
      >
        <img
          onClick={callback}
          alt="cross"
          src={CrossIcon}
          className="closeIcon"
        />
        <DialogContent sx={{ justifyContent: 'center' }}>
          <DialogContentText
            sx={{
              minHeight: '130px',
              display: 'flex',
              alignItems: 'center',
              color: '#6C6C6C',
              fontFamily: 'HK Grotesk',
              fontSize: '28px',
              textAlign: 'center',
              ml: 4,
              paddingTop: '20px',
            }}
          >
            All rows created during CSV upload will be deleted
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mt: 0 }}>
          <Button
            className="button-tr-2"
            sx={{ fontFamily: 'HK Grotesk', mb: 3, height: '35px', mt: 0 }}
            onClick={() => callback(true)}
          >
            Yes
          </Button>
          <Button
            className="button-tr-citizen-admin"
            sx={{
              fontFamily: 'HK Grotesk',
              mb: 3,
              width: '140px',
              height: '35px',
              fontSize: '13px!important',
              mt: 0,
            }}
            onClick={callback}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
