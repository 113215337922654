import React from 'react'
import third from '../../asserts/images/3rd.png'
import three from '../../asserts/images/2.jpg'
import first from '../../asserts/images/1st.png'
import one from '../../asserts/images/1.jpg'
import second from '../../asserts/images/2nd.png'
import two from '../../asserts/images/4.jpg'
import { useNavigate } from 'react-router-dom'
import { Grid, Box, Typography, Avatar, CardMedia } from '@mui/material'
import { useSelector } from 'react-redux'
import { getIds } from '../ReusableComponents.js/getIds'
import './ThreeCards.css'

import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { makeLogRequest } from '../../utils/AuditLogs'
import { convertNumberIntoLacsCr } from '../ReusableComponents.js/reuseMethods'
import ProfilePictureComponent from '../ReusableComponents.js/ProfilePictureComponent'
import { threeCardsProfile } from '../ReusableComponents.js/ProfilePictureStyles'
const ThreeCards = ({ cardClick, user }) => {
  const navigate = useNavigate()
  const topImage = [first, second, third]
  const mpList = useSelector((state) => state?.mpList?.data)
  const loggedInId = getIds()

  const handleCardlogs = (mp_id, index) => {
    const parameters = {
      rank: index + 1,
      mp_id: mp_id,
      section: cardClick,
      useridtype: getIds(),
    }
    makeLogRequest('mp_leaderboard', 'mp_card', parameters)
  }

  return (
    <Grid container spacing={3}>
      {mpList &&
        mpList.slice(0, 3).map((mp, index) => (
          <Grid item xs={12} md={12} lg={4} xl={4}>
            <Box
              className="cursor-pointer"
              onClick={(e) => {
                if (!window.getSelection().toString()) {
                  if (mp.id === loggedInId) {
                    navigate('/MySevaUpdates', {
                      state: {
                        MpName: 'My Seva Rank',
                        user: user,
                        mpId: loggedInId,
                      },
                    })
                  } else {
                    navigate('/MpSevaUpdates', {
                      state: {
                        MpName: mp.user_name,
                        user: user,
                        mpId: mp.id,
                        mpClick: true,
                        mpData: mp && mp,
                      },
                    })
                  }
                }
                handleCardlogs(mp.id, index)
              }}
            >
              <Box>
                <Box className="threeCards position-relative card-shadow">
                  <Box sx={{ maxWidth: '35px' }}>
                    <CardMedia component="img" alt="rank" src={topImage[index]} className="topicon"></CardMedia>
                  </Box>

                  <Box className="d-flex justify-content-center profile-parent">
                    <Box className="text-center pt-3  profile-img-box-shadow">
                      <ProfilePictureComponent ProfilPic={mp.user_avatar} sxProfile={threeCardsProfile} alt="cross" />
                    </Box>
                  </Box>

                  {/* {mp.user_avatar ? (
                    <Box className="text-center pt-3 d-flex justify-content-center">
                      <Avatar
                        alt="cross"
                        src={mp.user_avatar}
                        className="  profile-img-box-shadow threecard-avatar"
                        draggable="false"
                      />
                    </Box>
                  ) : (
                    <Box className="text-center pt-3">
                      <AccountCircleIcon
                        className="profile-icon-box-shadow"
                        sx={{
                          fontSize: 'xx-large',
                          width: '70px',
                          height: 'auto',
                          color: '#EEEEEE',
                        }}
                      />
                    </Box>
                  )} */}
                  <Box className="leader-content ellipsewe-name">
                    <Typography
                      className="ellipsewe1"
                      sx={{
                        color: '#356f92',
                        paddingTop: '20px',
                        fontFamily: 'HK Grotesk bold',
                        fontSize: '20px',
                      }}
                    >
                      {mp?.user_name}
                    </Typography>
                    <Typography variant="span" className="initial">
                      {mp?.house}
                    </Typography>
                    <br />
                    <Typography
                      className="ellipsewe1"
                      sx={{
                        color: '#707a89',
                        fontSize: '12px',
                        fontFamily: 'HK Grotesk medium',
                      }}
                    >
                      Constituency : {mp?.constituency_name}
                    </Typography>
                  </Box>
                  {cardClick === 'sevaScore' ? (
                    <Box className="score-container">
                      <Typography variant="h3" className="card-content">
                        MP Seva Score
                      </Typography>
                      <Typography variant="span">{convertNumberIntoLacsCr(parseInt(mp?.sevaScore, 10))}</Typography>
                    </Box>
                  ) : null}
                  {cardClick === 'ecards' ? (
                    <>
                      <Box className="score-container border-bottom">
                        <Typography variant="h3" className="card-content">
                          E-Cards Campaign Score
                        </Typography>
                        <Typography variant="span">
                          {convertNumberIntoLacsCr(parseInt(mp?.campaignScore < 0 ? 0 : mp?.campaignScore, 10))}
                        </Typography>
                      </Box>
                      <Box className="score-container font-12 mt-2">
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                          <Grid item xs={9} className="mt-1">
                            E-Cards created by MP
                          </Grid>
                          <Grid item xs={3} className="mt-1">
                            {convertNumberIntoLacsCr(mp?.cardsCreated)}
                          </Grid>
                          <Grid item xs={9}>
                            Likes on E-Cards created by MP
                          </Grid>
                          <Grid item xs={3}>
                            {convertNumberIntoLacsCr(mp?.likes)}
                          </Grid>
                          <Grid item xs={9}>
                            Wishes on E-Cards created by MP
                          </Grid>
                          <Grid item xs={3}>
                            {convertNumberIntoLacsCr(mp?.wishes)}
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : null}
                  {cardClick === 'memberAdded' ? (
                    <Box className="score-container">
                      <Typography variant="h3" className="card-content">
                        Members Added
                      </Typography>
                      <Typography variant="span">{convertNumberIntoLacsCr(parseInt(mp?.memberAdded, 10))}</Typography>
                    </Box>
                  ) : null}
                  {cardClick === 'followers' ? (
                    <Box className="score-container">
                      <Typography variant="h3" className="card-content">
                        Followers
                      </Typography>
                      <Typography variant="span">{convertNumberIntoLacsCr(parseInt(mp?.followers, 10))}</Typography>
                    </Box>
                  ) : null}
                  {cardClick === 'twitterPerformance' ? (
                    <Box className="score-container">
                      <Box className="Twitter-performance__score-heading">Twitter Performance</Box>
                      <Typography variant="span">
                        {convertNumberIntoLacsCr(parseInt(mp?.twitterPerformance, 10))}
                      </Typography>
                    </Box>
                  ) : null}
                  {cardClick === 'initiatives' ? (
                    <Box className="score-container font-14">
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={9} className="mt-1">
                          Citizens Engagement with Events
                        </Grid>
                        <Grid item xs={3} className="mt-1">
                          {convertNumberIntoLacsCr(mp?.citizen)}
                        </Grid>
                        <Grid item xs={8}>
                          Initiatives
                        </Grid>
                        <Grid item xs={4}>
                          {convertNumberIntoLacsCr(mp?.initiatives)}
                        </Grid>
                        <Grid item xs={8}>
                          Events Organized
                        </Grid>
                        <Grid item xs={4}>
                          {convertNumberIntoLacsCr(mp?.eventOrganized)}
                        </Grid>
                        {/* <Grid item xs={8}>
                        On-ground Participation
                        </Grid>
                        <Grid item xs={4}>
                        {mp.participation}
                        </Grid> */}
                      </Grid>
                    </Box>
                  ) : null}
                  {cardClick === 'mediaCoverage' ? (
                    <Box className="score-container">
                      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} paddingTop={2.5}>
                        <Grid item xs={6}>
                          Op-Eds
                        </Grid>
                        <Grid item xs={6}>
                          {convertNumberIntoLacsCr(parseInt(mp?.opEds, 10))}
                        </Grid>
                        <Grid item xs={8}>
                          Media Coverage
                        </Grid>
                        <Grid item xs={4}>
                          {convertNumberIntoLacsCr(parseInt(mp?.mediaCoverage, 10))}
                        </Grid>
                      </Grid>
                    </Box>
                  ) : null}
                  {cardClick === 'donation' && mp?.donation !== undefined ? (
                    <Box className="score-container">
                      <Typography variant="h3" className="card-content">
                        Donations Raised
                      </Typography>

                      <Typography variant="span">₹ {convertNumberIntoLacsCr(parseInt(mp?.donation, 0))}</Typography>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
    </Grid>
  )
}

export default ThreeCards
