import React, { useEffect, useMemo, useState } from 'react'
import { Grid, ImageListItem, ImageList, Typography, Button, Box, Avatar } from '@mui/material'
import { ImageLoader } from '../../ReusableComponents.js/ImageLoader'
import zipIcon from '../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../asserts/images/FileIcon.svg'
import unknownImg from '../../../asserts/images/folders.png'
import { handleFileName } from '../../ReusableComponents.js/reuseMethods'
import './ImagesFolder.css'

const ImagesFolder = ({ languageData, setViewImage, folderName, imageResponse, setAllImages }) => {
  const [imageData, setImageData] = useState([])
  const [selectedBannerRatio, setSelectedBannerRatio] = useState('')
  const [bannerFilter, setBannerFilter] = useState(false)

  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )

  useEffect(() => {
    if (folderName == 'Outdoor Banner') {
      setBannerFilter(true)
    } else {
      setImageData(handleImages)
      // setAllImages(handleImages);
    }
  }, [])

  const handleImages =
    imageResponse &&
    imageResponse.filter((data) => {
      return data.typeof_image === folderName && data.imageLanguage === languageData
    })

  const handleBannerImages = () => {
    return (
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs={4} sx={{ mr: '-14% !important', mb: 1.5 }}>
          <Button
            className="butbanner"
            sx={[
              {
                borderRadius: '17px',
                height: '30px',
                color: '#2c2c2c',
                font: 'HK Grotesk, SemiBold',
                fontSize: '15px',
                font: ' normal normal 600 20px/26px HK Grotesk',
                minWidth: '65px',
                background: ' #FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 2px 6px #0000001C',
              },
              selectedBannerRatio === '1:1' && {
                backgroundColor: '#356f92',
                color: '#fff',
              },
            ]}
            // variant="outlined"
            onClick={() => handleFilter('1:1')}
          >
            1:1
          </Button>
        </Grid>
        <Grid item xs={4} sx={{ mr: '-14% !important' }}>
          <Button
            className="butbanner"
            sx={[
              {
                borderRadius: '17px',
                height: '30px',
                color: '#2c2c2c',
                font: 'HK Grotesk, SemiBold',
                fontSize: '15px',
                font: ' normal normal 600 20px/26px HK Grotesk',
                minWidth: '65px',
                background: ' #FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 2px 6px #0000001C',
              },
              selectedBannerRatio === '16:9' && {
                backgroundColor: '#356f92',
                color: '#fff',
              },
            ]}
            // variant="outlined"
            onClick={() => handleFilter('16:9')}
          >
            16:9
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button
            className="butbanner"
            sx={[
              {
                borderRadius: '17px',
                height: '30px',
                color: '#2c2c2c',
                font: 'HK Grotesk, SemiBold',
                fontSize: '15px',
                font: ' normal normal 600 20px/26px HK Grotesk',
                minWidth: '65px',
                background: ' #FFFFFF 0% 0% no-repeat padding-box',
                boxShadow: '0px 2px 6px #0000001C',
              },
              selectedBannerRatio === '3:6' && {
                backgroundColor: '#356f92',
                color: '#fff',
              },
            ]}
            // variant="outlined"
            onClick={() => handleFilter('3:6')}
          >
            3:6
          </Button>
        </Grid>
      </Grid>
    )
  }

  useEffect(() => {
    const bannerSizes = ['16:9', '1:1', '3:6']
    const data = imageResponse?.filter((data) => {
      return (
        data.typeof_image === folderName &&
        data.imageLanguage === languageData &&
        bannerSizes.includes(data.banner_size)
      )
    })

    let sortedData = []
    const ratio69 = data?.filter((item) => item.banner_size === '16:9')
    const ratio11 = data?.filter((item) => item.banner_size === '1:1')
    const ratio36 = data?.filter((item) => item.banner_size === '3:6')
    if (ratio69[0]?.url?.length > 0) {
      sortedData.push(...ratio69)
      setSelectedBannerRatio('16:9')
      setViewImage(JSON.parse(ratio69[0].url)[0])
    } else if (ratio11[0]?.url?.length > 0) {
      sortedData.push(...ratio11)
      setSelectedBannerRatio('1:1')
      setViewImage(JSON.parse(ratio11[0].url)[0])
    } else if (ratio36[0]?.url?.length > 0) {
      if (ratio36[0]?.url?.length > 0) {
        sortedData.push(...ratio36)
        setSelectedBannerRatio('3:6')
        setViewImage(JSON.parse(ratio36[0].url)[0])
      }
    }

    if (ratio69[0]?.url?.length > 0 || ratio11[0]?.url?.length > 0 || ratio36[0]?.url?.length > 0) {
      setImageData(sortedData)
    }
  }, [imageResponse])

  const handleFilter = (value) => {
    const data =
      imageResponse &&
      imageResponse.filter((data) => {
        return (
          data.typeof_image === folderName && data.imageLanguage === languageData && data.banner_size.trim() === value
        )
      })
    setImageData(data)
    setSelectedBannerRatio(value)
    // setAllImages(data);
  }

  let imagesArray = []
  imageData &&
    imageData?.forEach((image) => {
      const url = JSON.parse(image.url)
      // image && image?.forEach((data) =)
      url && url?.forEach((data) => imagesArray.push(data))
    })

  useEffect(() => {
    setAllImages(imagesArray)
  }, [imageData])

  return (
    <>
      {bannerFilter ? handleBannerImages() : null}
      <Box className="itemgrid2 cursorshow">
        {imagesArray &&
          imagesArray?.map((item, i) => (
            <Box onClick={() => setViewImage(item)} className=" ">
              {item.split('.').pop() === 'zip' || ['ai', 'postscript'].includes(item.split('.').pop()) ? (
                <ImageListItem
                  key={i}
                  className="bgfolder cursorshow "
                  sx={{
                    margin: '0px',
                    width: '170px !important',
                    height: ' 120px !important',
                    padding: '10px',
                    borderRadius: '0px',
                    aspectRatio: '1 / 0.9 !important',
                  }}
                >
                  <img
                    className="zip-ai-postscript-image-org"
                    key={i}
                    src={
                      item.split('.').pop() === 'zip'
                        ? zipIcon
                        : ['ai', 'postscript'].includes(item.split('.').pop()) && fileIcon
                    } //?w=164&h=164&fit=crop&auto=format
                    srcSet={
                      item.split('.').pop() === 'zip'
                        ? zipIcon
                        : ['ai', 'postscript'].includes(item.split('.').pop()) && fileIcon
                    } //?w=164&h=164&fit=crop&auto=format
                    // alt={item.title}
                  />
                  <Typography variant="subtitle1" sx={subtitleStyles}>
                    {handleFileName(item)}
                  </Typography>
                </ImageListItem>
              ) : (
                <Box className="image-supported">
                  <ImageLoader
                    key={i}
                    src={`${
                      item.split('.').pop() === 'zip'
                        ? zipIcon
                        : ['ai', 'postscript'].includes(item.split('.').pop())
                        ? fileIcon
                        : item
                    }`}
                    className=" im100 visible-image"
                    srcSet={`${
                      item.split('.').pop() === 'zip'
                        ? zipIcon
                        : ['ai', 'postscript'].includes(item.split('.').pop())
                        ? fileIcon
                        : item
                    }`}
                    onClick={() => {
                      setViewImage(item)
                    }}
                    defaultImg={unknownImg}
                    subtitleStyles={subtitleStyles}
                    item={item}
                  />
                </Box>
              )}
            </Box>
          ))}
        {imagesArray?.length < 1 && (
          <Typography component="span" className="mt-2 ml-1">
            No image added
          </Typography>
        )}
      </Box>
    </>
  )
}

export default ImagesFolder
