import { getIds } from './getIds';
import axiosInstance from '../../utils/api';

const CopyAndShareClick = async () => {
  const loggedInId = getIds();
  try {
    await axiosInstance.post(`/api/mp/copyDataScore/${loggedInId}`, {}).then((response) => {
      return response;
    });
  } catch (error) {
    console.log('Error', error);
  }
};

export default CopyAndShareClick;
