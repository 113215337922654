import React, { useState, useEffect } from 'react'
import { Typography, Box, Menu, CardMedia } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getMostViewedTweets } from '../../../store/action/getMostViewedTweets.js'
import { getMostRetweetedTweets } from '../../../store/action/getMostRetweetedTweets.js'
import { getTopMostFollowers } from '../../../store/action/getTopMostFollowers.js'
import { ArrowComponent, ArrowComponentDown } from '../../ReusableComponents.js/reuseMethods.js'
import MenuItem from '@mui/material/MenuItem'
import '../Highlights.css'
import '../../../utils/LoadingContext.css'
import TwitterHighlightsContainer from './TwitterHighlightsContainer.js'

const TwitterHighlights = () => {
  const dispatch = useDispatch()
  const [option, setOption] = useState('view')
  const [optionText, setOptionText] = useState('By Likes')
  const [showMenu, setShowMenu] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const { listTopMostViewedTweet } = useSelector((state) => state?.mostViewedTweets?.data)
  const { listTopMostRetweetedTweet } = useSelector((state) => state?.mostRetweetedTweets?.data)
  const { topMostFollowers } = useSelector((state) => state?.mostTwiiterNewFollowers?.data)

  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isMenuOpen && anchorEl && !anchorEl.contains(event.target)) {
        handleClose()
      }
    }
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isMenuOpen, anchorEl])

  useEffect(() => {
    dispatch(getMostViewedTweets())
    dispatch(getMostRetweetedTweets())
    dispatch(getTopMostFollowers())
  }, [])

  const loaderStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    setShowMenu(true)
    setIsMenuOpen(true)
  }

  const handleClose = () => {
    setAnchorEl(null)
    setShowMenu(false)
    setIsMenuOpen(false)
  }

  const handleMenuItemClick = (selectedValue) => {
    setOption(selectedValue)
    setOptionText(selectedValue === 'view' ? 'By Likes' : 'By Retweets')
    handleClose()
  }

  return (
    <Box>
      <Box>
        {/* <Box className="twitterhead">
          <Box>
            <Avatar variant="square" src={twitter} className="twitter-icon" alt="delete" />
          </Box>
          <Box>
            <Typography variant="h1" className="twitterHighlightText">
              Twitter Highlights
            </Typography>
          </Box>
        </Box>
        <Box className="twitterHighlightsDescription">
          <Typography className="twitterHighlightsDescription__title">
            Highlights of past week refreshed every Sunday midnight.
          </Typography>
          <Typography className="twitterHighlightsDescription__subtitle">
            Last refreshed on {lastRefreshedAt && lastRefreshedAt}
          </Typography>
        </Box>
        <Divider className="hrdivi highlights-divider"></Divider> */}
        <Box
          className="tweetSectionHeader"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '25px',
            marginBottom: '15px',
          }}
        >
          <Typography variant="h3" className="header-twt">
            Trending Tweets
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography className="twitter-selected-option-text">{optionText}</Typography>
            <CardMedia onClick={handleClick} className="cursorshow">
              {showMenu ? <ArrowComponentDown /> : <ArrowComponent />}
            </CardMedia>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(showMenu)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              PaperProps={{
                sx: {
                  borderRadius: '14px',
                  '& .MuiMenuItem-root': {
                    fontFamily: 'HK Grotesk Bold',
                    border: '1px solid #D3D3D3',
                    margin: '10px 20px',
                    borderRadius: '10px',
                    justifyContent: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',

                    '&$selected': {
                      backgroundColor: '#356F92',
                      color: '#000000',
                      '&:hover': {
                        backgroundColor: '#356F92',
                      },
                    },
                    '&:hover': {
                      backgroundColor: '#356F92',
                      color: '#fff',
                    },
                  },

                  '& .MuiSelect-select-root:focus': {
                    backgroundColor: '#fff',
                  },
                },
              }}
            >
              <MenuItem onClick={() => handleMenuItemClick('view')} value={'view'}>
                By Likes
              </MenuItem>
              <MenuItem onClick={() => handleMenuItemClick('retweet')} value={'retweet'}>
                By Retweets
              </MenuItem>
            </Menu>
          </Box>
        </Box>
        {option === 'view' ? (
          <TwitterHighlightsContainer twitterData={listTopMostViewedTweet} />
        ) : (
          <TwitterHighlightsContainer twitterData={listTopMostRetweetedTweet} />
        )}
      </Box>
    </Box>
  )
}

export default TwitterHighlights
