import {
  SEARCH_SEVA_INITIATIVES_REQUEST,
  SEARCH_SEVA_INITIATIVES_SUCCESS,
  SEARCH_SEVA_INITIATIVES_FAILURE,
} from '../action/types'

const initialState = {
  data: [],
  loading: true,
  error: '',
}

export const searchSevaInitiatives = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_SEVA_INITIATIVES_REQUEST:
      return {
        state,
        loading: true,
      }
    case SEARCH_SEVA_INITIATIVES_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      }
    case SEARCH_SEVA_INITIATIVES_FAILURE:
      return {
        data: [],
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
