import {
  FETCH_EVENTS_LIST_REQUEST,
  FETCH_EVENTS_LIST_SUCCESS,
  FETCH_EVENTS_LIST_FAILURE,
  FETCH_EVENTS_LIST_BY_MP_ID_REQUEST,
  FETCH_EVENTS_LIST_BY_MP_ID_SUCCESS,
  FETCH_EVENTS_LIST_BY_MP_ID_FAILURE,
} from './types'
import axiosInstance from '../../utils/api'

export const fetchEventsListRequest = () => {
  return {
    type: FETCH_EVENTS_LIST_REQUEST,
  }
}
export const fetchEventsListSuccess = (carMakes) => {
  return {
    type: FETCH_EVENTS_LIST_SUCCESS,
    payload: carMakes,
  }
}
export const fetchEventsListFailure = (error) => {
  return {
    type: FETCH_EVENTS_LIST_FAILURE,
    payload: error,
  }
}

export const fetchEventsListByMpIdRequest = () => {
  return {
    type: FETCH_EVENTS_LIST_BY_MP_ID_REQUEST,
  }
}
export const fetchEventsListByMpIdSuccess = (carMakes) => {
  return {
    type: FETCH_EVENTS_LIST_BY_MP_ID_SUCCESS,
    payload: carMakes,
  }
}
export const fetchEventsListByMpIdFailure = (error) => {
  return {
    type: FETCH_EVENTS_LIST_BY_MP_ID_FAILURE,
    payload: error,
  }
}

export const getEventsList = (payload) => async (dispatch) => {
  const { id, limit, prevId = 0, isPrevTenure = false } = payload
  let endPoint = `/api/event/getall/${id ? id : 0}?prevId=${prevId}&isPrevTenure=${isPrevTenure}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  dispatch(fetchEventsListRequest)
  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response.data
      // const result = mpList
      dispatch(fetchEventsListSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchEventsListFailure(errorMsg))
    })
}

export const getEventsListByMpId = (payload) => async (dispatch) => {
  const { id, limit, prevId = 0, isPrevTenure = false } = payload
  let endPoint = `/api/getEventDataByMpId/${id}?prevId=${prevId}&isPrevTenure=${isPrevTenure}`
  if (limit) {
    endPoint += `&limit=${limit}`
  }
  dispatch(fetchEventsListByMpIdRequest)
  await axiosInstance
    .get(endPoint)
    .then((response) => {
      const result = response.data
      dispatch(fetchEventsListByMpIdSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchEventsListByMpIdFailure(errorMsg))
    })
}
