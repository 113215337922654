import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Box, Grid, Button } from '@mui/material'
import { makeLogRequest } from 'utils/AuditLogs'
import { useNotificationContext } from 'utils/NotificationContext'
import { pushTag } from 'utils/gtmservice'
import { getMpProfile } from 'store/action/individualMP'
import { getAllHighlights } from 'store/action/globalHighlights'
import TableHighlights from 'Components/Highlights/TableHighlightsRefactor/TableHighlights'
import ProfileCard from 'Components/ReusableComponents.js/ProfileCard/ProfileCard'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import MySevaRankCard from 'Components/MySevaUpdatesRefactor/MySevaRankCard/MySevaRankCard'
import { getIds } from 'Components/ReusableComponents.js/getIds'
import SevaUpdateHeader from 'Components/SevaUpdateRefactored/SevaUpdateHeader/SevaUpdateHeader'
import MySevaUpdateContent from 'Components/MySevaUpdatesRefactor/MySevaUpdateContent/MySevaUpdateContent'
import './MySevaUpdates.css'
import Tenure from 'Components/ReusableComponents.js/Tenure/Tenure'

const MySevaUpdatesRefactored = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const loggedInId = getIds()
  let mpName = location?.state?.MpName || 'My Seva Rank'
  let mpId = location?.state?.mpId || loggedInId
  const [active, setActive] = useState('Modi 3.0')

  useEffect(() => {
    dispatch(getMpProfile(getIds()))
  }, [])
  const [pageTitle, setPageTitle] = useState('')

  useEffect(() => {
    document.getElementsByClassName('App')[0].scrollTop = 0
  }, [location])

  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const postAuditLogs = async (sqId) => {
    let requestData = {
      useridtype: getIds(),
    }
    await makeLogRequest('my_seva_updates', sqId, requestData)
  }

  const sendGALog = (event, message = null, category = null, page_title_prop = null) => {
    const page_title = pageTitle || page_title_prop
    const payload = {
      event,
      page_title,
    }
    if (message) payload.message = message
    if (category) payload.category = category
    pushTag(payload)
  }

  useEffect(() => {
    const page_title = 'my_seva_update_page'
    setPageTitle(page_title)
    postAuditLogs('pageload')
    sendGALog('pageload', null, null, page_title)
  }, [])

  const handleMySevaUpdateClick = async (e) => {
    e.stopPropagation()
    await postAuditLogs('nationwide_seva_updates')
    const message = 'nationwide_seva_updates'
    sendGALog('select_option', message)
    navigate('/SevaUpdates')
  }

  const { data, loading } = useSelector((state) => state?.highlights)
  const [rows, setRows] = useState([])
  const [dataLoading, setDataLoading] = useState(loading)
  const { showNotification } = useNotificationContext()

  function createData(heading, value) {
    return { heading, value }
  }

  useEffect(() => {
    fetchData()
  }, [active])

  useEffect(() => {
    const rowsData = Object.keys(data ? data : [])?.map((val) => {
      return createData(val, data[val])
    })
    rowsData.splice(10, 1)
    setRows(rowsData)
  }, [data])

  const fetchData = async () => {
    try {
      const response = await dispatch(getAllHighlights(mpId, 'alltime', active))
      if (response.status !== 200) {
        showNotification('Error', response?.data?.message, 'error')
      }
    } catch (error) {
      showNotification('Error', error, 'error')
    } finally {
      setDataLoading(false)
    }
  }

  return (
    <Box className="page-wrapper d-flex">
      <SideMenu activeTab={'Seva'} />
      <Box className="main-wrapper center-width customscroll">
        <Grid container>
          <Grid xs={12} md={8} lg={8} xl={8} className="max-width-max-content">
            <Box className="d-flex justify-content-between align-items-center carousel-pos">
              <Box>
                <Box sx={{ ml: '-37px' }}>
                  <SevaUpdateHeader title={'My Seva Updates'} />
                </Box>
                {active === 'Modi 3.0' && <MySevaRankCard mySeva={true} mpName={mpName} mpId={mpId} />}
              </Box>
            </Box>

            <Tenure active={active} setActive={setActive} />
            <MySevaUpdateContent
              pageTitle={pageTitle}
              sendGALog={sendGALog}
              mpProfileData={mpProfileData}
              mySeva={true}
              isEdit={true}
              mpId={mpId}
              mpName={mpName}
              tenure={active}
            />
          </Grid>
          <Grid xs={12} md={4} lg={4} xl={4} className={'mtl-20'}>
            <Box className="rtcar">
              <Box className="right-card mb-3">
                <ProfileCard page_title={pageTitle} />

                <Button class="sevaupdate-nationbutton" onClick={(e) => handleMySevaUpdateClick(e)}>
                  {'NATIONWIDE SEVA UPDATES'}
                </Button>

                <TableHighlights data={rows} dataLoading={dataLoading} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default MySevaUpdatesRefactored
