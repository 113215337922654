import { DELETE_ALERT_REQUEST, DELETE_ALERT_SUCCESS, DELETE_ALERT_FAILURE } from '../types'
import axiosInstance from '../../../utils/api'

export const deleteAlertRequest = () => {
  return {
    type: DELETE_ALERT_REQUEST,
  }
}

export const deleteAlertSuccess = (value) => {
  return {
    type: DELETE_ALERT_SUCCESS,
    payload: value,
  }
}

export const deleteAlertError = (error) => {
  return {
    type: DELETE_ALERT_FAILURE,
    payload: error,
  }
}

export const deleteAlertAction = (id) => async (dispatch) => {
  dispatch(deleteAlertRequest)
  return axiosInstance
    .delete(`/api/admin/notification/delete?id=${id}`)
    .then((response) => {
      const result = response.data
      dispatch(deleteAlertSuccess(result))
      return response
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(deleteAlertError(errorMsg))
      throw new Error(error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message)
    })
}
