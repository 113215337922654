import { GET_EVENT_FAILURE, GET_EVENT_REQUEST, GET_EVENT_SUCCESS } from './types';
import axiosInstance from '../../utils/api';

export const fetchGetMpEventsRequest = () => {
  return {
    type: GET_EVENT_REQUEST,
  };
};
export const fetchGetMpEventsSuccess = (value) => {
  return {
    type: GET_EVENT_SUCCESS,
    payload: value,
  };
};
export const fetchGetMpEventsFailure = (error) => {
  return {
    type: GET_EVENT_FAILURE,
    payload: error,
  };
};

export const getEvents = (id) => async (dispatch) => {
  dispatch(fetchGetMpEventsRequest);
  await axiosInstance
    .get(`/api/event/getall/${id}`)
    .then((response) => {
      const result = response.data;
      dispatch(fetchGetMpEventsSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchGetMpEventsFailure(errorMsg));
    });
};
