import {
  SEARCH_SEVA_EVENTS_REQUEST,
  SEARCH_SEVA_EVENTS_SUCCESS,
  SEARCH_SEVA_EVENTS_FAILURE,
  SEARCH_MEDIA_COVERAGE_REQUEST,
  SEARCH_MEDIA_COVERAGE_SUCCESS,
  SEARCH_MEDIA_COVERAGE_FAILURE,
  SEARCH_DEVELOPMENT_PROJECTS_REQUEST,
  SEARCH_DEVELOPMENT_PROJECTS_SUCCESS,
  SEARCH_DEVELOPMENT_PROJECTS_FAILURE,
  SEARCH_MISCELLANEOUS_REQUEST,
  SEARCH_MISCELLANEOUS_SUCCESS,
  SEARCH_MISCELLANEOUS_FAILURE,
} from './types'
import axiosInstance from '../../utils/api'

export const searchSevaEventsRequest = () => {
  return {
    type: SEARCH_SEVA_EVENTS_REQUEST,
    payload: [],
  }
}
export const searchSevaEventsSuccess = (value) => {
  return {
    type: SEARCH_SEVA_EVENTS_SUCCESS,
    payload: value,
  }
}
export const searchSevaEventsFailure = (error) => {
  return {
    type: SEARCH_SEVA_EVENTS_FAILURE,
    payload: [],
  }
}

export const searchMediaCoverageRequest = () => {
  return {
    type: SEARCH_MEDIA_COVERAGE_REQUEST,
    payload: [],
  }
}
export const searchMediaCoverageSuccess = (value) => {
  return {
    type: SEARCH_MEDIA_COVERAGE_SUCCESS,
    payload: value,
  }
}
export const searchMediaCoverageFailure = (error) => {
  return {
    type: SEARCH_MEDIA_COVERAGE_FAILURE,
    payload: [],
  }
}

export const searchDevProjectsRequest = () => {
  return {
    type: SEARCH_DEVELOPMENT_PROJECTS_REQUEST,
    payload: [],
  }
}
export const searchDevProjectsSuccess = (value) => {
  return {
    type: SEARCH_DEVELOPMENT_PROJECTS_SUCCESS,
    payload: value,
  }
}
export const searchDevProjectsFailure = (error) => {
  return {
    type: SEARCH_DEVELOPMENT_PROJECTS_FAILURE,
    payload: [],
  }
}

export const searchMiscellaneousRequest = () => {
  return {
    type: SEARCH_MISCELLANEOUS_REQUEST,
    payload: [],
  }
}
export const searchMiscellaneousSuccess = (value) => {
  return {
    type: SEARCH_MISCELLANEOUS_SUCCESS,
    payload: value,
  }
}
export const searchMiscellaneousFailure = (error) => {
  return {
    type: SEARCH_MISCELLANEOUS_FAILURE,
    payload: [],
  }
}

export const getSearchEventsList = (queryParams) => async (dispatch) => {
  dispatch(searchSevaEventsRequest)
  const { searchText, offset, limit, mpId, initiativeId } = queryParams
  let urlEndPoint =
    mpId && !initiativeId
      ? `/api/search/event?searchText=${searchText}&id=${mpId}&offset=${offset}&limit=${limit}`
      : `/api/search/event?searchText=${searchText}&offset=${offset}&limit=${limit}`

  if (initiativeId) {
    urlEndPoint += `&initiativeId=${initiativeId}`
  }
  await axiosInstance
    .get(urlEndPoint)
    .then((response) => {
      const result = response.data
      dispatch(searchSevaEventsSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(searchSevaEventsFailure(errorMsg))
    })
}

export const getSearchMediaCoveragesList = (queryParams) => async (dispatch) => {
  dispatch(searchMediaCoverageRequest)
  const { searchText, offset, limit, mpId } = queryParams
  let urlEndPoint = mpId
    ? `/api/search/media?searchText=${searchText}&id=${mpId}&offset=${offset}&limit=${limit}`
    : `/api/search/media?searchText=${searchText}&offset=${offset}&limit=${limit}`

  await axiosInstance
    .get(urlEndPoint)
    .then((response) => {
      const result = response.data
      dispatch(searchMediaCoverageSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(searchMediaCoverageFailure(errorMsg))
    })
}

export const getSearchDevProjectsList = (queryParams) => async (dispatch) => {
  dispatch(searchDevProjectsRequest)
  const { searchText, offset, limit, mpId } = queryParams
  let urlEndPoint = mpId
    ? `/api/search/developmentprojects?searchText=${searchText}&id=${mpId}&offset=${offset}&limit=${limit}`
    : `/api/search/developmentprojects?searchText=${searchText}&offset=${offset}&limit=${limit}`

  await axiosInstance
    .get(urlEndPoint)
    .then((response) => {
      const result = response.data
      dispatch(searchDevProjectsSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(searchDevProjectsFailure(errorMsg))
    })
}

export const getSearchMiscellaneousList = (queryParams) => async (dispatch) => {
  dispatch(searchMiscellaneousRequest)
  const { searchText, offset, limit, mpId } = queryParams
  let urlEndPoint = mpId
    ? `/api/search/misc?searchText=${searchText}&id=${mpId}&offset=${offset}&limit=${limit}`
    : `/api/search/misc?searchText=${searchText}&offset=${offset}&limit=${limit}`

  await axiosInstance
    .get(urlEndPoint)
    .then((response) => {
      const result = response.data
      dispatch(searchMiscellaneousSuccess(result))
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(searchMiscellaneousFailure(errorMsg))
    })
}
