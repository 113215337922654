import { CardMedia, Box, Typography } from '@mui/material'
import illimageIcon from '../../../asserts/images/Illustration.svg'
import { Link } from 'react-router-dom'
import './NoDataFound.css'

const NoDataFound = () => {
  return (
    <>
      <CardMedia component="img" className="no-data-media" src={illimageIcon} alt="" />
      <Box className="no-data-found-text-container d-flex justify-content-center flex-column align-items-center">
        <Typography variant="span" className="no-data-found-text">
          No data found!
        </Typography>
        <Typography variant="span" className="create-initiative-link-text">
          Click <Link to="/SevaInitiatives/createInitiative">here</Link> to create an initiative
        </Typography>
      </Box>
    </>
  )
}
export default NoDataFound
