import { Box, Typography } from '@mui/material'
import '../InitiativesDetails.css'
import './EventInitiativeReport.css'
function EventInitiativeButton({ handleOnclick, button, id, data, initReportDetails, toHide }) {
  return (
    <>
      <Box
        className="gfg cursorshow"
        sx={toHide ? { cursor: 'default !important' } : ''}
        onClick={toHide ? null : handleOnclick}
      >
        <img src={button} className="imagewgfg" />
        <Typography variant="body1" class="first-txt clickable">
          {id === 1
            ? 'Create an Event For this Initiative'
            : data?.status === 'Completed' || initReportDetails?.id
            ? 'Update an Initiative Report'
            : 'Create an Initiative Report'}
        </Typography>
      </Box>
    </>
  )
}
export default EventInitiativeButton
