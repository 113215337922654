import { ASSIGN_MP_FAILURE, ASSIGN_MP_REQUEST, ASSIGN_MP_SUCCESS } from '../action/types';

const initialState = {
  assignMpList: [],
  error: '',
};

export const getAssignMpReducer = (state = initialState, action) => {
  switch (action.type) {
    case ASSIGN_MP_REQUEST:
      return {
        ...state,
      };
    case ASSIGN_MP_SUCCESS:
      return {
        assignMpList: action.payload,
      };
    case ASSIGN_MP_FAILURE:
      return {
        assignMpList: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
