import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import playicon from '../../../asserts/images/Play.svg'
import { Box, IconButton } from '@mui/material'
import CrossIcon from '../../../asserts/images/Close.svg'
import Arrow from '../../../asserts/images/previmg.png'
import DisabledArrow from '../../../asserts/images/disabled-previmg.png'
import './PreviewNew.css'

const PreviewImagesDialog = ({ openPreviewImages, handleClosePreviewImages, files, previewImageIndex }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(previewImageIndex || 0)
  const videoRefsArray = files.map(() => React.createRef())
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

  useEffect(() => {
    setSelectedImageIndex(previewImageIndex)
  }, [previewImageIndex])

  const handlePreviewThumbs = (file) => {
    onChangeEvent(selectedImageIndex)
    if (file.type === 'image') {
      return <img src={typeof file === 'string' ? file : file.url} alt="Preview" className="form-img__img-preview-1" />
    }
    if (file.type === 'video') {
      return (
        <video className="form-img__img-preview-1">
          <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }
    const images = ['jpg', 'jpeg', 'gif', 'png', 'svg', 'jfif']
    const videos = ['mp4', '3gp', 'ogg']
    const url = new URL(file)
    const extension = url.search.split('.')[1]
    const pathExtension = url?.pathname?.slice(url.pathname.lastIndexOf('.') + 1, url.pathname.length) || ''
    if (images.includes(extension) || images.includes(pathExtension)) {
      return <img src={file} alt="Preview" className="form-img__img-preview" />
    }
    if (videos.includes(extension) || videos.includes(pathExtension)) {
      return (
        <Box>
          <img alt="Play video" src={playicon} className="play-btn-style w-auto" />
          <video className="videothumb">
            <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      )
    }
  }

  const handlePreview = (file, index) => {
    // const images = ["jpg", "gif", "png", "svg"]
    // const videos = ["mp4", "3gp", "ogg"]
    // const url = new URL(file)
    // const extension = url.pathname.split(".")[1]
    // if (images.includes(extension)) {
    //     return <img src={file} alt="Preview" />;
    // }
    // else if (videos.includes(extension)) {
    //     return (
    //         <video controls>
    //             <source src={file} type="video/mp4" />
    //             {/* Your browser does not support the video tag. */}
    //         </video>
    //     );
    // }
    if (file.type === 'image') {
      return (
        <Box
          className="user-profile-img__background"
          sx={{
            backgroundImage: `url(${file?.url})`,
          }}
        >
          <img
            src={typeof file === 'string' ? file : file.url}
            alt="Preview"
            className="form-img__img-preview preview-asset-height"
          />
        </Box>
      )
    }
    if (file.type === 'video') {
      return (
        <video controls disablePictureInPicture controlsList="nodownload noplaybackrate" className="">
          <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }
    const images = ['jpg', 'jpeg', 'gif', 'png', 'svg', 'jfif']
    const videos = ['mp4', '3gp', 'ogg']
    const url = new URL(file)
    const extension = url.search.split('.')[1]
    const pathExtension = url?.pathname?.slice(url.pathname.lastIndexOf('.') + 1, url.pathname.length) || ''
    if (images.includes(extension) || images.includes(pathExtension)) {
      return (
        <Box
          className="user-profile-img__background"
          sx={{
            backgroundImage: `url(${file})`,
          }}
        >
          <img src={file} alt="Preview" className="form-img__img-preview preview-asset-height" />
        </Box>
      )
    }
    if (videos.includes(extension) || videos.includes(pathExtension)) {
      return (
        <>
          <Box className="postabsvideo">
            <video
              ref={videoRefsArray[index]}
              controls
              disablePictureInPicture
              controlsList="nodownload noplaybackrate"
              className="d-block h-23-rem"
            >
              <source
                src={typeof file === 'string' ? file : file.url}
                type="video/mp4"
                className="preview-asset-height"
              />
              Your browser does not support the video tag.
            </video>
          </Box>
        </>
      )
    }
  }

  useEffect(() => {
    const handleCanPlay = () => {
      if (videoRefsArray[selectedImageIndex]?.current) {
        const source = videoRefsArray[selectedImageIndex]?.current.querySelector('source')

        source.addEventListener('loadedmetadata', () => {
          const rangeStart = 0
          const rangeEnd = 28000 // set the end range in bytes

          const xhr = new XMLHttpRequest()
          xhr.open('GET', source.src, true)
          xhr.setRequestHeader('Range', 'bytes=' + rangeStart + '-' + rangeEnd)
          xhr.responseType = 'blob'

          xhr.onload = function () {
            if (this.status === 206) {
              // Assuming the server supports range requests and returns a 206 status code
              const blob = new Blob([this.response], { type: 'video/mp4' })
              source.src = URL.createObjectURL(blob)
              videoRefsArray[selectedImageIndex]?.current.load()
            }
          }

          xhr.send()
        })
      }
    }

    if (videoRefsArray[selectedImageIndex]?.current && isSafari) {
      videoRefsArray[selectedImageIndex]?.current.addEventListener('canplay', handleCanPlay)
    }

    return () => {
      if (videoRefsArray[selectedImageIndex]?.current && isSafari) {
        videoRefsArray[selectedImageIndex]?.current.removeEventListener('canplay', handleCanPlay)
      }
    }
  }, [isSafari, selectedImageIndex, videoRefsArray])

  const onChangeEvent = (index) => {
    setTimeout(function () {
      var element = document.getElementsByClassName('thumb')[index]
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }, 100)
  }

  const pauseVideosOnSelectedItemChange = () => {
    videoRefsArray.map((item) => item.current?.pause())
  }

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 37: // left arrow
        if (selectedImageIndex > 0) {
          setSelectedImageIndex((prevIndex) => prevIndex - 1)
          pauseVideosOnSelectedItemChange()
        }
        break
      case 39: // right arrow
        if (selectedImageIndex < files.length - 1) {
          setSelectedImageIndex((prevIndex) => prevIndex + 1)
          pauseVideosOnSelectedItemChange()
        }
        break
      default:
        break
    }
  }

  const handleRightArrow = () => {
    if (files?.length - 1 === selectedImageIndex) return
    setSelectedImageIndex((prevIndex) => prevIndex + 1)
    pauseVideosOnSelectedItemChange()
  }

  const handleLeftArrow = () => {
    if (selectedImageIndex === 0) return
    setSelectedImageIndex((prevIndex) => prevIndex - 1)
    pauseVideosOnSelectedItemChange()
  }

  const onCarouselItemSelect = (index) => {
    setSelectedImageIndex(index)
    pauseVideosOnSelectedItemChange()
  }

  return (
    <>
      <Dialog
        open={openPreviewImages}
        onClose={handleClosePreviewImages}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0,0,0,0.9)',
            mt: '-5px',
            width: '200%',
            minWidth: '100vw',
            minHeight: '100vh',
          },
        }}
        onKeyDown={handleKeyDown}
      >
        {/* <button className="close-button" onClick={handleClosePreviewImages}>
                <CloseIcon />
            </button> */}
        <Box className="d-flex justify-content-end  bg-transparent">
          <img
            alt="crossddd"
            onClick={() => {
              handleClosePreviewImages()
            }}
            src={CrossIcon}
            className="preview-asset-cross-icon"
          />
        </Box>

        <img src={selectedImageIndex === 0 ? DisabledArrow : Arrow} className="arrow-left" onClick={handleLeftArrow} />
        <img
          src={selectedImageIndex === files?.length - 1 ? DisabledArrow : Arrow}
          className="arrow-right"
          onClick={handleRightArrow}
        />
        <Box className="carousel-wrapper">
          <Carousel
            showArrows={false}
            className="dialogopac1"
            selectedItem={selectedImageIndex}
            onChange={onChangeEvent}
            renderIndicator={(onClickHandler, isSelected, index, label) => (
              <span
                key={index}
                className={isSelected ? 'dotactive ' : 'dot'}
                onClick={() => {
                  onClickHandler(index)
                  setSelectedImageIndex(index)
                }}
              ></span>
            )}
            renderThumbs={() =>
              files &&
              files?.map((file, index) => (
                <Box key={index} onClick={() => onCarouselItemSelect(index)}>
                  {handlePreviewThumbs(file)}
                </Box>
              ))
            }
          >
            {files &&
              files?.map((file, index) => (
                <Box className="selected-img cursorshow" key={index}>
                  {handlePreview(file, index)}
                </Box>
              ))}
          </Carousel>
        </Box>
      </Dialog>
    </>
  )
}

export default PreviewImagesDialog
