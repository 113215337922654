import {
  FETCH_DEVELOPMENT_PROJECTS_LIST_REQUEST,
  FETCH_DEVELOPMENT_PROJECTS_LIST_SUCCESS,
  FETCH_DEVELOPMENT_PROJECTS_LIST_FAILURE,
  FETCH_DEVELOPMENT_STATUS_LIST_REQUEST,
  FETCH_DEVELOPMENT_STATUS_LIST_SUCCESS,
  FETCH_DEVELOPMENT_STATUS_LIST_FAILURE,
  FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_REQUEST,
  FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_SUCCESS,
  FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_FAILURE,
} from '../action/types';

const initialState = {
  data: [],
  error: '',
};

const completionStatusIntialState = {
  data: [],
  error: '',
};

export const DevelopmentProjectListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVELOPMENT_PROJECTS_LIST_REQUEST:
      return {
        ...state,
      };
    case FETCH_DEVELOPMENT_PROJECTS_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_DEVELOPMENT_PROJECTS_LIST_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const DevelopmentProjectListByMpIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_REQUEST:
      return {
        ...state,
      };
    case FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const DevelopmentStatusListReducer = (state = completionStatusIntialState, action) => {
  switch (action.type) {
    case FETCH_DEVELOPMENT_STATUS_LIST_REQUEST:
      return {
        ...state,
      };
    case FETCH_DEVELOPMENT_STATUS_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_DEVELOPMENT_STATUS_LIST_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
