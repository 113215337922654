import { Typography, Box, Card, Dialog, DialogTitle, Grid } from '@mui/material'
import EmptyDataCard from 'Components/ReusableComponents.js/EmptyStateCard/EmptyStateCard'
import ShareIcon from '@mui/icons-material/Share'
import NoImageFound from 'asserts/images/noImageFound.jpg'
import { ImageLoader } from 'Components/ReusableComponents.js/ImageLoader'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import 'Components/SevaUpdateRefactored/ContentCarousel/ContentCarousel.css'
import { makeLogRequest } from 'utils/AuditLogs'
import { getIds, getRoles } from 'Components/ReusableComponents.js/getIds'
import { useState, useEffect } from 'react'
import { pushTag } from 'utils/gtmservice'
import Share from 'Components/ReusableComponents.js/Share/Share'
import CrossIcon from 'asserts/images/Close.svg'
import ContentCarouselButton from 'Components/SevaUpdateRefactored/ContnetCarouselButton/ContentCarouselButton'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import AddSevaEvent from '../AddSevaEvent/AddSevaEvent'
import CreateNewEvent from 'Components/SevaUpdateRefactored/AddSevaEvent/CreateNewEvent'
import AddMediaCoverage from 'Components/SevaUpdateRefactored/AddMediaCoverage/AddMediaCoverage'
import AddDevelopmentProjects from 'Components/SevaUpdateRefactored/AddDevelopmentProjects/AddDevelopmentProjects'
import { useNotificationContext } from 'utils/NotificationContext'
import { defaultErrorMessage } from 'utils/constant'
import { getIntiativesReportByInitiativeIdAndMpId } from 'store/action/ongoingSevaInitiativesList'
import UpdateInitiativeReportDetails from 'Components/SevaUpdate/AllInitiativeReports/UpdateInitiativeReportDialog'
import InitiativeDetails from 'Components/AllInitiativeReportRefactored/InitiativeDetails/initiativeDetails'
import ProjectStatus from 'Components/ReusableComponents.js/ProjectStatus'
import { getCoverImageThumbnail } from 'utils/helper'
const ContentCarousel = ({
  title,
  data,
  mySeva,
  mpId,
  mpName,
  page_title,
  sendGALog,
  MpClick,
  mpData,
  isEdit,
  mpProfileData,
  tenure,
}) => {
  useEffect(() => {
    setMPid(mpId)
  }, [mpId])

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const details = data
  const [MPid, setMPid] = useState()
  const [shareData, setShareData] = useState({})
  const [mediaShare, setMediaShare] = useState(false)
  const [showViewAll, setShowViewAll] = useState(false)
  const [eventByMpId, setEventByMpId] = useState(false)
  const [opedsByMpId, setOpedsByMpId] = useState(false)
  const [openNewEvent, setOpenNewEvent] = useState(false)
  const [openSevaEvent, setOpenSevaEvent] = useState(false)
  const [personDetails, setPersonDetails] = useState()
  const [onViewClick, setOnViewClick] = useState()
  const [onViewClickValue, setOnViewClickValue] = useState()
  const { showNotification } = useNotificationContext()
  const [addMediaTitle, setAddMediaTitle] = useState('Media Coverage')
  const [openMediaCoverage, setOpenMediaCoverage] = useState(false)
  const [openInitiativeDetailsDialog, setOpenInitiativeDetailsDialog] = useState(false)
  const [developmentProjectsByMpId, setDevelopmentProjectsByMpId] = useState(false)
  const [openDevelopmentProjects, setOpenDevelopmentProjects] = useState(false)
  const [initiativeName, setInitiativeName] = useState()
  const [ongoingDetails, setOngoingDetails] = useState()
  const [openUpdateIntiativeDialog, setOpenUpdateInitiativeDialog] = useState(false)
  const [personalDetails, setPersonalDetails] = useState()
  const ongoingSevaInitiativesListsByMpId = useSelector((state) => state?.ongoingSevaInitiativesListByMpId?.data)
  const ongoingSevaInitiativesListByMpId = ongoingSevaInitiativesListsByMpId?.initiative
  const initiativeReportDetailsByMPIdAndIntiativeId = useSelector(
    (state) => state?.initiativeReportDetailsByMPIdAndIntiativeId?.data
  )
  const userId = parseInt(getRoles())
  const [readonly, setReadOnly] = useState(false)

  useEffect(() => {
    if (userId === 3) {
      setReadOnly(true)
    }
  }, [userId])

  const defaultLimit = 10
  const colorSetter = 'light'
  const titleToValueMap = {
    'Ongoing Seva Initiatives': 1,
    'Seva Events': 2,
    'Media Coverage': 3,
    'Development Projects': 4,
    Miscellaneous: 5,
  }

  const category = {
    'Seva Events': 'seva_events',
    'Media Coverage': 'books_media',
    'Development Projects': 'dev_projects',
    Miscellaneous: 'miscellaneous',
  }

  const shareAuditLogMap = {
    'Ongoing Seva Initiatives': 'share_initiative',
    'Seva Events': 'share_event',
    'Media Coverage': 'share_books_and_media',
    'Development Projects': 'share_development_project',
  }

  const handleOpenInitiativeDetailsAuditLogMap = {
    'Ongoing Seva Initiatives': 'ongoing_seva_initiatives',
    'Seva Events': 'seva_events',
    'Media Coverage': 'books_and_media_coverage',
    'Development Projects': 'development_projects',
  }

  const viewAllMap = {
    'Seva Events': 'Seva aur Samarpan Campaign',
    'Media Coverage': 'Media Coverage of',
    'Development Projects': 'Development Projects',
    Miscellaneous: 'Miscellaneous of',
  }

  const addNewButtonMap = {
    'Seva Events': () => {
      handleOpenSevaEvent()
      postAuditLogsForButtons('add_new_seva_events')
      sendGALog('add_new', null, 'seva_events')
    },
    'Media Coverage': () => {
      handleOpenMediaCoverage('Media Coverage')
      postAuditLogsForButtons('add_new_books_and_media')
    },
    'Development Projects': () => {
      handleOpenDevelopmentProjects()
      postAuditLogsForButtons('add_new_development_projects')
    },
    Miscellaneous: () => {
      handleOpenMediaCoverage('Miscellaneous')
    },
  }

  const postAuditLogs = async (sqId, id) => {
    let requestData = {
      useridtype: getIds(),
    }
    if (title && title === 'Ongoing Seva Initiatives') {
      requestData['i_id'] = id && id
    } else if (title && title === 'Seva Events') {
      requestData['e_id'] = id && id
    } else if (title && title === 'Media Coverage') {
      requestData['bm_id'] = id && id
    } else if (title && title === 'Development Projects') {
      requestData['dp_id'] = id && id
    }

    await makeLogRequest(
      !mpName ? 'nationwide_seva_updates' : mySeva ? 'my_seva_updates' : 'mp_seva_updates',
      sqId,
      requestData
    )
  }

  const postAuditLogsForButtons = async (sqId, id, title) => {
    let requestData = {
      useridtype: getIds(),
    }

    if (!mySeva) {
      requestData['mp_id'] = mpId && mpId
    }

    if (title === 'Ongoing Seva Initiatives') {
      requestData['i_id'] = id
    } else if (title === 'Seva Events') {
      requestData['e_id'] = id
    } else if (title === 'Media Coverage') {
      requestData['bm_id'] = id
    } else if (title === 'Development Projects') {
      requestData['dp_id'] = id
    }

    await makeLogRequest(
      !mpName ? 'nationwide_seva_updates' : mySeva ? 'my_seva_updates' : 'mp_seva_updates',
      sqId,
      requestData
    )
  }

  const handleShare = (e, data, category) => {
    e.stopPropagation()
    e.preventDefault()
    setShareData(data)
    setMediaShare(true)
    let gaTagTitle
    switch (category) {
      case 'seva_events':
        gaTagTitle = data.eventTitle
        break
      case 'dev_projects':
        gaTagTitle = data.projecttitle
        break
      default:
        gaTagTitle = data.title
        break
    }
    pushTag({
      event: 'share',
      page_title,
      category,
      match: gaTagTitle,
    })
  }

  const viewAllOngoingIntiativesHandleClick = (value, data, title) => {
    sendGALog('view', null, 'ongoing')
    setShowViewAll(true)
    navigate('/SevaUpdates/viewAllOngoingInitiatives', {
      state: {
        viewAllValue: value,
        data,
        title: title,
        mpName: mpName,
        mpId: mpId,
        mySeva: mySeva,
        ongoingSevaInitiatives: true,
        tenure: tenure,
      },
    })
  }

  const viewAllhandleClick = (value, data, title) => {
    sendGALog('view', null, category[value])
    setShowViewAll(true)

    navigate('/SevaUpdates/viewAllSevaEvents', {
      state: {
        viewAllValue: value,
        data,
        title: title,
        mpName: mpName,
        mpData: mpData,
        isEdit: isEdit,
        mySeva: mySeva && true,
        mpId: mpId,
        isFromNationwide: !mySeva,
        tenure: tenure,
      },
    })
  }

  const handleCardClick = async (data) => {
    navigate('/SevaUpdates/allInitiativeReports', {
      state: {
        readonly: readonly,
        initiativeId: data?.id,
        initiativeName: data?.initiativeName,
        eventByMpId: eventByMpId,
        mpName: mpName,
        mpId: mpId,
        tenure: tenure,
      },
    })
  }

  const handleCardClickOpenInitiativeReport = async (data) => {
    await postAuditLogs('ongoing_seva_initiatives', data.id)
    setPersonalDetails(data)
    navigate('/SevaUpdates/allInitiativeReports', {
      state: {
        readonly: readonly,
        initiativeId: data?.id,
        initiativeName: data?.initiativeName,
        eventByMpId: eventByMpId,
        mpName: mpName,
        mpId: mpId,
        tenure: tenure,
      },
    })
  }

  const handleOpenSevaEvent = () => {
    if (mySeva || mpId) {
      setEventByMpId(true)
    }
    if (!ongoingSevaInitiativesListByMpId?.length) {
      setOpenNewEvent(true)
    } else {
      setOpenSevaEvent(true)
    }
  }

  const handleOpenMediaCoverage = (title) => {
    sendGALog('add_new', null, title.toLowerCase().includes('media') ? 'books_media' : 'miscellaneous')
    if (mySeva || mpId) {
      setOpedsByMpId(true)
    }
    setAddMediaTitle(title)
    setOpenMediaCoverage(true)
  }

  const handleOpenDevelopmentProjects = () => {
    sendGALog('add_new', null, 'dev_projects')
    if (mySeva || mpId) {
      setDevelopmentProjectsByMpId(true)
    }
    setOpenDevelopmentProjects(true)
  }

  const handleCloseNewEvent = () => {
    setOpenNewEvent(false)
  }

  const handleCloseSevaEvent = () => {
    setOpenSevaEvent(false)
  }

  const handleCloseInitiativeDetails = () => {
    setOpenInitiativeDetailsDialog(false)
  }

  const handleCloseMediaCoverage = (reset, setImages, setFiles) => {
    reset()
    setImages([])
    setFiles([])
    setOpenMediaCoverage(false)
    setOpedsByMpId(false)
  }

  const handleCloseDevelopmentProjects = (reset, setFiles, setUploadFiles, setTargetDate) => {
    reset()
    setFiles([])
    setUploadFiles([])
    setTargetDate(null)
    setOpenDevelopmentProjects(false)
    setDevelopmentProjectsByMpId(false)
  }

  const handleCloseUpdateInitiativeDetails = (reset, reportFiles, setUploadFiles) => {
    reset()
    reportFiles([])
    setUploadFiles([])
    setOpenUpdateInitiativeDialog(false)
  }

  const handleOpenInitiativeDetails = async (item, title, isOngoingSevaClick) => {
    await postAuditLogsForButtons(handleOpenInitiativeDetailsAuditLogMap[title], item.id, title)
    if (isOngoingSevaClick) {
      try {
        const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(item?.id, mpId))
        if (response.status === 200 || response.status === 201) {
          if (response.data?.message) {
            showNotification('Error', response.data?.message, 'error')
          } else if (response?.data?.reportdata.length === 0) {
            handleCardClick(item)
          } else {
            setPersonDetails(item)
            setOnViewClick(title)
            setOnViewClickValue(titleToValueMap[title])
            setOpenInitiativeDetailsDialog(true)
          }
        }
      } catch (error) {
        showNotification('Error', defaultErrorMessage)
      }
    } else {
      setPersonDetails(item)
      setOnViewClick(title)
      setOnViewClickValue(titleToValueMap[title])
      setOpenInitiativeDetailsDialog(true)
    }
  }

  const handleOpenInitiativeReport = async (dataVal) => {
    await postAuditLogs(handleOpenInitiativeDetailsAuditLogMap[title], dataVal.id)
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(dataVal?.id, mpId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          showNotification('Error', response.data?.message, 'error')
        } else if (response?.data?.reportdata.length === 0) {
          handleCardClickOpenInitiativeReport(dataVal)
        } else {
          setInitiativeName(dataVal?.initiativeName)
          setOpenUpdateInitiativeDialog(true)
          setOngoingDetails(dataVal)
        }
      }
    } catch (error) {
      showNotification('Error', error, 'error')
    }
  }

  return (
    <Box>
      {title === 'Ongoing Seva Initiatives' ? (
        <Box className="box-title d-flex justify-content-between align-items-center">
          <Box>
            <Typography
              sx={{
                fontSize: '20px',
                fontWeight: 'bold',
                fontFamily: 'HK Grotesk',
                color: '#2C2C2C',
                marginLeft: '38px',
              }}
            >
              {tenure !== 'Modi 3.0' && title === 'Ongoing Seva Initiatives' ? 'Seva Initiatives' : title}
            </Typography>
          </Box>
          <Box className="view-btn-pos">
            {data?.length > 3 && (
              <ContentCarouselButton
                onClick={() => viewAllOngoingIntiativesHandleClick(title, data, title)}
                buttonTitle={'View All'}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Grid container sx={userId === 2 && readonly && { marginLeft: '38px' }}>
          <Grid item xs={userId !== 2 && !readonly ? 7.5 : 9.5}>
            <Box>
              <Typography
                sx={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  fontFamily: 'HK Grotesk',
                  color: '#2C2C2C',
                  marginLeft: '38px',
                }}
              >
                {title}
              </Typography>
            </Box>
          </Grid>
          <Box className={data?.length < 3 ? 'consitioncss1' : 'anotwe'}>
            <Box>
              {userId !== 2 && !readonly && !MpClick && tenure === 'Modi 3.0' && (
                <ContentCarouselButton onClick={addNewButtonMap[title]} buttonTitle={'Add New'} />
              )}
            </Box>
            <Box>
              {data?.length > 3 && (
                <ContentCarouselButton
                  onClick={() => (
                    viewAllhandleClick(title, data, viewAllMap[title]),
                    title === 'Media Coverage' && postAuditLogsForButtons('view_all_books_and_media'),
                    title === 'Development Projects' && postAuditLogsForButtons('view_all_development_projects')
                  )}
                  buttonTitle={'View All'}
                />
              )}
            </Box>
          </Box>
        </Grid>
      )}

      <Box className="row-margin">
        {details == null || !details || details.length < 1 ? (
          <EmptyDataCard cardFor={title} />
        ) : (
          <Box className="itemfixed3">
            <Box>
              <Tabs
                variant="scrollable"
                scrollButtons
                aria-label="visible arrows tabs example"
                sx={{
                  [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                  },
                }}
              >
                {details &&
                  details?.map((dataVal, index) => (
                    <Card
                      className="clickable cursorshow"
                      sx={{ minWidth: 235, mr: 3, mb: 3, borderRadius: '15px' }}
                      onClick={() => {
                        title === 'Ongoing Seva Initiatives'
                          ? mpName
                            ? mySeva
                              ? handleOpenInitiativeReport(dataVal)
                              : handleOpenInitiativeDetails(dataVal, title, true)
                            : handleCardClickOpenInitiativeReport(dataVal)
                          : handleOpenInitiativeDetails(dataVal, title)
                      }}
                    >
                      <Box className="cardWrapper">
                        <ImageLoader
                          src={
                            title === 'Ongoing Seva Initiatives' || title === 'Seva Events'
                              ? dataVal?.coverimage && JSON.parse(dataVal?.coverimage)[0]
                              : getCoverImageThumbnail(dataVal)
                          }
                          sx={{
                            borderRadius: '15px',
                            background:
                              'transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%) 0% 0% no-repeat padding-box',
                          }}
                          loading="lazy"
                          height="150"
                          width="240"
                          id={index}
                          onError={(e) => (e.target.src = NoImageFound)}
                          className="blackoverlay sava-update--card-img__wrapper"
                        />
                        {title !== 'Ongoing Seva Initiatives' && (
                          <ShareIcon
                            className="shareicone customshare"
                            key={index}
                            onClick={(e) => {
                              postAuditLogs(shareAuditLogMap[title], dataVal?.id)
                              handleShare(e, dataVal, category[title])
                            }}
                          />
                        )}
                        <Box className="dibgover mgdiv"></Box>
                        <Box className="ecclipseonwidthnewrow1 title-text-st">
                          <Box className="para-wrapper">
                            <Typography
                              component="body1"
                              sx={{ lineHeight: '1.5' }}
                              className={`ecclipseonwidthnewrow`}
                            >
                              {' '}
                              {title === 'Ongoing Seva Initiatives'
                                ? dataVal?.initiativeName
                                : title === 'Seva Events'
                                ? dataVal?.eventTitle
                                : title === 'Media Coverage' || title == 'Miscellaneous'
                                ? dataVal?.title
                                : dataVal?.projecttitle}
                            </Typography>
                          </Box>
                          <Typography component="span" className="sp-st" sx={{ lineHeight: '1.5' }}>
                            {title === 'Ongoing Seva Initiatives' ? null : title === 'Seva Events' ? (
                              dataVal?.location.length > 25 ? (
                                dataVal?.location.substring(0, 25) + '...'
                              ) : (
                                dataVal?.location
                              )
                            ) : title === 'Media Coverage' || title == 'Miscellaneous' ? (
                              dataVal?.type
                            ) : (
                              <ProjectStatus status={dataVal?.status} colorSetter={colorSetter} />
                            )}
                          </Typography>
                        </Box>
                        <Box></Box>
                      </Box>
                    </Card>
                  ))}
              </Tabs>
              <Dialog open={mediaShare} onClose={() => setMediaShare(false)}>
                <DialogTitle>
                  <img onClick={() => setMediaShare(false)} src={CrossIcon} className="closeIcon" />
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      color: '#357092',
                      fontFamily: 'HK Grotesk',
                      fontSize: '26px',
                      fontWeight: 'bold',
                    }}
                  >
                    Share to Social Media
                  </Typography>

                  <Box className="share-st">
                    <Share data={shareData} title={title} />
                  </Box>
                </DialogTitle>
              </Dialog>
              {openUpdateIntiativeDialog && (
                <UpdateInitiativeReportDetails
                  openUpdateIntiativeDialog={openUpdateIntiativeDialog}
                  details={ongoingDetails}
                  initiativeName={initiativeName}
                  handleCloseUpdateInitiativeDetails={handleCloseUpdateInitiativeDetails}
                  initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
                  tenure={tenure}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
      <Box className="d-none">
        {openMediaCoverage && (
          <AddMediaCoverage
            openMediaCoverage={openMediaCoverage}
            handleCloseMediaCoverage={handleCloseMediaCoverage}
            onViewClickTitle={addMediaTitle}
            onViewClickTitleValue={titleToValueMap[addMediaTitle]}
            opedsByMpId={opedsByMpId}
            mpId={mySeva ? getIds() : mpId}
            limit={defaultLimit}
          />
        )}

        {openDevelopmentProjects && (
          <AddDevelopmentProjects
            openDevelopmentProjects={openDevelopmentProjects}
            handleCloseDevelopmentProjects={handleCloseDevelopmentProjects}
            developmentProjectsByMpId={developmentProjectsByMpId}
            mpId={mySeva ? getIds() : mpId}
            limit={defaultLimit}
          />
        )}
        {/* {openSevaEvent && ( */}
        <AddSevaEvent
          openSevaEvent={openSevaEvent}
          handleCloseSevaEvent={handleCloseSevaEvent}
          eventByMpId={eventByMpId}
          mpId={mySeva ? getIds() : mpId}
          limit={defaultLimit}
        />
        {/* )} */}

        {openNewEvent && (
          <CreateNewEvent
            openCreateEventDialog={openNewEvent}
            handleCloseCreateEvent={handleCloseNewEvent}
            eventByMpId={eventByMpId}
            mpId={mySeva ? getIds() : mpId}
            limit={defaultLimit}
          />
        )}

        {openInitiativeDetailsDialog && (
          <InitiativeDetails
            handleCloseInitiativeDetails={handleCloseInitiativeDetails}
            openInitiativeDetailsDialog={openInitiativeDetailsDialog}
            details={personDetails}
            onViewClickTitle={onViewClick}
            onViewClickTitleValue={onViewClickValue}
            mpName={mpName}
            readonly={readonly}
            mpId={MPid}
            isEdit={isEdit}
            MpClick={MpClick}
            mpProfileData={mpProfileData}
            initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
            checkMySeva={mpId || mySeva ? true : false}
            setDevelopmentProjectsByMpId={setDevelopmentProjectsByMpId}
            setOpedsByMpId={setOpedsByMpId}
            setEventByMpId={setEventByMpId}
            opedsByMpId={opedsByMpId}
            developmentProjectsByMpId={developmentProjectsByMpId}
            eventByMpId={eventByMpId}
            limit={defaultLimit}
            tenure={tenure}
          />
        )}
      </Box>
    </Box>
  )
}

export default ContentCarousel
