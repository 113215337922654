import {
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_REQUEST,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_SUCCESS,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_FAILURE,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_REQUEST,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_SUCCESS,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_FAILURE,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_REQUEST,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_SUCCESS,
  FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_FAILURE,
  FETCH_INITIATIVE_REPORT_BY_ID_LIST_REQUEST,
  FETCH_INITIATIVE_REPORT_BY_ID_LIST_SUCCESS,
  FETCH_INITIATIVE_REPORT_BY_ID_LIST_FAILURE,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_REQUEST,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_SUCCESS,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_FAILURE,
  FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_REQUEST,
  FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_SUCCESS,
  FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_FAILURE,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_REQUEST,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_SUCCESS,
  FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_FAILURE,
  FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_REQUEST,
  FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_SUCCESS,
  FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_FAILURE,
} from '../action/types'

const initialState = {
  data: [],

  error: '',
}

const socialMediaInitiativeState = {
  data: {},
  error: '',
}

const initialStateWithID = {
  data: {},
  loading: false,
  error: '',
}

const initialStateForInitiativeReportBy = {
  data: {},
  loading: false,
  error: '',
}

const initialStateForInitiativeReportByInitiativeId = {
  data: {},
  loading: false,
  error: '',
}

const initialStateForInitiativeEventByInitiativeId = {
  data: {},
  loading: false,
  error: '',
}

const initialStateForInitiativeByMpIdAndInitiativeID = {
  data: {},
  loading: false,
  error: '',
}

export const OngoingSevaInitiativesListWIthInitiativeIdReducer = (state = initialStateWithID, action) => {
  switch (action.type) {
    case FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      }
    case FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_FAILURE:
      return {
        data: [],
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export const OngoingSevaInitiativesListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ONGOING_SEVA_INITIATIVES_LIST_REQUEST:
      return {
        ...state,
      }
    case FETCH_ONGOING_SEVA_INITIATIVES_LIST_SUCCESS:
      return {
        data: action.payload,
      }
    case FETCH_ONGOING_SEVA_INITIATIVES_LIST_FAILURE:
      return {
        data: [],
        error: action.payload,
      }
    default:
      return state
  }
}

export const OngoingSevaInitiativesListByMpIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_REQUEST:
      return {
        ...state,
      }
    case FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_SUCCESS:
      return {
        data: action.payload,
      }
    case FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_FAILURE:
      return {
        data: [],
        error: action.payload,
      }
    default:
      return state
  }
}

export const OngoingSevaInitiativesReportsByIdReducer = (state = initialStateForInitiativeReportBy, action) => {
  switch (action.type) {
    case FETCH_INITIATIVE_REPORT_BY_ID_LIST_REQUEST:
      return {
        ...state,
      }
    case FETCH_INITIATIVE_REPORT_BY_ID_LIST_SUCCESS:
      return {
        data: action.payload,
      }
    case FETCH_INITIATIVE_REPORT_BY_ID_LIST_FAILURE:
      return {
        data: {},
        error: action.payload,
      }
    default:
      return state
  }
}

export const OngoingSevaInitiativesReportsByInitiativeIdAndMpIdReducer = (
  state = initialStateForInitiativeByMpIdAndInitiativeID,
  action
) => {
  switch (action.type) {
    case FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_REQUEST:
      return {
        ...state,
      }
    case FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_SUCCESS:
      return {
        data: action.payload,
      }
    case FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_FAILURE:
      return {
        data: {},
        error: action.payload,
      }
    default:
      return state
  }
}

export const SocialMediaInitiativeByIdReducer = (state = socialMediaInitiativeState, action) => {
  switch (action.type) {
    case FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_REQUEST:
      return {
        ...state,
      }
    case FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_SUCCESS:
      return {
        data: action.payload,
      }
    case FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_FAILURE:
      return {
        data: {},
        error: action.payload,
      }
    default:
      return state
  }
}

export const getIntiativesReportByInitiativeIdReducer = (
  state = initialStateForInitiativeReportByInitiativeId,
  action
) => {
  switch (action.type) {
    case FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_REQUEST:
      return {
        ...state,
      }
    case FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_SUCCESS:
      return {
        data: action.payload,
      }
    case FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_FAILURE:
      return {
        data: {},
        error: action.payload,
      }
    default:
      return state
  }
}

export const getIntiativesEventByInitiativeIdReducer = (
  state = initialStateForInitiativeEventByInitiativeId,
  action
) => {
  switch (action.type) {
    case FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_REQUEST:
      return {
        ...state,
      }
    case FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_SUCCESS:
      return {
        data: action.payload,
      }
    case FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_FAILURE:
      return {
        data: {},
        error: action.payload,
      }
    default:
      return state
  }
}
