import {
  FETCH_MASTERLIST_FAILURE,
  FETCH_MASTERLIST_REQUEST,
  FETCH_MASTERLIST_SUCCESS,
  UPDATE_MASTERLIST_SUCCESS,
} from './types'
import axiosInstance from '../../utils/api'

export const fetchMasterListRequest = () => {
  return {
    type: FETCH_MASTERLIST_REQUEST,
  }
}
export const fetchMasterListSuccess = (value) => {
  return {
    type: FETCH_MASTERLIST_SUCCESS,
    payload: value,
  }
}
export const fetchMasterListFailure = (error) => {
  return {
    type: FETCH_MASTERLIST_FAILURE,
    payload: error,
  }
}
export const updateMasterListSuccess = (value) => {
  return {
    type: UPDATE_MASTERLIST_SUCCESS,
    payload: value,
  }
}

export const getMasterList =
  (page, limit, searchText = '') =>
  async (dispatch) => {
    const payload = { searchText: searchText ? searchText : '' }
    dispatch(fetchMasterListRequest)
    const url = `/api/admin/master/list/?page=${page}&limit=${limit}`

    page && page !== 1
      ? await axiosInstance
          .post(url, payload)
          .then((response) => {
            const result = response.data.data
            dispatch(updateMasterListSuccess(result))
          })
          .catch((error) => {
            const errorMsg = error.message
            dispatch(fetchMasterListFailure(errorMsg))
          })
      : await axiosInstance
          .post(url, payload)
          .then((response) => {
            const result = response.data.data
            dispatch(fetchMasterListSuccess(result))
          })
          .catch((error) => {
            const errorMsg = error.message
            dispatch(fetchMasterListFailure(errorMsg))
          })
  }
