import { Box, Paper, Typography } from '@mui/material'
import NotFoundImg from '../../../asserts/images/searchNotFound.png'
import { useSelector, useDispatch } from 'react-redux'
import { useMemo } from 'react'
import './NoRecordFoundComponent.css'
import { getRoles } from '../../ReusableComponents.js/getIds'
import { useLocation } from 'react-router-dom'
import { useNotificationContext } from '../../../utils/NotificationContext'
import { defaultErrorMessage } from '../../../utils/constant'
import { getIntiativesReportByInitiativeIdAndMpId } from '../../../store/action/ongoingSevaInitiativesList'
import { getIds } from '../../ReusableComponents.js/getIds'

const NoRecordFoundComponent = ({
  viewAllTitle,
  viewAllTitleMap,
  setOpenNewEvent,
  setOpenSevaEvent,
  setAddMediaTitle,
  setOpenMediaCoverage,
  setOpenDevelopmentProjects,
  setOpenInitiativeReportDialog,
  setOpenUpdateInitiativeDialog,
  setOngoingDetails,
  setInitiativeName,
}) => {
  const userId = parseInt(getRoles())
  const dispatch = useDispatch()
  const showNotification = useNotificationContext()
  const location = useLocation()
  const ongoingSevaInitiativesListByMpId = useSelector(
    (state) => state?.ongoingSevaInitiativesListByMpId?.data?.initiative
  )
  const InitiativeReports = location?.state?.InitiativeReports
  const selectedOngoingSevaInitiativeDetails = location?.state?.selectedOngoingSevaInitiativeDetails

  const noRecordStyles = useMemo(
    () => ({
      mt: 4,
      ml: 1,
      height: '60vh',
      borderRadius: 6,
    }),
    []
  )

  const fetchInitiativeDetailsData = async (data) => {
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(data?.id, getIds()))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          showNotification('Error', response.data?.message, 'error')
        } else {
          const obj = response?.data?.reportdata
          if (!Array.isArray(obj)) {
            setInitiativeName(data?.initiativeName)
            setOngoingDetails(data)
            setOpenUpdateInitiativeDialog(true)
          } else {
            setOpenInitiativeReportDialog(true)
          }
        }
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    }
  }

  const handleNoRecord = (e) => {
    switch (viewAllTitleMap[viewAllTitle]) {
      case 2:
        if (!ongoingSevaInitiativesListByMpId?.length) {
          setOpenNewEvent(true)
        } else {
          setOpenSevaEvent(true)
        }
        break
      case 3:
        setAddMediaTitle('Media Coverage')
        setOpenMediaCoverage(true)
        break
      case 5:
        setAddMediaTitle('Miscellaneous')
        setOpenMediaCoverage(true)
        break
      case 4:
        setOpenDevelopmentProjects(true)
        break
      case 6:
        fetchInitiativeDetailsData(selectedOngoingSevaInitiativeDetails)
        break
      case 7:
        setOpenNewEvent(true)
        break
      default:
        break
    }
  }

  return (
    <Box sx={{ minWidth: '80vw' }}>
      <Paper className="d-flex flex-column" sx={noRecordStyles}>
        <img className="imagecenter image-center-width-height" src={NotFoundImg} alt="" />
        <Box className="viewAll-NoRecord">
          <Typography variant="span" className="viewAll-span">
            No results found :(
          </Typography>
        </Box>
        {userId !== 2 && userId !== 3 ? (
          <Box className="viewAll-NoRecord-add cursorshow">
            {viewAllTitleMap[viewAllTitle] !== 1 && (
              <Typography
                variant="span"
                className="viewAll-NoRecord-add-span font-family-weight"
                onClick={handleNoRecord}
              >
                Click here to create {InitiativeReports ? 'an' : 'a'}{' '}
                {['Seva Events', 'Development Projects'].includes(viewAllTitle)
                  ? viewAllTitle.slice(0, -1)
                  : ['Miscellaneous', 'Media Coverage'].includes(viewAllTitle)
                  ? viewAllTitle
                  : InitiativeReports
                  ? 'Initiative report for this Initiative'
                  : 'Seva Event'}
              </Typography>
            )}
          </Box>
        ) : (
          <Box />
        )}
      </Paper>
    </Box>
  )
}

export default NoRecordFoundComponent
