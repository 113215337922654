import { Box, Typography, Collapse, CardContent, Divider } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import ShareIcon from '@mui/icons-material/Share'
import NoImageFound from '../../../../asserts/images/noImageFound.jpg'
import '../InitiativesDetails.css'
import './YourEvents.css'

function YourEvents({
  ExpandMore,
  ExpandMoreIcon,
  initiativeEvents,
  yourEventsExpand,
  handleYourEventsExpandClick,
  postAuditLogs,
  handleEdit,
  handleShare,
}) {
  return (
    <>
      <Box className="d-flex justify-content-between align-items-center pr-4">
        <Box className="ur-evnt-init-dtls">Your Events </Box>

        <Box className="evnt-init-dtls">
          {`${initiativeEvents?.length} ${initiativeEvents?.length === 1 ? 'Event' : 'Events'}`}
          <ExpandMore
            expand={yourEventsExpand}
            onClick={handleYourEventsExpandClick}
            aria-expanded={yourEventsExpand}
            aria-label="show more"
          >
            <ExpandMoreIcon
              sx={{
                background: '#E3F5FF',
                color: '#356F92',
                borderRadius: '50%',
              }}
            />
          </ExpandMore>
        </Box>
      </Box>
      <Collapse in={yourEventsExpand} timeout="auto" unmountOnExit>
        {initiativeEvents.length > 0 ? (
          <CardContent className="gridyour" sx={{ mb: '-15px!important' }}>
            {initiativeEvents?.map((item) => (
              <Box>
                <Box class="d-flex">
                  <Box
                    class="event-card event-card-style"
                    onClick={() => postAuditLogs('event_card', { e_id: item?.id })}
                  >
                    <Box class="">
                      <Box>
                        <img
                          className="imagesquare"
                          src={item?.coverimage && JSON.parse(item?.coverimage)[0]}
                          alt=""
                          onError={(e) => (e.target.src = NoImageFound)}
                          style={{ filter: 'brightness(50%)' }}
                        />
                      </Box>
                    </Box>

                    <Box
                      className="ecclipseonwidthnewrow1 image-square-event-details"
                      style={{ top: item?.eventTitle?.length < 30 ? 117 : 94 }}
                    >
                      <Typography
                        variant="body1"
                        className="ecclipseonwidthnewrow event-title-location event-title-fontsize"
                      >
                        {item?.eventTitle}
                      </Typography>
                      <Typography
                        variant="span"
                        className="ecclipseonwidthnewrow event-title-location event-location-fontsize"
                        sx={{ WebkitLineClamp: 1 }}
                      >
                        {item?.location}{' '}
                      </Typography>
                    </Box>
                    <Box className="cursorshow edit-share-icon">
                      <Box sx={{ display: 'flex', ml: '-5px' }}>
                        <Typography variant="body1" className="oparditini">
                          <EditIcon
                            onClick={async () => {
                              await postAuditLogs('edit_event', { e_id: item.id })
                              handleEdit(item)
                            }}
                            sx={{ width: '15px', mt: '-28px', ml: '-7px' }}
                          />
                          <ShareIcon
                            className="shareicone customshare ml-2 your-event__card-icon"
                            onClick={(e) => {
                              handleShare(e, item)
                              postAuditLogs('share_event', { e_id: item.id })
                            }}
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </CardContent>
        ) : (
          <Typography variant="body1" className="yourEvent-text">
            There are no events created under this initiative
          </Typography>
        )}
      </Collapse>

      <Divider className="cover-divider"></Divider>
    </>
  )
}
export default YourEvents
