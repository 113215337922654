import { Typography, Divider, Box, Menu } from '@mui/material'
import SortOutlinedIcon from '@mui/icons-material/SortOutlined'
import { useState } from 'react'
import { getIds, getRoles } from '../../ReusableComponents.js/getIds'
import { getAllInitiative } from '../../../store/action/allInitiatives'
import CardHeaderFilterOption from './CardHeaderFilterOption'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { useDispatch } from 'react-redux'
import { useInitiativeContext } from '../../../utils/InitiativeContext'
import './CardHeader.css'
import SearchIcon from './SearchIcon'
const mpUserId = getRoles()
const postAuditLogs = async (sqId, data = {}, flag = 'your_initiatives_page') => {
  data.user_role = getRoles() == 2 ? 'admin' : getRoles() == 3 ? 'leadership' : 'mp'
  data.useridtype = mpUserId
  await makeLogRequest(flag, sqId, data)
}
function InitiativeDetailCardHeader({ setSearchStatus }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [hideSearch, setHideSearch] = useState(false)
  const [open, setOpen] = useState(false)
  const loggedInId = getIds()
  const dispatch = useDispatch()
  const { setResetInitiatives } = useInitiativeContext()

  const handleOpen = async (event) => {
    setAnchorEl(event?.currentTarget)

    setOpen(true)
    setSearchStatus(false)
    setHideSearch(false)
    await postAuditLogs('sort_initiative')
    await postAuditLogs('pageload', {}, 'sort_initiative')
  }
  const handleClose = (event) => {
    setAnchorEl(event?.currentTarget)
    setOpen(false)
  }

  const itemData = [
    {
      name: 'All',
      value: 'All',
    },
    {
      name: 'Requested',
      value: 'Requested',
    },
    {
      name: 'Ongoing',
      value: 'Ongoing',
    },
    {
      name: 'Report Pending',
      value: 'Report_Pending',
    },
    {
      name: 'Completed',
      value: 'Completed',
    },
  ]

  const [selectedFilter, setSelectedFilter] = useState(itemData[0]?.value ?? '')

  const handleFilter = async (value) => {
    let statusValue = value
    if (value === 'All') {
      statusValue = ''
    }

    setSelectedFilter(value)
    dispatch(getAllInitiative(loggedInId, statusValue))
    setAnchorEl(null)
    setOpen(false)
    await postAuditLogs('select', { initiative_status: value }, 'sort_initiative')
  }

  const handleAllInitiatives = () => {
    setSearchStatus(false)
    setSelectedFilter(itemData[0]?.value ?? '')
    setHideSearch(false)
    setResetInitiatives(false)
    dispatch(getAllInitiative(loggedInId))
  }
  return (
    <Box className="design">
      <Typography variant="span" className="header-design cursorshow table-header" onClick={handleAllInitiatives}>
        All Initiatives <Divider className="all-initiatives-heading-underline"></Divider>
      </Typography>
      <Box className="d-flex align-items-center mr-3 ">
        <Box className="menuItemForSort cursorshow hamburger-filter-menu">
          <SortOutlinedIcon className="sort-styling" onClick={handleOpen} />

          <Box className="checksort">
            <Menu
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              anchorEl={anchorEl}
              value={selectedFilter}
              open={open}
              onClose={handleClose}
              PaperProps={{
                sx: {
                  width: '320px',
                  height: '260px',
                  maxHeight: '260px !important',
                  borderRadius: '14px',
                  fontFamily: 'HK Grotesk',
                  left: '0!important',
                  right: ' 0!important',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,

                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 150,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                  '& .MuiMenuItem-root': {
                    fontFamily: 'HK Grotesk',
                    border: '1px solid #D3D3D3',
                    margin: '10px 20px 0',
                    borderRadius: '10px',
                    justifyContent: 'center',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    '&$selected': {
                      backgroundColor: '#356F92',
                      '&:hover': {
                        backgroundColor: '#356F92',
                      },
                    },
                    '&:hover': {
                      backgroundColor: '#356F92',
                      color: '#fff',
                    },
                  },
                },
              }}
            >
              <Typography variant="body1" className="filter-by">
                Filter By:
              </Typography>

              {itemData.map((data, index) => {
                return (
                  <CardHeaderFilterOption
                    key={index}
                    Item={data.name}
                    selectedFilter={selectedFilter}
                    handleFilter={handleFilter}
                    menuItem={data.value}
                  />
                )
              })}
            </Menu>
          </Box>
        </Box>
        <SearchIcon
          setSearchStatus={setSearchStatus}
          setHideSearch={setHideSearch}
          hideSearch={hideSearch}
          handleAllInitiatives={handleAllInitiatives}
          setSelectedFilter={setSelectedFilter}
          itemData={itemData}
        />
      </Box>
    </Box>
  )
}
export default InitiativeDetailCardHeader
