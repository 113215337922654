import {
  Box,
  Typography,
  Button,
  Tabs,
  Card,
  tabsClasses,
  CardMedia,
  CardContent,
  Divider,
  Dialog,
  DialogTitle,
} from '@mui/material'
import './EventsUnderInitiative.css'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import EmptyDataCard from '../../ReusableComponents.js/EmptyStateCard'
import ShareIcon from '@mui/icons-material/Share'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import NoImageFound from '../../../asserts/images/noImageFound.jpg'
import Moment from 'moment'
import EventDetailsDialog from '../EventDetailsDialog/EventDetailsDialog'
import InitiativeDetails from '../InitiativeDetails/initiativeDetails'
import Share from '../../ReusableComponents.js/Share'
import CrossIcon from '../../../asserts/images/Close.svg'
import { useEffect } from 'react'
import { getIntiativesReportByIdList } from '../../../store/action/ongoingSevaInitiativesList'
import { getIds } from '../../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { useMemo } from 'react'
function EventsUnderInitiative({
  value,
  handleChange,
  openInitiativeDetailsDialog,
  personDetails,
  setOpenInitiativeDetailsDialog,
  tenure,
}) {
  const [mediaShare, setMediaShare] = useState(false)
  const [shareData, setShareData] = useState({})
  const [openEventDetailsDialog, setOpenEventDetailsDialog] = useState(false)
  const [eventDetailsData, setEventDetailsData] = useState()
  const initiativeReportDetailsByMPIdAndIntiativeId = useSelector(
    (state) => state?.initiativeReportDetailsByMPIdAndIntiativeId?.data
  )
  const [eventDeleted, setEventDeleted] = useState(false)

  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const initiativeEventData = useSelector((state) => state?.intiativesEventByInitiativeId?.data)

  const location = useLocation()
  const navigate = useNavigate()
  let initiativeId = location?.state?.initiativeId
  let mpName = location?.state?.mpName
  let mpId = location?.state?.mpId
  let eventByMpId = location?.state?.eventByMpId
  let user = location?.state?.user
  let initiativeName = location?.state?.initiativeName
  let dispatch = useDispatch()
  const handleShare = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    setShareData(data)
    setMediaShare(true)
  }
  const handleOpenEventDetailsDialog = (data) => {
    setEventDetailsData(data)
    setOpenEventDetailsDialog(true)
  }

  const handleCloseEventDetailsDialog = () => {
    setOpenEventDetailsDialog(false)
  }

  const handleCloseInitiativeDetails = () => {
    setOpenInitiativeDetailsDialog(false)
  }

  const handleViewAllEvents = () => {
    navigate('/SevaUpdates/viewAllSevaEvents', {
      state: {
        viewAllValue: 'Initiative Events',
        data: initiativeEventData?.Eventdata,
        title: initiativeName,
        eventsForInitiative: true,
        initiativeId: initiativeId,
        mpName: mpName,
        tenure: tenure,
      },
    })
  }
  const postAuditLogs = async (sqId, i_id, report_id, e_id) => {
    let requestData = {
      useridtype: getIds(),
    }
    if (i_id !== null) {
      requestData['i_id'] = i_id && i_id
    } else if (report_id !== null) {
      requestData['report_id'] = report_id
    } else if (e_id !== null) {
      requestData['e_id'] = e_id
    }
    await makeLogRequest('individual_initiatives_wall', sqId, requestData)
  }

  useEffect(() => {
    if (eventDeleted) {
      dispatch(getIntiativesReportByIdList(initiativeId))
    }
  }, [eventDeleted])

  const ongoingSevaIntiativesReports = useMemo(() => {
    return initiativeEventData?.Eventdata == null || initiativeEventData?.Eventdata?.length < 1
  }, [initiativeEventData])

  return (
    <>
      <Box className="d-flex justify-content-between mt-4 pr-4">
        <Typography variant="h3" className="cmn-text evnts-under-init">
          Events under this Initiative
        </Typography>
        {initiativeEventData?.Eventdata?.length > 4 && (
          <Box>
            <Button className="button-tr-citizen" onClick={handleViewAllEvents}>
              View All
            </Button>
          </Box>
        )}
      </Box>
      {ongoingSevaIntiativesReports ? (
        <EmptyDataCard cardFor="Initiative Event" />
      ) : (
        <Box className="d-flex justify-content-between leaders-cards horizontal-padding">
          <Box
            sx={{
              flexGrow: 1,
              minWidth: { xs: 920, sm: 1080 },
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
              aria-label="visible arrows tabs example"
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              {initiativeEventData &&
                initiativeEventData?.Eventdata?.map((item, index) => (
                  <Card
                    className="cursorshow allInitiative-reports__event-card--wrapper"
                    sx={{
                      mr: 3,
                      mb: 3,
                      borderRadius: '15px',
                      marginRight: '16px',
                    }}
                    onClick={() => (
                      handleOpenEventDetailsDialog(item), postAuditLogs('event_card', null, null, item?.id)
                    )}
                  >
                    <Box className="position-relative">
                      <ShareIcon
                        className="shareicone3"
                        key={index}
                        onClick={(e) => {
                          handleShare(e, item)
                          postAuditLogs('share_event', null, null, item?.id)
                        }}
                      />
                      <CardMedia
                        sx={{
                          filter: 'brightness(50%)',
                          maxWidth: '310px!important',
                        }}
                        id={index}
                        component="img"
                        loading="lazy"
                        height="205"
                        onError={(e) => (e.target.src = NoImageFound)}
                        src={item?.coverimage && JSON.parse(item?.coverimage)[0]}
                        alt="new Image"
                        className="blackoverlay"
                      />

                      <CardContent sx={{ minHeight: '165px', maxHeight: '153px', padding: '10px' }}>
                        <Box className="cmn-text event-title"> {item?.eventTitle}</Box>
                        <Box className="event-date">Date : {Moment(item?.statTime).utc().format('DD/MM/YYYY')}</Box>

                        <Divider className="saperating-hr"></Divider>
                        <Box className="d-flex os-text">
                          {item?.user?.user_avatar ? (
                            <Box className="text-center pt-3">
                              <CardMedia
                                component="img"
                                src={item?.user?.user_avatar}
                                className="img-circle leader-circle-img mr-1 circularimage2 mp-avatar"
                              />
                            </Box>
                          ) : (
                            <AccountCircleIcon
                              sx={{
                                fontSize: 'xx-large',
                                width: '50px',
                                height: 'auto',
                                border: 0,
                                marginLeft: '15px',
                                marginTop: '-14px',
                                marginBottom: '5px',
                                color: '#EEEEEE',
                              }}
                            />
                          )}
                          <Box>
                            <Box className="cmn-text mp-uname">{item?.user?.user_name}</Box>
                            <Box className="mp-post">{item?.user?.designation}</Box>
                            <Box className="mp-post" title={item?.user?.party ? item?.user?.party : ''}>
                              {(item?.user?.party && item?.user?.party.slice(0, 24)) || '-'}
                              {item?.user?.party && item?.user?.party.length > 24 ? '...' : ''}
                            </Box>
                            <Box className="mp-post mp-state-btm">
                              {item?.user?.constituency_name}, {item?.user?.state_name}
                            </Box>
                          </Box>
                        </Box>
                      </CardContent>
                    </Box>
                  </Card>
                ))}
            </Tabs>
          </Box>
        </Box>
      )}
      <Box className="d-none">
        {openInitiativeDetailsDialog && (
          <InitiativeDetails
            handleCloseInitiativeDetails={handleCloseInitiativeDetails}
            openInitiativeDetailsDialog={openInitiativeDetailsDialog}
            details={personDetails}
            uniqueInitiativeClick={true}
            initiativeName={initiativeName}
            mpProfileData={mpProfileData}
            initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
            user={user}
          />
        )}
        {openEventDetailsDialog && (
          <EventDetailsDialog
            openEventDetailsDialog={openEventDetailsDialog}
            handleCloseEventDetailsDialog={handleCloseEventDetailsDialog}
            details={eventDetailsData}
            setEventDeleted={setEventDeleted}
            mpProfileData={mpProfileData}
            eventByMpId={eventByMpId}
            initiativeId={initiativeId}
            user={user}
            tenure={tenure}
          />
        )}
      </Box>
      <Dialog open={mediaShare} onClose={() => setMediaShare(false)}>
        <DialogTitle>
          <img onClick={() => setMediaShare(false)} src={CrossIcon} className="closeIcon" />
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              color: '#357092',
              fontFamily: 'HK Grotesk',
              fontSize: '26px',
              fontWeight: 'bold',
            }}
          >
            Share to Social Media
          </Typography>

          <Box className="d-flex justify-content-center share-details">
            <Share data={shareData} title={'Ongoing Seva Initiatives'} initiativeReport={true} />
          </Box>
        </DialogTitle>
      </Dialog>
    </>
  )
}
export default EventsUnderInitiative
