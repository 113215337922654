import { Box, Typography, Avatar } from '@mui/material'
import { useSelector } from 'react-redux'
import twitter from '../../../asserts/images/Twitter.svg'
import './MpTwitterHighlightsDescription.css'

const MpTwitterHighlightsDescription = () => {
  const { lastRefreshedAt } = useSelector((state) => state?.mostViewedTweets?.data)

  return (
    <>
      <Box className="twitterhead">
        <Box>
          <Avatar variant="square" src={twitter} className="twitter-icon" alt="delete" />
        </Box>
        <Box>
          <Typography>
            <Typography variant="h1" className="twitterHighlightText">
              Twitter Highlights
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box className="twitterHighlightsDescription">
        <Typography className="twitterHighlightsDescription__title">
          Highlights of past week refreshed every Sunday midnight.
        </Typography>
        <Typography className="twitterHighlightsDescription__subtitle">
          Last refreshed on {lastRefreshedAt && lastRefreshedAt}
        </Typography>
      </Box>
    </>
  )
}

export default MpTwitterHighlightsDescription
