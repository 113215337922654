import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useLoading } from '../../../utils/LoadingContext'
import { useNotificationContext } from '../../../utils/NotificationContext'
import CloseIcon from '@mui/icons-material/Close'
import CrossIcon from '../../../asserts/images/Close.svg'
import { IconButton, Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import { getMediaCoverageList, getMediaCoverageListByMpID } from '../../../store/action/mediaCoverageList'
import {
  getDevelopmentProjectsList,
  getDevelopmentProjectsListByMpId,
} from '../../../store/action/developmentProjectList'
import axiosInstance from '../../../utils/api'
import { defaultErrorMessage } from '../../../utils/constant'

export default function DeleteDevelopmentProjectsDialog(props) {
  const {
    developmentId,
    mpId,
    openDeleteDialog,
    handleCloseDeleteDialog,
    developmentProjectsByMpId,
    handleCloseInitiativeDetails,
    details,
    isDevelopmentProjectsEdit,
    setDeleteClick,
    limit = 10,
    tenure,
  } = props
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const dispatch = useDispatch()

  const dialogStyle = React.useMemo(
    () => ({
      maxWidth: '570px',
      margin: '0 auto',
      padding: '14px',
    }),
    []
  )

  const dialogContentTextStyle = React.useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      color: '#6C6C6C',
      fontFamily: 'HK Grotesk',
      fontSize: '22px',
      textAlign: 'center',
      mt: 2,
    }),
    []
  )

  const dialogActionsStyle = React.useMemo(
    () => ({
      justifyContent: 'center',
    }),
    []
  )

  const buttonStyle = React.useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      ml: 2,
      width: '140px',
      mb: 4,
      height: '40px',
    }),
    []
  )

  const onDeleteClick = async () => {
    try {
      setLoading(true)
      const payload = {
        id: 0,
        limit,
        isPrevTenure: tenure === 'Modi 2.0' ? true : false,
      }
      const response = await axiosInstance.delete(`/api/DeleteDevelop/${mpId}/${developmentId}`).then((response) => {
        return response
      })
      if (response.status === 200 || response.status === 201) {
        if (details) {
          handleCloseInitiativeDetails && handleCloseInitiativeDetails({ deleted: true })
        }
        if (isDevelopmentProjectsEdit) {
          setDeleteClick(true)
        }
        if (developmentProjectsByMpId) {
          payload.id = mpId
          dispatch(getDevelopmentProjectsListByMpId(payload))
        } else {
          dispatch(getDevelopmentProjectsList(payload))
        }
      }
      showNotification('Success', response.data.message, 'success')
      handleCloseDeleteDialog()
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={dialogStyle}
      >
        <DialogContent>
          <DialogContentText sx={dialogContentTextStyle}>
            Deleting this Development Project will have an impact on your Seva Score. <br />
            Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={dialogActionsStyle}>
          <Button className="button-tr-2" sx={buttonStyle} onClick={onDeleteClick}>
            Yes
          </Button>
          <Button className="button-tr-citizen-cancel" sx={buttonStyle} onClick={handleCloseDeleteDialog}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
