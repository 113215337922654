import { GET_STATE_FAILURE, GET_STATE_REQUEST, GET_STATE_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  error: '',
};

export const getAllStatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATE_REQUEST:
      return {
        ...state,
      };
    case GET_STATE_SUCCESS:
      return {
        data: action.payload,
      };
    case GET_STATE_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
