import CreateInitiativesButton from '../CreateInitiativesButton/CreateInitiativesButton'
import BorderColorIcon from '../../../asserts/images/Create.svg'
import { Typography, Grid } from '@mui/material'
import { pushTag } from '../../../utils/gtmservice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { makeLogRequest } from '../../../utils/AuditLogs'

const CreateInitiativesTitleContainer = ({ title, subTitle }) => {
  const navigate = useNavigate()
  const userProfile = useSelector((state) => state?.userProfile?.data)

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('seva_initiatives', sqId, data)
  }
  const handleCreateNew = async () => {
    pushTag({
      userid: userProfile?.uuid,
      event: 'select_option',
      message: 'create_now',
      page_title: 'seva_initiative_page',
    })
    await postAuditLogs('create_now')
    navigate('/SevaInitiatives/createInitiative', {
      state: {
        create: true,
      },
    })
  }

  return (
    <>
      <Grid container sx={{ ml: 2 }}>
        <Typography
          sx={{
            fontFamily: 'HK Grotesk ExtraBold',
            color: '#505050',
            fontSize: '24px',
            fontWeight: 'bolder !important',
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid container sx={{ ml: 2 }}>
        <Grid item xs={12}>
          <Typography
            sx={{
              fontFamily: 'HK Grotesk',
              color: '#505050',
              fontSize: '20px',
              fontWeight: 'normal',
            }}
          >
            {subTitle}
          </Typography>
        </Grid>
      </Grid>
      <Grid container sx={{ ml: 2 }}>
        <CreateInitiativesButton onClick={handleCreateNew} buttonTitle={'Create Now'} image={BorderColorIcon} />
      </Grid>
    </>
  )
}
export default CreateInitiativesTitleContainer
