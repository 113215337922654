import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { CircularProgress, FormHelperText, Grid, Card, FormControl, Typography, Box, Button } from '@mui/material'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import AddTextTemplates from 'Components/CreateInitiativesRefactored/AddTextTemplate/AddTextTemplates'
import AssignToMP from 'Components/CreateInitiativesRefactored/AssignMp/NewAssingMP'
import AddImageTemplates from 'Components/CreateInitiativesRefactored/AddImageTemplate/AddImageTemplates'
import UploadCoverImage from 'Components/CreateInitiativesRefactored/UploadCoverImage/UploadCoverImage'
import AddVideoTemplate from 'Components/CreateInitiativesRefactored/AddVideoTemplate/AddVideoTemplate'
import DeleteInitiativeDialog from 'Components/CreateInitiativesRefactored/DeleteInitiativeDialog/DeleteInitiativeDialog'
import WarningMessageDialog from 'Components/CreateInitiativesRefactored/WarningMessageDialog/WarningMessageDialog'
import { handleFileName, validateNotEmpty } from 'Components/ReusableComponents.js/reuseMethods'
import getUserProfile from 'store/action/getUserProfile'
import {
  getSocialMediaIntiativesListById,
  getOngoingSevaIntiativesListWithInitiativeId,
} from 'store/action/ongoingSevaInitiativesList'
import { getLanguageList } from 'store/action/languageList'
import { uploadMedia, uploadFile } from 'utils/mediaUpload'
import { useNotificationContext } from 'utils/NotificationContext'
import { useLoading } from 'utils/LoadingContext'
import { makeLogRequest } from 'utils/AuditLogs'
import { pushTag } from 'utils/gtmservice'
import InitiativeType from 'Components/CreateInitiativesRefactored/InitiativeType/InitiativeType'
import { acceptedImageFormatsForInitiativeCover } from 'utils/constant'
import CreateInitiativeInputField from 'Components/CreateInitiativesRefactored/CreateInitiativeInputField/CreateInitiativeInputField'
import CreateSocialMediaKitContainer from 'Components/CreateInitiativesRefactored/CreateSocialMediaKitContainer/CreateSocialMediaKitContainer'
import CreateInitiativeSectionTitle from 'Components/CreateInitiativesRefactored/CreateInitiativeSectionTitle/CreateInitiativeSectionTitle'
import DeleteButton from 'Components/CreateInitiativesRefactored/DeleteButton/DeleteButton'
import CreateInitiativeUploadPdfInputField from 'Components/CreateInitiativesRefactored/CreateInitiativeUploadPdfInputField/CreateInitiativeUploadPdfInputField'
import CreateInitiativeUploadCoverImageInputField from 'Components/CreateInitiativesRefactored/CreateInitiativeUploadCoverImageInputField/CreateInitiativeUploadCoverImageInputField'
import CreateInitiativeSelectDateInputField from 'Components/CreateInitiativesRefactored/CreateInitiativeSelectDateInputField/CreateInitiativeSelectDateInputField'
import TemplateDialogSubmitButton from 'Components/CreateInitiativesRefactored/TemplateDialogSubmitButton/TemplateDialogSubmitButton'

const enumForWarningDialog = {
  text: 'textTemplate',
  image: 'imageTemplate',
  video: 'videoTemplate',
}

const defaultDeleteMessage = 'Are you sure you want to delete this initiative?'

const CreateInitiatives = () => {
  const [addTextExpand, setAddTextExpand] = useState(false)
  const [addImageExpand, setAddImageExpand] = useState(false)
  const [addVideoExpand, setAddVideoExpand] = useState(false)
  const [openTextTemplateDialog, setOpenTextTemplateDialog] = useState(false)
  const [openUploadImageDialog, setOpenUploadImageDialog] = useState(false)
  const [openVideoTemplateDialog, setOpenVideoTemplateDialog] = useState(false)
  const [openImageTemplateDialog, setOpenImageTemplateDialog] = useState(false)
  const [openAssingToMPDialog, setOpenAssingToMPDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [intiative, setIntiative] = useState()
  const [files, setImageFileData] = useState()
  const [openWarningDialog, setOpenWarningDialog] = useState(false)
  const [dialogIsFor, setDialogIsFor] = useState('')
  const [checkWarningClick, setCheckWarningClick] = useState(false)
  const { eventList, MPList } = useSelector((state) => state?.ongoingIntiativeListWithID?.data)
  const editInitiativeDetails = useSelector((state) => state?.socialMediaInitiativeById?.data[0])
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const [editDetails, setEditDetails] = useState(editInitiativeDetails)
  const name = editDetails?.initiativeName
  const [editDetailsName, setEditDetailsName] = useState({
    prefix: editDetails?.initiativeName,
    text: name,
  })

  const [image, setImage] = useState(null)
  const [editTextDetails, setEditTextDetails] = useState(editInitiativeDetails?.texttemplates)
  const [checkTextDetails, setCheckDetails] = useState(false)
  const [preview, setPreview] = useState(editDetails?.coverimage)
  const img = editDetails?.coverimage?.substring(editDetails?.coverimage?.lastIndexOf('/') + 1)
  const [upload, setUpload] = useState(false)
  const coverImage = img?.substring(0, img.indexOf('.', 3))
  const [imagetemplates, setImagetemplates] = useState(editDetails?.imagetemplates)
  const [videoTemplates, setVideoTemplates] = useState(editDetails?.videotemplates)
  const [imageName, setImageName] = useState(coverImage)
  const { loading } = useSelector((state) => state?.ongoingIntiativeListWithID)
  const [targetedDate, setTargetDate] = useState(editDetails?.tagetDate)
  const apiLanguageList = useSelector((state) => state.languageList?.data?.languagedata)
  const [textData, setTextData] = useState(editDetails?.texttemplates || [])
  const [imageData, setImageData] = useState([])
  const [videoData, setVideoData] = useState([])
  const [onEditBrowse, setOnEditBrowse] = useState(false)
  const [updateValue, setUpdateValue] = useState(false)
  const [updateVideoValue, setUpdateVideoValue] = useState(false)
  const [checkCoverImage, setCheckCoverImage] = useState(false)
  const [isTextTemplateErrorVisible, setIsTextTemplateErrorVisible] = useState(false)
  const [isImageTemplateErrorVisible, setIsImageTemplateErrorVisible] = useState(false)
  const [isVideoTemplateErrorVisible, setIsVideoTemplateErrorVisible] = useState(false)
  const [isStepVisible, setIsStepVisible] = useState(false)
  const [stepValue, setStepValue] = useState(editDetails?.steps ? editDetails?.steps : '')
  const [checkDescError, setCheckDescError] = useState(false)
  const [checkDescValue, setCheckDescValue] = useState(false)
  const [checkDescChanges, setCheckDescChanges] = useState(false)
  const [stepPdf, setStepPdf] = useState(null)
  const [stepPdfName, setStepPdfName] = useState()
  const { showNotification } = useNotificationContext()
  const { data: initiativeEventDetails } = useSelector((state) => state?.getAllEvents)
  const [deleteMessage, setDeleteMessage] = React.useState(defaultDeleteMessage)
  const [textFileName, setTextFileName] = useState('')
  const [textFileError, setTextFileError] = useState(null)
  const [isTextFileLoading, setTextFileIsLoading] = useState(false)
  const [csvToJsonData, setCsvToJsonData] = useState([]) //use to delete file data
  const [manualInsetCount, setManualInsetCount] = useState(1) //use to delete file data from that location
  const [isTextFileUpload, setIsTextFileUpload] = useState(false)
  const [languageList, setLanguageList] = useState(apiLanguageList)
  const { setLoading } = useLoading()
  const [initiativeType, setInitiativeType] = useState(editDetails?.visibility ? editDetails?.visibility : '')
  const [initiativeName, setInitiativeName] = useState('')
  const [initiativeDetails, setInitiativeDetails] = useState('')
  const [initiativeHashtag, setInitiativeHashtag] = useState('')

  const isChange = useMemo(() => {
    return (
      initiativeName?.length > 0 ||
      initiativeDetails?.length > 0 ||
      image !== null ||
      initiativeHashtag?.length > 0 ||
      targetedDate !== null ||
      stepValue?.length > 0 ||
      stepPdf !== null ||
      textData?.length > 0 ||
      imageData?.length > 0 ||
      videoData?.length > 0
    )
  }, [
    initiativeName,
    initiativeDetails,
    image,
    initiativeHashtag,
    targetedDate,
    stepValue,
    stepPdf,
    textData,
    imageData,
    videoData,
  ])

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('create_seva_initiatives_form', sqId, data)
  }

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'seva_initiative_page',
      message: message,
    })
  }

  useEffect(() => {
    if (!editDetails?.stepsPdf?.[0]) {
      setStepPdf(null)
    }
    PdfFileInput.current.value = null
  }, [])
  useEffect(() => {
    if (apiLanguageList) {
      setLanguageList(apiLanguageList)
    }
  }, [apiLanguageList])

  useEffect(() => {
    setDeleteMessage(defaultDeleteMessage)
    if (Array.isArray(initiativeEventDetails) && initiativeEventDetails?.length) {
      setDeleteMessage(
        'Multiple MPs have created events for this initiative. It will be permanently deleted from MP Corner. Are you sure you want to delete this initiative?'
      )
    }
  }, [initiativeEventDetails])

  useEffect(() => {
    if (textData?.length > 0) {
      setEditTextDetails(textData || [])
      setIsTextTemplateErrorVisible(false)
    }
  }, [textData])
  useEffect(() => {
    if (updateValue && imageData.length > 0) {
      const output = imageData[0].map((item) => ({
        typeof_image: item.imageType,
        imageLanguage: item.language,
        url: item.images,
        banner_size: item.bannerSize || '',
      }))
      setImagetemplates(output)
      setUpdateValue(false)
      setIsImageTemplateErrorVisible(false)
    }
  }, [imageData])

  useEffect(() => {
    if (updateVideoValue && videoData.length > 0) {
      const output = videoData[0].map((item) => ({
        language: item.language,
        video: item.videos,
      }))
      setVideoTemplates(output)
      setUpdateVideoValue(false)
      setIsVideoTemplateErrorVisible(false)
    }
  }, [videoData])

  useEffect(() => {
    setIsStepVisible(false)
  }, [stepValue])

  useEffect(() => {
    if (!createNewClick) {
      setUpload(false)
      let temp = [[]]
      if (imagetemplates?.length > 0 && imageData?.length == 0) {
        imagetemplates?.map((value, i) => {
          let val = {}
          val.imageType = value.typeof_image
          val.language = value.imageLanguage
          let urlTemp = JSON.parse(value.url)
          let urlData = [...urlTemp]
          val.images = urlData
          value.banner_size && (val.bannerSize = value.banner_size)
          temp[0].push(val)
        })
        setUpdateValue(true)
        setImageData(temp)
      }
    }
  }, [editDetails])

  useEffect(() => {
    if (!createNewClick) {
      let temp = [[]]
      if (videoTemplates?.length > 0 && videoData?.length == 0) {
        videoTemplates?.map((value, i) => {
          let val = {}
          val.language = value.language
          let urlTemp = JSON.parse(value.video)
          let urlData = [...urlTemp]
          val.videos = urlData
          temp[0].push(val)
        })
        setUpdateVideoValue(true)
        setVideoData(temp)
      }
    }
  }, [editDetails])
  useEffect(() => {
    dispatch(getUserProfile())
    dispatch(getLanguageList())
    postAuditLogs('pageload')

    if (!id) {
      setPreview('')
      setImageName('')
      setImageData([])
      setTargetDate(null)
      setVideoData([])
      setValue('initiativeName', '')
      setValue('initiativeDetails', '')
      setValue('steps', '')
      setValue('hashTags', '')
    } else {
      dispatch(getSocialMediaIntiativesListById(id))
      dispatch(getOngoingSevaIntiativesListWithInitiativeId(id))
    }
  }, [])

  useEffect(() => {
    if (id && editInitiativeDetails) {
      setEditDetails(editInitiativeDetails)
      const name = editInitiativeDetails?.initiativeName.split('-')
      name?.shift()
      setValue('initiativeName', editInitiativeDetails?.initiativeName)
      setInitiativeName(editInitiativeDetails?.initiativeName)
      setValue('initiativeDetails', editInitiativeDetails?.initiativeDetails)
      setInitiativeDetails(editInitiativeDetails?.initiativeDetails)
      setValue('steps', editInitiativeDetails?.steps)
      setEditDetailsName({
        prefix: editInitiativeDetails?.initiativeName,
        text: name,
      })
      setEditTextDetails(editInitiativeDetails?.texttemplates)
      setTextData(editInitiativeDetails?.texttemplates || [])
      let coverImg = editInitiativeDetails?.coverimage?.substring(
        editInitiativeDetails?.coverimage?.lastIndexOf('/') + 1
      )
      let coverImageName = coverImg?.substring(0, coverImg.indexOf('.', 3))
      setImageName(coverImageName)
      setUpload(true)
      setPreview(editInitiativeDetails?.coverimage)
      setTargetDate(editInitiativeDetails?.tagetDate)
      setValue('hashTags', editInitiativeDetails?.hashTags)
      setImagetemplates(editInitiativeDetails?.imagetemplates)
      setVideoTemplates(editInitiativeDetails?.videotemplates)
      setInitiativeType(initiativeType)
      if (editInitiativeDetails?.stepsPdf != null && editInitiativeDetails?.stepsPdf.length > 0) {
        setStepPdf(editInitiativeDetails?.stepsPdf[0])
        setStepPdfName(handleFileName(editInitiativeDetails?.stepsPdf[0]))
      }
    }
  }, [editInitiativeDetails])

  const dispatch = useDispatch()
  const { id } = useParams()

  const hiddenFileInput = useRef(null)
  const PdfFileInput = useRef(null)
  const location = useLocation()
  let createNewClick = location?.state?.create
  const handleClick = async (event, postLog) => {
    hiddenFileInput.current?.click()
    event?.stopPropagation()
    if (postLog) await postAuditLogs('browse')
  }

  useEffect(() => {
    if (createNewClick) {
      setEditDetails({})
      setEditTextDetails([])
      setCheckDetails(true)
      setVideoData([])
      setImageData([])
      setTextData([])
      setStepValue('')
      setStepPdf(null)
      setInitiativeType('public')
    }
  }, [createNewClick])

  const onFileUpload = (event) => {
    const fileName = event.target.files[0].name
    const fileExtension = fileName.split('.').pop().toLowerCase()
    const allowedExtensions = [
      'png',
      'jpg',
      'jpeg',
      'jpe',
      'jif',
      'jfif',
      'jfi',
      'tiff',
      'tif',
      'psd',
      'eps',
      'ai',
      'indd',
      'raw',
      'webp',
      'svg',
      'svgz',
      'heif',
    ]
    if (!allowedExtensions.includes(fileExtension)) {
      showNotification(
        'Error',
        'Please upload image in .jpg, .jpeg, .jpe, .jif, .jfi, .png, .tiff, .psd, .eps, .ai, .indd, .raw, .jfif, .webp, .svg, .svgz, .heif format only',
        'error'
      )
      return
    }
    let fsize = Math.round(event.target.files[0].size / 1024)
    if (fsize > 20 * 1024) {
      showNotification('Error', 'Please upload image with size less than 20MB', 'error')
      return
    }
    setImage(event.target.files[0])
    setUpload(true)
    setPreview(URL.createObjectURL(event.target.files[0]))
    setImageName(event.target.files[0].name)
    setOnEditBrowse(createNewClick ? false : true)
  }

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    resetField,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    if (checkWarningClick) {
      handleCloseWarningDialog(true)
      setCheckWarningClick(false)
      switch (dialogIsFor) {
        case enumForWarningDialog.text:
          setAddTextExpand(false)
          setOpenTextTemplateDialog(false)
          if (isTextFileUpload) {
            setTextFileName('')
            setCsvToJsonData([])
          }
          break
        case enumForWarningDialog.image:
          setAddImageExpand(false)
          setOpenImageTemplateDialog(false)
          break
        case enumForWarningDialog.video:
          setAddVideoExpand(false)
          setOpenVideoTemplateDialog(false)
          break
        default:
          break
      }
    }
  }, [checkWarningClick])

  const checkTextField = (value, maxLength, minLength, register_name, fieldName) => {
    if (value.length == 0) {
      if (register_name === 'steps') {
        clearErrors('steps')
        return
      }
      setError(`${register_name}`, {
        type: 'value',
        message: `Please enter ${fieldName}`,
      })
    } else if (value.length > 0 && value.length < minLength) {
      setError(`${register_name}`, {
        type: 'minLength',
        message: `Minimum character length is ${minLength}`,
      })
    } else if (value.length == maxLength) {
      setError(`${register_name}`, {
        type: 'maxLength',
        message: 'Maximum limit reached',
      })
    } else {
      clearErrors(`${register_name}`)
    }
  }

  const registerEventName = () => {
    return register('initiativeName', {
      required: 'Please enter initiative name ',
      maxLength: {
        value: 300,
        message: 'Maximum character length is 300',
      },
      minLength: {
        value: 10,
        message: 'Minimum character length is 10',
      },
    })
  }
  const EventNameProps = {
    maxLength: 300,
    minLength: 10,
  }
  const registerEventDetails = () => {
    return register('initiativeDetails', {
      required: 'Please enter Initiative Details',
      maxLength: {
        value: 5000,
        message: 'Maximum 5000 characters allowed',
      },
      minLength: {
        value: 10,
        message: 'Minimum character length is 10',
      },
      validate: (value) => validateNotEmpty(value, 'Initiative Details'),
    })
  }
  const EventDetailProps = {
    maxLength: 5000,
    minLength: 10,
  }
  const registerHashtags = () => {
    return register('hashTags', {
      required: 'Please enter a hashtag',
      maxLength: {
        value: 280,
        message: 'Max character length is 280',
      },
      minLength: {
        value: 5,
        message: 'Minimum character length is 5',
      },
      validate: (value) => validateNotEmpty(value, 'hashtag'),
    })
  }
  const hashtagsprops = {
    maxLength: 280,
    minLength: 5,
  }
  const stepsRegister = () => {
    return register('steps', {
      maxLength: {
        value: 1000,
        message: 'Maximum character length is 1000',
      },
      minLength: {
        value: 10,
        message: 'Minimum character length is 10',
      },
    })
  }
  const stepsProps = {
    maxLength: 1000,
  }

  const registerFunctionForTargetDate = () => {
    return register('targetDate', {
      required: !targetedDate ? 'Please choose a target date' : false,
    })
  }

  const handleCloseWarningDialog = () => setOpenWarningDialog(false)

  const handleCloseTextTemplateDialog = (warningDialog, isCrossClicked) => {
    if (warningDialog) {
      setOpenWarningDialog(true)
      setDialogIsFor(enumForWarningDialog.text)
    } else {
      if (isCrossClicked) {
        setAddTextExpand(false)
      }
      setOpenTextTemplateDialog(false)
    }
  }

  const handleCloseImageTemplateDialog = (warningDialog, isCrossClicked) => {
    if (warningDialog) {
      setOpenWarningDialog(true)
      setDialogIsFor(enumForWarningDialog.image)
    } else {
      if (isCrossClicked) {
        setAddImageExpand(false)
      }
      setOpenImageTemplateDialog(false)
    }
  }

  const handleCloseVideosTemplateDialog = (warningDialog, isCrossClicked) => {
    if (warningDialog) {
      setOpenWarningDialog(true)
      setDialogIsFor(enumForWarningDialog.video)
    } else {
      if (isCrossClicked) {
        setAddVideoExpand(false)
      }
      setOpenVideoTemplateDialog(false)
    }
  }

  const handleCloseUploadImageDialog = () => {
    setOpenUploadImageDialog(false)
  }

  const handleClickOpenDeleteDialog = async () => {
    await postAuditLogs('delete', { i_id: id })
    setOpenDeleteDialog(true)
  }

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false)
  }

  const handleFormErrors = (errors, event) => {
    if (stepValue?.length === 0 && stepPdfName == null) {
      setIsStepVisible(true)
    }
    if (stepValue.length === 10 && stepValue?.charAt(stepValue?.length - 1) === ' ') {
      setError('steps', {
        type: 'minLength',
        message: 'Minimum character length is 10',
      })
    }
    if (textData?.length < 1) {
      setIsTextTemplateErrorVisible(true)
    }
    if (imageData?.length < 1) {
      setIsImageTemplateErrorVisible(true)
    }
    if (videoData?.length < 1) {
      setIsVideoTemplateErrorVisible(true)
    }
    if (stepValue?.length >= 10 || !(stepPdfName == null)) {
      setIsStepVisible(false)
    }
    return (
      textData?.length < 1 ||
      imageData?.length < 1 ||
      videoData?.length < 1 ||
      (stepValue?.length < 1 && stepPdfName == null) ||
      (stepValue?.length === 0 && stepPdfName == null) ||
      (stepValue.length === 10 && stepValue?.charAt(stepValue?.length - 1) === ' ')
    )
  }

  const handleOpenAssingToMPDialog = async (data) => {
    await postAuditLogs('assign', { i_id: id })
    if (handleFormErrors(null)) return
    if (!imageName) return
    setLoading(true)
    const textTemplates = csvToJsonData?.length ? [...textData] : textData

    let uploadImageTemplates = imageData[0]?.map(async (value, i) => {
      const existingUrls = []
      const filesObj = {
        imageFiles: value?.images?.map((val) => {
          if (val.toString().startsWith('http')) {
            existingUrls.push(val)
            return
          }
          return val.file
        }),
      }
      // todo need to remove undefined body api calls
      const urls = filesObj?.imageFiles?.length > 0 ? await uploadMedia(dispatch, filesObj) : { imageUrls: [] }
      const obj = {
        url: [...urls?.imageUrls, ...existingUrls],
        language: value.language,
        type: value.imageType,
      }
      if (value?.bannerSize) {
        obj.size = value.bannerSize
      }
      return obj
    })
    const imageTemplates = uploadImageTemplates ? await Promise.all(uploadImageTemplates || new Promise()) : []

    const uploadVideoTemplates = videoData[0]?.map(async (value, i) => {
      const existingUrls = []
      const filesObj = {
        videoFiles: value?.videos?.map((val) => {
          if (val.toString().startsWith('http')) {
            existingUrls.push(val)
            return
          }
          return val.file
        }),
      }
      // todo need to remove undefined body api calls
      const urls = filesObj?.videoFiles?.length > 0 ? await uploadFile(filesObj) : { videoUrls: [] }
      return {
        url: [...urls?.videoUrls, ...existingUrls],
        name: 'media',
        language: value.language,
      }
    })
    let videoTemplates = uploadVideoTemplates ? await Promise.all(uploadVideoTemplates || new Promise()) : []
    if (videoTemplates.length > 0) {
      videoTemplates = videoTemplates.filter((item) => {
        return item.url.length > 0
      })
    }

    let urls = image
    if (image) {
      urls = await uploadMedia(dispatch, {
        coverImage: image?.file ? image.file : image,
      })
    } else {
      urls = {
        coverImageUrl: JSON.parse(editInitiativeDetails?.coverimage)[0] || '',
      }
    }

    let stepurl = stepPdf ? stepPdf : null
    if (stepPdf && stepPdf?.size) {
      let { stepPdfUrl } = await uploadMedia(dispatch, { stepPdf: stepPdf })
      stepurl = stepPdfUrl
    } else if (stepPdf && id) {
      stepurl = editDetails?.stepsPdf[0]
    } else stepurl = null
    const payload = {
      initiativeName: data?.initiativeName,
      initiativeDetails: data?.initiativeDetails,
      visibility: initiativeType,
      hashTags: data?.hashTags,
      targetDate: targetedDate,
      steps: data?.steps?.trim()?.length > 0 ? data?.steps?.trim() : '',
      stepsPdf: stepurl != null ? [stepurl] : [],
      coverimage: [urls?.coverImageUrl] || [''],
      textTemplates: textTemplates || [],
      imageTemplates: imageTemplates,
      videoTemplates: videoTemplates,
    }

    setLoading(false)
    setIntiative(payload)
    setOpenAssingToMPDialog(true)
  }

  const handleCloseAssingToMPDialog = () => {
    setOpenAssingToMPDialog(false)
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  const handleInitiativeNameChange = (event) => {
    let inputValue = event.target.value?.trimStart()
    inputValue = inputValue.replace(/\s{2,}/g, ' ')
    checkTextField(inputValue, 300, 10, 'initiativeName', 'initiative name')
    setValue('initiativeName', inputValue)
    setInitiativeName(inputValue)
  }

  const handleInitiativeHashtagChange = (event) => {
    let inputValue = event?.target?.value?.trim()
    inputValue = inputValue.replace(/\s{2,}/g, ' ')
    checkTextField(inputValue, 280, 5, 'hashTags', 'a hashtag')
    setInitiativeHashtag(inputValue)
  }
  const handleHashTagPaste = (event) => {
    event.preventDefault()
    const text = event.clipboardData.getData('text/plain')
    const textWithoutSpaces = text.replace(/\s/g, '') // Remove spaces
    document.execCommand('insertText', false, textWithoutSpaces)
    setInitiativeHashtag(textWithoutSpaces)
  }

  const handleInitiativeDetailChange = (e) => {
    e.target.value = e.target.value.replace(/\s{2,}/g, ' ')
    checkTextField(e.target.value, 5000, 10, 'initiativeDetails', 'initiative details')
    setInitiativeDetails(e?.target?.value)
    if (e.target.value !== '') {
      setCheckDescValue(true)
    } else {
      setCheckDescValue(false)
    }
    if (e.target.value.length > 5000) {
      setCheckDescValue(false)
      setCheckDescError(true)
      setCheckDescChanges(true)
    } else {
      setCheckDescError(false)
      setCheckDescChanges(false)
    }
  }

  const handleInitiativeDetailPaste = (e) => {
    const pastedText = e.clipboardData.getData('text')
    if (pastedText.length + e.target.value.length > 5000) {
      e.preventDefault()
      const truncatedText = pastedText.substring(0, 5000 - e.target.value.length)
      document.execCommand('insertText', false, truncatedText)
    }
  }

  const handleSteps = (e) => {
    e.target.value = e.target.value.trimStart().replace(/\s{2,}/g, ' ')
    checkTextField(e.target.value, 1000, 10, 'steps', 'Steps or PDF')
    setStepValue(e.target.value)
    setIsStepVisible(false)
  }

  return (
    <>
      <Box className="page-wrapper1 d-flex">
        <SideMenu activeTab={'SevaInitiative'} createInitiative={isChange} />

        <Grid item xs={8} md={10} lg={10} xl={10} className={'main-wrapper customscroll gridwid'}>
          <Box className="d-flex justify-content-between align-items-center seva-initiative-st">
            <CreateInitiativeSectionTitle title={'Seva Initiatives'} className={'seva-initiative-h1'} />
          </Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <form>
              <Card
                sx={{
                  fontFamily: 'HK Grotesk',
                  width: '90%',
                  margin: '0 auto',
                  padding: '45px',
                  borderRadius: '20px',
                }}
              >
                <Grid item xs={10} md={10} lg={10} xl={10} sx={{ fontFamily: 'HK Grotesk' }}>
                  <CreateInitiativeSectionTitle
                    title={id ? 'Edit Initiative' : 'Create Initiative'}
                    className={'initiative-st'}
                  />
                  <FormControl variant="standard" sx={{ marginTop: '25px' }}>
                    <CreateInitiativeInputField
                      titleName="Initiative Name"
                      inputProps={EventNameProps}
                      defaultValue={id ? editDetailsName?.text : ''}
                      registerFunction={registerEventName}
                      onChange={handleInitiativeNameChange}
                    />
                  </FormControl>
                  <FormHelperText sx={{ color: '#d32f2f', fontWeight: '700' }}>
                    {errors && errors?.initiativeName?.message}
                  </FormHelperText>
                </Grid>

                <Grid item xs={10} md={10} lg={10} xl={10}>
                  <FormControl variant="standard" sx={{ marginTop: '25px' }}>
                    <CreateInitiativeInputField
                      titleName="Initiative Details"
                      inputProps={EventDetailProps}
                      placeholder="Initiative Details"
                      defaultValue={id ? editDetails?.initiativeDetails : ''}
                      multiline={true}
                      rows={2}
                      registerFunction={registerEventDetails}
                      onChange={handleInitiativeDetailChange}
                      onPaste={handleInitiativeDetailPaste}
                    />
                  </FormControl>
                  <FormHelperText
                    sx={{
                      color: errors && errors?.initiativeDetails?.message ? '#d32f2f !important' : 'blue !important',
                      fontWeight: '700',
                    }}
                  >
                    {errors && errors?.initiativeDetails?.message
                      ? errors && errors?.initiativeDetails?.message
                      : checkDescError || checkDescValue
                      ? 'Maximum 5000 characters allowed'
                      : errors?.initiativeDetails?.message}
                  </FormHelperText>
                </Grid>

                <InitiativeType initiativeType={initiativeType} setInitiativeType={setInitiativeType} />

                <CreateInitiativeUploadCoverImageInputField
                  acceptedImageFormatsForInitiativeCover={acceptedImageFormatsForInitiativeCover}
                  hiddenFileInput={hiddenFileInput}
                  onFileUpload={onFileUpload}
                  handleClick={handleClick}
                  imageName={imageName}
                  setOpenUploadImageDialog={setOpenUploadImageDialog}
                  checkCoverImage={checkCoverImage}
                  preview={preview}
                  sendGALog={sendGALog}
                />

                <Box className="hash-date-div ">
                  <Grid item xs={10} md={10} lg={10} xl={10} sx={{ marginTop: '30px' }}>
                    <FormControl variant="standard" sx={{ width: '180px' }}>
                      <CreateInitiativeInputField
                        titleName="Event Hashtag"
                        inputProps={hashtagsprops}
                        defaultValue={id ? editDetails?.hashTags : ''}
                        registerFunction={registerHashtags}
                        onKeyDown={handleKeyDown}
                        onPaste={handleHashTagPaste}
                        onChange={handleInitiativeHashtagChange}
                      />

                      <FormHelperText sx={{ color: '#d32f2f', fontWeight: '700', letterSpacing: '0.016em' }}>
                        {errors && errors?.hashTags?.message}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <CreateInitiativeSelectDateInputField
                    watch={watch}
                    resetField={resetField}
                    setError={setError}
                    register={register}
                    errors={errors}
                    id={id}
                    editDetails={editDetails}
                    targetedDate={targetedDate}
                    setTargetDate={setTargetDate}
                    registerFunctionForTargetDate={registerFunctionForTargetDate}
                  />
                </Box>
                <Grid item xs={10} md={10} lg={10} xl={10} sx={{ marginTop: '25px', display: 'flex' }}>
                  <FormControl sx={{ flexDirection: 'column' }} variant="standard">
                    <CreateInitiativeInputField
                      titleName="Steps"
                      inputProps={stepsProps}
                      defaultValue={id ? editDetails?.steps : ''}
                      multiline={true}
                      rows={3}
                      registerFunction={stepsRegister}
                      onChange={handleSteps}
                    />
                    <Grid container>
                      <Grid item xs={8}>
                        <FormHelperText sx={{ color: '#d32f2f', fontWeight: '1000' }}>
                          {isStepVisible && 'Please Enter Steps or PDF'}
                          {errors && !isStepVisible && errors?.steps?.message}
                        </FormHelperText>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography component="span" className="string-st">
                          (Max length 1000)
                        </Typography>
                      </Grid>
                    </Grid>
                  </FormControl>
                  <Typography component="span" className="orClass">
                    {' '}
                    Or{' '}
                  </Typography>
                  <CreateInitiativeUploadPdfInputField
                    stepPdfName={stepPdfName}
                    stepPdf={stepPdf}
                    PdfFileInput={PdfFileInput}
                    setStepPdf={setStepPdf}
                    setStepPdfName={setStepPdfName}
                    setUpload={setUpload}
                  />
                </Grid>

                <CreateSocialMediaKitContainer
                  addTextExpand={addTextExpand}
                  checkTextDetails={checkTextDetails}
                  textData={textData}
                  setOpenTextTemplateDialog={setOpenTextTemplateDialog}
                  setOpenImageTemplateDialog={setOpenImageTemplateDialog}
                  editDetails={editDetails}
                  postAuditLogs={postAuditLogs}
                  sendGALog={sendGALog}
                  isTextTemplateErrorVisible={isTextTemplateErrorVisible}
                  setAddTextExpand={setAddTextExpand}
                  addImageExpand={addImageExpand}
                  imageData={imageData}
                  isImageTemplateErrorVisible={isImageTemplateErrorVisible}
                  setAddImageExpand={setAddImageExpand}
                  addVideoExpand={addVideoExpand}
                  setAddVideoExpand={setAddVideoExpand}
                  isVideoTemplateErrorVisible={isVideoTemplateErrorVisible}
                  videoData={videoData}
                  setOpenVideoTemplateDialog={setOpenVideoTemplateDialog}
                />

                <Grid
                  item
                  xs={10}
                  md={10}
                  lg={10}
                  xl={10}
                  sx={{ display: 'flex', justifyContent: 'flex-start', height: '55px' }}
                >
                  <TemplateDialogSubmitButton
                    buttonSx={{
                      fontFamily: 'HK Grotesk',
                      mt: '24px!important',
                      fontSize: '18px!important',
                      height: '35px',
                    }}
                    buttonClassName={checkDescError || checkDescChanges ? 'button-tr-3' : 'button-tr-2'}
                    onClick={handleSubmit(handleOpenAssingToMPDialog, handleFormErrors)}
                    onMouseUp={() => (setCheckCoverImage(true), setCheckDescValue(false))}
                    disabled={(checkDescChanges || checkDescError) && true}
                    title={'Assign'}
                  />

                  {id && <DeleteButton onClick={handleClickOpenDeleteDialog} page={'CreateInitiative'} />}
                  {openTextTemplateDialog && (
                    <AddTextTemplates
                      openTextTemplateDialog={openTextTemplateDialog}
                      handleCloseTextTemplateDialog={handleCloseTextTemplateDialog}
                      setText={setTextData}
                      languages={languageList}
                      createNewClick={createNewClick}
                      editTextTemplateDetails={editTextDetails}
                      setTextFileName={setTextFileName}
                      textFileName={textFileName}
                      setTextFileError={setTextFileError}
                      textFileError={textFileError}
                      setTextFileIsLoading={setTextFileIsLoading}
                      isTextFileLoading={isTextFileLoading}
                      setCsvToJsonData={setCsvToJsonData}
                      csvToJsonData={csvToJsonData}
                      setManualInsetCount={setManualInsetCount}
                      manualInsetCount={manualInsetCount}
                      setIsTextFileUpload={setIsTextFileUpload}
                      isFileUploaded={isTextFileUpload}
                    />
                  )}
                  {openAssingToMPDialog && (
                    <AssignToMP
                      handleCloseAssingToMPDialog={handleCloseAssingToMPDialog}
                      openAssingToMPDialog={openAssingToMPDialog}
                      intiativeData={intiative}
                      image={image}
                      id={id}
                      selectedMP={MPList}
                      createNewClick={createNewClick}
                      eventList={eventList}
                    />
                  )}
                  {openImageTemplateDialog && (
                    <AddImageTemplates
                      handleCloseImageTemplateDialog={handleCloseImageTemplateDialog}
                      openImageTemplateDialog={openImageTemplateDialog}
                      setImageData={setImageData}
                      languages={languageList}
                      imageData={imageData}
                      editDetails={imageData.length > 0 && imagetemplates}
                      createNewClick={createNewClick}
                      setUpdateValue={setUpdateValue}
                    />
                  )}
                  {openVideoTemplateDialog && (
                    <AddVideoTemplate
                      handleCloseVideosTemplateDialog={handleCloseVideosTemplateDialog}
                      openVideoTemplateDialog={openVideoTemplateDialog}
                      languages={languageList}
                      editDetails={videoData.length > 0 && videoTemplates}
                      setVideoData={setVideoData}
                      setUpdateVideoValue={setUpdateVideoValue}
                    />
                  )}
                  {openDeleteDialog && (
                    <DeleteInitiativeDialog
                      handleCloseDeleteDialog={handleCloseDeleteDialog}
                      openDeleteDialog={openDeleteDialog}
                      initiativeId={id && id}
                      message={deleteMessage}
                    />
                  )}
                  {openUploadImageDialog && (
                    <UploadCoverImage
                      handleCloseUploadImageDialog={handleCloseUploadImageDialog}
                      openUploadImageDialog={openUploadImageDialog}
                      image={image}
                      setImage={setImage}
                      setImageFileData={setImageFileData}
                      preview={preview}
                      setPreview={setPreview}
                      setImageName={setImageName}
                      handleClick={handleClick}
                      createNewClick={createNewClick}
                      onEditBrowse={onEditBrowse}
                    />
                  )}
                  {openWarningDialog && (
                    <WarningMessageDialog
                      openWarningDialog={openWarningDialog}
                      handleCloseWarningDialog={handleCloseWarningDialog}
                      setCheckWarningClick={setCheckWarningClick}
                    />
                  )}
                </Grid>
              </Card>
            </form>
          )}
        </Grid>
      </Box>
    </>
  )
}

export default CreateInitiatives
