import { Typography, Box, Divider } from '@mui/material'
import MediaButtons from './mediaButtons'
import '../InitiativesDetails.css'
function SocialMediaKit({ handleClickingOpen, handleImageClickOpen, handleVideoClickOpen }) {
  return (
    <>
      <Box>
        <Typography variant="body1" className="initiative-heading initiative-title-style sort-styling">
          Social Media Kit
        </Typography>
        <Typography variant="body1" className="resources-heading resources-heading-style name-color ">
          You can download the resources from here
        </Typography>
        <Box className="social-media-kit-cards">
          <MediaButtons title="Text" onClick={handleClickingOpen} />
          <MediaButtons
            title="Images"
            onClick={handleImageClickOpen}
            viewBox="0 0 307.584 239.232"
            id="Icon_awesome-images"
            data-name="Icon awesome-images"
            d="M256.32,207.306v8.544a25.632,25.632,0,0,1-25.632,25.632H25.632A25.632,25.632,0,0,1,0,215.85V79.146A25.632,25.632,0,0,1,25.632,53.514h8.544V164.586a42.768,42.768,0,0,0,42.72,42.72Zm51.264-42.72V27.882A25.632,25.632,0,0,0,281.952,2.25H76.9A25.632,25.632,0,0,0,51.264,27.882v136.7A25.632,25.632,0,0,0,76.9,190.218H281.952A25.632,25.632,0,0,0,307.584,164.586ZM136.7,53.514a25.632,25.632,0,1,1-25.632-25.632A25.632,25.632,0,0,1,136.7,53.514ZM85.44,130.41l29.645-29.645a6.409,6.409,0,0,1,9.063,0l21.1,21.1L217.613,49.5a6.409,6.409,0,0,1,9.063,0l46.732,46.733v59.808H85.44Z"
            transform="translate(0 -2.25)"
          />
          <MediaButtons
            title="Videos"
            onClick={handleVideoClickOpen}
            viewBox="0 0 339.064 226.043"
            id="Icon_awesome-video"
            data-name="Icon awesome-video"
            d="M197.905,4.5H28.138A28.137,28.137,0,0,0,0,32.638V202.405a28.137,28.137,0,0,0,28.138,28.138H197.905a28.137,28.137,0,0,0,28.138-28.138V32.638A28.137,28.137,0,0,0,197.905,4.5ZM309.4,26.692l-64.516,44.5v92.654L309.4,208.292c12.479,8.594,29.668-.177,29.668-15.187V41.879C339.064,26.928,321.935,18.1,309.4,26.692Z"
            transform="translate(0 -4.5)"
          />
        </Box>
        <Divider className="cover-divider"></Divider>
      </Box>
    </>
  )
}
export default SocialMediaKit
