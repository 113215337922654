import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useLoading } from '../../../utils/LoadingContext'
import { useNotificationContext } from '../../../utils/NotificationContext'
import CrossIcon from '../../../asserts/images/Close.svg'
import { IconButton } from '@mui/material'
import { getIntiativesReportByInitiativeIdAndMpId } from '../../../store/action/ongoingSevaInitiativesList'
import { useDispatch } from 'react-redux'
import axiosInstance from '../../../utils/api'
import { defaultErrorMessage } from '../../../utils/constant'

export default function DeleteEventDialog(props) {
  const { eventId, mpId, openDeleteDialog, handleCloseDeleteDialog, initiativeId } = props
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const dispatch = useDispatch()

  const onDeleteClick = async () => {
    try {
      setLoading(true)
      const response = await axiosInstance.delete(`/api/DeleteEvent/${mpId}/${eventId}`).then((response) => {
        return response
      })
      if (response.status === 200 || response.status === 201) {
        await dispatch(getIntiativesReportByInitiativeIdAndMpId(initiativeId, mpId))
      }
      showNotification('Success', response.data.message, 'success')
      handleCloseDeleteDialog()
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ maxWidth: '550px', margin: '0 auto' }}
      >
        <img onClick={handleCloseDeleteDialog} src={CrossIcon} className="closeIcon" />
        <DialogContent>
          <DialogContentText
            sx={{
              minHeight: '140px',
              display: 'flex',
              alignItems: 'center',
              color: '#6C6C6C',
              fontFamily: 'HK Grotesk',
              fontSize: '22px',
              textAlign: 'center',
              mt: 3,
            }}
          >
            Deleting this event will have an impact on your Seva Score. Are you sure you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            className="button-tr-2"
            sx={{ fontFamily: 'HK Grotesk', ml: 2, width: '250px', mb: 3, height: '44px' }}
            onClick={onDeleteClick}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
