import React, { createContext, useContext, useState } from 'react';

export const InitiativeContext = createContext({});

export const useInitiativeContext = () => {
  return useContext(InitiativeContext);
};

export function InitiativesDataProvider(props) {
  const [resetInitiatives, setResetInitiatives] = useState(false);

  return (
    <InitiativeContext.Provider value={{ resetInitiatives, setResetInitiatives }}>
      {props.children}
    </InitiativeContext.Provider>
  );
}
