import {
  Breadcrumbs,
  Button,
  Dialog,
  Divider,
  Grid,
  Hidden,
  IconButton,
  ImageList,
  ImageListItem,
  Typography,
  Box,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import ItemData from './itemData'
import LanguageData from './LanguageData'
import ImagesFolder from '../ImagesShare/ImagesFolder'
import close from '../../../asserts/images/sharing/Close.svg'
import groupIcon from '../../../asserts/images/sharing/Illustration.svg'
import NoImageFound from '../../../asserts/images/noImageFound.jpg'
import { saveAs } from 'file-saver'
import './Share.css'
import { useSelector } from 'react-redux'
import { ImageLoader } from '../../ReusableComponents.js/ImageLoader'
import DownloadScore from '../../ReusableComponents.js/DownloadScore'
import { getIds } from '../../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../../utils/AuditLogs'
import zipIcon from '../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../asserts/images/FileIcon.svg'
import unknownImg from '../../../asserts/images/folders.png'
import { handleFileName } from '../../ReusableComponents.js/reuseMethods'
import { pushTag } from '../../../utils/gtmservice'

const ImagesModal = ({ open, setOpen }) => {
  const [folderName, setFolderName] = useState()
  const [languageName, setLanguageName] = useState()
  const [viewImage, setViewImage] = useState('')
  const [allImages, setAllImages] = useState([])
  const imagetemplates = useSelector(
    (state) => state?.socialMediaKit?.data !== [] && state?.socialMediaKit?.data?.imagetemplates
  )
  const [imageResponse, setImageResponse] = useState([])
  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: folderName == 'Certificate' ? 'download_certificate_pop_up' : 'download_image_pop_up',
      message: message,
    })
  }

  const handleClose = () => {
    setOpen(false)
    setFolderName('')
    setLanguageName('')
    setViewImage('')
  }
  const navigate = useNavigate()

  const onFolderClick = () => {
    setLanguageName('')
    setViewImage('')
    navigate(<ItemData folderName={folderName} imageResponse={imageResponse} />)
  }

  const onImagesLink = () => {
    setFolderName('')
    setViewImage('')
    setLanguageName('')
  }
  useEffect(() => {
    setImageResponse(imagetemplates && imagetemplates)
    setFolderName('')
    setViewImage('')
    setLanguageName('')
  }, [imagetemplates])

  const downloadAllImg = () => {
    allImages.forEach(async (data) => {
      await fetch(data, { headers: { 'Cache-Control': 'no-cache' } })
        .then((res) => res.blob())
        .then((blob) => {
          saveAs(blob, `image.${data?.split('.')?.pop()}`)
        })
    })
  }

  const downloadImg = async () => {
    await fetch(viewImage, { headers: { 'Cache-Control': 'no-cache' } })
      .then((res) => res.blob())
      .then((blob) => {
        saveAs(blob, `image.${viewImage?.split('.')?.pop()}`)
      })
  }

  useEffect(() => {
    if (open) {
      sendMediaImagesPopUpLog('pageload')
    }
  }, [open])

  useEffect(() => {
    if (folderName) {
      const parameters = {
        useridtype: getIds(),
        content_type: folderName,
      }
      sendMediaImagesLanguagePopUpLog('pageload', parameters)
    }
  }, [folderName])

  useEffect(() => {
    if (languageName) {
      const parameters = {
        useridtype: getIds(),
        content_type: folderName,
        language_selected: languageName,
      }
      sendMediaImagesLanguagePopUpLog('select_folder', parameters)
    }
  }, [languageName])

  const sendMediaImagesPopUpLog = async (type) => {
    const parameters = {
      useridtype: getIds(),
    }
    await makeLogRequest('social_media_images_popup', type, parameters)
  }

  const sendMediaImagesLanguagePopUpLog = async (type, parameters) => {
    await makeLogRequest('sm_images_language_folder_selection', type, parameters)
  }

  const sendDowanloadImagesLog = async (type) => {
    const parameters = {
      useridtype: getIds(),
      content_type: folderName,
      language_selected: languageName,
    }
    sendMediaImagesLanguagePopUpLog(type, parameters)
  }

  return (
    <Dialog
      disableEscapeKeyDown
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '70%',
            minWidth: '70%',
            minHeight: '90%', // Set your width here
          },
        },
      }}
    >
      <img
        src={close}
        className="cancel-icon cursorshow"
        onClick={() => {
          sendMediaImagesPopUpLog('close')
          handleClose()
        }}
      />
      <Box className="DialogBox dia-div-st">
        <Typography
          sx={{
            fontFamily: 'HK Grotesk',
            color: '#2e739c',
            fontWeight: '700',
            fontSize: '20px',
            mt: 1,
            ml: 1,
          }}
        >
          {folderName == 'Certificate' ? 'Certificate' : 'Images'}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'HK Grotesk',
            color: '#2C2C2C',
            fontWeight: '700',
            fontSize: '16px',
            ml: 1,
          }}
        >
          {folderName == 'Certificate'
            ? 'Select and download the certificate.'
            : 'Select and download the Images for social media & outdoor banners from the below directory.'}
        </Typography>
        <Divider
          sx={{
            border: '1px solid #356f92',
            width: '98%',
            marginTop: '16px',
            marginBottom: '16px',
            marginLeft: '9px',
          }}
        />
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            fontFamily: 'HK Grotesk',
            color: '#2C2C2C',
            fontWeight: '700',
            fontSize: '16px',
            mt: 1,
          }}
        >
          <Link underline="hover" className="custom-link imgs-style" href="/" onClick={onImagesLink}>
            Images
          </Link>
          {folderName && (
            <Link onClick={onFolderClick} href="/">
              {folderName && folderName}
            </Link>
          )}
          {languageName && <Link>{languageName && languageName}</Link>}
        </Breadcrumbs>
        <Box className="grid-div1-style">
          <Grid container spacing={2}>
            <Grid
              item
              xs={3}
              md={5.2}
              margin="12px"
              sx={{ backgroundColor: '#f5f6fa', borderRadius: '20px', minHeight: '70vh' }}
            >
              {' '}
              {!folderName ? (
                <ItemData
                  setFolderName={(item) => {
                    let sqId = item === 'Certificate' ? 'certificates' : item?.toLowerCase().replace(' ', '_')
                    sendMediaImagesPopUpLog(sqId)
                    setFolderName(item)
                  }}
                  imageResponse={imageResponse}
                />
              ) : folderName && languageName ? (
                <ImagesFolder
                  imageResponse={imageResponse}
                  languageData={languageName}
                  setViewImage={setViewImage}
                  folderName={folderName}
                  setAllImages={setAllImages}
                />
              ) : (
                <LanguageData setLanguageName={setLanguageName} imageResponse={imageResponse} folderName={folderName} />
              )}
            </Grid>
            <Grid
              item
              xs={9}
              md={6}
              margin="15px"
              padding="15px"
              sx={{
                backgroundColor: '#fff',
                border: '1px solid #DDDDDD',
                borderRadius: '20px',
              }}
            >
              {viewImage ? (
                <>
                  <Box
                    onClick={() => {
                      setViewImage(viewImage)
                    }}
                  >
                    {viewImage.split('.').pop() === 'zip' ||
                    ['ai', 'postscript'].includes(viewImage.split('.').pop()) ? (
                      <ImageListItem
                        // key={}
                        className="bgfolder cursorshow"
                        sx={{
                          margin: '107px',
                          width: '300px !important',
                          padding: '10px',
                          aspectRatio: '1 / 0.9 !important',
                        }}
                      >
                        <img
                          className="img-zipI"
                          // key={i}
                          src={
                            viewImage.split('.').pop() === 'zip'
                              ? zipIcon
                              : ['ai', 'postscript'].includes(viewImage.split('.').pop()) && fileIcon
                          } //?w=164&h=164&fit=crop&auto=format
                          srcSet={
                            viewImage.split('.').pop() === 'zip'
                              ? zipIcon
                              : ['ai', 'postscript'].includes(viewImage.split('.').pop()) && fileIcon
                          } //?w=164&h=164&fit=crop&auto=format
                          // alt={viewImage.title}
                        />
                        <Typography variant="subtitle1" sx={subtitleStyles}>
                          {handleFileName(viewImage)}
                        </Typography>
                      </ImageListItem>
                    ) : (
                      <ImageLoader
                        src={`${
                          viewImage.split('.').pop() === 'zip'
                            ? zipIcon
                            : ['ai', 'postscript'].includes(viewImage.split('.').pop())
                            ? fileIcon
                            : viewImage
                        }`} //?w=164&h=164&fit=crop&auto=format
                        srcSet={`${
                          viewImage.split('.').pop() === 'zip'
                            ? zipIcon
                            : ['ai', 'postscript'].includes(viewImage.split('.').pop())
                            ? fileIcon
                            : viewImage
                        }`} //w=164&h=164&fit=crop&auto=format&dpr=2 2x
                        width={'100%'}
                        loaderHeight={'150px'}
                        loading="lazy"
                        defaultImg={unknownImg}
                        subtitleStyles={subtitleStyles}
                        item={viewImage}
                        extrawidth={true}
                      />
                    )}
                    {/* <img
                      src={`${viewImage}?w=164&h=164&fit=crop&auto=format`}
                      // src={`${NoImageFound}?w=164&h=164&fit=crop&auto=format`}
                      srcSet={`${viewImage}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                      // alt={viewImage.subTitle}
                      loading="lazy"
                      width={"100%"}
                    /> */}
                    <Box className="download-st">
                      <Button
                        className="button-tr-2"
                        onClick={() => {
                          sendGALog('download')
                          sendDowanloadImagesLog('download')
                          downloadImg()
                        }}
                      >
                        Download
                      </Button>
                      <Button
                        className="button-tr-citizen-cancel"
                        onClick={() => {
                          sendGALog('download_all')
                          sendDowanloadImagesLog('download_all')
                          downloadAllImg()
                        }}
                      >
                        Download All
                      </Button>
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  <Box className="imagemodalfilemana">
                    <img src={groupIcon} />
                    <Typography
                      sx={{
                        fontFamily: 'HK Grotesk',
                        color: '#2e739c',
                        fontWeight: '700',
                        fontSize: '20px',
                        mt: 1,
                      }}
                    >
                      Select an Item to download
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: 'HK Grotesk',
                        color: '#2C2C2C',
                        fontWeight: '700',
                        fontSize: '16px',
                      }}
                    >
                      Nothing is selected
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  )
}

export default ImagesModal
