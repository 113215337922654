import { SOCIAL_MEDIA_KIT_FAILURE, SOCIAL_MEDIA_KIT_REQUEST, SOCIAL_MEDIA_KIT_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  error: '',
};

export const socialMediaKit = (state = initialState, action) => {
  switch (action.type) {
    case SOCIAL_MEDIA_KIT_REQUEST:
      return {
        state,
      };
    case SOCIAL_MEDIA_KIT_SUCCESS:
      return {
        data: action.payload,
      };
    case SOCIAL_MEDIA_KIT_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
