import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CrossIcon from '../asserts/images/Close.svg'
import { IconButton } from '@mui/material'

export default function LogoutDialog(props) {
  const { openLogoutDialog, handleCloseLogoutDialog, setCheckLogoutClick, isSessionTimedOut } = props

  const logoutUser = () => {
    localStorage.removeItem('leaderBoardSelectedItem')
    setCheckLogoutClick(true)
  }
  return (
    <div>
      <Dialog
        open={openLogoutDialog}
        onClose={handleCloseLogoutDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ maxWidth: '550px', margin: '0 auto' }}
      >
        <img onClick={handleCloseLogoutDialog} src={CrossIcon} className="closeIcon" />
        <DialogContent>
          <DialogContentText
            className="pre-line"
            sx={{
              minHeight: '50px',
              display: 'flex',
              alignItems: 'center',
              color: '#6C6C6C',
              fontFamily: 'HK Grotesk',
              fontSize: '22px',
              textAlign: 'center',
              fontWeight: 'Bold',
              mt: 2,
              justifyContent: 'center',
            }}
          >
            {isSessionTimedOut
              ? 'Session Expired!\nPlease log out of Mera Saansad website and login again'
              : 'Are you sure you want to log out?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            className="button-tr-2"
            sx={{ fontFamily: 'HK Grotesk', ml: 2, width: '180px', mb: 3, height: '44px' }}
            onClick={logoutUser}
          >
            Log out
          </Button>
          {!isSessionTimedOut && (
            <Button
              className="button-tr-citizen-cancel"
              sx={{ fontFamily: 'HK Grotesk', ml: 2, width: '180px', mb: 3, height: '44px' }}
              onClick={handleCloseLogoutDialog}
            >
              Cancel
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  )
}
