import { Card, Box, CardMedia, Typography, Avatar } from '@mui/material'
import './ProfileCardComponent.css'

const ProfileCardComponent = ({ ProfileIcon, onClick, rightArrow, ProfileCardComponentText, AddMembersCard }) => {
  const cardClassName = AddMembersCard ? 'small-card1' : 'small-card2'

  return (
    <Card
      className={`small-cards clickable cursorshow ${cardClassName}`}
      sx={{ borderRadius: '10px' }}
      onClick={onClick}
    >
      <Box mb={0} sx={{ height: '19px', width: '19px' }}>
        <CardMedia component="img" className="mr-3" src={ProfileIcon} alt="" />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }} mt={2}>
        <Typography
          className="right-card-bottom-elements-text mb-0"
          dangerouslySetInnerHTML={{ __html: ProfileCardComponentText }}
        />
        <Avatar
          variant="square"
          className="align-bottom right-card-bottom-elements-right-arrow-icon"
          src={rightArrow}
        />
      </Box>
    </Card>
  )
}

export default ProfileCardComponent
