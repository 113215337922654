import { Grid, Breadcrumbs, Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'

const SettingsBreadCrumb = ({ selectedOption }) => {
  let navigate = useNavigate()

  const backToSettings = () => {
    navigate('/settings')
  }

  return (
    <Grid container sx={{ mb: 2, mt: 1 }}>
      <Breadcrumbs aria-label="breadcrumb" separator={<Typography variant="h5">/</Typography>}>
        <Button className="btn" underline="hover" onClick={backToSettings}>
          <Typography className="breadcrumb-btn1 settings-brdcrmb ">Settings</Typography>
        </Button>
        {selectedOption && (
          <Button underline="hover" className="button-underline-hover2  btn">
            <Typography className="font-family-weight ml-0-4">{selectedOption}</Typography>
          </Button>
        )}
      </Breadcrumbs>
    </Grid>
  )
}

export default SettingsBreadCrumb
