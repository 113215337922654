import { Typography, Box, Card, CardMedia } from '@mui/material'
import EmptyOngoing from '../../asserts/EmptyStateSVGs/EmptyOngoing.svg'
import EmptySeva from '../../asserts/EmptyStateSVGs/EmptySevas.svg'
import EmptyBooks from '../../asserts/EmptyStateSVGs/EmptyBooks.svg'
import EmptyProjects from '../../asserts/EmptyStateSVGs/EmptyProjects.svg'
import EmptyEvents from '../../asserts/EmptyStateSVGs/EmptyEvents.svg'
import './EmptyStateCard.css'

const EmptyDataCard = ({ cardFor }) => {
  let displayImg = null
  let cardTitle = ''
  let cardDetails = ''

  switch (cardFor) {
    case 'Development Projects':
      displayImg = EmptyProjects
      cardTitle = 'Development Projects'
      cardDetails = 'by MPs will be shown here'
      break
    case 'Seva aur Samarpan Campaign':
      displayImg = EmptySeva
      cardTitle = 'Events by MPs will be visible here.'
      cardDetails = "Don't keep your followers waiting any longer - start creating your own event now!"
      break
    case 'Media Coverage of':
      displayImg = EmptyBooks
      cardTitle = 'Media Coverage'
      cardDetails = 'by MPs will be shown here'
      break
    case 'Miscellaneous of':
      displayImg = EmptyBooks
      cardTitle = 'Miscellaneous'
      cardDetails = 'by MPs will be shown here'
      break
    case 'Initiative Report':
      displayImg = EmptyOngoing
      cardTitle = 'Reports by MPs will be visible here.'
      cardDetails = 'Successfully completed an initiative? Create initiative reports & showcase your work!'
      break
    case 'Initiative Event':
      displayImg = EmptyEvents
      cardTitle = 'Events by MPs will be visible here.'
      cardDetails = "Don't keep your followers waiting any longer start creating your own event now!"
      break
    default:
      displayImg = EmptyOngoing
      cardTitle = 'Ongoing initiatives'
      cardDetails = 'by MPs will be shown here'
      break
  }

  return (
    <>
      <Box className="empty-card-wrapper-div">
        <Card>
          <CardMedia component="img" src={displayImg} alt="Empty Data" className="empty-card-display-image" />
        </Card>
        <Box className="empty-card-details-wrapper">
          <Typography className="empty-card-title">{cardTitle}</Typography>
          <Typography className="empty-card-detail">{cardDetails}</Typography>
        </Box>
      </Box>
    </>
  )
}

export default EmptyDataCard
