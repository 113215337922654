import { Box, Typography, Button } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useState } from 'react'
import Sort from './SortComponent/Sort'
const Sortbutton = ({ mpIdLists, setMpIdLists, sortBy, setSortBy }) => {
  const [sortName, setSortName] = useState('Most Supported')
  const [sortByUpdated, setSortByUpdated] = useState(false)
  const [openFilter, setOpenfilter] = useState(false)

  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
  }
  return (
    <>
      <Box className="filtersortselect">
        <Typography className="Filtersort-title">Sort</Typography>
        <Button
          aria-controls="select-menu"
          className="Select-component-sortFilter arrow"
          aria-haspopup="true"
          onClick={handleClick}
          variant="outlined"
        >
          <Typography className="stateName">{sortBy === 'interest' ? sortName : 'Most Recent'}</Typography>

          <ArrowDropDownIcon />
        </Button>
        {open && (
          <Sort
            open={open}
            setOpen={setOpen}
            mpIdLists={mpIdLists}
            sortBy={sortBy}
            setSortBy={setSortBy}
            setSortByUpdated={setSortByUpdated}
            setOpenfilter={setOpenfilter}
            setSortName={setSortName}
          />
        )}
      </Box>
    </>
  )
}
export default Sortbutton
