import { FETCH_HIGHLIGHTS_FAILURE, FETCH_HIGHLIGHTS_REQUEST, FETCH_HIGHLIGHTS_SUCCESS } from './types'
import axiosInstance from '../../utils/api'

export const fetchAllHighlightsRequest = () => {
  return {
    type: FETCH_HIGHLIGHTS_REQUEST,
  }
}
export const fetchAllHighlightsSuccess = (carMakes) => {
  return {
    type: FETCH_HIGHLIGHTS_SUCCESS,
    payload: carMakes,
  }
}
export const fetchAllHighlightsFailure = (error) => {
  return {
    type: FETCH_HIGHLIGHTS_FAILURE,
    payload: error,
  }
}

export const getAllHighlights = (mpId, filter, tenure) => async (dispatch) => {
  dispatch(fetchAllHighlightsRequest)
  return axiosInstance
    .get(
      `/api/getglobalhighlightcount/${mpId ? mpId : 0}${
        tenure == 'Modi2.0' || tenure == 'Modi 2.0' ? '/' + '2.0' : ''
      }?filter=${filter}`
    )
    .then((response) => {
      const result = response.data
      dispatch(fetchAllHighlightsSuccess(result))
      return response
    })
    .catch((error) => {
      const errorMsg = error.message
      dispatch(fetchAllHighlightsFailure(errorMsg))
    })
}
