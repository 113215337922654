export const FETCH_MPLIST_REQUEST = 'FETCH_MPLIST_REQUEST'
export const FETCH_MPLIST_SUCCESS = 'FETCH_MPLIST_SUCCESS'
export const FETCH_MPLIST_FAILURE = 'FETCH_MPLIST_FAILURE'

export const UPDATE_MPLIST_SUCCESS = 'UPDATE_MPLIST_SUCCESS'

export const FETCH_HIGHLIGHTS_REQUEST = 'FETCH_HIGHLIGHTS_REQUEST'
export const FETCH_HIGHLIGHTS_SUCCESS = 'FETCH_HIGHLIGHTS_SUCCESS'
export const FETCH_HIGHLIGHTS_FAILURE = 'FETCH_HIGHLIGHTS_FAILURE'

export const FETCH_EVENTS_LIST_REQUEST = 'FETCH_EVENTS_LIST_REQUEST'
export const FETCH_EVENTS_LIST_SUCCESS = 'FETCH_EVENTS_LIST_SUCCESS'
export const FETCH_EVENTS_LIST_FAILURE = 'FETCH_EVENTS_LIST_FAILURE'

export const FETCH_MEDIA_COVERAGE_LIST_REQUEST = 'FETCH_MEDIA_COVERAGE_LIST_REQUEST'
export const FETCH_MEDIA_COVERAGE_LIST_SUCCESS = 'FETCH_MEDIA_COVERAGE_LIST_SUCCESS'
export const FETCH_MEDIA_COVERAGE_LIST_FAILURE = 'FETCH_MEDIA_COVERAGE_LIST_FAILURE'

export const FETCH_MEDIA_COVERAGE_TYPES_LIST_REQUEST = 'FETCH_MEDIA_COVERAGE_TYPES_LIST_REQUEST'
export const FETCH_MEDIA_COVERAGE_TYPES_LIST_SUCCESS = 'FETCH_MEDIA_COVERAGE_TYPES_LIST_SUCCESS'
export const FETCH_MEDIA_COVERAGE_TYPES_LIST_FAILURE = 'FETCH_MEDIA_COVERAGE_TYPES_LIST_FAILURE'

export const FETCH_DEVELOPMENT_PROJECTS_LIST_REQUEST = 'FETCH_DEVELOPMENT_PROJECTS_LIST_REQUEST'
export const FETCH_DEVELOPMENT_PROJECTS_LIST_SUCCESS = 'FETCH_DEVELOPMENT_PROJECTS_LIST_SUCCESS'
export const FETCH_DEVELOPMENT_PROJECTS_LIST_FAILURE = 'FETCH_DEVELOPMENT_PROJECTS_LIST_FAILURE'

export const FETCH_DEVELOPMENT_STATUS_LIST_REQUEST = 'FETCH_DEVELOPMENT_STATUS_LIST_REQUEST'
export const FETCH_DEVELOPMENT_STATUS_LIST_SUCCESS = 'FETCH_DEVELOPMENT_STATUS_LIST_SUCCESS'
export const FETCH_DEVELOPMENT_STATUS_LIST_FAILURE = 'FETCH_DEVELOPMENT_STATUS_LIST_FAILURE'

export const POST_CREATE_EVENT_FAILURE = 'POST_CREATE_EVENT_FAILURE'
export const POST_CREATE_EVENT_REQUEST = 'POST_CREATE_EVENT_REQUEST'
export const POST_CREATE_EVENT_SUCCESS = 'POST_CREATE_EVENT_SUCCESS'

export const SEARCH_MP_REQUEST = 'SEARCH_MP_REQUEST'
export const SEARCH_MP_SUCCESS = 'SEARCH_MP_SUCCESS'
export const SEARCH_MP_FAILURE = 'SEARCH_MP_FAILURE'

export const INDIVIDUAL_MP_VIEW_REQUEST = 'INDIVIDUAL_MP_VIEW_REQUEST'
export const INDIVIDUAL_MP_VIEW_SUCCESS = 'INDIVIDUAL_MP_VIEW_SUCCESS'
export const INDIVIDUAL_MP_VIEW_FAILURE = 'INDIVIDUAL_MP_VIEW_FAILURE'

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST'
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS'
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE'

export const GET_EVENT_REQUEST = 'GET_EVENT_REQUEST'
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS'
export const GET_EVENT_FAILURE = 'GET_EVENT_FAILURE'

export const GET_STATE_REQUEST = 'GET_STATE_REQUEST'
export const GET_STATE_SUCCESS = 'GET_STATE_SUCCESS'
export const GET_STATE_FAILURE = 'GET_STATE_FAILURE'
export const POST_INTIATIVE_REQUEST = 'POST_INTIATIVE_REQUEST'
export const POST_INTIATIVE_SUCCESS = 'POST_INTIATIVE_SUCCESS'
export const POST_INTIATIVE_FAILURE = 'POST_INTIATIVE_FAILURE'

export const ASSIGN_MP_REQUEST = 'ASSIGN_MP_REQUEST'
export const ASSIGN_MP_SUCCESS = 'ASSIGN_MP_SUCCESS'
export const ASSIGN_MP_FAILURE = 'ASSIGN_MP_FAILURE'

export const POST_CREATE_OPEDS_FAILURE = 'POST_CREATE_OPEDS_FAILURE'
export const POST_CREATE_OPEDS_REQUEST = 'POST_CREATE_OPEDS_REQUEST'
export const POST_CREATE_OPEDS_SUCCESS = 'POST_CREATE_OPEDS_SUCCESS'

export const POST_CREATE_DEVELOPMENT_PROJECT_FAILURE = 'POST_CREATE_DEVELOPMENT_PROJECT_FAILURE'
export const POST_CREATE_DEVELOPMENT_PROJECT_REQUEST = 'POST_CREATE_DEVELOPMENT_PROJECT_REQUEST'
export const POST_CREATE_DEVELOPMENT_PROJECT_SUCCESS = 'POST_CREATE_DEVELOPMENT_PROJECT_SUCCESS'

export const ALL_INITIATIVES_REQUEST = 'ALL_INITIATIVES_REQUEST'
export const ALL_INITIATIVES_SUCCESS = 'ALL_INITIATIVES_SUCCESS'
export const ALL_INITIATIVES_FAILURE = 'ALL_INITIATIVES_FAILURE'

export const CREATE_INITIATIVE_REPORT_REQUEST = 'CREATE_INITIATIVE_REPORT_REQUEST'
export const CREATE_INITIATIVE_REPORT_SUCCESS = 'CREATE_INITIATIVE_REPORT_SUCCESS'
export const CREATE_INITIATIVE_REPORT_FAILURE = 'CREATE_INITIATIVE_REPORT_FAILURE'
export const INITIATIVE_LIST_FAILURE = 'INITIATIVE_LIST_FAILURE'
export const INITIATIVE_LIST_REQUEST = 'INITIATIVE_LIST_REQUEST'
export const INITIATIVE_LIST_SUCCESS = 'INITIATIVE_LIST_SUCCESS'

export const FETCH_ONGOING_SEVA_INITIATIVES_LIST_REQUEST = 'FETCH_ONGOING_SEVA_INITIATIVES_LIST_REQUEST'
export const FETCH_ONGOING_SEVA_INITIATIVES_LIST_SUCCESS = 'FETCH_ONGOING_SEVA_INITIATIVES_LIST_SUCCESS'
export const FETCH_ONGOING_SEVA_INITIATIVES_LIST_FAILURE = 'FETCH_ONGOING_SEVA_INITIATIVES_LIST_FAILURE'

export const FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_REQUEST = 'FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_REQUEST'
export const FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_SUCCESS = 'FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_SUCCESS'
export const FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_FAILURE = 'FETCH_ONGOING_SEVA_INITIATIVES_LIST_WITHID_FAILURE'

export const FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_REQUEST =
  'FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_REQUEST'
export const FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_SUCCESS =
  'FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_SUCCESS'
export const FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_FAILURE =
  'FETCH_ONGOING_SEVA_INITIATIVES_LIST_BY_MP_ID_FAILURE'

export const FETCH_RANKS_COUNT_REQUEST = 'FETCH_RANKS_COUNT_REQUEST'
export const FETCH_RANKS_COUNT_SUCCESS = 'FETCH_RANKS_COUNT_SUCCESS'
export const FETCH_RANKS_COUNT_FAILURE = 'FETCH_RANKS_COUNT_FAILURE'

export const CREATE_INITIATIVE_REPORT_BY_REQUEST = 'CREATE_INITIATIVE_REPORT_BY_REQUEST'
export const CREATE_INITIATIVE_REPORT_BY_SUCCESS = 'CREATE_INITIATIVE_REPORT_BY_SUCCESS'
export const CREATE_INITIATIVE_REPORT_BY_FAILURE = 'CREATE_INITIATIVE_REPORT_BY_FAILURE'

export const FETCH_INITIATIVE_REPORT_BY_ID_LIST_REQUEST = 'FETCH_INITIATIVE_REPORT_BY_ID_LIST_REQUEST'
export const FETCH_INITIATIVE_REPORT_BY_ID_LIST_SUCCESS = 'FETCH_INITIATIVE_REPORT_BY_ID_LIST_SUCCESS'
export const FETCH_INITIATIVE_REPORT_BY_ID_LIST_FAILURE = 'FETCH_INITIATIVE_REPORT_BY_ID_LIST_FAILURE'

export const FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_REQUEST = 'FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_REQUEST'
export const FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_SUCCESS = 'FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_SUCCESS'
export const FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_FAILURE = 'FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_FAILURE'

export const FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_REQUEST = 'FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_REQUEST'
export const FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_SUCCESS = 'FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_SUCCESS'
export const FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_FAILURE = 'FETCH_INITIATIVE_EVENT_BY_INITIATIVE_ID_FAILURE'

export const INITIATIVES_OVERVIEW_REQUEST = 'INITIATIVES_OVERVIEW_REQUEST'
export const INITIATIVES_OVERVIEW_SUCCESS = 'INITIATIVES_OVERVIEW_SUCCESS'
export const INITIATIVES_OVERVIEW_FAILURE = 'INITIATIVES_OVERVIEW_FAILURE'

export const SORT_REQUEST = 'SORT_REQUEST'
export const SORT_SUCCESS = 'SORT_SUCCESS'
export const SORT_FAILURE = 'SORT_FAILURE'

export const SEARCH_INITIATIVE_REQUEST = 'SEARCH_INITIATIVE_REQUEST'
export const SEARCH_INITIATIVE_SUCCESS = 'SEARCH_INITIATIVE_SUCCESS'
export const SEARCH_INITIATIVE_FAILURE = 'SEARCH_INITIATIVE_FAILURE'

export const TEXT_REQUEST = 'TEXT_REQUEST'
export const TEXT_SUCCESS = 'TEXT_SUCCESS'
export const TEXT_FAILURE = 'TEXT_FAILURE'

export const IMAGE_REQUEST = 'IMAGE_REQUEST'
export const IMAGE_SUCCESS = 'IMAGE_SUCCESS'
export const IMAGE_FAILURE = 'IMAGE_FAILURE'

export const VIDEO_REQUEST = 'VIDEO_REQUEST'
export const VIDEO_SUCCESS = 'VIDEO_SUCCESS'
export const VIDEO_FAILURE = 'VIDEO_FAILURE'

export const SOCIAL_MEDIA_KIT_REQUEST = 'SOCIAL_MEDIA_KIT_REQUEST'
export const SOCIAL_MEDIA_KIT_SUCCESS = 'SOCIAL_MEDIA_KIT_SUCCESS'
export const SOCIAL_MEDIA_KIT_FAILURE = 'SOCIAL_MEDIA_KIT_FAILURE'

export const FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_REQUEST =
  'FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_REQUEST'
export const FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_SUCCESS =
  'FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_SUCCESS'
export const FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_FAILURE =
  'FETCH_INITIATIVE_REPORT_BY_INITIATIVE_ID_AND_MP_ID_FAILURE'

export const FETCH_LANGUAGE_LIST_REQUEST = 'FETCH_LANGUAGE_LIST_REQUEST'
export const FETCH_LANGUAGE_LIST_SUCCESS = 'FETCH_LANGUAGE_LIST_SUCCESS'
export const FETCH_LANGUAGE_LIST_FAILURE = 'FETCH_LANGUAGE_LIST_FAILURE'

export const FETCH_EVENTS_LIST_BY_MP_ID_REQUEST = 'FETCH_EVENTS_LIST_BY_MP_ID_REQUEST'
export const FETCH_EVENTS_LIST_BY_MP_ID_SUCCESS = 'FETCH_EVENTS_LIST_BY_MP_ID_SUCCESS'
export const FETCH_EVENTS_LIST_BY_MP_ID_FAILURE = 'FETCH_EVENTS_LIST_BY_MP_ID_FAILURE'

export const FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST = 'FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST'
export const FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS = 'FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS'
export const FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE = 'FETCH_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE'

export const FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_REQUEST = 'FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_REQUEST'
export const FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_SUCCESS = 'FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_SUCCESS'
export const FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_FAILURE = 'FETCH_DEVELOPMENT_PROJECTS_LIST_BY_MP_ID_FAILURE'

export const FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_REQUEST = 'FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_REQUEST'
export const FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_SUCCESS = 'FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_SUCCESS'
export const FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_FAILURE = 'FETCH_SOCIAL_MEDIA_INITIATIVES_BY_ID_FAILURE'

export const FETCH_MOST_VIEWED_TWEETS_REQUEST = 'FETCH_MOST_VIEWED_TWEETS_REQUEST'
export const FETCH_MOST_VIEWED_TWEETS_SUCCESS = 'FETCH_MOST_VIEWED_TWEETS_SUCCESS'
export const FETCH_MOST_VIEWED_TWEETS_FAILURE = 'FETCH_MOST_VIEWED_TWEETS_FAILURE'

export const FETCH_MOST_RETWEETED_TWEETS_REQUEST = 'FETCH_MOST_RETWEETED_TWEETS_REQUEST'
export const FETCH_MOST_RETWEETED_TWEETS_SUCCESS = 'FETCH_MOST_RETWEETED_TWEETS_SUCCESS'
export const FETCH_MOST_RETWEETED_TWEETS_FAILURE = 'FETCH_MOST_RETWEETED_TWEETS_FAILURE'

export const GET_LOGIN_REQUEST = ' GET_LOGIN_REQUEST'
export const GET_LOGIN_SUCCESS = ' GET_LOGIN_SUCCESS'
export const GET_LOGIN_FAILURE = ' GET_LOGIN_FAILURE'
export const FETCH_TWITTER_MOST_NEW_FOLLOWERS_REQUEST = 'FETCH_TWITTER_MOST_NEW_FOLLOWERS_REQUEST'
export const FETCH_TWITTER_MOST_NEW_FOLLOWERS_SUCCESS = 'FETCH_TWITTER_MOST_NEW_FOLLOWERS_SUCCESS'
export const FETCH_TWITTER_MOST_NEW_FOLLOWERS_FAILURE = 'FETCH_TWITTER_MOST_NEW_FOLLOWERS_FAILURE'

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST'
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS'
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE'
export const ERROR_UNAUTH = 'ERROR_UNAUTH'
export const ERROR_CLOSED_CONNECTION = 'ERROR_CLOSED_CONNECTION'

export const UPLOAD_MEDIA_REQUEST = 'UPLOAD_MEDIA_REQUEST'
export const UPLOAD_MEDIA_SUCCESS = 'UPLOAD_MEDIA_SUCCESS'
export const UPLOAD_MEDIA_FAILURE = 'UPLOAD_MEDIA_FAILURE'

export const FETCH_OPED_AND_BOOKS_LIST_REQUEST = 'FETCH_OPED_AND_BOOKS_LIST_REQUEST'
export const FETCH_OPED_AND_BOOKS_LIST_SUCCESS = 'FETCH_OPED_AND_BOOKS_LIST_SUCCESS'
export const FETCH_OPED_AND_BOOKS_LIST_FAILURE = 'FETCH_OPED_AND_BOOKS_LIST_FAILURE'

export const FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_REQUEST = 'FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_REQUEST'
export const FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_SUCCESS = 'FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_SUCCESS'
export const FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_FAILURE = 'FETCH_OPED_AND_BOOKS_LIST_BY_MP_ID_FAILURE'

export const FETCH_ONLY_MEDIA_COVERAGE_LIST_REQUEST = 'FETCH_ONLY_MEDIA_COVERAGE_LIST_REQUEST'
export const FETCH_ONLY_MEDIA_COVERAGE_LIST_SUCCESS = 'FETCH_ONLY_MEDIA_COVERAGE_LIST_SUCCESS'
export const FETCH_ONLY_MEDIA_COVERAGE_LIST_FAILURE = 'FETCH_ONLY_MEDIA_COVERAGE_LIST_FAILURE'

export const FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST = 'FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_REQUEST'
export const FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS = 'FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_SUCCESS'
export const FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE = 'FETCH_ONLY_MEDIA_COVERAGE_LIST_BY_MP_ID_FAILURE'

export const SEARCH_SEVA_EVENTS_REQUEST = 'SEARCH_SEVA_EVENTS_REQUEST'
export const SEARCH_SEVA_EVENTS_SUCCESS = 'SEARCH_SEVA_EVENTS_SUCCESS'
export const SEARCH_SEVA_EVENTS_FAILURE = 'SEARCH_SEVA_EVENTS_FAILURE'

export const SEARCH_MEDIA_COVERAGE_REQUEST = 'SEARCH_MEDIA_COVERAGE_REQUEST'
export const SEARCH_MEDIA_COVERAGE_SUCCESS = 'SEARCH_MEDIA_COVERAGE_SUCCESS'
export const SEARCH_MEDIA_COVERAGE_FAILURE = 'SEARCH_MEDIA_COVERAGE_FAILURE'

export const SEARCH_DEVELOPMENT_PROJECTS_REQUEST = 'SEARCH_DEVELOPMENT_PROJECTS_REQUEST'
export const SEARCH_DEVELOPMENT_PROJECTS_SUCCESS = 'SEARCH_DEVELOPMENT_PROJECTS_SUCCESS'
export const SEARCH_DEVELOPMENT_PROJECTS_FAILURE = 'SEARCH_DEVELOPMENT_PROJECTS_FAILURE'

export const SEARCH_MISCELLANEOUS_REQUEST = 'SEARCH_MISCELLANEOUS_REQUEST'
export const SEARCH_MISCELLANEOUS_SUCCESS = 'SEARCH_MISCELLANEOUS_SUCCESS'
export const SEARCH_MISCELLANEOUS_FAILURE = 'SEARCH_MISCELLANEOUS_FAILURE'

export const SEARCH_SEVA_INITIATIVES_REQUEST = 'SEARCH_SEVA_INITIATIVES_REQUEST'
export const SEARCH_SEVA_INITIATIVES_SUCCESS = 'SEARCH_SEVA_INITIATIVES_SUCCESS'
export const SEARCH_SEVA_INITIATIVES_FAILURE = 'SEARCH_SEVA_INITIATIVES_FAILURE'

export const FETCH_MASTERLIST_REQUEST = 'FETCH_MASTERLIST_REQUEST'
export const FETCH_MASTERLIST_SUCCESS = 'FETCH_MASTERLIST_SUCCESS'
export const FETCH_MASTERLIST_FAILURE = 'FETCH_MASTERLIST_FAILURE'
export const UPDATE_MASTERLIST_SUCCESS = 'UPDATE_MPLIST_SUCCESS'
export const CREATE_ALERT_REQUEST = 'CREATE_ALERT_REQUEST'
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS'
export const CREATE_ALERT_FAILURE = 'CREATE_ALERT_FAILURE'

export const GET_ALERT_REQUEST = 'GET_ALERT_REQUEST'
export const GET_ALERT_SUCCESS = 'GET_ALERT_SUCCESS'
export const GET_ALERT_FAILURE = 'GET_ALERT_FAILURE'

export const DELETE_ALERT_REQUEST = 'DELETE_ALERT_REQUEST'
export const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS'
export const DELETE_ALERT_FAILURE = 'DELETE_ALERT_FAILURE'

export const GET_ACTIVITY = 'GET_ACTIVITY'
