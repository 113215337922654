import React, { useEffect, useState } from 'react'
import { Avatar, Dialog, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import CloseIcon from '../../asserts/images/Close.svg'
import './AboutSevaScore.css'
import axiosInstance from '../../utils/api'

const AboutSevaScore = ({ showAboutSevaScore, setShowAboutSevaScore }) => {
  const [scores, setScores] = useState([])
  const titleMapping = {
    initiative_report_points: 'Initiative Report Creation',
    event_creation_points: 'Event Creation',
    devpro_creation_points: 'Add Development Projects',
    opeds_creation_points: 'Add Op-Eds/Books/Media Coverage',
    share_points: 'Share Event/ Development Project/ Op-Ed',
    members_added_points: 'Add Members to NaMo app',
    donation_points: 'Invite Donations',
    citizen_interested_points: 'Citizens interested in Events',
    citizen_following_points: 'Citizens following MP',
    twitter_perf_points: 'Twitter performance which includes Likes, Retweets, Followers, Mentions & Views',
  }

  const getScores = async () => {
    try {
      const { data } = await axiosInstance.get(`/api/mp/defined/scores`)
      return data
    } catch (error) {
      console.log(error)
      return {}
    }
  }

  useEffect(() => {
    const fetchScores = async () => {
      const scores = await getScores()
      if (Object.keys(scores)?.length) {
        const scoreList = []
        for (let item in scores) {
          if (titleMapping[item]) {
            const obj = {}
            obj.label = titleMapping[item]
            obj.value = scores[item]
            scoreList.push(obj)
          }
        }
        setScores(scoreList)
      }
    }
    fetchScores()
  }, [])

  const closeDialog = () => {
    setShowAboutSevaScore(false)
  }

  return (
    <Dialog
      open={showAboutSevaScore}
      onClose={closeDialog}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiDialog-paperScrollPaper': {
            backgroundColor: 'transparent !important',
            boxShadow: 'none !important',
          },
        },
      }}
    >
      <Box>
        <Box className="d-flex justify-content-center mb-3">
          <Avatar alt="cross" className="cursor-pointer" src={CloseIcon} height={48} width={48} onClick={closeDialog} />
        </Box>
        <Box className="table-container-div">
          <Box className="d-flex justify-content-center pt-3 pb-2 table-header-div">
            <Typography className="table-header-content-label text-white">
              Seva Score is a calculation of all the Seva activities
              <br />
              contributed on Mera Saansad, NaMo app & Twitter.
            </Typography>
          </Box>
          <Box className="d-flex flex-column align-items-center p-4">
            <Box className="px-5 div-bottom-border">
              <Typography className="table-header-content-label text-color">Score per activity</Typography>
            </Box>
            <Box className="about-sava-score__item--wrapper">
              {scores?.map((item, key) => {
                if (item?.value === 0) {
                  return <></>
                }
                if (item.label.includes('Twitter performance')) {
                  return (
                    <Box key={key} className="w-100 pt-3 d-flex justify-content-between div-bottom-border">
                      <Typography className="table-item-label">
                        {item.value} times of the cumulative {item.label}
                      </Typography>
                      <Typography className="table-header-content-label text-color font-size-22px mr-3">
                        {item.value}
                      </Typography>
                    </Box>
                  )
                }
                return (
                  <Box key={key} className="w-100 pt-3 d-flex justify-content-between div-bottom-border">
                    <Typography className="table-item-label">
                      {item.value} points per {item.label}
                    </Typography>
                    <Typography className="table-header-content-label text-color font-size-22px mr-3">
                      {item.value}
                    </Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default AboutSevaScore
