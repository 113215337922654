import { useState, useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Typography, TextField, Button } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import WhatsNewAlertAccordionComponent from 'Components/SendNotification/WhatsNewAlertAccordionComponent'
import { createAlertAction } from 'store/action/NotificationAlerts/createNotificationAlerts'
import { defaultErrorMessage } from 'utils/constant'
import { useNotificationContext } from 'utils/NotificationContext'
import ConfirmationDialog from 'Components/SendNotification/ConfirmationDialog'
import { useNavigate } from 'react-router-dom'
import Moment from 'moment'

const WhatsNewAlert = ({ selectedItem, isSetBefore, setIsSetBefore, setIsChange }) => {
  const [startDate, setStartDate] = useState(selectedItem ? selectedItem?.startDate : null)
  const [endDate, setEndDate] = useState(selectedItem ? selectedItem?.endDate : null)
  const [isFeaturesAccordionOpen, setIsFeaturesAccordionOpen] = useState(false)
  const [isRefinementsAccordionOpen, setIsRefinementsAccordionOpen] = useState(false)
  const [newFeaturesList, setNewFeaturesList] = useState(selectedItem ? selectedItem?.newFeatures : [''])
  const [refinementsList, setRefinementsList] = useState(selectedItem ? selectedItem?.refinements : [''])
  const [showErrorBorderForFeatures, setShowErrorBorderForFeatures] = useState(
    selectedItem ? [...new Array(selectedItem?.newFeatures?.length)].map(() => false) : [false]
  )
  const [showErrorBorderForRefinements, setShowErrorBorderForRefinements] = useState(
    selectedItem ? [...new Array(selectedItem?.refinements?.length)].map(() => false) : [false]
  )
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const { showNotification } = useNotificationContext()
  let navigate = useNavigate()

  const isUpdate = useMemo(() => {
    return (
      newFeaturesList[0]?.length > 0 || refinementsList[0]?.length > 0 || startDate?.length > 0 || endDate?.length > 0
    )
  }, [newFeaturesList, refinementsList, startDate, endDate])

  useEffect(() => {
    setIsChange(isUpdate)
  }, [isUpdate])

  let setTimerData = [
    {
      title: 'Start Date',
      setter: setStartDate,
      getter: startDate,
      minDate: new Date(),
    },
    {
      title: 'End Date',
      setter: setEndDate,
      getter: endDate,
      minDate: new Date(startDate),
    },
  ]

  const dateConvert = (dateStr) => {
    const date = new Date(dateStr)
    let month = date.getMonth() + 1
    let day = date.getDate()
    let year = date.getFullYear()
    const formattedDate = `${month < 10 ? '0' + month : month}/${day < 10 ? '0' + day : day}/${year}`
    return formattedDate
  }

  const resetForm = () => {
    if (!isUpdate) {
      navigate('/settings')
    } else {
      setStartDate(null)
      setEndDate(null)
      setNewFeaturesList([''])
      setRefinementsList([''])
    }
  }

  const handleSubmit = () => {
    if (
      (!newFeaturesList.length || !newFeaturesList.some((item) => item.trim() !== '')) &&
      (!refinementsList.length || !refinementsList.some((item) => item.trim() !== ''))
    ) {
      showNotification(
        'Error',
        'Either New Features or Refinements cannot be empty and should have at least 1 non-empty data'
      )
      return
    }

    if (startDate == null || endDate == null) {
      showNotification('Error', 'Please select both start and end dates')
      return
    }

    if (Moment(startDate).isAfter(endDate, 'day')) {
      showNotification('Error', 'Start Date cannot be greater than End Date')
      return
    }

    setIsConfirmationDialogOpen(true)
  }

  const setWhatsNewContent = async () => {
    const tkn = localStorage.getItem('tokenDetails')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tkn}`,
      },
    }
    let formattedStartDate = Moment(startDate).format('YYYY-MM-DD HH:mm:ss')
    let formattedEndDate = Moment(endDate)
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .format('YYYY-MM-DD HH:mm:ss')
    const payload = {
      type: 'whatsNew',
      newFeatures: newFeaturesList,
      refinements: refinementsList,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }
    try {
      const response = await dispatch(createAlertAction(null, payload, config))
      if (response.status === 200 || response.status === 201) {
        showNotification('Success', response.data.message, 'success')
        resetForm()
      } else {
        showNotification('Error', response?.data?.message || defaultErrorMessage)
      }
    } catch (error) {
      showNotification('Error', error?.message || defaultErrorMessage)
    } finally {
      setIsConfirmationDialogOpen(false)
      setIsSetBefore(false)
    }
  }
  const updateWhatsNewContent = async () => {
    const tkn = localStorage.getItem('tokenDetails')
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tkn}`,
      },
    }
    let formattedStartDate = Moment(startDate).format('YYYY-MM-DD HH:mm:ss')
    let formattedEndDate = Moment(endDate)
      .set('hour', 23)
      .set('minute', 59)
      .set('second', 59)
      .format('YYYY-MM-DD HH:mm:ss')
    const payload = {
      type: 'whatsNew',
      newFeatures: newFeaturesList,
      refinements: refinementsList,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }
    try {
      const response = await dispatch(createAlertAction(selectedItem.id, payload, config))
      if (response.status === 200 || response.status === 201) {
        showNotification('Success', response.data.message, 'success')
        resetForm()
      } else {
        showNotification('Error', response?.data?.message || defaultErrorMessage)
      }
    } catch (error) {
      showNotification('Error', error?.message || defaultErrorMessage)
    } finally {
      setIsConfirmationDialogOpen(false)
      setIsSetBefore(false)
    }
  }

  useEffect(() => {
    if (isFeaturesAccordionOpen && isRefinementsAccordionOpen) setIsRefinementsAccordionOpen(false)
  }, [isFeaturesAccordionOpen])

  useEffect(() => {
    if (isRefinementsAccordionOpen && isFeaturesAccordionOpen) setIsFeaturesAccordionOpen(false)
  }, [isRefinementsAccordionOpen])

  return (
    <Box>
      <Box className="w-50 mt-4">
        <WhatsNewAlertAccordionComponent
          title="New Features"
          dataState={[newFeaturesList, setNewFeaturesList]}
          accordionState={[isFeaturesAccordionOpen, setIsFeaturesAccordionOpen]}
          errorBorder={[showErrorBorderForFeatures, setShowErrorBorderForFeatures]}
        />
        <WhatsNewAlertAccordionComponent
          title="Refinements"
          dataState={[refinementsList, setRefinementsList]}
          accordionState={[isRefinementsAccordionOpen, setIsRefinementsAccordionOpen]}
          errorBorder={[showErrorBorderForRefinements, setShowErrorBorderForRefinements]}
        />
      </Box>
      <Box className="d-flex w-50 justify-content-between mt-1 gap-between">
        {setTimerData.map((item, index) => (
          <Box key={index} className="d-flex flex-column">
            <Typography variant="label" className="ml-2 alert-type-label">
              {item.title}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                components={{
                  OpenPickerIcon: CalendarMonthIcon,
                }}
                InputProps={{
                  sx: {
                    '& .MuiSvgIcon-root': {
                      color: '#fff',
                      background: '#356f92',
                      width: '35px',
                      height: '35px',
                      padding: '5px',
                      borderRadius: '5px',
                    },
                  },
                }}
                inputFormat="DD MMM YYYY"
                value={item.getter}
                onChange={(newValue) => {
                  item.setter(dateConvert(newValue))
                }}
                minDate={item.minDate}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      '& .MuiInputBase-input': {
                        width: '13rem', // Set your height here.
                        textTransform: 'uppercase',
                      },
                    }}
                    {...params}
                    className="bdrdate"
                    onKeyDown={(e) => {
                      e.preventDefault()
                    }}
                    autoComplete="off"
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        ))}
      </Box>
      <Box className="d-flex mt-5">
        <Button
          variant="contained"
          sx={{ p: 1, mr: 4, backgroundColor: '#ef7335', fontWeight: 'bold' }}
          className="set-btn button-tr-2-13-disa"
          onClick={handleSubmit}
        >
          {selectedItem ? 'Update' : 'Set'}
        </Button>
        <Button variant="outlined" sx={{ p: 1 }} className="set-btn button-tr-2-1" onClick={resetForm}>
          Cancel
        </Button>
      </Box>
      {isConfirmationDialogOpen && isSetBefore ? (
        <ConfirmationDialog
          isConfirmationDialogOpen={isConfirmationDialogOpen}
          message="Are you sure you want to Update this alert?"
          confirmBtnContent="UPDATE"
          rejectBtnContent="CANCEL"
          onConfirm={updateWhatsNewContent}
          onReject={() => setIsConfirmationDialogOpen(false)}
        />
      ) : (
        <ConfirmationDialog
          isConfirmationDialogOpen={isConfirmationDialogOpen}
          message="Are you sure you want to set this alert?"
          confirmBtnContent="SET"
          rejectBtnContent="CANCEL"
          onConfirm={setWhatsNewContent}
          onReject={() => setIsConfirmationDialogOpen(false)}
        />
      )}
    </Box>
  )
}

export default WhatsNewAlert
