import { ALL_INITIATIVES_FAILURE, ALL_INITIATIVES_REQUEST, ALL_INITIATIVES_SUCCESS } from './types';
import axiosInstance from '../../utils/api';
import { env } from '../../env';

export const allInitiativeRequest = () => {
  return {
    type: ALL_INITIATIVES_REQUEST,
    payload: [],
  };
};
export const allInitiativeSuccess = (value) => {
  return {
    type: ALL_INITIATIVES_SUCCESS,
    payload: value,
  };
};
export const allInitiativeFailure = (error) => {
  return {
    type: ALL_INITIATIVES_FAILURE,
    payload: error,
  };
};

export const getAllInitiative =
  (...args) =>
  async (dispatch) => {
    const query = args[0];
    const action = args[1];
    dispatch(allInitiativeRequest);
    let api;
    // if(data == "Ongoing") {

    if (action) {
      api = `/api/initiative/sortbasedonstatus/${query}/${action}`;
    } else {
      api = `/api/initiative/getbymp/${query}`;
    }
    await axiosInstance
      .get(api)
      .then((response) => {
        let result;
        // if(data == "Ongoing") {

        if (action) {
          result = response.data.flatArray;
        } else {
          result = response.data.initiative;
        }

        dispatch(allInitiativeSuccess(result));
      })
      .catch((error) => {
        const errorMsg = error.message;
        dispatch(allInitiativeFailure(errorMsg));
      });
  };

// export const activityInitiative = () => async (dispatch) => {
//     dispatch(allInitiativeRequest)
//     await axios.get(env.REACT_APP_BASE_URL + `/api/initiative/sortbasedonstatus/69/Ongoing`)
//     .then(response => {
//         const result = response.data.initiative
//         dispatch(allInitiativeSuccess(result))
//     })
//     .catch(error => {
//         const errorMsg = error.message
//         dispatch(allInitiativeFailure(errorMsg))
//     })
// }
