import React, { useEffect, useState, useRef, useMemo } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import './AddTextTemplates.css'
import CrossIcon from '../../../asserts/images/Close.svg'
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  FormHelperText,
  DialogContent,
  DialogActions,
  CircularProgress,
  Backdrop,
  Avatar,
  Typography,
  Input,
} from '@mui/material'
import { Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import UploadFile from '@mui/icons-material/UploadFile'
import { textTemplatesSchema } from '../../../utils/Validations'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Height } from '@mui/icons-material'
import imagedelete from '../../../asserts/images/bin.png'
import { iconComponent } from '../../ReusableComponents.js/reuseMethods'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { useSelector } from 'react-redux'
import { CsvToJson } from '../../../utils/csvToJson'
import { useNotificationContext } from '../../../utils/NotificationContext'
import RemoveFileDialog from './RemoveFileDialog'
import './CreateInitiative.css'
import CircularDotLoader from '../../../asserts/images/circular_dot_loader.svg'
import { pushTag } from '../../../utils/gtmservice'
import { validateNotEmpty } from '../../ReusableComponents.js/reuseMethods'
const mainDialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiDialog-paperScrollPaper': {
      maxWidth: '860px !important',
      width: '860px',
      borderRadius: '14px!important',
    },
  },
}

const textTemplateDialogTitle = {
  fontFamily: 'HK Grotesk',
  color: '#2e739c',
  fontWeight: '700',
  textAlign: 'center',
  fontSize: '26px',
}

const scrollContainerStyle = { aspectRatio: '5 / 2.5 !important' }

const mainFormDialogStyle = {
  '& .MuiInputBase-input': { width: '180px', height: '25px!important' },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '14px',
    paddingRight: '-32px',
  },
  '& .MuiOutlinedInput-input': { paddingRight: '14px!important' },
}

const errorStyles = {
  color: '#D93025',
  position: 'absolute',
  top: '100%',
  fontWeight: 'bold',
}

const textFormDialog = {
  '& .MuiInputBase-input': { width: '180px', height: '25px' },
}

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '14px',
    },
  },
}

const textInputProps = {
  maxLength: 280,
  style: {
    height: '40px',
    width: '180px',
  },
}

const fullWidthStyle = { width: '100%' }

const hashTagTextFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '14px',
      marginTop: '-0.6px',
    },
  },
}

const hashTagInputProps = {
  maxLength: 280,
  minLength: 5,
  style: {
    height: '25px',
    width: '180px',
  },
}

const buttonBoxContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mb: 2,
  mt: 3.5,
  ml: 2,
}

const submitButtonBoxStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  mb: 2,
  mt: 2,
}

const submitButtonStyle = {
  p: 1,
  mr: 1,
  backgroundColor: '#ef7335',
  borderRadius: 4,
  position: 'relative',
  width: '250px',
}
const AddTextTemplates = ({
  handleCloseTextTemplateDialog,
  openTextTemplateDialog,
  setText,
  languages,
  editTextTemplateDetails,
  setTextFileName: setFileName,
  textFileName,
  setTextFileError: error,
  setTextFileIsLoading: setIsLoading,
  isTextFileLoading: isLoading,
  setCsvToJsonData,
  csvToJsonData,
  setManualInsetCount,
  manualInsetCount,
  setIsTextFileUpload: setIsFileUploaded,
  isFileUploaded,
}) => {
  const [editDetails, setEditDetails] = useState(editTextTemplateDetails)
  const [fileData, setFileData] = useState([])
  const [fileName, setLocalFileName] = useState(textFileName)
  const hiddenFileInput = useRef(null)
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const { showNotification } = useNotificationContext()
  const [removeDialogVisibleState, setRemoveDialogVisibleState] = useState(false)
  const scrollerContainer = useRef(null)
  const [maxShownCount, setMaxShownCount] = useState(50)

  const backdropStyle = useMemo(
    () => ({
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
    }),
    []
  )
  useEffect(() => {
    setEditDetails(editTextTemplateDetails)
    return () => {
      setIsLoading(false)
    }
  }, [editTextTemplateDetails])

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
  } = useForm({
    defaultValues: {
      test: editDetails && editDetails?.length > 0 ? editDetails : [{ text: '', hashtag: '', language: '' }],
    },
  })
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: 'test',
  })

  const textTemplatedValues = useWatch({
    name: 'test',
    control,
  })

  useEffect(() => {
    if (fileData && Object.values(textTemplatedValues[0]).every((item) => item === '')) {
      remove(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData])

  function createData(language, text, hashtag) {
    return { language, text, hashtag }
  }

  const onAddTextTemplateSubmit = async (data) => {
    if (isLoading) {
      return
    }
    await postAuditLogs('add_text_template')
    const rows = data?.test?.map((value, i) => {
      return createData(value.language, value.text, value.hashtag)
    })
    if (fileData?.data) {
      setText([...rows, ...fileData.data?.slice(maxShownCount, fileData.data.length - 1)])
      if (fileName) {
        setIsFileUploaded(true)
        setCsvToJsonData(fileData?.data)
        setFileName(fileData?.name)
      }
    } else {
      setText(rows)
    }
    handleCloseTextTemplateDialog()
    if (!fileName) {
      setFileName('')
      setCsvToJsonData([])
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('add_text_template_popup', sqId, data)
  }

  useEffect(() => {
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'text_template_pop_up',
    })
  }, [])

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'text_template_pop_up',
      message: message,
    })
  }

  const onFileUpload = async (event) => {
    setIsLoading(true)
    const file = event.target.files[0]
    if (!file?.name?.includes('.csv')) {
      setIsLoading(false)
      event.target.value = null
      return showNotification('Error', 'Only Csv file upload is allowed')
    }
    if (file?.size / 1000 + 1 > 102400) {
      setIsLoading(false)
      event.target.value = null
      return showNotification('Error', 'File size should be less than 100MB')
    }
    let fileData = []
    try {
      fileData = await CsvToJson(file)
      if (!fileData.length) {
        setIsLoading(false)
        event.target.value = null
        return showNotification('Error', 'Uploaded CSV file has no content')
      }
    } catch (error) {
      setIsLoading(false)
      event.target.value = null
      return showNotification('Error', error)
    }
    if (fileData?.length) {
      append(fileData.slice(0, maxShownCount), { shouldFocus: false })
    }

    setIsLoading(false)
    setFileData({ name: file.name, data: fileData })
    setLocalFileName(file.name)
    // added to handle same file selection
    event.target.value = null
  }

  const handleFileDeletion = async (value) => {
    if (value !== true) {
      setRemoveDialogVisibleState(false)
      return true
    }
    const totalTexts = (fileData?.data ? fileData.data?.length : csvToJsonData.length) + manualInsetCount - 1
    for (let i = totalTexts; i >= manualInsetCount; i--) {
      remove(i)
    }
    if (manualInsetCount === 0) {
      append({ text: '', hashtag: '', language: '' })
    }
    setLocalFileName('')
    setRemoveDialogVisibleState(false)
    setIsLoading(false)
  }

  const handleClick = async (event) => {
    if (fileName?.length !== 0) {
      setRemoveDialogVisibleState(true)
      return
    }
    hiddenFileInput.current?.click()
    event?.stopPropagation()
    await postAuditLogs('upload_csv')
  }

  const handleAddClick = async () => {
    await postAuditLogs('add_more')
    sendGALog('add_more')
    setManualInsetCount((prev) => parseInt(prev) + 1)
    insert(0, { text: '', hashtag: '', language: '' })
  }

  const handleFileState = (isDirty, state) => {
    handleCloseTextTemplateDialog(isDirty, state)
  }

  const fetchNext = (e) => {
    if (scrollerContainer) {
      const shouldGetNext =
        Math.abs(
          scrollerContainer.current.scrollHeight -
            scrollerContainer.current.scrollTop -
            scrollerContainer.current.clientHeight
        ) < 1
      if (shouldGetNext) {
        const totalData = fileData?.data
          ? fileData.data
          : csvToJsonData?.length
          ? csvToJsonData
          : editTextTemplateDetails
        const max = maxShownCount + 10
        append(totalData?.slice(maxShownCount, max), { shouldFocus: false })
        setMaxShownCount(max)
      }
    }
  }

  return (
    // handleCloseTextTemplateDialog(isDirty)}
    <Dialog onClose={() => handleFileState(isDirty)} open={openTextTemplateDialog} sx={mainDialogStyle}>
      <DialogTitle sx={textTemplateDialogTitle}>
        {editDetails?.length > 0 ? 'Update' : 'Add'} Text Templates
      </DialogTitle>
      <Avatar
        onClick={async () => {
          await postAuditLogs('close')
          sendGALog('close')
          handleCloseTextTemplateDialog(isDirty, true)
        }}
        alt="cross"
        src={CrossIcon}
        className="closeIcon"
        sx={{ height: '30px', width: '30px', position: 'absolute' }}
      />
      <Box className="templateDiv">
        <DialogContent className="templatedDialog">
          <Box>
            <form>
              {fields.map((item, index) => {
                return (
                  <Box className="box" key={`${item.id}_${index}`}>
                    <Box className="textcenterinbox">
                      <Box className="scrollerTopMargin">
                        <Typography component="b" className="titles">
                          Language
                        </Typography>

                        <FormControl size="small" fullWidth sx={mainFormDialogStyle}>
                          <TextField
                            name={`test[${index}].language`}
                            SelectProps={{ IconComponent: iconComponent }}
                            select
                            fullWidth
                            size="small"
                            autoComplete="off"
                            defaultValue={item?.language}
                            {...register(`test.${index}.language`, {
                              required: 'Please select language of the text',
                            })}
                            onChange={() => {
                              setError(`test.${index}.language`, null)
                            }}
                          >
                            {languages &&
                              languages.map((s) => {
                                return (
                                  <MenuItem
                                    // native
                                    key={s.id}
                                    sx={fullWidthStyle}
                                    value={s.language}
                                    size="small"
                                  >
                                    {s.language}
                                  </MenuItem>
                                )
                              })}
                          </TextField>
                          <Grid container>
                            <Grid item xs={8}>
                              <FormHelperText sx={errorStyles}>
                                {errors && errors?.test && errors?.test[index] && errors?.test[index].language?.message}
                              </FormHelperText>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Box>
                      <Box className="gapBetweenTitles">
                        <Typography component="b" className="titles">
                          Text
                        </Typography>
                        <FormControl sx={textFormDialog}>
                          <TextField
                            className="stepperFormInput"
                            // label="Text"
                            // name={"text" + i}
                            fullWidth
                            placeholder="Enter text"
                            size="small"
                            multiline
                            rows={70}
                            required
                            sx={textFieldStyle}
                            inputProps={textInputProps}
                            // value={x.text}
                            // onChange={e => handleInputChange(e, i)}

                            {...register(`test.${index}.text`, {
                              required: 'Please enter the text',
                              validate: (value) => validateNotEmpty(value, 'the Text'),
                            })}
                            autoComplete="off"
                            // error={errors?.text}
                            // helperText={`${errors}?.test.${index}.text.message`}
                          />
                          <Grid container>
                            <Grid item xs={8}>
                              <FormHelperText sx={errorStyles}>
                                {errors && errors?.test && errors?.test[index] && errors?.test[index].text?.message}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography variant="span" className="max-length-limit-size">
                                (Max length 280)
                              </Typography>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Box>
                      <Box>
                        <Typography component="b" className="titles">
                          Hashtag
                        </Typography>
                        <FormControl>
                          <TextField
                            className="stepperFormInput"
                            // label="Hashtag"
                            // name={"hashtag" + i}
                            fullWidth
                            // value={x.hashtag}
                            // onChange={e => handleInputChange(e, i)}
                            // placeholder="Enter text"
                            sx={hashTagTextFieldStyle}
                            onKeyDown={handleKeyDown}
                            inputProps={hashTagInputProps}
                            size="small"
                            required
                            {...register(`test.${index}.hashtag`, {
                              required: 'Please create a hashtag',
                              maxLength: {
                                value: 280,
                                message: 'Max length 280',
                              },
                              minLength: {
                                value: 5,
                                message: 'Min length 5',
                              },
                            })}
                            multiline
                            rows={70}
                            autoComplete="off"
                          />
                          <Grid container>
                            <Grid item xs={8}>
                              <FormHelperText sx={errorStyles}>
                                {errors && errors?.test && errors?.test[index] && errors?.test[index].hashtag?.message}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography variant="span" className="max-length-limit-size">
                                (Max length 280)
                              </Typography>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Box>
                      <Box>
                        {fields.length !== 1 && (
                          <img
                            src={imagedelete}
                            onClick={async () => {
                              await postAuditLogs('delete')
                              remove(index)
                              if (index < manualInsetCount) {
                                setManualInsetCount((prev) => parseInt(prev) - 1)
                              }
                              if (fields.length - 1 <= manualInsetCount) {
                                handleFileDeletion(true)
                              }
                            }}
                            className="deleteimgcss cursorshow"
                            alt="delete"
                          />
                        )}

                        {/* Remove display:none to show button */}
                      </Box>
                    </Box>
                    <Box className="d-none">
                      <Grid item xs={3}>
                        {fields.length !== index && index !== 0 && (
                          <Button
                            variant="outlined"
                            sx={{ borderRadius: 4 }}
                            className="button-primary-alt-contained"
                            // startIcon={<DeleteOutlinedIcon  />}
                            onClick={() => {
                              remove(index)
                            }}
                          >
                            Remove
                          </Button>
                        )}
                      </Grid>
                    </Box>
                  </Box>
                )
              })}
            </form>
          </Box>
        </DialogContent>
        <Box className="btn-box">
          <React.Fragment>
            <Box sx={buttonBoxContainer}>
              <Button
                variant="outlined"
                sx={{ boxShadow: 'none' }}
                className="button-tr-citizen-admin mglefto addMore"
                startIcon={<AddIcon sx={{ mt: '5px' }} />}
                onClick={handleAddClick}
              >
                Add More
              </Button>
              <input
                type="file"
                accept=".csv"
                className="d-none"
                ref={hiddenFileInput}
                onChange={onFileUpload}
                required
              />
              <Box className="upload-button-style">
                <Button onClick={handleClick} className="uploadCsvButton" disabled={isLoading}>
                  <UploadFile sx={{ mr: '5px' }} />
                  {isLoading && <CircularProgress size={30} />}
                  {!isLoading && fileName ? 'Remove uploaded CSV' : 'Upload a .CSV file'}
                </Button>
              </Box>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </React.Fragment>
        </Box>
      </Box>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <React.Fragment>
          <Box sx={submitButtonBoxStyle}>
            <Button
              variant="contained"
              sx={submitButtonStyle}
              className="button-tr-2 mgtopo"
              disabled={isLoading}
              onClick={handleSubmit(onAddTextTemplateSubmit)}
              onFocus={() => sendGALog(editDetails?.length > 0 ? 'update' : 'add_text_template')}
            >
              {isLoading && <CircularProgress size={30} />}
              {editDetails?.length > 0 ? 'Update Text Template' : 'Add Text Template'}
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
          </Box>
        </React.Fragment>
      </DialogActions>
      {removeDialogVisibleState && (
        <RemoveFileDialog callback={handleFileDeletion} openRemoveDialog={removeDialogVisibleState} />
      )}
      {isLoading && (
        <Backdrop sx={backdropStyle} open={isLoading} className="loadingText">
          <img alt="Search" width={40} height={40} src={CircularDotLoader} />
          <Box m={3} />
          <Typography component="span" variant="contained">
            Your file is being processed,
            <Box />
          </Typography>
          <Typography variant="span"> Please wait...</Typography>
        </Backdrop>
      )}
    </Dialog>
  )
}

export default AddTextTemplates
