import { Dialog, DialogActions, DialogContent, Button } from '@mui/material'
import TemplateDialogCloseButton from 'Components/CreateInitiativesRefactored/TemplateDialogCloseButton/TemplateDialogCloseButton'

const ConfirmationDialog = ({
  isConfirmationDialogOpen,
  message,
  confirmBtnContent,
  rejectBtnContent,
  onConfirm,
  onReject,
}) => {
  return (
    <Dialog
      open={isConfirmationDialogOpen}
      PaperProps={{
        sx: {
          borderRadius: '20px',
          position: 'fixed',
          minHeight: '250px',
          width: '450px',
        },
      }}
      onClose={onReject}
    >
      <TemplateDialogCloseButton onClick={onReject} />
      <DialogContent
        sx={{
          padding: '55px 55px 0 55px',
          fontSize: '1.6rem',
          textAlign: 'center',
          color: '#6C6C6C',
        }}
      >
        {message}
      </DialogContent>
      <DialogActions className="d-flex justify-content-center pb-4">
        <Button
          variant="contained"
          sx={{ p: 1, mr: 1, backgroundColor: '#ef7335' }}
          className="button-tr-2-13-disa"
          onClick={onConfirm}
        >
          {confirmBtnContent}
        </Button>
        <Button variant="outlined" sx={{ p: 1 }} className="button-tr-2-1" onClick={onReject}>
          {rejectBtnContent}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmationDialog
