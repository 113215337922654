import { POST_CREATE_OPEDS_FAILURE, POST_CREATE_OPEDS_REQUEST, POST_CREATE_OPEDS_SUCCESS } from '../action/types'

const initialState = {
  data: [],
  error: '',
  loading: false,
}

export const createOpedsReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_CREATE_OPEDS_REQUEST:
      return {
        ...state,
        loading: true,
      }
    case POST_CREATE_OPEDS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      }
    case POST_CREATE_OPEDS_FAILURE:
      return {
        data: [],
        error: action.payload,
        loading: false,
      }
    default:
      return state
  }
}
