import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import CrossIcon from '../../../asserts/images/Close.svg'

export default function UpdateDialog(props) {
  const { openDialog, handleCloseDialog, handleUpdate } = props

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ maxWidth: '550px', margin: '0 auto' }}
      >
        <img onClick={handleCloseDialog} src={CrossIcon} className="closeIcon" />
        <DialogContent>
          <DialogContentText
            className="pre-line"
            sx={{
              minHeight: '50px',
              display: 'flex',
              alignItems: 'center',
              color: '#6C6C6C',
              fontFamily: 'HK Grotesk',
              fontSize: '22px',
              textAlign: 'center',
              fontWeight: 'Bold',
              mt: 2,
              justifyContent: 'center',
            }}
          >
            Are you sure you want to update the master list?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            className="button-tr-2"
            sx={{ fontFamily: 'HK Grotesk', ml: 2, width: '180px', mb: 3, height: '44px' }}
            onClick={handleUpdate}
          >
            Update
          </Button>

          <Button
            variant="contained"
            className="button-tr-citizen-cancel ml-3"
            sx={{ fontFamily: 'HK Grotesk', ml: 2, width: '180px', mb: 3, height: '44px' }}
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
