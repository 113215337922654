import ProfilePictureComponent from '../../ReusableComponents.js/ProfilePictureComponent'
import { MPsevaProfile } from '../../ReusableComponents.js/ProfilePictureStyles'
import { Box } from '@mui/material'
import ToolTipComponent from '../../ReusableComponents.js/ToolTip/ToolTipComponent'
import infoic from '../../../asserts/images/Info.svg'
import MpDetails from '../MpDetails/MpDetails'
import { useLocation } from 'react-router-dom'
import './MpSevaProfileDetails.css'
const MpSevaProfileDetails = () => {
  const location = useLocation()
  let mpData = location?.state?.mpData
  if (!mpData) {
    return
  }
  return (
    <Box className="carousel-format classnamrleadere">
      <ProfilePictureComponent ProfilPic={mpData?.user_avatar} sxProfile={MPsevaProfile} alt="" />
      <MpDetails mpData={mpData} />
      <Box className="info-icon ml-0 mr-n3 pt-0 align-self-start mt-n3 infoic">
        <ToolTipComponent dataFilter={'15days'} icon={infoic} />
      </Box>
    </Box>
  )
}

export default MpSevaProfileDetails
