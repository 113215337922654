import React from 'react'
import { useSelector } from 'react-redux'
import { Typography, Box, Menu, CardMedia, Avatar, Divider } from '@mui/material'

import { MostFollowersProfile } from '../../ReusableComponents.js/ProfilePictureStyles.js'
import ProfilePictureComponent from '../../ReusableComponents.js/ProfilePictureComponent.js'

export default function MostNewFollowers() {
  const { topMostFollowers } = useSelector((state) => state?.mostTwiiterNewFollowers?.data)
  return (
    <>
      <Box className="twitterTopLeaders">
        <Box className="tweetSectionHeader">
          <Typography variant="h3" className="header-twt">
            Most New Followers Added
          </Typography>
        </Box>
        <Box className="card-format">
          {topMostFollowers?.map((mp) => (
            <Box>
              <Box className="card-size card-width">
                <Box className="card card-corner">
                  <Box className="card-body position-relative twitterboxleader">
                    <Box className="d-flex justify-content-center text-center pt-2">
                      <ProfilePictureComponent ProfilPic={mp?.user_avatar} sxProfile={MostFollowersProfile} />
                    </Box>
                    <Box className="d-flex flex-column leader-content-tw ellipsewe-name detail-box">
                      <Typography component="h2" className="ellipsewe-pcard profile-title">
                        {mp?.mpName}
                      </Typography>
                      <Typography variant="span" className="mb-3 initial ellipsewe-pcard profile-details">
                        {mp?.designation ? mp?.designation : 'Lok Sabha MP'}{' '}
                      </Typography>
                      {/* <Box m={3} /> */}
                      <Typography component="span" className="constituency ellipsewe-pcard-1">
                        Constituency :{mp?.constituency}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <hr />
    </>
  )
}
