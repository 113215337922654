import {
  Dialog,
  DialogTitle,
  IconButton,
  Grid,
  Box,
  Button,
  DialogContent,
  DialogActions,
  Input,
  Avatar,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, { useRef, useEffect } from 'react'
import { useNotificationContext } from '../../../utils/NotificationContext'
import CrossIcon from '../../../asserts/images/Close.svg'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { useSelector } from 'react-redux'
import { allowedImageFormat, acceptedImageFormat } from '../../../utils/constant'
import { pushTag } from '../../../utils/gtmservice'
import './UploadCoverImage.css'

const UploadCoverImage = ({
  openUploadImageDialog,
  handleCloseUploadImageDialog,
  setImageName,
  setImage,
  preview,
  setPreview,
  handleClick,
  createNewClick,
  onEditBrowse,
}) => {
  const hiddenFileInput = useRef(null)
  const { showNotification } = useNotificationContext()
  const fileFormats = ['image/png', 'image/jpg']
  const userProfile = useSelector((state) => state?.userProfile?.data)

  // const handleClick = event => {
  //     hiddenFileInput.current.click();
  // };
  const onFileUpload = (event) => {
    const type = event.target.files[0].type
    const isRightFormat = allowedImageFormat.includes(type)
    if (!isRightFormat) {
      showNotification('Error', 'Please upload image in JPG or PNG format only', 'error')
      return
    }
    setImage(event.target.files[0])
    setPreview(URL.createObjectURL(event.target.files[0]))
    setImageName(event.target.files[0].name)
    // handleCloseUploadImageDialog();
  }
  const onDelete = () => {
    setImage(null)
    setImageName(null)
    setPreview(null)
  }

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('upload_cover_popup', sqId, data)
  }

  useEffect(() => {
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'image_preview_pop_up_seva_initiative_page',
    })
  }, [])

  return (
    <Dialog onClose={handleCloseUploadImageDialog} open={openUploadImageDialog}>
      <DialogTitle
        sx={{
          fontFamily: 'HK Grotesk',
          color: '#2e739c',
          fontWeight: '700',
          textAlign: 'center',
          fontSize: '26px',
        }}
      >
        Image Preview
      </DialogTitle>
      <Avatar
        onClick={async () => {
          await postAuditLogs('close')
          handleCloseUploadImageDialog()
        }}
        src={CrossIcon}
        className="closeIcon"
        sx={{
          height: '30px',
          width: '30px',
          position: 'absolute',
        }}
      />
      <DialogContent>
        <Box>
          <Grid container sx={{ justifyContent: 'center' }}>
            {preview && (
              <Box sx={{ position: 'relative' }}>
                {createNewClick ? (
                  <Avatar variant="square" src={preview} alt="Preview" className="preview-image" />
                ) : (
                  <Avatar
                    variant="square"
                    src={preview?.toString().startsWith('blob') ? preview : JSON.parse(preview)[0]}
                    alt="Preview"
                    className="preview-image"
                  />
                )}
                <IconButton
                  aria-label="close"
                  onClick={onDelete}
                  sx={{
                    width: '12px',
                    height: '12px',
                    right: '5px',
                    top: '5px',
                    position: 'absolute',
                    color: (theme) => theme.palette.grey[500],
                    border: '1px solid #2C2C2C',
                    borderRadius: '50%',
                    color: '#fff',
                    backgroundColor: '#2C2C2C',
                    padding: '12px',
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            )}
            {/* {fileName ? fileName : null} */}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <React.Fragment>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              mb: 2,
              mt: 2,
              justifyContent: 'center',
            }}
          >
            <input
              type="file"
              ref={hiddenFileInput}
              className="d-none"
              onChange={onFileUpload}
              accept={acceptedImageFormat}
            />
            <Button
              variant="contained"
              sx={{ p: 1, mr: 1, backgroundColor: '#ef7335', borderRadius: 4 }}
              className="button-tr-2"
              onClick={async (e) => {
                await postAuditLogs('replace')
                pushTag({
                  event: 'select_option',
                  page_title: 'seva_initiative_page',
                  message: 'image_preview_replace',
                })
                handleClick(e, false)
              }}
            >
              Replace
            </Button>
            {/* <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={onFileUpload} accept="image/*" />
                    <Button onClick={handleClick}>Add Cover Image</Button>
                    {fileName ? fileName : null} */}
            <Box sx={{ flex: '1 1 auto' }} />
          </Box>
        </React.Fragment>
      </DialogActions>
    </Dialog>
  )
}

export default UploadCoverImage
