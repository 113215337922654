import { Select, MenuItem, Typography, Box, Button } from '@mui/material'
import { useEffect, useState } from 'react'
import './FilterSortComponent.css'
import Sort from './SortComponent/Sort'
import Filter from './FilterComponent/Filter'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useDispatch, useSelector } from 'react-redux'
import { SevenK } from '@mui/icons-material'

const FilterSortComponent = ({ Title, setMpIdLists, mpIdLists, sortBy, setSortBy }) => {
  const [selectedNames, setSelectedNames] = useState([])
  const [stateName, setStateName] = useState('Select Filter')
  const [filterOpen, setFilterOpen] = useState(false)
  const [stateSearchState, setStateSearchState] = useState('')
  const [selectedMps, setSelectedMps] = useState([])
  const [stateSearchClearIcon, setStateSearchClearIcon] = useState(false)
  const [selectedState, setSelectedState] = useState([])
  const [stateApiResult, setStateApiResult] = useState([])
  const [mpClearIcon, setMPSearchClearIcon] = useState(false)
  const { assignMpList } = useSelector((state) => state?.assignMpList)
  const { statedata: stateData } = useSelector((state) => state?.stateList?.data)

  const [mpApiResult, setMpApiResult] = useState([])
  const [mpSearchState, setMPSearchState] = useState('')
  const handleChange = (event) => {
    setSelectedNames(event.target.value)
  }

  const handleClick = () => {
    setFilterOpen(true)
  }

  return (
    <>
      <Box className="filtersortselect">
        <Typography className="Filtersort-title">{Title}</Typography>
        <Button
          aria-controls="select-menu"
          className="Select-component-sortFilter arrow"
          aria-haspopup="true"
          onClick={handleClick}
          variant="outlined"
        >
          <Typography className="stateName">
            {selectedMps.length === 0
              ? stateName
              : selectedState?.length === 1
              ? selectedState[0]?.state_name
              : selectedState[0]?.state_name + ', ..'}
          </Typography>

          <ArrowDropDownIcon />
        </Button>
      </Box>
      {filterOpen && (
        <Filter
          open={filterOpen}
          setFilterOpen={setFilterOpen}
          setStateName={setStateName}
          mpIdLists={mpIdLists}
          setMpIdLists={setMpIdLists}
          selectedState={selectedState}
          setStateApiResult={setStateApiResult}
          assignMpList={assignMpList}
          setMpApiResult={setMpApiResult}
          setStateSearchState={setStateSearchState}
          setStateSearchClearIcon={setStateSearchClearIcon}
          setSelectedState={setSelectedState}
          setMPSearchState={setMPSearchState}
          setMPSearchClearIcon={setMPSearchClearIcon}
          selectedMps={selectedMps}
          stateSearchClearIcon={stateSearchClearIcon}
          setSelectedMps={setSelectedMps}
          stateSearchState={stateSearchState}
          stateApiResult={stateApiResult}
          mpSearchState={mpSearchState}
          mpApiResult={mpApiResult}
          stateData={stateData}
          sortBy={sortBy}
        />
      )}
    </>
  )
}
export default FilterSortComponent
