import { Card, CardContent, CardMedia, IconButton, Typography, Box, Dialog, DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './RowImage.css'
import CancelSharpIcon from '@mui/icons-material/CancelSharp'
import CrossIcon from '../../../asserts/images/Close.svg'
import ShareIcon from '@mui/icons-material/Share'
import { useNavigate } from 'react-router-dom'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import NoImageFound from '../../../asserts/images/noImageFound.jpg'
import Share from '../../ReusableComponents.js/Share'
import { fontFamily } from '@mui/system'
import UpdateInitiativeReportDetails from '../AllInitiativeReports/UpdateInitiativeReportDialog'
import { useDispatch, useSelector } from 'react-redux'
import { useNotificationContext } from '../../../utils/NotificationContext'
import { getIntiativesReportByInitiativeIdAndMpId } from '../../../store/action/ongoingSevaInitiativesList'
import zIndex from '@mui/material/styles/zIndex'
import EmptyDataCard from '../../ReusableComponents.js/EmptyStateCard'
import { ImageLoader } from '../../ReusableComponents.js/ImageLoader'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { getIds } from '../../ReusableComponents.js/getIds'
import { pushTag } from '../../../utils/gtmservice'

function RowImage({
  title,
  data,
  user,
  isOngoingSevaClick,
  handleOpenInitiativeDetails,
  mpName,
  onSevaEvent,
  onMediaCoverage,
  readonly,
  eventByMpId,
  mySeva,
  mpId,
  page_title,
  category,
}) {
  const [personalDetails, setPersonalDetails] = useState()
  const { showNotification } = useNotificationContext()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [mediaShare, setMediaShare] = useState(false)
  const [shareData, setShareData] = useState({})
  const [initiativeName, setInitiativeName] = useState()
  const [ongoingDetails, setOngoingDetails] = useState()
  const [openUpdateIntiativeDialog, setOpenUpdateInitiativeDialog] = useState(false)
  const initiativeReportDetailsByMPIdAndIntiativeId = useSelector(
    (state) => state?.initiativeReportDetailsByMPIdAndIntiativeId?.data
  )

  const postAuditLogs = async (sqId, id) => {
    let requestData = {
      useridtype: getIds(),
    }
    if (title && title === 'Ongoing Seva Initiatives') {
      requestData['i_id'] = id && id
    } else if (title && title === 'Seva aur Samarpan Campaign') {
      requestData['e_id'] = id && id
    } else if (title && title === 'Media Coverage of') {
      requestData['bm_id'] = id && id
    } else if (title && title === 'Development Projects') {
      requestData['dp_id'] = id && id
    }

    await makeLogRequest(
      !mpName ? 'nationwide_seva_updates' : mySeva ? 'my_seva_updates' : 'mp_seva_updates',
      sqId,
      requestData
    )
  }

  // const handleClear=(e)=>{
  //     updateData(e)
  //   }
  const details = !mySeva && !mpId ? data?.length > 0 && data?.slice(0, 10) : data

  const handleShare = (e, data) => {
    e.stopPropagation()
    e.preventDefault()
    setShareData(data)
    setMediaShare(true)
    let gaTagTitle
    switch (category) {
      case 'seva_events':
        gaTagTitle = data.eventTitle
        break
      case 'dev_projects':
        gaTagTitle = data.projecttitle
        break
      default:
        gaTagTitle = data.title
        break
    }
    pushTag({
      event: 'share',
      page_title,
      category,
      match: gaTagTitle,
    })
  }

  const handleCardClick = async (data) => {
    await postAuditLogs('ongoing_seva_initiatives', data.id)
    setPersonalDetails(data)
    // !user ?
    // navigate("/SevaUpdates/allInitiativeReports") :
    navigate('/SevaUpdates/allInitiativeReports', {
      state: {
        readonly: readonly,
        user: user,
        // initiativeDetails: initiativeDetails,
        initiativeId: data?.id,
        initiativeName: data?.initiativeName,
        eventByMpId: eventByMpId,
        mpName: mpName,
        mpId: mpId,
      },
    })
  }

  const handleOpenInitiativeReport = async (dataVal) => {
    await postAuditLogs(
      title === 'Ongoing Seva Initiatives'
        ? 'ongoing_seva_initiatives'
        : title === 'Seva aur Samarpan Campaign'
        ? 'seva_events'
        : title === 'Media Coverage of'
        ? 'books_and_media_coverage'
        : 'development_projects',
      dataVal.id
    )
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(dataVal?.id, mpId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          showNotification('Error', response.data?.message, 'error')
        } else if (response?.data?.reportdata.length === 0) {
          handleCardClick(dataVal)
        } else {
          setInitiativeName(dataVal?.initiativeName)
          setOpenUpdateInitiativeDialog(true)
          setOngoingDetails(dataVal)
        }
      }
    } catch (error) {
      showNotification('Error', error, 'error')
    }
  }

  const handleCloseUpdateInitiativeDetails = (reset, reportFiles, setUploadFiles) => {
    // setShowUpdate(false);
    reset()
    reportFiles([])
    setUploadFiles([])
    setOpenUpdateInitiativeDialog(false)
  }

  return (
    <Box>
      {/* <h2 className='row__title'>{title}</h2> */}
      {details == null || !details || details.length < 1 ? (
        <EmptyDataCard cardFor={title} />
      ) : (
        <Box className="itemfixed3">
          <Box>
            <Tabs
              // value={value}
              // onChange={handleChange}
              variant="scrollable"
              scrollButtons
              aria-label="visible arrows tabs example"
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              {details &&
                details?.map((dataVal, index) => (
                  <Card
                    className="clickable cursorshow"
                    sx={{ minWidth: 235, mr: 3, mb: 3, borderRadius: '15px' }}
                    onClick={() => {
                      isOngoingSevaClick
                        ? mpName
                          ? mySeva
                            ? handleOpenInitiativeReport(dataVal)
                            : handleOpenInitiativeDetails(dataVal, title, isOngoingSevaClick)
                          : handleCardClick(dataVal)
                        : handleOpenInitiativeDetails(dataVal, title)
                    }}
                  >
                    <Box className="cardWrapper">
                      <ImageLoader
                        src={
                          isOngoingSevaClick || onSevaEvent
                            ? dataVal?.coverimage && JSON.parse(dataVal?.coverimage)[0]
                            : dataVal?.media && JSON.parse(dataVal?.media)[0]
                        }
                        sx={{
                          borderRadius: '15px',
                          background:
                            'transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%) 0% 0% no-repeat padding-box',
                        }}
                        loading="lazy"
                        height="150"
                        width="240"
                        id={index}
                        onError={(e) => (e.target.src = NoImageFound)}
                        className="blackoverlay sava-update--card-img__wrapper"
                      />
                      {/* <CardMedia
                      sx={{
                        // filter: "brightness(50%)",
                        // // backgroundColor: "rgb(0 0 0 / 30%)",
                        borderRadius: "15px",
                        background: "transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%) 0% 0% no-repeat padding-box",


                      }}
                      id={index}
                      component="img"
                      loading="lazy"
                      height="150"
                      width="190"
                      src={
                        (isOngoingSevaClick || onSevaEvent)
                          ? dataVal?.coverimage && JSON.parse(dataVal?.coverimage)[0]
                          : dataVal?.media && JSON.parse(dataVal?.media)[0]
                      }

                      // image={data.image}
                      onError={(e) => (e.target.src = NoImageFound)}
                      alt="new Image"
                      className="blackoverlay"
                    /> */}
                      {title !== 'Ongoing Seva Initiatives' && (
                        <ShareIcon
                          className="shareicone customshare"
                          key={index}
                          onClick={(e) => {
                            postAuditLogs(
                              title === 'Ongoing Seva Initiatives'
                                ? 'share_initiative'
                                : title === 'Seva aur Samarpan Campaign'
                                ? 'share_event'
                                : title === 'Media Coverage of'
                                ? 'share_books_and_media'
                                : 'share_development_project',
                              dataVal?.id
                            )
                            handleShare(e, dataVal)
                          }}
                        />
                      )}
                      <Box className="dibgover mgdiv"></Box>
                      <Box className="ecclipseonwidthnewrow1 title-text-st">
                        <Box className="para-wrapper">
                          <Typography component="body1" sx={{ lineHeight: '1.5' }} className={`ecclipseonwidthnewrow`}>
                            {' '}
                            {isOngoingSevaClick
                              ? dataVal?.initiativeName
                              : onSevaEvent
                              ? dataVal?.eventTitle
                              : onMediaCoverage
                              ? dataVal?.title
                              : dataVal?.projecttitle}
                          </Typography>
                        </Box>
                        <Typography component="span" className="sp-st" sx={{ lineHeight: '1.5' }}>
                          {isOngoingSevaClick
                            ? null
                            : onSevaEvent
                            ? dataVal?.location.length > 25
                              ? dataVal?.location.substring(0, 25) + '...'
                              : dataVal?.location
                            : onMediaCoverage
                            ? dataVal?.type
                            : dataVal?.status}
                        </Typography>
                      </Box>
                      <Box></Box>
                    </Box>
                  </Card>
                ))}
            </Tabs>
            <Dialog open={mediaShare} onClose={() => setMediaShare(false)}>
              <DialogTitle>
                <img onClick={() => setMediaShare(false)} src={CrossIcon} className="closeIcon" />
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    color: '#357092',
                    fontFamily: 'HK Grotesk',
                    fontSize: '26px',
                    fontWeight: 'bold',
                  }}
                >
                  Share to Social Media
                </Typography>

                <Box className="share-st">
                  <Share data={shareData} title={title} />
                </Box>
                {/* <CloseIcon onClick={() => setAddMembers(false)} /> */}
              </DialogTitle>
            </Dialog>
            {openUpdateIntiativeDialog && (
              <UpdateInitiativeReportDetails
                openUpdateIntiativeDialog={openUpdateIntiativeDialog}
                details={ongoingDetails}
                initiativeName={initiativeName}
                handleCloseUpdateInitiativeDetails={handleCloseUpdateInitiativeDetails}
                initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default RowImage
