import { DialogTitle } from '@mui/material'

const TemplateDialogTitle = ({ title, type }) => {
  return (
    <DialogTitle
      sx={{
        color: '#2e739c',
        fontWeight: '700',
        textAlign: 'center',
        fontSize: '26px',
        ...(type === 'preview' ? { fontFamily: 'HK Grotesk ExtraBold' } : { fontFamily: 'HK Grotesk' }),
      }}
    >
      {title}
    </DialogTitle>
  )
}

export default TemplateDialogTitle
