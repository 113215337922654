import { Box, Typography } from '@mui/material'
import './CardScoreComponent.css'

const CardScoreComponent = ({ CardScoreComponentTitle, CardScoreComponentValue, selectedCard }) => {
  return (
    <Box className="score-container">
      <Typography variant="h3" className="card-content">
        {CardScoreComponentTitle}
      </Typography>
      <Typography variant="span">
        {selectedCard === 'donation' && '₹'} {CardScoreComponentValue}
      </Typography>
    </Box>
  )
}

export default CardScoreComponent
