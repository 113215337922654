import { Dialog, DialogContent } from '@mui/material'
import TemplateDialogCloseButton from '../TemplateDialogCloseButton/TemplateDialogCloseButton'
import { pushTag } from '../../../utils/gtmservice'
import TemplateDialogSubmitButton from '../TemplateDialogSubmitButton/TemplateDialogSubmitButton'

const AssignMpConfirmDialog = ({ confirm, setConfirm, handlePost }) => {
  return (
    <Dialog
      open={confirm}
      PaperProps={{
        sx: {
          borderRadius: '15px',
          position: 'fixed',
          minHeight: '250px',
          width: '450px',
        },
      }}
      onClose={() => setConfirm(false)}
    >
      <TemplateDialogCloseButton onClick={() => setConfirm(false)} />
      <DialogContent
        sx={{
          padding: '55px 55px 0 55px',
          fontSize: '20px',
          textAlign: 'center',
        }}
      >
        Multiple MPs have created events for this initiative. Are you sure you want to update this initiative?
      </DialogContent>
      <TemplateDialogSubmitButton
        title={'Confirm'}
        variant={'contained'}
        onClick={() => {
          pushTag({
            event: 'select_option',
            page_title: 'update_initiative_pop_up',
            message: 'confirm',
          })
          handlePost()
        }}
        buttonClassName={'button-primary-alt-contained'}
        buttonSx={{
          p: 1,
          mr: 1,
          width: '300px',
          alignSelf: 'center',
          backgroundColor: '#ef7335',
          borderRadius: 4,
          position: 'relative',
          margin: '15px 15px 35px 15px',
        }}
        dialogActionSx={{
          mt: 1,
          justifyContent: 'center',
        }}
      />
    </Dialog>
  )
}

export default AssignMpConfirmDialog
