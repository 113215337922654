import { INITIATIVES_OVERVIEW_FAILURE, INITIATIVES_OVERVIEW_REQUEST, INITIATIVES_OVERVIEW_SUCCESS } from './types';
import axiosInstance from '../../utils/api';

export const initiativeOverviewRequest = () => {
  return {
    type: INITIATIVES_OVERVIEW_REQUEST,
    payload: {
      requested: 0,
      ongoing: 0,
      events: 0,
    },
  };
};
export const initiativeOverviewSuccess = (value) => {
  return {
    type: INITIATIVES_OVERVIEW_SUCCESS,
    payload: value,
  };
};
export const initiativeOverviewFailure = (error) => {
  return {
    type: INITIATIVES_OVERVIEW_FAILURE,
    payload: error,
  };
};

export const overViewInitiatives = (query) => async (dispatch) => {
  dispatch(initiativeOverviewRequest);
  await axiosInstance
    .get(`/api/initiative/statusbymp/${query}`)
    .then((response) => {
      const result = response.data;
      dispatch(initiativeOverviewSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(initiativeOverviewFailure(errorMsg));
    });
};
