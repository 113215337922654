import React from 'react'
import AddIcon from '../../asserts/images/AddIcon.svg'
import { Button, Typography, Box, Avatar } from '@mui/material'
import './AddPdfComponent.css'

const AddPdfComponent = ({ onClick, text, left }) => {
  return (
    <Box className="addIconPosDiv" onClick={(e) => onClick(e)}>
      <Button fullWidth className="addpdf-section">
        <Avatar src={AddIcon} alt="addPdf" className="addIconClass" />
        <>
          <Typography className="addPdfTitle">{text}</Typography>
          <Box className="addPdfFooter">Optimal Upload size: 50MB</Box>
        </>
      </Button>
    </Box>
  )
}

export default AddPdfComponent
