import React, { useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Paper,
  TableContainer,
  TableBody,
  TableRow,
  Table,
  TableHead,
  TextField,
  Box,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import AccordionAddMore from '../AccordionAddMore'
import './MasterListAccordion.css'
import DeleteAlert from 'asserts/images/DeleteAlert.svg'

const MasterListAccordion = ({ title, expanded, expandAccordion, addMoreText, users, setData }) => {
  var inputValue = ''

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F5F6FA',
      color: '#2e739c',
      fontWeight: 'bold',
      fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      fontWeight: 'bold',
      backgroundColor: '#fff',
      color: '#505050',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const handleAddMoreClick = () => {
    if (inputValue !== '') {
      setData((prevItems) => [...prevItems, inputValue])
      inputValue = ''
    }
  }

  const handleInputChange = (e) => {
    inputValue = e.target?.value?.trim()
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && inputValue !== '') {
      setData((prevItems) => [...prevItems, inputValue])
      inputValue = ''
    }
  }

  const handleDelete = (userToDelete) => {
    setData((prevUsers) => prevUsers.filter((user) => user !== userToDelete))
  }

  return (
    <Box className="ml-3 mr-3">
      <Accordion
        sx={{
          backgroundColor: '#E3F5FF',
          boxShadow: 'none',
          fontFamily: 'HK Grotesk',
          mt: 2,
          mb: 2,
          border: '0',
          color: '#505050',
          '&:before': {
            display: 'none',
          },
          '&:first-of-type': {
            borderTopLeftRadius: '18px',
            borderTopRightRadius: '18px',
          },
          '&:last-of-type': {
            borderBottomLeftRadius: '18px',
            borderBottomRightRadius: '18px',
          },
        }}
        expanded={expanded}
      >
        <AccordionSummary
          expandIcon={expanded ? <RemoveIcon color="primary" /> : <AddIcon color="primary" />}
          onClick={expandAccordion}
          sx={{ marginTop: 2 }}
        >
          <Typography className="accordion-title">
            {title} {expanded}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="acc-st">
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <StyledTableCell className="table-header">UUID</StyledTableCell>
              </TableHead>
              <TableBody>
                {users?.map((user, index) => (
                  <StyledTableRow>
                    <StyledTableCell component="th" scope="row" className="table-row table-row-content">
                      <Typography className="delete-icon">{user}</Typography>
                      <img
                        src={DeleteAlert}
                        alt="Delete"
                        onClick={() => handleDelete(user)}
                        style={{ cursor: 'pointer' }}
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
                <StyledTableRow>
                  <StyledTableCell>
                    <TextField
                      size="small"
                      type="text"
                      required
                      className="input-textField"
                      onChange={(e) => handleInputChange(e)}
                      onKeyDown={(e) => handleKeyDown(e)}
                      placeholder="Enter UUID"
                      inputProps={{ maxLength: 30 }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
                <AccordionAddMore onClick={handleAddMoreClick} text={addMoreText} />
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default MasterListAccordion
