import { useRef, useState, useEffect } from 'react'
import play from '../../asserts/images/Play.svg'
import pause from '../../asserts/images/Pause.png'
import { Avatar, Box, Button } from '@mui/material'
function VideoPlayer({
  key,
  src,
  className,
  width,
  height,
  position,
  top,
  left,
  transform,
  background,
  onClick,
  playButtonWidth,
  isSmallSection = false,
  addVideoExpandState,
}) {
  const videoRef = useRef(null)
  const [playbtn, setPlay] = useState(true)
  const [loading, setLoading] = useState(true)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const video = videoRef.current
    video.pause()
    setPlay(true)
  }, [addVideoExpandState])

  useEffect(() => {
    const video = videoRef.current

    const handleLoadedData = () => {
      setLoading(false)
    }

    const handleProgress = () => {
      if (video.duration && video.buffered.length > 0) {
        const bufferedTime = video.buffered.length > 0 ? video.buffered.end(video.buffered.length - 1) : 0
        const percentage = video.duration ? (bufferedTime / video.duration) * 100 : 0
        setProgress(percentage)
      }
    }

    const handleEnded = () => {
      setPlay(true)
    }

    video.addEventListener('loadeddata', handleLoadedData)
    video.addEventListener('progress', handleProgress)
    video.addEventListener('ended', handleEnded)

    return () => {
      video.removeEventListener('loadeddata', handleLoadedData)
      video.removeEventListener('progress', handleProgress)
      video.removeEventListener('ended', handleEnded)
    }
  }, [])

  const playWidth = `${width / 5}px`
  const playBttnWidth = playButtonWidth ? `${playButtonWidth}px` : width ? playWidth : '50px'
  const leftValue = left ? `${left}px` : width ? `${width / 2.5}px` : '20%'

  const togglePlay = () => {
    const video = videoRef.current

    if (video.paused) {
      video.play()
      setPlay(false)
    } else {
      video.pause()
      setPlay(true)
    }
  }

  return (
    <Box className="position-relative">
      <video
        ref={videoRef}
        key={key}
        className={className && className}
        width={width && width}
        height={height && height}
        onClick={onClick && onClick}
        style={{ position, top, left, transform, background }}
      >
        <source src={src} type="video/mp4" />
      </video>

      {!isSmallSection && (
        <Button onClick={togglePlay} className="player-btn" sx={{ position: 'absolute' }}>
          <Avatar variant="circular" alt="play" className="player-img" src={playbtn ? play : pause} />
        </Button>
      )}

      {loading && (
        <Box className="loading-div">
          <Box className="loading-div2">
            <Box className="loading-div3">{progress.toFixed()}%</Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default VideoPlayer
