import { useCallback, useMemo } from 'react'
import { Box, TextField, InputAdornment } from '@mui/material'
import CrossIcon from '../../../asserts/images/Close.svg'
import searchIcon from '../../../asserts/images/Search.svg'
import { useLocation } from 'react-router-dom'
import { debounce } from 'lodash'

const ViewAllScreenHeaderSearchBar = ({
  searchText,
  viewAllTitle,
  setCardsData,
  setTotalCardLength,
  containerRef,
  setSearchText,
  setIsItemUpdated,
  getSevaUpdateResults,
  setSearchOffset,
  callSearchAPI,
  setLastPaginatedResult,
}) => {
  const location = useLocation()
  const eventsForInitiative = location?.state?.eventsForInitiative
  const InitiativeReports = location?.state?.InitiativeReports
  const title = location?.state?.title

  const handleSearch = (e) => {
    const searchText = e.target.value
    if (searchText === '') {
      setLastPaginatedResult([])
    }
    setSearchText(searchText)
    debouncedSendRequest(searchText)
  }

  const sendRequest = useCallback((value) => {
    containerRef.current.scrollTop = 0
    setSearchOffset(0)
    if (value?.length === 0) {
      return setDefaultCardData()
    }
    callSearchAPI(value, 0)
  }, [])

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendRequest, 500)
  }, [sendRequest])

  const setDefaultCardData = () => {
    const payload = {
      id: 0,
      limit: 60,
    }
    setIsItemUpdated(true)
    getSevaUpdateResults(payload)
  }

  const handleSearchCrossClick = () => {
    if (searchText?.length === 0) {
      return
    }
    setSearchText('')
    debouncedSendRequest('')
    setLastPaginatedResult([])
  }

  return (
    <Box>
      <TextField
        className="search-filter-icon cursorshow truncate-search-placeholder"
        sx={{
          '& fieldset': { border: 'none' },
        }}
        value={searchText}
        inputProps={{ maxLength: 200, autoComplete: 'off' }}
        onChange={handleSearch}
        placeholder={`Search ${
          eventsForInitiative ? `Seva Events in ${title}` : InitiativeReports ? title : viewAllTitle
        }`}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <img
                className="cursorshow"
                alt="Search"
                width={20}
                height={21}
                src={searchText?.length ? CrossIcon : searchIcon}
                onClick={handleSearchCrossClick}
              />
            </InputAdornment>
          ),
        }}
      ></TextField>
    </Box>
  )
}

export default ViewAllScreenHeaderSearchBar
