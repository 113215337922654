import React, { useEffect, useMemo, useState } from 'react'
import { ImageListItem, Typography, Box, Avatar } from '@mui/material'
import { ImageLoader } from '../../ReusableComponents.js/ImageLoader'
import zipIcon from '../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../asserts/images/FileIcon.svg'
import unknownImg from '../../../asserts/images/folders.png'
import { handleFileName } from '../../ReusableComponents.js/reuseMethods'
import handleBannerImagesRefactor from './BannerSize/handleBannerImagesRefactor'
import './ImagesFolderRefactor.css'

const ImagesFolder = ({ languageData, setViewImage, folderName, imageResponse, setAllImages }) => {
  const [imageData, setImageData] = useState([])
  const [selectedBannerRatio, setSelectedBannerRatio] = useState('')
  const [bannerFilter, setBannerFilter] = useState(false)

  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )

  useEffect(() => {
    if (folderName == 'Outdoor Banner') {
      setBannerFilter(true)
    } else {
      setImageData(handleImages)
    }
  }, [])

  const handleImages =
    imageResponse &&
    imageResponse.filter((data) => {
      return data.typeof_image === folderName && data.imageLanguage === languageData
    })
  useEffect(() => {
    const bannerSizes = ['16:9', '1:1', '3:6']
    const data = imageResponse?.filter((data) => {
      return (
        data.typeof_image === folderName &&
        data.imageLanguage === languageData &&
        bannerSizes.includes(data.banner_size)
      )
    })

    let sortedData = []
    const ratio69 = data?.filter((item) => item.banner_size === '16:9')
    const ratio11 = data?.filter((item) => item.banner_size === '1:1')
    const ratio36 = data?.filter((item) => item.banner_size === '3:6')
    if (ratio69[0]?.url?.length > 0) {
      sortedData.push(...ratio69)
      setSelectedBannerRatio('16:9')
      setViewImage(JSON.parse(ratio69[0].url)[0])
    } else if (ratio11[0]?.url?.length > 0) {
      sortedData.push(...ratio11)
      setSelectedBannerRatio('1:1')
      setViewImage(JSON.parse(ratio11[0].url)[0])
    } else if (ratio36[0]?.url?.length > 0) {
      if (ratio36[0]?.url?.length > 0) {
        sortedData.push(...ratio36)
        setSelectedBannerRatio('3:6')
        setViewImage(JSON.parse(ratio36[0].url)[0])
      }
    }

    if (ratio69[0]?.url?.length > 0 || ratio11[0]?.url?.length > 0 || ratio36[0]?.url?.length > 0) {
      setImageData(sortedData)
    }
  }, [imageResponse])

  const handleFilter = (value) => {
    const data =
      imageResponse &&
      imageResponse.filter((data) => {
        return (
          data.typeof_image === folderName && data.imageLanguage === languageData && data.banner_size.trim() === value
        )
      })
    setImageData(data)
    setSelectedBannerRatio(value)
  }

  let imagesArray = []
  imageData &&
    imageData?.forEach((image) => {
      const url = JSON.parse(image.url)

      url && url?.forEach((data) => imagesArray.push(data))
    })

  useEffect(() => {
    setAllImages(imagesArray)
  }, [imageData])

  return (
    <>
      {bannerFilter ? handleBannerImagesRefactor(selectedBannerRatio, handleFilter) : null}
      <Box className="itemgrid2 cursorshow img-golder">
        {imagesArray &&
          imagesArray?.map((item, i) => (
            <Box onClick={() => setViewImage(item)} className="d-flex justify-content-center">
              {item.split('.').pop() === 'zip' || ['ai', 'postscript'].includes(item.split('.').pop()) ? (
                <ImageListItem
                  key={i}
                  className="bgfolder cursorshow "
                  sx={{
                    margin: '0px',
                    width: '160px !important',
                    height: ' 120px !important',
                    padding: '10px',
                    borderRadius: '0px',
                    aspectRatio: '1 / 0.9 !important',
                  }}
                >
                  <img
                    className="zip-ai-postscript-image-ref"
                    key={i}
                    src={
                      item.split('.').pop() === 'zip'
                        ? zipIcon
                        : ['ai', 'postscript'].includes(item.split('.').pop()) && fileIcon
                    }
                    srcSet={
                      item.split('.').pop() === 'zip'
                        ? zipIcon
                        : ['ai', 'postscript'].includes(item.split('.').pop()) && fileIcon
                    }
                  />
                  <Typography variant="subtitle1" sx={subtitleStyles}>
                    {handleFileName(item)}
                  </Typography>
                </ImageListItem>
              ) : (
                <Box className="image-supported">
                  <ImageLoader
                    key={i}
                    src={`${
                      item.split('.').pop() === 'zip'
                        ? zipIcon
                        : ['ai', 'postscript'].includes(item.split('.').pop())
                        ? fileIcon
                        : item
                    }`}
                    className=" im100 visible-image"
                    srcSet={`${
                      item.split('.').pop() === 'zip'
                        ? zipIcon
                        : ['ai', 'postscript'].includes(item.split('.').pop())
                        ? fileIcon
                        : item
                    }`}
                    onClick={() => {
                      setViewImage(item)
                    }}
                    defaultImg={unknownImg}
                    subtitleStyles={subtitleStyles}
                    item={item}
                  />
                </Box>
              )}
            </Box>
          ))}
        {imagesArray?.length < 1 && (
          <Typography component="span" className="mt-2 ml-1">
            No image added
          </Typography>
        )}
      </Box>
    </>
  )
}

export default ImagesFolder
