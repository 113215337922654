import { Grid } from '@mui/material'
import CreateInitiativeSectionTitle from '../CreateInitiativeSectionTitle/CreateInitiativeSectionTitle'
import AccordionComponent from '../AccordionComponent/AccordionComponent'
import './CreateSocialMediaKitContainer.css'

const CreateSocialMediaKitContainer = ({
  addTextExpand,
  checkTextDetails,
  textData,
  setOpenTextTemplateDialog,
  setOpenImageTemplateDialog,
  editDetails,
  postAuditLogs,
  sendGALog,
  isTextTemplateErrorVisible,
  setAddTextExpand,
  addImageExpand,
  imageData,
  isImageTemplateErrorVisible,
  setAddImageExpand,
  addVideoExpand,
  setAddVideoExpand,
  isVideoTemplateErrorVisible,
  videoData,
  setOpenVideoTemplateDialog,
}) => {
  const handleOpenTextTemplateDialog = () => {
    setOpenTextTemplateDialog(true)
  }
  const handleOpenImageTemplateDialog = () => {
    setOpenImageTemplateDialog(true)
  }
  const handleOpenVideosTemplateDialog = () => {
    setOpenVideoTemplateDialog(true)
  }

  return (
    <Grid item xs={10} md={10} lg={10} xl={10} sx={{ mt: 2 }}>
      <CreateInitiativeSectionTitle
        sx={{ lineHeight: '1.2', marginBottom: '0.5rem' }}
        title={'Create Social Media Kit'}
        className={'createKitText'}
      />
      <AccordionComponent
        expanded={addTextExpand}
        onClickForAccordion={
          checkTextDetails && textData[0] === undefined
            ? handleOpenTextTemplateDialog
            : editDetails && editDetails?.texttemplates?.length === 0
            ? handleOpenTextTemplateDialog
            : undefined
        }
        onClickForAccordionSummary={async () => {
          await postAuditLogs('add_text_template')
          sendGALog('add_template')
          setAddTextExpand(!addTextExpand)
        }}
        isTextTemplateErrorVisible={isTextTemplateErrorVisible}
        AccordionSummaryTitle={'Add Text Templates'}
        onClickForEditButton={async () => {
          await postAuditLogs('edit_text_template')
          handleOpenTextTemplateDialog()
        }}
        textData={textData}
        editDetails={editDetails}
        tooltipTitle={'Create share text or upload CSV files of share texts'}
      />

      <AccordionComponent
        onClickForAccordion={imageData.length === 0 ? handleOpenImageTemplateDialog : undefined}
        expanded={addImageExpand}
        onClickForAccordionSummary={async () => {
          await postAuditLogs('add_images')
          sendGALog('add_image')
          setAddImageExpand(!addImageExpand)
        }}
        AccordionSummaryTitle={'Add Images'}
        isImageTemplateErrorVisible={isImageTemplateErrorVisible}
        onClickForEditButton={async () => {
          await postAuditLogs('edit_image')
          handleOpenImageTemplateDialog()
        }}
        imageData={imageData}
        tooltipTitle={
          'Accepted file formats for open & zip uploads : .jpg, .jpeg, .jpe, .jif, .jfi, .png, .tiff, .psd, .eps, .ai, .indd, .raw, .jfif, .webp, .svg, .svgz, .heif'
        }
      />
      <AccordionComponent
        expanded={addVideoExpand}
        addVideoExpand={addVideoExpand}
        onClickForAccordion={videoData.length === 0 ? handleOpenVideosTemplateDialog : undefined}
        onClickForAccordionSummary={async () => {
          await postAuditLogs('add_videos')
          sendGALog('add_videos')
          setAddVideoExpand(!addVideoExpand)
        }}
        AccordionSummaryTitle={'Add Videos'}
        isVideoTemplateErrorVisible={isVideoTemplateErrorVisible}
        onClickForEditButton={async () => {
          await postAuditLogs('edit_video')
          handleOpenVideosTemplateDialog()
        }}
        videoData={videoData}
        tooltipTitle={'Accepted file formats for open & zip uploads: .mp4, .mov, .wmv, .webm, .avi, .flv, .mkv, .mts'}
      />
    </Grid>
  )
}

export default CreateSocialMediaKitContainer
