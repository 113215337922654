import { POST_INTIATIVE_FAILURE, POST_INTIATIVE_REQUEST, POST_INTIATIVE_SUCCESS } from './types';
import axiosInstance from '../../utils/api';

export const postIntiativeRequest = () => {
  return {
    type: POST_INTIATIVE_REQUEST,
  };
};
export const postIntiativeSuccess = (value) => {
  return {
    type: POST_INTIATIVE_SUCCESS,
    payload: value,
  };
};
export const postIntiativeFailure = (error) => {
  return {
    type: POST_INTIATIVE_FAILURE,
    payload: error,
  };
};

export const postIntiative = (payload, id) => async (dispatch) => {
  dispatch(postIntiativeRequest);
  let iniId = id ? id : 0;
  return axiosInstance
    .post(`/api/initiative/create/${iniId}`, payload)
    .then((response) => {
      const result = response.data;
      dispatch(postIntiativeSuccess(result));
      return response;
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(postIntiativeFailure(errorMsg));
    });
};
