import { Grid, Box } from '@mui/material'
import './InitiativesOverview.css'
function Overview({ title, id, count }) {
  return (
    <Grid item xs={4} className="count-alligment">
      <Box className="count-headers" title={title}>
        {title}
      </Box>
      <Box className={`count-container count-conatiner-color${id === 1 ? '1' : id === 2 ? '2' : '3'}`}>
        {count === null ? 0 : count}
      </Box>
    </Grid>
  )
}
export default Overview
