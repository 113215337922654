import { GET_EVENT_FAILURE, GET_EVENT_REQUEST, GET_EVENT_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  error: '',
};

export const getAllEvents = (state = initialState, action) => {
  switch (action.type) {
    case GET_EVENT_REQUEST:
      return {
        ...state,
      };
    case GET_EVENT_SUCCESS:
      return {
        data: action.payload,
      };
    case GET_EVENT_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
