import { Box, Card, Tabs, CardContent, CardMedia, Typography, IconButton, tabsClasses, Button } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { memo } from 'react'
import { useState } from 'react'
import { getIntiativesReportByInitiativeIdAndMpId } from '../../../store/action/ongoingSevaInitiativesList'
import { useNotificationContext } from '../../../utils/NotificationContext'
import EmptyDataCard from '../../ReusableComponents.js/EmptyStateCard'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { defaultErrorMessage } from '../../../utils/constant'
import CreateInitiativeReportDialog from '../CreateInitiativeReportDialog/createInitiativeReportDialog'
import UpdateInitiativeReportDetails from '../UpdateInitiativeReportDetails/UpdateInitiativeReportDialog'
import { getIntiativesReportByIdList } from '../../../store/action/ongoingSevaInitiativesList'
import { getIds } from '../../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { useMemo } from 'react'
import './ReportCarousel.css'
import { useNavigate } from 'react-router-dom'
import { getRoles } from '../../ReusableComponents.js/getIds'

function ReportCarousel({
  open,
  setOpen,
  value,
  handleChange,
  personDetails,
  setPersonDetails,
  setOpenInitiativeDetailsDialog,
  tenure,
}) {
  const userId = parseInt(getRoles())
  const [participants, setInitiativeParticipants] = useState(0)
  const [openUpdateIntiativeDialog, setOpenUpdateInitiativeDialog] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const initiativeReportDetailsByMPIdAndIntiativeId = useSelector(
    (state) => state?.initiativeReportDetailsByMPIdAndIntiativeId?.data
  )
  const { showNotification } = useNotificationContext()
  const initiativeReportData = useSelector((state) => state?.intiativesReportByInitiativeId?.data)

  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  let initiativeId = location?.state?.initiativeId
  let initiativeName = location?.state?.initiativeName
  let eventByMpId = location?.state?.eventByMpId
  let mpId = location?.state?.mpId
  const selectedOngoingSevaInitiativeDetails = location?.state?.selectedOngoingSevaInitiativeDetails

  const navigate = useNavigate()
  const arrowStyles = {
    color: '#356F92',
    backgroundColor: '#E3F5FF',
    borderRadius: '50%',
    padding: '7px',
    width: '1.5em',
    height: '1.5em',
  }

  const iconButtonStylesLeft = {
    borderRadius: 0,
    marginRight: '-6px',
    zIndex: 11,
  }

  const iconButtonStylesRight = {
    borderRadius: 0,
    marginLeft: '-6px',
    zIndex: 11,
  }

  const postAuditLogs = async (sqId, i_id, report_id, e_id) => {
    let requestData = {
      useridtype: getIds(),
    }
    if (i_id !== null) {
      requestData['i_id'] = i_id && i_id
    } else if (report_id !== null) {
      requestData['report_id'] = report_id
    } else if (e_id !== null) {
      requestData['e_id'] = e_id
    }
    await makeLogRequest('individual_initiatives_wall', sqId, requestData)
  }

  const handleOpenUpdateInitiativeDetails = async (item) => {
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(item?.initiativeId, item?.userId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          showNotification('Error', response.data?.message, 'error')
        } else {
          await setInitiativeParticipants(response?.data.participant)
          setPersonDetails(item)
          setOpenUpdateInitiativeDialog(true)
        }
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    }
  }

  const handleCloseUpdateInitiativeDetails = (reset, reportFiles, setUploadFiles) => {
    reset()
    reportFiles([])
    setUploadFiles([])
    setOpenUpdateInitiativeDialog(false)
    dispatch(getIntiativesReportByIdList(initiativeId))
  }

  const handleClose = (reset, setImages, setFiles) => {
    setOpen(false)
    reset()
    setImages([])
    setFiles([])
  }

  const ongoingSevaIntiativesReports = useMemo(() => {
    return initiativeReportData?.reportdata == null || initiativeReportData?.reportdata?.length < 1
  }, [initiativeReportData])

  const handleOpenInitiativeDetails = async (item) => {
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(item?.initiativeId, item?.userId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          showNotification('Error', response.data?.message, 'error')
        } else {
          setPersonDetails(item)
          await setInitiativeParticipants(response.data?.participant)
          setOpenInitiativeDetailsDialog(true)
        }
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    }
  }
  const handleViewAllReports = () => {
    navigate('/SevaUpdates/viewAllSevaEvents', {
      state: {
        viewAllValue: 'Initiative Reports',
        InitiativeReports: true,
        data: initiativeReportData?.reportdata,
        title: initiativeName,
        eventsForInitiative: false,
        initiativeId: initiativeId,
        tenure: tenure,
        selectedOngoingSevaInitiativeDetails: selectedOngoingSevaInitiativeDetails,
      },
    })
  }

  const handleCloseInitiativeDetails = () => {
    setOpenInitiativeDetailsDialog(false)
  }

  return (
    <>
      <Box className="d-flex justify-content-between mt-4 pr-4">
        <Typography component="h3" className=" evnts-under-init cmn-text">
          Initiative Reports by
        </Typography>
        {initiativeReportData?.reportdata?.length > 4 && (
          <Box>
            <Button className="button-tr-citizen" onClick={handleViewAllReports}>
              View All
            </Button>
          </Box>
        )}
      </Box>
      {ongoingSevaIntiativesReports ? (
        <EmptyDataCard cardFor="Initiative Report" />
      ) : (
        <Box className="d-flex justify-content-between leaders-cards ">
          <Box
            sx={{
              flexGrow: 1,
              minWidth: { xs: 920, sm: 1080 },
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              aria-label="visible arrows tabs example"
              ScrollButtonComponent={memo((props) => {
                if (props.direction === 'left') {
                  return (
                    <IconButton {...props} sx={iconButtonStylesLeft}>
                      <ArrowBackIcon sx={arrowStyles} />
                    </IconButton>
                  )
                } else if (props.direction === 'right') {
                  return (
                    <IconButton {...props} sx={iconButtonStylesRight}>
                      <ArrowForwardIcon sx={arrowStyles} />
                    </IconButton>
                  )
                } else {
                  return null
                }
              })}
              TabIndicatorProps={{
                style: { display: 'none' },
              }}
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
            >
              {initiativeReportData &&
                initiativeReportData?.reportdata?.map((item) => (
                  <Card
                    sx={{ minWidth: 305, mr: 2, borderRadius: '20px' }}
                    className="bshadow cursorshow"
                    onClick={() =>
                      mpProfileData?.id === item?.userId && userId !== 2 && userId !== 3
                        ? (handleOpenUpdateInitiativeDetails(item),
                          postAuditLogs('initiative_report_card', null, item?.id, null))
                        : handleOpenInitiativeDetails(item)
                    }
                  >
                    <CardContent>
                      {item?.user?.user_avatar ? (
                        <Box className="text-center pt-3 d-flex justify-content-center">
                          <CardMedia
                            component="img"
                            src={item?.user?.user_avatar}
                            className="img-circle leader-circle-img mr-1 circularimage init-report-mpcard-profile"
                          />
                        </Box>
                      ) : (
                        <AccountCircleIcon
                          sx={{
                            fontSize: 'xx-large',
                            width: '98px',
                            height: '98px',
                            position: 'relative',
                            left: '32%',
                            top: '16px',
                            color: '#EEEEEE',
                          }}
                        />
                      )}
                      <Box className="card-content">
                        <Typography component="h2" className="mp-name cmn-text">
                          {item?.user?.user_name}
                        </Typography>
                        <Typography variant="span" className="initial cmn-text mp-detail">
                          {item?.user?.house}
                        </Typography>
                        <Box mt={4} />
                        <Typography variant="span" className="constituency cmn-text mp-constituency">
                          Constituency : {item?.user?.constituency_name ? item?.user?.constituency_name : ' -'}
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
            </Tabs>
          </Box>
        </Box>
      )}
      <CreateInitiativeReportDialog
        open={open}
        handleClose={handleClose}
        initiativeName={initiativeName}
        initiativeId={initiativeId}
        mpId={mpId}
        participants={participants}
      />
      {openUpdateIntiativeDialog && (
        <UpdateInitiativeReportDetails
          handleCloseUpdateInitiativeDetails={handleCloseUpdateInitiativeDetails}
          openUpdateIntiativeDialog={openUpdateIntiativeDialog}
          details={personDetails}
          initiativeName={initiativeName}
          initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
          allInitiativeReportsPage={true}
          participants={participants}
          eventByMpId={eventByMpId}
          initiativeID={initiativeId}
          tenure={tenure}
        />
      )}
    </>
  )
}
export default ReportCarousel
