import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import NoImageFound from 'asserts/images/noImageFound.jpg'
import 'utils/LoadingContext.css'
import SmallLoaderImg from 'asserts/images/loader-img.png'
import { handleFileName } from 'Components/ReusableComponents.js/reuseMethods'
import { ImageListItem, Typography } from '@mui/material'
import 'Components/ReusableComponents.js/ImageLoader.css'

export function ImageLoader({
  src,
  onClick,
  srcSet,
  className,
  width,
  loading,
  id,
  height,
  onError,
  sx,
  defaultImg,
  item,
  subtitleStyles,
  extrawidth,
  loaderHeight,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [imageSrc, setImageSrc] = useState(null)
  const [defaultImage, setDefaultImage] = useState(false)
  const loaderStyle = {
    position: 'relative',
    top: '50px',
    left: '80px',
  }
  useEffect(() => {
    setDefaultImage(false)
    if (!src?.endsWith('.mp4#t=0.1')) {
      const img = new Image()
      img.src = src
      img.onload = () => {
        setIsLoading(false)
        setImageSrc(src)
      }
      img.onerror = () => {
        setDefaultImage(true)
        setIsLoading(false)
        setImageSrc(defaultImg ? defaultImg : NoImageFound)
      }
    } else {
      setImageSrc(src)
      setIsLoading(false)
    }
  }, [src])

  return (
    <Box>
      {isLoading ? (
        <Box sx={loaderStyle}>
          <Box className="custom-loader-small" style={loaderHeight ? { height: loaderHeight } : {}}>
            <img className="small-loader-img" src={SmallLoaderImg} alt="Loading..." />
            <Box className="custom-small-spinner">
              <div className="loader-box-small box-1"></div>
              <div className="loader-box-small box-2"></div>
              <div className="loader-box-small box-3"></div>
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          {!defaultImage ? (
            !src?.endsWith('.mp4#t=0.1') ? (
              <img
                src={imageSrc}
                alt=""
                onClick={onClick && onClick}
                className={`border-radius-15 ${className && className}`}
                width={width}
                height={height}
                id={id}
                onError={onError}
                style={sx}
              />
            ) : (
              <video className="object-fit-fill border-radius-15" src={imageSrc} width={width} height={height} />
            )
          ) : (
            <ImageListItem
              // key={}
              className="bgfolder cursorshow"
              sx={{
                width: extrawidth && '300px !important',
                margin: extrawidth && '107px',
                padding: '20px 20px 0 20px',
                aspectRatio: '1 / 0.9 !important',
              }}
            >
              <img className="default-image" src={defaultImg} />
              <Typography variant="subtitle1" sx={subtitleStyles}>
                {handleFileName(item)}
              </Typography>
            </ImageListItem>
          )}
        </>
      )}
    </Box>
  )
}
