import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Grid, Typography } from '@mui/material'
import SearchBar from 'Components/SevaInitiativesRefactored/SearchBar/SearchBar'
import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import CreateInitiativesTitleContainer from 'Components/SevaInitiativesRefactored/CreateInitiativesTitleContainer/CreateInitiativesTitleContainer'
import InitiativesList from 'Components/SevaInitiativesRefactored/InitiativesList/InitiativesList'
import { useLoading } from 'utils/LoadingContext'
import { useNotificationContext } from 'utils/NotificationContext'
import { makeLogRequest } from 'utils/AuditLogs'

import getUserProfile from 'store/action/getUserProfile'
import getInitiativeList from 'store/action/initiativeList'

import './SevaInitiatives.css'

const SevaInitiatives = () => {
  const { showNotification } = useNotificationContext()
  const { setLoading } = useLoading()
  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state?.userProfile?.data)

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('seva_initiatives', sqId, data)
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      await dispatch(getUserProfile())
      await dispatch(getInitiativeList())
      await postAuditLogs('pageload')
    } catch (error) {
      showNotification('Error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box className="page-wrapper d-flex seva-initiatives-outer-container">
      <SideMenu activeTab={'SevaInitiative'} />
      <Box className="main-wrapper customscroll w-100 seva-initiatives-inner-container">
        <Grid container>
          <Grid md={12} lg={12} xl={12}>
            <Box className="d-flex justify-content-between align-items-center">
              <Typography component="h1" className="seva-initiatives-title-text">
                {' '}
                Seva Initiatives
              </Typography>
              <SearchBar />
            </Box>
          </Grid>
          <Box className="create-dash-new card create-initiatives-title-container">
            <CreateInitiativesTitleContainer
              title={'Create Initiatives'}
              subTitle={'Would you like to create a Seva Initiative? Add the details here'}
            />
          </Box>
          <Grid md={12} lg={12} xl={12}>
            <InitiativesList />
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
export default SevaInitiatives
