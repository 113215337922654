import { Button, Box } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import './AddMoreButton.css'

const AddMoreButton = ({ boxSx, buttonTitle, onClick, type }) => {
  return (
    <Box sx={boxSx}>
      <Button
        variant="outlined"
        sx={type === 'text' && { boxShadow: 'none' }}
        className={`${type === 'asset' ? 'button-tr-citizen-admin1' : 'button-tr-citizen-admin'}  ${
          type === 'text' && 'mglefto addMore'
        } `}
        startIcon={buttonTitle !== 'Remove' && <AddIcon />}
        onClick={onClick}
      >
        {buttonTitle}
      </Button>
    </Box>
  )
}

export default AddMoreButton
