import { SOCIAL_MEDIA_KIT_FAILURE, SOCIAL_MEDIA_KIT_REQUEST, SOCIAL_MEDIA_KIT_SUCCESS } from './types';
import axiosInstance from '../../utils/api';

export const socialMediaKitRequest = () => {
  return {
    type: SOCIAL_MEDIA_KIT_REQUEST,
    payload: [],
  };
};
export const socialMediaKitSuccess = (value) => {
  return {
    type: SOCIAL_MEDIA_KIT_SUCCESS,
    payload: value,
  };
};
export const socialMediaKitFailure = (error) => {
  return {
    type: SOCIAL_MEDIA_KIT_FAILURE,
    payload: error,
  };
};

export const getSocialMediaKit = (query) => async (dispatch) => {
  dispatch(socialMediaKitRequest);
  await axiosInstance
    .get(`/api/socialMedia/getbyInitiative/${query}`)
    .then((response) => {
      const result = response?.data[0];
      dispatch(socialMediaKitSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(socialMediaKitFailure(errorMsg));
    });
};
