import {
  FormControl,
  Select,
  Grid,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormLabel,
  InputBase,
  ListItem,
  Avatar,
  List,
  Chip,
  Typography,
  Box,
  ListItemAvatar,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { iconComponent } from 'Components/ReusableComponents.js/reuseMethods'
import CrossIcon from 'asserts/images/close-2.svg'
import Search from '@mui/icons-material/Search'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import './AssignMpSelectComponent.css'

const AssignMpSelectComponent = ({
  inputLabelTitle,
  stateSearchClearIcon,
  selectedValue,
  setSelectedState,
  setSelectedMps,
  clearIconOnClick,
  inputBaseValue,
  inputBaseOnChange,
  stateApiResult,
  stateData,
  placeholder,
  mpApiResult,
  assignMpList,
  disabled,
}) => {
  const handleStateDelete = (e, state) => {
    const resultAfter = selectedValue.filter((item) => item.id !== state.id)
    setSelectedState(resultAfter)
    const mpResults = assignMpList.filter((item) => item.state_name !== state.state_name)
    setSelectedMps(mpResults)
  }

  const handleStateCheckedState = (state) => {
    if (state.id === 'all' && selectedValue?.length === stateData?.length) {
      return true
    }
    return selectedValue?.findIndex((item) => item.id === state.id) > -1 || false
  }

  const handleStateClick = (state) => {
    if (state.id === 'all') {
      if (stateData.length === selectedValue.length) {
        setSelectedState([])
        setSelectedMps([])
      } else {
        const stateResults = [...stateData]
        const allResults = stateResults.map((item) => {
          return {
            id: item.id,
            state_name: item.state_name,
          }
        })
        setSelectedState(allResults)
      }
      return
    }

    if (selectedValue.findIndex((item) => item.id === state.id) > -1) {
      const resultAfter = selectedValue.filter((item) => item.id !== state.id)
      setSelectedState(resultAfter)
    } else {
      setSelectedState((prev) => [...prev, { id: state.id, state_name: state.state_name }])
    }
  }

  const handleMPClick = (mp) => {
    if (mp.id === 'all') {
      if (assignMpList?.mpAssignData?.length === selectedValue.length) {
        setSelectedMps([])
      } else {
        const mpResults = [...assignMpList.mpAssignData]
        const allResults = mpResults.map((item) => {
          return {
            id: item.id,
            user_name: item.user_name,
            state_name: item.state_name,
            house: item?.house,
            user_avatar: item?.user_avatar,
          }
        })
        setSelectedMps(allResults)
      }
      return
    }

    if (selectedValue.findIndex((item) => item.id === mp.id) > -1) {
      const resultAfter = selectedValue.filter((item) => item.id !== mp.id)
      setSelectedMps(resultAfter)
    } else {
      setSelectedMps((prev) => [
        ...prev,
        {
          id: mp.id,
          state_name: mp.state_name,
          user_name: mp.user_name,
          house: mp?.house,
          user_avatar: mp?.user_avatar,
        },
      ])
    }
  }

  const handleMPCheckedState = (mp) => {
    if (mp.id === 'all' && selectedValue?.length === assignMpList?.mpAssignData?.length) {
      return true
    }
    return selectedValue?.findIndex((item) => item.id === mp.id) > -1 || false
  }

  const handleMPDelete = (e, mp) => {
    const resultAfter = selectedValue.filter((item) => item.id !== mp.id)
    setSelectedMps(resultAfter)
    if (resultAfter?.length === 0) {
      setSelectedState([])
    }
  }

  return (
    <Grid container>
      <FormControl sx={{ m: 1, width: 400 }} size="small" fullWidth>
        <Typography component="b" sx={{ fontWeight: 'bold' }} className="stateFontStyle">
          {inputLabelTitle}
        </Typography>
        <FormLabel id="demo-multiple-chip-label" className="label"></FormLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          IconComponent={iconComponent}
          multiple
          disabled={disabled}
          value={selectedValue}
          input={<OutlinedInput className={selectedValue?.length >= 2 && 'textArea'} />}
          renderValue={(selected) => (
            <Box>
              {selected?.map((item) => (
                <Chip
                  sx={
                    inputLabelTitle === 'Select State'
                      ? {
                          backgroundColor: '#e3f5ff !important',
                          margin: '3px !important',
                          fontWeight: 'bold',
                          color: '#505050 !important',
                        }
                      : {
                          height: item.house ? '50px' : '40px',
                          backgroundColor: '#e3f5ff !important',
                          borderRadius: '37px !important',
                          margin: '4px',
                        }
                  }
                  key={item.id}
                  label={
                    inputLabelTitle === 'Select State' ? (
                      item.state_name
                    ) : (
                      <Grid container>
                        <Grid
                          xs={3}
                          sx={{
                            mt: item.user_avatar
                              ? item.house
                                ? '12px'
                                : '2px'
                              : !item.user_avatar && !item.house
                              ? '2px'
                              : item?.house
                              ? '9px'
                              : '12px',
                          }}
                        >
                          <Avatar sx={{ height: '25px', width: '25px' }}>
                            {item?.user_avatar ? (
                              <Avatar src={item.user_avatar}></Avatar>
                            ) : (
                              <AccountCircleIcon
                                sx={{
                                  fontSize: 'xx-large',
                                  width: '26px',
                                  height: 'auto',
                                  border: 0,
                                }}
                              />
                            )}
                          </Avatar>
                        </Grid>
                        <Grid
                          xs={8}
                          sx={{
                            mr: '-7px',
                            ml: '31px',
                            mt: item?.user_avatar
                              ? item?.house
                                ? '-38px'
                                : '-25px'
                              : !item?.user_avatar && !item?.house
                              ? '-26px'
                              : item?.house
                              ? '-33px'
                              : '-37px',
                          }}
                        >
                          <Typography component="span" variant="inherit">
                            {item.user_name}
                          </Typography>
                          <br />
                          <Typography
                            component="span"
                            sx={{
                              fontSize: '12px',
                              marginTop: '-6px !important',
                            }}
                          >
                            {item.house}
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  }
                  clickable
                  deleteIcon={
                    <Avatar
                      src={CrossIcon}
                      sx={{ width: '22px', height: '22px' }}
                      className="filters"
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  onDelete={(e) =>
                    inputLabelTitle === 'Select State' ? handleStateDelete(e, item) : handleMPDelete(e, item)
                  }
                />
              ))}
            </Box>
          )}
        >
          <Box>
            {stateSearchClearIcon ? (
              <ClearIcon position="start" sx={{ cursor: 'pointer' }} onClick={clearIconOnClick} />
            ) : (
              <Search sx={{ color: '#356F92', ml: 1 }} position="start"></Search>
            )}
            <InputBase
              onKeyDown={(e) => e.stopPropagation()}
              onChange={inputBaseOnChange}
              sx={{ width: '85%' }}
              value={inputBaseValue}
              placeholder={placeholder}
            />
          </Box>

          <Box className="menu-container">
            {inputLabelTitle === 'Select State' ? (
              stateApiResult?.length > 0 &&
              stateApiResult?.map((state) => (
                <MenuItem
                  key={state.id}
                  value={state.state_name}
                  sx={{
                    borderBottom: '1px solid #F5F5F5',
                    margin: '0 10px',
                  }}
                  onClick={(e) => {
                    handleStateClick(state)
                  }}
                >
                  <Checkbox sx={{ padding: '2px', transform: 'scale(.7)' }} checked={handleStateCheckedState(state)} />
                  <List sx={{ padding: '0px' }}>
                    <ListItem sx={{ padding: '0px' }}>
                      <ListItemText primary={state.state_name} className="listItem" />
                    </ListItem>
                  </List>
                </MenuItem>
              ))
            ) : mpApiResult?.length > 1 ? (
              <Box className="menu-container">
                {mpApiResult?.length > 0 &&
                  mpApiResult.map((mp) => (
                    <MenuItem
                      key={mp.id}
                      value={mp.user_name}
                      sx={{
                        borderBottom: '1px solid #F5F5F5',
                        margin: '0 10px',
                      }}
                      onClick={(e) => {
                        handleMPClick(mp)
                      }}
                    >
                      <Checkbox sx={{ padding: '2px', transform: 'scale(.7)' }} checked={handleMPCheckedState(mp)} />
                      <List sx={{ padding: '0px' }}>
                        <ListItem sx={{ padding: '0px' }}>
                          {mp.id !== 'all' && (
                            <ListItemAvatar sx={{ minWidth: '30px' }}>
                              <Avatar sx={{ height: '25px', width: '25px' }}>
                                {mp.user_avatar ? (
                                  <Avatar src={mp.user_avatar}></Avatar>
                                ) : (
                                  <AccountCircleIcon
                                    sx={{
                                      fontSize: 'xx-large',
                                      width: '26px',
                                      height: 'auto',
                                      border: 0,
                                      color: '#EEEEEE',
                                    }}
                                  />
                                )}
                              </Avatar>
                            </ListItemAvatar>
                          )}
                          <ListItemText primary={mp.user_name} secondary={mp.house} className="listItem" />
                        </ListItem>
                      </List>
                    </MenuItem>
                  ))}
              </Box>
            ) : (
              <Typography className="no-mp-found-position" variant="body1">
                No mp found
              </Typography>
            )}
          </Box>
        </Select>
      </FormControl>
    </Grid>
  )
}

export default AssignMpSelectComponent
