import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import NoImageFound from '../../../src/asserts/images/noImageFound.jpg'
import '../../utils/LoadingContext.css'
import SmallLoaderImg from '../../asserts/images/loader-img.png'
import { handleFileName } from './reuseMethods'
import { ImageListItem, Typography } from '@mui/material'

export function ImageLoader2({
  src,
  onClick,
  srcSet,
  className,
  width,
  loading,
  id,
  height,
  onError,
  sx,
  defaultImg,
  item,
  subtitleStyles,
  extrawidth,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [imageSrc, setImageSrc] = useState(null)
  const [defaultImage, setDefaultImage] = useState(false)
  const loaderStyle = {
    position: 'relative',
    top: '50px',
    left: '80px',
  }
  useEffect(() => {
    const img = new Image()
    setDefaultImage(false)

    img.src = src
    img.onload = () => {
      setIsLoading(false)
      setImageSrc(src)
    }
    img.onerror = () => {
      setDefaultImage(true)
      setIsLoading(false)
      setImageSrc(defaultImg ? defaultImg : NoImageFound)
    }
  }, [src])

  return (
    <>
      {!defaultImage ? (
        <img
          src={imageSrc}
          alt=""
          onClick={onClick && onClick}
          className={className && className}
          width={width}
          height={height}
          id={id}
          onError={onError}
          style={sx}
        />
      ) : (
        <ImageListItem
          className="bgfolder cursorshow"
          sx={{
            position: 'relative',
            margin: '0px 0px -67px',
            display: extrawidth && 'inline-table',
            right: !extrawidth && '51px',
            width: '200px !important',
            padding: '7px 44px 58px',
            aspectRatio: '1 / 0.9 !important',
          }}
        >
          <img
            className="m-0"
            src={defaultImg} // alt={item.title}
            onClick={onClick && onClick}
          />
          <Typography variant="subtitle1" sx={subtitleStyles}>
            {handleFileName(item)}
          </Typography>
        </ImageListItem>
      )}
    </>
  )
}
