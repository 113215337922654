import { useRef } from 'react'
import play from '../../asserts/images/Play.svg'
import pause from '../../asserts/images/Pause.png'
import { useState } from 'react'
import './VideoPlayerPreview.css'
import { Box, Button, Avatar } from '@mui/material'

function VideoPlayerPreview({
  src,
  className,
  width,
  height,
  position,
  top,
  left,
  transform,
  background,
  onClick,
}) {
  const videoRef = useRef(null)
  const playWidth = `${width / 5}px`
  const [playbtn, setPlay] = useState(true)
  function togglePlay(e) {
    const video = videoRef.current
    e.stopPropagation()
    e.preventDefault()
    if (video.paused) {
      video.play()
      setPlay(false)
    } else {
      video.pause()
      setPlay(true)
    }
  }

  return (
    <Box className="position-relative">
      <video
        ref={videoRef}
        onEnded={() => setPlay(true)}
        className={className && className}
        width={width && width}
        height={height && height}
        onClick={onClick && onClick}
        style={{ position, top, left, transform, background }}
        src={src && src}
      ></video>

      <Button
        onClick={(e) => togglePlay(e)}
        className="video-player-preview-button"
        style={{ width: width ? playWidth : '50px' }}
      >
        <Avatar
          variant="circular"
          className="video-player-preview-play-button"
          src={playbtn ? play : pause}
        />
      </Button>
    </Box>
  )
}

export default VideoPlayerPreview
