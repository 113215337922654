import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { getMasterList } from 'store/action/masterList'
import axiosInstance from 'utils/api'
import { useNotificationContext } from 'utils/NotificationContext'

import SideMenu from 'Components/SideMenuRefactored/SideMenu'
import MasterListBreadCrumb from 'Components/Masterlist/MasterListBreadCrumb'
import MasterListAccordion from 'Components/Masterlist/MasterListAccordion'
import MasterUserList from 'Components/Masterlist/MasterUserList'
import MasterListSearchBar from 'Components/Masterlist/MasterListSearchBar'
import MasterListButtonSection from 'Components/Masterlist/MasterListButtonSection'
import UpdateDialog from 'Components/Masterlist/UpdateDialog/'
import './MasterList.css'

export default function MasterList() {
  const readonly = false
  const dispatch = useDispatch()
  const [addExpand, setAddExpand] = useState(false)
  const [deleteExpand, setDeleteExpand] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [page, setPage] = useState(1)
  const [openDialog, setOpenDialog] = useState(false)
  const [addedUsers, setAddedUsers] = useState([])
  const [deletedUsers, setDeletedUsers] = useState([])
  const [tableTop, setTableTop] = useState(false)

  const containerRef = useRef()
  const { showNotification } = useNotificationContext()
  let navigate = useNavigate()

  const getSearchContent = (value) => {
    setPage(1)
    dispatch(getMasterList(1, 30, value))
  }

  useEffect(() => {
    containerRef.current.scrollTop = 0
  }, [containerRef])

  useEffect(() => {
    if (searchText?.length) return
    dispatch(getMasterList(page, 30))
  }, [page, searchText])

  const isChange = useMemo(() => {
    return addedUsers.length || deletedUsers.length
  }, [addedUsers, deletedUsers])

  const updateMasterList = async () => {
    if (!addedUsers.length && !deletedUsers.length) return
    const payload = {
      newUsers: addedUsers,
      deleteUsers: deletedUsers,
    }

    try {
      const { data } = await axiosInstance.post(`/api/admin/master/update`, payload)
      setAddedUsers([])
      setDeletedUsers([])
      handleResponseNotification(data)
      closeUpdateDialog(false)
      dispatch(getMasterList(1, 30))
      setAddExpand(false)
      setDeleteExpand(false)
      setTableTop(true)
      containerRef.current.scrollTop = 0
      setPage(1)
    } catch (error) {
      console.log(error, 'error in update API')
      showNotification('Error', 'Error in update API')
      closeUpdateDialog(false)
    }
  }

  const handleResponseNotification = (data) => {
    const { additionMessages, deletionMessages } = data
    const responseData = [...additionMessages, ...deletionMessages]
    const type = responseData.some((item) => item.state === 'success') ? 'Success' : 'Error'
    const popUpContent = responseData.map((item) => {
      const cssClass = item.state === 'success' ? 'success-toast' : 'error-toast'
      return `<span class='${cssClass}'>${item.message}</span>`
    })
    showNotification(type, popUpContent, type.toLowerCase(), 'medium', true)
  }

  const clickCancelButton = () => {
    if (!isChange) {
      navigate('/settings')
    }
    setAddedUsers([])
    setDeletedUsers([])
    setAddExpand(false)
    setDeleteExpand(false)
  }

  const openUpdateDialog = () => {
    setOpenDialog(true)
  }

  const closeUpdateDialog = () => {
    setOpenDialog(false)
  }

  return (
    <Box className="page-wrapper d-flex">
      <SideMenu activeTab={'Settings'} settingsTab={isChange} />
      <Box className="main-wrapper customscroll adminhome-container" ref={containerRef}>
        <Box className="middle-wrapper">
          <Box className="d-flex justify-content-between align-items-center">
            <Typography component="h1" className="page-title mb-0 cmn-text master-list-header">
              Master List
            </Typography>
          </Box>
          <MasterListBreadCrumb />
          <Box className="list-wrapper">
            <Box className="row viewAllHeader">
              <Typography component="h1" className="page-title mb-0 font-style-property" sx={{ marginLeft: '14px' }}>
                Master List
              </Typography>
              <MasterListSearchBar
                searchText={searchText}
                setSearchText={setSearchText}
                searchContent={getSearchContent}
              />
            </Box>
            <MasterUserList setPage={setPage} top={tableTop} />
            <Box className="mt-5 addition-parent">
              <MasterListAccordion
                title="Addition of Users"
                expanded={addExpand}
                expandAccordion={() => {
                  setAddExpand((value) => !value)
                }}
                addMoreText="Add More"
                users={addedUsers}
                setData={setAddedUsers}
              />
              <MasterListAccordion
                title="Deletion of Users"
                expanded={deleteExpand}
                expandAccordion={() => {
                  setDeleteExpand((value) => !value)
                }}
                addMoreText="Add More"
                users={deletedUsers}
                setData={setDeletedUsers}
              />
            </Box>
            <MasterListButtonSection
              updateList={openUpdateDialog}
              clickCancel={clickCancelButton}
              showUpdateButton={isChange}
            />
          </Box>
        </Box>
      </Box>
      <UpdateDialog openDialog={openDialog} handleCloseDialog={closeUpdateDialog} handleUpdate={updateMasterList} />
    </Box>
  )
}
