import { Box, Typography } from '@mui/material'
import './AboutSevaScoreText.css'

const AboutSevaScoreText = ({ key, item, text }) => {
  return (
    <Box key={key} className="w-100 pt-3 d-flex justify-content-between div-bottom-border">
      <Typography className="table-item-label">
        {item.value} {text} {item.label}
      </Typography>
      <Typography className="table-header-content-label text-color font-size-22px mr-3">{item.value}</Typography>
    </Box>
  )
}

export default AboutSevaScoreText
