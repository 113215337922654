import { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import VideoPlayer from './VideoPlayer'
import '../../utils/LoadingContext.css'
import SmallLoaderImg from '../../asserts/images/loader-img.png'
import play from '../../asserts/images/Play.svg'
import './VideoLoader.css'

export function VideoLoader({
  src,
  onClick,
  srcSet,
  className,
  width,
  loading,
  iframe,
  top,
  left,
  height,
  allow,
  isSmallSection = false,
  videosFolderPath,
  addVideoExpandState,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [videoSrc, setVideoSrc] = useState(null)

  useEffect(() => {
    setIsLoading(true) // Set isLoading to true when the src prop changes to show the loader

    const video = document.createElement('video')
    video.src = src
    video.preload = 'metadata'

    const handleLoadedMetadata = () => {
      setIsLoading(false)
      setVideoSrc(src)
    }

    video.addEventListener('loadedmetadata', handleLoadedMetadata)

    // Clean up the event listener when the component unmounts or when src changes again
    return () => {
      video.removeEventListener('loadedmetadata', handleLoadedMetadata)
    }
  }, [src])

  const loaderStyle = {
    position: 'relative',
    top: '50px',
    left: '80px',
  }
  return (
    <Box className="position-relative">
      {isLoading ? (
        <Box sx={loaderStyle} className="custome-loader-path">
          <Box
            className="custom-loader
          "
          >
            <img className="small-loader-img" src={SmallLoaderImg} alt="Loading..." />
            <Box className="custom-small-spinner">
              <div className="loader-box-small box-1"></div>
              <div className="loader-box-small box-2"></div>
              <div className="loader-box-small box-3"></div>
            </Box>
          </Box>
        </Box>
      ) : iframe ? (
        <iframe
          width={width && width}
          height={height && height}
          src={src && src}
          frameBorder="0"
          allow={allow && allow}
          allowFullScreen
          title="preview iframe"
        ></iframe>
      ) : (
        <>
          {videosFolderPath && <img src={play} className="play-icon-position" />}
          <VideoPlayer
            width={width && width}
            height={height && height}
            playButtonWidth="44"
            src={videoSrc}
            onClick={onClick && onClick}
            top={top && top}
            left={left && left}
            isSmallSection={isSmallSection}
            addVideoExpandState={addVideoExpandState}
          />
        </>
      )}
    </Box>
  )
}
