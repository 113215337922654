import { Grid, FormControl, Typography, FormHelperText, Select } from '@mui/material'
import { iconComponent } from '../../ReusableComponents.js/reuseMethods'

const AddAssetInputField = ({
  labelName,
  data,
  errorMessage,
  registerFunction,
  onChange,
  renderValue,
  defaultValue,
}) => {
  return (
    <Grid item xs={12} md={12} lg={12} xl={12} sx={{ width: '280px' }}>
      <FormControl
        size="small"
        fullWidth
        sx={{
          '& .MuiInputBase-input': {
            borderRadius: '14px!important',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: '14px',
          },
        }}
      >
        <Typography
          component="b"
          sx={{
            fontWeight: 'bold',
            letterSpacing: 'normal',
            fontFamily: 'HK Grotesk',
          }}
          className="language-fieldname"
        >
          {labelName}
        </Typography>
        <Select
          displayEmpty
          labelId="demo-select-small"
          id="demo-select-small"
          IconComponent={iconComponent}
          required
          {...registerFunction()}
          defaultValue={defaultValue}
          onChange={onChange}
          renderValue={renderValue}
        >
          {data}
        </Select>
        <FormHelperText class="p-error">{errorMessage}</FormHelperText>
      </FormControl>
    </Grid>
  )
}

export default AddAssetInputField
