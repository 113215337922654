import React, { useEffect, useState, useRef } from 'react'
import Dialog from '@mui/material/Dialog'
import {
  MenuItem,
  DialogContent,
  CircularProgress,
  Typography,
  Grid,
  TextField,
  FormHelperText,
  FormControl,
} from '@mui/material'
import { Box, getValue } from '@mui/system'
import { useForm, useFieldArray, useWatch } from 'react-hook-form'
import { iconComponent } from 'Components/ReusableComponents.js/reuseMethods'
import { makeLogRequest } from 'utils/AuditLogs'
import { useSelector } from 'react-redux'
import { CsvToJson } from 'utils/csvToJson'
import { useNotificationContext } from 'utils/NotificationContext'
import RemoveFileDialog from 'Components/CreateInitiativesRefactored/RemoveFileDialog/RemoveFileDialog'
import 'pages/CreateInitiatives/CreateInitiative.css'
import './AddTextTemplates.css'
import { pushTag } from 'utils/gtmservice'
import { validateNotEmpty } from 'Components/ReusableComponents.js/reuseMethods'
import DeleteButton from 'Components/CreateInitiativesRefactored/DeleteButton/DeleteButton'
import AddMoreButton from 'Components/CreateInitiativesRefactored/AddMoreButton/AddMoreButton'
import AddTextTemplateInputField from 'Components/CreateInitiativesRefactored/AddTextTemplateInputField/AddTextTemplateInputField'
import TemplateDialogTitle from 'Components/CreateInitiativesRefactored/TemplateDialogTitle/TemplateDialogTitle'
import TemplateDialogCloseButton from 'Components/CreateInitiativesRefactored/TemplateDialogCloseButton/TemplateDialogCloseButton'
import TemplateDialogSubmitButton from 'Components/CreateInitiativesRefactored/TemplateDialogSubmitButton/TemplateDialogSubmitButton'
import UploadCsvFileButton from 'Components/CreateInitiativesRefactored/UploadCsvFileButton/UploadCsvFileButton'
import BackdropComponent from 'Components/CreateInitiativesRefactored/BackdropComponent/BackdropComponent'

const errorStyles = {
  color: '#D93025',
  position: 'absolute',
  top: '100%',
  fontWeight: 'bold',
}

const mainDialogStyle = {
  '& .MuiDialog-container': {
    '& .MuiDialog-paperScrollPaper': {
      maxWidth: '860px !important',
      width: '860px',
      borderRadius: '14px!important',
    },
  },
}

const mainFormDialogStyle = {
  '& .MuiInputBase-input': { width: '180px', height: '25px!important' },
  '& .MuiOutlinedInput-notchedOutline': {
    borderRadius: '14px',
    paddingRight: '-32px',
  },
  '& .MuiOutlinedInput-input': { paddingRight: '14px!important' },
}

const textFormDialog = {
  '& .MuiInputBase-input': { width: '180px', height: '25px' },
}

const textFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '14px',
    },
  },
}

const textInputProps = {
  minLength: 10,
  maxLength: 255,
  style: {
    height: '50px',
    width: '180px',
  },
}

const fullWidthStyle = { width: '100%' }

const hashTagTextFieldStyle = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: '14px',
      marginTop: '-0.6px',
    },
  },
}

const hashTagInputProps = {
  maxLength: 255,
  minLength: 10,
  style: {
    height: '50px',
    width: '180px',
  },
}

const buttonBoxContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  mb: 2,
  mt: 3.5,
  ml: 2,
}

const AddTextTemplates = ({
  handleCloseTextTemplateDialog,
  openTextTemplateDialog,
  setText,
  languages,
  editTextTemplateDetails,
  setTextFileName: setFileName,
  textFileName,
  setTextFileError: error,
  setTextFileIsLoading: setIsLoading,
  isTextFileLoading: isLoading,
  setCsvToJsonData,
  csvToJsonData,
  setManualInsetCount,
  manualInsetCount,
  setIsTextFileUpload: setIsFileUploaded,
}) => {
  const [editDetails, setEditDetails] = useState(editTextTemplateDetails)
  const [fileData, setFileData] = useState([])
  const [fileName, setLocalFileName] = useState(textFileName)
  const hiddenFileInput = useRef(null)
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const { showNotification } = useNotificationContext()
  const [removeDialogVisibleState, setRemoveDialogVisibleState] = useState(false)
  const [maxShownCount, setMaxShownCount] = useState(50)

  useEffect(() => {
    setEditDetails(editTextTemplateDetails)
    return () => {
      setIsLoading(false)
    }
  }, [editTextTemplateDetails])

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isDirty },
    setError,
    getValues,
    clearErrors,
  } = useForm({
    defaultValues: {
      test: editDetails && editDetails?.length > 0 ? editDetails : [{ text: '', hashtag: '', language: '' }],
    },
  })
  const { fields, append, insert, remove } = useFieldArray({
    control,
    name: 'test',
  })

  const textTemplatedValues = useWatch({
    name: 'test',
    control,
  })

  useEffect(() => {
    if (fileData && Object.values(textTemplatedValues[0]).every((item) => item === '')) {
      remove(0)
    }
  }, [fileData])

  function createData(language, text, hashtag) {
    return { language, text, hashtag }
  }

  const onAddTextTemplateSubmit = async (data) => {
    if (isLoading) {
      return
    }
    await postAuditLogs('add_text_template')
    const rows = data?.test?.map((value, i) => {
      return createData(value.language, value.text, value.hashtag)
    })
    if (fileData?.data) {
      setText([...rows, ...fileData.data?.slice(maxShownCount, fileData.data.length - 1)])
      if (fileName) {
        setIsFileUploaded(true)
        setCsvToJsonData(fileData?.data)
        setFileName(fileData?.name)
      }
    } else {
      setText(rows)
    }
    handleCloseTextTemplateDialog()
    if (!fileName) {
      setFileName('')
      setCsvToJsonData([])
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === ' ') {
      e.preventDefault()
    }
  }

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('add_text_template_popup', sqId, data)
  }

  useEffect(() => {
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'text_template_pop_up',
    })
  }, [])

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'text_template_pop_up',
      message: message,
    })
  }

  const onFileUpload = async (event) => {
    setIsLoading(true)
    const file = event.target.files[0]
    if (!file?.name?.includes('.csv')) {
      setIsLoading(false)
      event.target.value = null
      return showNotification('Error', 'Only Csv file upload is allowed')
    }
    if (file?.size / 1000 + 1 > 102400) {
      setIsLoading(false)
      event.target.value = null
      return showNotification('Error', 'File size should be less than 100MB')
    }
    let fileData = []
    try {
      fileData = await CsvToJson(file)
      if (!fileData.length) {
        setIsLoading(false)
        event.target.value = null
        return showNotification('Error', 'Uploaded CSV file has no content')
      }
    } catch (error) {
      setIsLoading(false)
      event.target.value = null
      return showNotification('Error', error)
    }
    if (fileData?.length) {
      append(fileData.slice(0, maxShownCount), { shouldFocus: false })
    }

    setIsLoading(false)
    setFileData({ name: file.name, data: fileData })
    setLocalFileName(file.name)
    event.target.value = null
  }

  const handleFileDeletion = async (value) => {
    if (value !== true) {
      setRemoveDialogVisibleState(false)
      return true
    }
    const totalTexts = (fileData?.data ? fileData.data?.length : csvToJsonData.length) + manualInsetCount - 1
    for (let i = totalTexts; i >= manualInsetCount; i--) {
      remove(i)
    }
    if (manualInsetCount === 0) {
      append({ text: '', hashtag: '', language: '' })
    }
    setLocalFileName('')
    setRemoveDialogVisibleState(false)
    setIsLoading(false)
  }

  const handleAddClick = async () => {
    await postAuditLogs('add_more')
    sendGALog('add_more')
    setManualInsetCount((prev) => parseInt(prev) + 1)
    insert(0, { text: '', hashtag: '', language: '' })
  }

  const handleFileState = (isDirty, state) => {
    handleCloseTextTemplateDialog(isDirty, state)
  }
  const checkTextField = (value, maxLength, minLength, register_name, fieldName) => {
    if (value.length == 0) {
      setError(`${register_name}`, {
        type: 'value',
        message: `Please enter ${fieldName}`,
      })
    } else if (value.length > 0 && value.length < minLength) {
      setError(`${register_name}`, {
        type: 'minLength',
        message: `Minimum character length is ${minLength}`,
      })
    } else if (value.length == maxLength) {
      setError(`${register_name}`, {
        type: 'maxLength',
        message: 'Maximum limit reached',
      })
    } else {
      clearErrors(`${register_name}`)
    }
  }

  const registerFunctionLanguage = (index) => {
    return register(`test.${index}.language`, {
      required: 'Please select language of the text',
    })
  }

  const handleHashTagPaste = (event) => {
    event.preventDefault()
    const text = event.clipboardData.getData('text/plain')
    const textWithoutSpaces = text.replace(/\s/g, '') // Remove spaces
    document.execCommand('insertText', false, textWithoutSpaces)
    event.target.value = textWithoutSpaces
  }

  const handleClose = () => {
    const data = getValues()
    let hasData = false

    for (let i = 0; i < data.test.length; i++) {
      const item = data.test[i]
      if (item.language || item.text || item.hashtag) {
        hasData = true
        break
      }
    }

    handleCloseTextTemplateDialog(hasData, true)
  }

  return (
    <Dialog onClose={() => handleClose()} open={openTextTemplateDialog} sx={mainDialogStyle}>
      <TemplateDialogTitle title={editDetails?.length > 0 ? 'Update Text Templates' : 'Add Text Templates'} />
      <TemplateDialogCloseButton
        onClick={async () => {
          await postAuditLogs('close')
          sendGALog('close')
          handleClose()
        }}
      />
      <Box className="templateDiv">
        <DialogContent className="templatedDialog">
          <Box>
            <form>
              {fields.map((item, index) => {
                return (
                  <Box className="box" key={`${item.id}_${index}`}>
                    <Box className="textcenterinbox">
                      <AddTextTemplateInputField
                        boxClassName={'scrollerTopMargin'}
                        textFieldText={'Language'}
                        formControlSx={mainFormDialogStyle}
                        errorMessage={
                          errors && errors?.test && errors?.test[index] && errors?.test[index].language?.message
                        }
                        registerFunction={() => registerFunctionLanguage(index)}
                        select={true}
                        name={`test[${index}].language`}
                        SelectProps={{ IconComponent: iconComponent }}
                        defaultValue={item?.language}
                        data={
                          languages &&
                          languages.map((s) => {
                            return (
                              <MenuItem key={s.id} sx={fullWidthStyle} value={s.language} size="small">
                                {s.language}
                              </MenuItem>
                            )
                          })
                        }
                        onChange={() => {
                          setError(`test.${index}.language`, null)
                        }}
                      />

                      <Box className="gapBetweenTitles">
                        <Typography component="b" className="titles">
                          Text
                        </Typography>
                        <FormControl sx={textFormDialog}>
                          <TextField
                            className="stepperFormInput"
                            fullWidth
                            placeholder="Enter text"
                            size="small"
                            multiline
                            rows={70}
                            required
                            sx={textFieldStyle}
                            inputProps={textInputProps}
                            {...register(`test.${index}.text`, {
                              required: 'Please enter the text',
                              maxLength: {
                                value: 255,
                                message: 'MAximum limit reached',
                              },
                              minLength: {
                                value: 10,
                                message: 'Minimum character length is 10',
                              },
                              validate: (value) => validateNotEmpty(value, 'the Text'),
                            })}
                            onChange={(event) => {
                              let inputValue = event?.target?.value?.trimStart()
                              inputValue = inputValue.replace(/\s{2,}/g, ' ')
                              checkTextField(inputValue, 255, 10, `test.${index}.text`, 'the text')
                              event.target.value = inputValue
                            }}
                            autoComplete="off"
                          />
                          <Grid container>
                            <Grid item xs={8}>
                              <FormHelperText sx={errorStyles} className="mgl-0">
                                {errors && errors?.test && errors?.test[index] && errors?.test[index].text?.message}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography variant="span" className="max-length-limit-size">
                                (Max length 255)
                              </Typography>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Box>

                      <Box>
                        <Typography component="b" className="titles">
                          Hashtag
                        </Typography>
                        <FormControl>
                          <TextField
                            className="stepperFormInput"
                            // label="Hashtag"
                            // name={"hashtag" + i}
                            fullWidth
                            // value={x.hashtag}
                            // placeholder="Enter text"
                            sx={hashTagTextFieldStyle}
                            onKeyDown={handleKeyDown}
                            inputProps={hashTagInputProps}
                            size="small"
                            required
                            {...register(`test.${index}.hashtag`, {
                              required: 'Please create a hashtag',
                              maxLength: {
                                value: 255,
                                message: 'Maximum limit reached',
                              },
                              minLength: {
                                value: 10,
                                message: 'Minimum character length is 10',
                              },
                            })}
                            onPaste={handleHashTagPaste}
                            onChange={(event) => {
                              checkTextField(event.target.value, 255, 10, `test.${index}.hashtag`, 'hashtag')
                            }}
                            multiline
                            rows={70}
                            autoComplete="off"
                          />
                          <Grid container>
                            <Grid item xs={8}>
                              <FormHelperText sx={errorStyles} className="mgl-0">
                                {errors && errors?.test && errors?.test[index] && errors?.test[index].hashtag?.message}
                              </FormHelperText>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography variant="span" className="max-length-limit-size">
                                (Max length 255)
                              </Typography>
                            </Grid>
                          </Grid>
                        </FormControl>
                      </Box>
                      <Box>
                        {fields.length !== 1 && (
                          <DeleteButton
                            onClick={async () => {
                              await postAuditLogs('delete')
                              remove(index)
                              if (index < manualInsetCount) {
                                setManualInsetCount((prev) => parseInt(prev) - 1)
                              }
                              if (fields.length - 1 <= manualInsetCount) {
                                handleFileDeletion(true)
                              }
                            }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Box>
                )
              })}
            </form>
          </Box>
        </DialogContent>
        <Box className="btn-box">
          <React.Fragment>
            <Box sx={buttonBoxContainer}>
              <AddMoreButton buttonTitle={'Add More'} onClick={handleAddClick} type={'text'} />
              <input
                type="file"
                accept=".csv"
                className="d-none"
                ref={hiddenFileInput}
                onChange={onFileUpload}
                required
              />
              <UploadCsvFileButton
                fileName={fileName}
                isLoading={isLoading}
                CircularProgress={CircularProgress}
                hiddenFileInput={hiddenFileInput}
                postAuditLogs={postAuditLogs}
                setRemoveDialogVisibleState={setRemoveDialogVisibleState}
              />
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </React.Fragment>
        </Box>
      </Box>
      <TemplateDialogSubmitButton
        onClick={handleSubmit(onAddTextTemplateSubmit)}
        isLoading={isLoading}
        buttonSx={{
          p: 1,
          mr: 1,
          mb: 2,
          backgroundColor: '#ef7335',
          borderRadius: 4,
          position: 'relative',
          width: '250px',
        }}
        dialogActionSx={{ justifyContent: 'center', mt: 5 }}
        CircularProgress={CircularProgress}
        onFocus={() => sendGALog(editDetails?.length > 0 ? 'update' : 'add_text_template')}
        title={editDetails?.length > 0 ? 'Update Text Template' : 'Add Text Template'}
      />
      {removeDialogVisibleState && (
        <RemoveFileDialog callback={handleFileDeletion} openRemoveDialog={removeDialogVisibleState} />
      )}
      {isLoading && <BackdropComponent isLoading={isLoading} />}
    </Dialog>
  )
}

export default AddTextTemplates
