import { VIDEO_FAILURE, VIDEO_REQUEST, VIDEO_SUCCESS } from './types';
import axiosInstance from '../../utils/api';

export const getVideoRequest = () => {
  return {
    type: VIDEO_REQUEST,
  };
};
export const getVideoSuccess = (value) => {
  return {
    type: VIDEO_SUCCESS,
    payload: value,
  };
};
export const getVideoFailure = (error) => {
  return {
    type: VIDEO_FAILURE,
    payload: error,
  };
};

const getVideo = (query) => async (dispatch) => {
  dispatch(getVideoRequest);
  await axiosInstance
    .get(`/api/videostemplate/getall/${query}`)
    .then((response) => {
      const result = response.data;
      // const result = mpList
      dispatch(getVideoSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(getVideoFailure(errorMsg));
    });
};

const updateVideo = (formData) => async (dispatch) => {
  await axiosInstance.post(`/api/user/update-mpdata/${formData.id}`, formData);
};

export { updateVideo, getVideo };
