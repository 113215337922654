import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { Box } from '@mui/system'
import './WarningPopUp.css'

const WarningPopUp = ({ openWarningDialog, handleCloseWarningDialog, onCancel }) => {
  return (
    <Dialog
      open={openWarningDialog}
      onClose={() => {
        handleCloseWarningDialog()
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{ maxWidth: '550px', margin: '0 auto' }}
    >
      <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
        <DialogContentText className="warning-dialog__text">
          Are you sure, you don't want to
          <br />
          Upload this Video
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', mt: 0 }}>
        <Button
          className="button-tr-2 mb-3 mt-0 warning-dialog__button"
          onClick={async () => {
            onCancel(false)
          }}
        >
          Yes
        </Button>
        <Button
          className="button-tr-citizen-admin mb-3 mt-0 warning-dialog__button"
          onClick={async () => {
            handleCloseWarningDialog()
          }}
        >
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WarningPopUp
