import { GET_STATE_FAILURE, GET_STATE_REQUEST, GET_STATE_SUCCESS } from './types';
import axiosInstance from '../../utils/api';

export const fetchStateListRequest = () => {
  return {
    type: GET_STATE_REQUEST,
  };
};
export const fetchStateListSuccess = (value) => {
  return {
    type: GET_STATE_SUCCESS,
    payload: value,
  };
};
export const fetchStateListFailure = (error) => {
  return {
    type: GET_STATE_FAILURE,
    payload: error,
  };
};

export const getStateList = () => async (dispatch) => {
  dispatch(fetchStateListRequest);
  await axiosInstance
    .get(`/api/states/getall/0`)
    .then((response) => {
      const result = response.data;
      dispatch(fetchStateListSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchStateListFailure(errorMsg));
    });
};
