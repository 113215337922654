import { Box, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'

const ViewAllScreenSubHeader = () => {
  const location = useLocation()
  const subHeader = location?.state?.viewAllValue
  return (
    <>
      {subHeader === 'Initiative Reports' && (
        <Box className="d-flex justify-content-between mt-4 pr-4">
          <Typography component="h3" className=" evnts-under-init cmn-text">
            {`${subHeader} by`}
          </Typography>
        </Box>
      )}
    </>
  )
}
export default ViewAllScreenSubHeader
