import { MenuItem } from '@mui/material'
function CardHeaderFilterOption({ Item, selectedFilter, handleFilter, menuItem }) {
  return (
    <>
      <MenuItem
        value={Item}
        selected={menuItem === selectedFilter ? true : false}
        onClick={() => handleFilter(menuItem)}
        sx={{
          '&.Mui-selected': {
            backgroundColor: selectedFilter === menuItem ? '#356F92 !important' : '',
            color: selectedFilter === menuItem ? '#fff !important' : '',
          },
        }}
      >
        {Item}
      </MenuItem>
    </>
  )
}
export default CardHeaderFilterOption
