import { INITIATIVE_LIST_REQUEST, INITIATIVE_LIST_FAILURE, INITIATIVE_LIST_SUCCESS } from '../action/types';

const initialState = {
  data: [],
  error: '',
};

export const getInitiativeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case INITIATIVE_LIST_REQUEST:
      return {
        state,
      };
    case INITIATIVE_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    case INITIATIVE_LIST_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
