import React, { useEffect, useState, useRef } from 'react'
import Marquee from 'react-fast-marquee'
import { getAlertAction } from '../../../store/action/NotificationAlerts/getNotificationAlerts'
import { useDispatch, useSelector } from 'react-redux'
import './NotificationList.css'
import { Typography, Box } from '@mui/material'
import { Close } from '@mui/icons-material'
import InfoAlertIcon from '../../../asserts/images/InfoAlert.svg'
import WarningAlertIcon from '../../../asserts/images/Warning.svg'
import ErrorAlert from '../../../asserts/images/ErrorAlert.svg'
import { getRoles } from '../getIds'
import { browserName, browserVersion } from 'react-device-detect'
import convertToHtml from '../../ReusableComponents.js/ConvertToHtml'

const NotificationsList = ({ setIsMarqueeVisible, isMarqueeVisible }) => {
  const closeMarquee = () => {
    setIsMarqueeVisible(false)
    setIsMarqueeClosed(true)
  }

  const getAlertIcon = (alertType) => {
    switch (alertType) {
      case 'information':
        return InfoAlertIcon
      case 'error':
        return ErrorAlert
      default:
        return WarningAlertIcon
    }
  }

  const dispatch = useDispatch()
  const getNotificationAlerts = useSelector((state) => state?.getNotificationAlerts?.data)

  const [currentAlertIndex, setCurrentAlertIndex] = useState(0)
  const [isBrowserSupported, setIsBrowserSupported] = useState(true)
  const [browserAlertItem, setBrowserAlertItem] = useState('')
  const [notificationsList, setNotificationsList] = useState([])
  const [isMarqueeClosed, setIsMarqueeClosed] = useState(false)
  const [padding, setPadding] = useState(750)
  const elementRef = useRef(null)
  const userId = getRoles()

  useEffect(() => {
    if (userId != 2) {
      dispatch(getAlertAction(true))
    }
  }, [])

  useEffect(() => {
    let msg = ''
    const isSupportedBrowser = () => {
      switch (browserName) {
        case 'Chrome':
          if (browserVersion < 92) msg = `For best experience please upgrade to the latest version of ${browserName}`
          break
        case 'Firefox':
          if (browserVersion < 115) msg = `For best experience please upgrade to the latest version of ${browserName}`
          break
        case 'Edge':
          if (browserVersion < 93) msg = `For best experience please upgrade to the latest version of ${browserName}`
          break
        case 'Safari':
          msg = 'We recommend using the latest version of Chrome browser for optimal app experience!'
          break

        default:
          msg = 'Mera Saansad is best supported on Google Chrome/ V92.04515.131 & above'
          break
      }
      if (msg) {
        setBrowserAlertItem({
          id: 'browserWarning',
          desc: msg,
          alertType: 'warning',
        })
        setIsBrowserSupported(false)
      }
    }
    if (browserName) isSupportedBrowser()
  }, [browserName, browserVersion])

  useEffect(() => {
    const filteredNotificationsList = getNotificationAlerts.filter((item) => item.desc !== null)
    if (!isBrowserSupported && userId != 2) {
      setNotificationsList([browserAlertItem, ...filteredNotificationsList])
    } else if (userId != 2) {
      setNotificationsList(filteredNotificationsList)
    } else if (!isBrowserSupported && userId == 2) {
      setNotificationsList([browserAlertItem])
    } else {
      setNotificationsList([])
    }

    if (elementRef.current) {
      setPadding(elementRef.current.clientWidth)
    }
  }, [getNotificationAlerts, isBrowserSupported])

  useEffect(() => {
    if (notificationsList?.length > 0) {
      setIsMarqueeVisible(true)
    } else {
      setIsMarqueeVisible(false)
    }
  }, [notificationsList])

  const handleCycleComplete = () => {
    setCurrentAlertIndex((prevIndex) => {
      if (prevIndex < notificationsList.length - 1) {
        return prevIndex + 1
      } else {
        return 0
      }
    })
  }

  return (
    <>
      {notificationsList !== null && notificationsList.length > 0 && isMarqueeVisible === true && (
        <Box
          className={`notification-container-${notificationsList[currentAlertIndex]?.alertType} br-20-gap-1 d-flex p-3 align-items-center mb-3`}
          ref={elementRef}
        >
          <img src={getAlertIcon(notificationsList[currentAlertIndex]?.alertType)} alt="Alert" />
          <Box className={`h-3r vertical-line-${notificationsList[currentAlertIndex]?.alertType}`} />
          {notificationsList !== null && notificationsList.length > 0 ? (
            <Marquee
              key={currentAlertIndex}
              pauseOnHover
              delay={1}
              className="mb-1 marquee"
              direction="left"
              autoFill={false}
              onCycleComplete={handleCycleComplete}
            >
              {convertToHtml(notificationsList[currentAlertIndex]?.desc, padding, currentAlertIndex)}
            </Marquee>
          ) : null}
          <Close onClick={closeMarquee} sx={{ color: '#000', fontSize: '1.2rem', cursor: 'pointer' }} />
        </Box>
      )}
    </>
  )
}

export default NotificationsList
