import { Grid, FormControl, Typography, TextField, InputLabel, FormHelperText } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { useForm } from 'react-hook-form'

const CreateInitiativeSelectDateInputField = ({
  errors,
  id,
  editDetails,
  targetedDate,
  setTargetDate,
  registerFunctionForTargetDate,
  watch,
  resetField,
  setError,
  register,
}) => {
  const validateDate = (value, fieldName, useValue) => {
    const parts = value?.split('/')
    if (parts?.length === 3) {
      const year = parts[2]
      if (year > 2099) {
        return 'Please enter a valid date'
      }
    }
    const regex = new RegExp('^[a-zA-Z][a-zA-Z]*')
    const inputDate = new Date(useValue ? formatDate(value) : targetedDate)
    const currentDate = new Date()
    if (inputDate <= currentDate || regex.test(targetedDate)) {
      return 'Please enter a valid date'
    }
    return null
  }

  const formatDate = (value) => {
    let splittedDate = value.split('/')
    return `${splittedDate[2]}-${splittedDate[1]}-${splittedDate[0]}`
  }

  const dateConvert = (dateStr) => {
    if (dateStr === null) return ''
    const date = new Date(dateStr)
    let month = date.getMonth() + 1 // getMonth() returns 0-indexed month, so add 1
    let day = date.getDate()
    let year = date.getFullYear()
    const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
    return formattedDate
  }

  return (
    <Grid item xs={10} md={10} lg={10} xl={10} sx={{ marginTop: '30px', marginLeft: '35px' }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <InputLabel shrink htmlFor="bootstrap-input" sx={{ color: '#2e739c', marginBottom: '0px' }}>
          <Typography className="headincreateini initiative-titles-st" sx={{ lineHeight: '1.2' }}>
            Target Date
          </Typography>
        </InputLabel>
        <DatePicker
          components={{
            OpenPickerIcon: CalendarMonthIcon,
          }}
          InputProps={{
            disableUnderline: true,
            sx: {
              '& .MuiButtonBase-root': {
                left: '33px',
              },
              '& .MuiSvgIcon-root': {
                color: '#fff',
                background: '#356f92',
                width: '30px',
                height: '30px',
                padding: '5px',
                borderRadius: '5px',
              },
            },
          }}
          {...registerFunctionForTargetDate()}
          defaultValue={id ? editDetails?.tagetDate : ''}
          inputFormat="DD/MM/YYYY"
          value={targetedDate}
          minDate={new Date(Date.now() + 86400000)}
          className="cls-input-sty-2"
          sx={{
            fontFamily: 'HK Grotesk',
            backgroundColor: 'red',
          }}
          onChange={(newValue) => {
            setTargetDate(dateConvert(newValue))
            resetField('targetDate')
          }}
          renderInput={(params) => (
            <FormControl
              sx={{
                fontFamily: 'HK Grotesk',
                backgroundColor: '#fcfcfb',
                width: '182px',
              }}
            >
              <TextField
                variant="standard"
                value={watch('targetDate')}
                sx={{
                  '& .MuiInputBase-input': {
                    width: '100px',
                    marginTop: '5px',
                    marginLeft: '10px',
                  },
                }}
                {...register('targetDate', {
                  required: !targetedDate ? 'Please choose a target date' : false,
                  validate: (value) => validateDate(value, 'targetDate'),
                })}
                tooltip={false}
                {...params}
                onKeyUp={(e) => {
                  if (e.target.value?.length === 10) {
                    let validationResult = validateDate(e.target.value, 'targetDate', true)
                    let error =
                      validationResult === null
                        ? null
                        : {
                            type: 'validate',
                            message: validationResult,
                          }
                    setError('targetDate', error)
                  }
                }}
                error={Boolean(errors?.targetDate?.message)}
              />
            </FormControl>
          )}
        />
        <FormHelperText sx={{ color: '#d32f2f', fontWeight: '700' }}>
          {errors && errors?.targetDate?.message}
        </FormHelperText>
      </LocalizationProvider>
    </Grid>
  )
}

export default CreateInitiativeSelectDateInputField
