import React, { useState, useEffect, createRef } from 'react'
import Dialog from '@mui/material/Dialog'
import unknownImg from '../../../asserts/images/folders.png'
import './AddVideoTemplate.css'

import { Grid, MenuItem, Typography, styled, Tooltip, tooltipClasses } from '@mui/material'
import { Box } from '@mui/system'
import { useForm } from 'react-hook-form'
import UploadVideos from '../UploadVideoDialog/UploadVideoDialog'
import { useNotificationContext } from '../../../utils/NotificationContext'
import { useSelector } from 'react-redux'
import { makeLogRequest } from '../../../utils/AuditLogs'
import zipIcon from '../../../asserts/images/ZipIcon.svg'
import '../AddImageTemplate/ImageTemplate.css'
import { pushTag } from '../../../utils/gtmservice'
import TemplateDialogTitle from '../TemplateDialogTitle/TemplateDialogTitle'
import TemplateDialogCloseButton from '../TemplateDialogCloseButton/TemplateDialogCloseButton'
import TemplateDialogSubmitButton from '../TemplateDialogSubmitButton/TemplateDialogSubmitButton'
import AddMoreButton from '../AddMoreButton/AddMoreButton'
import UploadFileComponent from '../UploadFileComponent/UploadFileComponent'
import AddAssetInputField from '../AddAssetInputField/AddAssetInputField'
import UploadedFileComponent from '../UploadedFileComponent/UploadedFileComponent'
import { useLoading } from '../../../utils/LoadingContext'
export const BootstrapTooltipInfo = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #356F92',
    },
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #356F92',
    boxShadow: theme.shadows[1],
    width: 240,
    maxWidth: '370px',
  },
}))

const AddVideoTemplates = ({
  handleCloseVideosTemplateDialog,
  openVideoTemplateDialog,
  setVideoData,
  languages,
  editDetails,
  setUpdateVideoValue,
}) => {
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    setError,
  } = useForm()
  const zipFormat = ['application/x-zip-compressed', 'application/x-zip', 'zip']
  const mtsFormat = ['video/vnd.dlna.mpeg-tts', 'video/mp2t']
  const fileFormats = [
    'video/mp4',
    'video/quicktime',
    'video/mov',
    'video/x-ms-wmv',
    'video/x-ms-asf',
    'video/webm',
    'video/avi',
    'video/x-msvideo',
    'video/x-flv',
    'video/x-f4v',
    'video/x-matroska',
    'video/MP2T',
    'video/vnd.dlna.mpeg-tts',
    'video/mp2t',
    'application/metastream',
    'video/avchd-stream',
    'video/mts',
    'application/zip',
    '.flv',
    '.webm',
    '.wmv',
    '.zip',
    '.rar',
    '.7z',
    '.gz',
    '.mts',
    '.avi',
    '.mkv',
    '.mp4',
    '.mov',
    'application/x-zip-compressed',
  ]
  const { showNotification } = useNotificationContext()

  const [videoRefs, setVideoRefs] = useState([])
  const { setLoading } = useLoading()
  const [videos, setVideos] = useState([])
  const [videoFiles, setVideoFiles] = useState([])
  const [currentImageIndex, setCurrentImageIndex] = useState(-1)
  const [checkWarningPopup, setCheckWarningPopup] = useState(false)

  const [dialogState, setDialogState] = useState({
    open: false,
    for: undefined,
  })
  const [inputState, setInputState] = useState([
    {
      id: Date.now(),
      language: true,
      upload: true,
      remove: true,
    },
  ])
  const userProfile = useSelector((state) => state?.userProfile?.data)

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('add_video_template_popup', sqId, data)
  }

  const countExistingZips = () => {
    let zipCount = 0
    videos.forEach((file) => {
      if (file.type === 'zip') {
        zipCount++
      }
    })
    return zipCount
  }

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'add_video_template_pop_up',
      message: message,
    })
  }

  useEffect(() => {
    setVideoRefs(inputState.map(() => createRef()))
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'add_video_template_pop_up',
    })
  }, [])

  useEffect(() => {
    if (editDetails) {
      const temp = []

      editDetails?.forEach((template, i) => {
        temp.push({
          id: i,
          language: true,
          languageValue: template.language,
          upload: true,
          remove: true,
          videosData: typeof template.video === 'string' ? JSON.parse(template.video) : template.video,
        })
        !getValues(`${i}`)?.length > 0 && setValue(`${i}.language`, template.language)
        !getValues(`${i}`)?.length > 0 &&
          setValue(`${i}.videos`, typeof template.video === 'string' ? JSON.parse(template.video) : template.video)
      })
      setInputState(temp)
      setVideoRefs(temp.map(() => createRef()))
    }
  }, [editDetails])

  const removeInput = (id) => {
    const tempData = [...inputState]
    const newData = tempData.filter((input) => input.id !== id)
    const index = inputState.findIndex((data) => data.id === id)
    const newRefs = videoRefs.filter((_, i) => tempData[i].id !== id)
    unregister(index.toString())
    setInputState(newData)
    setVideoRefs(newRefs)
    const newFormData = {}
    const formData = getValues()
    const formDataValues = Object.values(formData)
    formDataValues.forEach((item, ind) => {
      newFormData[ind] = item
    })
    reset(newFormData)
  }

  const addInput = () => {
    const newInput = {
      id: inputState[inputState?.length - 1]?.id + 1,
      language: true,
      upload: true,
      remove: true,
    }
    setInputState((prevState) => [...prevState, newInput])
    setVideoRefs((prevRefs) => [...prevRefs, createRef()])
  }
  const isUploadVideoEnabledOrNot = (i) => {
    const language = getValues(`${i}.language`)
    let isDisabled = false
    isDisabled = Boolean(language)
    return isDisabled
  }
  const handleClick = (e, i) => {
    setVideos([])
    if (isUploadVideoEnabledOrNot(i)) {
      videoRefs[i].current?.click()
    }
    e?.stopPropagation()
  }
  const handleVideoChange = async (e, i) => {
    if (errors[i]) {
      errors[i].videos = null
    }
    let remainingZipCount = 5 - countExistingZips()
    const uploadedFiles = e.target.files
    if (uploadedFiles.length > 0) {
      setCheckWarningPopup(true)
    }
    let newVideos = []
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i]
      let fileType = file.type === '' ? '.' + file.name?.split('.').pop() : file.type
      const isRightFormat = fileFormats.includes(fileType)
      const fileSizeInBytes = file.size
      const maxSizeInBytes = 1024 * 1024 * 1024 // 1GB
      if (!isRightFormat) {
        showNotification('Error', 'You can only upload mp4 videos', 'error')
        return
      }
      if (fileSizeInBytes > maxSizeInBytes) {
        setLoading(false)
        showNotification('Error', 'Please upload video with size less than 1GB', 'error')
        return
      }
      if (file.type === 'application/x-zip-compressed' && remainingZipCount > 0) {
        remainingZipCount--
      } else if (file.type === 'application/x-zip-compressed' && remainingZipCount <= 0) {
        // Reject the upload and display an error message
        showNotification('Error', 'Only a maximum of 5 zip files are allowed in total.', 'error')
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      const filePromise = new Promise((resolve, reject) => {
        setLoading(true)
        reader.onload = () => {
          newVideos.push({
            type: zipFormat.includes(file.type) ? 'zip' : mtsFormat.includes(file.type) ? 'mts' : 'video',

            url: zipFormat.includes(file.type) ? zipIcon : fileFormats.includes(file.type) ? reader.result : unknownImg,
            file: file,
            id: i,
          })
          // if (i === uploadedFiles.length - 1) {
          //   if (!isRightFormat) {
          //     showNotification('Error', 'You can only upload mp4 videos', 'error')
          //     return
          //   }
          // }
          resolve()
        }
        reader.onerror = () => {
          reject(reader.error)
        }
      })
      try {
        await filePromise
        if (i === uploadedFiles.length - 1) {
          setVideoFiles([...videoFiles, ...newVideos])
          await setVideos([...newVideos])
          handleOpenUploadDialog(`${currentImageIndex}`, true, newVideos)
        }
      } catch (error) {
        console.error('An error occurred:', error)
      } finally {
        setLoading(false)
      }
    }
  }
  const handleOpenUploadDialog = (id, edit = false, videos = []) => {
    if (!edit) {
      setVideos(getValues(id))
      setDialogState({ open: true, for: id })
    } else {
      setVideos(videos)
      setDialogState({ open: true, videos, for: id })
    }
  }
  const handleCloseUploadDialog = () => setDialogState({ open: false, for: undefined })

  const onSubmit = async (data) => {
    await postAuditLogs('add_video_template')
    const objectArray = Object.values(data)
    await setVideoData([])
    await setVideoData([objectArray])
    await setUpdateVideoValue(true)
    handleCloseVideosTemplateDialog()
  }

  const handleDelete = (i) => {
    setCheckWarningPopup(true)
    const tempVideos = [...videos]
    tempVideos.splice(0, 1)
    setVideos(tempVideos)
    const tempVideoFiles = [...videoFiles]
    tempVideoFiles.splice(0, 1)
    setVideoFiles(tempVideoFiles)
    // setVideoData(tempVideoFiles)
    if (inputState?.length > 0) {
      let updatedInputState = [...inputState] // create a copy of inputState
      updatedInputState[i] = {
        // update the object at index i
        ...updatedInputState[i], // spread the current object's properties
        videosData: inputState[i]?.videosData.slice(1), // create a new array without the first element
      }
      setInputState(updatedInputState)
      let val = getValues(`${i}`)

      delete val.videos[0]
      val.videos.splice(0, 1)
      setValue(`${i}.videos`, val.videos)
      setVideos(val.videos)
    } else {
      let val = getValues(`${i}`)

      delete val.videos[0]
      val.videos.splice(0, 1)
      setValue(`${i}.videos`, val.videos)
    }
  }

  const registerFunction = (i) => {
    return register(`${i}.videos`, {
      required: 'Please add Videos',
    })
  }

  const registerFunctionForLanguage = (i) => {
    return register(`${i}.language`, {
      required: 'Please select Language',
    })
  }

  return (
    <Dialog
      onClose={() => handleCloseVideosTemplateDialog(checkWarningPopup)}
      open={openVideoTemplateDialog}
      sx={{ height: '90vh', marginTop: '25px' }}
    >
      <TemplateDialogTitle title={editDetails?.length > 0 ? 'Update Video Templates' : 'Add Video Templates'} />
      <TemplateDialogCloseButton
        onClick={async () => {
          await postAuditLogs('close')
          sendGALog('close')
          handleCloseVideosTemplateDialog(checkWarningPopup, true)
        }}
      />
      <Box sx={{ m: 3, overflowX: 'hidden' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {inputState.map((input, i) => {
            return (
              <Box className="box" key={input.id}>
                <Grid container>
                  <Grid item xs={6} md={6} lg={6} xl={6}>
                    {input.language && (
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <AddAssetInputField
                          labelName={'Language'}
                          defaultValue={input?.languageValue}
                          data={
                            languages &&
                            languages.map((s) => {
                              return (
                                <MenuItem native key={s.id} sx={{ width: '100%' }} value={s.language} size="small">
                                  {s.language}
                                </MenuItem>
                              )
                            })
                          }
                          errorMessage={errors && errors[i] && errors[i].language?.message}
                          registerFunction={() => registerFunctionForLanguage(i)}
                          onChange={() => {
                            setCheckWarningPopup(true)
                            setError(`${i}.language`, null)
                          }}
                          renderValue={(selected) => {
                            if (selected == null || selected?.length === 0) {
                              return (
                                <Typography className="vid-lang-placeholder">Select Language of the video</Typography>
                              )
                            } else {
                              return <Typography>{selected}</Typography>
                            }
                          }}
                        />
                      </Grid>
                    )}
                    {input.remove && inputState.length !== 1 && (
                      <Grid item xs={3} sx={{ mt: 1, marginLeft: '-15px' }}>
                        <AddMoreButton
                          buttonTitle={'Remove'}
                          onClick={() => {
                            removeInput(input.id)
                            setCheckWarningPopup(true)
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                  {!input?.videosData?.[0] ? (
                    <UploadFileComponent
                      ComponentTitle={'Video Preview'}
                      input={input}
                      errors={errors}
                      i={i}
                      fileFormats={fileFormats}
                      assetRef={videoRefs[i]}
                      onClick={async (e) => {
                        handleClick(e, i)
                        setCurrentImageIndex(i)
                        await postAuditLogs('add_video')
                      }}
                      onChange={(e) => {
                        handleVideoChange(e, i)
                        setError(`${i}.videos`, null)
                      }}
                      registerFunction={() => registerFunction(i)}
                    />
                  ) : (
                    <UploadedFileComponent
                      i={i}
                      input={input}
                      ComponentTitle={'Video Preview'}
                      iconButtonOnClick={async (e) => {
                        handleClick(e, i)
                        setCurrentImageIndex(i)
                        await postAuditLogs('add_video')
                      }}
                      registerFunction={() => registerFunction(i)}
                      fileFormats={fileFormats}
                      assetRef={videoRefs[i]}
                      inputOnChange={(e) => handleVideoChange(e, i)}
                      inputOnClick={(event) => {
                        event.target.value = null
                      }}
                      boxClassName={'add-video-button'}
                      handleOpenUploadDialog={handleOpenUploadDialog}
                      errors={errors}
                      handleDelete={handleDelete}
                    />
                  )}
                  <hr className="hr-line"></hr>
                </Grid>
              </Box>
            )
          })}
        </form>

        <Box className="btn-box">
          <AddMoreButton
            boxSx={{
              display: 'flex',
              flexDirection: 'row',
              mb: 2,
              mt: 2,
              marginLeft: '-15px',
            }}
            onClick={async () => {
              await postAuditLogs('add_row')
              sendGALog('add_row')
              addInput()
            }}
            buttonTitle={'Add More'}
          />
        </Box>
      </Box>
      <TemplateDialogSubmitButton
        buttonSx={{
          p: 1,
          mr: 1,
          backgroundColor: '#ef7335',
          borderRadius: 4,
          width: '250px',
        }}
        dialogActionSx={{ justifyContent: 'center', mb: 2 }}
        onClick={handleSubmit(onSubmit)}
        onFocus={() => sendGALog(editDetails?.length > 0 ? 'update' : 'save')}
        title={editDetails?.length > 0 ? 'Update Video Template' : 'Add Video Template'}
      />
      {dialogState?.open && (
        <UploadVideos
          handleCloseUploadDialog={handleCloseUploadDialog}
          state={dialogState}
          setValue={setValue}
          initialVideos={dialogState.for === 'edit' ? dialogState.videos : videos}
          setVideosNew={setVideos}
          setInputState={setInputState}
          fileFormats={fileFormats}
          setCheckWarningPopup={setCheckWarningPopup}
          videoRefs={videoRefs}
        />
      )}
    </Dialog>
  )
}

export default AddVideoTemplates
