import { Card, CardContent, Typography, CardMedia, Dialog, DialogContent, DialogActions, Button } from '@mui/material'
import './DevelopementProjectCarousel.css'
import { Box } from '@mui/system'
import PinProjectDialog from './PinProjectDialog/PinProjectDialog'
import { useState } from 'react'
import PinIcond from 'asserts/images/pindevelopement.svg'
import { useNotificationContext } from 'utils/NotificationContext'
import { getDevelopementAcitivites } from 'store/action/pinActivity'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import ProjectStatus from 'Components/ReusableComponents.js/ProjectStatus'
import { ImageLoader } from 'Components/ReusableComponents.js/ImageLoader'
import axiosInstance from 'utils/api'

const DevelopementProjectCarousel = ({ setPinnedCount, pinnedCount, mpIdLists, setMpIdLists, sortBy }) => {
  const [open, setOpen] = useState(false)
  const [activityId, setActivityId] = useState('')
  const { showNotification } = useNotificationContext()
  const [data, setdata] = useState('')
  const activity = useSelector((state) => state?.activity?.data)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getDevelopementAcitivites())
  }, [])

  const [pinnedCards, setPinnedCards] = useState([])
  const [selectedCardIndex, setSelectedCardIndex] = useState(null)
  const handlPinclick = async (dataVal) => {
    if (dataVal?.pinned) {
      let endPoint = `api/admin/pin-dev-projects/${dataVal?.id}?action=unpin`
      await axiosInstance
        .get(endPoint)
        .then((response) => {
          const result = response.data
        })
        .catch((error) => {
          const errorMsg = error.message
        })
      dispatch(getDevelopementAcitivites(mpIdLists, sortBy))
    }
  }
  const handleCardClick = (dataVal) => {
    if (dataVal?.pinned) {
      return
    } else {
      setOpen(true)
      setActivityId(dataVal?.id)
    }
  }

  const handleDialogClose = (confirmed) => {
    setOpen(false)
    if (pinnedCards.length === 10) {
      console.log(pinnedCards)
      showNotification('Error', '10 activities are already pinned', 'error')
      return
    }
    if (confirmed && selectedCardIndex !== null) {
      setPinnedCards([...pinnedCards, selectedCardIndex])
      setPinnedCount(pinnedCards.length + 1)

      showNotification('Success', 'Activity has been pinned sucessfully', 'success')
    }

    setSelectedCardIndex(null)
  }

  return (
    <Box className="carousel-container">
      {
        // Using forEach method
        // console.log(activity?.developments)
      }
      {/* {activity?.developments?.map(
          (dataVal, index) =>
            pinnedCards.includes(index) && (
              <Card
                key={index}
                sx={{
                  width: 250,
                  height: 145,
                  mr: 3,
                  borderRadius: '15px',
                  mt: 4,
                  position: 'relative',
                  overflow: 'hidden',
                }}
                onClick={() => handleCardClick(index)}
              >
                <ImageLoader
                  src={

                    dataVal?.media && JSON.parse(dataVal?.media)[0]
                  }
                  sx={{
                    borderRadius: '15px',
                    background:
                      'transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%) 0% 0% no-repeat padding-box',
                  }}
                  loading="lazy"
                  height="150"
                  width="240"
                  id={index}
                  className="blackoverlay sava-update--card-img__wrapper"
                />

                <Box className="dibgover mgdiv"></Box>
                <Box className="ecclipseonwidthnewrow1 title-text-st">
                  <Box className="para-wrapper">
                    <Typography
                      component="body1"
                      sx={{ lineHeight: '1.5' }}
                      className={`ecclipseonwidthnewrow`}
                    >
                      {' '}

                      {dataVal?.projecttitle}
                    </Typography>

                  </Box>
                  <Typography component="span" className="sp-st" sx={{ lineHeight: '1.5' }}>

                    <ProjectStatus status={dataVal?.status} colorSetter={'light'} />

                  </Typography>
                </Box>
                {dataVal?.pinned(
                  <>
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                      }}
                    >
                      <img src={PinIcond} alt="Pin Icon" style={{ width: '40px', height: '40px' }} />
                    </Box>

                  </>
                )}


              </Card>
            )
        )} */}
      {activity?.developments?.map((dataVal, index) => (
        <Card
          key={index}
          sx={{
            width: 240,
            height: 150,
            mr: 3,
            borderRadius: '15px',
            mt: 4,
            position: 'relative',
            overflow: 'hidden',
          }}
          onClick={() => handleCardClick(dataVal)}
        >
          <ImageLoader
            src={dataVal?.media && JSON.parse(dataVal?.media)[0]}
            sx={{
              borderRadius: '15px',
              background: 'transparent linear-gradient(180deg, #FFFFFF00 0%, #000000 100%) 0% 0% no-repeat padding-box',
            }}
            loading="lazy"
            height="150"
            width="240"
            id={index}
            className="blackoverlay sava-update--card-img__wrapper"
          />

          <Box className="dibgover mgdiv"></Box>
          <Box className="ecclipseonwidthnewrow1 title-text-st">
            <Box className="para-wrapper">
              <Typography component="body1" sx={{ lineHeight: '1.5' }} className={`ecclipseonwidthnewrow`}>
                {' '}
                {dataVal?.projecttitle}
              </Typography>
            </Box>
            <Typography component="span" className="sp-st" sx={{ lineHeight: '1.5' }}>
              <ProjectStatus status={dataVal?.status} colorSetter={'light'} />
            </Typography>
          </Box>

          {dataVal?.pinned && (
            <>
              <Box
                sx={{
                  position: 'absolute',
                  top: '0px',
                  right: '-7px',
                }}
              >
                <img
                  src={PinIcond}
                  alt="Pin Icon"
                  style={{ width: '55px', height: '55px' }}
                  onClick={() => handlPinclick(dataVal)}
                />
              </Box>
            </>
          )}
        </Card>
      ))}

      {open && (
        <PinProjectDialog
          setOpen={setOpen}
          open={open}
          activityId={activityId}
          mpIdLists={mpIdLists}
          pinnedCount={pinnedCount}
          setPinnedCount={setPinnedCount}
          sortBy={sortBy}
        />
      )}
    </Box>
  )
}

export default DevelopementProjectCarousel
