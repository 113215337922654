import { CREATE_ALERT_REQUEST, CREATE_ALERT_SUCCESS, CREATE_ALERT_FAILURE } from '../../action/types'

const initialState = {
  data: [],
  error: '',
}

export const createNotificationAlertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ALERT_REQUEST:
      return {
        ...state,
      }
    case CREATE_ALERT_SUCCESS:
      return {
        data: action.payload,
      }
    case CREATE_ALERT_FAILURE:
      return {
        error: action.payoad,
      }
    default:
      return state
  }
}
