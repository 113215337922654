import { Dialog, DialogActions, DialogContent } from '@mui/material'
import { Box } from '@mui/system'
import PinButtons from 'Components/PinnedActivityContainer/DevelopementProjectCarousel/PinProjectDialog/PinButtons/PinButtons'
import './PinProjectDialog.css'
import { useSelector } from 'react-redux'
const PinProjectDialog = ({
  open,
  setOpen,
  setPin,
  setYesClick,
  activityId,
  mpIdLists,
  pinnedCount,
  setPinnedCount,
  sortBy,
}) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            height: '30vh',
            width: '30vw',
            overflowY: 'auto',
          },
        }}
      >
        <DialogContent
          sx={{
            textAlign: 'center',
            mt: '4vh',
            fontSize: '3vh',
            padding: '1vh',
            letterSpacing: '0.1rem',
            color: '#6C6C6C',
            fontFamily: 'HK Grotesk medium',
          }}
        >
          Are you sure want <br />
          to Pin this Activity ?
        </DialogContent>
        <DialogActions className="button-layout">
          <Box class="PinButtons">
            <PinButtons
              title={'yes'}
              setOpen={setOpen}
              setPin={setPin}
              setYesClick={setYesClick}
              activityId={activityId}
              mpIdLists={mpIdLists}
              pinnedCount={pinnedCount}
              setPinnedCount={setPinnedCount}
              sortBy={sortBy}
            />
            <PinButtons title={'cancel'} setOpen={setOpen} activityId={activityId} />
          </Box>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default PinProjectDialog
