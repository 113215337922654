import { combineReducers } from 'redux'
import { HighlightsReducer } from './reducer/globalHighlight'
import { MpListReducer } from './reducer/mpList'
import { EventsListReducer, EventsListByMpIdReducer } from './reducer/eventsList'
import {
  MediaCoverageListReducer,
  MediaCoverageTypesListReducer,
  MediaCoverageListByMpIdReducer,
  OpedsAndBooksListByMpIdReducer,
  OpedsAndBooksListReducer,
  OnlyMediaCoverageListByMpIdReducer,
  OnlyMediaCoverageListReducer,
} from './reducer/mediaCoverageList'
import {
  DevelopmentProjectListReducer,
  DevelopmentStatusListReducer,
  DevelopmentProjectListByMpIdReducer,
} from './reducer/developmentProjectList'
import { createEventReducer } from './reducer/createNewEvent'
import { MpSearchReducer } from './reducer/mpSearch'
import { getMpProfileReducer } from './reducer/individualMP'
import { getAllEvents } from './reducer/getEvents'
import { getAssignMpReducer } from './reducer/assignMplist'
import { createOpedsReducer } from './reducer/createNewOpeds'
import { createDevelopmentProjectReducer } from './reducer/createDevelopmentProject'
import { getInitiativeListReducer } from './reducer/initiativeList'
import { getAllStatesReducer } from './reducer/stateList'
import {
  OngoingSevaInitiativesListReducer,
  OngoingSevaInitiativesListWIthInitiativeIdReducer,
  OngoingSevaInitiativesListByMpIdReducer,
  OngoingSevaInitiativesReportsByIdReducer,
  OngoingSevaInitiativesReportsByInitiativeIdAndMpIdReducer,
  SocialMediaInitiativeByIdReducer,
  getIntiativesReportByInitiativeIdReducer,
  getIntiativesEventByInitiativeIdReducer,
} from './reducer/ongoingSevaInitiativesList'
import { RanksCountReducer } from './reducer/getRanksCount'
import { createInitiativeReportBy } from './reducer/createInitiativeReportBy'

import { allInitiatives } from './reducer/allInitiatives'
import { initiativesOverview } from './reducer/initiativesOverview'
import { searchInitiative } from './reducer/searchInitiative'
import { getText } from './reducer/text'
import { getImage } from './reducer/image'
import { getVideo } from './reducer/video'
import { LanguageListReducer } from './reducer/languageList'
import { socialMediaKit } from './reducer/socialMediaKit'
import { getMostViewedTweetsReducer } from './reducer/mostViewedTweets'
import { getMostRetweetedTweetsReducer } from './reducer/mostRetweetedTweets'
import { getloginReducer } from './reducer/userLogin'
import { getTwitterMostNewFollowersReducer } from './reducer/twitterMostNewFollowers'
import { getUserProfileReducer } from './reducer/userProfile'
import { uploadMediaFiles } from './reducer/uploadMedia'
import { searchSevaUpdates } from './reducer/searchSevaUpdates'
import { searchSevaInitiatives } from './reducer/searchSevaInitiatives'
import { MasterListReducer } from './reducer/masterList'

import { createNotificationAlertsReducer } from './reducer/NotificationAlerts/createNotificationAlerts'
import { getNotificationAlertsReducer } from './reducer/NotificationAlerts/getNotificationAlerts'
import { deleteNotificationAlertsReducer } from './reducer/NotificationAlerts/deleteNotificationAlerts'
import { activityReducer } from './reducer/pinActivity'

const rootReducer = combineReducers({
  mpList: MpListReducer,
  masterList: MasterListReducer,
  highlights: HighlightsReducer,
  eventsList: EventsListReducer,
  mediaCoverageList: MediaCoverageListReducer,
  developmentProjectsList: DevelopmentProjectListReducer,
  mpSearchList: MpSearchReducer,
  mpProfileData: getMpProfileReducer,
  getAllEvents: getAllEvents,
  mediaCoverageTypesList: MediaCoverageTypesListReducer,
  developmentCompletionStatusList: DevelopmentStatusListReducer,
  createEventData: createEventReducer,
  assignMpList: getAssignMpReducer,
  createOpedsData: createOpedsReducer,
  createDevelopmentProjectData: createDevelopmentProjectReducer,
  initiativeList: getInitiativeListReducer,
  ongoingSevaInitiativesList: OngoingSevaInitiativesListReducer,
  ongoingIntiativeListWithID: OngoingSevaInitiativesListWIthInitiativeIdReducer,
  stateList: getAllStatesReducer,
  socialMediaKit: socialMediaKit,
  ongoingSevaInitiativesListByMpId: OngoingSevaInitiativesListByMpIdReducer,
  ranksCount: RanksCountReducer,
  createInitiativeReportBy: createInitiativeReportBy,
  ongoingSevaIntiativesReportsList: OngoingSevaInitiativesReportsByIdReducer,
  allInitiatives: allInitiatives,
  initiativesOverview: initiativesOverview,
  searchInitiative: searchInitiative,
  getText: getText,
  getImage: getImage,
  getVideo,
  initiativesOverview: initiativesOverview,
  searchInitiative: searchInitiative,
  initiativeReportDetailsByMPIdAndIntiativeId: OngoingSevaInitiativesReportsByInitiativeIdAndMpIdReducer,
  languageList: LanguageListReducer,
  eventListByMpId: EventsListByMpIdReducer,
  mediaCoverageListByMpId: MediaCoverageListByMpIdReducer,
  developmentProjectListByMpId: DevelopmentProjectListByMpIdReducer,
  socialMediaInitiativeById: SocialMediaInitiativeByIdReducer,
  mostViewedTweets: getMostViewedTweetsReducer,
  mostRetweetedTweets: getMostRetweetedTweetsReducer,
  userLogin: getloginReducer,
  mostTwiiterNewFollowers: getTwitterMostNewFollowersReducer,
  userProfile: getUserProfileReducer,
  mediaFiles: uploadMediaFiles,
  opedAndBooksList: OpedsAndBooksListReducer,
  opedAndBooksListByMpId: OpedsAndBooksListByMpIdReducer,
  onlyMediaCoverageList: OnlyMediaCoverageListReducer,
  onlyMediaCoverageListByMpId: OnlyMediaCoverageListByMpIdReducer,
  searchSevaUpdates: searchSevaUpdates,
  searchSevaInitiatives: searchSevaInitiatives,
  createNotificationAlerts: createNotificationAlertsReducer,
  getNotificationAlerts: getNotificationAlertsReducer,
  deleteNotificationAlerts: deleteNotificationAlertsReducer,
  intiativesReportByInitiativeId: getIntiativesReportByInitiativeIdReducer,
  intiativesEventByInitiativeId: getIntiativesEventByInitiativeIdReducer,
  activity: activityReducer,
})

export default rootReducer
