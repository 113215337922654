import { FETCH_RANKS_COUNT_FAILURE, FETCH_RANKS_COUNT_REQUEST, FETCH_RANKS_COUNT_SUCCESS } from './types';
import axiosInstance from '../../utils/api';

export const fetchRanksCountRequest = () => {
  return {
    type: FETCH_RANKS_COUNT_REQUEST,
  };
};
export const fetchRanksCountSuccess = (data) => {
  return {
    type: FETCH_RANKS_COUNT_SUCCESS,
    payload: data,
  };
};
export const fetchRanksCountFailure = (error) => {
  return {
    type: FETCH_RANKS_COUNT_FAILURE,
    payload: error,
  };
};

export const getRanksCount = (mpId) => async (dispatch) => {
  dispatch(fetchRanksCountRequest);
  await axiosInstance
    .get(`/api/ranks/${mpId ? mpId : 0}?filter=15days`)
    .then((response) => {
      const result = response.data;
      dispatch(fetchRanksCountSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchRanksCountFailure(errorMsg));
    });
};
