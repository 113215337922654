import React, { useState, useRef, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import {
  Select,
  MenuItem,
  Button,
  TextField,
  Grid,
  Box,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  Paper,
  IconButton,
  FormHelperText,
  Tooltip,
  tooltipClasses,
  styled,
  InputAdornment,
  Avatar,
  Typography,
} from '@mui/material'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import UploadIcon from '@mui/icons-material/Upload'
import CrossIcon from 'asserts/images/Close.svg'
import { useLoading } from 'utils/LoadingContext'
import { useNotificationContext } from 'utils/NotificationContext'
import { postCreateInitiativeReportBy } from 'store/action/createInitiativeReportBy'
import { getMpProfile } from 'store/action/individualMP'
import closeIconp from 'asserts/images/close.png'
import infoic from 'asserts/images/Info.svg'
import {
  getIntiativesReportByIdList,
  getIntiativesReportByInitiativeIdAndMpId,
} from 'store/action/ongoingSevaInitiativesList'
import { getIds } from 'Components/ReusableComponents.js/getIds'
import { validateNotEmpty } from 'Components/ReusableComponents.js/reuseMethods'
import '../DeleteEventDialog/dialogStyle.css'
import { uploadMedia } from 'utils/mediaUpload'
import { makeLogRequest } from 'utils/AuditLogs'
import { allowedImageFormat, acceptedImageFormat, defaultErrorMessage } from 'utils/constant'
import { pushTag } from 'utils/gtmservice'
import './createInitiativeReportDialog.css'

const CreateInitiativeReportDialog = ({
  handleClose,
  open,
  initiativeName,
  initiativeId,
  setCheckInitiativeStatus,
  insideYourInitiative,
  participants,
}) => {
  const [images, setImages] = useState([])
  const [files, setFiles] = useState([])
  const { setLoading } = useLoading()
  const dispatch = useDispatch()
  const { showNotification } = useNotificationContext()
  const [checkImages, setCheckImages] = useState(false)
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const [checkDescError, setCheckDescError] = useState(false)
  const [checkDescValue, setCheckDescValue] = useState(false)
  const [descValue, setDescValue] = useState('')
  const [descErrorValue, setDescErrorValue] = useState('')
  const [checkDescChanges, setCheckDescChanges] = useState(false)
  const fileFormats = ['image/png', 'image/jpg']
  // const [value, setValue] = useState(0);
  useEffect(() => {
    //call api to update store
    dispatch(getMpProfile(getIds()))
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    clearErrors,
  } = useForm()

  const hiddenFileInput = useRef(null)

  const postAuditLogs = async (sqId, i_id, report_id) => {
    let requestData = {
      useridtype: getIds(),
    }
    if (i_id !== null) {
      requestData['i_id'] = i_id && i_id
    }
    if (report_id !== null) {
      requestData['report_id'] = report_id && report_id
    }
    await makeLogRequest('create_initiative_report_popup', sqId, requestData)
    pushTag({
      event: 'pop_up',
      page_title: 'create_initiative_report_pop_up',
    })
  }

  useEffect(() => {
    postAuditLogs('pageload')
  }, [])

  const onSubmit = async (data) => {
    if (descValue === '') {
      setDescErrorValue('Please enter the Initiative description')
      return
    }
    if (descValue.length < 10 && descValue.length > 0) {
      setDescErrorValue('Minimum character length is 10')
      return
    }
    if (images?.length === 0) return
    // Log handled from backend
    // postAuditLogs("submit",initiativeId);
    pushTag({
      event: 'proceed',
      page_title: 'create_initiative_report_pop_up',
    })
    setLoading(true)
    const tkn = localStorage.getItem('tokenDetails')
    const formData = new FormData()
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tkn}`,
      },
    }
    const mediaFiles = await uploadMedia(dispatch, { imageFiles: files }, true)
    const payloadObj = {
      // participant: data?.participants,
      desc: data?.desc,
      initiativeId: initiativeId,
      mpmodelId: mpProfileData?.id,
      media: mediaFiles?.imageUrls,
    }

    try {
      const response = await dispatch(postCreateInitiativeReportBy(0, payloadObj, config, showNotification))
      if (response.status === 200 || response.status === 201) {
        showNotification('Success', response.data.message, 'success')
        if (insideYourInitiative) {
          setCheckInitiativeStatus(true)
        }
        handleClose(reset, setImages, setFiles)
        dispatch(getIntiativesReportByIdList(initiativeId))
        // Object.keys(data).map(val => resetField(val));
        // setFiles([]);
        // setUploadFiles([]);
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    } finally {
      setLoading(false)
    }
  }
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #356F92',
      },
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: '1px solid #356F92',
      borderRadius: '15px',
      boxShadow: theme.shadows[1],
      left: '20px',
      width: 210,
    },
  }))

  const handleDelete = (index) => {
    const tempImages = [...images]
    const tempFiles = [...files]
    tempImages.splice(index, 1)
    tempFiles.splice(index, 1)
    document.getElementById('Images-Item').value = ''
    setImages(tempImages)
    setFiles(tempFiles)
  }

  // const handleImageChange = (e) => {
  //     const uploadedFiles = e.target.files;
  //     setFiles([...files, ...uploadedFiles]);
  //     let newImages = [];
  //     for (let i = 0; i < uploadedFiles.length; i++) {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(uploadedFiles[i]);
  //         reader.onload = () => {
  //             newImages.push({ url: reader.result, file: uploadedFiles[i] });
  //             if (i === uploadedFiles.length - 1) {
  //                 setImages([...images, ...newImages]);
  //             }
  //         };
  //     }
  // };

  const handleImageChange = (e) => {
    const uploadedFiles = e.target.files
    let newFiles = []
    let processedImages = 0
    for (let i = 0; i < uploadedFiles.length; i++) {
      const isRightFormat = allowedImageFormat.includes(uploadedFiles[i].type)
      const fileSizeInBytes = uploadedFiles[i].size
      const maxSizeInBytes = 20 * 1024 * 1024 // 20MB
      if (fileSizeInBytes > maxSizeInBytes) {
        showNotification('Error', 'Please upload image with size less than 20MB', 'error')
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(uploadedFiles[i])
      reader.onload = () => {
        if (uploadedFiles[i].type.startsWith('image')) {
          if (!isRightFormat) {
            showNotification('Error', 'Please upload image in JPG or PNG format only', 'error')
            return
          }
          newFiles.push({
            type: 'image',
            url: reader.result,
            file: uploadedFiles[i],
          })
          processedImages++
        } else if (uploadedFiles[i].type.startsWith('video')) {
          if (!isRightFormat) {
            showNotification('Error', 'Please upload image in JPG or PNG format only', 'error')
            return
          }
          newFiles.push({
            type: 'video',
            url: reader.result,
            file: uploadedFiles[i],
          })
          processedImages++
        } else {
          if (!isRightFormat) {
            showNotification('Error', 'Please upload image in JPG or PNG format only', 'error')
            return
          }
        }

        if (processedImages === uploadedFiles.length) {
          setImages([...newFiles, ...images])
          setFiles([...files, ...uploadedFiles])
        }
      }
    }
  }

  const handlePreview = (file) => {
    if (file.type === 'image') {
      return (
        <img
          src={typeof file === 'string' ? file : file.url}
          alt="Preview"
          className="form-img__img-preview-ed-ne w-140px"
        />
      )
    } else if (file.type === 'video') {
      return (
        <video controls className="form-img__img-preview-ed-ne">
          <source src={typeof file === 'string' ? file : file.url} />
          Your browser does not support the video tag.
        </video>
      )
    }
  }

  const handleClick = (event) => {
    hiddenFileInput.current.click()
  }

  const blockInvalidChar = (e) => {
    if (['e', 'E', '+', '-', '.'].includes(e.key) || e.keyCode === 40) {
      e.preventDefault()
    }
    if (e.keyCode === 40) {
      // 40 is the key code for down arrow
      const currentValue = e.target.value
      if (currentValue > 1) {
        e.target.value = Number(currentValue) - 1
      }
      e.preventDefault()
    }
  }

  const onPasteCLick = (e) => {
    var clipboardData = e.clipboardData.getData('text')
    var cleanedData = clipboardData.replace(/[-e]/gi, '')
    if (cleanedData.length === 0) {
      e.preventDefault()
    } else {
      document.execCommand('insertText', false, cleanedData)
      e.preventDefault()
    }
  }
  const checkTextField = (value, maxLength, minLength, register_name, fieldName) => {
    if (value.length == 0) {
      setError(`${register_name}`, {
        type: 'value',
        message: `Please enter ${fieldName}`,
      })
    } else if (value.length > 0 && value.length < minLength) {
      setError(`${register_name}`, {
        type: 'minLength',
        message: `Minimum character length is ${minLength}`,
      })
    } else if (value.length == maxLength) {
      setError(`${register_name}`, {
        type: 'maxLength',
        message: 'Maximum limit reached',
      })
    } else {
      clearErrors(`${register_name}`)
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={async () => (
          await postAuditLogs('close'),
          pushTag({
            event: 'back',
            page_title: 'create_initiative_report_pop_up',
          }),
          setCheckImages(false),
          handleClose(reset, setImages, setFiles)
        )}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              onClick={async () => (
                setCheckImages(false),
                await postAuditLogs('close'),
                pushTag({
                  event: 'back',
                  page_title: 'create_initiative_report_pop_up',
                }),
                handleClose(reset, setImages, setFiles)
              )}
              src={CrossIcon}
              className="closeIcon"
            />
          </Box>
          <Box className="d-flex flex-column align-items-center">
            <Box>
              <Typography component="b" className="Create-Initiative-Report-heading-text">
                Create Initiative Report For{' '}
              </Typography>
            </Box>
            <Box className="create-initiative-report__title-wrapper">
              <Typography
                component="b"
                className="create-initiative-report__title create-initiative-report-subtext"
                title={initiativeName ? initiativeName : ''}
              >
                {initiativeName && initiativeName}
              </Typography>
            </Box>
          </Box>
        </DialogTitle>
        <Grid container className="bg-white">
          <Grid item xs={12} md={12}>
            <Grid item xs={12} md={12} sx={{ p: 4 }}>
              <Box>
                <form>
                  <Grid container spacing={1} justifyContent="left" alignItems="center">
                    <Grid container sx={{ mb: 2 }}>
                      <Grid item xs={12} sx={{ pr: 1 }}>
                        <Typography component="b" className="fieldname-title">
                          Participants
                        </Typography>
                        <FormControl
                          fullWidth
                          height="60px"
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderRadius: '14px',
                            },
                            height: '60px',
                          }}
                        >
                          <TextField
                            className="stepperFormInput partic"
                            width="300px"
                            size="small"
                            type="number"
                            autoComplete="off"
                            disabled
                            sx={{
                              '& .MuiInputBase-input.Mui-disabled': {
                                WebkitTextFillColor: 'black',
                                height: '40px',
                              },
                              width: '300px',
                              height: '56px',
                              backgroundColor: '#E8E8E8',
                              borderRadius: '13px',
                            }}
                            defaultValue={participants ? participants : 0}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <BootstrapTooltip
                                    title="This is the sum of participant count across all events under this initiative"
                                    placement="right"
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          fontSize: '18px',
                                          fontFamily: 'HK Grotesk',
                                          padding: '15px',
                                        },
                                      },
                                    }}
                                  >
                                    <img src={infoic} className="infoicon2" />
                                    {/* <ErrorOutlineIcon sx={{ ml: 2, color: "#387194" }} /> */}
                                  </BootstrapTooltip>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ mb: 2, mt: 1 }}>
                      <Grid item xs={12} sx={{ pr: 1 }}>
                        <Typography component="b" className="fieldname-title">
                          Initiative Description
                        </Typography>
                        <FormControl
                          fullWidth
                          sx={{
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderRadius: '14px',
                            },
                          }}
                        >
                          <TextField
                            className="stepperFormInput"
                            name="desc"
                            placeholder="Enter Initiative Description"
                            fullWidth
                            required
                            inputProps={{
                              maxLength: 5000,
                              minLength: 10,
                              wrap: 'soft',
                            }}
                            multiline
                            rows={3}
                            size="small"
                            autoComplete="off"
                            {...register('desc', {
                              required: 'Please enter the initiative description',
                              maxLength: {
                                value: 5000,
                                message: 'Maximum 5000 characters allowed',
                              },
                              minLength: {
                                value: 10,
                                message: 'Minimum character length is 10',
                              },
                              validate: (value) => validateNotEmpty(value, 'the Initiative description'),
                            })}
                            onChange={(e) => {
                              let trimmedValue = e.target.value.trimStart()
                              trimmedValue = trimmedValue.replace(/\s{2,}/g, ' ')
                              checkTextField(trimmedValue, 5000, 10, 'desc', 'the initiative description')
                              e.target.value = trimmedValue
                              if (trimmedValue.length !== 0) {
                                setDescValue(trimmedValue)
                                setCheckDescValue(true)
                              } else {
                                setCheckDescValue(false)
                              }

                              if (e.target.value.length > 5000) {
                                setCheckDescValue(false)
                                setCheckDescError(true)
                                setCheckDescChanges(true)
                                setDescErrorValue('Maximum 5000 characters allowed')
                              } else if (trimmedValue.length !== 0) {
                                setDescValue(trimmedValue)
                                setDescErrorValue('')
                                setCheckDescError(false)
                                setCheckDescChanges(false)
                              }
                            }}
                            onPaste={(e) => {
                              const pastedText = e.clipboardData.getData('text')
                              if (pastedText.length + e.target.value.length > 5000) {
                                e.preventDefault()
                                const truncatedText = pastedText.substring(0, 5000 - e.target.value.length)
                                document.execCommand('insertText', false, truncatedText)
                                setCheckDescError(true)
                              }
                            }}
                          />
                        </FormControl>
                        <FormHelperText
                          sx={{
                            color: errors && errors?.desc?.message ? '#d32f2f !important' : 'blue !important',
                          }}
                        >
                          {errors && errors?.desc?.message
                            ? errors && errors?.desc?.message
                            : checkDescValue
                            ? 'Maximum 5000 characters allowed'
                            : errors?.desc?.message}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Box className="fieldname-title">
                      <Typography
                        component="b"
                        sx={{
                          marginLeft: '5px',
                          fontFamily: 'HK Grotesk',
                          color: '#357092',
                          fontWeight: 'bold',
                        }}
                      >
                        {' '}
                        Upload Event Images
                      </Typography>
                    </Box>
                    <Grid container>
                      <Box className="d-flex">
                        {/* className="contpopup1"}*/}
                        {/* {images.length > 0 ? ( */}
                        {/* <Grid item xs={6} sx={{ width: "80%" }}> */}
                        <Box
                          // style={{ width: "470px", top: "0px" }}
                          className={`itemfixed-update-1-ini-less ${
                            images?.length > 2 ? 'itemfixed-update-1-ini ' : ''
                          } top-0`}
                        >
                          {images?.length >= 2 ? (
                            <Tabs
                              variant="scrollable"
                              scrollButtons
                              aria-label="visible arrows tabs example"
                              sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                  '&.Mui-disabled': { opacity: 0.3 },
                                  mt: '30px',
                                  width: '10px',
                                },
                              }}
                            >
                              {images.map((image, index) => (
                                <Card
                                  sx={{
                                    minWidth: 160,
                                    borderRadius: 0,
                                    boxShadow: 'none',
                                  }}
                                  className="form-img__img-preview-ed-ne imageCard"
                                >
                                  <CardContent className="add-more-images">
                                    <Box key={index}>{handlePreview(image)}</Box>
                                    {/* <img key={index} src={image.url} alt="Preview" className="form-img__img-preview" /> */}
                                    {/* <Button onClick={() => handleDelete(index)}>delete</Button> */}
                                    <img
                                      src={closeIconp}
                                      onClick={() => handleDelete(index)}
                                      className="imageclose-2-cird close-icon2"
                                      alt="Close"
                                    />
                                  </CardContent>
                                </Card>
                              ))}
                            </Tabs>
                          ) : (
                            images.map((image, index) => (
                              <Card
                                sx={{
                                  minWidth: 200,
                                  borderRadius: 0,
                                  boxShadow: 'none',
                                }}
                                className="form-img__img-preview-ed-ne imageCard"
                              >
                                <CardContent>
                                  <Box key={index}>{handlePreview(image)}</Box>
                                  {/* <img key={index} src={image.url} alt="Preview" className="form-img__img-preview" /> */}
                                  {/* <Button onClick={() => handleDelete(index)}>delete</Button> */}
                                  <img
                                    src={closeIconp}
                                    onClick={() => handleDelete(index)}
                                    className="imageclose-2-cird close-icon2"
                                  />
                                </CardContent>
                              </Card>
                            ))
                          )}
                        </Box>
                        {/* <h6>Event Images</h6>
                                                <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} multiple onChange={handleImageChange} />
                                                <div>
                                                    {images.map((image, index) => (
                                                        <img key={index} src={image.url} alt="" className="form-img__img-preview" />
                                                    ))}
                                                </div> */}
                        {/* </Grid>
                        ) : null} */}

                        <Grid item xs={6} sx={{ width: '20%' }}>
                          <input
                            type="file"
                            id="Images-Item"
                            ref={hiddenFileInput}
                            className="d-none"
                            multiple
                            onChange={handleImageChange}
                            accept={acceptedImageFormat}
                          />
                          <Box
                            sx={{
                              mt: 2,
                              display: 'flex',
                              '& > :not(style)': {
                                width: 150,
                                height: 140,
                                border: '3px dotted #356F92',
                                ml: '10px',
                              },
                            }}
                          >
                            <Paper
                              variant="outlined"
                              square
                              sx={{
                                border: 'dotted 3px #1976d2',
                                padding: '50px',
                                width: '40%',
                                borderRadius: '14px',
                                marginTop: '7px',
                              }}
                            >
                              <IconButton
                                color="primary"
                                aria-label="Upload"
                                onClick={async () => (postAuditLogs('add_images'), await handleClick())}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  position: 'relative',
                                  top: '25%',
                                  margin: '0 auto',
                                }}
                              >
                                <UploadIcon />
                              </IconButton>
                              <Box mt={3} />
                              <Box className="add-images-text">
                                {images?.length > 0 ? 'Add More Images' : 'Add Images'}
                              </Box>
                            </Paper>
                          </Box>
                          <FormHelperText
                            sx={{
                              color: '#d32f2f',
                              width: 'max-content',
                            }}
                          >
                            {checkImages && images?.length === 0 && 'Please upload event images'}
                          </FormHelperText>
                        </Grid>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
            <React.Fragment>
              <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <Button
                  variant="contained"
                  sx={{}}
                  className={`button-tr-2-12-disa postioncenterrelative${
                    checkDescError || checkDescChanges ? 'button-tr-2-1 postioncenterrelative' : ''
                  }`}
                  onClick={handleSubmit(onSubmit)}
                  onMouseUp={() => (setCheckImages(true), setCheckDescValue(false))}
                  disabled={checkDescChanges && true}
                >
                  Submit
                </Button>
                {/* <Button
                                    variant="outlined"
                                    onClick={handleClose}
                                    sx={{ p: 1, mr: 1 }}
                                    className="button-primary-alt-contained"
                                >
                                    Cancel
                                </Button> */}
                <Box sx={{ flex: '1 1 auto' }} />
              </Box>
            </React.Fragment>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default CreateInitiativeReportDialog
