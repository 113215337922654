import { Box, Typography } from '@mui/material'
import './SevaUpdateHeader.css'

const SevaUpdateHeader = ({ title }) => {
  return (
    <Box className="d-flex justify-content-between align-items-center carousel-pos">
      <Typography variant="h1" className="page-title mb-0 page-header2">
        {title}
      </Typography>
    </Box>
  )
}

export default SevaUpdateHeader
