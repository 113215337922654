import React, { useEffect, useMemo, useRef, useState } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import CrossIcon from '../../../../asserts/images/Close.svg'
import { IconButton, Grid, Box, Button, Toolbar, ImageListItem, Typography } from '@mui/material'
import DialogActions from '@mui/material/DialogActions'
import closeIconp from '../../../../asserts/images/close-1.png'
import { useNotificationContext } from '../../../../utils/NotificationContext'
import AddIcon from '@mui/icons-material/Add'
import zipIcon from '../../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../../asserts/images/FileIcon.svg'
import { handleFileName, handleSrc } from '../../../ReusableComponents.js/reuseMethods'
import { useSelector } from 'react-redux'
import { makeLogRequest } from '../../../../utils/AuditLogs'
import unknownImg from '../../../../asserts/images/folders.png'
import { ImageLoader2 } from '../../../ReusableComponents.js/ImageLoader2'

const UploadImages = ({
  handleCloseUploadDialog,
  state,
  setValue,
  getValue,
  initialImages,
  setImagesNew,
  setInputData,
  fileFormats,
  zipFormat,
  aiFormat,
  setCheckWarningPopup,
}) => {
  const [isCheckFirstTime, setIsCheckFirstTime] = useState(true)
  const images = useRef([])
  const [ImagesCount, setImagesCount] = useState(-1)
  const tempImagesForOperationsRef = useRef([])
  const originalStateForImagesRef = useRef([])
  const tempForRemovingImagesRef = useRef([])
  const [isImageDeleted, setIsImageDeleted] = useState(false)

  useEffect(() => {
    if (isCheckFirstTime) {
      setIsImageDeleted(false)
      images.current = initialImages.length > 0 && initialImages
      setImagesCount(images.current.length)
      originalStateForImagesRef.current = [...images.current]
      tempImagesForOperationsRef.current = originalStateForImagesRef.current
      setIsCheckFirstTime(false)
    }
  }, [])

  const [files, setFiles] = useState([])
  const [uploadMore, setUploadMore] = useState(false)
  const { showNotification } = useNotificationContext()
  const AddMoreFileInput = useRef(null)

  const titleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#2e739c',
      fontWeight: '700',
      textAlign: 'center',
      fontSize: '26px',
    }),
    []
  )
  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )
  const userProfile = useSelector((state) => state?.userProfile?.data)

  const handleFileChange = (e) => {
    const uploadedFiles = e.target.files
    if (uploadedFiles.length > 0) {
      setCheckWarningPopup(true)
    }

    let newImages = []
    let remainingZipCount = 5 - countExistingZips()

    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i]
      const isRightFormat = fileFormats.includes(file.type) || fileFormats.includes('.' + file?.name?.split('.').pop())
      const fileSizeInBytes = uploadedFiles[i].size
      const maxSizeInBytes = 20 * 1024 * 1024 // 20MB
      if (fileSizeInBytes > maxSizeInBytes) {
        showNotification('Error', 'Please upload image with size less than 20MB', 'error')
        return
      }
      if (file.type === 'application/x-zip-compressed' && remainingZipCount > 0) {
        remainingZipCount--
      } else if (file.type === 'application/x-zip-compressed' && remainingZipCount <= 0) {
        // Reject the upload and display an error message
        showNotification('Error', 'Only a maximum of 5 zip files are allowed in total.', 'error')
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        newImages.push({
          type: zipFormat.includes(file.type) ? 'zip' : aiFormat.includes(file.type) ? 'ai' : 'image',
          url: zipFormat.includes(file.type) ? zipIcon : aiFormat.includes(file.type) ? fileIcon : reader.result,
          file: file,
        })

        if (i === uploadedFiles.length - 1) {
          if (!isRightFormat) {
            showNotification('Error', 'You can only upload jpg, jpeg and png images', 'error')
            return
          }

          images.current = [...images.current, ...newImages]
          tempImagesForOperationsRef.current = [...tempImagesForOperationsRef.current, ...newImages]
          setImagesCount(images.current.length)
          setUploadMore(true)
        }
      }
    }
  }

  const countExistingZips = () => {
    let zipCount = 0
    images.current.forEach((file) => {
      if (file.type === 'zip' || (!file.type && file?.split('.').pop() == 'zip')) {
        zipCount++
      }
    })
    return zipCount
  }

  const handleDelete = (index) => {
    setCheckWarningPopup(true)
    const tempImages = [...images.current]
    tempImages.splice(index, 1)
    images.current = tempImages
    setImagesCount(images.current.length)
    tempForRemovingImagesRef.current = tempImages
    setIsImageDeleted(true)
  }

  const closeDialog = (event, reason) => {
    if (reason && reason == 'backdropClick') return
    setInputData((prevState) => {
      const newState = [...prevState] // make a copy of the state array
      newState[`${state.for}`] = {
        ...newState[`${state.for}`],
        imagesData: originalStateForImagesRef.current,
      } // update the i'th object's imagesData
      return newState
    })

    tempImagesForOperationsRef.current = [...originalStateForImagesRef.current]
    setValue(`${state.for}.images`, tempImagesForOperationsRef.current)
    images.current = tempImagesForOperationsRef.current
    setImagesCount(images.current.length)
    setImagesNew([...tempImagesForOperationsRef.current])

    if (isImageDeleted) {
      setIsImageDeleted(false)
    }
    handleCloseUploadDialog(true)
  }

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('image_preview_popup', sqId, data)
  }

  useEffect(() => {
    postAuditLogs('pageload')
  }, [])

  useEffect(() => {}, [getValue])

  useEffect(() => {
    if (uploadMore) {
      setUploadMore(false)
    }
  }, [images.current])

  const handleAddMore = (event) => {
    AddMoreFileInput.current.value = ''
    AddMoreFileInput.current?.click()
    event?.stopPropagation()
  }

  const handleSaveButton = async (e) => {
    await postAuditLogs('save')
    handleCloseUploadDialog(true)

    setInputData((prevState) => {
      const newState = [...prevState]

      newState[`${state.for}`] = {
        ...newState[`${state.for}`],
        imagesData: isImageDeleted ? tempForRemovingImagesRef.current : tempImagesForOperationsRef.current,
      }

      return newState
    })

    if (isImageDeleted) {
      originalStateForImagesRef.current = [...tempForRemovingImagesRef.current]
      setValue(`${state.for}.images`, tempForRemovingImagesRef.current)
      setImagesNew([...tempForRemovingImagesRef.current])
      images.current = tempForRemovingImagesRef.current
      tempImagesForOperationsRef.current = [...tempForRemovingImagesRef.current]
      setImagesCount(images.current.length)
      setIsImageDeleted(false)
    } else {
      originalStateForImagesRef.current = [...tempImagesForOperationsRef.current]
      setValue(`${state.for}.images`, tempImagesForOperationsRef.current)
      setImagesNew([...tempImagesForOperationsRef.current])
      images.current = tempImagesForOperationsRef.current
      setImagesCount(images.current.length)
    }
  }

  return (
    <Dialog onClose={closeDialog} disableEscapeKeyDown open={state.open} sx={{ borderRadius: '14px' }}>
      <DialogTitle sx={titleStyles}>Image Preview</DialogTitle>
      <img
        onClick={async (e) => {
          await postAuditLogs('close')
          closeDialog(e, undefined)
        }}
        src={CrossIcon}
        className="closeIcon"
      />
      <Grid item xs={6}>
        <Box className="popup-h">
          <Box
            className="upload-img__items"
            sx={{
              overflow: 'auto',
              display: 'flex',
            }}
          >
            <Box className="image-column-align image-container">
              {images.current &&
                images.current.length > 0 &&
                ImagesCount &&
                images.current.map((image, index) => (
                  <Box className="img-m1" key={Date.now() + index}>
                    {['zip', 'ai'].includes(handleSrc(image)) ? (
                      <ImageListItem
                        key={index}
                        className="bgfolder cursorshow zip-placeholder"
                        sx={{
                          margin: '0px',
                          width: '200px !important',
                          padding: '7px 33px 38px',
                          aspectRatio: '1 / 0.9 !important',
                        }}
                      >
                        <img
                          class="m-0 "
                          key={index}
                          src={
                            typeof image == 'string'
                              ? image?.split('.').pop() === 'zip'
                                ? zipIcon
                                : ['ai', 'postscript'].includes(image.split('.').pop()) && fileIcon
                              : image.type === 'zip'
                              ? zipIcon
                              : fileIcon
                          } //?w=164&h=164&fit=crop&auto=format
                          srcSet={
                            typeof image == 'string'
                              ? image?.split('.').pop() === 'zip'
                                ? zipIcon
                                : ['ai', 'postscript'].includes(image.split('.').pop()) && fileIcon
                              : image.type === 'zip'
                              ? zipIcon
                              : fileIcon
                          } //?w=164&h=164&fit=crop&auto=format
                          // alt={image.title}
                        />
                        <Typography variant="subtitle1" sx={subtitleStyles}>
                          {handleFileName(image)}
                        </Typography>
                      </ImageListItem>
                    ) : (
                      <ImageLoader2
                        extrawidth={true}
                        key={index}
                        src={handleSrc(image)}
                        srcSet={handleSrc(image)}
                        className={`imageuploadFinal${state.for} uploaded-images`}
                        defaultImg={unknownImg}
                        subtitleStyles={subtitleStyles}
                        item={handleFileName(image)}
                      />
                    )}
                    {/* <Button className='imageclose' >delete</Button> */}
                    <img
                      src={closeIconp}
                      onClick={() => handleDelete(index)}
                      className={`${
                        getValue(`${state.for}.bannerSize`) == '3:6'
                          ? 'imageclose36'
                          : getValue(`${state.for}.bannerSize`) == '16:9'
                          ? 'imageclose169'
                          : 'imageclose'
                      }
                      ${['zip', 'ai'].includes(handleSrc(image)) && ' cond-1'}`}
                    />
                  </Box>
                ))}
            </Box>

            {/* <Button onClick={handleClick} variant="contained" sx={{ p: 1, mr: 1, backgroundColor: "#ef7335" }}
                        className="button-primary-alt-contained">Upload Videos</Button> */}
            {/* Add More Videos */}
            {/* </Paper> */}
          </Box>
        </Box>
      </Grid>

      <DialogActions sx={{ justifyContent: 'flex-start', marginBottom: '10px', marginLeft: '15px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            mb: 2,
            marginLeft: '-15px',
          }}
        >
          <Button
            variant="outlined"
            className="button-tr-citizen-admin1"
            startIcon={<AddIcon sx={{ mt: '5px' }} />}
            onClick={(e) => handleAddMore(e)}
          >
            <input
              type="file"
              ref={AddMoreFileInput}
              class="d-none"
              multiple
              onChange={handleFileChange}
              accept={fileFormats.join(', ')}
            />
            Add More
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
        </Box>
        {/* <Button variant="contained" className='button-tr-2' style={Styles.button}>
                    <input type="file" multiple onChange={handleFileChange} accept="image/png, image/jpeg, image/jpg" style={Styles.input} />
                    Upload More</Button> */}
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, mt: 2 }}>
          <Button
            variant="contained"
            className="button-tr-2"
            autoFocus
            onClick={(e) => handleSaveButton(e)}
            sx={{ backgroundColor: '#ef7335', left: '50%' }}
          >
            Save
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 2, mt: 2 }}>
          <Button
            variant="outlined"
            className="button-tr-citizen-cancel"
            sx={{
              p: 1,
              mr: 1,
              backgroundColor: '#ef7335',
              borderRadius: 4,
              position: 'relative',
              left: '40%',
            }}
            // className="button-tr-2"
            type="submit"
            onClick={async (e) => {
              await postAuditLogs('cancel')
              closeDialog(e, undefined)
            }}
          >
            Cancel
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
        </Box>
      </DialogActions>
    </Dialog>
  )
}

const Styles = {
  // button: {
  //     position: 'relative',
  //     overflow: 'hidden',
  //     width: 'fit-content'
  // },
  input: {
    position: 'absolute',
    opacity: '0',
    cursor: 'pointer',
  },
}

export default UploadImages
