import React, { useEffect, useMemo, useState, useRef } from 'react'
import Dialog from '@mui/material/Dialog'
import { Grid, Box, ImageListItem, Typography } from '@mui/material'
import closeIconp from '../../../asserts/images/close-1.png'
import { useNotificationContext } from '../../../utils/NotificationContext'
import zipIcon from '../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../asserts/images/FileIcon.svg'
import { handleFileName, handleSrc } from '../../ReusableComponents.js/reuseMethods'
import { useSelector } from 'react-redux'
import { makeLogRequest } from '../../../utils/AuditLogs'
import unknownImg from '../../../asserts/images/folders.png'
import { ImageLoader2 } from '../../ReusableComponents.js/ImageLoader2'
import UploadAssetDialogButtonsSection from '../UploadAssetDialogButtonsSection/UploadAssetDialogButtonsSection'
import TemplateDialogTitle from '../TemplateDialogTitle/TemplateDialogTitle'
import TemplateDialogCloseButton from '../TemplateDialogCloseButton/TemplateDialogCloseButton'
import './UploadImageDialog.css'

const UploadImages = ({
  handleCloseUploadDialog,
  state,
  setValue,
  getValue,
  initialImages,
  setImagesNew,
  setInputState,
  inputState,
  fileFormats,
  zipFormat,
  aiFormat,
  setCheckWarningPopup,
}) => {
  const [imagesArr, setImagesArr] = useState([])
  const [initialImagesData, setInitialImagesData] = useState(initialImages?.length > 0 && initialImages)

  useEffect(() => {
    setImagesArr([...initialImagesData])
  }, [initialImagesData])

  const [uploadMore, setUploadMore] = useState(false)
  const { showNotification } = useNotificationContext()

  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )
  const userProfile = useSelector((state) => state?.userProfile?.data)

  const handleFileChange = (e) => {
    const uploadedFiles = e.target.files
    if (uploadedFiles.length > 0) {
      setCheckWarningPopup(true)
    }

    let newImages = []
    let remainingZipCount = 5 - countExistingZips()

    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i]
      const isRightFormat = fileFormats.includes(file.type) || fileFormats.includes('.' + file?.name?.split('.').pop())
      const fileSizeInBytes = uploadedFiles[i].size
      const maxSizeInBytes = 20 * 1024 * 1024 // 20MB
      if (fileSizeInBytes > maxSizeInBytes) {
        showNotification('Error', 'Please upload image with size less than 20MB', 'error')
        return
      }
      if (file.type === 'application/x-zip-compressed' && remainingZipCount > 0) {
        remainingZipCount--
      } else if (file.type === 'application/x-zip-compressed' && remainingZipCount <= 0) {
        // Reject the upload and display an error message
        showNotification('Error', 'Only a maximum of 5 zip files are allowed in total.', 'error')
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)

      reader.onload = () => {
        newImages.push({
          type: zipFormat.includes(file.type) ? 'zip' : aiFormat.includes(file.type) ? 'ai' : 'image',
          url: zipFormat.includes(file.type) ? zipIcon : aiFormat.includes(file.type) ? fileIcon : reader.result,
          file: file,
        })

        if (i === uploadedFiles.length - 1) {
          if (!isRightFormat) {
            showNotification(
              'Error',
              'Accepted file formats for open & zip uploads: .jpg, .jpeg, .jpe, .jif, .jfif, .jfi, .png, .tiff, .psd, .eps, .ai, .indd, .raw, .jfif, .webp, .svg, .svgz, .heif',
              'error'
            )
            return
          }

          setImagesArr((prevState) => [...prevState, ...newImages])
          setUploadMore(true)
        }
      }
    }
  }

  const countExistingZips = () => {
    let zipCount = 0
    imagesArr.forEach((file) => {
      if (file.type === 'zip' || (!file.type && file?.split('.').pop() == 'zip')) {
        zipCount++
      }
    })
    return zipCount
  }

  const handleDelete = (index) => {
    setCheckWarningPopup(true)
    const imageArr = imagesArr
    imageArr.splice(index, 1)
    setImagesArr([...imageArr])
  }

  const closeDialog = (event, reason) => {
    if (reason && reason == 'backdropClick') return

    setValue(`${state.for}.images`, initialImagesData)
    setImagesNew([])

    setInputState((prevState) => {
      const newState = [...prevState] // make a copy of the state array
      newState[`${state.for}`] = {
        ...newState[`${state.for}`],
        imagesData: initialImagesData,
      } // update the i'th object's imagesData
      return newState
    })

    handleCloseUploadDialog()
  }

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('image_preview_popup', sqId, data)
  }

  useEffect(() => {
    postAuditLogs('pageload')
  }, [])

  useEffect(() => {}, [getValue])

  useEffect(() => {
    if (uploadMore) {
      setUploadMore(false)
    }
  }, [imagesArr])

  const handleSaveButton = async (e) => {
    await postAuditLogs('save')
    handleCloseUploadDialog()

    setInputState((prevState) => {
      const newState = [...inputState]
      newState[`${state.for}`] = {
        ...newState[`${state.for}`],
        imagesData: imagesArr,
      }
      return newState
    })

    setValue(`${state.for}.images`, imagesArr)
    setImagesNew([...imagesArr])
  }
  const handleCancelButton = async (e) => {
    await postAuditLogs('cancel')
    setImagesArr([])

    setInputState((prevState) => {
      const newState = [...inputState]
      newState[`${state.for}`] = {
        ...newState[`${state.for}`],
        imagesData: [],
      }
      return newState
    })

    setValue(`${state.for}.images`, [])
    setImagesNew([])
  }

  return (
    <Dialog
      onClose={() => {
        closeDialog()
        handleCancelButton()
      }}
      disableEscapeKeyDown
      open={state.open}
      sx={{ borderRadius: '14px' }}
    >
      <TemplateDialogTitle title={'Image Preview'} type={'preview'} />
      <TemplateDialogCloseButton
        onClick={async (e) => {
          await postAuditLogs('close')
          closeDialog(e, undefined)
          handleCancelButton()
        }}
      />
      <Grid item xs={6}>
        <Box className="popup-h">
          <Box
            className="upload-img__items"
            sx={{
              overflow: 'auto',
              display: 'flex',
            }}
          >
            <Box className="image-column-align image-container">
              {imagesArr &&
                imagesArr?.length > 0 &&
                imagesArr.map((image, index) => (
                  <Box className="img-m1" key={Date.now() + index}>
                    {['zip', 'ai'].includes(handleSrc(image)) ? (
                      <ImageListItem
                        key={index}
                        className="bgfolder cursorshow zip-placeholder"
                        sx={{
                          margin: '0px',
                          width: '200px !important',
                          padding: '7px 33px 38px',
                          aspectRatio: '1 / 0.9 !important',
                        }}
                      >
                        <img
                          class="m-0"
                          key={index}
                          src={
                            typeof image == 'string'
                              ? image?.split('.').pop() === 'zip'
                                ? zipIcon
                                : ['ai', 'postscript'].includes(image.split('.').pop()) && fileIcon
                              : image.type === 'zip'
                              ? zipIcon
                              : fileIcon
                          } //?w=164&h=164&fit=crop&auto=format
                          srcSet={
                            typeof image == 'string'
                              ? image?.split('.').pop() === 'zip'
                                ? zipIcon
                                : ['ai', 'postscript'].includes(image.split('.').pop()) && fileIcon
                              : image.type === 'zip'
                              ? zipIcon
                              : fileIcon
                          } //?w=164&h=164&fit=crop&auto=format
                        />
                        <Typography variant="subtitle1" sx={subtitleStyles}>
                          {handleFileName(image)}
                        </Typography>
                      </ImageListItem>
                    ) : (
                      <ImageLoader2
                        extrawidth={true}
                        key={index}
                        src={handleSrc(image)}
                        srcSet={handleSrc(image)}
                        className={`imageuploadFinal${state.for} uploaded-images`}
                        defaultImg={unknownImg}
                        subtitleStyles={subtitleStyles}
                        item={handleFileName(image)}
                      />
                    )}
                    <img
                      src={closeIconp}
                      onClick={() => handleDelete(index)}
                      className={`${
                        getValue(`${state.for}.bannerSize`) == '3:6'
                          ? 'imageclose36'
                          : getValue(`${state.for}.bannerSize`) == '16:9'
                          ? 'imageclose169'
                          : 'imageclose'
                      }
                      ${['zip', 'ai'].includes(handleSrc(image)) && ' cond-1'}`}
                    />
                  </Box>
                ))}
            </Box>
          </Box>
        </Box>
      </Grid>

      <UploadAssetDialogButtonsSection
        onChange={handleFileChange}
        onClickForSaveButton={(e) => handleSaveButton(e)}
        onClickForCancelButton={async (e) => {
          await postAuditLogs('cancel')
          closeDialog(e, undefined)
          handleCancelButton(e)
        }}
        fileFormats={fileFormats}
      />
    </Dialog>
  )
}

export default UploadImages
