import { Box } from '@mui/material'
import './InitiativeData.css'
import React, { useEffect, useState } from 'react'
import { getRoles } from '../../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../../utils/AuditLogs'
import DetailsColumn from './DetailsColumn'
const mpUserId = getRoles()
const postAuditLogs = async (sqId, data = {}, flag = 'your_initiatives_page') => {
  data.user_role = getRoles() == 2 ? 'admin' : getRoles() == 3 ? 'leadership' : 'mp'
  data.useridtype = mpUserId
  await makeLogRequest(flag, sqId, data)
}
function IntiativesDetailsCard({ data, passChildData, setDetails, loading, searchStatus, filteredList }) {
  const functionHandler = async (item) => {
    await postAuditLogs('assigned_initiative')
    passChildData(item)
    if (filteredList.length !== 0) {
      setDetails(true)
    }
  }

  let list = filteredList

  useEffect(() => {
    // if (!props.data) {
    if (filteredList) {
      if (!data) {
        passChildData(filteredList[0])
      }

      if (filteredList.length !== 0) {
        setDetails(true)
      }
    }

    // }
  }, [filteredList])

  if (list?.length === 0) {
    if (loading) return <Box className="loading-color">Loading Initiatives....</Box>
    else if (searchStatus) return <Box className="search-status-style">No Matches found. Try something else...</Box>
    else return <Box className="search-status-style">No Initiatives Created....</Box>
  }

  return list?.map((item, index) => {
    return <DetailsColumn item={item} index={index} functionHandler={functionHandler} />
  })
}
export default IntiativesDetailsCard
