import { Dialog, DialogTitle, Box } from '@mui/material'
import React, { useEffect } from 'react'
import { useState } from 'react'
import AssignMpSelectComponent from 'Components/CreateInitiativesRefactored/AssignMpSelectComponent/AssignMpSelectComponent'
import { useDispatch, useSelector } from 'react-redux'
import TemplateDialogCloseButton from 'Components/CreateInitiativesRefactored/TemplateDialogCloseButton/TemplateDialogCloseButton.js'
import TemplateDialogSubmitButton from 'Components/CreateInitiativesRefactored/TemplateDialogSubmitButton/TemplateDialogSubmitButton'
import { getStateList } from 'store/action/stateList'
import { getDevelopementAcitivites } from 'store/action/pinActivity'

import { getAssignMpList } from 'store/action/assignMpList'
const Filter = ({
  open,
  setFilterOpen,
  setStateName,
  mpIdLists,
  setMpIdLists,
  selectedState,
  setStateApiResult,
  assignMpList,
  setMpApiResult,
  setStateSearchState,
  setStateSearchClearIcon,
  setSelectedState,
  setMPSearchState,
  setMPSearchClearIcon,
  selectedMps,
  stateSearchClearIcon,
  setSelectedMps,
  stateSearchState,
  stateApiResult,
  mpSearchState,
  mpApiResult,
  stateData,
  sortBy,
}) => {
  const dispatch = useDispatch()
  const [tempSelectedState, setTempSelectedState] = useState(selectedState)
  const [tempSelectedMps, setTempSelectedMps] = useState(selectedMps)
  const mpidlist = []
  useEffect(() => {
    dispatch(getStateList())
  }, [])

  useEffect(() => {
    const states = tempSelectedState?.map((state) => {
      return state.state_name
    })
    dispatch(getAssignMpList(states.toString()))
  }, [tempSelectedState])

  useEffect(() => {
    const stateResult = stateData?.length > 0 ? [...stateData] : []
    stateResult.unshift({ id: 'all', state_name: 'All' })
    setStateApiResult(stateResult)
  }, [stateData])
  useEffect(() => {
    const mpResult = assignMpList?.mpAssignData?.length > 0 ? [...assignMpList.mpAssignData] : []
    mpResult.unshift({ id: 'all', user_name: 'All' })
    setMpApiResult(mpResult)
  }, [assignMpList?.mpAssignData])

  const handleStateSearch = (searchText) => {
    setStateSearchState(searchText)
    const stateResults = [...stateData]
    if (searchText.length > 0) {
      setStateSearchClearIcon(true)
      const results = stateResults.filter((item) => item.state_name.toLowerCase().includes(searchText.toLowerCase()))
      results.unshift({ id: 'all', state_name: 'All' })
      setStateApiResult(results)
    } else {
      setStateSearchClearIcon(false)
      stateResults.unshift({ id: 'all', state_name: 'All' })
      setStateApiResult(stateResults)
    }
  }
  const setUniqueStates = (mps) => {
    const uniqueStates = [...new Set(mps.map((item) => item.state_name))]
    setTempSelectedState([])
    uniqueStates.forEach((state) => {
      const index = stateData?.findIndex((item) => item.state_name.toLowerCase() == state.toLowerCase())
      const stateItem = stateData && stateData[index] ? stateData[index] : []
      setTempSelectedState((prev) => [...prev, { id: stateItem?.id, state_name: state }])
    })
  }
  const handleMPSearch = (searchText) => {
    setMPSearchState(searchText)
    const mpResults = [...assignMpList?.mpAssignData]
    if (searchText.length > 0) {
      setMPSearchClearIcon(true)
      const results = mpResults.filter((item) => item.user_name.toLowerCase().includes(searchText.toLowerCase()))
      results.unshift({ id: 'all', user_name: 'All' })
      setMpApiResult(results)
    } else {
      setMPSearchClearIcon(false)
      mpResults.unshift({ id: 'all', user_name: 'All' })
      setMpApiResult(assignMpList?.mpAssignData)
    }
  }
  const handlePost = () => {
    mpIdLists = []
    tempSelectedMps?.map((dataval) => {
      mpIdLists.push(dataval.id)
    })
    setMpIdLists(mpIdLists)
    setFilterOpen(false)
    dispatch(getDevelopementAcitivites(mpIdLists, sortBy))
  }
  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: '15px',
            height: 'auto',
            position: 'absolute',
            top: '20%',
            maxWidth: '650px !important',
          },
        }}
        fullScreen
        onClose={() => {
          // setStateSearchState('')
          // handleCloseAssingToMPDialog()
          // if (selectedMps.length === 0) {
          //   dispatch(getDevelopementAcitivites([], sortBy))
          //   setMpIdLists([])
          // }
          setFilterOpen(false)
        }}
        open={open}
      >
        <DialogTitle
          sx={{
            color: '#356f92 !important',
            fontWeight: 'bold !important',
            textAlign: 'center !important',
            fontSize: '1.8rem !important',
          }}
        >
          Filter By
        </DialogTitle>
        <TemplateDialogCloseButton
          onClick={async () => {
            // handleCloseAssingToMPDialog()
            // if (selectedMps.length === 0) {
            //   dispatch(getDevelopementAcitivites([], sortBy))
            //   setMpIdLists([])
            // }
            setFilterOpen(false)
          }}
        />

        <Box className="centered-container">
          <AssignMpSelectComponent
            assignMpList={tempSelectedMps}
            inputLabelTitle={'Select State'}
            stateSearchClearIcon={stateSearchClearIcon}
            selectedValue={tempSelectedState}
            setSelectedState={setTempSelectedState}
            setSelectedMps={setTempSelectedMps}
            clearIconOnClick={() => {
              handleStateSearch('')
              setStateSearchClearIcon(false)
              setStateSearchState('')
            }}
            inputBaseValue={stateSearchState}
            inputBaseOnChange={(e) => {
              handleStateSearch(e.target.value)
            }}
            stateApiResult={stateApiResult}
            stateData={stateData}
            placeholder={'Search State Name'}
          />

          <AssignMpSelectComponent
            inputLabelTitle={`Select MP's`}
            selectedValue={tempSelectedMps}
            setSelectedState={setTempSelectedState}
            setSelectedMps={setTempSelectedMps}
            disabled={tempSelectedState?.length === 0}
            clearIconOnClick={() => {
              setMPSearchClearIcon(false)
              setMPSearchState('')
              const mpResults = [...assignMpList?.mpAssignData]
              mpResults.unshift({ id: 'all', user_name: 'All' })
              setMpApiResult(assignMpList?.mpAssignData)
            }}
            inputBaseValue={mpSearchState}
            inputBaseOnChange={(e) => {
              handleMPSearch(e.target.value)
            }}
            placeholder={'Search MP Name'}
            mpApiResult={mpApiResult}
            assignMpList={assignMpList}
          />
        </Box>
        <React.Fragment>
          <TemplateDialogSubmitButton
            buttonSx={{
              p: 1,
              mr: 1,
              mt: 4,
              backgroundColor: '#ef7335',
              borderRadius: 4,
              position: 'relative',
              alignSelf: 'center',
              width: '300px',
              padding: '8px',
              marginBottom: '40px',
            }}
            buttonClassName={'button-primary-alt-contained'}
            title={'Done'}
            onClick={async () => {
              // let jsonObj = initiativeData
              // const mpIds = []
              // selectedMps.map((mp, i) => {
              //   mpIds.push(mp.id)
              // })
              // jsonObj.mplist = mpIds
              setSelectedMps(tempSelectedMps)
              setSelectedState(tempSelectedState)
              setUniqueStates(tempSelectedMps)
              // setInitiativeDetail(jsonObj)
              handlePost()
            }}
            disabled={tempSelectedMps?.length === 0}
            variant={'contained'}
            dialogActionSx={{ justifyContent: 'center' }}
          />
        </React.Fragment>
      </Dialog>
    </>
  )
}
export default Filter
