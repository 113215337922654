import React, { useEffect, useRef, useState } from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import SideMenu from '../SideMenu/SideMenu'
import TableHighlights from '../Highlights/TableHighlights'
import { Grid, Tabs, TextField, InputAdornment, Tooltip, Box, Typography, Avatar, Divider, Button } from '@mui/material'
import Search from '../MpHome/Search'
import DateFilter from '../ReusableComponents.js/DateFilter'
import { useDispatch, useSelector } from 'react-redux'
import { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import infoic from '../../asserts/images/Info.svg'
import { getMpList } from '../../store/action/mpList'
import StickyHeadTable from '../MpHome/MpTable'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import getUserProfile from '../../store/action/getUserProfile'
import searchIcon from '../../asserts/images/Search.svg'
import { getIds } from '../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../utils/AuditLogs'
import { useNavigate } from 'react-router-dom'
import AboutSevaScore from '../MpHome/AboutSevaScore'
import IntervalTabs from '../ReusableComponents.js/IntervalTabs'
import './AdminHome.css'
import ThreeCards from '../MpHome/ThreeCards'
import WhatsNewSection from '../ReusableComponents.js/WhatsNewSection'
import Marquee from 'react-fast-marquee'
import NotificationsList from '../ReusableComponents.js/NotificationList/NotificationsList'
const AdminHome = ({ readonly, userId }) => {
  const isBdayCampaignEnabled = JSON.parse(localStorage.getItem('isBdayCampaignEnabled') || false)
  const [cardClick, setCardClick] = useState(isBdayCampaignEnabled ? 'ecards' : 'sevaScore')
  const [tabactive, settabactive] = useState(isBdayCampaignEnabled ? 0 : 1)
  const [flag, setFlag] = useState(isBdayCampaignEnabled ? 100 : 101)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [dataFilter, setDataFilter] = useState('15days')
  const [showAboutSevaScore, setShowAboutSevaScore] = useState(false)
  const [isMarqueeVisible, setIsMarqueeVisible] = useState(false)
  const inputRef = useRef(null)
  const mpList = useSelector((state) => state?.mpList?.data)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    const lastData = JSON.parse(localStorage.getItem('leaderBoardSelectedItem') || '{}')
    const { flag: previouslySelected } = lastData
    //call api to update store
    dispatch(getUserProfile())
    dispatch(getMpList(previouslySelected || flag, 1, dataFilter))
  }, [flag, dataFilter])

  useEffect(() => {
    const lastData = JSON.parse(localStorage.getItem('leaderBoardSelectedItem') || '{}')
    const { tabId, card, flag, scrollDirection } = lastData
    setSelection(tabId, card, flag, scrollDirection, false)
  }, [])

  const containerRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollRight, setScrollRight] = useState(true)

  const handleMouseDown = (event) => {
    setIsDragging(true)
    setStartX(event.pageX - containerRef.current.offsetLeft)
    setScrollLeft(containerRef.current.scrollLeft)
  }

  useEffect(() => {
    setScrollLeft(containerRef.current.scrollLeft)
    const container = containerRef.current
    if (
      Math.floor(container.scrollLeft) + container.clientWidth == container.scrollWidth ||
      Math.floor(container.scrollLeft) + container.clientWidth + 1 == container.scrollWidth ||
      Math.ceil(container.scrollLeft) + container.clientWidth == container.scrollWidth
    ) {
      setScrollRight(false)
    } else {
      setScrollRight(true)
    }
  }, [containerRef?.current?.scrollLeft])

  useEffect(() => {
    if (!isSearchOpen) {
      inputRef.current.blur()
    }
  }, [isSearchOpen])

  useEffect(() => {
    const pageLoad = async () => {
      const parameters = {
        useridtype: getIds(),
        section: cardClick,
      }
      await makeLogRequest('mp_leaderboard', 'pageload', parameters)
    }
    pageLoad()
  }, [])

  const sendSearchLog = async () => {
    const parameters = {
      useridtype: getIds(),
    }
    await makeLogRequest('mp_leaderboard', 'search', parameters)
  }

  const handleMouseMove = (event) => {
    if (!isDragging) return
    event.preventDefault()
    const x = event.pageX - containerRef.current.offsetLeft
    const distance = (x - startX) * 2
    containerRef.current.scrollLeft = scrollLeft - distance
  }

  const scrollLeftTab = () => {
    const container = containerRef.current
    if (
      Math.floor(container.scrollLeft) + container.clientWidth == container.scrollWidth ||
      Math.floor(container.scrollLeft) + container.clientWidth + 1 == container.scrollWidth ||
      Math.ceil(container.scrollLeft) + container.clientWidth == container.scrollWidth
    ) {
      setScrollRight(false)
    } else {
      setScrollRight(true)
    }
    if (container.scrollLeft > 0) {
      containerRef.current.scrollLeft = containerRef?.current?.scrollLeft - container.offsetWidth / 2
      // container.scrollTo({
      //   left: container.scrollLeft - container.offsetWidth / 2,
      //   behavior: "smooth",
      // });
    }
  }

  const scrollRightTab = () => {
    const container = containerRef.current
    if (
      Math.floor(container.scrollLeft) + container.clientWidth == container.scrollWidth ||
      Math.floor(container.scrollLeft) + container.clientWidth + 1 == container.scrollWidth ||
      Math.ceil(container.scrollLeft) + container.clientWidth == container.scrollWidth
    ) {
      setScrollRight(false)
    } else {
      setScrollRight(true)
    }
    if (container.scrollLeft < container.scrollWidth - container.offsetWidth) {
      container.scrollTo({
        left: container.scrollLeft + container.offsetWidth / 2,
        behavior: 'smooth',
      })
    }
  }
  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const defaultTab = isBdayCampaignEnabled ? 0 : 1
  const defaultCard = isBdayCampaignEnabled ? 'ecards' : 'sevaScore'
  const defaultFlag = isBdayCampaignEnabled ? 100 : 101

  const setSelection = (
    tabId = defaultTab,
    card = defaultCard,
    flag = defaultFlag,
    scrollDirection = 'left',
    saveSelection = true
  ) => {
    settabactive(tabId)
    setCardClick(card)
    setFlag(flag)
    scrollDirection === 'left' ? scrollLeftTab() : scrollRightTab()
    saveSelection &&
      localStorage.setItem('leaderBoardSelectedItem', JSON.stringify({ tabId, card, flag, scrollDirection }))
  }

  const openAboutSevaScorePopup = () => {
    setShowAboutSevaScore(true)
  }

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #356F92',
      },
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: '1px solid #356F92',
      boxShadow: theme.shadows[1],
      width: 200,
      height: 70,
      borderRadius: '5px',
    },
  }))

  const BootstrapTooltip1 = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #356F92',
      },
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: '1px solid #356F92',
      boxShadow: theme.shadows[1],
      width: 220,
      borderRadius: '15px',
    },
  }))

  return (
    <Box className="page-wrapper d-flex">
      <SideMenu active="Leader" user={readonly ? 'Leader' : 'Admin'} />
      <Box className="main-wrapper customscroll adminhome-container">
        <NotificationsList setIsMarqueeVisible={setIsMarqueeVisible} isMarqueeVisible={isMarqueeVisible} />
        <Box className="d-flex align-items-center">
          <Box className="d-flex-gap align-items-center leaderboard-header-title-box-width">
            <Typography
              component="h1"
              className="leaderboard-page-title mb-0 text-overflow-clip"
              sx={{ fontSize: '24px' }}
            >
              Leaderboard
            </Typography>
            {cardClick === 'ecards' && (
              <BootstrapTooltip1
                title={
                  'The Seva Pakhwada E-Card campaign will run for a duration of 15 days. The E-Card Campaign Score is established by aggregating the Likes and Wishes received on E-Cards created by the MP'
                }
                placement="right"
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: '16px',
                      fontFamily: 'HK Grotesk',
                      padding: '10px',
                      textAlign: 'center',
                    },
                  },
                }}
              >
                <Avatar variant="square" src={infoic} className="info-ecard" />
              </BootstrapTooltip1>
            )}
            {cardClick === 'sevaScore' && (
              <Typography
                component="span"
                className="about-seva-score-hyperlink user-select-none w-100 ml-2"
                onClick={openAboutSevaScorePopup}
                sx={{ fontFamily: 'HK Grotesk Bold' }}
              >
                About Seva Score
              </Typography>
            )}
            {showAboutSevaScore && (
              <AboutSevaScore showAboutSevaScore={showAboutSevaScore} setShowAboutSevaScore={setShowAboutSevaScore} />
            )}
          </Box>
          <Box className="d-flex pr-4">
            <Box className="d-flex mr-4">
              <IntervalTabs setDataFilter={setDataFilter} />
              <BootstrapTooltip1
                title={
                  dataFilter === '15days'
                    ? 'Showing results of last 15 days. Refreshes everyday at midnight.'
                    : 'Showing results of all time. Refreshes everyday at midnight.'
                }
                placement="bottom"
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: '16px',
                      fontFamily: 'HK Grotesk',
                      padding: '10px',
                      textAlign: 'center',
                    },
                  },
                }}
              >
                <Avatar variant="square" src={infoic} className="info-icon" />
              </BootstrapTooltip1>
            </Box>
            <Box>
              <TextField
                className="search-filter-icon cursorshow"
                sx={{
                  '& fieldset': { border: 'none' },
                }}
                onClick={(e) => {
                  setIsSearchOpen(true)
                }}
                inputRef={inputRef}
                placeholder="Search MP / Constituency"
                onFocus={(e) => {
                  sendSearchLog()
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {/* search icon  */}
                      <Avatar
                        variant="square"
                        className="cursorshow search-icon"
                        alt="Search"
                        src={searchIcon}
                        onClick={() => {
                          setIsSearchOpen(true)
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              >
                {' '}
              </TextField>
              {isSearchOpen && (
                <Search
                  marginRightValue={'-290'}
                  marginTopValue={userId != 2 && isMarqueeVisible ? '68' : '-15'}
                  inputValue={isSearchOpen}
                  clearParentTextField={setIsSearchOpen}
                />
              )}
            </Box>
          </Box>
        </Box>
        {/* <DateFilter /> */}
        <Grid container className="mpHomeContainer">
          <Grid md={8} lg={8} xl={8} xs={10}>
            <Box className="filter-btns no-gutters pt-3 leaderboard-options-outer-container">
              {/* {props.user!=="Admin" && */}
              <ChevronLeft
                sx={{
                  color: scrollLeft === 0 ? 'grey' : 'black',
                  cursor: scrollLeft !== 0 && 'pointer',
                }}
                onClick={scrollLeftTab}
              />{' '}
              <Tabs
                variant="scrollable"
                scrollButtons={false}
                aria-label="scrollable auto tabs example"
                sx={{ width: '94%' }}
              >
                <Box
                  aria-label="scrollable auto tabs example"
                  ref={containerRef}
                  className="leaderBoardScrollbar leaderboard-options-inner-container"
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseUp={handleMouseUp}
                  onMouseLeave={handleMouseUp}
                >
                  {isBdayCampaignEnabled && (
                    <Button
                      type="button"
                      className={
                        tabactive === 0
                          ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                          : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                      }
                      onClick={() => {
                        setSelection(0, 'ecards', 100, 'left')
                      }}
                    >
                      E-Cards Campaign
                    </Button>
                  )}
                  <Button
                    type="button"
                    className={
                      tabactive === 1
                        ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                        : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                    }
                    onClick={() => {
                      setSelection(1, 'sevaScore', 101, 'left')
                    }}
                  >
                    MP Seva Score
                  </Button>
                  <Button
                    type="button"
                    className={
                      tabactive === 2
                        ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                        : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                    }
                    onClick={() => {
                      setSelection(2, 'initiatives', 102, 'left')
                    }}
                  >
                    Initiatives
                  </Button>
                  <Button
                    type="button"
                    className={
                      tabactive === 3
                        ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                        : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                    }
                    onClick={() => {
                      setSelection(3, 'memberAdded', 103, 'left')
                    }}
                  >
                    Members Added
                  </Button>
                  {/* <button
                    type="button"
                    className={
                      tabactive === 4
                        ? "btn btn-primary primary-btn mr-3 mt-1 mb-1"
                        : "btn btn-primary normal-btn mr-3 mt-1 mb-1"
                    }
                    onClick={() => {
                      setSelection(4, 'followers', 104, 'left')
                    }}
                  >
                    Followers
                  </button> */}
                  <Button
                    type="button"
                    className={
                      tabactive === 5
                        ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                        : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                    }
                    onClick={() => {
                      setSelection(5, 'twitterPerformance', 105, 'right')
                    }}
                  >
                    Twitter Performance
                  </Button>
                  <Button
                    type="button"
                    className={
                      tabactive === 6
                        ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                        : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                    }
                    onClick={() => {
                      setSelection(6, 'mediaCoverage', 106, 'right')
                    }}
                  >
                    Op-Eds & Media Coverage
                  </Button>
                  <Button
                    type="button"
                    className={
                      tabactive === 7
                        ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                        : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                    }
                    onClick={() => {
                      setSelection(7, 'donation', 107, 'right')
                    }}
                  >
                    Donations
                  </Button>
                </Box>
              </Tabs>
              <ChevronRight
                sx={{
                  color: !scrollRight ? 'grey' : 'black',
                  cursor: scrollRight && 'pointer',
                }}
                onClick={scrollRightTab}
              />
            </Box>

            <Divider className="mt-2 mb-4" />
            <ThreeCards cardClick={cardClick} user={readonly ? 'Leader' : 'Admin'} />
            <Box className="mt-5 learders-list">
              <StickyHeadTable
                cardClick={cardClick}
                flag={flag}
                count={1}
                user={readonly ? 'Leader' : 'Admin'}
                dataFilter={dataFilter}
              />
            </Box>
          </Grid>
          <Grid md={6} lg={4} xl={4} sx={{ pl: '16px' }}>
            <Box className="rtcar">
              <Box className="right-card-2 mb-3" onClick={() => navigate('/SevaUpdates', { state: { user: userId } })}>
                <Box className="highlights-title">
                  <Typography component="h1" className="fs-30" sx={{ fontSize: '30px', fontFamily: 'HK Grotesk' }}>
                    Highlights
                  </Typography>
                  <Typography
                    component="p"
                    className="mb-0 highlightBodyText"
                    sx={{ fontSize: '24px', fontFamily: 'HK Grotesk' }}
                  >
                    All India Seva Initiatives
                  </Typography>
                </Box>
                <TableHighlights dataFilter={dataFilter} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default AdminHome
