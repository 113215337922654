import { uploadMediaFiles } from '../store/action/uploadMedia'
import axiosInstance from '../utils/api'
import axios from 'axios'
const getValuesByKey = (obj, key) => {
  return Object.keys(obj).reduce((acc, curr) => {
    if (curr === key) {
      acc.push(obj[curr])
    } else if (typeof obj[curr] === 'object' && obj[curr] !== null) {
      acc.push(...getValuesByKey(obj[curr], key))
    }
    return acc
  }, [])
}
export const uploadMedia = async (dispatch, filesObj, withKey = false) => {
  const tkn = localStorage.getItem('tokenDetails')
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${tkn}`,
    },
  }
  const { coverImage, imageFiles, videoFiles, stepPdf } = filesObj

  const mediaFormData = new FormData()
  if (coverImage) {
    mediaFormData.append(`coverimage`, coverImage)
  }
  if (stepPdf) {
    mediaFormData.append(`stepPdf`, stepPdf)
  }
  for (let i = 0; i < imageFiles?.length; i++) {
    mediaFormData.append(`media`, imageFiles[i])
  }
  for (let i = 0; i < videoFiles?.length; i++) {
    mediaFormData.append(`media`, videoFiles[i])
  }
  let result = []
  if (filesObj) {
    try {
      result = await axiosInstance
        .post(`/api/url`, mediaFormData, config)
        .then((response) => {
          const result = response.data
          return response
        })
        .catch((error) => {
          const errorMsg = error.message
          console.log('🚀 ~ file: mediaUpload.js:44 ~ uploadMedia ~ errorMsg:', errorMsg)
        })
    } catch (error) {
      console.log('🚀 ~ file: mediaUpload.js:55 ~ uploadMedia ~ error:', error)
    }
  }
  if (withKey) {
    return {
      coverImageUrl: result?.data?.response?.urlCollection || [],
      imageUrls: result?.data?.response?.urlCollection || [],
      videoUrls: result?.data?.response?.urlCollection || [],
      stepPdfUrl: result?.data?.response?.urlCollection || [],
    }
  }
  const coverImageUrl = getValuesByKey(result?.data?.response?.urlCollection || {}, 'coverimage')[0] || ''
  const imageUrls = getValuesByKey(result?.data?.response?.urlCollection || {}, 'media') || []
  const videoUrls = getValuesByKey(result?.data?.response?.urlCollection || {}, 'media') || []
  const stepPdfUrl = getValuesByKey(result?.data?.response?.urlCollection || {}, 'stepPdf')[0] || ''
  return { coverImageUrl, imageUrls, stepPdfUrl }
}

export const uploadFile = async (selectedFile, name = null) => {
  const fetchPreSignedUrl = async (fileName, type = 'video/mp4') => {
    const token = localStorage.getItem('tokenDetails')
    const Authorization = token ? `Bearer ${token}` : ''
    const response = await axiosInstance.get(`/api/uploadurl?filename=${fileName}&type=${type}`, {
      headers: {
        Authorization: Authorization,
      },
    })
    return response.data
  }

  const uploadPromises = []
  const videoUrls = []

  // if (selectedFile?.videoFiles[0] === undefined) return { videoUrls }

  for (let i = 0; i < selectedFile?.videoFiles?.length; i++) {
    if (selectedFile?.videoFiles[i] !== undefined) {
      const fileName = name ? name : selectedFile?.videoFiles[i].name
      const response = await fetchPreSignedUrl(`${fileName}`) // fileName
      uploadPromises.push(axios.put(response.uploadUrl, selectedFile?.videoFiles[i]))
      videoUrls.push(response.fileUrl)
    }
  }
  try {
    const uploadFiles = await Promise.all(uploadPromises)
    console.log('File uploaded successfully!', uploadFiles)
    return { videoUrls: videoUrls }
  } catch (error) {
    console.error('Error uploading file:', error)
  }
}
