import React from 'react'
import Dialog from '@mui/material/Dialog'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import playicon from '../../../asserts/images/Play.svg'
import { useState } from 'react'
import { Box, IconButton } from '@mui/material'
import CrossIcon from '../../../asserts/images/Close.svg'
import Arrow from '../../../asserts/images/previmg.png'
import DisabledArrow from '../../../asserts/images/disabled-previmg.png'
import './Preview.css'
const PreviewImagesDialog = ({ openPreviewImages, handleClosePreviewImages, files, previewImageIndex }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(previewImageIndex || 0)
  const handlePreviewThumbs = (file) => {
    onChangeEvent(selectedImageIndex)
    if (file.type === 'image') {
      return <img src={typeof file === 'string' ? file : file.url} alt="Preview" className="form-img__img-preview-1" />
    }
    if (file.type === 'video') {
      return (
        <video controls className="form-img__img-preview-1">
          <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }
    const images = ['jpg', 'jpeg', 'gif', 'png', 'svg', 'jfif']
    const videos = ['mp4', '3gp', 'ogg']
    const url = new URL(file)
    const extension = url.search.split('.')[1]
    const pathExtension = url?.pathname?.slice(url.pathname.lastIndexOf('.') + 1, url.pathname.length) || ''
    if (images.includes(extension) || images.includes(pathExtension)) {
      return <img src={file} alt="Preview" className="form-img__img-preview" />
    }
    if (videos.includes(extension) || videos.includes(pathExtension)) {
      return (
        <Box>
          {!isHidden && <img src={playicon} className="play-buttonthumb" onClick={handleHideImage} />}
          <video className="videothumb">
            <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Box>
      )
    }
  }

  const [isHidden, setIsHidden] = useState(false)
  function handleHideImage() {
    setIsHidden(true)
  }
  const handlePreview = (file) => {
    // const images = ["jpg", "gif", "png", "svg"]
    // const videos = ["mp4", "3gp", "ogg"]
    // const url = new URL(file)
    // const extension = url.pathname.split(".")[1]
    // if (images.includes(extension)) {
    //     return <img src={file} alt="Preview" />;
    // }
    // else if (videos.includes(extension)) {
    //     return (
    //         <video controls>
    //             <source src={file} type="video/mp4" />
    //             {/* Your browser does not support the video tag. */}
    //         </video>
    //     );
    // }
    if (file.type === 'image') {
      return <img src={typeof file === 'string' ? file : file.url} alt="Preview" className="" />
    }
    if (file.type === 'video') {
      return (
        <video controls className="">
          <source src={typeof file === 'string' ? file : file.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )
    }
    const images = ['jpg', 'jpeg', 'gif', 'png', 'svg', 'jfif']
    const videos = ['mp4', '3gp', 'ogg']
    const url = new URL(file)
    const extension = url.search.split('.')[1]
    const pathExtension = url?.pathname?.slice(url.pathname.lastIndexOf('.') + 1, url.pathname.length) || ''
    if (images.includes(extension) || images.includes(pathExtension)) {
      return (
        <Box
          className="user-profile-img__background"
          sx={{
            backgroundImage: `url(${file})`,
          }}
        >
          <img src={file} alt="Preview" className="form-img__img-preview preview-asset-height" />
        </Box>
      )
    }
    if (videos.includes(extension) || videos.includes(pathExtension)) {
      return (
        <>
          <Box className="postabsvideo">
            {!isHidden && <img src={playicon} className="play-button" onClick={handleHideImage} />}
            <video controls onClick={handleHideImage}>
              <source
                src={typeof file === 'string' ? file : file.url}
                type="video/mp4"
                className="preview-asset-height"
              />
              Your browser does not support the video tag.
            </video>
          </Box>
        </>
      )
    }
  }

  const onChangeEvent = (index) => {
    setTimeout(function () {
      var element = document.getElementsByClassName('thumb')[index]
      element.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case 37: // left arrow
        if (selectedImageIndex > 0) {
          setSelectedImageIndex((prevIndex) => prevIndex - 1)
        }
        break
      case 39: // right arrow
        if (selectedImageIndex < files.length - 1) {
          setSelectedImageIndex((prevIndex) => prevIndex + 1)
        }
        break
      default:
        break
    }
  }
  const handleRightArrow = () => {
    if (files?.length - 1 === selectedImageIndex) return
    setSelectedImageIndex((prevIndex) => prevIndex + 1)
  }
  const handleLeftArrow = () => {
    if (selectedImageIndex === 0) return
    setSelectedImageIndex((prevIndex) => prevIndex - 1)
  }

  return (
    <>
      <Dialog
        open={openPreviewImages}
        onClose={handleClosePreviewImages}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0,0,0,0.2)',
            mt: '-5px',
          },
        }}
        onKeyDown={handleKeyDown}
      >
        {/* <button className="close-button" onClick={handleClosePreviewImages}>
                <CloseIcon />
            </button> */}
        <img onClick={handleClosePreviewImages} src={CrossIcon} className="preview-asset-cross-icon" />
        <img src={selectedImageIndex === 0 ? DisabledArrow : Arrow} className="arrow-left" onClick={handleLeftArrow} />
        <img
          src={selectedImageIndex === files?.length - 1 ? DisabledArrow : Arrow}
          className="arrow-right"
          onClick={handleRightArrow}
        />

        <Carousel
          showArrows={false}
          className="dialogopac1"
          selectedItem={selectedImageIndex}
          onChange={onChangeEvent}
          renderThumbs={() =>
            files &&
            files?.map((file, index) => (
              <Box key={index} onClick={() => setSelectedImageIndex(index)}>
                {handlePreviewThumbs(file)}
              </Box>
            ))
          }
        >
          {files &&
            files?.map((file, index) => (
              <Box className="selected-img cursorshow" key={index}>
                {handlePreview(file)}
              </Box>
            ))}
        </Carousel>
      </Dialog>
    </>
  )
}

export default PreviewImagesDialog
