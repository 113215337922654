import { Card, CardMedia, CardContent, Box, Typography, Divider } from '@mui/material'
import NoImageFound from 'asserts/images/noImageFound.jpg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ShareIcon from '@mui/icons-material/Share'
import Moment from 'moment'
import { useLocation } from 'react-router-dom'
import { getIds } from 'Components/ReusableComponents.js/getIds'
import 'Components/ViewAllScreenRefactored/ViewAllScreenCard/ViewAllScreenCard.css'
import ProjectStatus from 'Components/ReusableComponents.js/ProjectStatus'
import { formatUtcDateToLocal, getCoverImageThumbnail } from 'utils/helper'

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const ViewAllScreenCard = ({
  item,
  index,
  handleOpenInitiativeDetails,
  viewAllTitle,
  viewAllTitleMap,
  sendWallLogs,
  setMediaShare,
  setShareData,
}) => {
  const thumbnailImage = getCoverImageThumbnail(item)
  const location = useLocation()
  const title = location?.state?.title || 'Seva aur Samarpan Campaign'
  const eventsForInitiative = location?.state?.eventsForInitiative
  const colorSetter = 'dark'
  const handleShare = (e, data) => {
    const parameters = {
      useridtype: getIds(),
    }
    switch (viewAllTitleMap[viewAllTitle]) {
      case 2:
        parameters.e_id = data.id
        break
      case 3:
        parameters.bm_id = data.id
        break
      case 4:
        parameters.dp_id = data.id
        break
      case 5:
        parameters.m_id = data.id
        break
      default:
        break
    }
    sendWallLogs('share', parameters)
    e.stopPropagation()
    e.preventDefault()
    setShareData(data)
    setMediaShare(true)
  }

  const truncateText = (text, characterLimit) => {
    if (text?.length > characterLimit) {
      const words = text.split(' ')
      let truncatedText = ''

      for (const word of words) {
        if (truncatedText.length + word.length + 1 <= characterLimit) {
          truncatedText += `${word} `
        } else {
          if (truncatedText.length === 0) {
            return `${word.slice(0, 19)}...`
          }
          break
        }
      }
      return `${truncatedText.trim()}...`
    }
    return text
  }

  const isMediaVideo = () => {
    return (
      title !== 'Seva aur Samarpan Campaign' &&
      !eventsForInitiative &&
      item?.media &&
      thumbnailImage?.endsWith('.mp4#t=0.1')
    )
  }

  return (
    <Card key={item?.id} className="cursorshow cardStyle" onClick={() => handleOpenInitiativeDetails(item, title)}>
      <CardMedia
        className="image2acrd cardmedia-dimension border-radius-14"
        component={isMediaVideo() ? 'video' : 'img'}
        autoPlay={false}
        onError={(e) => (e.target.src = NoImageFound)}
        src={
          title === 'Seva aur Samarpan Campaign' || eventsForInitiative
            ? item?.coverimage && JSON.parse(item?.coverimage)[0]
            : thumbnailImage
        }
        id={item?.id}
        loading="lazy"
        alt="new Image"
      />
      <ShareIcon
        className={`shareicone2 customshare ${isSafari ? 'mt-3' : ''}`}
        key={index}
        onClick={(e) => {
          handleShare(e, item)
        }}
      />
      <CardContent sx={{ minHeight: '150px', maxHeight: '150px' }}>
        <Box className="mt-n2-1">
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 'bold',
              fontFamily: 'HK Grotesk',
              color: '#505050',
            }}
            className="ellipsewehe12-qw "
          >
            {viewAllTitleMap[viewAllTitle] === 2 || eventsForInitiative
              ? truncateText(item.eventTitle, 25)
              : viewAllTitleMap[viewAllTitle] === 5 || viewAllTitleMap[viewAllTitle] === 3
              ? item?.title
              : item?.projecttitle}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              fontFamily: 'HK Grotesk',
              color: '#505050',
            }}
          >
            {viewAllTitleMap[viewAllTitle] === 5 || viewAllTitleMap[viewAllTitle] === 3 ? (
              item?.type
            ) : viewAllTitleMap[viewAllTitle] === 2 || eventsForInitiative ? (
              truncateText(item?.location, 30)
            ) : (
              <ProjectStatus status={item?.status} colorSetter={colorSetter} />
            )}
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              fontFamily: 'HK Grotesk',
              color: '#505050',
            }}
          >
            Date- {item.statTime ? formatUtcDateToLocal(item.statTime) : Moment(item?.createdAt).format('DD/MM/YYYY')}
          </Typography>
          <Divider sx={{ padding: '5px' }} />
          <Box className="display-margin">
            <Box>
              {item?.user?.user_avatar ? (
                <CardMedia
                  component="img"
                  alt="DP"
                  key={index}
                  src={item?.user?.user_avatar}
                  className="img-circle viewAllScreenCard-leader-circle-img mr-1 circularimage2 width-margin"
                />
              ) : (
                <Box className="text-center">
                  <AccountCircleIcon
                    sx={{
                      fontSize: 'xx-large',
                      width: '50px',
                      height: 'auto',
                      border: 0,
                      color: '#EEEEEE',
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box className="card-details-container">
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  fontFamily: 'HK Grotesk',
                  color: '#356F92',
                }}
              >
                {item.user?.user_name}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  fontFamily: 'HK Grotesk',
                  color: '#797979',
                }}
              >
                {item.user?.designation}
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  fontFamily: 'HK Grotesk',
                  color: '#797979',
                }}
              >
                {item.user?.constituency_name} - {item.user?.state_name}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ViewAllScreenCard
