import React from 'react'
import { useEffect } from 'react'
import { LoaderIcon } from 'react-hot-toast'
import './Login.css'
import { env } from '../../env'
import Box from '@mui/material/Box'
import { Typography, Link } from '@mui/material'

function Login({ setId, paramId, loading }) {
  useEffect(() => {
    setTimeout(() => {
      let redirect =
        env.REACT_APP_TESTLOGIN !== '1'
          ? document.getElementById('login')
          : document.getElementById('testlogin')
      if (redirect && !paramId) {
        redirect.click()
      }
    }, [1500])
  }, [])

  return (
    <>
      {loading || paramId ? (
        <Box className="loader">
          <LoaderIcon />
          <Typography component="span">Loading...</Typography>
        </Box>
      ) : (
        <Box className="text-wrapper-login">
          <Box className="isi">Redirecting to Login Page...</Box>
          <Box className="btncontainer">
            <Link
              id="login"
              className="d-none"
              href={env.REACT_APP_WEBSITE_URL}
            >
              Go to Login
            </Link>
            <Link id="testlogin" className="d-none" href="/LoginUsers">
              Test Login
            </Link>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Login
