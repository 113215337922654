import { Box, Typography } from '@mui/material'
import './HighlightsText.css'

const HighlightsText = () => {
  return (
    <Box className="highlights-title">
      <Typography className="fs-30" component="h1" sx={{ fontSize: '30px', fontFamily: 'HK Grotesk' }}>
        Highlights
      </Typography>
      <Typography component="p" className="mb-0 highlightBodyText" sx={{ fontSize: '24px', fontFamily: 'HK Grotesk' }}>
        All India Seva Initiatives
      </Typography>
    </Box>
  )
}

export default HighlightsText
