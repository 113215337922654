import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from 'redux-logger';
import logger from 'redux-logger';
import reducers from './rootReducers';

const middleware = [thunkMiddleware];
const disableLoggerEnvs = ['UAT', 'PROD'];

// if(!disableLoggerEnvs.includes(process.env.REACT_APP_ENVIRONMENT)){
//     middleware.push(loggerMiddleware)
// }

const store = createStore(reducers, composeWithDevTools(applyMiddleware(...middleware)));

export default store;
