import { Avatar, Box } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

const ProfilePictureComponent = ({ ProfilPic, sxCircleIcon, sxProfile, alt }) => {
  return (
    <Box>
      {ProfilPic ? (
        <Avatar
          src={ProfilPic}
          draggable="false"
          alt={alt}
          sx={{
            border: '1px solid #b6b6b6',
            ...sxProfile,
          }}
        />
      ) : (
        <AccountCircleIcon
          sx={{
            color: '#EEEEEE',
            fontSize: 'xx-large',
            ...sxProfile,
            ...sxCircleIcon,
          }}
        />
      )}
    </Box>
  )
}
export default ProfilePictureComponent
