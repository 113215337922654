import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { NotificationProvider } from './utils/NotificationContext';
import { InitiativesDataProvider } from './utils/InitiativeContext';
import { LoadingProvider } from './utils/LoadingContext';
import { Toaster } from 'react-hot-toast';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <LoadingProvider>
        <NotificationProvider>
          <InitiativesDataProvider>
            <App />
            <Toaster />
          </InitiativesDataProvider>
        </NotificationProvider>
      </LoadingProvider>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
