import { Box, Typography, Avatar } from '@mui/material'
import { useState } from 'react'
import AboutSevaScore from '../AboutSevaScore/AboutSevaScore'
import './LeaderBoardText.css'
import infoic from '../../../asserts/images/Info.svg'
import { BootstrapTooltip1 } from '../../ReusableComponents.js/ToolTip/ToolTipComponent'

const LeaderBoardText = ({ title, selectedCard }) => {
  const [showAboutSevaScore, setShowAboutSevaScore] = useState(false)

  const openAboutSevaScorePopup = () => {
    setShowAboutSevaScore(true)
  }

  return (
    <Box className="d-flex-gap align-items-center leaderboard-header-title-box-width">
      <Typography component="h1" className="leaderboard-page-title mb-0 text-overflow-clip">
        {title}
      </Typography>
      {selectedCard === 'sevaScore' && (
        <Typography
          component="span"
          className="about-seva-score-hyperlink user-select-none"
          onClick={openAboutSevaScorePopup}
          sx={{ fontFamily: 'HK Grotesk Bold' }}
        >
          About Seva Score
        </Typography>
      )}
      {selectedCard === 'survey' && (
        <BootstrapTooltip1
          title={
            'Jan Man Surveys completed in constituency are mapped to the Hon. MP Data shown is from the launch of Jan Man Survey in the Narendra Modi app.'
          }
          placement="right"
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '16px',
                fontFamily: 'HK Grotesk',
                padding: '10px',
                textAlign: 'center',
              },
            },
          }}
        >
          <Avatar variant="square" src={infoic} className="info-ecard" />
        </BootstrapTooltip1>
      )}
      {selectedCard === 'ecards' && (
        <BootstrapTooltip1
          title={
            'The Seva Pakhwada E-Card campaign will run for a duration of 15 days. The E-Card Campaign Score is established by aggregating the Likes and Wishes received on E-Cards created by the MP'
          }
          placement="right"
          componentsProps={{
            tooltip: {
              sx: {
                fontSize: '16px',
                fontFamily: 'HK Grotesk',
                padding: '10px',
                textAlign: 'center',
              },
            },
          }}
        >
          <Avatar variant="square" src={infoic} className="info-ecard" />
        </BootstrapTooltip1>
      )}
      {showAboutSevaScore && (
        <AboutSevaScore showAboutSevaScore={showAboutSevaScore} setShowAboutSevaScore={setShowAboutSevaScore} />
      )}
    </Box>
  )
}

export default LeaderBoardText
