import { FETCH_LANGUAGE_LIST_REQUEST, FETCH_LANGUAGE_LIST_SUCCESS, FETCH_LANGUAGE_LIST_FAILURE } from './types';
import axiosInstance from '../../utils/api';

export const fetchLanguageListRequest = () => {
  return {
    type: FETCH_LANGUAGE_LIST_REQUEST,
  };
};
export const fetchLanguageListSuccess = (data) => {
  return {
    type: FETCH_LANGUAGE_LIST_SUCCESS,
    payload: data,
  };
};
export const fetchLanguageListFailure = (error) => {
  return {
    type: FETCH_LANGUAGE_LIST_FAILURE,
    payload: error,
  };
};

export const getLanguageList = () => async (dispatch) => {
  dispatch(fetchLanguageListRequest);
  await axiosInstance
    .get(`/api/languages/getall/${0}`)
    .then((response) => {
      const result = response.data;
      // const result = mpList
      dispatch(fetchLanguageListSuccess(result));
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(fetchLanguageListFailure(errorMsg));
    });
};
