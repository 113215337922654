import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Paper,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Box,
  ImageListItem,
} from '@mui/material'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import { BootstrapTooltip } from '../../ReusableComponents.js/ToolTip/ToolTipComponent'
import infoic from '../../../asserts/images/Info.svg'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import Table from '@mui/material/Table'
import { ImageLoader } from '../../ReusableComponents.js/ImageLoader'
import { handleFileName, handleSrc, handleVideoSrc } from '../../ReusableComponents.js/reuseMethods'
import zipIcon from '../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../asserts/images/FileIcon.svg'
import unknownImg from '../../../asserts/images/folders.png'
import { useMemo } from 'react'
import AccordionComponentEditButton from '../AccordionComponentEditButton/AccordionComponentEditButton'
import './AccordionComponent.css'
import { VideoLoader } from '../../ReusableComponents.js/VideoLoader'

const AccordionComponent = ({
  expanded,
  onClickForAccordion,
  onClickForAccordionSummary,
  isTextTemplateErrorVisible,
  isImageTemplateErrorVisible,
  isVideoTemplateErrorVisible,
  AccordionSummaryTitle,
  onClickForEditButton,
  textData,
  editDetails,
  imageData,
  videoData,
  addVideoExpand,
  tooltipTitle,
}) => {
  const videos = useMemo(() => {
    return videoData?.length ? videoData[0] : []
  }, [videoData])

  const images = useMemo(() => {
    return imageData?.length ? imageData[0] : []
  }, [imageData])

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F5F6FA',
      color: '#2e739c',
      fontWeight: 'bold',
      fontSize: 18,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 16,
      fontWeight: 'bold',
      backgroundColor: '#fff',
      color: '#505050',
    },
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }))

  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '14px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )

  return (
    <Accordion
      className="bradiusaccord"
      sx={{
        backgroundColor: '#E3F5FF',
        mb: 2,
        fontFamily: 'HK Grotesk',
        mt: 2,
        border: '0',
        color: '#505050',
        '&:before': {
          display: 'none',
        },
      }}
      onClick={onClickForAccordion}
      expanded={expanded}
    >
      <AccordionSummary
        expandIcon={expanded ? <RemoveIcon color="primary" /> : <AddIcon color="primary" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={onClickForAccordionSummary}
      >
        <Typography sx={{ fontFamily: 'HK Grotesk', fontWeight: 'bold' }}>
          {AccordionSummaryTitle}
          <BootstrapTooltip
            title={tooltipTitle}
            placement="right"
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '18px',
                  fontFamily: 'HK Grotesk',
                  padding: '15px',
                },
              },
            }}
          >
            <img src={infoic} className="tooltip-div-st" />
          </BootstrapTooltip>

          <Typography
            component="span"
            className={`${
              isImageTemplateErrorVisible || isVideoTemplateErrorVisible ? 'error-msg ml-5rem' : 'error-msg1'
            }`}
          >
            {isTextTemplateErrorVisible
              ? 'Please add text templates'
              : isImageTemplateErrorVisible
              ? 'Please add image templates'
              : isVideoTemplateErrorVisible && 'Please add video templates'}
          </Typography>
        </Typography>
      </AccordionSummary>
      {AccordionSummaryTitle === 'Add Text Templates' ? (
        textData?.length > 0 || (editDetails && editDetails?.texttemplates?.length > 0) ? (
          <AccordionDetails className="acc-st">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 810, tableLayout: 'fixed' }} aria-label="customized table">
                <TableHead>
                  <StyledTableCell sx={{ fontFamily: 'HK Grotesk' }}>Language</StyledTableCell>
                  <StyledTableCell sx={{ fontFamily: 'HK Grotesk' }}>Text</StyledTableCell>
                  <StyledTableCell sx={{ fontFamily: 'HK Grotesk' }}>Hashtags</StyledTableCell>
                </TableHead>
                <TableBody>
                  {textData.map((row, index) => (
                    <StyledTableRow key={`${row?.id}_${index}`}>
                      <StyledTableCell component="th" scope="row">
                        {row?.language ? row?.language : row?.language}
                      </StyledTableCell>
                      <StyledTableCell title={row?.text ? row.text : ''}>
                        {typeof row?.text === 'string' ? row?.text?.slice(0, 25) || '-' : row?.text}
                        {typeof row?.text === 'string' ? (row?.text && row?.text?.length > 25 ? '...' : '') : row?.text}
                      </StyledTableCell>
                      <StyledTableCell className="hashtagEllipse" title={row?.hashtag ? row.hashtag : ''}>
                        {row?.hashtag}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <AccordionComponentEditButton onClick={onClickForEditButton} />
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        ) : null
      ) : AccordionSummaryTitle === 'Add Images' ? (
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table
              sx={{
                display: 'grid',
                maxHeight: '450px',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
              aria-label="customized table"
            >
              {images &&
                images?.length > 0 &&
                images.map((item, i) => (
                  <Box className="div-st-im" key={i}>
                    <Typography component="h6" className="h6-st-1" sx={{ lineHeight: '1.2' }}>
                      {item.imageType} - {item.language}
                    </Typography>
                    <Box className="create-initiative__image-grid">
                      {item?.images?.map((img, i) =>
                        ['zip', 'ai'].includes(handleSrc(img)) ? (
                          <ImageListItem
                            key={i}
                            className="bgfolder cursorshow zip-placeholder unsupported-files"
                            sx={{
                              margin: '0px',
                              width: '170px !important',
                              padding: '0px 33px ',
                              aspectRatio: '1 / 0.9 !important',
                            }}
                          >
                            <img
                              className="im-mg unsupported-image"
                              key={i}
                              src={handleSrc(img) === 'zip' ? zipIcon : handleSrc(img) == 'ai' && fileIcon}
                              srcSet={
                                handleSrc(img) === 'zip' ? zipIcon : handleSrc(img) == 'ai' && fileIcon && fileIcon
                              }
                            />
                            <Typography variant="subtitle1" sx={subtitleStyles}>
                              {handleFileName(img)}
                            </Typography>
                          </ImageListItem>
                        ) : (
                          <ImageLoader
                            key={i}
                            className={`fetchImage${i}`}
                            src={handleSrc(img)}
                            alt=""
                            width={!item.bannerSize || item.bannerSize == '1:1' ? '160' : '150'}
                            height={(!item.bannerSize || item.bannerSize == '1:1') && '160'}
                            defaultImg={unknownImg}
                            subtitleStyles={subtitleStyles}
                            item={handleFileName(img)}
                          />
                        )
                      )}
                    </Box>
                  </Box>
                ))}
              <AccordionComponentEditButton onClick={onClickForEditButton} />
            </Table>
          </TableContainer>
        </AccordionDetails>
      ) : (
        <AccordionDetails>
          <TableContainer className="video-main-Container" component={Paper}>
            <Table
              sx={{
                display: 'grid',
                maxHeight: '450px',
                overflowX: 'auto',
                overflowY: 'auto',
                fontFamily: 'HK Grotesk',
              }}
              aria-label="customized table"
            >
              {videos &&
                videos?.length > 0 &&
                videos.map((item, i) => (
                  <Box className="div-st-im" key={i}>
                    <Typography component="h6" className="h6-st-1" sx={{ lineHeight: '1.2' }}>
                      {item.language}
                    </Typography>
                    <Box className="itemfixed4 ">
                      {item?.videos?.map((video, index) => (
                        <Box key={index} sx={{ minHeight: '125px' }}>
                          {handleVideoSrc(video) === 'video' && (
                            <VideoLoader
                              key={video?.id}
                              width="300"
                              top="37%"
                              height="200"
                              className={`fetchVideo${i}`}
                              src={video?.url || video?.url === '' ? URL.createObjectURL(video.file) : video}
                              controls="play"
                              addVideoExpandState={addVideoExpand}
                            />
                          )}
                          {['ai', 'mts', 'zip', 'unknown'].includes(handleVideoSrc(video)) && (
                            <ImageListItem
                              key={i}
                              className="bgfolder1 cursorshow"
                              sx={{
                                margin: '45px !important',
                                width: '200px !important',
                                padding: '0px 25px',
                                aspectRatio: '1 / 0.9 !important',
                                textAlign: '-webkit-center',
                              }}
                            >
                              <img
                                width="120"
                                height="120"
                                className={`fetchVideo${i} video-hn`}
                                src={
                                  handleVideoSrc(video) === 'ai'
                                    ? fileIcon
                                    : handleVideoSrc(video) === 'zip'
                                    ? zipIcon
                                    : unknownImg
                                }
                              />
                              <Typography variant="subtitle1" sx={subtitleStyles}>
                                {handleFileName(video)}
                              </Typography>
                            </ImageListItem>
                          )}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                ))}
              <AccordionComponentEditButton onClick={onClickForEditButton} />
            </Table>
          </TableContainer>
        </AccordionDetails>
      )}
    </Accordion>
  )
}

export default AccordionComponent
