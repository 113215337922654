import {
  FETCH_EVENTS_LIST_REQUEST,
  FETCH_EVENTS_LIST_SUCCESS,
  FETCH_EVENTS_LIST_FAILURE,
  FETCH_EVENTS_LIST_BY_MP_ID_REQUEST,
  FETCH_EVENTS_LIST_BY_MP_ID_SUCCESS,
  FETCH_EVENTS_LIST_BY_MP_ID_FAILURE,
} from '../action/types';

const initialState = {
  data: [],
  error: '',
};

export const EventsListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS_LIST_REQUEST:
      return {
        ...state,
      };
    case FETCH_EVENTS_LIST_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_EVENTS_LIST_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export const EventsListByMpIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_EVENTS_LIST_BY_MP_ID_REQUEST:
      return {
        ...state,
      };
    case FETCH_EVENTS_LIST_BY_MP_ID_SUCCESS:
      return {
        data: action.payload,
      };
    case FETCH_EVENTS_LIST_BY_MP_ID_FAILURE:
      return {
        data: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
