import { Box } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { useLoading } from '../../../utils/LoadingContext'
import { useNotificationContext } from '../../../utils/NotificationContext'
import { getIds } from '../../ReusableComponents.js/getIds'
import { getMpProfile } from '../../../store/action/individualMP'
import ContentCarousel from '../../SevaUpdateRefactored/ContentCarousel/ContentCarousel'
import { getEventsListByMpId } from '../../../store/action/eventsList'
import { getOpedsAndBooksListByMpID, getOnlyMediaCoverageListByMpID } from '../../../store/action/mediaCoverageList'
import { getDevelopmentProjectsListByMpId } from '../../../store/action/developmentProjectList'
import { getOngoingSevaIntiativesListByMpId } from '../../../store/action/ongoingSevaInitiativesList'

const MySevaUpdateContent = ({ pageTitle, sendGALog, mpProfileData, mySeva, isEdit, mpId, mpName, tenure }) => {
  const eventListsByMpId = useSelector((state) => state.eventListByMpId?.data)
  const eventListByMpId = eventListsByMpId?.data
  const mediaCoverageListsByMpId = useSelector((state) => state?.onlyMediaCoverageListByMpId?.data)
  const mediaCoverageListByMpId = mediaCoverageListsByMpId?.data
  const opedAndBooksListsByMpId = useSelector((state) => state?.opedAndBooksListByMpId?.data)
  const opedAndBooksListByMpId = opedAndBooksListsByMpId?.data
  const developmentProjectListsByMpId = useSelector((state) => state.developmentProjectListByMpId?.data)
  const developmentProjectListByMpId = developmentProjectListsByMpId?.data
  const ongoingSevaInitiativesListsByMpId = useSelector((state) => state?.ongoingSevaInitiativesListByMpId?.data)
  const ongoingSevaInitiativesListByMpId = ongoingSevaInitiativesListsByMpId?.initiative

  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const defaultLimit = 10
  const dispatch = useDispatch()

  const fetchAllData = async () => {
    try {
      setLoading(true)
      const payload = {
        id: 0,
        limit: defaultLimit,
        prevId: 0,
        isPrevTenure: tenure == 'Modi 3.0' ? false : true,
      }
      dispatch(getMpProfile(getIds()))
      payload.id = mySeva ? getIds() : mpId
      dispatch(getOngoingSevaIntiativesListByMpId(payload))
      dispatch(getEventsListByMpId(payload))
      dispatch(getOnlyMediaCoverageListByMpID(payload))
      dispatch(getOpedsAndBooksListByMpID(payload))

      dispatch(getDevelopmentProjectsListByMpId(payload))
    } catch (error) {
      showNotification('Error', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAllData()
  }, [tenure])

  return (
    <Box className="carousel-parent pt-1">
      <ContentCarousel
        title={'Ongoing Seva Initiatives'}
        data={ongoingSevaInitiativesListByMpId?.slice(0, 10)}
        page_title={pageTitle}
        sendGALog={sendGALog}
        mySeva={mySeva}
        mpId={mpId}
        isEdit={isEdit}
        mpName={mpName}
        tenure={tenure}
      />

      <ContentCarousel
        title={'Seva Events'}
        data={eventListByMpId}
        page_title={pageTitle}
        sendGALog={sendGALog}
        mpProfileData={mpProfileData}
        mySeva={mySeva}
        mpId={mpId}
        isEdit={isEdit}
        mpName={mpName}
        tenure={tenure}
      />

      <ContentCarousel
        title={'Media Coverage'}
        data={mediaCoverageListByMpId}
        page_title={pageTitle}
        sendGALog={sendGALog}
        mpProfileData={mpProfileData}
        mySeva={mySeva}
        mpId={mpId}
        isEdit={isEdit}
        mpName={mpName}
        tenure={tenure}
      />

      <ContentCarousel
        title={'Development Projects'}
        data={developmentProjectListByMpId}
        page_title={pageTitle}
        sendGALog={sendGALog}
        mpProfileData={mpProfileData}
        mySeva={mySeva}
        mpId={mpId}
        isEdit={isEdit}
        mpName={mpName}
        tenure={tenure}
      />

      <ContentCarousel
        title={'Miscellaneous'}
        data={opedAndBooksListByMpId}
        page_title={pageTitle}
        sendGALog={sendGALog}
        mpProfileData={mpProfileData}
        mySeva={mySeva}
        mpId={mpId}
        isEdit={isEdit}
        mpName={mpName}
        tenure={tenure}
      />
    </Box>
  )
}

export default MySevaUpdateContent
