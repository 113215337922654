import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import UpdateInitiativeReportDetails from '../../AllInitiativeReportRefactored/UpdateInitiativeReportDetails/UpdateInitiativeReportDialog'
import { getIntiativesReportByInitiativeIdAndMpId } from '../../../store/action/ongoingSevaInitiativesList'
import { getIds } from '../../ReusableComponents.js/getIds'
import { makeLogRequest } from '../../../utils/AuditLogs'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { useNotificationContext } from '../../../utils/NotificationContext'
import { defaultErrorMessage } from '../../../utils/constant'
import { useLocation } from 'react-router-dom'
import { getIntiativesReportByIdList } from '../../../store/action/ongoingSevaInitiativesList'
import { getRoles } from '../../ReusableComponents.js/getIds'
import InitiativeDetails from '../../AllInitiativeReportRefactored/InitiativeDetails/initiativeDetails'
import { InitiativeProfile } from '../../ReusableComponents.js/ProfilePictureStyles'
import ProfilePictureComponent from '../../ReusableComponents.js/ProfilePictureComponent'
import './ViewAllReports.css'

const ViewAllReports = (item) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [participants, setInitiativeParticipants] = useState(0)
  const { showNotification } = useNotificationContext()
  const [openInitiativeDetailsDialog, setOpenInitiativeDetailsDialog] = useState(false)
  const [personDetails, setPersonDetails] = useState()
  let initiativeId = location?.state?.initiativeId
  const title = location?.state?.title
  const userId = parseInt(getRoles())

  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const initiativeReportDetailsByMPIdAndIntiativeId = useSelector(
    (state) => state?.initiativeReportDetailsByMPIdAndIntiativeId?.data
  )
  const [openUpdateIntiativeDialog, setOpenUpdateInitiativeDialog] = useState(false)
  const postAuditLogs = async (sqId, i_id, report_id, e_id) => {
    let requestData = {
      useridtype: getIds(),
    }
    if (i_id !== null) {
      requestData['i_id'] = i_id && i_id
    } else if (report_id !== null) {
      requestData['report_id'] = report_id
    } else if (e_id !== null) {
      requestData['e_id'] = e_id
    }
    await makeLogRequest('individual_initiatives_wall', sqId, requestData)
  }

  const handleCloseUpdateInitiativeDetails = (reset, reportFiles, setUploadFiles) => {
    reset()
    reportFiles([])
    setUploadFiles([])
    setOpenUpdateInitiativeDialog(false)
    dispatch(getIntiativesReportByIdList(initiativeId))
  }

  const handleOpenUpdateInitiativeDetails = async (item) => {
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(item?.initiativeId, item?.userId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          showNotification('Error', response.data?.message, 'error')
        } else {
          await setInitiativeParticipants(response?.data.participant)
          setPersonDetails(item)
          setOpenUpdateInitiativeDialog(true)
        }
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    }
  }

  const handleOpenInitiativeDetails = async (item) => {
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(item?.initiativeId, item?.userId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          showNotification('Error', response.data?.message, 'error')
        } else {
          setPersonDetails(item)
          await setInitiativeParticipants(response.data?.participant)
          setOpenInitiativeDetailsDialog(true)
        }
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    }
  }

  const handleCloseInitiativeDetails = () => {
    setOpenInitiativeDetailsDialog(false)
  }

  return (
    <Box>
      <Card
        className="bshadow-1 cursorshow reportContainer"
        onClick={() =>
          mpProfileData?.id === item?.item?.userId && userId !== 2 && userId !== 3
            ? (handleOpenUpdateInitiativeDetails(item?.item),
              postAuditLogs('initiative_report_card', null, item?.item?.id, null))
            : handleOpenInitiativeDetails(item?.item)
        }
      >
        <CardContent>
          <Box className="d-flex justify-content-center profile-parent">
            <Box className="text-center profile-img-box-shadow report-profile">
              <ProfilePictureComponent ProfilPic={item?.item?.user?.user_avatar} sxProfile={InitiativeProfile} />
            </Box>
          </Box>
          <Box className="card-content">
            <Typography component="h2" className="mp-name cmn-text">
              {item?.item.user?.user_name}
            </Typography>
            <Typography variant="span" className="initial cmn-text mp-detail">
              {item?.item.user?.house}
            </Typography>
            <Box mt={4} />
            <Typography variant="span" className="constituency cmn-text mp-constituency">
              Constituency : {item.item?.user?.constituency_name ? item.item?.user?.constituency_name : ' -'}
            </Typography>
          </Box>
        </CardContent>
      </Card>
      {openUpdateIntiativeDialog && (
        <UpdateInitiativeReportDetails
          handleCloseUpdateInitiativeDetails={handleCloseUpdateInitiativeDetails}
          openUpdateIntiativeDialog={openUpdateIntiativeDialog}
          details={personDetails}
          initiativeName={title}
          initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
          allInitiativeReportsPage={true}
          participants={participants}
          initiativeID={initiativeId}
        />
      )}
      {openInitiativeDetailsDialog && (
        <InitiativeDetails
          handleCloseInitiativeDetails={handleCloseInitiativeDetails}
          openInitiativeDetailsDialog={openInitiativeDetailsDialog}
          details={personDetails}
          uniqueInitiativeClick={true}
          initiativeName={title}
          mpProfileData={mpProfileData}
          initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
        />
      )}
    </Box>
  )
}

export default ViewAllReports
