import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import { useLoading } from '../../../utils/LoadingContext'
import { useNotificationContext } from '../../../utils/NotificationContext'
import CrossIcon from '../../../asserts/images/Close.svg'
import { Avatar, IconButton } from '@mui/material'
import { useDispatch } from 'react-redux'
import {
  getOnlyMediaCoverageList,
  getOnlyMediaCoverageListByMpID,
  getOpedsAndBooksList,
  getOpedsAndBooksListByMpID,
} from '../../../store/action/mediaCoverageList'
import axiosInstance from '../../../utils/api'
import { defaultErrorMessage } from '../../../utils/constant'
import { Box } from '@mui/system'
import './AddMediaCoverage.css'
export default function DeleteMediaCoverageDialog(props) {
  const {
    opedId,
    mpId,
    openDeleteDialog,
    handleCloseDeleteDialog,
    opedsByMpId,
    handleCloseInitiativeDetails,
    details,
    isMediaCoverageEdit,
    setDeleteClick,
    isMiscellaneous,
    limit = 10,
    tenure,
  } = props
  const { setLoading } = useLoading()
  const { showNotification } = useNotificationContext()
  const dispatch = useDispatch()
  const tkn = localStorage.getItem('tokenDetails')

  const dialogStyle = React.useMemo(
    () => ({
      maxWidth: '620px',
      margin: '0 auto',
      padding: '10px',
      paddingTop: '5px',
    }),
    []
  )

  const dialogContentTextStyle = React.useMemo(
    () => ({
      display: 'flex',
      alignItems: 'center',
      color: '#6C6C6C',
      fontFamily: 'HK Grotesk',
      fontSize: '22px',
      textAlign: 'center',
      mt: 3,
    }),
    []
  )

  const dialogActionsStyle = React.useMemo(
    () => ({
      justifyContent: 'center',
    }),
    []
  )

  const buttonStyle = React.useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      ml: 2,
      width: '140px',
      mb: 4,
      height: '40px',
    }),
    []
  )

  const onDeleteClick = async () => {
    try {
      setLoading(true)
      const payload = {
        id: 0,
        limit,
        isPrevTenure: tenure === 'Modi 2.0' ? true : false,
      }
      const response = await axiosInstance.delete(`/api/DeleteOpeds/${mpId}/${opedId}`).then((response) => {
        return response
      })
      if (response.status === 200 || response.status === 201) {
        if (details) {
          handleCloseInitiativeDetails && handleCloseInitiativeDetails({ deleted: true })
        }
        if (isMediaCoverageEdit) {
          setDeleteClick(true)
        }
        if (opedsByMpId) {
          payload.id = mpId
          dispatch(isMiscellaneous ? getOpedsAndBooksListByMpID(payload) : getOnlyMediaCoverageListByMpID(payload))
        } else {
          dispatch(isMiscellaneous ? getOpedsAndBooksList(payload) : getOnlyMediaCoverageList(payload))
        }
      }
      showNotification('Success', response.data.message, 'success')
      handleCloseDeleteDialog()
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box>
      <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={dialogStyle}
      >
        <DialogContent>
          <DialogContentText sx={dialogContentTextStyle}>
            Deleting this {props?.isMiscellaneous ? 'Miscellaneous' : 'Media coverage'} will have an impact on your Seva
            Score. Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={dialogActionsStyle}>
          <Button className="button-tr-2" sx={buttonStyle} onClick={onDeleteClick}>
            Yes
          </Button>
          <Button className="button-tr-citizen-cancel" sx={buttonStyle} onClick={handleCloseDeleteDialog}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
