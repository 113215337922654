import './YourInitiative.css'
import SideMenu from '../SideMenu/SideMenu'
import React, { useEffect, useRef, useState } from 'react'
import pdf2 from '../../asserts/images/pdf2.svg'
import {
  CardActionArea,
  CardContent,
  Collapse,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Box,
  Dialog,
  DialogTitle,
  Tooltip,
  Button,
  Divider,
  Card,
  SvgIcon,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CrossIcon from '../../asserts/images/Close.svg'
// import 'bootstrap/dist/css/bootstrap.min.css';
// import InitiativeCard from "./InitiativeCard";
// import CreateInitiativeCard from "./createIntiativecard";
import SortOutlinedIcon from '@mui/icons-material/SortOutlined'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
// import SortOut from "./sortOut";
import EditIcon from '@mui/icons-material/Edit'
import ShareIcon from '@mui/icons-material/Share'
import ImagesModal from './ImagesShare/ImagesModal'
import searchIcon from '../../asserts/images/Search.svg'
import closeIcon from '../../asserts/images/close.png'
import SharingText from './sharingText'
import VideosModal from './VideoSHare/VideosModal'
import buttonicon from './../../asserts/images/Card1your.svg'
import buttonicon2 from './../../asserts/images/Card2your.svg'
import { useDispatch, useSelector } from 'react-redux'
import CreateInitiativeReportDialog from '../SevaUpdate/AllInitiativeReports/createInitiativeReportDialog'
import { getAllInitiative } from '../../store/action/allInitiatives'
import { overViewInitiatives } from '../../store/action/initiativesOverview'
import { getSocialMediaKit } from '../../store/action/socialMediaKit'
import { searchInitiative } from '../../store/action/searchInitiative'
import { useLocation } from 'react-router-dom'
import { getMpProfile } from '../../store/action/individualMP'
import { useLoading } from '../../utils/LoadingContext'
import { useNotificationContext } from '../../utils/NotificationContext'
import { getIntiativesReportByInitiativeIdAndMpId } from '../../store/action/ongoingSevaInitiativesList'
import UpdateInitiativeReportDetails from '../SevaUpdate/AllInitiativeReports/UpdateInitiativeReportDialog'
import CreateNewEvent from '../SevaUpdate/AddSevaEvent/CreateNewEvent'
import NoImageFound from '../../asserts/images/noImageFound.jpg'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import Share from '../ReusableComponents.js/Share'
import { getIds, getRoles } from '../ReusableComponents.js/getIds'
import Moment from 'moment'
import { useInitiativeContext } from '../../utils/InitiativeContext'
import { makeLogRequest } from '../../utils/AuditLogs'
import { pushTag } from '../../utils/gtmservice'
import { handleFileName, handlePdfClickOpen } from '../ReusableComponents.js/reuseMethods'
// import PdfModal from "./PdfModal";
import { defaultErrorMessage } from '../../utils/constant'
const CryptoJS = require('crypto-js')
const mpUserId = getIds()

const postAuditLogs = async (sqId, data = {}, flag = 'your_initiatives_page') => {
  data.user_role = getRoles() == 2 ? 'admin' : getRoles() == 3 ? 'leadership' : 'mp'
  data.useridtype = mpUserId
  await makeLogRequest(flag, sqId, data)
}

// import CreateInitiativeReportDialog from '../SevaUpdate/AllInitiativeReports/createInitiativeReportDialog';

function IntiativesDetailsCard({ data, passChildData, setDetails, loading, searchStatus, filteredList }) {
  const functionHandler = async (item) => {
    await postAuditLogs('assigned_initiative')
    passChildData(item)
    if (filteredList.length !== 0) {
      setDetails(true)
    }
  }

  let list = filteredList
  const [openTooltip, setOpenTooltip] = useState(null)

  function handleWheel() {
    setOpenTooltip(null)
  }
  useEffect(() => {
    // if (!props.data) {
    if (filteredList) {
      if (!data) {
        passChildData(filteredList[0])
      }

      if (filteredList.length !== 0) {
        setDetails(true)
      }
    }

    // }
  }, [filteredList])

  if (list?.length === 0) {
    if (loading) return <Box className="loading-color">Loading Initiatives....</Box>
    else if (searchStatus) return <Box className="search-status-style">No Matches found. Try something else...</Box>
    else return <Box className="search-status-style">No Initiatives Created....</Box>
  }

  return list?.map((item, index) => {
    return (
      <Box className="onhovertes">
        <Box className="onhoverte" onClick={() => functionHandler(item)}>
          <Box className="details-wrapper" key={item.id}>
            <p onMouseLeave={() => setOpenTooltip(null)}>
              <Tooltip title={item.initiativeName} arrow key={index} open={openTooltip === index}>
                <Typography
                  variant="span"
                  className="name-alligment ellipsewehe12 name-color"
                  onMouseEnter={() => setOpenTooltip(index)}
                  onWheel={handleWheel}
                >
                  {getRoles() == 2 && (
                    <Typography variant="span" className="get-role-style">
                      Seva-{item.id}
                    </Typography>
                  )}{' '}
                  {item.initiativeName}
                </Typography>
              </Tooltip>

              <button
                className={`${
                  item.status == 'Ongoing' || item.status == 'Completed'
                    ? 'details-btn-ongoing'
                    : item.status == 'Report_Pending'
                    ? 'details-btn-report'
                    : 'details-btn-alligment'
                } button-mt`}
              >
                {item.status}
              </button>
            </p>
            <Typography component="p">
              {item?.tagetDate && (
                <Typography variant="span" className="target-date-style">
                  Target Date:{' '}
                  <Typography variant="span" className="target-date">
                    {Moment(item?.tagetDate).format('DD MMMM YYYY')}
                  </Typography>
                </Typography>
              )}

              <Button className="details-btn-alligments ellipsewehe12-h ">#{item?.hashTags?.replace(/^#+/, '')}</Button>
            </Typography>
          </Box>
        </Box>
        <Divider className="initiativ-divider" />
      </Box>
    )
  })
}

function IntiativesDetails({ data, setInitiatives, initiative, initDetailsChange, setInitDetailsChange }) {
  const initiativeReportDetailsByMPIdAndIntiativeId = useSelector(
    (state) => state?.initiativeReportDetailsByMPIdAndIntiativeId?.data
  )
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const mpId = mpProfileData?.id
  const userEventId = useRef(data)
  const [buttonClick, setButtonClick] = useState(false)
  const [createButtonClick, setCreateButtonClick] = useState(false)
  // const [cardFormData, setCardFormData] = useState([]);
  const [update, setUpdate] = useState(false)
  const [open, setOpen] = useState(false)
  const [openPdf, setOpenPdf] = useState(false)
  const [openImage, setOpenImage] = useState(false)
  const [openText, setOpenText] = useState(false)
  const [openVideo, setOpenVideo] = useState(false)
  // const [initiativeReportDialog, setInitiativeReportDialog] = useState(false);
  const [formImg, setFormImg] = useState()
  const [myValues, setMyValues] = useState(data)
  const [initiativeEvents, setInitiativeEvents] = useState([])
  const [editEvent, setEditEvent] = useState(false)
  const [openUpdateIntiativeDialog, setOpenUpdateInitiativeDialog] = useState(false)
  const [openEventDialog, setOpenEventDialog] = useState(false)
  const [eventDetails, setEventDetails] = useState()
  const [isEventEdit, setIsEventEdit] = useState(false)
  const [createEventDialog, setCreateEventDialog] = useState(false)
  const [initReportDetails, setInitReportDetails] = useState([])
  const [participants, setInitiativeParticipants] = useState(0)
  const [details, setDetails] = useState()
  const [expanded, setExpanded] = useState(false)
  const [yourEventsExpand, setYourEventsExpand] = useState(false)
  const [mediaShare, setMediaShare] = useState(false)
  const [shareData, setShareData] = useState({})
  const location = useLocation()
  const [checkInitiativeStatus, setCheckInitiativeStatus] = useState(false)
  let MPId = getIds()
  const { showNotification } = useNotificationContext()
  const { setLoading } = useLoading()
  const dispatch = useDispatch()
  const texttemplates = useSelector(
    (state) => state?.socialMediaKit?.data !== [] && state?.socialMediaKit?.data?.texttemplates
  )
  const imagetemplates = useSelector(
    (state) => state?.socialMediaKit?.data !== [] && state?.socialMediaKit?.data?.imagetemplates
  )
  const videotemplates = useSelector(
    (state) => state?.socialMediaKit?.data !== [] && state?.socialMediaKit?.data?.videotemplates
  )
  const fetchInitiativeData = async () => {
    try {
      const response = await dispatch(getIntiativesReportByInitiativeIdAndMpId(data?.id, mpId))
      if (response.status === 200 || response.status === 201) {
        if (response.data?.message) {
          // showNotification("Error", response.data?.message, "error");
          setInitiativeEvents([])
        } else {
          setInitiativeEvents(response?.data?.Eventdata)
          setInitReportDetails(response?.data?.reportdata)
          setInitiativeParticipants(response?.data?.participant)
          // setPersonDetails(item);
          // setOpenInitiativeDetailsDialog(true);
        }
      }
    } catch (error) {
      showNotification('Error', defaultErrorMessage)
    }
  }

  useEffect(() => {
    if (checkInitiativeStatus) {
      setInitDetailsChange(!initDetailsChange)
      // setCheckInitiativeStatus(false);
    }
  }, [createButtonClick])

  useEffect(() => {
    if (data) {
      setMyValues(data)
      dispatch(getSocialMediaKit(data?.id))
    }
    if (data?.id && mpId) fetchInitiativeData()
  }, [data, openUpdateIntiativeDialog, createButtonClick, createEventDialog, openEventDialog, mpId])
  const handleOpenText = (val) => {
    setOpenText(val)
  }

  const handleOpen = (val) => {
    setOpen(val)
  }

  const sendGALog = (event, message) => {
    pushTag({
      event: event,
      page_title: 'your_initiative_page',
      message: message,
    })
  }

  // const handlePdfClickOpen = async () => {
  //   setOpenPdf(true);
  // }
  const handleClickingOpen = async () => {
    await postAuditLogs('text')
    sendGALog('select_option', 'text')
    await postAuditLogs('pageload', { i_id: data?.id }, 'sharing_text_popup')
    pushTag({
      event: 'pop_up',
      page_title: 'sharing_text_pop_up',
    })
    setOpenText(true)
  }

  const handleImageClickOpen = async () => {
    await postAuditLogs('images')
    sendGALog('select_option', 'image')
    setOpenImage(true)
  }
  const handleVideoClickOpen = async () => {
    await postAuditLogs('videos')
    sendGALog('select_option', 'videos')
    setOpenVideo(true)
  }
  const handleClosePdf = (value) => {
    setOpenPdf(false)
  }
  const handleCloseText = (value) => {
    setOpenText(false)
  }

  const handleImageClose = (value) => {
    setOpenImage(false)
  }

  const handleVideoClose = (value) => {
    setOpenVideo(false)
  }

  const handleClickOpen = () => {
    setButtonClick(!buttonClick)
  }
  const handlecreateClickOpen = () => {
    setCreateButtonClick(!createButtonClick)
  }

  const handleUpdate = () => {
    setUpdate(true)
  }

  const handleCloseEvent = () => {
    setOpenEventDialog(false)
  }

  const handleCloseCreateEvent = () => {
    setCreateEventDialog(false)
  }

  const handleEdit = (item) => {
    setEventDetails(item)
    setOpenEventDialog(true)
    setEditEvent(true)
    setIsEventEdit(true)
  }

  const handleExpandClick = async () => {
    await postAuditLogs('expand_initiavtive_details')
    setExpanded(!expanded)
  }

  const handleYourEventsExpandClick = async () => {
    await postAuditLogs('expand_your_events')
    setYourEventsExpand(!yourEventsExpand)
  }

  const handleCloseUpdateInitiativeDetails = (reset, reportFiles, setUploadFiles) => {
    // setShowUpdate(false);
    reset()
    reportFiles([])
    setUploadFiles([])
    setOpenUpdateInitiativeDialog(false)
  }

  const handleInitiativeReport = async () => {
    await postAuditLogs('create_initiavtive_report')
    sendGALog('select_option', 'create_initiative_report')
    if (data.status === 'Completed' || initReportDetails?.id) {
      setOpenUpdateInitiativeDialog(true)
    } else {
      handlecreateClickOpen()
      // setCreateButtonClick(true)
    }
  }

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props
    return <IconButton {...other} />
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }))

  const handleShare = (e, item) => {
    e.stopPropagation()
    e.preventDefault()
    setShareData(item)
    setMediaShare(true)
  }

  const dataSteps = data?.steps?.split('\n')

  return (
    <Card card-style>
      {data ? (
        <Box>
          <Tooltip title={data?.initiativeName} arrow>
            <Typography variant="body1" className="ellipsewehe12 cursorshow initiative-title-style name-color">
              {data?.initiativeName}
            </Typography>
          </Tooltip>

          <ImagesModal setOpen={handleImageClose} open={openImage} />
          <VideosModal setOpenVideo={handleVideoClose} open={openVideo}></VideosModal>
          <img
            src={JSON.parse(data?.coverimage)[0]}
            className="seva-image seva-image-style"
            onError={(e) => (e.target.src = NoImageFound)}
          ></img>
          <Box>
            <Grid container>
              <Grid item xs={11}>
                <Typography variant="span" className="initiative-heading initiative-title-style sort-styling">
                  Initiative Details
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <ExpandMore
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon
                    sx={{
                      background: '#E3F5FF',
                      color: '#356F92',
                      borderRadius: '50%',
                    }}
                  />
                </ExpandMore>
              </Grid>
            </Grid>
          </Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent>
              <Typography
                sx={{
                  ml: '25px',
                  fontFamily: 'HK Grotesk',
                  fontSize: '18px',
                  fontWeight: '500',
                  color: '#505050',
                }}
              >
                {data?.initiativeDetails}
              </Typography>
              <Typography
                sx={{
                  ml: '25px',
                  fontFamily: 'HK Grotesk',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  color: '#3571ED',
                }}
              >
                {' '}
                #{data?.hashTags?.replace(/^#+/, '')}{' '}
              </Typography>
              <Typography
                sx={{
                  ml: '25px',
                  mt: '11px',
                  fontFamily: 'HK Grotesk',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  color: '#505050',
                }}
              >
                {' '}
                Target Date :
                <Typography variant="span" className="target-date-span-style name-color">
                  {' '}
                  {data?.tagetDate && Moment(data?.tagetDate).format('DD MMMM YYYY')}
                </Typography>{' '}
              </Typography>
              <Typography
                sx={{
                  ml: '25px',
                  mt: '15px',
                  fontFamily: 'HK Grotesk',
                  fontSize: '18px',
                  fontWeight: 'bold',
                  color: '#505050',
                }}
              >
                Steps:
              </Typography>
              {dataSteps.map((line) => (
                <Typography
                  key={line}
                  sx={{
                    ml: '25px',
                    fontFamily: 'HK Grotesk',
                    fontSize: '18px',
                    fontWeight: '500',
                    color: '#505050',
                  }}
                >
                  {line}
                </Typography>
              ))}
              {data?.stepsPdf[0] && (
                <Button
                  sx={{ maxWidth: '400px' }}
                  onClick={() => {
                    handlePdfClickOpen(data?.stepsPdf[0])
                  }}
                  className="pdfButton ml15 pdf-button-open-style "
                >
                  <Typography sx={{ fontFamily: 'HK Grotesk', fontWeight: 'bold' }} className="uploadPdfClass">
                    <img src={pdf2} alt="pdf" width="20px" />
                  </Typography>

                  <Typography className="pdfEllipse" sx={{ whiteSpace: 'pre-wrap' }}>
                    {handleFileName(data?.stepsPdf[0])}
                  </Typography>
                </Button>
              )}
              {/*  */}
            </CardContent>
          </Collapse>
          <Divider className="cover-divider"></Divider>
          <Box>
            <Typography variant="body1" className="initiative-heading initiative-title-style sort-styling">
              Social Media Kit
            </Typography>
            <Typography variant="body1" className="resources-heading resources-heading-style name-color ">
              You can download the resources from here
            </Typography>
            <Box className="social-media-kit-cards">
              <Button className="button-heading" onClick={handleClickingOpen}>
                <SvgIcon
                  className={'enabletextsvg'}
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  margin-right="10px"
                  viewBox="0 0 190.783 235.229"
                >
                  <g
                    id="Icon_feather-file-text"
                    data-name="Icon feather-file-text"
                    transform="translate(6.5 6.5)"
                    className={'enabletextsvg'}
                  >
                    <path
                      id="Path_497528"
                      data-name="Path 497528"
                      d="M117.114,3H28.223A22.223,22.223,0,0,0,6,25.223V203.006a22.223,22.223,0,0,0,22.223,22.223H161.56a22.223,22.223,0,0,0,22.223-22.223V69.669Z"
                      transform="translate(-6 -3)"
                    ></path>
                    <path
                      id="Path_497529"
                      data-name="Path 497529"
                      d="M21,3V69.669H87.669"
                      transform="translate(90.115 -3)"
                    ></path>
                    <path
                      id="Path_497530"
                      data-name="Path 497530"
                      d="M100.892,19.5H12"
                      transform="translate(32.446 102.726)"
                    ></path>
                    <path
                      id="Path_497531"
                      data-name="Path 497531"
                      d="M100.892,25.5H12"
                      transform="translate(32.446 141.172)"
                    ></path>
                    <path
                      id="Path_497532"
                      data-name="Path 497532"
                      d="M34.223,13.5H12"
                      transform="translate(32.446 64.28)"
                    ></path>
                  </g>
                </SvgIcon>
                Text
              </Button>
              <Button className="button-heading" onClick={handleImageClickOpen}>
                <SvgIcon
                  className={'enablesvg'}
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  margin-right="10px"
                  viewBox="0 0 307.584 239.232"
                >
                  <path
                    id="Icon_awesome-images"
                    data-name="Icon awesome-images"
                    d="M256.32,207.306v8.544a25.632,25.632,0,0,1-25.632,25.632H25.632A25.632,25.632,0,0,1,0,215.85V79.146A25.632,25.632,0,0,1,25.632,53.514h8.544V164.586a42.768,42.768,0,0,0,42.72,42.72Zm51.264-42.72V27.882A25.632,25.632,0,0,0,281.952,2.25H76.9A25.632,25.632,0,0,0,51.264,27.882v136.7A25.632,25.632,0,0,0,76.9,190.218H281.952A25.632,25.632,0,0,0,307.584,164.586ZM136.7,53.514a25.632,25.632,0,1,1-25.632-25.632A25.632,25.632,0,0,1,136.7,53.514ZM85.44,130.41l29.645-29.645a6.409,6.409,0,0,1,9.063,0l21.1,21.1L217.613,49.5a6.409,6.409,0,0,1,9.063,0l46.732,46.733v59.808H85.44Z"
                    transform="translate(0 -2.25)"
                  />
                </SvgIcon>
                Images
              </Button>
              <Button className="button-heading" onClick={handleVideoClickOpen}>
                <SvgIcon
                  className={'enablesvg'}
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  margin-right="10px"
                  viewBox="0 0 339.064 226.043"
                >
                  <path
                    id="Icon_awesome-video"
                    data-name="Icon awesome-video"
                    d="M197.905,4.5H28.138A28.137,28.137,0,0,0,0,32.638V202.405a28.137,28.137,0,0,0,28.138,28.138H197.905a28.137,28.137,0,0,0,28.138-28.138V32.638A28.137,28.137,0,0,0,197.905,4.5ZM309.4,26.692l-64.516,44.5v92.654L309.4,208.292c12.479,8.594,29.668-.177,29.668-15.187V41.879C339.064,26.928,321.935,18.1,309.4,26.692Z"
                    transform="translate(0 -4.5)"
                  />
                </SvgIcon>
                Videos
              </Button>
            </Box>
            <Divider className="cover-divider"></Divider>
          </Box>
          <SharingText open={openText} onClose={handleCloseText} />

          <Box className="d-flex justify-content-between align-items-center pr-4">
            <Box className="ur-evnt-init-dtls">Your Events </Box>

            <Box className="evnt-init-dtls">
              {`${initiativeEvents?.length} ${initiativeEvents?.length === 1 ? 'Event' : 'Events'}`}
              <ExpandMore
                expand={yourEventsExpand}
                onClick={handleYourEventsExpandClick}
                aria-expanded={yourEventsExpand}
                aria-label="show more"
              >
                <ExpandMoreIcon
                  sx={{
                    background: '#E3F5FF',
                    color: '#356F92',
                    borderRadius: '50%',
                  }}
                />
              </ExpandMore>
            </Box>
          </Box>

          <Collapse in={yourEventsExpand} timeout="auto" unmountOnExit>
            {initiativeEvents.length > 0 ? (
              <CardContent className="gridyour" sx={{ mb: '-15px!important' }}>
                {initiativeEvents?.map((item) => (
                  <Box>
                    <Box class="d-flex">
                      <Box
                        class="event-card event-card-style"
                        onClick={() => postAuditLogs('event_card', { e_id: item?.id })}
                      >
                        <Box class="">
                          <Box>
                            <img
                              className="imagesquare"
                              src={item?.coverimage && JSON.parse(item?.coverimage)[0]}
                              alt=""
                              onError={(e) => (e.target.src = NoImageFound)}
                              style={{ filter: 'brightness(50%)' }}
                            />
                          </Box>
                        </Box>

                        <Box
                          className="ecclipseonwidthnewrow1 image-square-event-details"
                          style={{ top: item?.eventTitle?.length < 30 ? 117 : 94 }}
                        >
                          <Typography
                            variant="body1"
                            className="ecclipseonwidthnewrow event-title-location event-title-fontsize"
                          >
                            {item?.eventTitle}
                          </Typography>
                          <Typography
                            variant="span"
                            className="ecclipseonwidthnewrow event-title-location event-location-fontsize"
                            sx={{ WebkitLineClamp: 1 }}
                          >
                            {item?.location}{' '}
                          </Typography>
                        </Box>
                        <Box className="cursorshow edit-share-icon">
                          <Box sx={{ display: 'flex', ml: '-5px' }}>
                            <Typography variant="body1" className="oparditini">
                              <EditIcon
                                onClick={async () => {
                                  await postAuditLogs('edit_event', { e_id: item.id })
                                  handleEdit(item)
                                }}
                                sx={{ width: '15px', mt: '-28px', ml: '-7px' }}
                              />
                              <ShareIcon
                                className="shareicone customshare ml-2 your-event__card-icon"
                                onClick={(e) => {
                                  handleShare(e, item)
                                  postAuditLogs('share_event', { e_id: item.id })
                                }}
                              />
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </CardContent>
            ) : (
              <Typography variant="body1" className="yourEvent-text">
                There are no events created under this initiative
              </Typography>
            )}
          </Collapse>

          <Divider className="cover-divider"></Divider>
          <Box>
            <Box className="twobu">
              <Box
                class="gfg cursorshow"
                onClick={async () => {
                  await postAuditLogs('create_an_event')
                  sendGALog('select_option', 'create_event')
                  setCreateEventDialog(true)
                }}
              >
                <img src={buttonicon} className="imagewgfg" />
                <Typography
                  variant="body1"
                  class="first-txt clickable"
                  // onClick={() => setCreateEventDialog(true)}
                >
                  Create an Event <Box /> For this Initiative
                </Typography>
              </Box>

              <Box class="gfg cursorshow" onClick={handleInitiativeReport}>
                <img src={buttonicon2} className="imagewgfg" />
                <Typography variant="body1" class="first-txt clickable">
                  {/* <p class="first-txt clickable" onClick={handleInitiativeReport}> */}
                  {data.status === 'Completed' ||
                  // checkInitiativeStatus ||
                  initReportDetails?.id
                    ? 'Update '
                    : 'Create '}
                  an <Box /> Initiative Report
                </Typography>
              </Box>
            </Box>
          </Box>
          {createEventDialog && (
            <CreateNewEvent
              openCreateEventDialog={createEventDialog}
              handleCloseCreateEvent={handleCloseCreateEvent}
              initiativeId={data.id}
              initiativeName={data.initiativeName}
            />
          )}
          {editEvent && openEventDialog && (
            <CreateNewEvent
              mpId={mpId}
              initiativeId={data.id}
              initiativeName={data.initiativeName}
              details={eventDetails}
              handleCloseCreateEvent={handleCloseEvent}
              openCreateEventDialog={openEventDialog}
              isSevaEventEdit={isEventEdit}
            />
          )}
          {createButtonClick ? (
            <CreateInitiativeReportDialog
              open={createButtonClick}
              handleClose={handlecreateClickOpen}
              initiativeName={data.initiativeName}
              initiativeId={data.id}
              participants={participants}
              setCheckInitiativeStatus={setCheckInitiativeStatus}
              insideYourInitiative={true}
              mpId={MPId}
            />
          ) : null}
          {openUpdateIntiativeDialog ? (
            <UpdateInitiativeReportDetails
              participants={participants}
              handleCloseUpdateInitiativeDetails={handleCloseUpdateInitiativeDetails}
              openUpdateIntiativeDialog={openUpdateIntiativeDialog}
              details={initReportDetails}
              initiativeName={data.initiativeName}
              initiativeReportDetailsByMPIdAndIntiativeId={initiativeReportDetailsByMPIdAndIntiativeId}
            />
          ) : null}
          <Dialog open={mediaShare} onClose={() => setMediaShare(false)}>
            <DialogTitle>
              <img onClick={() => setMediaShare(false)} src={CrossIcon} className="closeIcon" />
              <Typography
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: '#357092',
                  fontFamily: 'HK Grotesk',
                  fontSize: '26px',
                  fontWeight: 'bold',
                }}
              >
                Share to Social Media
              </Typography>

              <Box className="social-media-icons">
                <Share data={shareData} title="Seva aur Samarpan Campaign" />
              </Box>
            </DialogTitle>
          </Dialog>
        </Box>
      ) : null}
    </Card>
  )
}

const YourIntiatives = () => {
  const [anchorEl, setAnchorEl] = useState(null)
  const allInitiatives = useSelector((state) => state?.allInitiatives?.data)
  const loading = useSelector((state) => state?.allInitiatives?.loading)
  const searchData = useSelector((state) => state?.searchInitiative?.data)
  const searchLoading = useSelector((state) => state?.searchInitiative?.loading)
  const [details, setDetails] = useState(false)
  const [hideSearch, setHideSearch] = useState(false)
  const [open, setOpen] = React.useState(false)
  const [initDetailsChange, setInitDetailsChange] = useState(false)
  const handleOpen = async (event) => {
    setAnchorEl(event.currentTarget)

    setOpen(true)
    setSearchStatus(false)
    setHideSearch(false)
    await postAuditLogs('sort_initiative')
    await postAuditLogs('pageload', {}, 'sort_initiative')
  }

  const handleClose = (event) => {
    setAnchorEl(event.currentTarget)
    setOpen(false)
  }
  const [data, setData] = useState()
  const [searchStatus, setSearchStatus] = useState(false)
  const [filterStatus, setFiterStatus] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState('')
  const [filteredList, setFilteredList] = useState(allInitiatives)
  const [searchInput, setSearchInput] = useState('')

  const dispatch = useDispatch()
  const location = useLocation()
  let newUser = location?.state?.user
  const loggedInId = getIds()
  const initiative = useSelector((state) => state?.initiativesOverview?.data)
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const mpId = mpProfileData?.id

  const { resetInitiatives, setResetInitiatives } = useInitiativeContext()

  useEffect(() => {
    if (resetInitiatives) {
      handleAllInitiatives()
    }
  }, [resetInitiatives])

  const handleSearch = (event) => {
    const loggedInId = getIds()
    setSearchInput(event.target.value)
    if (event.target.value == '') {
      setSearchStatus(false)
      dispatch(getAllInitiative(loggedInId))
    } else {
      setSearchStatus(true)
      dispatch(searchInitiative(loggedInId, event.target.value))
    }
  }

  const handleFilter = async (event, value) => {
    await postAuditLogs('select', { initiative_status: event.target.innerText }, 'sort_initiative')
    let statusValue
    if (event.target.innerText == 'Report Pending') {
      statusValue = 'Report_Pending'
      setSelectedFilter('Report_Pending')
    } else if (event.target.innerText == 'All') {
      statusValue = ''
      setSelectedFilter('All')
    } else {
      statusValue = event.target.innerText
      setSelectedFilter(event.target.innerText)
    }
    setFiterStatus(true)

    // dispatch(activityInitiative())
    dispatch(
      getAllInitiative(
        loggedInId,
        // event.target.innerText === "All" ? "" : event.target.innerText
        statusValue
      )
    )
    setAnchorEl(null)
    setOpen(false)
    // dispatch()    // filter api
  }

  const [maxHeight, setMaxHeight] = useState(0)

  useEffect(() => {
    function handleResize() {
      // Set the max height based on the user's screen resolution
      const screenHeight = window.innerHeight
      if (screenHeight > 700 && screenHeight < 800) setMaxHeight(`107vh`)
      else if (screenHeight > 600 && screenHeight < 700) setMaxHeight(`125vh`)
      else if (screenHeight > 800 && screenHeight < 900) setMaxHeight(`97vh`)
      else if (screenHeight > 900 && screenHeight < 1000) setMaxHeight(`83vh`)
      else setMaxHeight(`77vh`)
    }
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'your_initiative_page',
    })
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])
  useEffect(() => {
    // const mpUserId = localStorage.getItem("userId");
    // dispatch(getEventsList(data.id));
    dispatch(getMpProfile(loggedInId))
    dispatch(getAllInitiative(loggedInId, ''))
    dispatch(overViewInitiatives(loggedInId))
    setResetInitiatives(false)
    // setData(allInitiatives[0]);
    // handleSearch();
  }, [initDetailsChange])

  const handleAllInitiatives = () => {
    setSearchStatus(false)
    setSelectedFilter('')
    setHideSearch(false)
    setResetInitiatives(false)
    dispatch(getAllInitiative(loggedInId))
  }

  const blockInvalidChar = (e) => {
    if (e.keyCode === 32 && e.target.selectionStart === 0) {
      e.preventDefault()
    }
    // ['#', '&', '+', '_', '!', '@', '%', '[', ']', '=', '*', '^', '-', '?', '/', '$', ')', '('].includes(e.key) &&
    //   e.preventDefault()
  }

  return (
    <Box className="d-flex page-wrapper1">
      {newUser ? (
        <SideMenu active="Initiatives" user={newUser} />
      ) : (
        <SideMenu active="Initiatives" profileData={mpProfileData} />
      )}

      <Box className="intiatives-container main-wrapper center-width customscroll">
        <Typography component="h1" className="header-design header-alligment" sx={{ fontSize: '23px' }}>
          Your Initiatives
        </Typography>
        <Grid container>
          <Grid item xs={6} md={6}>
            <Box className="container1-alligment">
              <card>
                <Box className="overview-card">
                  <Typography variant="body1" className="header-design-1">
                    Initiatives Overview
                  </Typography>
                  <Grid container spacing={2} sx={{ mb: '10px' }}>
                    <Grid item xs={4} className="count-alligment">
                      <Box className="count-headers" title="Requested Initiatives">
                        Requested Initiatives
                      </Box>
                      <Box className="count-container count-conatiner-color1">{initiative.requestCount}</Box>
                    </Grid>
                    <Grid item xs={4} className="count-alligment">
                      <Box className="count-headers" title="Ongoing Initiatives">
                        Ongoing Initiatives
                      </Box>
                      <Box className="count-container count-conatiner-color2">{initiative.ongoingCount}</Box>
                    </Grid>
                    <Grid item xs={4} className="count-alligment">
                      <Box className="count-headers" title="Events Conducted">
                        Events Conducted
                      </Box>
                      <Box className="count-container1 count-conatiner-color3">
                        {initiative.eventcount1 === null ? 0 : initiative.eventcount1}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </card>
              <Box class="overview-card Testdemo all-initiatives-heading table-pos">
                <Box>
                  <Box class="all-intiatives-header">
                    <Box className="design">
                      <Typography
                        variant="span"
                        className="header-design cursorshow table-header"
                        onClick={handleAllInitiatives}
                      >
                        All Initiatives <Divider className="all-initiatives-heading-underline"></Divider>
                      </Typography>
                      <Box className="d-flex align-items-center mr-3 ">
                        <Box className="menuItemForSort cursorshow hamburger-filter-menu">
                          <SortOutlinedIcon className="sort-styling" onClick={handleOpen} />

                          <Box className="checksort">
                            <Menu
                              // id="basic-menu"
                              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                              anchorEl={anchorEl}
                              value={selectedFilter}
                              open={open}
                              onClose={handleClose}
                              // MenuListProps={{
                              //   'aria-labelledby': 'basic-button',
                              // }}
                              PaperProps={{
                                sx: {
                                  width: '320px',
                                  height: '260px',
                                  maxHeight: '260px !important',
                                  borderRadius: '14px',
                                  fontFamily: 'HK Grotesk',
                                  left: '0!important',
                                  right: ' 0!important',
                                  marginLeft: 'auto',
                                  marginRight: 'auto',
                                  overflow: 'visible',
                                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                  mt: 1.5,

                                  '&:before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 150,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                  },
                                  '& .MuiMenuItem-root': {
                                    fontFamily: 'HK Grotesk',
                                    border: '1px solid #D3D3D3',
                                    margin: '10px 20px 0',
                                    borderRadius: '10px',
                                    justifyContent: 'center',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    '&$selected': {
                                      backgroundColor: '#356F92',
                                      '&:hover': {
                                        backgroundColor: '#356F92',
                                      },
                                    },
                                    '&:hover': {
                                      backgroundColor: '#356F92',
                                      color: '#fff',
                                    },
                                  },
                                },
                              }}
                            >
                              <Typography variant="body1" className="filter-by">
                                Filter By:
                              </Typography>
                              <MenuItem
                                value={'All'}
                                selected={'All' == selectedFilter ? true : false}
                                onClick={(event, value) => handleFilter(event, value)}
                                sx={{
                                  '&.Mui-selected': {
                                    backgroundColor: selectedFilter === 'All' ? '#356F92 !important' : '',
                                    color: selectedFilter === 'All' ? '#fff !important' : '',
                                  },
                                }}
                              >
                                All
                              </MenuItem>
                              <MenuItem
                                value={'Requested'}
                                selected={'Requested' == selectedFilter ? true : false}
                                onClick={(event, value) => handleFilter(event, value)}
                                sx={{
                                  '&.Mui-selected': {
                                    backgroundColor: selectedFilter === 'Requested' ? '#356F92 !important' : '',
                                    color: selectedFilter === 'Requested' ? '#fff !important' : '',
                                  },
                                }}
                              >
                                Requested
                              </MenuItem>
                              <MenuItem
                                value={'Ongoing'}
                                selected={'Ongoing' == selectedFilter ? true : false}
                                onClick={(event, value) => handleFilter(event, value)}
                                sx={{
                                  '&.Mui-selected': {
                                    backgroundColor: selectedFilter === 'Ongoing' ? '#356F92 !important' : '',
                                    color: selectedFilter === 'Ongoing' ? '#fff !important' : '',
                                  },
                                }}
                              >
                                Ongoing
                              </MenuItem>
                              <MenuItem
                                value={'Report_Pending'}
                                selected={'Report_Pending' == selectedFilter ? true : false}
                                onClick={(event, value) => handleFilter(event, value)}
                                sx={{
                                  '&.Mui-selected': {
                                    backgroundColor: selectedFilter === 'Report_Pending' ? '#356F92 !important' : '',
                                    color: selectedFilter === 'Report_Pending' ? '#fff !important' : '',
                                  },
                                }}
                              >
                                Report Pending
                              </MenuItem>
                              <MenuItem
                                value={'Completed'}
                                selected={'Completed' == selectedFilter ? true : false}
                                onClick={(event, value) => handleFilter(event, value)}
                                sx={{
                                  '&.Mui-selected': {
                                    backgroundColor: selectedFilter === 'Completed' ? '#356F92 !important' : '',
                                    color: selectedFilter === 'Completed' ? '#fff !important' : '',
                                  },
                                }}
                              >
                                Completed
                              </MenuItem>
                            </Menu>
                          </Box>
                        </Box>
                        <Box>
                          {!hideSearch ? (
                            <Box className="sort-search-icon">
                              <img
                                className="searchIcon cursorshow"
                                width={18}
                                height={18}
                                src={searchIcon}
                                onClick={async () => {
                                  await postAuditLogs('search_initiative')
                                  setHideSearch(!hideSearch)
                                }}
                              />
                            </Box>
                          ) : (
                            <Box>
                              <TextField
                                sx={{
                                  '& fieldset': { border: 'none' },
                                  padding: '0px',
                                  '& .MuiInputBase-input': {
                                    padding: '5px 10px 5px 10px !important',
                                    fontFamily: 'HK Grotesk',
                                  },
                                  marginRight: '-12px',
                                }}
                                className="search-sort-ini"
                                onChange={(e) => {
                                  handleSearch(e)
                                }}
                                onKeyDown={blockInvalidChar}
                                placeholder="Search Seva Initiatives"
                                value={searchInput}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {/* search icon  */}
                                      {searchInput?.length > 0 && (
                                        <img
                                          src={closeIcon}
                                          width={16}
                                          height={16}
                                          className="m-3 search-close-icon"
                                          onClick={() => {
                                            setSearchInput('')
                                            setSearchStatus(false)
                                            dispatch(getAllInitiative(loggedInId))
                                          }}
                                          alt="close"
                                        />
                                      )}
                                      <img
                                        className="searchIcon cursorshow"
                                        width={18}
                                        height={18}
                                        src={searchIcon}
                                        alt="Hide Search"
                                        onClick={() => {
                                          setHideSearch(!hideSearch)
                                          handleAllInitiatives()
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              ></TextField>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    </Box>
                    <Box className="all-initiatives--list-wrapper customscroll">
                      <IntiativesDetailsCard
                        data={data}
                        passChildData={setData}
                        setDetails={setDetails}
                        loading={searchStatus ? searchLoading : loading}
                        searchStatus={searchStatus}
                        filteredList={searchStatus ? searchData : allInitiatives}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          {details ? (
            <Grid item xs={6} md={6}>
              <Box className="container2-alligment-2">
                <Box
                  className="customscroll scroll-property"
                  // style={{
                  //   maxHeight,
                  //   overflowX: 'hidden',
                  // }}
                >
                  <IntiativesDetails
                    initiative={initiative}
                    setInitiatives={[]}
                    data={data}
                    initDetailsChange={initDetailsChange}
                    setInitDetailsChange={setInitDetailsChange}
                  />
                </Box>
              </Box>
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Box>
  )
}

export default YourIntiatives
