import './App.css'
import { useEffect, useState } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import MpHome from 'Components/MpHome/MpHome'
import MpHomeRefactor from 'pages/MpHome/MpHome'
import SevaUpdates from 'Components/SevaUpdate/SevaUpdates'
import SevaUpdatesRefactored from 'pages/SevaUpdates/SevaUpdates'
import YourInitiative from 'Components/YourIntiative/YourInitiative'
import YourInitiativeRefactor from 'pages/YourInitiatives/YourInitiatives'
import AdminHome from 'Components/AdminHome/AdminHome'
import AdminHomeRefactor from 'pages/AdminHome/AdminHome'
import Login from 'Components/Login/Login.js'
import MpSevaUpdate from 'Components/SevaUpdate/MpSevaUpdate/MpSevaUpdate'
import MpSevaUpdateRefactored from 'pages/MpSevaUpdates/MpSevaUpdates'
import AllInitiativeReports from 'Components/SevaUpdate/AllInitiativeReports/AllInitiativeReports'
import AllInitiativeReportsRefactor from 'pages/AllInitiavesReport/AllInitiativesReport'
import SevaInitiatives from 'Components/SevaInitiatives/SevaInitiatives'
import SevaInitiativesRefactored from 'pages/SevaInitiatives/SevaInitiatives'
import CreateInitiatives from 'Components/SevaInitiatives/CreateInitiatives/CreateInitiatives'
import CreateInitiativesRefactored from 'pages/CreateInitiatives/CreateInitiatives'
import MySevaUpdate from 'Components/SevaUpdate/MySevaUpdates/MySevaUpdates'
import MySevaUpdatesRefactored from 'pages/MySevaUpdates/MySevaUpdates'
import ViewAllScreen from 'Components/SevaUpdate/ViewAllScreen'
import ViewAllScreenRefactor from 'pages/ViewAllScreen/ViewAllScreen'
import ViewAllOngoingInitiatives from 'Components/SevaUpdate/ViewAllOngoingInitiatives'
import MasterList from 'pages/MasterList/MasterList'
import Settings from 'pages/Settings/Settings'
import SendNotification from 'pages/SendNotifications/SendNotifications'
import PinActivity from 'pages/PinActivity/PinActivity'
import TestLogin from 'utils/TestLogin'
import axiosInstance from 'utils/api'
import { env } from 'env'

// this is dummmy comment

function App() {
  const CryptoJS = require('crypto-js')
  const [userId, setUserId] = useState(
    localStorage.getItem('userId') &&
      CryptoJS.AES.decrypt(localStorage.getItem('userId'), env.REACT_APP_ROLE_KEY).toString(CryptoJS.enc.Utf8)
  )
  const [loading, setLoading] = useState(false)

  const [paramId, setParamId] = useState()
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setParamId(params.get('userid'))
  }, [])

  useEffect(() => {
    if (paramId) {
      handleLogin(paramId)
    }
  }, [paramId])

  const handleLogin = async (id) => {
    // 1- MP, 2- Admin, 3- Leadership
    const idMapping = {
      1002: 1,
      1004: 2,
      1001: 3,
    }
    let payload = {
      userid: id?.replace(/[']/g, ''),
    }
    setLoading(true)
    await axiosInstance
      .post(env.REACT_APP_BASE_URL + `/api/auth/signin`, payload)
      .then(async (response) => {
        const CryptoJS = require('crypto-js')
        let val = response.data?.token
        let id = response.data?.roleId

        localStorage.setItem('tokenDetails', val)
        response.data?.role == 'mp' &&
          localStorage.setItem('mpId', CryptoJS.AES.encrypt(response.data?.mpid.toString(), env.REACT_APP_ID_KEY))
        localStorage.setItem('userId', CryptoJS.AES.encrypt(`${idMapping[id]}`, env.REACT_APP_ROLE_KEY))
        if (id == 1002) {
          localStorage.setItem('Referal', response.data.refferalCode)
        }
        localStorage.setItem('mpName', CryptoJS.AES.encrypt(response.data?.user_name, env.REACT_APP_ID_KEY))
        localStorage.setItem('isBdayCampaignEnabled', response?.data?.isBdayCampaignEnabled)
        localStorage.setItem('isSurveyCampaignEnabled', response?.data?.isSurveyCampaignEnabled)
        localStorage.setItem('uuid', CryptoJS.AES.encrypt(response.data?.uuid, env.REACT_APP_ID_KEY))
        localStorage.setItem('role', CryptoJS.AES.encrypt(response.data?.role?.split('_')?.pop(), env.REACT_APP_ID_KEY))
        setUserId(idMapping[id])
        setLoading(false)
      })
      .catch((error) => {
        setTimeout(() => {
          let redirect =
            env.REACT_APP_TESTLOGIN === '1' ? document.getElementById('testlogin') : document.getElementById('login')
          if (redirect) {
            redirect.click()
          }
        }, [500])
        const errorMsg = error.message
        localStorage.setItem('tokenDetails', '')
        setLoading(false)
      })
  }

  // 1- MP, 2- Admin, 3- Leadership
  return (
    <>
      <div className="App">
        <Router>
          <Routes>
            {!userId && (
              <>
                {env.REACT_APP_TESTLOGIN === '1' && (
                  <>
                    <Route exact path="/LoginUsers" element={<TestLogin />} />
                  </>
                )}
                <Route exact path="*" element={<Login paramId={paramId} loading={loading} />}></Route>
              </>
            )}
            {userId == 1 && (
              <>
                <Route path="/" element={<Navigate replace to="/MpHome" />} replace={true} />
                <Route exact path="/MpHomeOld" element={<MpHome />} />
                <Route exact path="/MpHome" element={<MpHomeRefactor />} />
                <Route exact path="/InitiativesOld" element={<YourInitiative />} />
                <Route exact path="/Initiatives" element={<YourInitiativeRefactor />} />
                <Route exact path="/SevaUpdatesOld" element={<SevaUpdates />} />
                <Route exact path="/SevaUpdates/viewAllSevaEventsOld" element={<ViewAllScreen />} />
                <Route exact path="/SevaUpdates/viewAllOngoingInitiativesOld" element={<ViewAllOngoingInitiatives />} />
                <Route exact path="/MySevaUpdatesOld" element={<MySevaUpdate />} />
                <Route exact path="/MySevaUpdates" element={<MySevaUpdatesRefactored />} />
              </>
            )}
            {userId == 2 && (
              <>
                <Route path="/" element={<Navigate replace to="/SevaInitiatives" />} replace={true} />
                <Route exact path="/AdminHomeOld" element={<AdminHome userId={userId} />} />
                <Route exact path="/AdminHome" element={<AdminHomeRefactor />} />
                <Route exact path="/SevaInitiativesOld" element={<SevaInitiatives />} />
                <Route exact path="/SevaInitiatives" element={<SevaInitiativesRefactored />} />
                <Route exact path="/SevaInitiatives/createInitiativeOld" element={<CreateInitiatives />} />
                <Route exact path="/SevaInitiatives/createInitiative" element={<CreateInitiativesRefactored />} />
                <Route exact path="/SevaInitiatives/editInitiativeOld/:id" element={<CreateInitiatives />} />
                <Route exact path="/SevaInitiatives/editInitiative/:id" element={<CreateInitiativesRefactored />} />
                <Route exact path="/SevaUpdatesOld" element={<SevaUpdates user={'Admin'} />} />
                <Route exact path="/SevaUpdates/viewAllSevaEventsOld" element={<ViewAllScreen user={'Admin'} />} />
                <Route
                  exact
                  path="/SevaUpdates/viewAllOngoingInitiativesOld"
                  element={<ViewAllOngoingInitiatives user={'Admin'} />}
                />
                <Route exact path="/Mp_SevaUpdateOld" element={<MpSevaUpdate user={'Admin'} />} />
                <Route exact path="/Settings/MasterList" element={<MasterList />} />
                <Route exact path="/Settings" element={<Settings />} />
                <Route path="/Settings/SendNotification" element={<SendNotification />} />
                <Route path="/Settings/PinActivity" element={<PinActivity />} />
              </>
            )}
            {userId == 3 && (
              <>
                <Route path="/" element={<Navigate replace to="/AdminHome" />} replace={true} />
                <Route exact path="/SevaUpdates/viewAllSevaEventsOld" element={<ViewAllScreen user={'Leader'} />} />
                <Route
                  exact
                  path="/SevaUpdates/viewAllOngoingInitiativesOld"
                  element={<ViewAllOngoingInitiatives user={'Leader'} />}
                />
                <Route exact path="/AdminHomeOld" element={<AdminHome readonly={true} userId={userId} />} />
                <Route exact path="/AdminHome" element={<AdminHomeRefactor />} />
                <Route exact path="/SevaUpdatesOld" element={<SevaUpdates readonly={true} user={'Leader'} />} />
                <Route exact path="/SevaUpdates/viewAllSevaEventsOld" element={<ViewAllScreen user={'Leader'} />} />
                <Route exact path="/Mp_SevaUpdateOld" element={<MpSevaUpdate readonly={true} user={'Leader'} />} />
              </>
            )}
            <Route exact path="/Mp_SevaUpdateOld" element={<MpSevaUpdate />} />
            <Route exact path="/MpSevaUpdates" element={<MpSevaUpdateRefactored />} />
            <Route exact path="/SevaUpdates/allInitiativeReportsOld" element={<AllInitiativeReports />} />
            <Route exact path="/SevaUpdates/allInitiativeReports" element={<AllInitiativeReportsRefactor />} />
            <Route exact path="/SevaUpdates/viewAllSevaEvents" element={<ViewAllScreenRefactor />} />
            <Route exact path="/SevaUpdates/viewAllOngoingInitiatives" element={<ViewAllScreenRefactor />} />
            <Route exact path="/SevaUpdates" element={<SevaUpdatesRefactored />} />
            <Route path="*" element={<Navigate to="" replace />} />
          </Routes>
        </Router>
      </div>
    </>
  )
}

export default App
