import { Box, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import './SevaUpdateButton.css'

const SevaUpdateButton = ({ postAuditLogs, sendGALog, loggedInId }) => {
  const navigate = useNavigate()
  const handleMySevaUpdateClick = async (e) => {
    e.stopPropagation()
    await postAuditLogs('my_seva_updates')
    const message = 'my_seva_updates'
    sendGALog('select_option', message)

    navigate('/MySevaUpdates', {
      state: {
        MpName: 'My Seva Rank',
        mpId: loggedInId,
      },
    })
  }

  return (
    <Box>
      <Button class={`button-sevaupdate`} onClick={(e) => handleMySevaUpdateClick(e)}>
        {'MY SEVA UPDATES'}
      </Button>
    </Box>
  )
}
export default SevaUpdateButton
