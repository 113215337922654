import React, { useEffect, useRef, useState } from 'react'
// import 'bootstrap/dist/css/bootstrap.min.css';
import ProfileCard from '../ReusableComponents.js/ProfileCard'
import SideMenu from '../SideMenu/SideMenu'
import TableHighlights from '../Highlights/TableHighlights'
import ThreeCards from './ThreeCards'
import {
  Grid,
  MenuItem,
  Select,
  Tabs,
  Tooltip,
  TextField,
  InputAdornment,
  Box,
  Typography,
  Avatar,
  Divider,
  Button,
} from '@mui/material'
import Search from './Search'
import DateFilter from '../ReusableComponents.js/DateFilter'
import StickyHeadTable from './MpTable'
import { useDispatch, useSelector } from 'react-redux'
import { getMpList } from '../../store/action/mpList'
import { getMpProfile } from '../../store/action/individualMP'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import Constant from '../../utils/constant'
import { getIds } from '../ReusableComponents.js/getIds'
import { useNavigate } from 'react-router-dom'
import infoic from '../../asserts/images/Info.svg'
import { tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import searchIcon from '../../asserts/images/Search.svg'
import { makeLogRequest } from '../../utils/AuditLogs'
import { pushTag } from '../../utils/gtmservice'
import WhatsNewSection from '../ReusableComponents.js/WhatsNewSection'
import AboutSevaScore from './AboutSevaScore'
import IntervalTabs from '../ReusableComponents.js/IntervalTabs'
import './MpHome.css'
import NotificationsList from '../ReusableComponents.js/NotificationList/NotificationsList'

export const BootstrapTooltip1 = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #356F92',
    },
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #356F92',
    boxShadow: theme.shadows[1],
    width: 220,
    borderRadius: '15px',
    marginTopValue: '-30px',
  },
}))

const MpHome = () => {
  const isBdayCampaignEnabled = JSON.parse(localStorage.getItem('isBdayCampaignEnabled') || false)
  const [cardClick, setCardClick] = useState(isBdayCampaignEnabled ? 'ecards' : 'sevaScore')
  const [tabactive, settabactive] = useState(isBdayCampaignEnabled ? 0 : 1)
  const [flag, setFlag] = useState(isBdayCampaignEnabled ? 100 : 101)
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const [showAboutSevaScore, setShowAboutSevaScore] = useState(false)
  const [isMarqueeVisible, setIsMarqueeVisible] = useState(false)
  //redux get store data
  const mpList = useSelector((state) => state?.mpList?.data)
  const mpProfileData = useSelector((state) => state?.mpProfileData?.data[0])
  const [dataFilter, setDataFilter] = useState('15days')
  const CryptoJS = require('crypto-js')
  const loggedInId = getIds()
  const dispatch = useDispatch()
  const inputRef = useRef(null)
  const navigate = useNavigate()
  useEffect(() => {
    const lastData = JSON.parse(localStorage.getItem('leaderBoardSelectedItem') || '{}')
    const { flag: previouslySelected } = lastData
    //call api to update store
    dispatch(getMpProfile(loggedInId))
    dispatch(getMpList(previouslySelected || flag, 1, dataFilter))
  }, [flag, dataFilter])

  useEffect(() => {
    const lastData = JSON.parse(localStorage.getItem('leaderBoardSelectedItem') || '{}')
    const { tabId, card, flag, scrollDirection } = lastData
    setSelection(tabId, card, flag, scrollDirection, false)
  }, [])

  const containerRef = useRef(null)
  const [isDragging, setIsDragging] = useState(false)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [scrollRight, setScrollRight] = useState(true)
  const handleMouseDown = (event) => {
    setIsDragging(true)
    setStartX(event.pageX - containerRef.current.offsetLeft)
    setScrollLeft(containerRef.current.scrollLeft)
  }

  useEffect(() => {
    setScrollLeft(containerRef.current.scrollLeft)
    const container = containerRef.current
    if (
      Math.floor(container.scrollLeft) + container.clientWidth == container.scrollWidth ||
      Math.floor(container.scrollLeft) + container.clientWidth + 1 == container.scrollWidth ||
      Math.ceil(container.scrollLeft) + container.clientWidth == container.scrollWidth
    ) {
      setScrollRight(false)
    } else {
      setScrollRight(true)
    }
  }, [containerRef?.current?.scrollLeft])

  const handleMouseMove = (event) => {
    if (!isDragging) return
    event.preventDefault()
    const x = event.pageX - containerRef.current.offsetLeft
    const distance = (x - startX) * 2
    containerRef.current.scrollLeft = scrollLeft - distance
  }

  const handleMouseUp = () => {
    setIsDragging(false)
  }

  const scrollLeftTab = () => {
    const container = containerRef.current
    if (
      Math.floor(container.scrollLeft) + container.clientWidth == container.scrollWidth ||
      Math.floor(container.scrollLeft) + container.clientWidth + 1 == container.scrollWidth ||
      Math.ceil(container.scrollLeft) + container.clientWidth == container.scrollWidth
    ) {
      setScrollRight(false)
    } else {
      setScrollRight(true)
    }
    if (container.scrollLeft > 0) {
      containerRef.current.scrollLeft = containerRef?.current?.scrollLeft - container.offsetWidth / 2
      // container.scrollTo({
      //   left: container.scrollLeft - container.offsetWidth / 2,
      //   behavior: "smooth",
      // });
    }
  }

  const scrollRightTab = () => {
    const container = containerRef.current
    if (
      Math.floor(container.scrollLeft) + container.clientWidth == container.scrollWidth ||
      Math.floor(container.scrollLeft) + container.clientWidth + 1 == container.scrollWidth ||
      Math.ceil(container.scrollLeft) + container.clientWidth == container.scrollWidth
    ) {
      setScrollRight(false)
    } else {
      setScrollRight(true)
    }
    if (container.scrollLeft < container.scrollWidth - container.offsetWidth) {
      container.scrollTo({
        left: container.scrollLeft + container.offsetWidth,
        behavior: 'smooth',
      })
    }
  }

  const defaultTab = isBdayCampaignEnabled ? 0 : 1
  const defaultCard = isBdayCampaignEnabled ? 'ecards' : 'sevaScore'
  const defaultFlag = isBdayCampaignEnabled ? 100 : 101

  const setSelection = (
    tabId = defaultTab,
    card = defaultCard,
    flag = defaultFlag,
    scrollDirection = 'left',
    saveSelection = true
  ) => {
    settabactive(tabId)
    setCardClick(card)
    setFlag(flag)
    scrollDirection === 'left' ? scrollLeftTab() : scrollRightTab()
    saveSelection &&
      localStorage.setItem('leaderBoardSelectedItem', JSON.stringify({ tabId, card, flag, scrollDirection }))
    const parameters = {
      useridtype: getIds(),
    }
    makeLogRequest('mp_leaderboard', log[card], parameters)
    sendGALog('select_option', log[card])
  }

  const openAboutSevaScorePopup = () => {
    setShowAboutSevaScore(true)
  }

  const log = {
    sevaScore: 'mp_seva_score',
    memberAdded: 'members_added',
    followers: 'followers',
    twitterPerformance: 'twitter_performance',
    mediaCoverage: 'op_eds_and_media_coverage',
    donation: 'donations',
    initiatives: 'initiatives',
  }

  useEffect(() => {
    if (!isSearchOpen) {
      inputRef.current.blur()
    }
  }, [isSearchOpen])

  useEffect(() => {
    const pageLoad = async () => {
      const parameters = {
        useridtype: getIds(),
        section: cardClick,
      }
      await makeLogRequest('mp_leaderboard', 'pageload', parameters)
      pushTag({
        event: 'pageload',
        page_title: 'leaderboard_page',
      })
    }
    pageLoad()
  }, [])

  const sendSearchLog = async () => {
    const parameters = {
      useridtype: getIds(),
    }
    await makeLogRequest('mp_leaderboard', 'search', parameters)
    sendGALog('select_option', 'search')
  }

  const sendGALog = (event, message) => {
    pushTag({
      event: event,
      page_title: 'leaderboard_page',
      message: message,
    })
  }

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      '&:before': {
        border: '1px solid #356F92',
      },
      color: theme.palette.common.white,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      border: '1px solid #356F92',
      boxShadow: theme.shadows[1],
      width: 200,
      height: 70,
      borderRadius: '5px',
    },
  }))

  return (
    <>
      {/* API loading time mpList will be empty */}
      <Box className="page-wrapper d-flex">
        <SideMenu active="Leader" profile={mpList && mpList[0]} profileData={mpProfileData} />
        <Box className="main-wrapper center-width customscroll mphome-container">
          <NotificationsList setIsMarqueeVisible={setIsMarqueeVisible} isMarqueeVisible={isMarqueeVisible} />
          <Box>
            <Box>
              <Box className="d-flex align-items-center">
                <Box className="d-flex-gap align-items-center leaderboard-header-title-box-width">
                  <Typography component="h1" className="leaderboard-page-title mb-0 text-overflow-clip">
                    MP Leaderboard
                  </Typography>
                  {cardClick === 'sevaScore' && (
                    <Typography
                      component="span"
                      className="about-seva-score-hyperlink user-select-none"
                      onClick={openAboutSevaScorePopup}
                      sx={{ fontFamily: 'HK Grotesk Bold' }}
                    >
                      About Seva Score
                    </Typography>
                  )}
                  {cardClick === 'ecards' && (
                    <BootstrapTooltip1
                      title={
                        'The Seva Pakhwada E-Card campaign will run for a duration of 15 days. The E-Card Campaign Score is established by aggregating the Likes and Wishes received on E-Cards created by the MP'
                      }
                      placement="right"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: '16px',
                            fontFamily: 'HK Grotesk',
                            padding: '10px',
                            textAlign: 'center',
                          },
                        },
                      }}
                    >
                      <Avatar variant="square" src={infoic} className="info-ecard" />
                    </BootstrapTooltip1>
                  )}
                  {showAboutSevaScore && (
                    <AboutSevaScore
                      showAboutSevaScore={showAboutSevaScore}
                      setShowAboutSevaScore={setShowAboutSevaScore}
                    />
                  )}
                </Box>
                <Box className="d-flex pr-4">
                  <Box className="d-flex mr-4">
                    <IntervalTabs setDataFilter={setDataFilter} />
                    <BootstrapTooltip1
                      title={
                        dataFilter === '15days'
                          ? 'Showing results of last 15 days. Refreshes everyday at midnight.'
                          : 'Showing results of All time. Refreshes everyday at midnight.'
                      }
                      placement="bottom"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            fontSize: '16px',
                            fontFamily: 'HK Grotesk',
                            padding: '10px',
                            textAlign: 'center',
                          },
                        },
                      }}
                    >
                      <Avatar variant="square" src={infoic} className="info-icon" />
                    </BootstrapTooltip1>
                  </Box>
                  <Box>
                    <TextField
                      className="search-filter-icon cursorshow"
                      sx={{
                        '& fieldset': { border: 'none' },
                      }}
                      onClick={(e) => {
                        setIsSearchOpen(true)
                      }}
                      inputRef={inputRef}
                      onFocus={(e) => {
                        sendSearchLog()
                      }}
                      placeholder="Search MP / Constituency"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            {/* search icon  */}
                            <Avatar
                              variant="square"
                              className="search-icon-mphome cursorshow"
                              alt="Search"
                              src={searchIcon}
                              onClick={() => {
                                setIsSearchOpen(true)
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    >
                      {' '}
                    </TextField>
                    {isSearchOpen && (
                      <Search
                        marginRightValue={'-310'}
                        marginTopValue={isMarqueeVisible ? '70' : '-15'}
                        inputValue={isSearchOpen}
                        clearParentTextField={setIsSearchOpen}
                      />
                    )}
                  </Box>
                </Box>
              </Box>
              {/* <DateFilter /> */}
            </Box>
            <Grid container spacing={2} className="mpHomeContainer">
              <Grid md={8} lg={8} xl={8} xs={10}>
                <Box className="filter-btns no-gutters pt-3 leaderboard-options-outer-container cursorshow">
                  {/* {props.user!=="Admin" && */}
                  <ChevronLeft
                    sx={{
                      color: scrollLeft === 0 ? 'grey' : 'black',
                      cursor: scrollLeft !== 0 && 'pointer',
                    }}
                    onClick={scrollLeftTab}
                  />
                  <Tabs
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable auto tabs example"
                    sx={{ width: '94%' }}
                  >
                    <Box
                      aria-label="scrollable auto tabs example"
                      ref={containerRef}
                      className="leaderBoardScrollbar leaderboard-options-inner-container"
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onMouseLeave={handleMouseUp}
                    >
                      {isBdayCampaignEnabled && (
                        <Button
                          type="button"
                          className={
                            tabactive === 0
                              ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                              : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                          }
                          onClick={() => {
                            setSelection(0, 'ecards', 100, 'left')
                          }}
                        >
                          E-Cards Campaign
                        </Button>
                      )}
                      <Button
                        type="button"
                        className={
                          tabactive === 1
                            ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                            : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                        }
                        onClick={() => {
                          setSelection(1, 'sevaScore', 101, 'left')
                        }}
                      >
                        MP Seva Score
                      </Button>
                      <Button
                        type="button"
                        className={
                          tabactive === 2
                            ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                            : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                        }
                        onClick={() => {
                          setSelection(2, 'initiatives', 102, 'left')
                        }}
                      >
                        Initiatives
                      </Button>
                      <Button
                        type="button"
                        className={
                          tabactive === 3
                            ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                            : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                        }
                        onClick={() => {
                          setSelection(3, 'memberAdded', 103, 'left')
                        }}
                      >
                        Members Added
                      </Button>
                      <Button
                        type="button"
                        className={
                          tabactive === 4
                            ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                            : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                        }
                        onClick={() => {
                          setSelection(4, 'followers', 104, 'left')
                        }}
                      >
                        Followers
                      </Button>
                      <Button
                        type="button"
                        className={
                          tabactive === 5
                            ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                            : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                        }
                        onClick={() => {
                          setSelection(5, 'twitterPerformance', 105, 'right')
                        }}
                      >
                        Twitter Performance
                      </Button>
                      <Button
                        type="button"
                        className={
                          tabactive === 6
                            ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                            : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                        }
                        onClick={() => {
                          setSelection(6, 'mediaCoverage', 106, 'right')
                        }}
                      >
                        Op-Eds & Media Coverage
                      </Button>
                      <Button
                        type="button"
                        className={
                          tabactive === 7
                            ? 'btn btn-primary primary-btn mr-3 mt-1 mb-1'
                            : 'btn btn-primary normal-btn mr-3 mt-1 mb-1'
                        }
                        onClick={() => {
                          setSelection(7, 'donation', 107, 'right')
                        }}
                      >
                        Donations
                      </Button>
                    </Box>
                  </Tabs>
                  <ChevronRight
                    sx={{
                      color: !scrollRight ? 'grey' : 'black',
                      cursor: scrollRight && 'pointer',
                    }}
                    onClick={scrollRightTab}
                  />
                </Box>
                <Divider className="mt-2 mb-4" />
                <ThreeCards cardClick={cardClick} />
                <Box className="mt-5 learders-list">
                  {/* <MpTables cardClick={cardClick} mpList={mpList} /> */}
                  <StickyHeadTable
                    cardClick={cardClick}
                    flag={flag}
                    count={1}
                    mpProfileData={mpProfileData}
                    dataFilter={dataFilter}
                  />
                </Box>
              </Grid>
              <Grid item xs={6} md={4} lg={4} xl={4} sx={{ backgroundColor: '#F5F6FA' }}>
                <Box className="rtcar">
                  <Box className="right-card-2" onClick={() => navigate('/SevaUpdates')}>
                    <Box className="highlights-title">
                      <Typography className="fs-30" component="h1" sx={{ fontSize: '30px', fontFamily: 'HK Grotesk' }}>
                        Highlights
                      </Typography>
                      <Typography
                        component="p"
                        className="mb-0 highlightBodyText"
                        sx={{ fontSize: '24px', fontFamily: 'HK Grotesk' }}
                      >
                        All India Seva Initiatives
                      </Typography>
                    </Box>

                    {mpList?.length && <TableHighlights mpList={mpList[0]} dataFilter={dataFilter} />}
                  </Box>
                  <Box>
                    <ProfileCard profile={mpProfileData ? mpProfileData : {}} page_title="leaderboard_page" />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default MpHome
