import { Grid, Breadcrumbs, Button, Typography } from '@mui/material'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useNavigate } from 'react-router-dom'
import { getIds } from '../../ReusableComponents.js/getIds'
import { useLocation } from 'react-router-dom'
import './ViewAllScreenHeaderBreadCrumb.css'
import { useSelector } from 'react-redux'

const ViewAllScreenHeaderBreadCrumb = ({ tenure, viewAllTitle }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const mpId = location?.state?.mpId
  const eventsForInitiative = location?.state?.eventsForInitiative
  const title = location?.state?.title
  const isFromNationwide = location?.state?.isFromNationwide
  const initiativeId = location?.state?.initiativeId
  const mpData = location?.state?.mpData
  const mpName = location?.state?.mpName
  const InitiativeReports = location?.state?.InitiativeReports
  const mySeva = location?.state?.mySeva
  const ongoingSevaInitiativesLists = useSelector((state) => state?.ongoingSevaInitiativesList?.data)
  const ongoingSevaInitiativesListsByMpId = useSelector((state) => state?.ongoingSevaInitiativesListByMpId?.data)
  const ongoingSevaInitiativesList = ongoingSevaInitiativesLists?.initiative
  const ongoingSevaInitiativesListByMpId = ongoingSevaInitiativesListsByMpId?.initiative

  const onFolderClick = () => {
    if (viewAllTitle === 'Ongoing Seva Initiatives' || InitiativeReports) {
      navigate('/SevaUpdates')
    } else if (!isFromNationwide && !eventsForInitiative) {
      navigate('/MySevaUpdates', {
        state: {
          MpName: 'My Seva Rank',
          mpId: getIds(),
        },
      })
    } else if (eventsForInitiative) {
      handleCardClick()
    } else {
      navigate('/SevaUpdates')
    }
  }

  const handleCardClick = () => {
    navigate('/SevaUpdates/allInitiativeReports', {
      state: {
        initiativeId: initiativeId,
        initiativeName: title,
        mpName: mpName,
        tenure: tenure,
        mpId: getIds(),
      },
    })
  }

  const handleMp = (e) => {
    navigate('/MpSevaUpdates', {
      state: {
        MpName: mpName,
        mpId: mpId,
        mpClick: true,
        mpData: mpData,
      },
    })
  }

  const ongoingBreadCrumbClick = () => {
    navigate('/SevaUpdates/viewAllOngoingInitiatives', {
      state: {
        viewAllValue: 'Ongoing Seva Initiatives',
        mpName: mpName,
        mpId: mpId,
        mySeva: mySeva,
        tenure: tenure,
        data: mpId ? ongoingSevaInitiativesListByMpId : ongoingSevaInitiativesList,
        InitiativeReports: false,
      },
    })
  }

  return (
    <Grid container sx={{ mb: 2, mt: 2 }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        separator={<NavigateNextIcon fontSize="small" sx={{ marginLeft: '-15px' }} />}
      >
        {InitiativeReports ? (
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextIcon fontSize="small" sx={{ marginLeft: '-15px' }} />}
          >
            <Button underline="hover" onClick={onFolderClick}>
              <Typography className="breadcrumb-btn1">Nationwide Seva Updates</Typography>
            </Button>
            <Button underline="hover">
              <Typography className="breadcrumb-btn1" onClick={ongoingBreadCrumbClick}>
                Ongoing Seva Initiative
              </Typography>
            </Button>
            <Button underline="hover">
              <Typography className="breadcrumb-btn1" onClick={handleCardClick}>
                {title}
              </Typography>
            </Button>
          </Breadcrumbs>
        ) : viewAllTitle === 'Ongoing Seva Initiatives' ? (
          <Button underline="hover" onClick={onFolderClick}>
            <Typography className="breadcrumb-btn1">Nationwide Seva Updates</Typography>
          </Button>
        ) : (
          <>
            <Breadcrumbs
              aria-label="breadcrumb"
              separator={<NavigateNextIcon fontSize="small" sx={{ marginLeft: '-15px' }} />}
            >
              <Button underline="hover" className="button-underline-hover1" onClick={onFolderClick}>
                <Typography className="font-family-weight">
                  {isFromNationwide ? 'Nationwide Seva Updates' : eventsForInitiative ? title : 'My Seva Updates'}
                </Typography>
              </Button>
              {mpId && mpName && mpName !== 'My Seva Rank' && (
                <Button underline="hover" className="Breadcrumb-btn" onClick={handleMp}>
                  {mpName}
                </Button>
              )}
            </Breadcrumbs>
          </>
        )}
        <Button underline="hover" className="button-underline-hover2">
          <Typography className="font-family-weight">
            {eventsForInitiative ? 'All Events' : InitiativeReports ? 'Initiative Reports' : viewAllTitle}
          </Typography>
        </Button>
      </Breadcrumbs>
    </Grid>
  )
}

export default ViewAllScreenHeaderBreadCrumb
