import { Box, Card, Typography, CardContent, CardMedia } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import first from '../../../asserts/images/1st.png'
import second from '../../../asserts/images/2nd.png'
import third from '../../../asserts/images/3rd.png'
import { getRanksCount } from '../../../store/action/getRanksCount'
import Tabs, { tabsClasses } from '@mui/material/Tabs'
import './MySevaRankCardCarousel.css'

const MySevaRankCardCarousel = ({ mySeva, mpId, mpName }) => {
  const dispatch = useDispatch()
  const ranksCount = useSelector((state) => state?.ranksCount?.data[0])

  const [rankingHeaders, setRankingHeaders] = useState([])
  const isBdayCampaignEnabled = JSON.parse(localStorage.getItem('isBdayCampaignEnabled') || false)
  const isSurveyCampaignEnabled = JSON.parse(localStorage.getItem('isSurveyCampaignEnabled') || false)
  const ranking = {
    1: first,
    2: second,
    3: third,
  }
  useEffect(() => {
    if (ranksCount) {
      const headerTabsData = [
        { id: 1, value: 'MP Seva Score', score: ranksCount?.sevaScore1 },
        { id: 2, value: 'Initiatives', score: ranksCount?.initiatives1 },
        { id: 3, value: 'Members Added', score: ranksCount?.memberAdded1 },
        { id: 4, value: 'Followers', score: ranksCount?.followers1 },
        {
          id: 5,
          value: 'Twitter Performance',
          score: ranksCount?.twitterPerformance1,
        },
        { id: 6, value: 'Op-Eds & Media Coverage', score: ranksCount?.opEds1 },
        { id: 7, value: 'Donations', score: ranksCount?.donation1 },
      ]

      if (isBdayCampaignEnabled) {
        headerTabsData.unshift({ id: 0, value: 'E-Cards Score', score: ranksCount?.bdayCampaignRank })
      }
      if (isSurveyCampaignEnabled) {
        headerTabsData.unshift({ id: 0, value: 'Survey Score', score: ranksCount?.surveyCampaignRank })
      }

      const sortedRankings = headerTabsData.sort((prev, current) => prev?.score - current?.score)
      setRankingHeaders(sortedRankings)
    }
  }, [ranksCount])

  const fetchAllData = async () => {
    try {
      if (mySeva || mpId) {
        dispatch(getRanksCount(mpId))
      }
    } catch (error) {
    } finally {
    }
  }
  useEffect(() => {
    fetchAllData()
  }, [])

  return (
    <>
      {ranksCount && (
        <Box className={`nav-icon ${mySeva && mpName ? 'width-all' : 'widthall900'}`}>
          <Tabs
            variant="scrollable"
            scrollButtons={true}
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
              '& .MuiTabs-flexContainer': {
                marginLeft: '-25px',
              },
            }}
          >
            {rankingHeaders?.map((item, index) => (
              <Card
                sx={{
                  borderRadius: 0,
                  overflow: 'visible',
                  minWidth: item.value.length > 22 ? '250px' : '200px',
                }}
              >
                <CardContent>
                  <Box className="d-flex justify-content-center align-items-center flex-column">
                    {Object.keys(ranking).includes(item?.score?.toString()) ? (
                      <Box sx={{ maxWidth: '35px' }}>
                        <CardMedia component="img" alt="rank" src={ranking[item.score]} className="topicon"></CardMedia>
                      </Box>
                    ) : (
                      <Box>
                        <Typography className="scoreicon d-flex justify-content-center align-items-center">
                          <Typography className="score-icon-number" variant="span">
                            {item?.score}
                            <Typography variant="span" className="rank-super-tag">
                              th
                            </Typography>
                          </Typography>
                        </Typography>
                      </Box>
                    )}
                    <Box className="rank-format" key={index}>
                      {item.value}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            ))}
          </Tabs>
        </Box>
      )}
    </>
  )
}

export default MySevaRankCardCarousel
