import React, { useState, useRef, useEffect, createRef, useMemo } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import CrossIcon from '../../../../asserts/images/Close.svg'
import unknownImg from '../../../../asserts/images/folders.png'
import NoImage from '../../../../asserts/images/noImageFound.jpg'
import './AddVideoTemplate.css'
import { useLoading } from '../../../../utils/LoadingContext'
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
  DialogActions,
  ImageListItem,
  Typography,
  styled,
  Tooltip,
  tooltipClasses,
  Avatar,
  TextField,
} from '@mui/material'
import { Box } from '@mui/system'
import AddIcon from '@mui/icons-material/Add'
import UploadIcon from '@mui/icons-material/Upload'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import UploadVideos from './UploadVideoDialog'
import closeIconp from '../../../../asserts/images/close-1.png'
import infoic from '../../../../asserts/images/Info.svg'
import { useNotificationContext } from '../../../../utils/NotificationContext'
import VideoPlayerPreview from '../../../ReusableComponents.js/VideoPlayerPreview'
import { useSelector } from 'react-redux'
import { makeLogRequest } from '../../../../utils/AuditLogs'
import { handleFileName, handleVideoSrc, iconComponent } from '../../../ReusableComponents.js/reuseMethods'
import zipIcon from '../../../../asserts/images/ZipIcon.svg'
import fileIcon from '../../../../asserts/images/FileIcon.svg'
import downloadIcon from '../../../../asserts/images/downloadIcon.svg'
import '../AddImageTemplate/ImageTemplate.css'
import { pushTag } from '../../../../utils/gtmservice'

export const BootstrapTooltipInfo = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    '&:before': {
      border: '1px solid #356F92',
    },
    color: theme.palette.common.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #356F92',
    boxShadow: theme.shadows[1],
    width: 240,
    maxWidth: '370px',
  },
}))

const AddVideoTemplates = ({
  handleCloseVideosTemplateDialog,
  openVideoTemplateDialog,
  setVideoData,
  languages,
  editDetails,
  setUpdateVideoValue,
}) => {
  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    setError,
  } = useForm()
  const zipFormat = ['application/x-zip-compressed', 'application/x-zip', 'zip']
  const mtsFormat = ['video/vnd.dlna.mpeg-tts', 'video/mp2t']
  const fileFormats = [
    'video/mp4',
    'video/quicktime',
    'video/mov',
    'video/x-ms-wmv',
    'video/x-ms-asf',
    'video/webm',
    'video/avi',
    'video/x-msvideo',
    'video/x-flv',
    'video/x-f4v',
    'video/x-matroska',
    'video/MP2T',
    'video/vnd.dlna.mpeg-tts',
    'video/mp2t',
    'application/metastream',
    'video/avchd-stream',
    'video/mts',
    'application/zip',
    '.flv',
    '.webm',
    '.wmv',
    '.zip',
    '.rar',
    '.7z',
    '.gz',
    '.mts',
    '.avi',
    '.mkv',
    '.mp4',
    '.mov',
    'application/x-zip-compressed',
  ]
  const { showNotification } = useNotificationContext()

  const uploadVideoRef = useRef()
  const [videoRefs, setVideoRefs] = useState([])

  const [videos, setVideos] = useState([])
  const [videoFiles, setVideoFiles] = useState([])
  const [currentImageIndex, setCurrentImageIndex] = useState(-1)
  const [checkWarningPopup, setCheckWarningPopup] = useState(false)
  const [dialogState, setDialogState] = useState({
    open: false,
    for: undefined,
  })
  const [inputState, setInputState] = useState([
    {
      id: Date.now(),
      language: true,
      upload: true,
      remove: true,
    },
  ])
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const { setLoading } = useLoading()
  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('add_video_template_popup', sqId, data)
  }

  const gridStyles = useMemo(
    () => ({
      pr: 1,
      pl: 1,
      margin: '0 auto',
      border: 'dotted 3px #1976d2',
      bgcolor: '#E0F6FF',
      padding: '50px',
      maxWidth: '275px !important',
      borderRadius: '14px',
      aspectRatio: '7 / 4',
    }),
    []
  )
  const subtitleStyles = useMemo(
    () => ({
      fontFamily: 'HK Grotesk',
      color: '#356F92',
      fontSize: '10px',
      fontWeight: 'bold',
      margin: '0 auto',
      textAlign: 'center',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    }),
    []
  )
  const countExistingZips = () => {
    let zipCount = 0
    videos.forEach((file) => {
      if (file.type === 'zip') {
        zipCount++
      }
    })
    return zipCount
  }

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'add_video_template_pop_up',
      message: message,
    })
  }

  useEffect(() => {
    setVideoRefs(inputState.map(() => createRef()))
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'add_video_template_pop_up',
    })
  }, [])

  useEffect(() => {
    if (editDetails) {
      const temp = []
      editDetails?.forEach((template, i) => {
        temp.push({
          id: i,
          language: true,
          languageValue: template.language,
          upload: true,
          remove: true,
          videosData: typeof template.video === 'string' ? JSON.parse(template.video) : template.video,
        })
        !getValues(`${i}`)?.length > 0 && setValue(`${i}.language`, template.language)
        !getValues(`${i}`)?.length > 0 &&
          setValue(`${i}.videos`, typeof template.video === 'string' ? JSON.parse(template.video) : template.video)
      })
      setInputState(temp)
      setVideoRefs(temp.map(() => createRef()))
    }
  }, [editDetails])

  const removeInput = (id) => {
    const tempData = [...inputState]
    const newData = tempData.filter((input) => input.id !== id)
    const index = inputState.findIndex((data) => data.id === id)
    const newRefs = videoRefs.filter((_, i) => tempData[i].id !== id)
    unregister(index.toString())
    setInputState(newData)
    setVideoRefs(newRefs)
    const newFormData = {}
    const formData = getValues()
    const formDataValues = Object.values(formData)
    formDataValues.forEach((item, ind) => {
      newFormData[ind] = item
    })
    reset(newFormData)
  }

  const addInput = () => {
    const newInput = {
      id: inputState[inputState.length - 1]?.id + 1,
      language: true,
      upload: true,
      remove: true,
    }
    setInputState((prevState) => [...prevState, newInput])
    setVideoRefs((prevRefs) => [...prevRefs, createRef()])
  }
  const isUploadVideoEnabledOrNot = (i) => {
    const language = getValues(`${i}.language`)
    let isDisabled = false
    isDisabled = Boolean(language)
    return isDisabled
  }
  const handleClick = (e, i) => {
    setVideos([])
    // uploadVideoRef.current?.click();
    if (isUploadVideoEnabledOrNot(i)) {
      videoRefs[i].current?.click()
    }
    e?.stopPropagation()
  }
  const handleVideoChange = async (e, i) => {
    if (errors[i]) {
      errors[i].videos = null
    }
    let newFiles = []
    let remainingZipCount = 5 - countExistingZips()
    const uploadedFiles = e.target.files
    if (uploadedFiles.length > 0) {
      setCheckWarningPopup(true)
    }
    // setVideoFiles([...videoFiles, ...uploadedFiles]);
    let newVideos = []
    for (let i = 0; i < uploadedFiles.length; i++) {
      const file = uploadedFiles[i]
      let fileType = file.type === '' ? '.' + file.name?.split('.').pop() : file.type
      const isRightFormat = fileFormats.includes(fileType)
      const fileSizeInBytes = file.size
      const maxSizeInBytes = 1024 * 1024 * 1024 // 1GB
      if (fileSizeInBytes > maxSizeInBytes) {
        setLoading(false)
        showNotification('Error', 'Please upload video with size less than 1GB', 'error')
        return
      }
      if (file.type === 'application/x-zip-compressed' && remainingZipCount > 0) {
        remainingZipCount--
      } else if (file.type === 'application/x-zip-compressed' && remainingZipCount <= 0) {
        // Reject the upload and display an error message
        showNotification('Error', 'Only a maximum of 5 zip files are allowed in total.', 'error')
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      const filePromise = new Promise((resolve, reject) => {
        setLoading(true)
        reader.onload = () => {
          newVideos.push({
            type: zipFormat.includes(file.type) ? 'zip' : mtsFormat.includes(file.type) ? 'mts' : 'video',

            url: zipFormat.includes(file.type) ? zipIcon : fileFormats.includes(file.type) ? reader.result : unknownImg,
            file: file,
            id: i,
          })
          if (i === uploadedFiles.length - 1) {
            if (!isRightFormat) {
              showNotification('Error', 'You can only upload mp4 videos', 'error')
              return
            }
          }
          resolve()
        }
        reader.onerror = () => {
          reject(reader.error)
        }
      })
      try {
        await filePromise
        if (i === uploadedFiles.length - 1) {
          setVideoFiles([...videoFiles, ...newVideos])
          await setVideos([...newVideos])
          handleOpenUploadDialog(`${currentImageIndex}`, true, newVideos)
        }
      } catch (error) {
        console.error('An error occurred:', error)
      } finally {
        setLoading(false)
      }
    }
    // await handleOpenUploadDialog(`${currentImageIndex}`);
  }
  const handleOpenUploadDialog = (id, edit = false, videos = []) => {
    if (!edit) {
      setVideos(getValues(id))
      setDialogState({ open: true, for: id })
    } else {
      setVideos(videos)
      setDialogState({ open: true, videos, for: id })
    }
  }
  const handleCloseUploadDialog = () => setDialogState({ open: false, for: undefined })

  const onSubmit = async (data) => {
    await postAuditLogs('add_video_template')
    const objectArray = Object.values(data)
    await setVideoData([])
    await setVideoData((prevData) => [
      ...prevData,
      prevData.length > 0 ? objectArray?.map((val) => prevData[0]?.push(val)) : objectArray,
    ])
    await setUpdateVideoValue(true)
    handleCloseVideosTemplateDialog()
  }
  const handleDelete = (i) => {
    // setInitialId(i)
    // if(videos.length<1){
    //     setVideos([])
    // setValue(`${i}.videos`, []);
    // setValue(`undefined`, []);
    // }
    // else{
    setCheckWarningPopup(true)
    const tempVideos = [...videos]
    tempVideos.splice(0, 1)
    setVideos(tempVideos)
    const tempVideoFiles = [...videoFiles]
    tempVideoFiles.splice(0, 1)
    setVideoFiles(tempVideoFiles)
    setVideoData(tempVideoFiles)
    if (inputState.length > 0) {
      let updatedInputState = [...inputState] // create a copy of inputState
      updatedInputState[i] = {
        // update the object at index i
        ...updatedInputState[i], // spread the current object's properties
        videosData: inputState[i].videosData.slice(1), // create a new array without the first element
      }
      setInputState(updatedInputState)
      let val = getValues(`${i}`)

      delete val.videos[0]
      val.videos.splice(0, 1)
      setValue(`${i}.videos`, val.videos)
      setVideos(val.videos)
    } else {
      let val = getValues(`${i}`)

      delete val.videos[0]
      val.videos.splice(0, 1)
      setValue(`${i}.videos`, val.videos)
    }
  }

  return (
    <Dialog
      onClose={() => handleCloseVideosTemplateDialog(checkWarningPopup)}
      // onClose={() => handleCloseVideosTemplateDialog()}
      open={openVideoTemplateDialog}
      sx={{ height: '90vh', marginTop: '25px' }}
    >
      <DialogTitle
        sx={{
          fontFamily: 'HK Grotesk',
          color: '#2e739c',
          fontWeight: '700',
          textAlign: 'center',
          fontSize: '26px',
        }}
      >
        {editDetails?.length > 0 ? 'Update' : 'Add'} Video Templates
      </DialogTitle>
      <Avatar
        onClick={async () => {
          await postAuditLogs('close')
          sendGALog('close')
          handleCloseVideosTemplateDialog(checkWarningPopup, true)
        }}
        sx={{
          height: '30px',
          width: '30px',
          position: 'absolute',
        }}
        src={CrossIcon}
        className="closeIcon"
      />
      <Box sx={{ m: 3, overflowX: 'hidden' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {inputState.map((input, i) => {
            return (
              <Box className="box" key={input.id}>
                <Grid container>
                  <Grid item xs={6} md={6} lg={6} xl={6}>
                    {input.language && (
                      <Grid container spacing={2} sx={{ mt: 0.5 }}>
                        <Grid item xs={12} md={12} lg={12} xl={12} sx={{ width: '280px' }}>
                          <FormControl
                            size="small"
                            fullWidth
                            sx={{
                              '& .MuiInputBase-input': {
                                borderRadius: '14px!important',
                              },
                              '& .MuiOutlinedInput-notchedOutline': {
                                borderRadius: '14px',
                              },
                            }}
                          >
                            <Typography
                              component="b"
                              sx={{
                                fontWeight: 'bold',
                                letterSpacing: 'normal',
                                fontFamily: 'HK Grotesk',
                              }}
                              className="language-fieldname"
                            >
                              Language
                            </Typography>
                            <Select
                              displayEmpty
                              labelId="demo-select-small"
                              id="demo-select-small"
                              IconComponent={iconComponent}
                              // label="Langauge"
                              required
                              {...register(`${i}.language`, {
                                required: 'Please select Language',
                              })}
                              defaultValue={input?.languageValue}
                              onChange={() => {
                                setCheckWarningPopup(true)
                              }}
                              renderValue={(selected) => {
                                if (selected == null || selected?.length === 0) {
                                  return (
                                    <Typography className="vid-lang-placeholder">
                                      Select Language of the video
                                    </Typography>
                                  )
                                } else {
                                  return <Typography>{selected}</Typography>
                                }
                              }}
                            >
                              {languages &&
                                languages.map((s) => {
                                  return (
                                    <MenuItem native key={s.id} sx={{ width: '100%' }} value={s.language} size="small">
                                      {s.language}
                                    </MenuItem>
                                  )
                                })}
                            </Select>
                            <FormHelperText class="p-error">
                              {errors && errors[i] && errors[i].language?.message}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                    )}
                    {input.remove && inputState.length !== 1 && (
                      <Grid item xs={3} sx={{ mt: 1, marginLeft: '-15px' }}>
                        <Button
                          variant="outlined"
                          sx={{ borderRadius: 4 }}
                          className="button-tr-citizen-admin"
                          onClick={() => {
                            removeInput(input.id)
                            setCheckWarningPopup(true)
                          }}
                        >
                          Remove
                        </Button>
                        {/* } */}
                      </Grid>
                    )}
                  </Grid>
                  {!input?.videosData?.[0] ? (
                    <Grid item xs={6} md={6} lg={6} xl={6} sx={{ marginTop: '20px' }}>
                      <Box className="preview-img">Video Preview</Box>
                      {input.upload && (
                        <Grid item xs={6} sx={gridStyles}>
                          <Box className="title-n-tooltip d-flex justify-content-center">
                            <Box className="upload-text upload-file-text ">UPLOAD A FILE</Box>
                            <BootstrapTooltipInfo
                              title="Accepted file formats: .mp4, .mov, .wmv, .webm, .avi, .flv, .mkv, .mts"
                              placement="left"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    fontSize: '18px',
                                    fontFamily: 'HK Grotesk',
                                    padding: '15px',
                                  },
                                },
                              }}
                            >
                              <img src={infoic} className="infoic-tooltip" />

                              {/* <ErrorOutlineIcon sx={{ ml: 2, color: "#387194" }} /> */}
                            </BootstrapTooltipInfo>
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              '& > :not(style)': {
                                width: 30,
                                height: 30,
                              },
                            }}
                          >
                            <Box>
                              <IconButton
                                color="primary"
                                aria-label="Upload"
                                className="upload-icon"
                                onClick={async (e) => {
                                  handleClick(e, i)
                                  setCurrentImageIndex(i)
                                  await postAuditLogs('add_video')
                                }}
                                sx={{}}
                              >
                                <img src={downloadIcon} width={40} />
                              </IconButton>
                              <br />
                              <input
                                type="file"
                                {...register(`${i}.videos`, {
                                  required: 'Please add Videos',
                                })}
                                ref={videoRefs[i]}
                                multiple
                                onChange={(e) => handleVideoChange(e, i)}
                                accept={fileFormats.join(', ')}
                                className="d-none"
                              />

                              <Box className="upload-support">Supported File: Videos,Open File, Zip File.</Box>
                              <Box
                                className="upload-browse cursorshow"
                                onClick={async (e) => {
                                  handleClick(e, i)
                                  setCurrentImageIndex(i)
                                  await postAuditLogs('add_video')
                                }}
                              >
                                {/* Add Videos */}
                                Browse
                              </Box>
                            </Box>
                            {/* {
                                                    getValues(`${i}.videos`)?.length > 0 && (
                                                        <div>
                                                            <video controls src={getValues(`${i}.videos`)[0].url} alt="" style={{ width: 250, height: 144, position: "relative", top: "45%", left: "0", transform: "translate(-50%, -50%)", background: "#fff" }} />
                                                            <img src={closeIconp} id={i} onClick={() => handleDelete(i)} className="imageclose-imge" />
                                                            {
                                                                getValues(`${i}.videos`).length > 1 ? <Link style={{ whiteSpace: 'nowrap', marginLeft: "-50px", position: "relative", top: "-280%" }} onClick={() => handleOpenUploadDialog(`${i}.videos`)}>+{getValues(`${i}.videos`).length - 1} More {getValues(`${i}.videos`).length > 2 ? "videos" : "Videos"} </Link> :
                                                                    <Link style={{ whiteSpace: 'nowrap', marginLeft: "-50px", position: "relative", top: "-280%" }} onClick={() => handleOpenUploadDialog(`${i}.videos`)}>Add More Videos </Link>
                                                            }
                                                        </div>
                                                    )
                                                } */}
                            {input?.videosData?.length > 0 && (
                              <Box>
                                <VideoPlayerPreview
                                  width="250"
                                  height="150"
                                  className="mar-class-video bg-white"
                                  // className={`fetchVideo${i}`}
                                  src={handleVideoSrc(input?.videosData[0])}
                                  controls
                                  onClick={() => handleOpenUploadDialog(i, true, input?.videosData)}
                                />

                                <img
                                  src={closeIconp}
                                  id={i}
                                  onClick={() => handleDelete(i)}
                                  className="imageclose-imge-preview-video"
                                />
                                {input?.videosData?.length > 1 ? (
                                  <Link
                                    className="linkclose-imge-preview-video"
                                    onClick={() => handleOpenUploadDialog(i, true, input?.videosData)}
                                  >
                                    +{input?.videosData?.length - 1} More{' '}
                                    {input?.videosData?.length > 2 ? 'Files' : 'File'}{' '}
                                  </Link>
                                ) : (
                                  <Link
                                    className="linkclose-imge-preview-video"
                                    onClick={() => handleOpenUploadDialog(i, true, input?.videosData)}
                                  >
                                    Add More Videos{' '}
                                  </Link>
                                )}
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      )}
                      {errors[i] && (
                        <FormHelperText error class="p-error center" classname="ml-0">
                          {errors && errors[i] && errors[i].videos?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                  ) : (
                    <Grid item xs={6} md={6} lg={6} xl={6} sx={{ marginTop: '20px', marginBottom: '7px' }}>
                      <Box className="update-video-preview-title">Video Preview</Box>
                      {input.upload && (
                        <Grid
                          item
                          xs={6}
                          sx={{
                            pr: 1,
                            pl: 1,
                            margin: '0 auto',
                            border: 'dotted 3px #1976d2',
                            padding: '50px',
                            width: '40%',
                            borderRadius: '14px',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              '& > :not(style)': {
                                width: 25,
                                height: 30,
                              },
                            }}
                          >
                            <Box>
                              <IconButton
                                color="primary"
                                aria-label="Upload"
                                onClick={async (e) => {
                                  handleClick(e, i)
                                  setCurrentImageIndex(i)
                                  await postAuditLogs('add_video')
                                }}
                                sx={{}}
                              >
                                <UploadIcon />
                              </IconButton>
                              <br />
                              <input
                                type="file"
                                {...register(`${i}.videos`, {
                                  required: 'Please add Videos',
                                })}
                                ref={videoRefs[i]}
                                multiple
                                onChange={(e) => handleVideoChange(e, i)}
                                onClick={(event) => {
                                  event.target.value = null
                                }}
                                accept={fileFormats.join(', ')}
                                className="d-none"
                              />

                              <Box className="add-video-button">Add Video</Box>
                            </Box>
                            {/* {
                                                    getValues(`${i}.videos`)?.length > 0 && (
                                                        <div>
                                                            <video controls src={getValues(`${i}.videos`)[0].url} alt="" style={{ width: 250, height: 144, position: "relative", top: "45%", left: "0", transform: "translate(-50%, -50%)", background: "#fff" }} />
                                                            <img src={closeIconp} id={i} onClick={() => handleDelete(i)} className="imageclose-imge" />
                                                            {
                                                                getValues(`${i}.videos`).length > 1 ? <Link style={{ whiteSpace: 'nowrap', marginLeft: "-50px", position: "relative", top: "-280%" }} onClick={() => handleOpenUploadDialog(`${i}.videos`)}>+{getValues(`${i}.videos`).length - 1} More {getValues(`${i}.videos`).length > 2 ? "videos" : "Videos"} </Link> :
                                                                    <Link style={{ whiteSpace: 'nowrap', marginLeft: "-50px", position: "relative", top: "-280%" }} onClick={() => handleOpenUploadDialog(`${i}.videos`)}>Add More Videos </Link>
                                                            }
                                                        </div>
                                                    )
                                                } */}
                            {input?.videosData?.length > 0 && (
                              <Box>
                                {handleVideoSrc(input.videosData[0]) === 'video' && (
                                  <VideoPlayerPreview
                                    width="250"
                                    height="150"
                                    className="mar-class-video bg-white"
                                    // className={`fetchVideo${i}`}
                                    src={
                                      input?.videosData[0].url || input?.videosData[0].url === ''
                                        ? URL.createObjectURL(input?.videosData[0].file)
                                        : input?.videosData[0]
                                    }
                                    controls
                                    onClick={() => handleOpenUploadDialog(i, true, input?.videosData)}
                                  />
                                )}
                                {['zip', 'unknown', 'mts'].includes(handleVideoSrc(input.videosData[0])) && (
                                  <ImageListItem
                                    key={i}
                                    className="bgfolder cursorshow"
                                    sx={{
                                      margin: '0px 0px -56px',
                                      bottom: '70px',
                                      right: '103px',
                                      width: '200px !important',
                                      padding: '7px 33px 39px',
                                      aspectRatio: '1 / 0.9 !important',
                                    }}
                                  >
                                    <img
                                      className="m-0"
                                      key={i}
                                      src={handleVideoSrc(input.videosData[0]) === 'zip' ? zipIcon : unknownImg} // alt={item.title}
                                      onClick={() => handleOpenUploadDialog(i, true, input?.videosData)}
                                    />
                                    <Typography variant="subtitle1" sx={subtitleStyles}>
                                      {handleFileName(input?.videosData[0])}
                                    </Typography>
                                  </ImageListItem>
                                )}

                                {input?.videosData?.length > 1 ? (
                                  <Link
                                    className="add-files-link1"
                                    style={{
                                      top: handleVideoSrc(input.videosData[0]) !== 'video' ? '-200%' : '-25%',
                                    }}
                                    onClick={() => handleOpenUploadDialog(i, true, input?.videosData)}
                                  >
                                    +{input?.videosData?.length - 1} More{' '}
                                    {input?.videosData?.length > 2 ? 'Files' : 'File'}{' '}
                                  </Link>
                                ) : (
                                  <Link
                                    className="add-files-link"
                                    style={{
                                      top: handleVideoSrc(input.videosData[0]) !== 'video' ? '-200%' : '-25%',
                                    }}
                                    onClick={() => handleOpenUploadDialog(i, true, input?.videosData)}
                                  >
                                    Add More Files{' '}
                                  </Link>
                                )}
                                <img
                                  src={closeIconp}
                                  id={i}
                                  onClick={() => handleDelete(i)}
                                  className="imageclose-imge-preview-video"
                                  alt="Close"
                                  style={{
                                    marginLeft:
                                      handleVideoSrc(input.videosData[0]) !== 'video'
                                        ? '62px'
                                        : !isSafari
                                        ? '40px'
                                        : '100px',
                                    marginTop:
                                      handleVideoSrc(input.videosData[0]) !== 'video'
                                        ? '-420px'
                                        : !isSafari
                                        ? '-300px'
                                        : '-345px',
                                  }}
                                />
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      )}
                      {errors[i] && (
                        <FormHelperText error class="p-error center" className="ml-0">
                          {errors && errors[i] && errors[i].videos?.message}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}
                  <hr className="hr-line"></hr>
                </Grid>
              </Box>
            )
          })}
        </form>
        <Box className="btn-box">
          <React.Fragment>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                mb: 2,
                mt: 2,
                marginLeft: '-15px',
              }}
            >
              <Button
                variant="outlined"
                sx={{ borderRadius: 4 }}
                className="button-tr-citizen-admin"
                startIcon={<AddIcon sx={{ mt: '5px' }} />}
                onClick={async () => {
                  await postAuditLogs('add_row')
                  sendGALog('add_row')
                  addInput()
                }}
              >
                Add More
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
            </Box>
          </React.Fragment>
        </Box>
      </Box>
      <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
        <Button
          sx={{
            p: 1,
            mr: 1,
            backgroundColor: '#ef7335',
            borderRadius: 4,
            width: '250px',
          }}
          className="button-tr-2"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          onFocus={() => sendGALog(editDetails?.length > 0 ? 'update' : 'save')}
        >
          {editDetails?.length > 0 ? 'Update Video Template' : 'Add Video Template'}
        </Button>
      </DialogActions>
      {videos?.length > 0 && (
        <UploadVideos
          handleCloseUploadDialog={handleCloseUploadDialog}
          state={dialogState}
          setValue={setValue}
          getValue={getValues}
          initialVideos={dialogState.for === 'edit' ? dialogState.videos : videos}
          setVideosNew={setVideos}
          setInputData={setInputState}
          inputData={inputState}
          fileFormats={fileFormats}
          setCheckWarningPopup={setCheckWarningPopup}
        />
      )}
    </Dialog>
  )
}

export default AddVideoTemplates
