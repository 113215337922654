import { POST_CREATE_EVENT_FAILURE, POST_CREATE_EVENT_REQUEST, POST_CREATE_EVENT_SUCCESS } from './types';
import axiosInstance from '../../utils/api';

export const postCreateEventRequest = () => {
  return {
    type: POST_CREATE_EVENT_REQUEST,
  };
};
export const postCreateEventSuccess = (value) => {
  return {
    type: POST_CREATE_EVENT_SUCCESS,
    payload: value,
  };
};
export const postCreateEventFailure = (error) => {
  return {
    type: POST_CREATE_EVENT_FAILURE,
    payload: error,
  };
};

// currently used
export const postCreateEvent = (id, payload, config) => async (dispatch) => {
  dispatch(postCreateEventRequest);
  return axiosInstance
    .post(`/api/event/create/${id}`, payload, config)
    .then((response) => {
      const result = response.data;
      dispatch(postCreateEventSuccess(result));
      return response;
    })
    .catch((error) => {
      const errorMsg = error.message;
      dispatch(postCreateEventFailure(errorMsg));
      throw new Error(error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message);
      // throw new Error(error?.response?.data?.message)
    });
};
