import imagedelete from '../../../asserts/images/bin.png'
import './DeleteButton.css'

const DeleteButton = ({ onClick, page }) => {
  return (
    <img
      src={imagedelete}
      onClick={onClick}
      className={`${page === 'CreateInitiative' ? 'deleteimgcss1' : 'deleteimgcss'} cursorshow`}
      alt="delete"
    />
  )
}

export default DeleteButton
