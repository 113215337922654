import React, { useState, useEffect } from 'react'
import DialogTitle from '@mui/material/DialogTitle'
import Dialog from '@mui/material/Dialog'
import 'pages/CreateInitiatives/CreateInitiative.css'
import './NewAssingMP.css'
import { Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAssignMpList } from 'store/action/assignMpList'
import { postIntiative } from 'store/action/createInitiative'
import { getStateList } from 'store/action/stateList'
import { useNotificationContext } from 'utils/NotificationContext'
import { useLoading } from 'utils/LoadingContext'
import { makeLogRequest } from 'utils/AuditLogs'
import { pushTag } from 'utils/gtmservice'
import TemplateDialogCloseButton from 'Components/CreateInitiativesRefactored/TemplateDialogCloseButton/TemplateDialogCloseButton.js'
import AssignMpConfirmDialog from 'Components/CreateInitiativesRefactored/AssignMpConfirmDialog/AssignMpConfirmDialog.js'
import TemplateDialogSubmitButton from 'Components/CreateInitiativesRefactored/TemplateDialogSubmitButton/TemplateDialogSubmitButton'
import AssignMpSelectComponent from 'Components/CreateInitiativesRefactored/AssignMpSelectComponent/AssignMpSelectComponent'

const AssignToMP = ({
  handleCloseAssingToMPDialog,
  openAssingToMPDialog,
  intiativeData: initiativeData,
  id,
  selectedMP,
  createNewClick,
  eventList,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { assignMpList } = useSelector((state) => state?.assignMpList)
  const { statedata: stateData } = useSelector((state) => state?.stateList?.data)
  const userProfile = useSelector((state) => state?.userProfile?.data)
  const { setLoading } = useLoading()
  const [initiativeDetail, setInitiativeDetail] = useState(initiativeData)
  const [confirm, setConfirm] = useState(false)
  const { showNotification } = useNotificationContext()
  const [stateApiResult, setStateApiResult] = useState([])
  const [selectedState, setSelectedState] = useState([]) // format id, state_name
  const [stateSearchClearIcon, setStateSearchClearIcon] = useState(false)
  const [stateSearchState, setStateSearchState] = useState('')
  const [mpApiResult, setMpApiResult] = useState([])
  const [selectedMps, setSelectedMps] = useState([]) // format id, name, user_name, state_name
  const [mpClearIcon, setMPSearchClearIcon] = useState(false)
  const [mpSearchState, setMPSearchState] = useState('')

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('assign_to_mp_popup', sqId, data)
  }

  useEffect(() => {
    if (confirm) {
      pushTag({
        event: 'pageload',
        page_title: 'update_initiative_pop_up',
      })
    }
  }, [confirm])

  const sendGALog = (message) => {
    pushTag({
      event: 'select_option',
      page_title: 'assign_mp_pop_up',
      message: message,
    })
  }

  useEffect(() => {
    dispatch(getStateList())
    postAuditLogs('pageload')
    pushTag({
      event: 'pageload',
      page_title: 'assign_mp_pop_up',
    })
  }, [])

  useEffect(() => {
    const states = selectedState?.map((state) => {
      return state.state_name
    })
    dispatch(getAssignMpList(states.toString()))
  }, [selectedState])

  useEffect(() => {
    const stateResult = stateData?.length > 0 ? [...stateData] : []
    stateResult.unshift({ id: 'all', state_name: 'All' })
    setStateApiResult(stateResult)
  }, [stateData])

  useEffect(() => {
    const mpResult = assignMpList?.mpAssignData?.length > 0 ? [...assignMpList.mpAssignData] : []
    mpResult.unshift({ id: 'all', user_name: 'All' })
    setMpApiResult(mpResult)
  }, [assignMpList?.mpAssignData])

  const setUniqueStates = (mps) => {
    const uniqueStates = [...new Set(mps.map((item) => item.state_name))]
    setSelectedState([])
    uniqueStates.forEach((state) => {
      const index = stateData?.findIndex((item) => item.state_name.toLowerCase() == state.toLowerCase())
      const stateItem = stateData && stateData[index] ? stateData[index] : []
      setSelectedState((prev) => [...prev, { id: stateItem?.id, state_name: state }])
    })
  }
  useEffect(() => {
    if (id && selectedMP?.length) {
      setSelectedMps([])
      selectedMP?.map((val) => {
        setSelectedMps((prev) => [
          ...prev,
          {
            id: val?.id,
            user_name: val?.user_name,
            state_name: val?.state_name,
            house: val?.house,
            user_avatar: val?.user_avatar,
          },
        ])
      })
      setUniqueStates(selectedMP)
    }
  }, [])

  const handleStateSearch = (searchText) => {
    setStateSearchState(searchText)
    const stateResults = [...stateData]
    if (searchText.length > 0) {
      setStateSearchClearIcon(true)
      const results = stateResults.filter((item) => item.state_name.toLowerCase().includes(searchText.toLowerCase()))
      results.unshift({ id: 'all', state_name: 'All' })
      setStateApiResult(results)
    } else {
      setStateSearchClearIcon(false)
      stateResults.unshift({ id: 'all', state_name: 'All' })
      setStateApiResult(stateResults)
    }
  }

  const handleMPSearch = (searchText) => {
    setMPSearchState(searchText)
    const mpResults = [...assignMpList?.mpAssignData]
    if (searchText.length > 0) {
      setMPSearchClearIcon(true)
      const results = mpResults.filter((item) => item.user_name.toLowerCase().includes(searchText.toLowerCase()))
      results.unshift({ id: 'all', user_name: 'All' })
      setMpApiResult(results)
    } else {
      setMPSearchClearIcon(false)
      mpResults.unshift({ id: 'all', user_name: 'All' })
      setMpApiResult(assignMpList?.mpAssignData)
    }
  }

  const handlePost = async (event) => {
    try {
      setLoading(true)
      const response = await dispatch(postIntiative(initiativeDetail, id))

      if (response.status === 200 || response.status === 201) {
        navigate('/SevaInitiatives')
        showNotification('Success', response.data.message, 'success')
      }
    } catch (error) {
      showNotification('Error', 'Failed to post the data')
    } finally {
      setLoading(false)
    }
    setConfirm(false)
    handleCloseAssingToMPDialog()
  }

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: '15px',
            height: 'auto',
            position: 'absolute',
            top: '20%',
            maxWidth: '650px !important',
          },
        }}
        fullScreen
        onClose={() => {
          setStateSearchState('')
          handleCloseAssingToMPDialog()
        }}
        open={openAssingToMPDialog}
      >
        <DialogTitle
          sx={{
            color: '#356f92 !important',
            fontWeight: 'bold !important',
            textAlign: 'center !important',
            fontSize: '1.8rem !important',
          }}
        >
          Assign To MP
        </DialogTitle>
        <TemplateDialogCloseButton
          onClick={async () => {
            await postAuditLogs('close')
            sendGALog('close')
            handleCloseAssingToMPDialog()
          }}
        />

        <Box className="centered-container">
          <AssignMpSelectComponent
            assignMpList={selectedMps}
            inputLabelTitle={'Select State'}
            stateSearchClearIcon={stateSearchClearIcon}
            selectedValue={selectedState}
            setSelectedState={setSelectedState}
            setSelectedMps={setSelectedMps}
            clearIconOnClick={() => {
              handleStateSearch('')
              setStateSearchClearIcon(false)
              setStateSearchState('')
            }}
            inputBaseValue={stateSearchState}
            inputBaseOnChange={(e) => {
              handleStateSearch(e.target.value)
            }}
            stateApiResult={stateApiResult}
            stateData={stateData}
            placeholder={'Search State Name'}
          />

          <AssignMpSelectComponent
            inputLabelTitle={`Select MP's`}
            selectedValue={selectedMps}
            setSelectedMps={setSelectedMps}
            setSelectedState={setSelectedState}
            disabled={selectedState?.length === 0}
            clearIconOnClick={() => {
              setMPSearchClearIcon(false)
              setMPSearchState('')
              const mpResults = [...assignMpList?.mpAssignData]
              mpResults.unshift({ id: 'all', user_name: 'All' })
              setMpApiResult(assignMpList?.mpAssignData)
            }}
            inputBaseValue={mpSearchState}
            inputBaseOnChange={(e) => {
              handleMPSearch(e.target.value)
            }}
            placeholder={'Search MP Name'}
            mpApiResult={mpApiResult}
            assignMpList={assignMpList}
          />
        </Box>
        <React.Fragment>
          <TemplateDialogSubmitButton
            buttonSx={{
              p: 1,
              mr: 1,
              mt: 4,
              backgroundColor: '#ef7335',
              borderRadius: 4,
              position: 'relative',
              alignSelf: 'center',
              width: '300px',
              padding: '8px',
              marginBottom: '40px',
            }}
            buttonClassName={'button-primary-alt-contained'}
            title={createNewClick ? 'CREATE AND ASSIGN INITIATIVE' : 'UPDATE AND ASSIGN INITIATIVE'}
            onClick={async () => {
              let jsonObj = initiativeData
              const mpIds = []
              selectedMps.map((mp, i) => {
                mpIds.push(mp.id)
              })
              jsonObj.mplist = mpIds
              setUniqueStates(selectedMps)
              setInitiativeDetail(jsonObj)
              sendGALog(createNewClick ? 'create' : 'update')
              {
                createNewClick ? handlePost() : eventList?.length > 0 ? setConfirm(true) : handlePost()
              }
            }}
            disabled={selectedMps?.length === 0}
            variant={'contained'}
            dialogActionSx={{ justifyContent: 'center' }}
          />
        </React.Fragment>
      </Dialog>
      <AssignMpConfirmDialog confirm={confirm} setConfirm={setConfirm} handlePost={handlePost} />
    </>
  )
}

export default AssignToMP
