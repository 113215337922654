import React, { useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { TextField, InputAdornment } from '@mui/material'
import './SearchBar.css'
import CrossIcon from '../../../asserts/images/Close.svg'
import searchIcon from '../../../asserts/images/Search.svg'
import { makeLogRequest } from '../../../utils/AuditLogs'
import getInitiativeList from '../../../store/action/initiativeList'

const SearchBar = () => {
  const [searchValue, setSearchValue] = useState('')
  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state?.userProfile?.data)

  const handleSearch = (e) => {
    const searchText = e.target.value
    setSearchValue(searchText)
    debouncedSendRequest(searchText)
  }

  const sendRequest = useCallback((value) => {
    dispatch(getInitiativeList(value))
    postAuditLogs('search')
  }, [])

  const debouncedSendRequest = useMemo(() => {
    return debounce(sendRequest, 1000)
  }, [sendRequest])

  const postAuditLogs = async (sqId, data = {}) => {
    data.user_role = 'admin'
    data.useridtype = userProfile?.uuid
    await makeLogRequest('seva_initiatives', sqId, data)
  }

  const handleCrossClick = () => {
    if (searchValue?.length === 0) {
      return
    }
    setSearchValue('')
    debouncedSendRequest('')
  }

  const blockInvalidChar = (e) =>
    ['#', '&', '+', '_', '!', '@', '%', '[', ']', '=', '*', '^', '-', '?', '/', '$', ')', '('].includes(e.key) &&
    e.preventDefault()

  return (
    <TextField
      className="search-filter-icon"
      sx={{
        '& fieldset': { border: 'none' },
      }}
      onChange={handleSearch}
      value={searchValue}
      // onKeyDown={blockInvalidChar}
      placeholder="Search Initiative"
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <img
              className="cursorshow"
              alt="Search"
              width={20}
              height={21}
              src={searchValue?.length ? CrossIcon : searchIcon}
              onClick={handleCrossClick}
            />
          </InputAdornment>
        ),
      }}
    ></TextField>
  )
}
export default SearchBar
